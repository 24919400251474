import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { Gauge, LoadableContentArea, Theme } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';

import { PowerControllersGauge$key } from './__generated__/PowerControllersGauge.graphql';
import style from './style.module.css';

export interface PowerControllersGaugeProps {
    data: PowerControllersGauge$key | null;
    error?: boolean;
    retry: () => void;
}

export const PowerControllersGauge: FC<PowerControllersGaugeProps> = ({ data, error, retry }) => {
    const props = useFragment<PowerControllersGauge$key>(
        graphql`
            fragment PowerControllersGauge on Query {
                devices {
                    total
                }
                globalDeviceHealth {
                    status
                    count
                }
            }
        `,
        data
    );

    let healthy = 0;
    if (props) {
        props.globalDeviceHealth.forEach(element => {
            if (element.status === 'Healthy') {
                healthy = element.count;
            }
        });
    }

    const hasDevice = !!props && props.devices.total > 0;

    return (
        <LoadableContentArea
            className={style.gauge_container}
            variant='framed'
            data={props}
            error={error}
            onRetry={retry}
            render={props => {
                const value = hasDevice ? (healthy / props.devices.total) * 100 : 0;

                return (
                    <div className={style.gauge_container} data-testid='power-controllers-gauge'>
                        <Gauge
                            max={100}
                            value={value}
                            unit='%'
                            text='Healthy'
                            colour={Theme.customWhiteEggplant}
                            backgroundColour={Theme.customWhiteCoral}
                        />
                    </div>
                );
            }}
        />
    );
};
