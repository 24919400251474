import React, { FC, useState } from 'react';
import { commitMutation } from 'react-relay';
import { useRelayEnvironment } from 'react-relay';

import { Button, ThemedPageHeading } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';

import { AbortTestProgressBar } from './AbortTestProgressBar';
import {
    AbortTestModalMutation,
    AbortTestModalMutation$variables,
} from './__generated__/AbortTestModalMutation.graphql';
import style from './style.module.css';

interface AbortTestModalProps {
    testId: string | undefined;
    testName: string;
    setShowAbortModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AbortTestModal: FC<AbortTestModalProps> = ({ testId, testName, setShowAbortModal }) => {
    const environment = useRelayEnvironment();
    const [testAbortRequested, setTestAbortRequested] = useState(false);
    const [confirmationHeading, setConfirmationHeading] = useState(`Aborting: ${testName}`);
    const [confirmationText, setConfirmationText] = useState(
        'Test abort has been initiated. This may take a few minutes.'
    );

    const handleAbortTask = () => {
        const variables: AbortTestModalMutation$variables = {
            id: testId ?? '',
        };

        commitMutation<AbortTestModalMutation>(environment, {
            mutation: graphql`
                mutation AbortTestModalMutation($id: ID!) {
                    abortBatteryTest(id: $id)
                }
            `,
            variables,
            onError: () => {
                setConfirmationHeading('Error');
                setConfirmationText('Unable to cancel the task, please try again later.');
                setTestAbortRequested(true);
            },
            onCompleted: (response, errors) => {
                if (response.abortBatteryTest === true) {
                    setTestAbortRequested(true);
                }

                if (response.abortBatteryTest === false || errors) {
                    setConfirmationHeading('Error');
                    setConfirmationText('Unable to cancel the task, please try again later.');
                    setTestAbortRequested(true);
                }
            },
        });
    };

    return (
        <div className={style.cancel_abort_modal}>
            {!testAbortRequested && (
                <>
                    <ThemedPageHeading value='Abort Test' />
                    <div className='text-customEggplant font-CynthoNext-Regular text-base pb-12'>
                        Are your sure you want to abort this test?
                    </div>
                    <div className='space-x-2'>
                        <span data-testid='abort-yes-button'>
                            <Button onClick={handleAbortTask} buttonText='Yes' buttonClasses='w-24' />
                        </span>
                        <span data-testid='abort-no-button'>
                            <Button
                                onClick={() => setShowAbortModal(false)}
                                buttonText='No'
                                variant='gray'
                                buttonClasses='w-24'
                            />
                        </span>
                    </div>
                </>
            )}
            {testAbortRequested && (
                <>
                    <div className={`${style.confirmationHeading} text-customCoral font-CynthoNext-SemiBold pb-12`}>
                        {confirmationHeading}
                    </div>
                    <div className='pb-6'>
                        <AbortTestProgressBar testId={testId} />
                    </div>
                    <div className='text-customEggplant font-CynthoNext-Regular text-base pb-12'>
                        {confirmationText}
                    </div>
                    <div>
                        <Button
                            onClick={() => setShowAbortModal(false)}
                            buttonText='OK'
                            variant='gray'
                            buttonClasses='w-24'
                        />
                    </div>
                </>
            )}
        </div>
    );
};
