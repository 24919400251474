import React, { FC } from 'react';

import { Address, GPSCoordinates, GeneralInformation } from './components';

export interface SiteInformationProps {
    namespace?: string;
}

export const SiteInformation: FC<SiteInformationProps> = ({ namespace }) => {
    return (
        <div className={'space-y-6'}>
            <GeneralInformation namespace={namespace} />

            <Address namespace={namespace} />

            <GPSCoordinates namespace={namespace} />
        </div>
    );
};
