import React, { FC } from 'react';

import { FilterDurationSelect } from 'components/FilterDurationSelect';
import { PredefinedDuration } from 'components/FilterDurationSelect/common';
import { Less1Hour, Less3Hour, More5Hour } from 'components/FilterDurationSelect/presets';
import { CommonFilterProps } from 'filters/common';

import { TestResultEstimatedReserveTimeFilter } from '../types';

const FilterPresets: PredefinedDuration[] = [Less1Hour, Less3Hour, More5Hour];

export type TestResultEstimatedReserveTimeFilterUIProps = CommonFilterProps<TestResultEstimatedReserveTimeFilter>;

export const TestResultEstimatedReserveTimeFilterUI: FC<TestResultEstimatedReserveTimeFilterUIProps> = props => (
    <FilterDurationSelect title='Filter by Estimated Reserve Time' presets={FilterPresets} {...props} />
);
