/**
 * @generated SignedSource<<700117c6e7bce331c6cce3aa2d4b4823>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BatteryTestState = "Aborted" | "Analyzing" | "Failed" | "Finalizing" | "InProgress" | "Inconclusive" | "Passed" | "Scheduled" | "SmartStart" | "Waiting" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BlocHealthTable_test$data = {
  readonly blocs: {
    readonly results: ReadonlyArray<{
      readonly bloc: number;
      readonly state: BatteryTestState;
      readonly stateOfHealth: number | null;
      readonly string: number;
    }>;
    readonly total: number;
  };
  readonly " $fragmentType": "BlocHealthTable_test";
};
export type BlocHealthTable_test$key = {
  readonly " $data"?: BlocHealthTable_test$data;
  readonly " $fragmentSpreads": FragmentRefs<"BlocHealthTable_test">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BlocHealthTable_test",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BlocTestResults",
      "kind": "LinkedField",
      "name": "blocs",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BlocTestResult",
          "kind": "LinkedField",
          "name": "results",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bloc",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "string",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "stateOfHealth",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "state",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DeviceBatteryTestResults",
  "abstractKey": null
};

(node as any).hash = "4bf22b3c6e7445b78a64ff7aef3339ab";

export default node;
