import React, { FC, useEffect } from 'react';
import { PreloadedQuery, fetchQuery, loadQuery, usePreloadedQuery, useRelayEnvironment } from 'react-relay';

import { captureMessage } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { getGlobalEnvironment } from 'lib/environment';
import { DateTime, Duration } from 'luxon';
import { Subscription } from 'relay-runtime';

import { DevicePane } from '../../components/DevicePane';
import { WrappedDevicePlaceholder } from '../../components/WrappedDevicePlaceholder';
import { DefaultTimeRange, LiveDataRefreshInterval } from '../../settings';
import { DeviceLoad } from './DeviceLoad';
import { LoadContentQuery } from './__generated__/LoadContentQuery.graphql';

type Device = NonNullable<
    NonNullable<NonNullable<LoadContentQuery['response']['device']>['dualPlaneCompanion']>['device']
>;

export interface LoadContentProps {
    queryRef: PreloadedQuery<LoadContentQuery>;
    timeRange: Duration;
    deviceId: string;
}

export const LoadContent: FC<LoadContentProps> = ({ queryRef, timeRange, deviceId }) => {
    const data = usePreloadedQuery(ContentQuery, queryRef);
    const environment = useRelayEnvironment();

    // Live refresh the data
    useEffect(() => {
        // NOTE: The built-in poll mechanism does not support changing the variables
        let timeout: NodeJS.Timeout;
        let currentSubscription: Subscription | undefined;

        function poll() {
            const now = DateTime.local();
            currentSubscription = fetchQuery<LoadContentQuery>(
                environment,
                ContentQuery,
                {
                    id: deviceId,
                    start: now.minus(timeRange).toISO(),
                    end: now.toISO(),
                },
                { fetchPolicy: 'network-only' }
            ).subscribe({
                complete: () => {
                    timeout = setTimeout(poll, LiveDataRefreshInterval);
                },
                error: () => {
                    timeout = setTimeout(poll, LiveDataRefreshInterval);
                },
            });
        }

        timeout = setTimeout(poll, LiveDataRefreshInterval);

        return () => {
            clearTimeout(timeout);
            currentSubscription?.unsubscribe();
        };
    }, [deviceId, environment, timeRange]);

    if (!data.device) {
        // This should never happen as the DeviceLayout validates devices
        captureMessage('Assertion failed: device is null in LoadContent', scope => {
            scope.setExtra('data', data);
            scope.setTag('Component', 'LoadContent');
            return scope;
        });
        return null;
    }

    let primaryDevice: Device = data.device;
    let secondaryDevice: Device | undefined | null = data.device.dualPlaneCompanion?.device;

    // Order alphabetically
    if (secondaryDevice && secondaryDevice.name < primaryDevice.name) {
        [primaryDevice, secondaryDevice] = [secondaryDevice, primaryDevice];
    }

    return (
        <div className='grid grid-cols-2 gap-4'>
            {primaryDevice && (
                <DevicePane title={primaryDevice.name} subtitle={primaryDevice.type.displayName}>
                    <DeviceLoad device={primaryDevice} deviceId={primaryDevice.id} timeRange={timeRange} />
                </DevicePane>
            )}
            {(secondaryDevice && (
                <DevicePane title={secondaryDevice.name} subtitle={secondaryDevice.type.displayName}>
                    <DeviceLoad device={secondaryDevice} deviceId={secondaryDevice.id} timeRange={timeRange} />
                </DevicePane>
            )) || <WrappedDevicePlaceholder deviceId={primaryDevice.id} />}
        </div>
    );
};

export const ContentQuery = graphql`
    query LoadContentQuery($id: ID!, $start: Timestamp!, $end: Timestamp!) {
        device(id: $id) {
            id
            name
            type {
                displayName
            }
            ...DeviceLoad_device

            dualPlaneCompanion {
                device {
                    id
                    name
                    type {
                        displayName
                    }
                    ...DeviceLoad_device
                }
            }
        }
    }
`;

export function loadLoadPageData(id: string) {
    const now = DateTime.local();

    return loadQuery(
        getGlobalEnvironment(),
        ContentQuery,
        {
            id,
            start: now.minus(DefaultTimeRange).toISO(),
            end: now.toISO(),
        },
        {
            fetchPolicy: 'store-and-network',
        }
    );
}
