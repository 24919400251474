/**
 * @generated SignedSource<<08b2c61256309452760a31602d6714cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DataDisplayCombinedBase_test$data = {
  readonly commencedTime: string | null;
  readonly completedTime: string | null;
  readonly device: {
    readonly name: string;
  };
  readonly id: string;
  readonly " $fragmentType": "DataDisplayCombinedBase_test";
};
export type DataDisplayCombinedBase_test$key = {
  readonly " $data"?: DataDisplayCombinedBase_test$data;
  readonly " $fragmentSpreads": FragmentRefs<"DataDisplayCombinedBase_test">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DataDisplayCombinedBase_test",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Device",
      "kind": "LinkedField",
      "name": "device",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commencedTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completedTime",
      "storageKey": null
    }
  ],
  "type": "DeviceBatteryTestResults",
  "abstractKey": null
};

(node as any).hash = "cea0599d643d9ac4e6ea0655069054ce";

export default node;
