import React, { FC, useEffect, useMemo, useState } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';

import { Button, Modal, ThemedPageHeading } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { DeviceTableColumn, deviceToFilterObject, useDeviceFilter } from 'filters/device';
import { SortDirection, useTableReducer } from 'layouts';
import { CoreTableLayout } from 'layouts/TableLayout/CoreTableLayout';
import { AllTableColumns, BaseTableColumns } from 'views/manage/device/settings';

import {
    DeviceOrdering,
    DeviceSelectionModalQuery,
    DeviceSelectionModalQuery$data,
    DeviceSelectionModalQuery$variables,
    DeviceSortField,
} from './__generated__/DeviceSelectionModalQuery.graphql';
import { fetchAllDeviceIds } from './lib/select-all';
import style from './style.module.css';

type DeviceResults = DeviceSelectionModalQuery$data['devices'];

export interface DeviceSelectionModalProps {
    selectedDevices: string[];
    onConfirm: (selection: string[]) => void;

    open?: boolean;
    onClose: () => void;
}

export const DeviceSelectionModal: FC<DeviceSelectionModalProps> = ({ selectedDevices, onConfirm, open, onClose }) => {
    const environment = useRelayEnvironment();

    const [isFetching, setIsFetching] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [data, setData] = useState<DeviceResults>();

    const [tableState, dispatchTableState] = useTableReducer<DeviceTableColumn>({
        defaultSortColumn: DeviceTableColumn.Name,
        defaultSortDirection: SortDirection.Ascending,
        allColumns: AllTableColumns.map(column => column.id),
        defaultVisibleColumns: BaseTableColumns.map(column => column.id),
        storageKeyPrefix: 'device-selection-table',
        initialSelection: selectedDevices,
    });

    const [filters, dispatchFilters] = useDeviceFilter();
    const filterObject = useMemo(() => deviceToFilterObject(filters), [filters]);

    useEffect(() => {
        if (!open) {
            return;
        }

        const sortObject: DeviceOrdering = {
            field: tableState.sortColumn as DeviceSortField,
            dir: tableState.sortDirection === SortDirection.Ascending ? 'Asc' : 'Desc',
        };

        const refetchVariables: DeviceSelectionModalQuery$variables = {
            page: tableState.page,
            orderBy: sortObject,
            filter: filterObject,
            withMonitorOnly: tableState.visibleColumnsInOrder.includes(DeviceTableColumn.MonitorOnly),
            withSnmpVersion: tableState.visibleColumnsInOrder.includes(DeviceTableColumn.SnmpVersion),
            withBatteryStatus: tableState.visibleColumnsInOrder.includes(DeviceTableColumn.BatteryStatus),
            withDeviceHealth: tableState.visibleColumnsInOrder.includes(DeviceTableColumn.DeviceStatus),
            withBatteryStringCount: tableState.visibleColumnsInOrder.includes(DeviceTableColumn.BatteryStringCount),
            withBatteryTemperature: tableState.visibleColumnsInOrder.includes(DeviceTableColumn.BatteryTemperature),
            withBatteryCapacityRemaining: tableState.visibleColumnsInOrder.includes(
                DeviceTableColumn.BatteryCapacityRemaining
            ),
            withBatteryEnergyTotal: tableState.visibleColumnsInOrder.includes(DeviceTableColumn.BatteryEnergyTotal),
            withBatteryReserveTime: tableState.visibleColumnsInOrder.includes(DeviceTableColumn.BatteryReserveTime),
            withBatteryStateOfHealth: tableState.visibleColumnsInOrder.includes(DeviceTableColumn.BatteryStateOfHealth),
        };

        setIsFetching(true);
        setHasError(false);

        fetchQuery<DeviceSelectionModalQuery>(environment, TableQuery, refetchVariables).subscribe({
            next(value) {
                setData(value.devices);
                setIsFetching(false);
            },
            error(error: unknown) {
                captureException(error, scope => {
                    scope.setExtra('variables', refetchVariables);
                    scope.setTag('Component', 'DeviceSelectionModal');
                    return scope;
                });
                setHasError(true);
            },
        });
    }, [
        environment,
        filterObject,
        filters,
        open,
        tableState.page,
        tableState.sortColumn,
        tableState.sortDirection,
        tableState.visibleColumnsInOrder,
    ]);

    return (
        <Modal bgColor='bg-white' className={style.modal} open={!!open} onHide={onClose} closeButton>
            {data && (
                <div>
                    <div>
                        <ThemedPageHeading value='Select devices' />
                        <CoreTableLayout
                            tableSize='compact'
                            data={data.data}
                            columns={BaseTableColumns}
                            getRowId={row => row.id}
                            filterState={filters}
                            dispatchFilterState={dispatchFilters}
                            tableState={tableState}
                            dispatchTableState={dispatchTableState}
                            selection
                            onRequestAllIds={() => fetchAllDeviceIds(environment, filterObject)}
                            tableVariant='white'
                            clickBehaviour='select'
                            hasError={hasError}
                            // onRetry={}
                            isProcessing={isFetching}
                            emptyMessage='There are no devices present'
                            page={tableState.page}
                            pageCount={data.pageInfo.total}
                            allowEditingColumns
                        />
                    </div>
                    <div className='space-x-2 pt-4'>
                        <Button
                            buttonText='Select devices'
                            onClick={() => {
                                onConfirm(tableState.selectedItems);
                                onClose();
                            }}
                            disabled={tableState.selectedItems.length === 0}
                        />
                        <Button buttonText='Cancel' variant='gray' onClick={onClose} />
                    </div>
                </div>
            )}
        </Modal>
    );
};

const TableQuery = graphql`
    query DeviceSelectionModalQuery(
        $page: Int = 1
        $orderBy: DeviceOrdering
        $filter: DeviceFilter
        $withMonitorOnly: Boolean = false
        $withSnmpVersion: Boolean = false
        $withBatteryStatus: Boolean = false
        $withDeviceHealth: Boolean = false
        $withBatteryStringCount: Boolean = false
        $withBatteryTemperature: Boolean = false
        $withBatteryReserveTime: Boolean = false
        $withBatteryStateOfHealth: Boolean = false
        $withBatteryCapacityRemaining: Boolean = false
        $withBatteryEnergyTotal: Boolean = false
    ) {
        devices(page: $page, orderBy: $orderBy, filters: $filter, pageSize: 10) {
            total
            data {
                id
                name
                type {
                    displayName
                }
                site {
                    name
                    address {
                        state
                    }
                }
                monitorOnly @include(if: $withMonitorOnly)
                health @include(if: $withDeviceHealth)
                connectionSettings @include(if: $withSnmpVersion) {
                    protocols {
                        ... on ProtocolSnmp {
                            version
                        }
                    }
                }
                battery {
                    reserveTime @include(if: $withBatteryReserveTime)
                    metrics {
                        latestStatus @include(if: $withBatteryStatus)
                        latestTemperature @include(if: $withBatteryTemperature)
                        latestStateOfHealth @include(if: $withBatteryStateOfHealth)
                        latestRemainingCapacity @include(if: $withBatteryCapacityRemaining)
                        latestTotalEnergy @include(if: $withBatteryEnergyTotal)
                    }
                    strings {
                        count @include(if: $withBatteryStringCount)
                    }
                }
            }
            pageInfo {
                page
                total
            }
        }
    }
`;
