/**
 * @generated SignedSource<<2576222752bed86f3f18fa62f9c19bee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type dynamic_loadBatteryTypeAttributeNamesQuery$variables = {};
export type dynamic_loadBatteryTypeAttributeNamesQuery$data = {
  readonly attributeNames: ReadonlyArray<string>;
};
export type dynamic_loadBatteryTypeAttributeNamesQuery = {
  response: dynamic_loadBatteryTypeAttributeNamesQuery$data;
  variables: dynamic_loadBatteryTypeAttributeNamesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "limit",
        "value": 1000
      },
      {
        "kind": "Literal",
        "name": "only",
        "value": "Battery"
      },
      {
        "kind": "Literal",
        "name": "search",
        "value": ""
      },
      {
        "kind": "Literal",
        "name": "status",
        "value": "Active"
      }
    ],
    "kind": "ScalarField",
    "name": "attributeNames",
    "storageKey": "attributeNames(limit:1000,only:\"Battery\",search:\"\",status:\"Active\")"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "dynamic_loadBatteryTypeAttributeNamesQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "dynamic_loadBatteryTypeAttributeNamesQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "c547ffe529df757223b568ca0c86da2f",
    "id": null,
    "metadata": {},
    "name": "dynamic_loadBatteryTypeAttributeNamesQuery",
    "operationKind": "query",
    "text": "query dynamic_loadBatteryTypeAttributeNamesQuery {\n  attributeNames(search: \"\", only: Battery, limit: 1000, status: Active)\n}\n"
  }
};
})();

(node as any).hash = "2acc7312b5ad878009dc1b4b19c76423";

export default node;
