import React, { FC } from 'react';

import { CommonFilterProps, RangeFilter } from 'filters/common';

import { TestResultFilterRange } from './TestResultFilterRange';

export const TestResultBatteryStringFilterUI: FC<CommonFilterProps<RangeFilter | null>> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    return (
        <TestResultFilterRange
            current={current}
            onClearOrBack={onClearOrBack}
            onClose={onClose}
            onSubmit={onSubmit}
            title='Filter by Battery String Count'
            distributionType='BatteryStringCount'
            discrete
            showBack={hasPrevious}
        />
    );
};
