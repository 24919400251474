import React, { ReactNode } from 'react';

import { StepItemType } from '@accesstel/pcm-ui';

import { FormikErrors } from 'formik';
import { BatteryTechnologyType } from 'lib/provision';
import { getBatteryName } from 'lib/textFormatters';
import { set } from 'lodash';
import { DateTime } from 'luxon';

import { BatteryTestScheduleErrorType } from './__generated__/mutations_AddBatteryTestTaskMutation.graphql';
import { ScheduleTestFormValues } from './schema';

export interface DropdownType {
    id: string;
    text: string;
}

export type RepeatTestType = 'Never' | 'Monthly' | 'BiMonthly' | 'Quarterly';
export interface RepeatTestDropdownType {
    id: RepeatTestType;
    text: string;
}

export type BatteryTestType = 'Capacity' | 'Quick' | 'Custom';

export interface TestTypeDropdownType {
    id: BatteryTestType;
    text: string;
}

export interface BatteryTypeWithEodvs {
    id: string;
    type: string;
    eodvs: number[];
    count: number;
}

export const stepperItems: StepItemType[] = [
    {
        buttonText: '1',
        label: 'Select device(s)',
    },
    {
        buttonText: '2',
        label: 'Settings',
    },
    {
        buttonText: '3',
        label: 'Confirmation',
    },
    {
        buttonText: '4',
        label: 'Done',
    },
];

export const RepeatTestOptionsById: Record<RepeatTestType, RepeatTestDropdownType> = {
    Never: {
        id: 'Never',
        text: 'Do not repeat',
    },
    Monthly: {
        id: 'Monthly',
        text: 'Monthly',
    },
    BiMonthly: {
        id: 'BiMonthly',
        text: 'Bi-monthly',
    },
    Quarterly: {
        id: 'Quarterly',
        text: 'Quarterly',
    },
};

export const RepeatTestOptions: RepeatTestType[] = ['Never', 'Monthly', 'BiMonthly', 'Quarterly'];

export const TestTypeOptionsById: Record<BatteryTestType, TestTypeDropdownType> = {
    Capacity: {
        id: 'Capacity',
        text: 'Capacity Test',
    },
    Quick: {
        id: 'Quick',
        text: 'Quick Test',
    },
    Custom: {
        id: 'Custom',
        text: 'Custom Test',
    },
};

export const TestTypeOptions: BatteryTestType[] = ['Capacity', 'Custom', 'Quick'];

export enum ActiveStepStates {
    SelectDevices,
    Settings,
    Confirmation,
    Done,
}

export function renderItem(item: DropdownType): string {
    return item.text;
}

export function generateTestName(numberOfDevices: number): string {
    return `Battery test - ${numberOfDevices} ${numberOfDevices === 1 ? 'Device' : 'Devices'}`;
}

export function getInitialStartTime(): Date {
    const defaultStartTime = DateTime.now();
    defaultStartTime.plus({ hour: 1 });
    defaultStartTime.set({ minute: 0 });
    return defaultStartTime.toJSDate();
}

export function getTestTypeDescription(type: BatteryTestType, divStyle?: string, withHighlights = false): ReactNode[] {
    const description: ReactNode[] = [];

    switch (type) {
        case 'Capacity':
            description.push(
                <div className={divStyle}>
                    A <span className={withHighlights ? 'font-bold' : undefined}>Capacity Test</span> will discharge the
                    batteries down to a voltage specified by the battery discharge tables.
                </div>
            );
            description.push(
                <div className={divStyle}>
                    You can get a battery SoH (State of Health) and Estimated Capacity using this test type.
                </div>
            );
            break;
        case 'Quick':
            description.push(
                <div className={divStyle}>
                    A <span className={withHighlights ? 'font-bold' : undefined}>Quick Test</span> will shallowly
                    discharge the batteries to quickly check that the batteries can take the load
                </div>
            );
            description.push(
                <div className={divStyle}>This test cannot produce a SoH (State of Health) or estimated capacity</div>
            );
            description.push(
                <div className={divStyle}>
                    This test will fail a battery if it could not sustain the load for the given run time without
                    falling below the voltage threshold'
                </div>
            );
            break;
        case 'Custom':
            description.push(
                <div className={divStyle}>
                    A <span className={withHighlights ? 'font-bold' : undefined}>Custom Test</span> allows you to
                    specify all the end conditions for the test
                </div>
            );
            description.push(
                <div className={divStyle}>
                    If the test has a deep enough discharge, you can get a SoH (State of Health) and estimated capacity
                    value
                </div>
            );
            break;
        default:
            break;
    }

    return description;
}

export function setSettingsError(
    problem: BatteryTestScheduleErrorType,
    setFieldError: (field: string, message: string) => void
): void {
    switch (problem) {
        case 'InvalidQuickThreshold':
            setFieldError('quickSettings.minAllowedVoltage', 'Invalid Threshold');
            break;
        case 'InvalidQuickReserveTime':
            setFieldError('quickSettings.reserveTime', 'Invalid Reserve Time');
            break;
        case 'InvalidQuickNoDefinedReserve':
            setFieldError('quickSettings.reserveTime', 'Invalid Reserve Time');
            break;
        case 'InvalidCustomDuration':
            setFieldError('customSettings.maxDuration', 'Invalid Duration');
            break;
        case 'InvalidCustomDischarged':
            setFieldError('customSettings.maxDischarge', 'Invalid Discharge Value');
            break;
        case 'InvalidCustomMinVoltage':
            setFieldError('customSettings.eodv', 'Invalid End of Discharge Voltage');
            break;
        case 'InvalidCapacityEodv':
        case 'InvalidCapacityBatteryInsufficient':
        case 'InvalidCapacityBattery':
            setFieldError('capacitySettings.targets[0]', 'Invalid End of Discharge Voltage');
            break;
        case 'MissingDevices':
            setFieldError('name', 'No device selected');
            break;
        case 'InvalidDevice':
            setFieldError('name', 'Invalid device selected');
            break;
        case 'InvalidCustomMissingRequired':
            setFieldError('customSettings.eodv', 'One of these fields must be set');
            setFieldError('customSettings.maxDuration', 'One of these fields must be set');
            setFieldError('customSettings.maxDischarge', 'One of these fields must be set');
            break;
        case 'InvalidCapacityMissingBattery':
            setFieldError('name', 'Device(s) selected must have a battery linked');
            break;
        case 'InvalidName':
            setFieldError('name', 'Invalid Test Name');
            break;
        case 'InvalidScheduleTime':
            setFieldError('scheduleTime', 'Invalid device selected');
            break;
        case 'DeviceBatteryMissing':
            setFieldError('name', 'All device(s) selected must have a battery linked');
            break;
    }
}

export interface SettingsValuePair {
    name: string;
    value: string;
}
export interface BatteryTestSettings {
    targets?: { endOfDischargeVoltage: number; batteryType: { id: string; manufacturer: string; model: string } }[];
    reserveTime?: number;
    threshold?: number;
    endOfDichargeTable?: number;
    endOfDischargeVoltage?: number;
    maxDuration?: number;
    maxDischarged?: number;
}

export function getBatteryTestSettingsParameter(
    type: BatteryTestType,
    settings: BatteryTestSettings
): SettingsValuePair[] {
    const valuePair = [] as SettingsValuePair[];
    const { maxDuration, maxDischarged, endOfDischargeVoltage, reserveTime, threshold, targets } = settings;

    switch (type) {
        case 'Capacity':
            if (targets) {
                targets.forEach(target => {
                    valuePair.push({
                        name: 'Battery Type',
                        value: `${target.batteryType.manufacturer} ${target.batteryType.model}`,
                    });
                    valuePair.push({
                        name: 'Cutoff Voltage',
                        value: `${target.endOfDischargeVoltage}Vpc`,
                    });
                });
            }
            return valuePair;
        case 'Custom':
            valuePair.push({ name: 'Duration Limit', value: `${maxDuration ? `${maxDuration} minutes` : '--'}` });
            valuePair.push({
                name: 'Cutoff Voltage',
                value: `${endOfDischargeVoltage ? `${endOfDischargeVoltage}V` : '--'}`,
            });
            valuePair.push({ name: 'Discharge Limit', value: `${maxDischarged ? `${maxDischarged} Ah` : '--'}` });
            return valuePair;
        case 'Quick':
            valuePair.push({ name: 'Reserve Time', value: `${reserveTime}%` });
            valuePair.push({ name: 'Cutoff Voltage', value: `${threshold ? `${threshold}V` : '--'}` });
            return valuePair;
        default:
            return valuePair;
    }
}

export const describeEodvRange = (min: number, max: number): string => {
    return `EODV value must be between ${Math.ceil(min * 100) / 100} and ${Math.ceil(max * 100) / 100}`;
};

export interface DeviceWithName {
    id: string;
    name: string;
    site: {
        id: string;
        name: string;
    };
}
export interface BatteryStringType {
    id: string;
    manufacturer: string;
    model: string;
    dischargeTables: {
        endOfDischargeVoltage: number;
    }[];
    cells: {
        allowedVoltage?: {
            minimum?: number | null;
            maximum?: number | null;
        } | null;
    };
    technology: BatteryTechnologyType;
}
export interface BatteryString {
    cellsPerString: number;
    type: BatteryStringType;
}

export interface DeviceWithBattery extends DeviceWithName {
    battery: {
        strings: {
            count: number;
            strings: BatteryString[];
        };
        allowedVoltage?: {
            minimum?: number | null;
            maximum?: number | null;
        } | null;
        reserveTime?: number | null;
        quickTestFailThreshold?: number | null;
    };
}

export const validateSettings = (values: ScheduleTestFormValues, selectedDeviceList: DeviceWithBattery[]) => {
    const errors: FormikErrors<ScheduleTestFormValues> = {};
    const { type, customSettings, quickSettings } = values;

    const allowedRangeAllDevices = [
        Math.max(
            ...selectedDeviceList
                .filter(({ battery }) => battery.allowedVoltage?.minimum)
                .map(({ battery }) => battery.allowedVoltage!.minimum!)
        ),
        Math.min(
            ...selectedDeviceList
                .filter(({ battery }) => battery.allowedVoltage?.maximum)
                .map(({ battery }) => battery.allowedVoltage!.maximum!)
        ),
    ];
    const batteryTypesAllowedRange: { minimum: number; maximum: number }[] = [];
    selectedDeviceList
        .filter(device =>
            device.battery.strings.strings.every(
                string =>
                    string.type.cells.allowedVoltage &&
                    string.type.cells.allowedVoltage.minimum &&
                    string.type.cells.allowedVoltage.maximum
            )
        )
        .forEach(device => {
            device.battery.strings.strings.forEach(string => {
                batteryTypesAllowedRange.push({
                    minimum: string.cellsPerString * string.type.cells.allowedVoltage!.minimum!,
                    maximum: string.cellsPerString * string.type.cells.allowedVoltage!.maximum!,
                });
            });
        });
    const allowedRangeAllBatteries = [
        Math.max(...batteryTypesAllowedRange.map(range => range.minimum)),
        Math.min(...batteryTypesAllowedRange.map(range => range.maximum)),
    ];

    const combinedAllowedRange = [
        Math.max(allowedRangeAllBatteries[0], allowedRangeAllDevices[0]),
        Math.min(allowedRangeAllBatteries[1], allowedRangeAllDevices[1]),
    ];

    // if the min we got exceeds the max, that tells us that the devices has no overlapping
    const eodvRangeDescription =
        combinedAllowedRange[0] < combinedAllowedRange[1]
            ? describeEodvRange(combinedAllowedRange[0], combinedAllowedRange[1])
            : 'There is no overlap in the allowed voltage ranges for the selected devices';

    const numberOfDeviceToShow = 2;

    // combinedAllowedRange can be [-Infinity, Infinity, need to have this check
    const isAllowedRangeFinite = combinedAllowedRange.every(bound => isFinite(bound));

    if (type === 'Capacity') {
        const devicesWithNoBattery = selectedDeviceList.filter(device => device.battery.strings.count === 0);
        if (devicesWithNoBattery.length > 0) {
            const prefix = `Device ${devicesWithNoBattery
                .map(({ name }) => name)
                .slice(0, numberOfDeviceToShow)
                .join(', ')} ${
                devicesWithNoBattery.length > numberOfDeviceToShow
                    ? ` and ${devicesWithNoBattery.length - numberOfDeviceToShow} more device(s)`
                    : ''
            }`;

            set(errors, 'type', `${prefix} do not have a battery with discharge table`);
        }
    }

    if (type === 'Custom') {
        if (customSettings?.maxDuration === null && customSettings.maxDischarge === '' && customSettings.eodv === '') {
            set(errors, 'customSettings.eodv', 'One of these fields must be set');
            set(errors, 'customSettings.maxDuration', 'One of these fields must be set');
            set(errors, 'customSettings.maxDischarge', 'One of these fields must be set');
        }

        // Validate falls within all battery types min and max allowed voltage.
        if (
            customSettings?.eodv !== '' &&
            isAllowedRangeFinite &&
            (Number(customSettings?.eodv) < combinedAllowedRange[0] ||
                Number(customSettings?.eodv) > combinedAllowedRange[1])
        ) {
            set(errors, 'customSettings.eodv', eodvRangeDescription);
        }

        // validate maxDuration
        if (customSettings?.maxDuration && customSettings?.maxDuration.as('milliseconds') <= 0) {
            set(errors, 'customSettings.maxDuration', 'Not a valid duration');
        }
    }

    if (type === 'Quick') {
        // Validate falls within all battery types min and max allowed voltage.
        const deviceWithNoReserveTime = selectedDeviceList.filter(
            device => device.battery.reserveTime === null || device.battery.reserveTime === undefined
        );
        if (deviceWithNoReserveTime.length > 0) {
            const prefix = `Device ${deviceWithNoReserveTime
                .map(({ name }) => name)
                .slice(0, numberOfDeviceToShow)
                .join(', ')} ${
                deviceWithNoReserveTime.length > numberOfDeviceToShow
                    ? ` and ${deviceWithNoReserveTime.length - numberOfDeviceToShow} more device(s)`
                    : ''
            }`;
            set(errors, 'quickSettings.reserveTime', `${prefix} do not have a reserve time set`);
        }
        if (
            quickSettings?.minAllowedVoltage !== '' &&
            isAllowedRangeFinite &&
            (Number(quickSettings?.minAllowedVoltage) < combinedAllowedRange[0] ||
                Number(quickSettings?.minAllowedVoltage) > combinedAllowedRange[1])
        ) {
            set(errors, 'quickSettings.minAllowedVoltage', eodvRangeDescription);
        }
        // If omitted, all devices must have the fail threshold set in their battery info
        if (quickSettings?.minAllowedVoltage === '') {
            const batteryTypeFailThreshold = selectedDeviceList.map(({ battery }) => battery.quickTestFailThreshold);
            if (batteryTypeFailThreshold.includes(null) || batteryTypeFailThreshold.includes(undefined)) {
                set(errors, 'quickSettings.minAllowedVoltage', 'This is required');
            }
        }
    }

    return errors;
};

export const setFieldTouchedOnTestSettings = (
    type: BatteryTestType,
    batteryTypes: BatteryTypeWithEodvs[],
    setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void
) => {
    if (type === 'Capacity') {
        batteryTypes.forEach((_, idx) => {
            setFieldTouched(`capacitySettings.targets[${idx}].eodv`, true, false);
        });
    }
    if (type === 'Custom') {
        setFieldTouched('customSettings.eodv', true);
        setFieldTouched('customSettings.maxDuration', true);
        setFieldTouched('customSettings.maxDischarge', true);
    }
    if (type === 'Quick') {
        setFieldTouched('quickSettings.reserveTime', true);
        setFieldTouched('quickSettings.minAllowedVoltage', true);
    }
};

export const getBatteryTypesWithEodv = (selectedDevices: DeviceWithBattery[]): BatteryTypeWithEodvs[] => {
    const batteryWithDevices = new Map<string, Set<DeviceWithBattery>>();
    for (const device of selectedDevices) {
        device.battery.strings.strings.forEach(batteryString => {
            const { id } = batteryString.type;
            const devices = batteryWithDevices.get(id) ?? new Set<DeviceWithBattery>();
            batteryWithDevices.set(id, devices.add(device));
        });
    }
    const batteryIds = Array.from(batteryWithDevices.keys());

    const batteryNames = new Map<string, string>();
    batteryIds.forEach(batteryId => {
        for (const device of selectedDevices) {
            for (const string of device.battery.strings.strings) {
                if (string.type.id === batteryId) {
                    batteryNames.set(batteryId, getBatteryName(string.type.manufacturer, string.type.model));
                }
            }
        }
    });

    const batteryTypesWithEodv: BatteryTypeWithEodvs[] = [];
    batteryIds.forEach(batteryId => {
        const devices = Array.from(batteryWithDevices.get(batteryId) ?? new Set<DeviceWithBattery>());
        const battery = devices[0].battery.strings.strings.find(string => string.type.id === batteryId);
        batteryTypesWithEodv.push({
            id: batteryId,
            type: batteryNames.get(batteryId)!,
            // also sort in DESC
            eodvs: battery
                ? battery.type.dischargeTables.map(table => table.endOfDischargeVoltage).sort((a, b) => b - a)
                : [],
            count: devices.length,
        });
    });

    return batteryTypesWithEodv;
};
