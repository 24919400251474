import React, { ReactNode } from 'react';

import { createColumnHelper } from '@tanstack/react-table';
import { UserTableColumn } from 'filters/user';
import { ColumnWithId } from 'layouts';

import type { ManageUsersTableQuery$data } from './__generated__/ManageUsersTableQuery.graphql';

type User = ManageUsersTableQuery$data['users']['data'][number];

function renderRoles(result: User): ReactNode {
    if (result.type === 'External') {
        return <div className='italic'>Provided from external source</div>;
    }

    return result.roles?.join(', ');
}

const columnHelper = createColumnHelper<User>();

export const NameColumn = columnHelper.accessor('name', {
    id: UserTableColumn.Name,
    header: 'NAME',
    meta: {
        maxWidth: '30rem',
    },
});
export const EmailColumn = columnHelper.accessor('email', {
    id: UserTableColumn.Email,
    header: 'EMAIL',
    meta: {
        maxWidth: '15rem',
    },
});
export const RolesColumn = columnHelper.display({
    id: UserTableColumn.Roles,
    header: 'ROLES',
    cell: ({ row }) => renderRoles(row.original),
});

export const TableColumns = [NameColumn, EmailColumn, RolesColumn] as ColumnWithId<UserTableColumn, User>[];
