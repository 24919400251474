import React, { FC } from 'react';

import { FilterSingleSelect } from 'components/FilterSingleSelect';
import { CommonFilterProps } from 'filters/common';

import { IsAlertActive } from '../types';

export type AlertIsActiveFilterUIProps = CommonFilterProps<IsAlertActive | null>;

export const AlertIsActiveFilterUI: FC<AlertIsActiveFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
}) => {
    const options = [IsAlertActive.Yes, IsAlertActive.No];

    return (
        <FilterSingleSelect
            options={options}
            active={current ?? undefined}
            title='Filter by alerts that are active'
            renderItem={item => item}
            onClear={onClearOrBack}
            onSubmit={item => onSubmit(item)}
            onClose={onClose}
            showBack={true}
        />
    );
};
