/**
 * @generated SignedSource<<84b45f25bbc7b476371ba3392c8b10bc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SelectDevicesPaneContentQuery$variables = {
  deviceIds: ReadonlyArray<string>;
};
export type SelectDevicesPaneContentQuery$data = {
  readonly devices: {
    readonly data: ReadonlyArray<{
      readonly dualPlaneCompanion: {
        readonly device: {
          readonly id: string;
        } | null;
      } | null;
      readonly id: string;
      readonly site: {
        readonly devices: {
          readonly data: ReadonlyArray<{
            readonly id: string;
          }>;
        };
        readonly id: string;
      };
    }>;
  };
};
export type SelectDevicesPaneContentQuery = {
  response: SelectDevicesPaneContentQuery$data;
  variables: SelectDevicesPaneContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deviceIds"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "ids",
            "variableName": "deviceIds"
          }
        ],
        "kind": "ObjectValue",
        "name": "filters"
      }
    ],
    "concreteType": "PaginatedDevices",
    "kind": "LinkedField",
    "name": "devices",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Site",
            "kind": "LinkedField",
            "name": "site",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PaginatedDevices",
                "kind": "LinkedField",
                "name": "devices",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Device",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": true,
                    "selections": (v2/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DualPlaneCompanion",
            "kind": "LinkedField",
            "name": "dualPlaneCompanion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "device",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectDevicesPaneContentQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SelectDevicesPaneContentQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "94d968f8e57373b86268930465de6487",
    "id": null,
    "metadata": {},
    "name": "SelectDevicesPaneContentQuery",
    "operationKind": "query",
    "text": "query SelectDevicesPaneContentQuery(\n  $deviceIds: [ID!]!\n) {\n  devices(filters: {ids: $deviceIds}) {\n    data {\n      id\n      site {\n        id\n        devices {\n          data {\n            id\n          }\n        }\n      }\n      dualPlaneCompanion {\n        device {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "42e31d968ab90c3c153344564e7cc73b";

export default node;
