import React, { FC, useEffect, useState } from 'react';

import { Button, ChevronRightIcon } from '@accesstel/pcm-ui';

import { useFormikContext } from 'formik';

import {
    ActiveStepStates,
    BatteryTypeWithEodvs,
    DeviceWithBattery,
    generateTestName,
    setFieldTouchedOnTestSettings,
} from '../../common';
import { ScheduleTestFormValues } from '../../schema';
import style from '../../style.module.css';
import { TestDetails } from './components';

interface ConfirmationProps {
    selectedDevices: DeviceWithBattery[];
    batteryTypes: BatteryTypeWithEodvs[];
    setActiveStep: React.Dispatch<React.SetStateAction<number>>;
    setTaskID: React.Dispatch<React.SetStateAction<string | undefined>>;
    setStepperDone: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Confirmation: FC<ConfirmationProps> = ({ selectedDevices, batteryTypes, setActiveStep }) => {
    const { values, isSubmitting, isValid, setFieldTouched, submitForm } = useFormikContext<ScheduleTestFormValues>();

    const [disableButton, setDisableButton] = useState(false);

    useEffect(() => {
        if (selectedDevices.length === 0) {
            setDisableButton(true);
        } else {
            setDisableButton(false);
        }
    }, [selectedDevices]);

    useEffect(() => {
        setFieldTouchedOnTestSettings(values.type, batteryTypes, setFieldTouched);

        if (!isValid) {
            setActiveStep(ActiveStepStates.Settings);
        }
    }, [batteryTypes, isValid, setActiveStep, setFieldTouched, values.type]);

    const finalTestName = values.name ?? generateTestName(selectedDevices.length);

    const handleBackButtonClick = () => setActiveStep(ActiveStepStates.Settings);

    return (
        <>
            <div className={`${style.confirmation_heading} font-CynthoNext-SemiBold text-customEggplant`}>
                {finalTestName}
            </div>
            <div className='flex flex-col justify-between flex-grow'>
                <TestDetails selectedDevices={selectedDevices} batteryTypes={batteryTypes} />
            </div>
            <div className={style.buttons_container}>
                <Button buttonText='Edit settings' variant='gray' onClick={handleBackButtonClick} />
                <div className={disableButton ? 'cursor-not-allowed' : ''}>
                    <Button
                        // FIXME: Wrap Schedule component inside <Form /> comnponent so that this button submit trigger the form onSubmit (Depends on PCM-1571 table rebuild)
                        // make the button type to submit and remove onClick
                        // type='submit'
                        onClick={() => submitForm()}
                        disabled={disableButton}
                        processing={isSubmitting}
                        icon={<ChevronRightIcon />}
                        buttonText='Schedule test'
                        buttonClasses={disableButton ? 'pointer-events-none' : ''}
                    />
                </div>
            </div>
        </>
    );
};
