import React, { FC, useEffect, useState } from 'react';
import { useFragment } from 'react-relay';

import {
    AmmeterIcon,
    BarDataType,
    ListIcon,
    Menu,
    MetricDisplay,
    MetricViewFrame,
    RectifierIcon,
    StackedHorizontalBar,
    ThermometerIcon,
    VoltmeterIcon,
    useExtendedNavigate,
} from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { IconWithStatus } from 'components';
import humanizeDuration, { Unit } from 'humanize-duration';
import { useUserPermissions } from 'lib/auth';
import { numberToLocaleString, percentageToStringCapped, wattToKillowattString } from 'lib/numberFormatters';
import { Paths } from 'lib/routes';
import { Duration } from 'luxon';

import { makeLinkToMetric } from '../../../../explore/lib/link';
import { Operation } from '../../../../explore/types';
import { DeviceRectifiers_device$key } from './__generated__/DeviceRectifiers_device.graphql';
import { ModuleRow, ModuleTile } from './components';

enum Mode {
    Tiles = 'tiles',
    List = 'list',
}

export interface DeviceRectifiersProps {
    device: DeviceRectifiers_device$key;
    deviceId: string;
    timeRange: Duration;
}

export const DeviceRectifiers: FC<DeviceRectifiersProps> = ({ device, deviceId, timeRange }) => {
    const { hasAssetsWrite } = useUserPermissions();
    const navigate = useExtendedNavigate();
    const data = useFragment(Fragment, device);
    const [displayMode, setDisplayMode] = useState(Mode.Tiles);

    let durationUnits: Unit[];
    if (timeRange.as('days') >= 1) {
        durationUnits = ['h', 'm'];
    } else {
        durationUnits = ['d', 'h', 'm'];
    }

    const humanizeOpts = { largest: 1, round: true, units: durationUnits };
    const timeRangeString = humanizeDuration(timeRange.as('milliseconds'), humanizeOpts);

    const uptime = data.rectifier?.uptime?.percentage ? Math.round(data.rectifier.uptime.percentage) : 0;

    const uptimeBarData: BarDataType[] = [];

    if (uptime > 0) {
        uptimeBarData.push({
            label: 'Online',
            value: uptime,
            bgClass: 'bg-customEggplant',
        });
    }

    if (uptime < 100) {
        uptimeBarData.push({
            label: 'Offline',
            value: 100 - uptime,
            bgClass: 'bg-customCoral',
        });
    }

    let voltageMetric: string;
    let currentMetric: string;
    let temperatureMetric: string;

    if (data.rectifier?.metrics?.latestOutputVoltage) {
        voltageMetric = `${numberToLocaleString(data.rectifier.metrics.latestOutputVoltage)}V`;
    } else {
        voltageMetric = '-V';
    }

    if (data.rectifier?.metrics?.latestOutputCurrent) {
        currentMetric = `${numberToLocaleString(data.rectifier.metrics.latestOutputCurrent)}A`;
    } else {
        currentMetric = '-A';
    }

    if (data.rectifier?.metrics?.latestTemperature) {
        temperatureMetric = `${numberToLocaleString(data.rectifier.metrics.latestTemperature)}°C`;
    } else {
        temperatureMetric = '-°C';
    }

    const capacityBarData: BarDataType[] = [];

    if (data.rectifier?.usedCapacity) {
        capacityBarData.push({
            label: 'Used capacity',
            value: data.rectifier.usedCapacity,
            bgClass: 'bg-customEggplant',
        });
    }

    if (data.rectifier?.freeCapacity) {
        capacityBarData.push({
            label: 'Free capacity',
            value: data.rectifier.freeCapacity,
            bgClass: 'bg-customPine',
        });
    }

    if (data.rectifier?.offlineCapacity) {
        capacityBarData.push({
            label: 'Offline capacity',
            value: data.rectifier.offlineCapacity,
            bgClass: 'bg-customCoral',
        });
    }

    const modules = data.rectifier?.modules?.data ?? [];

    const metricFrameActions = [];

    if (modules.length > 0) {
        metricFrameActions.push({
            buttonIcon: displayMode === Mode.Tiles ? <RectifierIcon /> : <ListIcon />,
            buttonText: displayMode === Mode.Tiles ? 'Tiles' : 'List',
            onClick: () => setDisplayMode(displayMode === Mode.Tiles ? Mode.List : Mode.Tiles),
        });
    }

    useEffect(() => {
        // if we have no modules, switch to list view so that the "Module information is unavailable" is centered
        if (modules.length === 0) {
            setDisplayMode(Mode.List);
        }
    }, [modules.length]);

    return (
        <div className='space-y-4'>
            <div className='flex flex-row gap-4'>
                <div className='font-CynthoNext-SemiBold text-xl'>Rectifiers</div>
                <div className='flex flex-col justify-start items-end flex-grow font-CynthoNext-SemiBold text-xl'>
                    <div>{percentageToStringCapped(uptime)}</div>
                    <div className='text-xs font-CynthoNext-Regular'>Uptime last {timeRangeString}</div>
                </div>
                <div>
                    <Menu
                        id={`device-menu-${deviceId}`}
                        menuItems={[
                            {
                                name: hasAssetsWrite ? 'Edit device' : 'View device',
                                onClick: () => navigate({ pathname: Paths.EditDevice, params: { id: deviceId } }),
                            },
                        ]}
                        variant='small'
                    />
                </div>
            </div>
            <StackedHorizontalBar
                data={uptimeBarData}
                valueFormatter={value => {
                    if (value == null) {
                        return '-';
                    }

                    return humanizeDuration(timeRange.as('milliseconds') * (value / 100), humanizeOpts);
                }}
            />

            <div className='flex flex-row justify-start gap-4 mb-4'>
                <IconWithStatus
                    title='Output Voltage'
                    icon={<VoltmeterIcon />}
                    label={voltageMetric}
                    link={makeLinkToMetric(deviceId, { metric: 'RectifierVoltage', op: Operation.Average })}
                />
                <IconWithStatus
                    title='Output Current'
                    icon={<AmmeterIcon />}
                    label={currentMetric}
                    link={makeLinkToMetric(deviceId, { metric: 'RectifierCurrent', op: Operation.Average })}
                />
                <IconWithStatus
                    title='Average Temperature'
                    icon={<ThermometerIcon />}
                    label={temperatureMetric}
                    link={makeLinkToMetric(deviceId, { metric: 'RectifierTemperature', op: Operation.Average })}
                />
            </div>

            <MetricViewFrame
                title='Modules'
                view={displayMode === Mode.Tiles ? 'grid' : 'list'}
                contentClassName='h-96'
                scroll
                actions={metricFrameActions}
                subtitle={
                    data.rectifier?.totalCapacity !== undefined
                        ? `${wattToKillowattString(data.rectifier?.totalCapacity)} total capacity`
                        : ''
                }
            >
                {modules.map(module =>
                    displayMode === Mode.Tiles ? (
                        <ModuleTile key={module.id} module={module} deviceId={deviceId} />
                    ) : (
                        <ModuleRow key={module.id} module={module} deviceId={deviceId} />
                    )
                )}
                {modules.length === 0 && (
                    <>
                        <div className='invisible' style={{ aspectRatio: '1/1' }}></div>
                        <div className='col-span-4 grid place-items-center'>
                            <div className=''>
                                <span>Module information is unavailable</span>
                            </div>
                        </div>
                        <div className='invisible' style={{ aspectRatio: '1/1' }}></div>
                    </>
                )}
            </MetricViewFrame>

            <StackedHorizontalBar
                data={capacityBarData}
                valueFormatter={value => {
                    if (value == null) {
                        return '-';
                    }

                    return wattToKillowattString(value);
                }}
            />

            <div className='flex flex-row justify-between' data-testid='capacity-metric-display'>
                <MetricDisplay
                    label='Total capacity'
                    value={wattToKillowattString(data.rectifier?.totalCapacity)}
                    size='small'
                />
                <MetricDisplay
                    label='Used capacity'
                    value={wattToKillowattString(data.rectifier?.usedCapacity)}
                    size='small'
                />
                <MetricDisplay
                    label='Free capacity'
                    value={wattToKillowattString(data.rectifier?.freeCapacity)}
                    size='small'
                />
                <MetricDisplay
                    label='Offline capacity'
                    value={wattToKillowattString(data.rectifier?.offlineCapacity)}
                    size='small'
                    valueError={data.rectifier?.offlineCapacity && data.rectifier.offlineCapacity > 0 ? true : false}
                />
            </div>
        </div>
    );
};

const Fragment = graphql`
    fragment DeviceRectifiers_device on Device {
        rectifier {
            uptime(from: $begin, to: $end) {
                percentage
            }
            metrics {
                latestTemperature
                latestOutputVoltage
                latestOutputCurrent
            }
            totalCapacity(unit: Watt)
            freeCapacity(unit: Watt)
            usedCapacity(unit: Watt)
            offlineCapacity(unit: Watt)
            modules {
                data {
                    id
                    ...ModuleTile_data
                    ...ModuleRow_data
                }
            }
        }
    }
`;
