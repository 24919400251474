import React, { FC, useCallback, useEffect } from 'react';

import {
    Button,
    FormikCheckbox,
    FormikDateTimeSelect,
    FormikDropdown,
    FormikTextBox,
    IconWithTooltip,
} from '@accesstel/pcm-ui';

import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { getFormattedDateFromDate, getFormattedTimeFromDate } from 'lib/dateFormatter';

import {
    ActiveStepStates,
    BatteryTestType,
    BatteryTypeWithEodvs,
    DeviceWithBattery,
    RepeatTestOptions,
    RepeatTestOptionsById,
    RepeatTestType,
    TestTypeOptions,
    TestTypeOptionsById,
    generateTestName,
    getTestTypeDescription,
    setFieldTouchedOnTestSettings,
} from '../../common';
import { ScheduleTestFormValues } from '../../schema';
import style from '../../style.module.css';
import { CustomTestFields, FullTestFields, QuickTestFields } from './components';

interface SettingsProps {
    /* selected device list */
    selectedDeviceList: DeviceWithBattery[];
    /* other */
    batteryTypes: BatteryTypeWithEodvs[];
    setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

export const Settings: FC<SettingsProps> = ({ batteryTypes, setActiveStep, selectedDeviceList }) => {
    const { values, setFieldValue, touched, setTouched, setFieldTouched, validateForm } = useFormikContext<
        ScheduleTestFormValues
    >();

    const handleBackButtonClick = () => setActiveStep(ActiveStepStates.SelectDevices);
    const handleForwardButtonClick = () => setActiveStep(ActiveStepStates.Confirmation);

    const ensureTestName = useCallback(() => {
        if (values.name != null) {
            if (values.name.trim() === '') {
                setFieldValue('name', null);
            } else {
                setFieldValue('name', values.name!.trim());
            }
        }
    }, [setFieldValue, values.name]);

    useEffect(() => {
        if (values.name === null || touched.name !== true) {
            setFieldValue('name', generateTestName(selectedDeviceList.length), false);
        }
    }, [selectedDeviceList.length, setFieldValue, touched.name, values.name]);

    return (
        <>
            <div className={style.settings_grid}>
                <div className={style.settings_start_test}>
                    <FormikDateTimeSelect
                        name='scheduleTime'
                        minDate={new Date()}
                        dateFormatter={getFormattedDateFromDate}
                        timeFormatter={getFormattedTimeFromDate}
                        placeHolder='Start test'
                        light
                        testId='start-test'
                        variant='outlined'
                    />
                </div>
                <div className={style.settings_repeat_test}>
                    <FormikDropdown<RepeatTestType>
                        name='scheduleRepeat'
                        light
                        placeHolder='Repeat test'
                        renderItem={item => RepeatTestOptionsById[item].text}
                        items={RepeatTestOptions}
                        testId='repeat-test'
                        variant='outlined'
                    />
                </div>
                <div className={classNames(style.settings_test_name, 'font-CynthoNext-Regular text-customEggplant')}>
                    <FormikTextBox
                        name='name'
                        light
                        placeHolder='Test name'
                        onBlur={ensureTestName}
                        testId='test-name'
                        variant='outlined'
                        onFocus={() => setTouched({ name: true })}
                    />
                </div>
                <div className='flex flex-row items-center space-x-2 col-start-2 row-start-2'>
                    <FormikCheckbox name='isSmartStart' placeHolder='Smart Start' variant='white' />
                    <IconWithTooltip message='Test will delay until device is ready' />
                </div>
                <div className={style.settings_test_type}>
                    <FormikDropdown<BatteryTestType>
                        name='type'
                        light
                        placeHolder='Test type'
                        renderItem={item => TestTypeOptionsById[item].text}
                        items={TestTypeOptions}
                        testId='test-type'
                        variant='outlined'
                    />
                </div>
                <div data-testid='test-description-container' className={style.settings_test_type_description}>
                    {getTestTypeDescription(values.type, 'font-CynthoNext-Regular text-customEggplant pb-2', true).map(
                        (text, i) => (
                            <div key={`test-description-${i}`}>{text}</div>
                        )
                    )}
                </div>
                {values.type === 'Capacity' && <FullTestFields batteryTypes={batteryTypes} />}
                {values.type === 'Quick' && <QuickTestFields />}
                {values.type === 'Custom' && <CustomTestFields />}
            </div>
            <div className={style.buttons_container}>
                <Button buttonText='Edit device selection' variant='gray' onClick={handleBackButtonClick} />
                <Button
                    buttonText='Save and continue'
                    onClick={async () => {
                        setFieldTouchedOnTestSettings(values.type, batteryTypes, setFieldTouched);

                        const errors = await validateForm();
                        if (Object.keys(errors).length > 0) {
                            return;
                        }
                        handleForwardButtonClick();
                    }}
                />
            </div>
        </>
    );
};
