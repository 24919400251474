import React, { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';

import { Button, ThemedPageHeading } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { SelectionWithTestIds } from 'components/SelectTestPane';
import {
    BatteryTestResultsColumn,
    batteryTestResultToFilterObject,
    useTestResultFilter,
} from 'filters/battery-test-results-model';
import { SortDirection, useTableReducer } from 'layouts';
import { CoreTableLayout } from 'layouts/TableLayout/CoreTableLayout';
import { useQuery } from 'lib/query-helpers';
import { IEnvironment } from 'relay-runtime';

import {
    TestSelectModalContentAllIdsQuery,
    TestSelectModalContentAllIdsQuery$variables,
} from './__generated__/TestSelectModalContentAllIdsQuery.graphql';
import {
    TestResultOrdering,
    TestResultSortField,
    TestSelectModalContentQuery,
    TestSelectModalContentQuery$data,
    TestSelectModalContentQuery$variables,
} from './__generated__/TestSelectModalContentQuery.graphql';
import { BaseTableColumns } from './settings';
import style from './style.module.css';

type BatteryTestResults = TestSelectModalContentQuery$data['batteryTestResults']['data'][number];

interface TestSelectModalProps {
    testIds: string[];
    isTestSelectModalOpen: boolean;
    setIsCompareModalOpen: Dispatch<SetStateAction<boolean>>;
    setSelectedTestsCompare: Dispatch<SetStateAction<SelectionWithTestIds | null>>;
}

export const TestSelectModalContent: FC<TestSelectModalProps> = ({
    testIds,
    isTestSelectModalOpen,
    setIsCompareModalOpen,
    setSelectedTestsCompare,
}) => {
    const environment = useRelayEnvironment();

    const [tableState, dispatchTableState] = useTableReducer<BatteryTestResultsColumn>({
        defaultSortColumn: BatteryTestResultsColumn.StartTime,
        defaultSortDirection: SortDirection.Descending,
        allColumns: BaseTableColumns.map(column => column.id),
        defaultVisibleColumns: BaseTableColumns.map(column => column.id),
        storageKeyPrefix: 'test-selection-table',
    });

    const [filters, dispatchFilters] = useTestResultFilter(testIds);
    const filterObject = useMemo(() => batteryTestResultToFilterObject(filters), [filters]);

    const sortObject: TestResultOrdering = {
        field: tableState.sortColumn as TestResultSortField,
        dir: tableState.sortDirection === SortDirection.Ascending ? 'Asc' : 'Desc',
    };

    const variables: TestSelectModalContentQuery$variables = {
        ids: testIds,
        page: tableState.page,
        orderBy: sortObject,
        filters: filterObject,
    };

    const { data: props, error, retry, isFetching } = useQuery<TestSelectModalContentQuery>(
        graphql`
            query TestSelectModalContentQuery(
                $page: Int = 1
                $ids: [String!]
                $orderBy: TestResultOrdering
                $filters: TestResultFilter
            ) {
                batteryTestResults(
                    ids: $ids
                    page: $page
                    pageSize: 10
                    state: [Passed, Failed, Aborted, Inconclusive]
                    orderBy: $orderBy
                    filters: $filters
                ) {
                    data {
                        id
                        commencedTime
                        completedTime
                        cause
                        device {
                            name
                        }
                        task {
                            name
                            type
                        }
                        state
                    }
                    pageInfo {
                        page
                        total
                        hasNext
                        hasPrevious
                    }
                }
            }
        `,
        variables,
        {
            fetchPolicy: 'network-only',
            skip: !isTestSelectModalOpen,
        }
    );

    const onCompareClicked = () => {
        setSelectedTestsCompare({
            ids: tableState.selectedItems,
            text: 'Custom',
        });
        setIsCompareModalOpen(false);
    };

    const onCancelClicked = () => {
        setSelectedTestsCompare(null);
        setIsCompareModalOpen(false);
    };

    const pageInfo = {
        hasNext: props?.batteryTestResults.pageInfo.hasNext ?? false,
        hasPrevious: props?.batteryTestResults.pageInfo.hasPrevious ?? false,
        page: props?.batteryTestResults.pageInfo.page ?? 1,
        total: props?.batteryTestResults.pageInfo.total ?? 1,
    };

    return (
        <div className={style.container}>
            <div>
                <ThemedPageHeading value='Select tests to compare' />
                <CoreTableLayout
                    tableSize='compact'
                    data={props?.batteryTestResults.data as BatteryTestResults[]}
                    columns={BaseTableColumns}
                    getRowId={row => row.id}
                    filterState={filters}
                    dispatchFilterState={dispatchFilters}
                    tableState={tableState}
                    dispatchTableState={dispatchTableState}
                    selection
                    onRequestAllIds={() => getAllTestIds(environment, testIds, filterObject)}
                    tableVariant='white'
                    clickBehaviour='select'
                    hasError={!!error}
                    onRetry={retry}
                    isProcessing={!!props && isFetching}
                    emptyMessage='There are no tests present'
                    page={pageInfo.page}
                    pageCount={pageInfo.total}
                />
            </div>
            <div className='space-x-2 pt-4'>
                <Button
                    buttonText='Compare selected'
                    onClick={onCompareClicked}
                    disabled={tableState.selectedItems.length === 0}
                />
                <Button buttonText='Cancel' variant='gray' onClick={onCancelClicked} />
            </div>
        </div>
    );
};

function getAllTestIds(
    environment: IEnvironment,
    testIds: string[],
    filters: Record<string, unknown>
): Promise<string[]> {
    const getAllTestIdsQuery = graphql`
        query TestSelectModalContentAllIdsQuery($ids: [String!], $filters: TestResultFilter) {
            batteryTestResults(ids: $ids, pageSize: 10000, filters: $filters) {
                data {
                    id
                }
            }
        }
    `;

    const variables: TestSelectModalContentAllIdsQuery$variables = {
        ids: testIds,
        filters: filters,
    };

    return fetchQuery<TestSelectModalContentAllIdsQuery>(environment, getAllTestIdsQuery, variables)
        .toPromise()
        .then(data => data?.batteryTestResults.data.map(test => test.id) ?? []);
}
