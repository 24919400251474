/**
 * @generated SignedSource<<1099a8dac1826090fca735dd76744627>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TrendState = "Down" | "Flat" | "Up" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CapacitySparkline_battery$data = {
  readonly latestRemainingCapacity: number | null;
  readonly remainingCapacity: {
    readonly trend: {
      readonly state: TrendState;
    } | null;
    readonly values: ReadonlyArray<{
      readonly timestamp: string;
      readonly value: number | null;
    }>;
  } | null;
  readonly " $fragmentType": "CapacitySparkline_battery";
};
export type CapacitySparkline_battery$key = {
  readonly " $data"?: CapacitySparkline_battery$data;
  readonly " $fragmentSpreads": FragmentRefs<"CapacitySparkline_battery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CapacitySparkline_battery",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latestRemainingCapacity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TimeSeriesMetrics",
      "kind": "LinkedField",
      "name": "remainingCapacity",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TimestampMetric",
          "kind": "LinkedField",
          "name": "values",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "timestamp",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Trend",
          "kind": "LinkedField",
          "name": "trend",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "state",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DeviceBatteryMetrics",
  "abstractKey": null
};

(node as any).hash = "84d13c70242e85904dd5a512bb576a71";

export default node;
