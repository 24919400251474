/**
 * @generated SignedSource<<e764350e23f8eee211c0afb1e6696d0a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BatteryTestType = "Capacity" | "Custom" | "Quick" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TestHorizontalCard_data$data = {
  readonly commencedTime: string | null;
  readonly creator: {
    readonly email: string | null;
    readonly name: string | null;
  };
  readonly devices: {
    readonly total: number;
  };
  readonly id: string;
  readonly name: string | null;
  readonly type: BatteryTestType;
  readonly " $fragmentSpreads": FragmentRefs<"TaskProgressBar_task">;
  readonly " $fragmentType": "TestHorizontalCard_data";
};
export type TestHorizontalCard_data$key = {
  readonly " $data"?: TestHorizontalCard_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"TestHorizontalCard_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TestHorizontalCard_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PaginatedDevices",
      "kind": "LinkedField",
      "name": "devices",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commencedTime",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TaskProgressBar_task"
    }
  ],
  "type": "BatteryTest",
  "abstractKey": null
};
})();

(node as any).hash = "a69a59e5d676a52c19dde8388c098e02";

export default node;
