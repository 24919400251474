/**
 * @generated SignedSource<<b031dac13bc08ce70861d17e4b2854be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BatteryStatus = "BoostCharging" | "Charging" | "Discharging" | "Disconnected" | "FloatCharging" | "Idle" | "Missing" | "Unknown" | "%future added value";
export type DeviceFilter = {
  attributes?: ReadonlyArray<AttributeFilters> | null;
  batteryCapacityRemaining?: FloatRangeFilter | null;
  batteryCapacityTotal?: FloatRangeFilter | null;
  batteryEnergyTotal?: FloatRangeFilter | null;
  batteryInstallDate?: DateRangeFilter | null;
  batteryManufactureDate?: DateRangeFilter | null;
  batteryReserveTime?: FloatRangeFilter | null;
  batteryState?: ReadonlyArray<BatteryStatusFilter> | null;
  batteryStateOfHealth?: FloatRangeFilter | null;
  batteryStringCount?: IntRangeFilter | null;
  batteryTemperature?: FloatRangeFilter | null;
  health?: ReadonlyArray<StringFilter> | null;
  ids?: ReadonlyArray<string> | null;
  lastTestedDate?: DateRangeFilter | null;
  monitorOnly?: BooleanFilter | null;
  name?: ReadonlyArray<StringFilter> | null;
  siteId?: ReadonlyArray<string> | null;
  siteName?: ReadonlyArray<StringFilter> | null;
  siteState?: ReadonlyArray<StringFilter> | null;
  snmpVersion?: ReadonlyArray<StringFilter> | null;
  supportsBatteryTesting?: BooleanFilter | null;
  type?: ReadonlyArray<StringFilter> | null;
};
export type StringFilter = {
  value: string;
};
export type AttributeFilters = {
  filters: ReadonlyArray<StringFilter>;
  name: string;
};
export type IntRangeFilter = {
  max: number;
  min: number;
};
export type FloatRangeFilter = {
  max: number;
  min: number;
};
export type BatteryStatusFilter = {
  value: BatteryStatus;
};
export type DateRangeFilter = {
  max: string;
  min: string;
};
export type BooleanFilter = {
  value: boolean;
};
export type ScheduleSelectAllQuery$variables = {
  filters?: DeviceFilter | null;
  search?: string | null;
};
export type ScheduleSelectAllQuery$data = {
  readonly devices: {
    readonly data: ReadonlyArray<{
      readonly dualPlaneCompanion: {
        readonly device: {
          readonly id: string;
        } | null;
      } | null;
      readonly id: string;
    }>;
  };
};
export type ScheduleSelectAllQuery = {
  response: ScheduleSelectAllQuery$data;
  variables: ScheduleSelectAllQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filters",
        "variableName": "filters"
      },
      {
        "kind": "Literal",
        "name": "onlyWithValidBattery",
        "value": true
      },
      {
        "kind": "Literal",
        "name": "pageSize",
        "value": 10000
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Literal",
        "name": "searchOptions",
        "value": {
          "includeDeviceName": true,
          "includeSiteName": true
        }
      }
    ],
    "concreteType": "PaginatedDevices",
    "kind": "LinkedField",
    "name": "devices",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DualPlaneCompanion",
            "kind": "LinkedField",
            "name": "dualPlaneCompanion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "device",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ScheduleSelectAllQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ScheduleSelectAllQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "b7ab9841b2ed97a857b3263dd3e6727f",
    "id": null,
    "metadata": {},
    "name": "ScheduleSelectAllQuery",
    "operationKind": "query",
    "text": "query ScheduleSelectAllQuery(\n  $search: String\n  $filters: DeviceFilter\n) {\n  devices(onlyWithValidBattery: true, pageSize: 10000, search: $search, filters: $filters, searchOptions: {includeSiteName: true, includeDeviceName: true}) {\n    data {\n      id\n      dualPlaneCompanion {\n        device {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "309354d5aa43d0ca322864b5f01db3da";

export default node;
