import React, { FC } from 'react';

interface TableDeviceHeaderProps {
    name: string;
}

export const TableDeviceHeader: FC<TableDeviceHeaderProps> = ({ name }) => {
    return (
        <div className='font-CynthoNext-Light text-customCoral text-base whitespace-nowrap'>
            Device: <b>{name}</b>
        </div>
    );
};
