import React, { FC } from 'react';

import { Button } from '@accesstel/pcm-ui';

import { Paths } from 'lib/routes';

export const ErrorUnhandled: FC = () => {
    return (
        <div className='error-page'>
            <div className='error-title'>Something went wrong</div>
            <div className='error-subtitle '>There was an error we didnt expect. Please try again later.</div>
            <Button buttonClasses='overview-button text-lg' buttonText='Go to dashboard' to={Paths.Dashboard} />
        </div>
    );
};
