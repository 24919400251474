/**
 * @generated SignedSource<<ea8ca46699b118d60aaa6f5dc15cc014>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type StatusListAbortTestsMutation$variables = {
  ids: ReadonlyArray<string>;
};
export type StatusListAbortTestsMutation$data = {
  readonly abortBatteryTestTests: boolean | null;
};
export type StatusListAbortTestsMutation = {
  response: StatusListAbortTestsMutation$data;
  variables: StatusListAbortTestsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ids",
        "variableName": "ids"
      }
    ],
    "kind": "ScalarField",
    "name": "abortBatteryTestTests",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StatusListAbortTestsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StatusListAbortTestsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d2e4485e6c853dba361dd649c4bfc993",
    "id": null,
    "metadata": {},
    "name": "StatusListAbortTestsMutation",
    "operationKind": "mutation",
    "text": "mutation StatusListAbortTestsMutation(\n  $ids: [ID!]!\n) {\n  abortBatteryTestTests(ids: $ids)\n}\n"
  }
};
})();

(node as any).hash = "d99fc57dff421e4b104526dfe5609011";

export default node;
