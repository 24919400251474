/**
 * @generated SignedSource<<2d79783ccb77485001df435c947c35bb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ScheduleFetchDeviceBatteryInfoQuery$variables = {
  count: number;
  ids?: ReadonlyArray<string> | null;
};
export type ScheduleFetchDeviceBatteryInfoQuery$data = {
  readonly devices: {
    readonly data: ReadonlyArray<{
      readonly battery: {
        readonly allowedVoltage: {
          readonly maximum: number | null;
          readonly minimum: number | null;
        };
        readonly quickTestFailThreshold: number | null;
        readonly reserveTime: number | null;
        readonly strings: {
          readonly count: number;
          readonly strings: ReadonlyArray<{
            readonly cellsPerString: number;
            readonly type: {
              readonly cells: {
                readonly allowedVoltage: {
                  readonly maximum: number | null;
                  readonly minimum: number | null;
                } | null;
              };
              readonly dischargeTables: ReadonlyArray<{
                readonly endOfDischargeVoltage: number;
              }>;
              readonly id: string;
              readonly manufacturer: string;
              readonly model: string;
            };
          }>;
        };
      };
      readonly id: string;
      readonly name: string;
      readonly site: {
        readonly id: string;
        readonly name: string;
      };
    }>;
  };
};
export type ScheduleFetchDeviceBatteryInfoQuery = {
  response: ScheduleFetchDeviceBatteryInfoQuery$data;
  variables: ScheduleFetchDeviceBatteryInfoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ids"
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "ids",
        "variableName": "ids"
      }
    ],
    "kind": "ObjectValue",
    "name": "filters"
  },
  {
    "kind": "Variable",
    "name": "pageSize",
    "variableName": "count"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Site",
  "kind": "LinkedField",
  "name": "site",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cellsPerString",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "VoltageRange",
  "kind": "LinkedField",
  "name": "allowedVoltage",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minimum",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maximum",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "BatteryType",
  "kind": "LinkedField",
  "name": "type",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "manufacturer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "model",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DischargeTable",
      "kind": "LinkedField",
      "name": "dischargeTables",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endOfDischargeVoltage",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BatteryCellDefinition",
      "kind": "LinkedField",
      "name": "cells",
      "plural": false,
      "selections": [
        (v8/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reserveTime",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quickTestFailThreshold",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ScheduleFetchDeviceBatteryInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "PaginatedDevices",
        "kind": "LinkedField",
        "name": "devices",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Device",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "DeviceBattery",
                "kind": "LinkedField",
                "name": "battery",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeviceBatteryStrings",
                    "kind": "LinkedField",
                    "name": "strings",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DeviceBatteryString",
                        "kind": "LinkedField",
                        "name": "strings",
                        "plural": true,
                        "selections": [
                          (v7/*: any*/),
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ScheduleFetchDeviceBatteryInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "PaginatedDevices",
        "kind": "LinkedField",
        "name": "devices",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Device",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "DeviceBattery",
                "kind": "LinkedField",
                "name": "battery",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeviceBatteryStrings",
                    "kind": "LinkedField",
                    "name": "strings",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DeviceBatteryString",
                        "kind": "LinkedField",
                        "name": "strings",
                        "plural": true,
                        "selections": [
                          (v7/*: any*/),
                          (v9/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5637276ac13eb3d88222e5fe49753673",
    "id": null,
    "metadata": {},
    "name": "ScheduleFetchDeviceBatteryInfoQuery",
    "operationKind": "query",
    "text": "query ScheduleFetchDeviceBatteryInfoQuery(\n  $ids: [ID!]\n  $count: Int!\n) {\n  devices(pageSize: $count, filters: {ids: $ids}) {\n    data {\n      id\n      name\n      site {\n        id\n        name\n      }\n      battery {\n        strings {\n          count\n          strings {\n            cellsPerString\n            type {\n              id\n              manufacturer\n              model\n              dischargeTables {\n                endOfDischargeVoltage\n              }\n              cells {\n                allowedVoltage {\n                  minimum\n                  maximum\n                }\n              }\n            }\n            id\n          }\n        }\n        allowedVoltage {\n          minimum\n          maximum\n        }\n        reserveTime\n        quickTestFailThreshold\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "38ee0419703c3e5054bc9d185041198f";

export default node;
