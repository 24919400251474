import React, { FC, useCallback, useMemo } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';

import { BarDataType } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { FilterActionType } from 'filters/common';
import {
    DeviceHealthStatus,
    DeviceHealthStatusNames,
    SiteTableColumn,
    siteToFilterObject,
    useSiteFilter,
} from 'filters/site';
import { SortDirection, TableLayout, useTableReducer } from 'layouts';
import { useQuery } from 'lib/query-helpers';

import { SiteListSearchQuery, SiteListSearchQuery$data } from './__generated__/SiteListSearchQuery.graphql';
import {
    DeviceHealth,
    SiteListTableQuery,
    SiteListTableQuery$variables,
    SiteOrdering,
    SiteSortField,
} from './__generated__/SiteListTableQuery.graphql';
import { AllTableColumns, BaseTableColumns, SiteDevice, SiteWithDevices } from './settings';

type SiteSearchResult = SiteListSearchQuery$data['sites']['data'][number];

export const SiteList: FC = () => {
    const environment = useRelayEnvironment();

    const [tableState, dispatchTableState] = useTableReducer<SiteTableColumn>({
        defaultSortColumn: SiteTableColumn.Name,
        defaultSortDirection: SortDirection.Descending,
        allColumns: AllTableColumns.map(column => column.id),
        defaultVisibleColumns: BaseTableColumns.map(column => column.id),
        storageKeyPrefix: 'powercontroller-table',
    });

    const [filters, dispatchFilters] = useSiteFilter();
    const filterObject = useMemo(() => siteToFilterObject(filters), [filters]);

    const sortObject: SiteOrdering = {
        field: tableState.sortColumn as SiteSortField,
        dir: tableState.sortDirection === SortDirection.Descending ? 'Asc' : 'Desc',
    };

    const variables: SiteListTableQuery$variables = {
        page: tableState.page,
        search: tableState.search,
        orderBy: sortObject,
        filter: filterObject,
        withSiteType: tableState.visibleColumnsInOrder.includes(SiteTableColumn.Type) !== undefined,
        withPostcode: tableState.visibleColumnsInOrder.includes(SiteTableColumn.Postcode) !== undefined,
        withBatteryStatus: tableState.visibleColumnsInOrder.includes(SiteTableColumn.BatteryStatus) !== undefined,
        withBatteryCapacity: tableState.visibleColumnsInOrder.includes(SiteTableColumn.BatteryCapacity) !== undefined,
        withBatteryTimeRemaining:
            tableState.visibleColumnsInOrder.includes(SiteTableColumn.BatteryTotalTimeRemaining) !== undefined,
    };

    const { data: props, error, retry, isFetching } = useQuery<SiteListTableQuery>(
        graphql`
            query SiteListTableQuery(
                $page: Int = 1
                $search: String = ""
                $orderBy: SiteOrdering
                $filter: SiteFilter
                $withSiteType: Boolean = false
                $withPostcode: Boolean = false
                $withBatteryStatus: Boolean = false
                $withBatteryCapacity: Boolean = false
                $withBatteryTimeRemaining: Boolean = false
            ) {
                sites(
                    page: $page
                    search: $search
                    onlyProvisioningStatuses: Active
                    orderBy: $orderBy
                    filters: $filter
                ) {
                    total
                    data {
                        __typename
                        id
                        name
                        type @include(if: $withSiteType)
                        address {
                            state
                            postcode @include(if: $withPostcode)
                        }
                        devices {
                            total
                            data {
                                __typename
                                id
                                name
                                health
                            }
                        }
                        health {
                            status
                            commonStatus
                            multipleStatuses
                        }
                        batteryMetrics {
                            commonStatus @include(if: $withBatteryStatus)
                            multipleStatuses @include(if: $withBatteryStatus)
                            totalCapacity(unit: AmpHour) @include(if: $withBatteryCapacity)
                            totalTimeRemaining(unit: Hours) @include(if: $withBatteryTimeRemaining)
                        }
                    }
                    pageInfo {
                        page
                        total
                        hasNext
                        hasPrevious
                    }
                }
                overallSites: sites {
                    total
                }
                globalDeviceHealth {
                    status
                    count
                }
            }
        `,
        variables,
        {
            fetchPolicy: 'network-only',
        }
    );

    const handleSearch = useCallback(
        (input: string) => {
            return fetchQuery<SiteListSearchQuery>(
                environment,
                graphql`
                    query SiteListSearchQuery($name: String = "", $pageSize: Int!) {
                        sites(
                            filters: { name: { value: $name } }
                            pageSize: $pageSize
                            onlyProvisioningStatuses: Active
                        ) {
                            data {
                                id
                                name
                            }
                        }
                    }
                `,
                { name: input, pageSize: 10 }
            )
                .toPromise()
                .then(result => (result?.sites.data as SiteSearchResult[]) ?? []);
        },
        [environment]
    );

    const barChartData: BarDataType[] = [];
    if (props) {
        let unknownTotal = 0;

        props.globalDeviceHealth.forEach((status: { count: number; status: DeviceHealth | null }) => {
            if (status.status !== 'Unknown') {
                barChartData.push({
                    id: status.status ?? '',
                    label: status.status ?? '',
                    value: status.count,
                    bgClass: healthStringToColour(status.status ?? ''),
                });
            } else {
                unknownTotal = status.count;
            }
        });

        if (unknownTotal >= 1) {
            barChartData.push({
                label: 'Unknown',
                value: unknownTotal,
                bgClass: healthStringToColour('Unknown'),
            });
        }
    }

    const onSegmentClick = (state: string) => {
        dispatchFilters({
            type: FilterActionType.Apply,
            column: SiteTableColumn.DeviceStatus,
            value: [
                { id: state as DeviceHealthStatus, displayText: DeviceHealthStatusNames[state as DeviceHealthStatus] },
            ],
        });
    };

    return (
        <TableLayout
            title='Sites'
            columns={AllTableColumns}
            allowEditingColumns
            filterState={filters}
            dispatchFilterState={dispatchFilters}
            tableState={tableState}
            dispatchTableState={dispatchTableState}
            data={props?.sites.data ?? null}
            isProcessing={!!props && isFetching}
            getRowId={(row: SiteWithDevices | SiteDevice) => row.id}
            page={props?.sites.pageInfo.page}
            pageCount={props?.sites.pageInfo.total}
            overallCount={props?.overallSites.total}
            resultCount={props?.sites.total}
            hasError={!!error}
            onRetry={retry}
            searchPlaceholder='Search by Site name'
            onSearch={handleSearch}
            renderSearchResult={(item: SiteSearchResult) => item.name}
            renderSearchResultAsString={(item: SiteSearchResult) => item.name}
            emptyMessage='There are no sites present'
            unit='Site'
            barChartData={{
                data: barChartData,
                emptyLabel: 'No devices found',
                labelFormatter,
                valueFormatter: barChartValueFormatter,
                onSegmentClick,
            }}
            getItemLink={(item: SiteWithDevices | SiteDevice) => item.id}
        />
    );
};

function labelFormatter(label: string | null, data: unknown, unit: unknown): string {
    if (label === null) {
        return '';
    }

    return label.toUpperCase();
}

function barChartValueFormatter(value: number | null): string {
    return `${value} ${value === 1 ? 'Device' : 'Devices'}`;
}

function healthStringToColour(status: string): string {
    switch (status) {
        case 'Healthy':
            return 'bg-customEggplantCoral';
        case 'Degraded':
            return 'bg-customWhiteCoral';
        case 'Critical':
            return 'bg-customMustard';
        case 'Offline':
            return 'bg-customCoral';
        case 'Unknown':
            return 'bg-customWhiteEggplant';
        default:
            return '';
    }
}
