import React, { Dispatch, FC } from 'react';

import { FormikErrors, useField } from 'formik';
import { getQualifiedName } from 'lib/namespaces';
import { FormControlAction, FormControlParameters } from 'views/manage/device/add-edit/lib/reducer';

import { DeviceBatteryString } from '../../schema';
import style from '../style.module.css';
import { StringEditor } from './StringEditor';

interface ExistingStringEditorProps {
    namespace?: string;
    stringIndex: number;
    onClose: () => void;
    onUpdate: (string: DeviceBatteryString) => void;

    // form control
    formControlState: FormControlParameters;
    formControlDispatch: Dispatch<FormControlAction>;
}

export const ExistingStringEditor: FC<ExistingStringEditorProps> = ({
    namespace,
    stringIndex,
    onUpdate,
    onClose,
    formControlState,
    formControlDispatch,
}) => {
    const [, batteryStringField] = useField<DeviceBatteryString>(getQualifiedName(`strings.${stringIndex}`, namespace));
    const batteryString = batteryStringField.value;
    const errors = batteryStringField.error as FormikErrors<DeviceBatteryString>;

    const submitBatteryString = (value: DeviceBatteryString) => {
        onUpdate(value);
        onClose();
    };

    return (
        <div className={style.battery_string_edit_container}>
            <StringEditor
                initialValues={batteryString}
                initialErrors={errors}
                formControlDispatch={formControlDispatch}
                formControlState={formControlState}
                onSubmit={submitBatteryString}
                onCancel={onClose}
            />
        </div>
    );
};
