import React, { FC, ReactNode } from 'react';
import { useFragment } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { NameValuePair } from 'components';
import { NameValuePairClassesProps } from 'components/NameValuePair';

import { formatAPIDate } from '../../../test-result-view/common';
import { OverviewFields_task$key } from './__generated__/OverviewFields_task.graphql';

export interface OverviewFieldsProps {
    task: OverviewFields_task$key;
}

const nameValueClasses: NameValuePairClassesProps = {
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, minmax(0, 1fr))',
        lineHeight: '1.5rem',
    },
    value: {
        gridColumnStart: 5,
    },
};

export const OverviewFields: FC<OverviewFieldsProps> = ({ task }) => {
    const result = useFragment<OverviewFields_task$key>(
        graphql`
            fragment OverviewFields_task on BatteryTest {
                overallState
                commencedTime
                cancelledTime
                cancelledUser {
                    name
                    email
                }
                completedTime
                usingSmartStart
                createdTime
                schedule {
                    repeat
                    time
                }
                type
                creator {
                    name
                }
                testState
                abortedTime
                abortedUser {
                    name
                    email
                }
                totalDevice: devices {
                    total
                    data {
                        id
                    }
                }
            }
        `,
        task
    );

    const fields: ReactNode[] = [];
    switch (result.overallState!) {
        case 'Scheduled':
            fields.push(
                <NameValuePair
                    key='date'
                    name='Commences'
                    value={formatAPIDate(result.schedule?.time) ?? 'Unknown'}
                    classes={nameValueClasses}
                />
            );
            break;
        case 'Cancelled':
            fields.push(
                <NameValuePair
                    key='date'
                    name='Scheduled'
                    value={formatAPIDate(result.schedule?.time) ?? 'Unknown'}
                    classes={nameValueClasses}
                />
            );
            break;
        case 'InProgress':
        case 'Completed':
            fields.push(
                <NameValuePair
                    key='date'
                    name='Commenced'
                    value={formatAPIDate(result.commencedTime) ?? 'Unknown'}
                    classes={nameValueClasses}
                />
            );
            break;
        default:
            fields.push(
                <NameValuePair
                    key='date'
                    name='Created'
                    value={formatAPIDate(result.createdTime)!}
                    classes={nameValueClasses}
                />
            );
            break;
    }

    fields.push(
        <NameValuePair
            key='devCount'
            name='Number of devices'
            value={(result.totalDevice?.total ?? 0).toFixed(0)}
            classes={nameValueClasses}
        />
    );
    fields.push(
        <NameValuePair key='user' name='Initiated By' value={result.creator!.name!} classes={nameValueClasses} />
    );
    fields.push(<NameValuePair key='type' name='Test Type' value={`${result.type} Test`} classes={nameValueClasses} />);

    let repeat: string;
    switch (result.schedule?.repeat) {
        default:
        case 'Never':
            repeat = 'Do not repeat';
            break;
        case 'Monthly':
        case 'Quarterly':
            repeat = result.schedule.repeat;
            break;
        case 'BiMonthly':
            repeat = 'Bi-monthly';
            break;
    }

    fields.push(<NameValuePair key='repeat' name='Repeat' value={repeat} classes={nameValueClasses} />);
    fields.push(
        <NameValuePair
            key='sstart'
            name='Smart Start'
            value={result.usingSmartStart ? 'Enabled' : 'Disabled'}
            classes={nameValueClasses}
        />
    );

    if (result.overallState === 'Cancelled') {
        fields.push(
            <NameValuePair
                key='cancelDate'
                name='Cancelled'
                value={formatAPIDate(result.cancelledTime) ?? 'Unknown'}
                primary
                classes={nameValueClasses}
            />
        );
        fields.push(
            <NameValuePair
                key='cancelUser'
                name='Cancelled By'
                value={result.cancelledUser?.name ?? result.cancelledUser?.email ?? 'Unknown'}
                primary
                classes={nameValueClasses}
            />
        );
    } else if (result.testState === 'Aborted') {
        fields.push(
            <NameValuePair
                key='abortDate'
                name='Aborted'
                value={formatAPIDate(result.abortedTime) ?? 'Unknown'}
                primary
                classes={nameValueClasses}
            />
        );
        fields.push(
            <NameValuePair
                key='abortUser'
                name='Aborted By'
                value={result.abortedUser?.name ?? result.abortedUser?.email ?? 'Unknown'}
                primary
                classes={nameValueClasses}
            />
        );
    }

    return <div className='grid grid-cols-2'>{fields}</div>;
};
