/**
 * @generated SignedSource<<97ee49de21d19f0ba9d08eec12241113>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TestResultListDeviceSearchQuery$variables = {
  deviceName?: string | null;
  pageSize: number;
};
export type TestResultListDeviceSearchQuery$data = {
  readonly unplannedTests: {
    readonly data: ReadonlyArray<{
      readonly device: {
        readonly name: string;
      };
    }>;
  };
};
export type TestResultListDeviceSearchQuery = {
  response: TestResultListDeviceSearchQuery$data;
  variables: TestResultListDeviceSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": "",
    "kind": "LocalArgument",
    "name": "deviceName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageSize"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "pageSize",
    "variableName": "pageSize"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "deviceName"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TestResultListDeviceSearchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PaginatedDeviceBatteryTestResults",
        "kind": "LinkedField",
        "name": "unplannedTests",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceBatteryTestResults",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "device",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TestResultListDeviceSearchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PaginatedDeviceBatteryTestResults",
        "kind": "LinkedField",
        "name": "unplannedTests",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceBatteryTestResults",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "device",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "21b524864a859b87f21ad2504f2c48ad",
    "id": null,
    "metadata": {},
    "name": "TestResultListDeviceSearchQuery",
    "operationKind": "query",
    "text": "query TestResultListDeviceSearchQuery(\n  $deviceName: String = \"\"\n  $pageSize: Int!\n) {\n  unplannedTests(search: $deviceName, pageSize: $pageSize) {\n    data {\n      device {\n        name\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bc74027b16cc15ce6797ee9e3ca0a149";

export default node;
