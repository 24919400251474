import React, { FC, Suspense } from 'react';
import { PreloadedQuery } from 'react-relay';
import { useLoaderData, useParams } from 'react-router-dom';

import { useDocumentTitle } from 'components';

import { SiteViewLayoutContent } from './SiteViewLayoutContent';
import { SiteViewLayoutSkeleton } from './SiteViewLayoutSkeleton';
import { SiteViewLayoutContentQuery } from './__generated__/SiteViewLayoutContentQuery.graphql';

export const SiteViewLayout: FC = () => {
    const queryRef = useLoaderData() as PreloadedQuery<SiteViewLayoutContentQuery>;
    const { siteId } = useParams() as { siteId: string };
    useDocumentTitle('View site');

    return (
        <>
            {!queryRef && <SiteViewLayoutSkeleton />}
            {queryRef && (
                <Suspense fallback={<SiteViewLayoutSkeleton />}>
                    <SiteViewLayoutContent key={siteId} queryRef={queryRef} />
                </Suspense>
            )}
        </>
    );
};
