/**
 * @generated SignedSource<<e05c4df796e46a7394fb95b12aade25c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeviceRectifiers_device$data = {
  readonly rectifier: {
    readonly freeCapacity: number | null;
    readonly metrics: {
      readonly latestOutputCurrent: number | null;
      readonly latestOutputVoltage: number | null;
      readonly latestTemperature: number | null;
    };
    readonly modules: {
      readonly data: ReadonlyArray<{
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"ModuleRow_data" | "ModuleTile_data">;
      }>;
    };
    readonly offlineCapacity: number | null;
    readonly totalCapacity: number | null;
    readonly uptime: {
      readonly percentage: number | null;
    };
    readonly usedCapacity: number | null;
  } | null;
  readonly " $fragmentType": "DeviceRectifiers_device";
};
export type DeviceRectifiers_device$key = {
  readonly " $data"?: DeviceRectifiers_device$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeviceRectifiers_device">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "unit",
    "value": "Watt"
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "begin"
    },
    {
      "kind": "RootArgument",
      "name": "end"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeviceRectifiers_device",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceRectifier",
      "kind": "LinkedField",
      "name": "rectifier",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "from",
              "variableName": "begin"
            },
            {
              "kind": "Variable",
              "name": "to",
              "variableName": "end"
            }
          ],
          "concreteType": "RectifierUptime",
          "kind": "LinkedField",
          "name": "uptime",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "percentage",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DeviceRectifierMetrics",
          "kind": "LinkedField",
          "name": "metrics",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "latestTemperature",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "latestOutputVoltage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "latestOutputCurrent",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v0/*: any*/),
          "kind": "ScalarField",
          "name": "totalCapacity",
          "storageKey": "totalCapacity(unit:\"Watt\")"
        },
        {
          "alias": null,
          "args": (v0/*: any*/),
          "kind": "ScalarField",
          "name": "freeCapacity",
          "storageKey": "freeCapacity(unit:\"Watt\")"
        },
        {
          "alias": null,
          "args": (v0/*: any*/),
          "kind": "ScalarField",
          "name": "usedCapacity",
          "storageKey": "usedCapacity(unit:\"Watt\")"
        },
        {
          "alias": null,
          "args": (v0/*: any*/),
          "kind": "ScalarField",
          "name": "offlineCapacity",
          "storageKey": "offlineCapacity(unit:\"Watt\")"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PaginatedRectifierModules",
          "kind": "LinkedField",
          "name": "modules",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RectifierModule",
              "kind": "LinkedField",
              "name": "data",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ModuleTile_data"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ModuleRow_data"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
};
})();

(node as any).hash = "bcebf32a07509895d9cd4896ada5d83d";

export default node;
