import { DateRangeFilter } from 'components/FilterDateSelect/common';
import { RunTimeFilter } from 'components/FilterDurationSelect/common';
import { BatteryTypeManufacturerFilterUI, BatteryTypeModelFilterUI } from 'filters/battery-type/components';
import { BatteryTypeManModelFilter } from 'filters/battery-type/types';
import { formatDateFilter, formatDurationFilter } from 'filters/common/util';
import { DeviceBatteryDateFilterUI } from 'filters/device/components/DeviceBatteryDateFilterUI';

import { FilterDefinition, FilterValueMap, RangeFilter } from '../common';
import {
    TaskBatteryTestNameFilterUI,
    TaskBatteryTestStatusFilterUI,
    TaskBatteryTestTypeFilterUI,
    TaskDateFilterUI,
    TaskRunTimeFilterUI,
    TaskScheduleRepeatFilterUI,
    TaskUsingSmartStartFilterUI,
} from './components';
import { TaskDeviceCountFilterUI } from './components/TaskDeviceCountFilterUI';
import {
    BatteryTestNameFilter,
    BatteryTestStatusFilter,
    BatteryTestTypeFilter,
    NotInProgTestsTableColumn,
    ScheduleRepeatFilter,
    SmartStartStatus,
    TaskExtraFilters,
} from './types';

export type TaskColumnFilterMap = {
    [NotInProgTestsTableColumn.Name]: BatteryTestNameFilter[];
    [NotInProgTestsTableColumn.Date]: DateRangeFilter | null;
    [NotInProgTestsTableColumn.Devices]: RangeFilter | null;
    [NotInProgTestsTableColumn.Status]: BatteryTestStatusFilter[];
    [NotInProgTestsTableColumn.Type]: BatteryTestTypeFilter[];
    [NotInProgTestsTableColumn.ScheduleRepeat]: ScheduleRepeatFilter[];
    [NotInProgTestsTableColumn.UsingSmartStart]: SmartStartStatus | null;
    [NotInProgTestsTableColumn.RunTime]: RunTimeFilter | null;
};

export const DefaultValues: FilterValueMap<TaskColumnFilterMap> = {
    [NotInProgTestsTableColumn.Name]: [],
    [NotInProgTestsTableColumn.Date]: null,
    [NotInProgTestsTableColumn.Devices]: null,
    [NotInProgTestsTableColumn.Status]: [],
    [NotInProgTestsTableColumn.Type]: [],
    [NotInProgTestsTableColumn.ScheduleRepeat]: [],
    [NotInProgTestsTableColumn.UsingSmartStart]: null,
    [NotInProgTestsTableColumn.RunTime]: null,
};

export const DefaultExtraFilters: Record<TaskExtraFilters, unknown> = {
    [TaskExtraFilters.DateCreated]: null,
    [TaskExtraFilters.DateCommenced]: null,
    [TaskExtraFilters.DateCompleted]: null,
    [TaskExtraFilters.DateCancelled]: null,
    [TaskExtraFilters.DateAborted]: null,
    [TaskExtraFilters.DateScheduled]: null,
    [TaskExtraFilters.BatteryStringManufacturer]: [],
    [TaskExtraFilters.BatteryStringModel]: [],
};

export const StaticTaskFilterDefinitions: FilterDefinition<NotInProgTestsTableColumn>[] = [
    {
        type: 'multi',
        name: NotInProgTestsTableColumn.Name,
        category: NotInProgTestsTableColumn.Name,
        column: NotInProgTestsTableColumn.Name,
        component: () => TaskBatteryTestNameFilterUI,
        describeValue: (value: BatteryTestNameFilter) => value.name,
    },
    {
        type: 'multi',
        name: NotInProgTestsTableColumn.Type,
        category: NotInProgTestsTableColumn.Type,
        column: NotInProgTestsTableColumn.Type,
        component: () => TaskBatteryTestTypeFilterUI,
        describeValue: (value: BatteryTestTypeFilter) => value.name,
    },
    {
        type: 'single',
        name: 'Device Count',
        category: NotInProgTestsTableColumn.Devices,
        column: NotInProgTestsTableColumn.Devices,
        component: () => TaskDeviceCountFilterUI,
        describeValue: (value: RangeFilter) => `${value.min} - ${value.max}`,
    },
    {
        type: 'multi',
        name: NotInProgTestsTableColumn.Status,
        category: NotInProgTestsTableColumn.Status,
        column: NotInProgTestsTableColumn.Status,
        component: () => TaskBatteryTestStatusFilterUI,
        describeValue: (value: BatteryTestStatusFilter) => `${value.name}`,
    },
    {
        type: 'single',
        name: NotInProgTestsTableColumn.Date,
        category: NotInProgTestsTableColumn.Date,
        column: NotInProgTestsTableColumn.Date,
        component: () => TaskDateFilterUI,
        describeValue: formatDateFilter,
    },
    {
        type: 'single',
        name: TaskExtraFilters.DateCreated,
        category: TaskExtraFilters.DateCreated,
        component: () => props => DeviceBatteryDateFilterUI({ ...props, title: 'Filter by Date Created' }),
        describeValue: formatDateFilter,
    },
    {
        type: 'single',
        name: TaskExtraFilters.DateCommenced,
        category: TaskExtraFilters.DateCommenced,
        component: () => props => DeviceBatteryDateFilterUI({ ...props, title: 'Filter by Date Commenced' }),
        describeValue: formatDateFilter,
    },
    {
        type: 'single',
        name: TaskExtraFilters.DateCompleted,
        category: TaskExtraFilters.DateCompleted,
        component: () => props => DeviceBatteryDateFilterUI({ ...props, title: 'Filter by Date Completed' }),
        describeValue: formatDateFilter,
    },
    {
        type: 'single',
        name: TaskExtraFilters.DateCancelled,
        category: TaskExtraFilters.DateCancelled,
        component: () => props => DeviceBatteryDateFilterUI({ ...props, title: 'Filter by Date Cancelled' }),
        describeValue: formatDateFilter,
    },
    {
        type: 'single',
        name: TaskExtraFilters.DateAborted,
        category: TaskExtraFilters.DateAborted,
        component: () => props => DeviceBatteryDateFilterUI({ ...props, title: 'Filter by Date Aborted' }),
        describeValue: formatDateFilter,
    },
    {
        type: 'single',
        name: TaskExtraFilters.DateScheduled,
        category: TaskExtraFilters.DateScheduled,
        component: () => props => DeviceBatteryDateFilterUI({ ...props, title: 'Filter by Date Scheduled' }),
        describeValue: formatDateFilter,
    },
    {
        type: 'single',
        name: 'Using Smart Start',
        category: 'Using Smart Start',
        column: NotInProgTestsTableColumn.UsingSmartStart,
        component: () => TaskUsingSmartStartFilterUI,
        describeValue: (value: string) => value,
    },
    {
        type: 'multi',
        name: 'Repeat Cadence',
        category: 'Repeat Cadence',
        column: NotInProgTestsTableColumn.ScheduleRepeat,
        component: () => TaskScheduleRepeatFilterUI,
        describeValue: (value: ScheduleRepeatFilter) => `${value.name}`,
    },
    {
        type: 'multi',
        name: TaskExtraFilters.BatteryStringManufacturer,
        category: TaskExtraFilters.BatteryStringManufacturer,
        component: () => BatteryTypeManufacturerFilterUI,
        describeValue: (value: BatteryTypeManModelFilter) => value.name,
    },
    {
        type: 'multi',
        name: TaskExtraFilters.BatteryStringModel,
        category: TaskExtraFilters.BatteryStringModel,
        component: () => BatteryTypeModelFilterUI,
        describeValue: (value: BatteryTypeManModelFilter) => value.name,
    },
    {
        type: 'single',
        name: 'Run Time',
        category: 'Run Time',
        column: NotInProgTestsTableColumn.RunTime,
        component: () => TaskRunTimeFilterUI,
        describeValue: formatDurationFilter,
    },
];
