/**
 * @generated SignedSource<<d0b32828bfd7a2c7875cb94cd80defde>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeviceHealth = "Critical" | "Degraded" | "Healthy" | "Offline" | "Unknown" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PowerControllersGauge$data = {
  readonly devices: {
    readonly total: number;
  };
  readonly globalDeviceHealth: ReadonlyArray<{
    readonly count: number;
    readonly status: DeviceHealth | null;
  }>;
  readonly " $fragmentType": "PowerControllersGauge";
};
export type PowerControllersGauge$key = {
  readonly " $data"?: PowerControllersGauge$data;
  readonly " $fragmentSpreads": FragmentRefs<"PowerControllersGauge">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PowerControllersGauge",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PaginatedDevices",
      "kind": "LinkedField",
      "name": "devices",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GlobalDeviceHealth",
      "kind": "LinkedField",
      "name": "globalDeviceHealth",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "ad00e64a28929252dfa4d5718f7cb1d7";

export default node;
