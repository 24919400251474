/**
 * @generated SignedSource<<2e7855f3a22f4f92a005c111dd6bef6c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BatteryHealthTaskListSearchQuery$variables = {
  pageSize: number;
  search?: string | null;
  state?: ReadonlyArray<string> | null;
};
export type BatteryHealthTaskListSearchQuery$data = {
  readonly tasks: {
    readonly data: ReadonlyArray<{
      readonly id: string;
      readonly name: string | null;
    }>;
  };
};
export type BatteryHealthTaskListSearchQuery = {
  response: BatteryHealthTaskListSearchQuery$data;
  variables: BatteryHealthTaskListSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageSize"
},
v1 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "search"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "state"
},
v3 = [
  {
    "kind": "Variable",
    "name": "pageSize",
    "variableName": "pageSize"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "state",
    "variableName": "state"
  },
  {
    "kind": "Literal",
    "name": "type",
    "value": "BatteryTest"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BatteryHealthTaskListSearchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "PaginatedTasks",
        "kind": "LinkedField",
        "name": "tasks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "BatteryHealthTaskListSearchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "PaginatedTasks",
        "kind": "LinkedField",
        "name": "tasks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d7a35608f550fc9beb4c74ed56c8db86",
    "id": null,
    "metadata": {},
    "name": "BatteryHealthTaskListSearchQuery",
    "operationKind": "query",
    "text": "query BatteryHealthTaskListSearchQuery(\n  $search: String = \"\"\n  $state: [String!]\n  $pageSize: Int!\n) {\n  tasks(search: $search, state: $state, type: BatteryTest, pageSize: $pageSize) {\n    data {\n      __typename\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2fa9bd4dcce981018502a6cb9d309b13";

export default node;
