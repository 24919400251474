import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import {
    AmmeterIcon,
    BarDataType,
    BatteryEmptyIcon,
    Button,
    Menu,
    MetricViewFrame,
    StackedHorizontalBar,
    StraightAngleGauge,
    Theme,
    ThermometerIcon,
    VoltmeterIcon,
    useExtendedNavigate,
} from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { IconWithStatus } from 'components';
import humanizeDuration, { Unit } from 'humanize-duration';
import { useUserPermissions } from 'lib/auth';
import { batteryStatusToString } from 'lib/conversion/battery-status';
import { round } from 'lib/number';
import { numberToLocaleString, percentageToStringCapped } from 'lib/numberFormatters';
import { Paths } from 'lib/routes';
import { Duration } from 'luxon';

import { makeLinkToMetric } from '../../../../explore/lib/link';
import { Operation } from '../../../../explore/types';
import { BatteryStatus, DeviceBatteries_device$key } from './__generated__/DeviceBatteries_device.graphql';
import { StringRow } from './components/StringRow';
import style from './style.module.css';

// Just using 3 hours as a default
const DefaultReserveTime = 3;

const BatteryStatusToColor: Record<BatteryStatus, string> = {
    FloatCharging: 'bg-customEggplant',
    BoostCharging: 'bg-customPine',
    Discharging: 'bg-customCoral',
    Idle: 'bg-customWhiteCoral',
    Unknown: 'bg-customWhiteEggplant',
    Missing: 'bg-customGray',
    Charging: 'bg-customPine',
    Disconnected: 'bg-customGray',
    '%future added value': 'bg-customGray',
};

export interface DeviceBatteriesProps {
    device: DeviceBatteries_device$key;
    deviceId: string;
    siteId: string;
    timeRange: Duration;
}

export const DeviceBatteries: FC<DeviceBatteriesProps> = ({ device, deviceId, siteId, timeRange }) => {
    const { hasAssetsWrite, hasTasksWrite } = useUserPermissions();
    const navigate = useExtendedNavigate();
    const data = useFragment(Fragment, device);

    let durationUnits: Unit[];
    if (timeRange.as('days') >= 1) {
        durationUnits = ['h', 'm'];
    } else {
        durationUnits = ['d', 'h', 'm'];
    }

    const timeRangeString = humanizeDuration(timeRange.as('milliseconds'), {
        largest: 1,
        round: true,
        units: durationUnits,
    });

    const stringRows = data.battery?.strings.strings ?? [];

    let averageAge;
    if (data.battery?.averageAge !== null) {
        averageAge = Duration.fromISO(data.battery?.averageAge).as('years');
        averageAge = round(averageAge, 1);
    } else {
        averageAge = null;
    }

    let timeInFloatChargingHours = 0;
    const totalTimeInHours = timeRange.as('hours');

    const statusBarData: BarDataType[] = [];
    if (data.battery?.metrics.statusBreakdown) {
        for (const status of data.battery.metrics.statusBreakdown) {
            if (status.time == null || status.time <= 0) {
                continue;
            }
            if (status.status === 'FloatCharging') {
                timeInFloatChargingHours = status.time;
            }
            statusBarData.push({
                label: batteryStatusToString(status.status),
                value: status.time,
                bgClass: BatteryStatusToColor[status.status],
            });
        }
    }

    const statusTimePercentageString = (timeInFloatChargingHours / totalTimeInHours) * 100;
    const statusBarDataHoursCount = statusBarData.reduce((acc, curr) => acc + curr.value, 0);

    // if we don't have 24 hours of data, add a "no data" bar
    if (Math.round(statusBarDataHoursCount) < 24) {
        statusBarData.push({
            label: 'No data',
            value: totalTimeInHours - statusBarDataHoursCount,
            bgClass: 'bg-customWhiteEggplant',
        });
    }

    let voltageMetric: string;
    let currentMetric: string;
    let temperatureMetric: string;
    let statusMetric: string;
    let totalReserveTime;
    if (data.battery?.metrics?.latestVoltage) {
        voltageMetric = `${numberToLocaleString(data.battery.metrics.latestVoltage)}V`;
    } else {
        voltageMetric = '-V';
    }
    if (data.battery?.metrics?.latestCurrent) {
        currentMetric = `${numberToLocaleString(data.battery.metrics.latestCurrent)}A`;
    } else {
        currentMetric = '-A';
    }
    if (data.battery?.metrics?.latestTemperature) {
        temperatureMetric = `${numberToLocaleString(data.battery.metrics.latestTemperature)}°C`;
    } else {
        temperatureMetric = '-°C';
    }
    if (data.battery?.metrics?.latestStatus) {
        statusMetric = `${batteryStatusToString(data.battery?.metrics?.latestStatus)}`;
    } else {
        statusMetric = '-';
    }
    if (data.battery?.reserveTime) {
        totalReserveTime = data.battery?.reserveTime.toFixed(0);
        totalReserveTime = `out of ${totalReserveTime} hours`;
    } else {
        totalReserveTime = '-';
    }

    const designReserveTimeForThresholds = data.battery?.reserveTime ?? DefaultReserveTime;

    const badReserveTimeCutoff = designReserveTimeForThresholds * 0.9;
    const goodReserveTimeCutoff = designReserveTimeForThresholds * 1.1;
    const minReserveTimeCutoff = 0;
    const maxReserveTimeCutoff = designReserveTimeForThresholds * 2;

    let estimatedReserveTime: number | null;
    if (data.battery?.estimatedReserveTime) {
        estimatedReserveTime = round(data.battery.estimatedReserveTime, 1);
    } else {
        estimatedReserveTime = null;
    }

    const supportBatteryTesting = data.type.features.battery.testing;

    return (
        <div className='space-y-4'>
            <div className='flex flex-row gap-4'>
                <div className='font-CynthoNext-SemiBold text-customEggplant text-xl'>Batteries</div>
                <div className='flex flex-col justify-start items-end flex-grow font-CynthoNext-SemiBold text-xl'>
                    <div>{percentageToStringCapped(Math.round(statusTimePercentageString))}</div>
                    <div className='text-xs font-CynthoNext-Regular'>Float Charging - last {timeRangeString}</div>
                </div>
                <div>
                    <Menu
                        id={`device-menu-${deviceId}`}
                        menuItems={[
                            {
                                name: hasAssetsWrite ? 'Edit device' : 'View device',
                                onClick: () => navigate({ pathname: Paths.EditDevice, params: { id: deviceId } }),
                            },
                            {
                                name: 'Schedule Test',
                                onClick: () =>
                                    navigate({ pathname: Paths.TestsScheduleTest, search: { device: deviceId } }),
                                disabled: !hasTasksWrite || data.monitorOnly || !supportBatteryTesting,
                                // TODO: Add disabled message when supported - should either tell user that they dont have permission or device is in monitorOnly mode
                            },
                            {
                                name: 'View Battery Report',
                                onClick: () =>
                                    navigate({
                                        pathname: Paths.ReportBatteriesViewSiteDevice,
                                        params: { siteId, deviceId },
                                    }),
                            },
                        ]}
                        variant='small'
                    />
                </div>
            </div>

            <StackedHorizontalBar
                data={statusBarData}
                valueFormatter={value => {
                    if (value == null) {
                        return '-';
                    }
                    return humanizeDuration(Duration.fromObject({ hours: value }).as('milliseconds'), {
                        largest: 1,
                        round: true,
                        units: ['h', 'm'],
                    });
                }}
            />

            <div className='flex flex-row justify-start gap-3 mb-4'>
                <IconWithStatus
                    title='Battery Voltage'
                    icon={<VoltmeterIcon />}
                    label={voltageMetric}
                    link={makeLinkToMetric(deviceId, { metric: 'BatteryVoltage', op: Operation.Average })}
                />
                <IconWithStatus
                    title='Battery Current'
                    icon={<AmmeterIcon />}
                    label={currentMetric}
                    link={makeLinkToMetric(deviceId, { metric: 'BatteryCurrent', op: Operation.Average })}
                />
                <IconWithStatus
                    title='Battery Temperature'
                    icon={<ThermometerIcon />}
                    label={temperatureMetric}
                    link={makeLinkToMetric(deviceId, { metric: 'BatteryTemperature', op: Operation.Average })}
                />
                <IconWithStatus title='Battery Status' icon={<BatteryEmptyIcon />} label={statusMetric} />
            </div>

            <div className='grid grid-cols-2'>
                <div className='col-start-1'>
                    <div className={style.chart_title}>Reserve Time</div>
                    <div className={style.chart_container}>
                        <StraightAngleGauge
                            thresholdValue1={badReserveTimeCutoff}
                            thresholdValue2={goodReserveTimeCutoff}
                            value={estimatedReserveTime}
                            unit='hours'
                            subtitle={totalReserveTime}
                            min={minReserveTimeCutoff}
                            max={maxReserveTimeCutoff}
                        ></StraightAngleGauge>
                    </div>
                </div>
                <div className='col-start-2'>
                    <div className={style.chart_title}>Average Age</div>
                    <div className={style.chart_container}>
                        <StraightAngleGauge
                            thresholdValue1={7}
                            thresholdValue2={10}
                            slice1Colour={Theme.customPine}
                            slice2Colour={Theme.customMustard}
                            slice3Colour={Theme.customCoral}
                            value={averageAge}
                            unit={'years'}
                            subtitle=''
                            max={15}
                        ></StraightAngleGauge>
                    </div>
                </div>
            </div>

            <MetricViewFrame
                title='Strings'
                view='list'
                contentClassName='h-96'
                scroll
                subtitle={data.battery?.strings.count ? `${data.battery?.strings.count} strings` : 'No strings'}
            >
                {stringRows.map(stringRow => (
                    <StringRow key={stringRow.id} stringRow={stringRow} />
                ))}

                {stringRows.length === 0 && (
                    <>
                        <div className='invisible' style={{ aspectRatio: '1/1' }}></div>
                        <div className='col-span-4 grid place-items-center space-y-4'>
                            <span>No battery strings configured</span>
                            <Button
                                buttonText={'Edit device'}
                                onClick={() => navigate({ pathname: Paths.EditDevice, params: { id: deviceId } })}
                                size='small'
                                variant={'primary'}
                                disabled={!hasAssetsWrite}
                                disabledMessage={'You do not have permission to edit devices'}
                            />
                        </div>
                        <div className='invisible' style={{ aspectRatio: '1/1' }}></div>
                    </>
                )}
            </MetricViewFrame>
        </div>
    );
};

const Fragment = graphql`
    fragment DeviceBatteries_device on Device {
        monitorOnly
        type {
            features {
                battery {
                    testing
                }
            }
        }
        battery {
            metrics {
                statusBreakdown(begin: $begin, end: $end) {
                    status
                    time(unit: Hours)
                }
                latestStatus
                latestVoltage
                latestCurrent
                latestTemperature
            }
            strings {
                count
                strings {
                    id
                    ...StringRow_data
                }
            }
            averageAge
            estimatedReserveTime(unit: Hours)
            reserveTime(unit: Hours)
        }
    }
`;
