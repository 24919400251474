/**
 * @generated SignedSource<<478f643525421f2321b50d15d6b168b3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BatteryStatus = "BoostCharging" | "Charging" | "Discharging" | "Disconnected" | "FloatCharging" | "Idle" | "Missing" | "Unknown" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DeviceBatteries_device$data = {
  readonly battery: {
    readonly averageAge: string | null;
    readonly estimatedReserveTime: number | null;
    readonly metrics: {
      readonly latestCurrent: number | null;
      readonly latestStatus: BatteryStatus;
      readonly latestTemperature: number | null;
      readonly latestVoltage: number | null;
      readonly statusBreakdown: ReadonlyArray<{
        readonly status: BatteryStatus;
        readonly time: number | null;
      }> | null;
    };
    readonly reserveTime: number | null;
    readonly strings: {
      readonly count: number;
      readonly strings: ReadonlyArray<{
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"StringRow_data">;
      }>;
    };
  };
  readonly monitorOnly: boolean;
  readonly type: {
    readonly features: {
      readonly battery: {
        readonly testing: boolean;
      };
    };
  };
  readonly " $fragmentType": "DeviceBatteries_device";
};
export type DeviceBatteries_device$key = {
  readonly " $data"?: DeviceBatteries_device$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeviceBatteries_device">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "unit",
    "value": "Hours"
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "begin"
    },
    {
      "kind": "RootArgument",
      "name": "end"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeviceBatteries_device",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "monitorOnly",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceType",
      "kind": "LinkedField",
      "name": "type",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DeviceTypeFeatures",
          "kind": "LinkedField",
          "name": "features",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DeviceTypeBatteryFeatures",
              "kind": "LinkedField",
              "name": "battery",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "testing",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceBattery",
      "kind": "LinkedField",
      "name": "battery",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DeviceBatteryMetrics",
          "kind": "LinkedField",
          "name": "metrics",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "begin",
                  "variableName": "begin"
                },
                {
                  "kind": "Variable",
                  "name": "end",
                  "variableName": "end"
                }
              ],
              "concreteType": "BatteryStatusTimeBreakdown",
              "kind": "LinkedField",
              "name": "statusBreakdown",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": (v0/*: any*/),
                  "kind": "ScalarField",
                  "name": "time",
                  "storageKey": "time(unit:\"Hours\")"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "latestStatus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "latestVoltage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "latestCurrent",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "latestTemperature",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DeviceBatteryStrings",
          "kind": "LinkedField",
          "name": "strings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "count",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "DeviceBatteryString",
              "kind": "LinkedField",
              "name": "strings",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "StringRow_data"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "averageAge",
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v0/*: any*/),
          "kind": "ScalarField",
          "name": "estimatedReserveTime",
          "storageKey": "estimatedReserveTime(unit:\"Hours\")"
        },
        {
          "alias": null,
          "args": (v0/*: any*/),
          "kind": "ScalarField",
          "name": "reserveTime",
          "storageKey": "reserveTime(unit:\"Hours\")"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
};
})();

(node as any).hash = "6e9055e19c536218a3ec3d00250c6ef5";

export default node;
