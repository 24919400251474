import React, { ReactElement, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import graphql from 'babel-plugin-relay/macro';
import { FilterRange } from 'components/FilterRange';
import { RangeFilter } from 'filters/common';
import { useQuery } from 'lib/query-helpers';

import { PageTypes } from '../types';
import { getStringFilterFromPageType } from '../util';
import {
    TaskDistributionType,
    TaskFilterRange_taskDistributionQuery,
} from './__generated__/TaskFilterRange_taskDistributionQuery.graphql';

export interface TaskFilterRangeProps {
    title: string;
    distributionType: TaskDistributionType;
    countLabel?: [string, string];
    range?: [number, number];
    current: RangeFilter | null | undefined;
    discrete?: boolean;
    showBack?: boolean;
    onClearOrBack: () => void;
    onClose: () => void;
    onSubmit: (items: RangeFilter | null) => void;
}

export function TaskFilterRange({
    title,
    distributionType,
    countLabel = ['Min Count', 'Max Count'],
    range,
    current,
    discrete,
    showBack,
    onClearOrBack,
    onClose,
    onSubmit,
}: TaskFilterRangeProps): ReactElement {
    // FIXME: Casting isnt the best way of handling undefined type check. Currently, there are multiple ways people are dealing with this.
    // https://github.com/remix-run/react-router/issues/8200
    const { pageType } = useParams() as { pageType: PageTypes };

    const { data } = useQuery<TaskFilterRange_taskDistributionQuery>(
        graphql`
            query TaskFilterRange_taskDistributionQuery($type: TaskDistributionType!, $taskState: [TaskStateFilter!]) {
                taskDistribution(type: $type, taskState: $taskState) {
                    distribution {
                        key
                        value
                    }
                    minimum
                    maximum
                }
            }
        `,
        {
            type: distributionType,
            taskState: getStringFilterFromPageType(pageType),
        }
    );

    const distributionRange = useMemo<[number, number] | null>(() => {
        if (!data || data.taskDistribution.minimum == null || data.taskDistribution.maximum == null) {
            return null;
        }

        return [data.taskDistribution.minimum, data.taskDistribution.maximum];
    }, [data]);

    return (
        <FilterRange
            title={title}
            onSubmit={onSubmit}
            onClose={onClose}
            limit={range}
            current={current}
            discrete={discrete}
            showBack={showBack}
            onClearOrBack={onClearOrBack}
            countLabel={countLabel}
            distribution={data?.taskDistribution}
            distributionRange={distributionRange}
        />
    );
}
