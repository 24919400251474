import React, { FC } from 'react';

import { SiteIcon } from '@accesstel/pcm-ui';

import { SmallerLabelWithIcon } from '../SmallerLabelWithIcon';

interface Device {
    readonly site: {
        readonly name: string;
    };
}

export interface CardLocationDisplayProps {
    readonly devices: readonly Device[];
}

export const CardLocationDisplay: FC<CardLocationDisplayProps> = ({ devices }) => {
    // Find used sites
    const usedSites = new Set<string>();
    devices.forEach(device => {
        usedSites.add(device.site.name);
    });

    let displayText: string;

    if (usedSites.size === 1) {
        // All devices are in the same site
        const siteName = Array.from(usedSites)[0];
        displayText = siteName;
    } else if (usedSites.size > 1) {
        displayText = `${Array.from(usedSites)[0]} and ${usedSites.size - 1} more`;
    } else {
        // NOTE: This is not a valid case
        displayText = 'No devices';
    }

    return <SmallerLabelWithIcon label={displayText} icon={<SiteIcon />} classNames='text-customEggplantWhite' />;
};
