import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { fetchQuery, useFragment, useRelayEnvironment } from 'react-relay';

import { Domain, MenuItemType, Modal, Theme } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { TestSelectModalContent } from 'components';
import { SelectionWithTestIds } from 'components/SelectTestPane';
import { TimeseriesOffsetSeries } from 'lib/dateChart';
import { TimeseriesOffsetPoint } from 'lib/dateChart';
import { useZoomableData } from 'lib/line-chart-zoom';
import { logError } from 'lib/log';
import { formatBatteryTestName } from 'lib/textFormatters';
import isEqual from 'lodash.isequal';
import { DateTime, Duration } from 'luxon';

import { MetricLine } from '../../components/MetricsLine';
import { ChartRefreshInterval } from '../../settings';
import {
    AggregatedDataPoint,
    MetricsData,
    formatAPIDate,
    getDisplayInterval,
    spliceAllData,
    toTimeSeriesOffsetData,
} from '../common';
import { AnnotationType, DefaultAnnotationTypes, useAnnotations } from '../lib/annotations';
import { useCDFOverlays } from '../lib/cdf';
import { MenuOptions } from '../lib/common';
import { supportsTemperatureMetric } from '../lib/feature-support';
import { DataDisplaySingleQuery } from './__generated__/DataDisplaySingleQuery.graphql';
import { DataDisplay_test$key } from './__generated__/DataDisplay_test.graphql';

const DefaultChartXDomain: Domain<Duration> = ['dataMin', 'dataMax'];
const InitialLiveXBuffer = Duration.fromObject({ minutes: 15 });
const DefaultXMinTicks = Math.round(InitialLiveXBuffer.as('minutes')) + 1;

interface TestMetrics {
    id: string;
    commencedTime: string;
    completedTime: string;

    label: string;

    metrics: MetricsData;
    zoomedMetrics?: MetricsData;
}

const DataQuery = graphql`
    query DataDisplaySingleQuery(
        $testId: ID!
        $interval: Duration
        $begin: Duration
        $end: Duration
        $relativeTestIds: [String!]
        $includeRelativeTests: Boolean = false
    ) {
        batteryTestResult(id: $testId) {
            commencedTime
            completedTime
            abortedTime
            state
            aggregatedVoltage(unit: Volts, interval: $interval, begin: $begin, end: $end) {
                timestamp
                offset
                average
            }
            aggregatedTemperature(unit: Celsius, interval: $interval, begin: $begin, end: $end) {
                timestamp
                offset
                average
            }
            aggregatedCurrent(unit: Amps, interval: $interval, begin: $begin, end: $end) {
                timestamp
                offset
                average
            }
            aggregatedPower(unit: Watt, interval: $interval, begin: $begin, end: $end) {
                timestamp
                offset
                average
            }
            device @include(if: $includeRelativeTests) {
                tests(ids: $relativeTestIds) {
                    data {
                        id
                        commencedTime
                        completedTime
                        task {
                            name
                        }
                        cause
                        aggregatedVoltage(unit: Volts, interval: $interval, begin: $begin, end: $end) {
                            timestamp
                            offset
                            average
                        }
                        aggregatedTemperature(unit: Celsius, interval: $interval, begin: $begin, end: $end) {
                            timestamp
                            offset
                            average
                        }
                        aggregatedCurrent(unit: Amps, interval: $interval, begin: $begin, end: $end) {
                            timestamp
                            offset
                            average
                        }
                        aggregatedPower(unit: Watt, interval: $interval, begin: $begin, end: $end) {
                            timestamp
                            offset
                            average
                        }
                    }
                }
            }
        }
    }
`;

interface DataDisplayProps {
    test: DataDisplay_test$key;
}

export const DataDisplay: FC<DataDisplayProps> = ({ test }) => {
    const environment = useRelayEnvironment();
    const [selectedMenuOption, setSelectedMenuOption] = useState<string | undefined>(MenuOptions.ViewAll);
    const [selectedTestsCompare, setSelectedTestsCompare] = useState<SelectionWithTestIds | null>(null);
    const [selectedLegend, setSelectedLegend] = useState<null | string>(null);
    const [isTestSelectModalOpen, setIsTestSelectModalOpen] = useState(false);
    const [visibleAnnotations, setVisibleAnnotations] = useState<AnnotationType[]>(DefaultAnnotationTypes);

    const info = useFragment(
        graphql`
            fragment DataDisplay_test on DeviceBatteryTestResults {
                id
                commencedTime
                completedTime
                abortedTime
                state
                coupDeFouet {
                    float
                    troughVoltage
                    troughOffset
                    plateauVoltage
                    plateauOffset
                    dip
                }
                markers {
                    timestamp
                    offset
                    type
                    source
                    description
                }
                task {
                    settings {
                        ... on BatteryTestTypeQuick {
                            reserveTime
                            threshold
                        }
                    }
                }
                device {
                    battery {
                        quickTestFailThreshold
                        quickTestCheckPercent
                        reserveTime(unit: Minutes)
                        allowedVoltage {
                            minimum
                            maximum
                        }
                    }
                    tests(filters: { state: [Passed, Failed, Aborted, Inconclusive] }) {
                        data {
                            id
                            commencedTime
                            completedTime
                        }
                    }
                }
            }
        `,
        test
    );

    let isLiveUpdating = false;
    let startTime: string | null = null;
    let endTime: string | null = null;

    if (info.state === 'InProgress') {
        isLiveUpdating = true;
        startTime = info.commencedTime as string;
    } else if (info.state !== 'Scheduled') {
        startTime = info.commencedTime as string;
        endTime = info.completedTime as string;

        if (info.completedTime || info.abortedTime) {
            const finishedTime = info.completedTime ?? info.abortedTime;
            isLiveUpdating =
                DateTime.now()
                    .diff(DateTime.fromISO(finishedTime as string))
                    .as('minutes') < 5;
        }
    }

    const fetchData = useCallback(
        (interval: Duration | null, begin: Duration | null, end: Duration | null) => {
            if (!interval && startTime) {
                if (!endTime) {
                    interval = getDisplayInterval(DateTime.fromISO(startTime), DateTime.now());
                } else {
                    interval = getDisplayInterval(DateTime.fromISO(startTime), DateTime.fromISO(endTime));
                }
            }

            return fetchQuery<DataDisplaySingleQuery>(environment, DataQuery, {
                testId: info.id,
                begin: begin?.toISO(),
                end: end?.toISO(),
                interval: interval?.toISO(),
                relativeTestIds: selectedTestsCompare?.ids,
                includeRelativeTests: selectedTestsCompare !== null,
            }).toPromise();
        },
        [startTime, environment, info.id, selectedTestsCompare, endTime]
    );

    const { chartDomain, doZoom, baseData, hasError, reloadData, zoomData } = useZoomableData(
        DefaultChartXDomain,
        (_, zoomed) => {
            if (!zoomed) {
                setSelectedMenuOption(MenuOptions.ViewAll);
                return;
            }

            setSelectedMenuOption(undefined);
        },
        fetchData
    );

    useEffect(() => {
        if (selectedTestsCompare) {
            reloadData();
        }
    }, [reloadData, selectedTestsCompare]);

    // provide a fixed scale for a while after starting a test
    let overrideChartDomain: Domain<Duration> = chartDomain;
    let minXTicks = 0;
    if (
        zoomData == null &&
        baseData != null &&
        (isLiveUpdating ||
            info.state === 'Scheduled' ||
            info.state === 'Waiting' ||
            info.state === 'SmartStart' ||
            baseData.batteryTestResult?.aggregatedVoltage?.length === 0)
    ) {
        const chartData = baseData.batteryTestResult?.aggregatedVoltage;

        if (chartData && chartData.length > 0) {
            const currentDuration = Duration.fromISO(chartData[chartData.length - 1].offset as string);
            if (currentDuration < InitialLiveXBuffer) {
                overrideChartDomain = [chartDomain[0], InitialLiveXBuffer];
                minXTicks = DefaultXMinTicks;
            }
        } else {
            overrideChartDomain = [chartDomain[0], InitialLiveXBuffer];
            minXTicks = DefaultXMinTicks;
        }
    }

    // Live data
    useEffect(() => {
        if (isLiveUpdating) {
            const handle = setInterval(reloadData, ChartRefreshInterval);
            return () => {
                clearInterval(handle);
            };
        }
    }, [isLiveUpdating, reloadData]);

    const [data, subData] = useMemo<[MetricsData, TestMetrics[]]>(() => {
        if (baseData?.batteryTestResult) {
            const baseMetrics: MetricsData = [
                toTimeSeriesOffsetData(baseData.batteryTestResult.aggregatedVoltage as AggregatedDataPoint[]),
                toTimeSeriesOffsetData(baseData.batteryTestResult.aggregatedTemperature as AggregatedDataPoint[]),
                toTimeSeriesOffsetData(baseData.batteryTestResult.aggregatedCurrent as AggregatedDataPoint[], true),
                toTimeSeriesOffsetData(baseData.batteryTestResult.aggregatedPower as AggregatedDataPoint[], true),
            ];

            // only compare tests that are complete
            const otherTests = baseData.batteryTestResult.device
                ? baseData.batteryTestResult.device.tests.data
                      .filter(test => test.id !== info.id && test.completedTime !== null && test.commencedTime !== null)
                      .sort(
                          (testA, testB) =>
                              DateTime.fromISO(testA.commencedTime as string).toMillis() -
                              DateTime.fromISO(testB.commencedTime as string).toMillis()
                      )
                : [];

            const otherMetrics: TestMetrics[] = otherTests.map(test => ({
                id: test.id,
                label: test.task?.name ?? formatBatteryTestName(test.cause),
                commencedTime: test.commencedTime as string,
                completedTime: test.completedTime as string,
                metrics: [
                    toTimeSeriesOffsetData(test.aggregatedVoltage as AggregatedDataPoint[]),
                    toTimeSeriesOffsetData(test.aggregatedTemperature as AggregatedDataPoint[]),
                    toTimeSeriesOffsetData(test.aggregatedCurrent as AggregatedDataPoint[], true),
                    toTimeSeriesOffsetData(test.aggregatedPower as AggregatedDataPoint[], true),
                ] as MetricsData,
            }));

            if (zoomData?.batteryTestResult) {
                const overlay: MetricsData = [
                    toTimeSeriesOffsetData(zoomData.batteryTestResult.aggregatedVoltage as AggregatedDataPoint[]),
                    toTimeSeriesOffsetData(zoomData.batteryTestResult.aggregatedTemperature as AggregatedDataPoint[]),
                    toTimeSeriesOffsetData(zoomData.batteryTestResult.aggregatedCurrent as AggregatedDataPoint[], true),
                    toTimeSeriesOffsetData(zoomData.batteryTestResult.aggregatedPower as AggregatedDataPoint[], true),
                ];

                const otherMetricsWithOverlay: TestMetrics[] = otherMetrics
                    .filter(test => zoomData.batteryTestResult?.device?.tests.data.find(d => d.id === test.id))
                    .map(test => {
                        const zoomed = zoomData.batteryTestResult?.device?.tests.data.find(d => d.id === test.id);
                        const subDataOverlay = [
                            toTimeSeriesOffsetData(zoomed!.aggregatedVoltage as AggregatedDataPoint[]),
                            toTimeSeriesOffsetData(zoomed!.aggregatedTemperature as AggregatedDataPoint[]),
                            toTimeSeriesOffsetData(zoomed!.aggregatedCurrent as AggregatedDataPoint[], true),
                            toTimeSeriesOffsetData(zoomed!.aggregatedPower as AggregatedDataPoint[], true),
                        ] as MetricsData;

                        const zoomedMetrics = spliceAllData(test.metrics, subDataOverlay);

                        return {
                            ...test,
                            zoomedMetrics,
                        };
                    });

                return [spliceAllData(baseMetrics, overlay), otherMetricsWithOverlay];
            } else {
                return [baseMetrics, otherMetrics];
            }
        } else {
            return [[null, null, null, null], []];
        }
    }, [baseData, zoomData, info]);

    const [cdfMarkers, cdfMenuOptions] = useCDFOverlays(
        startTime ? DateTime.fromISO(startTime) : DateTime.now(),
        endTime ? DateTime.fromISO(endTime) : DateTime.now(),
        chartDomain,
        info.coupDeFouet,
        cdfDomain => {
            doZoom(cdfDomain);
            setSelectedMenuOption(MenuOptions.ViewCDF);
        }
    );

    let threshold: number | null = null;
    if (info.task?.settings.threshold != null) {
        threshold = info.task.settings.threshold;
    } else if (info.device.battery.quickTestFailThreshold != null) {
        threshold = info.device.battery.quickTestFailThreshold;
    }

    let thresholdDuration: Duration | null = null;
    if (info.task?.settings?.reserveTime != null) {
        thresholdDuration = Duration.fromObject({ minutes: info.task.settings.reserveTime });
    } else if (info.device.battery.quickTestCheckPercent != null && info.device.battery.reserveTime != null) {
        thresholdDuration = Duration.fromObject({
            minutes: info.device.battery.reserveTime * (info.device.battery.quickTestCheckPercent / 100),
        });
    }

    const designReserveTime = info.device.battery.reserveTime;

    const [voltageMarkers, allChartMarkers] = useAnnotations(
        {
            startTime: startTime ? DateTime.fromISO(startTime) : null,
            endTime: endTime ? DateTime.fromISO(endTime) : null,
            quickTestThresholdVoltage: threshold,
            quickTestThresholdTime: thresholdDuration,
            designReserveTime,
            minimumAllowedVoltage: info.device.battery.allowedVoltage?.minimum,
            maximumAllowedVoltage: info.device.battery.allowedVoltage?.maximum,
        },
        info.markers
    );

    const markers = [...cdfMarkers, ...voltageMarkers];

    const uniqueAnnotationTypes = new Set<AnnotationType>();
    for (const marker of markers) {
        uniqueAnnotationTypes.add(marker.annotationType);
    }
    for (const marker of allChartMarkers) {
        uniqueAnnotationTypes.add(marker.annotationType);
    }

    const uniqueAnnotationTypesAsArray = Array.from(uniqueAnnotationTypes);

    const zoomMenuOptions: MenuItemType[] = [
        {
            id: MenuOptions.ViewAll,
            name: 'All',
            onClick() {
                doZoom(null);
                setSelectedMenuOption(MenuOptions.ViewAll);
            },
        },
        ...cdfMenuOptions,
    ];

    const testIdsWithCurrentFiltered = info.device.tests.data.map(t => t.id).filter(t => t !== info.id);

    const selectTestMenuOptions = useMemo<SelectionWithTestIds[]>(() => {
        if (info.commencedTime) {
            const items: SelectionWithTestIds[] = [];
            const relativeTests = info.device.tests.data.filter(
                test => test.id !== info.id && test.completedTime !== null && test.commencedTime !== null
            );
            const previousTests = relativeTests.filter(
                test => DateTime.fromISO(test.commencedTime as string) < DateTime.fromISO(info.commencedTime as string)
            );
            const futureTests = relativeTests.filter(
                test => DateTime.fromISO(test.commencedTime as string) > DateTime.fromISO(info.commencedTime as string)
            );

            if (previousTests.length > 0) {
                if (previousTests.length === 1) {
                    // previous test is the first test
                    items.push({ text: 'First/Previous Test', ids: [previousTests[0].id] });
                } else {
                    items.push({ text: 'First Test', ids: [previousTests[0].id] });
                    items.push({ text: 'Previous Test', ids: [previousTests.at(-1)!.id] });
                }
            }

            if (futureTests.length > 0) {
                if (futureTests.length === 1) {
                    // most recent test is the next test
                    items.push({ text: 'Next/Most Recent Test', ids: [futureTests[0].id] });
                } else {
                    items.push({ text: 'Next Test', ids: [futureTests[0].id] });
                    items.push({ text: 'Most Recent Test', ids: [futureTests.at(-1)!.id] });
                }
            }

            if (relativeTests.length > 0) {
                const ids = [...previousTests.map(test => test.id), ...futureTests.map(test => test.id)];
                const count = ids.length;
                items.push({
                    text: 'All Tests',
                    subText: count > 1 ? `(${count} tests)` : `(${count} test)`,
                    ids,
                });
            }

            if (testIdsWithCurrentFiltered.length !== 0) {
                items.push({
                    ids: [],
                    text: 'Custom',
                    onClick: () => setIsTestSelectModalOpen(true),
                });
            }

            return items;
        } else {
            return [];
        }
    }, [info.commencedTime, info.device.tests.data, info.id, testIdsWithCurrentFiltered.length]);

    if (baseData && !baseData.batteryTestResult) {
        // Test should always exist because this shouldnt be rendered without one.
        logError('Invalid situation found. Should not be able to have no test inside this component');
        return <></>;
    }

    const voltageData = data[0];
    const temperatureData = data[1];
    const currentData = data[2];
    const powerData = data[3];

    const otherTestsVoltageData: Map<string, TimeseriesOffsetPoint[]> = new Map<string, TimeseriesOffsetPoint[]>();
    const otherTestsTemperatureData: Map<string, TimeseriesOffsetPoint[]> = new Map<string, TimeseriesOffsetPoint[]>();
    const otherTestsCurrentData: Map<string, TimeseriesOffsetPoint[]> = new Map<string, TimeseriesOffsetPoint[]>();
    const otherTestsPowerData: Map<string, TimeseriesOffsetPoint[]> = new Map<string, TimeseriesOffsetPoint[]>();

    subData.forEach(test => {
        const voltageSubData = test.zoomedMetrics ? test.zoomedMetrics[0] : test.metrics[0];
        const temperatureSubData = test.zoomedMetrics ? test.zoomedMetrics[1] : test.metrics[1];
        const currentSubData = test.zoomedMetrics ? test.zoomedMetrics[2] : test.metrics[2];
        const powerSubData = test.zoomedMetrics ? test.zoomedMetrics[3] : test.metrics[3];

        if (voltageSubData) {
            otherTestsVoltageData.set(test.id, voltageSubData);
        }
        if (temperatureSubData) {
            otherTestsTemperatureData.set(test.id, temperatureSubData);
        }
        if (currentSubData) {
            otherTestsCurrentData.set(test.id, currentSubData);
        }
        if (powerSubData) {
            otherTestsPowerData.set(test.id, powerSubData);
        }
    });

    const isTemperatureSupported = supportsTemperatureMetric(data);

    const voltageSeries: TimeseriesOffsetSeries[] | undefined = selectedTestsCompare
        ? [
              {
                  name: 'Current Test',
                  lineColor: Theme.customCoral,
                  points: voltageData ?? [],
                  id: info.id,
                  dots:
                      info.id === selectedLegend
                          ? { stroke: Theme.customCoral, strokeWidth: 1, fill: Theme.customCoral }
                          : false,
              },
              ...selectedTestsCompare.ids.map(id => {
                  const prettyName = getPrettyTestName(id, selectTestMenuOptions, subData);
                  return {
                      name: prettyName,
                      lineColor: id === selectedLegend ? Theme.customPine : Theme.customEggplant,
                      dots:
                          id === selectedLegend
                              ? { stroke: Theme.customPine, strokeWidth: 1, fill: Theme.customPine }
                              : false,
                      points: otherTestsVoltageData.get(id) ?? [],
                      id,
                  };
              }),
          ]
        : undefined;

    const temperatureSeries: TimeseriesOffsetSeries[] | undefined =
        selectedTestsCompare && isTemperatureSupported
            ? [
                  {
                      name: 'Current Test',
                      lineColor: Theme.customCoral,
                      points: temperatureData ?? [],
                      id: info.id,
                      dots:
                          info.id === selectedLegend
                              ? { stroke: Theme.customCoral, strokeWidth: 1, fill: Theme.customCoral }
                              : false,
                  },
                  ...selectedTestsCompare.ids.map(id => {
                      const prettyName = getPrettyTestName(id, selectTestMenuOptions, subData);
                      return {
                          name: prettyName,
                          lineColor: id === selectedLegend ? Theme.customPine : Theme.customEggplant,
                          dots:
                              id === selectedLegend
                                  ? { stroke: Theme.customPine, strokeWidth: 1, fill: Theme.customPine }
                                  : false,
                          points: otherTestsTemperatureData.get(id) ?? [],
                          id,
                      };
                  }),
              ]
            : undefined;

    const currentSeries: TimeseriesOffsetSeries[] | undefined = selectedTestsCompare
        ? [
              {
                  name: 'Current Test',
                  lineColor: Theme.customCoral,
                  points: currentData ?? [],
                  id: info.id,
                  dots:
                      info.id === selectedLegend
                          ? { stroke: Theme.customCoral, strokeWidth: 1, fill: Theme.customCoral }
                          : false,
              },
              ...selectedTestsCompare.ids.map(id => {
                  const prettyName = getPrettyTestName(id, selectTestMenuOptions, subData);
                  return {
                      name: prettyName,
                      lineColor: id === selectedLegend ? Theme.customPine : Theme.customEggplant,
                      dots:
                          id === selectedLegend
                              ? { stroke: Theme.customPine, strokeWidth: 1, fill: Theme.customPine }
                              : false,
                      points: otherTestsCurrentData.get(id) ?? [],
                      id,
                  };
              }),
          ]
        : undefined;

    const powerSeries: TimeseriesOffsetSeries[] | undefined = selectedTestsCompare
        ? [
              {
                  name: 'Current Test',
                  lineColor: Theme.customCoral,
                  points: powerData ?? [],
                  id: info.id,
                  dots:
                      info.id === selectedLegend
                          ? { stroke: Theme.customCoral, strokeWidth: 1, fill: Theme.customCoral }
                          : false,
              },
              ...selectedTestsCompare.ids.map(id => {
                  const prettyName = getPrettyTestName(id, selectTestMenuOptions, subData);
                  return {
                      name: prettyName,
                      lineColor: id === selectedLegend ? Theme.customPine : Theme.customEggplant,
                      dots:
                          id === selectedLegend
                              ? { stroke: Theme.customPine, strokeWidth: 1, fill: Theme.customPine }
                              : false,
                      points: otherTestsPowerData.get(id) ?? [],
                      id,
                  };
              }),
          ]
        : undefined;

    const handleLegendClick = (_: string, id?: string) => {
        if (id === selectedLegend) {
            setSelectedLegend(null);
        } else if (id) {
            setSelectedLegend(id);
        }
    };

    const voltageChartMarkers = [...markers, ...allChartMarkers].filter(marker =>
        visibleAnnotations.includes(marker.annotationType)
    );
    const otherChartMarkers = allChartMarkers.filter(marker => visibleAnnotations.includes(marker.annotationType));

    return (
        <>
            <MetricLine
                type='voltage'
                data={selectedTestsCompare ? undefined : voltageData}
                series={voltageSeries}
                error={hasError}
                onRetry={reloadData}
                xDomain={overrideChartDomain}
                xMinTicks={minXTicks}
                onZoom={doZoom}
                markers={voltageChartMarkers}
                menuItems={zoomMenuOptions}
                menuPlaceholder='Zoom'
                menuSelected={selectedMenuOption}
                testCompare={{
                    testMenuItems: selectTestMenuOptions,
                    setSelectedSelection: setSelectedTestsCompare,
                    selectedSelection: selectedTestsCompare,
                }}
                collapsible={selectedTestsCompare ? false : true}
                showLegend={selectedTestsCompare ? true : undefined}
                legendOnClick={handleLegendClick}
                allAnnotations={uniqueAnnotationTypesAsArray}
                visibleAnnotations={visibleAnnotations}
                onSetVisibleAnnotations={setVisibleAnnotations}
            />
            <MetricLine
                type='current'
                data={selectedTestsCompare ? undefined : currentData}
                series={currentSeries}
                error={hasError}
                onRetry={reloadData}
                xDomain={overrideChartDomain}
                xMinTicks={minXTicks}
                onZoom={doZoom}
                collapsible={true}
                markers={otherChartMarkers}
            />
            <MetricLine
                type='power'
                data={selectedTestsCompare ? undefined : powerData}
                series={powerSeries}
                error={hasError}
                onRetry={reloadData}
                xDomain={overrideChartDomain}
                xMinTicks={minXTicks}
                onZoom={doZoom}
                collapsible={true}
                markers={otherChartMarkers}
            />
            <MetricLine
                type='temperature'
                data={selectedTestsCompare ? undefined : temperatureData}
                series={temperatureSeries}
                error={hasError}
                onRetry={reloadData}
                xDomain={overrideChartDomain}
                xMinTicks={minXTicks}
                onZoom={doZoom}
                unsupported={!isTemperatureSupported}
                collapsible={true}
                markers={otherChartMarkers}
            />
            <Modal
                bgColor='bg-white'
                open={isTestSelectModalOpen}
                onHide={() => setIsTestSelectModalOpen(false)}
                closeButton={true}
            >
                {isTestSelectModalOpen && (
                    <TestSelectModalContent
                        testIds={testIdsWithCurrentFiltered}
                        isTestSelectModalOpen={isTestSelectModalOpen}
                        setIsCompareModalOpen={setIsTestSelectModalOpen}
                        setSelectedTestsCompare={setSelectedTestsCompare}
                    />
                )}
            </Modal>
        </>
    );
};
/**
 * name is prioritised as follows: Selection label > task name > test cause > id
 */
function getPrettyTestName(id: string, menuOptions: SelectionWithTestIds[], tests: TestMetrics[]) {
    const selectedTest = tests.find(test => test.id === id);

    const baseName = menuOptions.find(options => isEqual([id], options.ids))?.text ?? selectedTest?.label ?? id;
    const date = formatAPIDate(selectedTest?.commencedTime, false) ?? baseName; // WARN: Should not fallback as only tests that has commenced and completed time are included

    return date;
}
