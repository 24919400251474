/**
 * @generated SignedSource<<3b62c889a6fc5095867cd6873719224d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BatteryTestState = "Aborted" | "Analyzing" | "Failed" | "Finalizing" | "InProgress" | "Inconclusive" | "Passed" | "Scheduled" | "SmartStart" | "Waiting" | "%future added value";
export type BlocId = {
  bloc: number;
  string: number;
};
export type BlocDataDisplaySingleQuery$variables = {
  begin?: string | null;
  blocs?: ReadonlyArray<BlocId> | null;
  end?: string | null;
  interval?: string | null;
  testId: string;
};
export type BlocDataDisplaySingleQuery$data = {
  readonly batteryTestResult: {
    readonly abortedTime: string | null;
    readonly blocs: {
      readonly results: ReadonlyArray<{
        readonly aggregatedCurrent: ReadonlyArray<{
          readonly average: number | null;
          readonly offset: string;
          readonly timestamp: string;
        }> | null;
        readonly aggregatedPower: ReadonlyArray<{
          readonly average: number | null;
          readonly offset: string;
          readonly timestamp: string;
        }> | null;
        readonly aggregatedTemperature: ReadonlyArray<{
          readonly average: number | null;
          readonly offset: string;
          readonly timestamp: string;
        }> | null;
        readonly aggregatedVoltage: ReadonlyArray<{
          readonly average: number | null;
          readonly offset: string;
          readonly timestamp: string;
        }> | null;
        readonly bloc: number;
        readonly coupDeFouet: {
          readonly dip: number;
          readonly float: number;
          readonly plateauOffset: string;
          readonly plateauVoltage: number;
          readonly troughOffset: string;
          readonly troughVoltage: number;
        } | null;
        readonly string: number;
      }>;
    };
    readonly commencedTime: string | null;
    readonly completedTime: string | null;
    readonly state: BatteryTestState;
  } | null;
};
export type BlocDataDisplaySingleQuery = {
  response: BlocDataDisplaySingleQuery$data;
  variables: BlocDataDisplaySingleQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "begin"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "blocs"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "interval"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "testId"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "testId"
  }
],
v6 = {
  "kind": "Variable",
  "name": "begin",
  "variableName": "begin"
},
v7 = {
  "kind": "Variable",
  "name": "end",
  "variableName": "end"
},
v8 = {
  "kind": "Variable",
  "name": "interval",
  "variableName": "interval"
},
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "timestamp",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "offset",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "average",
    "storageKey": null
  }
],
v10 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "blocs",
      "variableName": "blocs"
    }
  ],
  "concreteType": "BlocTestResults",
  "kind": "LinkedField",
  "name": "blocs",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BlocTestResult",
      "kind": "LinkedField",
      "name": "results",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "string",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bloc",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            {
              "kind": "Literal",
              "name": "unit",
              "value": "Volts"
            }
          ],
          "concreteType": "AggregatedTimestampMetric",
          "kind": "LinkedField",
          "name": "aggregatedVoltage",
          "plural": true,
          "selections": (v9/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            {
              "kind": "Literal",
              "name": "unit",
              "value": "Celsius"
            }
          ],
          "concreteType": "AggregatedTimestampMetric",
          "kind": "LinkedField",
          "name": "aggregatedTemperature",
          "plural": true,
          "selections": (v9/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            {
              "kind": "Literal",
              "name": "unit",
              "value": "Amps"
            }
          ],
          "concreteType": "AggregatedTimestampMetric",
          "kind": "LinkedField",
          "name": "aggregatedCurrent",
          "plural": true,
          "selections": (v9/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            {
              "kind": "Literal",
              "name": "unit",
              "value": "Watt"
            }
          ],
          "concreteType": "AggregatedTimestampMetric",
          "kind": "LinkedField",
          "name": "aggregatedPower",
          "plural": true,
          "selections": (v9/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BatteryCoupDeFouet",
          "kind": "LinkedField",
          "name": "coupDeFouet",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "float",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "troughVoltage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "troughOffset",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "plateauVoltage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "plateauOffset",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dip",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "commencedTime",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completedTime",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "abortedTime",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BlocDataDisplaySingleQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "DeviceBatteryTestResults",
        "kind": "LinkedField",
        "name": "batteryTestResult",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "BlocDataDisplaySingleQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "DeviceBatteryTestResults",
        "kind": "LinkedField",
        "name": "batteryTestResult",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6185346e56d2aa24d54464c01b62abc3",
    "id": null,
    "metadata": {},
    "name": "BlocDataDisplaySingleQuery",
    "operationKind": "query",
    "text": "query BlocDataDisplaySingleQuery(\n  $testId: ID!\n  $interval: Duration\n  $begin: Duration\n  $end: Duration\n  $blocs: [BlocId!]\n) {\n  batteryTestResult(id: $testId) {\n    blocs(blocs: $blocs) {\n      results {\n        string\n        bloc\n        aggregatedVoltage(unit: Volts, interval: $interval, begin: $begin, end: $end) {\n          timestamp\n          offset\n          average\n        }\n        aggregatedTemperature(unit: Celsius, interval: $interval, begin: $begin, end: $end) {\n          timestamp\n          offset\n          average\n        }\n        aggregatedCurrent(unit: Amps, interval: $interval, begin: $begin, end: $end) {\n          timestamp\n          offset\n          average\n        }\n        aggregatedPower(unit: Watt, interval: $interval, begin: $begin, end: $end) {\n          timestamp\n          offset\n          average\n        }\n        coupDeFouet {\n          float\n          troughVoltage\n          troughOffset\n          plateauVoltage\n          plateauOffset\n          dip\n        }\n      }\n    }\n    commencedTime\n    completedTime\n    abortedTime\n    state\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "9ce799e5b3df563adf22d5c70f490a0f";

export default node;
