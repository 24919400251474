import { fetchQuery } from 'react-relay';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { getGlobalEnvironment } from 'lib/environment';

import { breadcrumbsDeviceQuery } from './__generated__/breadcrumbsDeviceQuery.graphql';
import { breadcrumbsSiteQuery } from './__generated__/breadcrumbsSiteQuery.graphql';

export async function getSiteBreadcrumb(id: string): Promise<string> {
    const environment = getGlobalEnvironment();
    try {
        const data = await fetchQuery<breadcrumbsSiteQuery>(
            environment,
            graphql`
                query breadcrumbsSiteQuery($id: ID!) {
                    site(id: $id) {
                        name
                    }
                }
            `,
            { id },
            {
                fetchPolicy: 'store-or-network',
            }
        ).toPromise();

        return data?.site?.name ?? 'Unknown';
    } catch (error) {
        captureException(error, scope => {
            scope.setTag('Site', id);
            scope.setTag('Function', 'getSiteBreadcrumb');
            return scope;
        });
        return 'Unknown';
    }
}

export async function getDeviceBreadcrumb(id: string): Promise<string> {
    const environment = getGlobalEnvironment();
    try {
        const data = await fetchQuery<breadcrumbsDeviceQuery>(
            environment,
            graphql`
                query breadcrumbsDeviceQuery($id: ID!) {
                    device(id: $id) {
                        name
                        dualPlaneCompanion {
                            device {
                                name
                            }
                        }
                    }
                }
            `,
            { id },
            {
                fetchPolicy: 'store-or-network',
            }
        ).toPromise();

        let title = 'Unknown';
        if (data?.device?.dualPlaneCompanion?.device?.name && data?.device?.name) {
            return [data.device.dualPlaneCompanion.device.name, data.device.name].sort().join(' & ');
        } else if (data?.device?.name) {
            title = data.device.name;
        }

        return title;
    } catch (error) {
        captureException(error, scope => {
            scope.setTag('Device', id);
            scope.setTag('Function', 'getDeviceBreadcrumb');
            return scope;
        });
        return 'Unknown';
    }
}
