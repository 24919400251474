/**
 * @generated SignedSource<<9577edcc206b5c150d7680a83ee016ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AuthLevelAdmin = "Admin" | "None" | "%future added value";
export type AuthLevelRead = "None" | "ReadOnly" | "%future added value";
export type AuthLevelWrite = "None" | "ReadOnly" | "ReadWrite" | "%future added value";
export type AuthenticationRole = "Administrator" | "AssetsReadOnly" | "AssetsReadWrite" | "ReadOnly" | "TasksBatteryHealthReadOnly" | "TasksBatteryHealthReadWrite" | "%future added value";
export type UserErrorType = "EmailAlreadyRegistered" | "InvalidUser" | "MissingEmail" | "MissingName" | "Noop" | "%future added value";
export type UserUpdateIn = {
  email?: string | null;
  name?: string | null;
  permissions?: UserPermissionsIn | null;
  roles?: ReadonlyArray<AuthenticationRole> | null;
};
export type UserPermissionsIn = {
  administration?: AuthLevelAdmin | null;
  assets?: AuthLevelWrite | null;
  batteryHealthTasks?: AuthLevelWrite | null;
  general?: AuthLevelRead | null;
};
export type EditUserContentUpdateMutation$variables = {
  user: UserUpdateIn;
  username: string;
};
export type EditUserContentUpdateMutation$data = {
  readonly editUser: {
    readonly __typename: "User";
    readonly username: string;
  } | {
    readonly __typename: "UserProblemResponse";
    readonly problems: ReadonlyArray<{
      readonly type: UserErrorType;
    }>;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type EditUserContentUpdateMutation = {
  response: EditUserContentUpdateMutation$data;
  variables: EditUserContentUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "user"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "username"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "user",
        "variableName": "user"
      },
      {
        "kind": "Variable",
        "name": "username",
        "variableName": "username"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "editUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          }
        ],
        "type": "User",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProblem",
            "kind": "LinkedField",
            "name": "problems",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "UserProblemResponse",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditUserContentUpdateMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EditUserContentUpdateMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "0580ab6bb362fe9ef1db950ac31df567",
    "id": null,
    "metadata": {},
    "name": "EditUserContentUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation EditUserContentUpdateMutation(\n  $username: String!\n  $user: UserUpdateIn!\n) {\n  editUser(username: $username, user: $user) {\n    __typename\n    ... on User {\n      username\n    }\n    ... on UserProblemResponse {\n      problems {\n        type\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "13c3cce1dee6fee255579b8cfb8d4a0f";

export default node;
