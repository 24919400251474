import React, { FC } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { PlaceholderArea } from '@accesstel/pcm-ui';

import { useUserPermissions } from 'lib/auth';
import { Paths } from 'lib/routes';

interface WrappedDevicePlaceholderProps {
    deviceId: string;
}

export const WrappedDevicePlaceholder: FC<WrappedDevicePlaceholderProps> = ({ deviceId }) => {
    const { hasAssetsWrite } = useUserPermissions();

    const navigate = useNavigate();

    return (
        <div className='flex flex-col items-stretch'>
            <div className='flex flex-row pl-4 gap-2 items-baseline'>
                <div className='font-CynthoNext-SemiBold text-3xl'>&nbsp;</div>
            </div>
            <PlaceholderArea
                onClick={() => navigate(`${generatePath(Paths.AddDevice)}?companion=${deviceId}`)}
                // TODO: Would it be a better UX to just not show the button if the user doesn't have the right permissions?
                disableButton={!hasAssetsWrite}
                message={'No dual plane companion device'}
                buttonText={'Add a new device'}
                disableButtonMessage={'You do not have permission to add a new device'}
            />
        </div>
    );
};
