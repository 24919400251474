import React, { FC } from 'react';

import { CommonFilterProps, RangeFilter } from 'filters/common';

import { DeviceFilterRange } from './DeviceFilterRange';

export const DeviceBatteryCapacityTotalFilterUI: FC<CommonFilterProps<RangeFilter | null>> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    return (
        <DeviceFilterRange
            current={current}
            onClearOrBack={onClearOrBack}
            onClose={onClose}
            onSubmit={onSubmit}
            title='Filter by Battery Capacity Total'
            distributionType='BatteryCapacityTotal'
            countLabel={['Min', 'Max']}
            showBack={hasPrevious}
        />
    );
};
