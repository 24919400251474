import React, { Dispatch, FC, Fragment, ReactNode, useState } from 'react';

import { useField } from 'formik';
import { getQualifiedName } from 'lib/namespaces';

import { FormControlAction, FormControlParameters } from '../../../lib/reducer';
import { DeviceBatteryString } from '../schema';
import { BatteryStringDisplay } from './BatteryStringDisplay';
import { ExistingStringEditor } from './string-editing/ExistingStringEditor';

export interface BatteryStringListProps {
    namespace?: string;

    onRemove: (index: number) => void;
    onDuplicate: (batteryString: DeviceBatteryString) => void;
    onUpdate: (index: number, batteryString: DeviceBatteryString) => void;

    // form control
    formControlState: FormControlParameters;
    formControlDispatch: Dispatch<FormControlAction>;
}

export const BatteryStringList: FC<BatteryStringListProps> = ({
    namespace,
    onRemove,
    onUpdate,
    onDuplicate,
    formControlState,
    formControlDispatch,
}) => {
    const [, stringsField] = useField<DeviceBatteryString[]>(getQualifiedName('strings', namespace));
    const batteryStrings = stringsField.value;

    const [editStringId, setEditStringId] = useState<number | null>(null);

    const handleStringNameClick = (idx: number) => {
        if (idx === editStringId) {
            setEditStringId(null);
        } else {
            setEditStringId(idx);
        }
    };

    return (
        <>
            {batteryStrings.map((string, stringIndex) => {
                const stringName = string.type ? string.type.displayName : 'New Battery';
                const editingThisId = editStringId === stringIndex;

                let batteryStringDisplay: ReactNode;
                if (editingThisId) {
                    batteryStringDisplay = (
                        <div
                            onClick={() => handleStringNameClick(stringIndex)}
                            className='flex flex-row text-customEggplant font-CynthoNext-Regular text-base cursor-pointer col-start-1'
                        >
                            <div className='pr-1 font-CynthoNext-Light'>Editing:</div>
                            <div className='underline col-start-1 whitespace-nowrap'>{stringName}</div>
                        </div>
                    );
                } else {
                    batteryStringDisplay = (
                        <BatteryStringDisplay
                            namespace={namespace}
                            stringIndex={stringIndex}
                            onClick={() => setEditStringId(stringIndex)}
                            cells={parseInt(string.cellsPerString)} // TODO: Fallback
                            name={stringName}
                            onDuplicate={() => onDuplicate(string)}
                            onRemove={() => onRemove(stringIndex)}
                        />
                    );
                }

                return (
                    <Fragment key={`battery-string-${stringIndex}`}>
                        {batteryStringDisplay}
                        {editingThisId && (
                            <ExistingStringEditor
                                namespace={namespace}
                                stringIndex={stringIndex}
                                onUpdate={value => onUpdate(stringIndex, value)}
                                onClose={() => setEditStringId(null)}
                                formControlState={formControlState}
                                formControlDispatch={formControlDispatch}
                            />
                        )}
                    </Fragment>
                );
            })}
        </>
    );
};
