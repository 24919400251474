import React from 'react';
import { RouteObject } from 'react-router-dom';

import { LoginView } from 'views';

const Routes: RouteObject[] = [
    {
        path: 'login',
        element: <LoginView />,
    },
    {
        path: 'logout',
        element: <LoginView />,
    },
];

export default Routes;
