import {
    DeviceBatteryCapacityTotalFilterUI,
    DeviceBatteryStatusFilter,
    DeviceBatteryStatusFilterUI,
} from 'filters/device';

import { FilterDefinition, FilterValueMap, RangeFilter } from '../common';
import {
    SiteBatteryTotalTimeRemainingFilterUI,
    SiteDeviceCountFilterUI,
    SiteDeviceNameFilterUI,
    SiteDeviceWithBatteriesFilterUI,
    SiteNameFilterUI,
    SitePostcodeFilterUI,
    SiteStateFilterUI,
    SiteTypeFilterUI,
} from './components';
import { SiteDeviceStatusFilterUI } from './components/SiteDeviceStatusFilterUI';
import {
    SiteDeviceBatteries,
    SiteDeviceStatusFilter,
    SiteExtraFilters,
    SiteNameFilter,
    SitePostcodeFilter,
    SiteStateFilter,
    SiteTableColumn,
    SiteTypeFilter,
} from './types';

export type SiteColumnFilterMap = {
    [SiteTableColumn.Name]: SiteNameFilter[];
    [SiteTableColumn.Type]: SiteTypeFilter[];
    [SiteTableColumn.State]: SiteStateFilter[];
    [SiteTableColumn.Postcode]: SitePostcodeFilter[];
    [SiteTableColumn.DeviceCount]: RangeFilter | null;
    [SiteTableColumn.BatteryCapacity]: RangeFilter | null;
    [SiteTableColumn.BatteryTotalTimeRemaining]: RangeFilter | null;
    [SiteTableColumn.BatteryStatus]: DeviceBatteryStatusFilter[];
    [SiteTableColumn.DeviceStatus]: SiteDeviceStatusFilter[];
};

export const DefaultValues: FilterValueMap<SiteColumnFilterMap> = {
    [SiteTableColumn.Name]: [],
    [SiteTableColumn.Type]: [],
    [SiteTableColumn.State]: [],
    [SiteTableColumn.Postcode]: [],
    [SiteTableColumn.DeviceCount]: null,
    [SiteTableColumn.BatteryCapacity]: null,
    [SiteTableColumn.BatteryTotalTimeRemaining]: null,
    [SiteTableColumn.BatteryStatus]: [],
    [SiteTableColumn.DeviceStatus]: [],
};

export const DefaultExtraFilters: Record<SiteExtraFilters, unknown> = {
    [SiteExtraFilters.DeviceName]: [],
    [SiteExtraFilters.DeviceWithBatteries]: null,
};

export const StaticSiteFilterDefinitions: FilterDefinition<SiteTableColumn>[] = [
    {
        type: 'multi',
        name: 'Type',
        category: 'Type',
        column: SiteTableColumn.Type,
        component: () => SiteTypeFilterUI,
        describeValue: (value: SiteTypeFilter) => value.name,
    },
    {
        type: 'multi',
        name: 'Name',
        category: 'Name',
        column: SiteTableColumn.Name,
        component: () => SiteNameFilterUI,
        describeValue: (value: SiteNameFilter) => value.name,
    },
    {
        type: 'multi',
        name: 'State',
        category: 'State',
        column: SiteTableColumn.State,
        component: () => SiteStateFilterUI,
        describeValue: (value: SiteStateFilter) => value.state,
    },
    {
        type: 'single',
        name: 'Device Count',
        category: 'Device Count',
        column: SiteTableColumn.DeviceCount,
        component: () => SiteDeviceCountFilterUI,
        describeValue: (value: RangeFilter): string => `${value.min} - ${value.max}`,
    },
    {
        type: 'multi',
        name: 'Postcode',
        category: 'Postcode',
        column: SiteTableColumn.Postcode,
        component: () => SitePostcodeFilterUI,
        describeValue: (value: SitePostcodeFilter) => value.value,
    },
    {
        type: 'multi',
        name: SiteExtraFilters.DeviceName,
        category: SiteExtraFilters.DeviceName,
        component: () => SiteDeviceNameFilterUI,
        describeValue: (value: SiteNameFilter) => value.name,
    },
    {
        type: 'single',
        name: SiteExtraFilters.DeviceWithBatteries,
        category: SiteExtraFilters.DeviceWithBatteries,
        component: () => SiteDeviceWithBatteriesFilterUI,
        describeValue: (value: SiteDeviceBatteries) => value,
    },
    {
        type: 'multi',
        name: 'Battery Status',
        category: 'Battery Status',
        column: SiteTableColumn.BatteryStatus,
        component: () => DeviceBatteryStatusFilterUI,
        describeValue: (value: DeviceBatteryStatusFilter) => value.name,
    },
    {
        type: 'single',
        name: 'Site Battery Capacity',
        category: 'Site Battery Capacity',
        column: SiteTableColumn.BatteryCapacity,
        component: () => DeviceBatteryCapacityTotalFilterUI,
        describeValue: (value: RangeFilter): string => `${value.min} - ${value.max}`,
    },
    {
        type: 'single',
        name: 'Site Battery Total Time Remaining',
        category: 'Site Battery Total Time Remaining',
        column: SiteTableColumn.BatteryTotalTimeRemaining,
        component: () => SiteBatteryTotalTimeRemainingFilterUI,
        describeValue: (value: RangeFilter): string =>
            `${(value.min / 60).toPrecision(2)} - ${(value.max / 60).toPrecision(2)}`,
    },
    {
        type: 'multi',
        name: 'Device Status',
        category: 'Device Status',
        column: SiteTableColumn.DeviceStatus,
        component: () => SiteDeviceStatusFilterUI,
        describeValue: (value: SiteDeviceStatusFilter) => value.displayText,
    },
];
