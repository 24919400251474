import { Dispatch } from 'react';

import { FilterAction, FilterState, useFilterReducer } from 'filters/common';

import { DefaultValues, DeviceTestResultColumnFilterMap, StaticDeviceTestResultFilterDefinitions } from './settings';
import { DeviceTestResultTableColumn } from './types';

export type DeviceTestResultFilterState = FilterState<DeviceTestResultTableColumn, DeviceTestResultColumnFilterMap>;
export type DeviceTestResultFilterAction = FilterAction<DeviceTestResultColumnFilterMap, DeviceTestResultTableColumn>;

export function useDeviceTestResultFilter(
    deviceId: string
): [DeviceTestResultFilterState, Dispatch<DeviceTestResultFilterAction>] {
    const [state, updateState] = useFilterReducer<
        DeviceTestResultTableColumn,
        DeviceTestResultColumnFilterMap,
        DeviceTestResultFilterState,
        DeviceTestResultFilterAction
    >('device-test-result-filter', DefaultValues, StaticDeviceTestResultFilterDefinitions, {}, { deviceId });

    return [state, updateState];
}

export function deviceTestResultToFilterObject(filters: DeviceTestResultFilterState): Record<string, unknown> {
    const output: Record<string, unknown> = {};

    const {
        TaskName: names,
        State: statuses,
        Type: types,
        RunTime: runTime,
        StartTime: startTime,
        Cause: causes,
    } = filters.columnValues;

    if (names.length > 0) {
        output.taskName = names.map(filter => ({ value: filter.name }));
    }

    if (types.length > 0) {
        output.taskType = types;
    }

    if (statuses.length > 0) {
        output.state = statuses.map(filter => filter.id);
    }

    if (runTime) {
        output.runTime = {
            min: runTime.range[0],
            max: runTime.range[1],
        };
    }

    if (startTime) {
        output.startTime = {
            min: startTime.range[0].toISOString(),
            max: startTime.range[1].toISOString(),
        };
    }

    if (causes.length > 0) {
        output.cause = causes.map(filter => filter.id);
    }

    return output;
}
