import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { Button } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { Paths } from 'lib/routes';
import { AverageEnergySparkline } from 'views/reports/batteries/overview/components/AverageEnergySparkline';
import { AverageTemperatureSparkline } from 'views/reports/batteries/overview/components/AverageTemperatureSparkline';
import { CurrentCapacityDoughnut } from 'views/reports/batteries/overview/components/CurrentCapacityDoughnut';
import { CurrentStatusDoughnut } from 'views/reports/batteries/overview/components/CurrentStatusDoughnut';
import { DefaultTimeRanges, useTimeRange } from 'views/reports/common';

import style from '../../style.module.css';
import { SectionHeading } from '../components';
import { BatteryReportsSection$key } from './__generated__/BatteryReportsSection.graphql';

export interface BatteryReportsSectionProps {
    data: BatteryReportsSection$key | null;
    error?: boolean;
    retry: () => void;
}

export const BatteryReportsSection: FC<BatteryReportsSectionProps> = ({ data, error, retry }) => {
    const [timeRange] = useTimeRange(DefaultTimeRanges[0]);

    const result = useFragment<BatteryReportsSection$key>(
        graphql`
            fragment BatteryReportsSection on Query {
                ...CurrentStatusDoughnut
                ...CurrentCapacityDoughnut
            }
        `,
        data
    );

    return (
        <>
            <SectionHeading title='Battery Reports overview' />
            <div className={style.batteries_graph_grid}>
                <CurrentStatusDoughnut data={result} error={error} retry={retry} />
                <CurrentCapacityDoughnut data={result} error={error} retry={retry} />
                <AverageTemperatureSparkline timeRange={timeRange} />
                <AverageEnergySparkline timeRange={timeRange} />
            </div>
            <div className='pt-8'>
                <Button
                    buttonClasses={style.section_button_links}
                    to={Paths.ReportBatteries}
                    buttonText='Open Battery Reports'
                />
            </div>
        </>
    );
};
