import React, { FC, Suspense } from 'react';
import { PreloadedQuery } from 'react-relay';
import { useLoaderData, useParams } from 'react-router-dom';

import { DefaultTimeRange } from '../../settings';
import { RectifiersContent } from './RectifiersContent';
import { RectifiersSkeleton } from './RectifiersSkeleton';
import { RectifiersContentQuery } from './__generated__/RectifiersContentQuery.graphql';

export const Rectifiers: FC = () => {
    const queryRef = useLoaderData() as PreloadedQuery<RectifiersContentQuery> | null;
    const { siteId, deviceId } = useParams() as { siteId: string; deviceId: string };

    return (
        <>
            {!queryRef && <RectifiersSkeleton />}
            {queryRef && (
                <Suspense fallback={<RectifiersSkeleton />}>
                    <RectifiersContent
                        key={siteId}
                        queryRef={queryRef}
                        timeRange={DefaultTimeRange}
                        deviceId={deviceId}
                    />
                </Suspense>
            )}
        </>
    );
};
