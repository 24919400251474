import { Dispatch, Reducer, useEffect, useReducer } from 'react';

import { logError } from 'lib/log';
import { useHistoryState } from 'lib/saved-state';

import {
    FilterDefinition,
    FilterState,
    FilterValueMap,
    MultiFilterDefinition,
    SingleValueFilterDefinition,
} from './types';

export enum FilterActionType {
    Apply,
    ApplyIndex,
    Clear,
    ClearAll,
    Show,
    HideAll,
    ShowAdd,
    AddApply,
    Remove,
    UpdateFilterTypes,
}

type FilterApplyAction<TypeMap, ColumnType extends keyof TypeMap> = {
    type: FilterActionType.Apply;
    column: ColumnType;
    value: TypeMap[ColumnType];
};

type FilterApplyIndexMultiAction<T, ColumnType> = {
    type: FilterActionType.ApplyIndex;
    definition: MultiFilterDefinition<ColumnType, T>;
    index: number;
    value: T[];
};

type FilterApplyIndexSingleAction<T, ColumnType> = {
    type: FilterActionType.ApplyIndex;
    definition: SingleValueFilterDefinition<ColumnType, T>;
    index: number;
    value: T;
};

type FilterAddApplyAction<T, ColumnType> = {
    type: FilterActionType.AddApply;
    definition: FilterDefinition<ColumnType, T>;
    value: T;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FilterAction<TypeMap, ColumnType extends keyof TypeMap, T = any> =
    | FilterApplyAction<TypeMap, ColumnType>
    | FilterApplyIndexMultiAction<T, ColumnType>
    | FilterApplyIndexSingleAction<T, ColumnType>
    | { type: FilterActionType.Clear; column: ColumnType }
    | { type: FilterActionType.Show; column: ColumnType }
    | { type: FilterActionType.Show; definition: string; index: number }
    | { type: FilterActionType.HideAll }
    | { type: FilterActionType.ShowAdd }
    | FilterAddApplyAction<T, ColumnType>
    | { type: FilterActionType.Remove; definition: string; index: number }
    | { type: FilterActionType.ClearAll }
    | { type: FilterActionType.UpdateFilterTypes; definitions: FilterDefinition<ColumnType>[] };

export function filterReducer<ColumnType extends string, TypeMap extends Record<ColumnType, unknown>>(
    state: FilterState<ColumnType, TypeMap>,
    action: FilterAction<TypeMap, ColumnType>
): FilterState<ColumnType, TypeMap> {
    switch (action.type) {
        case FilterActionType.Apply:
            return {
                ...state,
                columnValues: {
                    ...state.columnValues,
                    [action.column]: action.value,
                },
            };
        case FilterActionType.ApplyIndex:
            if (action.definition.column) {
                let newValue: unknown;
                if (action.definition.type === 'multi') {
                    if (Array.isArray(action.value)) {
                        newValue = action.value;
                    } else {
                        newValue = [action.value];
                    }
                } else {
                    newValue = action.value;
                }

                return {
                    ...state,
                    columnValues: {
                        ...state.columnValues,
                        [action.definition.column as ColumnType]: newValue,
                    },
                };
            } else {
                let newValue: unknown[];
                if (Array.isArray(action.value)) {
                    newValue = action.value;
                } else {
                    newValue = action.definition.type === 'multi' ? [action.value] : action.value;
                }

                return {
                    ...state,
                    extraFilters: {
                        ...state.extraFilters,
                        [action.definition.name]: newValue,
                    },
                };
            }
        case FilterActionType.Clear:
            return {
                ...state,
                columnValues: {
                    ...state.columnValues,
                    [action.column]: state.columnDefaultValues[action.column],
                },
            };
        case FilterActionType.Show:
            if ('column' in action) {
                return {
                    ...state,
                    activeColumn: action.column,
                    activeExtra: null,
                    addFilterVisible: false,
                };
            } else {
                return {
                    ...state,
                    activeExtra: [action.definition, action.index],
                    activeColumn: null,
                    addFilterVisible: false,
                };
            }
        case FilterActionType.HideAll:
            if (!state.activeColumn && !state.activeExtra && !state.addFilterVisible) {
                return state;
            }

            return {
                ...state,
                activeColumn: null,
                activeExtra: null,
                addFilterVisible: false,
            };
        case FilterActionType.ShowAdd:
            if (!state.addFilterVisible) {
                return {
                    ...state,
                    addFilterVisible: true,
                };
            } else {
                return state;
            }
        case FilterActionType.AddApply: {
            if (action.definition.column) {
                const existing = state.columnValues[action.definition.column as ColumnType];

                let newValue: unknown;
                if (action.definition.type === 'multi') {
                    if (existing && Array.isArray(existing)) {
                        if (Array.isArray(action.value)) {
                            newValue = existing.concat(action.value);
                        } else {
                            newValue = [...existing, action.value];
                        }
                    } else {
                        if (Array.isArray(action.value)) {
                            newValue = action.value;
                        } else {
                            newValue = [action.value];
                        }
                    }
                } else {
                    newValue = action.value;
                }

                return {
                    ...state,
                    columnValues: {
                        ...state.columnValues,
                        [action.definition.column as ColumnType]: newValue,
                    },
                };
            } else {
                let existing = state.extraFilters[action.definition.name] ?? [];
                const isMultiFilter = Array.isArray(state.extraFilters[action.definition.name]);

                if (isMultiFilter) {
                    if (Array.isArray(action.value)) {
                        existing = (existing as unknown[]).concat(action.value);
                    } else {
                        existing = [...(existing as unknown[]), action.value];
                    }

                    // remove duplicate based on id
                    const uniqueIds: string[] = [];
                    existing = (existing as { id: string }[]).filter(filter => {
                        // only do this filter if the id exists
                        if (!filter.id) {
                            return true;
                        }

                        const isDuplicate = uniqueIds.includes(filter.id);
                        if (!isDuplicate) {
                            uniqueIds.push(filter.id);
                            return true;
                        }
                        return false;
                    });
                } else {
                    existing = action.value;
                }

                return {
                    ...state,
                    extraFilters: {
                        ...state.extraFilters,
                        [action.definition.name]: existing,
                    },
                };
            }
        }
        case FilterActionType.Remove: {
            const definition = state.filterDefinitions.find(definition => definition.name === action.definition);
            if (!definition) {
                logError('Missing definition on remove', action.definition);
                return state;
            }

            if (definition.column) {
                const existing = state.columnValues[definition.column as ColumnType];
                if (Array.isArray(existing)) {
                    return {
                        ...state,
                        columnValues: {
                            ...state.columnValues,
                            [definition.column as ColumnType]: existing.filter((_, index) => index !== action.index),
                        },
                    };
                } else {
                    return {
                        ...state,
                        columnValues: {
                            ...state.columnValues,
                            [definition.column as ColumnType]: null,
                        },
                    };
                }
            } else {
                return {
                    ...state,
                    extraFilters: {
                        ...state.extraFilters,
                        [definition.name]: Array.isArray(state.extraFilters[definition.name])
                            ? (state.extraFilters[definition.name] as unknown[]).filter(
                                  (_, index) => index !== action.index
                              )
                            : null,
                    },
                };
            }
        }
        case FilterActionType.ClearAll:
            return {
                ...state,
                columnValues: { ...state.columnDefaultValues },
                extraFilters: {},
            };
        case FilterActionType.UpdateFilterTypes:
            return {
                ...state,
                filterDefinitions: action.definitions,
            };
        default:
            return state;
    }
}

export function useFilterReducer<
    ColumnType extends string,
    TypeMap extends Record<ColumnType, unknown>,
    State extends FilterState<ColumnType, TypeMap>,
    Action extends FilterAction<TypeMap, ColumnType>
>(
    name: string,
    defaultValues: FilterValueMap<TypeMap>,
    definitions: FilterDefinition<ColumnType>[],
    defaultExtraFilters: Record<string, unknown>,
    meta?: Record<string, unknown>
): [State, Dispatch<Action>] {
    const [storedInitialState, setStoredInitialState] = useHistoryState<Pick<State, 'columnValues' | 'extraFilters'>>(
        name,
        {
            columnValues: defaultValues,
            extraFilters: defaultExtraFilters,
        }
    );

    const alteredDefinitions = definitions.map(definition => ({
        ...definition,
        component: meta ? () => definition.component(meta) : definition.component,
    }));

    const [state, dispatch] = useReducer<Reducer<State, Action>, undefined>(
        (filterReducer as unknown) as Reducer<State, Action>,
        undefined,
        () => {
            return {
                ...storedInitialState,
                activeColumn: null,
                columnDefaultValues: defaultValues,
                addFilterVisible: false,
                filterDefinitions: alteredDefinitions,
                activeExtra: null,
            } as State;
        }
    );

    useEffect(() => {
        setStoredInitialState({
            columnValues: state.columnValues,
            extraFilters: state.extraFilters,
        });
        // NOTE: setStoredInitialState is not included or it will cause a render loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    return [state, dispatch];
}
