import React, { FC, useEffect, useState } from 'react';
import { useRelayEnvironment } from 'react-relay';

import { SearchBox } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { fetchQuery } from 'relay-runtime';

import { Mutable } from '../../lib/common';
import type {
    SiteNameSearchQuery,
    SiteNameSearchQuery$data,
    SiteNameSearchQuery$variables,
} from './__generated__/SiteNameSearchQuery.graphql';

interface SiteNameSearchProps {
    resultClick?: (name: string) => void;
    iconClick?: React.Dispatch<React.SetStateAction<string>>;
}

type SearchResultType = Mutable<SiteNameSearchQuery$data['sites']['data']>;

export const SiteNameSearch: FC<SiteNameSearchProps> = ({ resultClick, iconClick = () => null }) => {
    const environment = useRelayEnvironment();
    const [value, setValue] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [searchResults, setSearchResults] = useState<SearchResultType | undefined>();
    const [moreResults, setMoreResults] = useState<boolean>(false);
    const [showReset, setShowReset] = useState<boolean>(false);

    const query = graphql`
        query SiteNameSearchQuery($search: String!, $pageSize: Int!, $page: Int = 1) {
            sites(
                search: $search
                pageSize: $pageSize
                page: $page
                orderBy: { field: Name, dir: Asc }
                onlyProvisioningStatuses: Active
            ) {
                pageInfo {
                    page
                    size
                    total
                    hasNext
                    hasPrevious
                }
                data {
                    id
                    name
                }
            }
        }
    `;

    useEffect(() => {
        const variables: SiteNameSearchQuery$variables = {
            search: searchTerm,
            pageSize: 5,
        };

        if (searchTerm.length !== 0 && searchTerm.length >= 2) {
            fetchQuery<SiteNameSearchQuery>(environment, query, variables)
                .toPromise()
                .then(data => {
                    setMoreResults(true);
                    setSearchResults((data?.sites.data as SearchResultType) ?? []);
                });
        } else {
            setSearchResults(undefined);
            setMoreResults(false);
            iconClick('');
        }
    }, [environment, iconClick, query, searchTerm]);

    const handleReset = () => {
        setShowReset(false);
        setSearchTerm('');
        setValue('');
    };

    const handleSubmit = (value: string) => {
        setShowReset(true);
        if (iconClick) {
            iconClick(value);
        }
    };

    const handleResultClick = (result: SearchResultType[number]) => {
        setShowReset(true);
        setValue(result.name);
        if (resultClick) {
            resultClick(result.name);
        }
    };

    function handleChange(value: string) {
        setShowReset(false);
        setSearchTerm(value);
        setValue(value);
    }

    return (
        <SearchBox
            autoFocus
            value={value}
            className=''
            placeHolder='Search by Site Name'
            onChange={handleChange}
            searchResults={searchResults}
            renderResult={result => result.name}
            onSubmit={handleSubmit}
            onReset={handleReset}
            onResultClick={handleResultClick}
            moreResults={moreResults}
            showReset={showReset}
            id='searchBoxID'
        />
    );
};
