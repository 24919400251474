import React, { useState } from 'react';

import { CrossIcon, IconWithTitle, SearchBox } from '@accesstel/pcm-ui';

import { MultipleSelect } from 'components/MultipleSelect';
import { ColumnWithId } from 'layouts';
import { capitalize } from 'lib/textFormatters';

export interface ColumnOptionsProps<ColumnType extends string> {
    visibleColumns: ColumnType[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    availableColumns: ColumnWithId<ColumnType, any>[];
    onSubmit: (option: ColumnType[]) => void;
    onReset: () => void;
    hideReset?: (selectedColumns: ColumnType[]) => boolean;
}

export function ColumnOptions<ColumnType extends string>({
    availableColumns,
    visibleColumns,
    onSubmit,
    onReset,
    hideReset = () => false,
}: ColumnOptionsProps<ColumnType>) {
    const [searchValue, setSearchValue] = useState<string>('');

    const searchMatch = searchValue.toLowerCase();

    const matchingColumns = availableColumns
        .filter(option => option.header && (option.header as string).toLowerCase().includes(searchMatch))
        .map(column => column.id);

    return (
        <>
            <SearchBox
                id='column-select'
                variant='filter'
                renderResult={() => ''}
                value={searchValue}
                onChange={setSearchValue}
                placeHolder='Search'
                autoFocus={true}
            />
            <MultipleSelect
                variant='standard'
                options={matchingColumns}
                active={visibleColumns}
                onClear={onReset}
                renderItem={columnId => {
                    const column = availableColumns.find(column => column.id === columnId);
                    return capitalize(column!.header as string, ['SNMP']);
                }}
                renderItemSimple={columnId => {
                    const column = availableColumns.find(column => column.id === columnId);
                    return capitalize(column!.header as string, ['SNMP']);
                }}
                onSubmit={onSubmit}
                clearButton={
                    <IconWithTitle
                        title='Revert to Default'
                        icon={<CrossIcon />}
                        iconStroke='white'
                        size='small'
                        circleColor='whiteEggplant'
                        fontFamily='light'
                        onClick={onReset}
                    />
                }
                overwriteHideClearButton={hideReset}
                minItemSelected={1}
            />
        </>
    );
}
