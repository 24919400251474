/**
 * @generated SignedSource<<abc30435b73cdb5eb6c1ab66cc3527dc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AlertDomain = "AcPower" | "Battery" | "Load" | "Rectifier" | "%future added value";
export type AlertOriginator = "Device" | "%future added value";
export type AlertSeverity = "Critical" | "Indeterminate" | "Major" | "Minor" | "Warning" | "%future added value";
export type AlertType = "Insight" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InsightTable_alerts$data = {
  readonly data: ReadonlyArray<{
    readonly category: string;
    readonly clearDate: string | null;
    readonly device: {
      readonly id: string;
      readonly name: string;
    };
    readonly domain: AlertDomain;
    readonly id: string;
    readonly isActive: boolean;
    readonly message: string;
    readonly originator: AlertOriginator;
    readonly raiseDate: string;
    readonly severity: AlertSeverity;
    readonly site: {
      readonly address: {
        readonly state: string;
      };
      readonly id: string;
      readonly name: string;
    };
    readonly suggestions: ReadonlyArray<string>;
    readonly type: AlertType;
  }>;
  readonly pageInfo: {
    readonly hasNext: boolean | null;
    readonly hasPrevious: boolean | null;
    readonly page: number;
    readonly size: number;
    readonly total: number;
  };
  readonly total: number;
  readonly " $fragmentType": "InsightTable_alerts";
};
export type InsightTable_alerts$key = {
  readonly " $data"?: InsightTable_alerts$data;
  readonly " $fragmentSpreads": FragmentRefs<"InsightTable_alerts">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InsightTable_alerts",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Alert",
      "kind": "LinkedField",
      "name": "data",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "severity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "category",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "domain",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "message",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "originator",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "raiseDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "clearDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isActive",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "suggestions",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Device",
          "kind": "LinkedField",
          "name": "device",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Site",
          "kind": "LinkedField",
          "name": "site",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Address",
              "kind": "LinkedField",
              "name": "address",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "state",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PageInfo",
      "kind": "LinkedField",
      "name": "pageInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "page",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "size",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasNext",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasPrevious",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PaginatedAlerts",
  "abstractKey": null
};
})();

(node as any).hash = "4e6ddce96f2d6ead2c9302aeaeef6324";

export default node;
