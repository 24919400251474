import React, { FC } from 'react';

import classNamesBind from 'classnames/bind';
import { SkeletonCard, SkeletonText } from 'components/Skeleton';

import { PageHeader } from '../../../../components';
import style from './layout.module.css';

const classNames = classNamesBind.bind(style);

export const BatteryHealthTaskOverviewSkeleton: FC = () => {
    return (
        <div className='space-y-6'>
            <PageHeader />
            <div>
                <div className='flex justify-between items-center pt-6'>
                    <SkeletonText characters={30} size='heading' />
                </div>
                <SkeletonText characters={10} size='subheading' />

                <div className={classNames('card_section')}>
                    <SkeletonText characters={20} size={1.375} />

                    <div className={classNames('card_section__body')}>
                        <SkeletonCard height='8.5rem' />
                        <SkeletonCard height='8.5rem' />
                        <SkeletonCard height='8.5rem' />
                    </div>
                </div>
                <div className={classNames('card_section')}>
                    <SkeletonText characters={20} size={1.375} />

                    <div className={classNames('card_section__body')}>
                        <SkeletonCard height='17.6rem' />
                        <SkeletonCard height='17.6rem' />
                        <SkeletonCard height='17.6rem' />
                    </div>
                </div>
                <div className={classNames('card_section')}>
                    <SkeletonText characters={20} size={1.375} />

                    <div className={classNames('card_section__body')}>
                        <SkeletonCard height='17.6rem' />
                        <SkeletonCard height='17.6rem' />
                        <SkeletonCard height='17.6rem' />
                        <SkeletonCard height='17.6rem' />
                        <SkeletonCard height='17.6rem' />
                        <SkeletonCard height='17.6rem' />
                    </div>
                </div>
            </div>
        </div>
    );
};
