import { SiteTableColumn } from 'filters/site';
import { ColumnWithId } from 'layouts';
import {
    BatteryCapacityColumn,
    BatteryStatusColumn,
    BatteryTotalTimeRemainingColumn,
    DeviceCountColumn,
    NameColumn,
    PostcodeColumn,
    StateColumn,
    TypeColumn,
} from 'lib/tables/site/columns';

import { BatterySiteListTableQuery$data } from './__generated__/BatterySiteListTableQuery.graphql';

type Site = BatterySiteListTableQuery$data['sites']['data'][number];
export type Device = Site['devices']['data'][number];

export const BaseTableColumns = [
    NameColumn,
    StateColumn,
    BatteryStatusColumn,
    BatteryCapacityColumn,
    BatteryTotalTimeRemainingColumn,
] as ColumnWithId<SiteTableColumn, Site | Device>[];

export const AllTableColumns = [...BaseTableColumns, TypeColumn, DeviceCountColumn, PostcodeColumn] as ColumnWithId<
    SiteTableColumn,
    Site | Device
>[];
