import React, { FC } from 'react';

import { DeviceNameFilter } from '../types';

export interface DeviceNameFilterResultProps {
    filter: DeviceNameFilter;
}

export const DeviceNameFilterResult: FC<DeviceNameFilterResultProps> = ({ filter }) => {
    if (filter.wildcard) {
        return (
            <div className='flex flex-row items-baseline space-x-2'>
                <span className='text-customEggplantWhite'>Matching</span>
                <span>{filter.name}</span>
            </div>
        );
    } else {
        return (
            <div className='flex flex-row items-baseline space-x-2'>
                <span>{filter.name}</span>
                <span className='text-customEggplantWhite text-xs'>
                    {filter.siteName}
                    {filter.state && `, ${filter.state}`}
                </span>
            </div>
        );
    }
};
