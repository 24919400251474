/**
 * @generated SignedSource<<d37c21e3251a1459e476ec01711d534e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InsightsReloadQuery$variables = {};
export type InsightsReloadQuery$data = {
  readonly settings: {
    readonly " $fragmentSpreads": FragmentRefs<"InsightsFragment">;
  };
};
export type InsightsReloadQuery = {
  response: InsightsReloadQuery$data;
  variables: InsightsReloadQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "InsightsReloadQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Settings",
        "kind": "LinkedField",
        "name": "settings",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "InsightsFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "InsightsReloadQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Settings",
        "kind": "LinkedField",
        "name": "settings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GlobalInsightSettings",
            "kind": "LinkedField",
            "name": "insights",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rectifierCapacityHeadroomPercent",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "abnormalFloatCurrentLimitPercent",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "syncInterval",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cec40a4bca11325d25bad391dc0e2f79",
    "id": null,
    "metadata": {},
    "name": "InsightsReloadQuery",
    "operationKind": "query",
    "text": "query InsightsReloadQuery {\n  settings {\n    ...InsightsFragment\n  }\n}\n\nfragment InsightsFragment on Settings {\n  insights {\n    rectifierCapacityHeadroomPercent\n    abnormalFloatCurrentLimitPercent\n    syncInterval\n  }\n}\n"
  }
};

(node as any).hash = "6c03e5c88c9abeafd60258887d5a7fd2";

export default node;
