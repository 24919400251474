import { DateRangeFilter } from 'components/FilterDateSelect/common';
import { RunTimeFilter } from 'components/FilterDurationSelect/common';
import { BatteryTestNameFilter } from 'filters/task';
import {
    BatteryTestState,
    BatteryTestTypeIncludingUnplanned,
} from 'views/reports/batteries/__generated__/queries_batteryTestCardsQuery.graphql';

export enum DeviceTestResultTableColumn {
    Name = 'TaskName',
    Type = 'Type',
    State = 'State',
    RunTime = 'RunTime',
    StartTime = 'StartTime',
    Cause = 'Cause',
}

export interface DeviceTestResultStateFilter {
    id: BatteryTestState;
    name: string;
}

export type DeviceTestResultTypeFilter = BatteryTestTypeIncludingUnplanned;

export type DeviceTestResultNameFilter = BatteryTestNameFilter;
export type DeviceTestResultRunTimeFilter = RunTimeFilter;
export type DeviceTestResultStartTimeFilter = DateRangeFilter;
