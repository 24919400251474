import { AttributeValidationSchema } from 'views/manage/common/schema';
import { array, number, object, ref, string } from 'yup';

export const BatteryDischargeTableSchema = object({
    endOfDischargeVoltage: number().label('End Of Discharge Table').nullable(),
    type: string().label('Type').oneOf(['Current', 'Power']).required(),
    rows: array().of(
        object({
            time: number().label('Time').positive().required(),
            value: number().label('Value').required(),
        })
    ),
});

export const BatteryTypeValidationSchema = object({
    manufacturer: string().trim().required('A Manufacturer must be provided'),
    model: string().trim().required('A Model must be provided'),

    technology: string().typeError('A Battery Technology must be selected').required().label('Battery Technology'),

    cellsPerBloc: number()
        .typeError('Cells per bloc must be a number')
        .nullable()
        .min(1)
        .integer()
        .label('Cells per bloc'),

    cells: object().shape(
        {
            capacity: number().typeError('Cell Capacity must be a number').required().min(1).label('Cell Capacity'),
            capacityRating: number()
                .typeError('Capacity Hour Rating must be a number')
                .required()
                .min(1)
                .label('Capacity Hour Rating'),
            referenceTemperature: number()
                .typeError('Cell Reference Temperature must be a number')
                .required()
                .min(1)
                .label('Cell Reference Temperature'),

            maximumAllowedVoltage: number()
                .typeError('Cell Maximum Voltage must be a number')
                .nullable()
                .positive()
                .label('Cell Maximum Voltage')
                .when('minimumAllowedVoltage', {
                    is: (minimumAllowedVoltage: number | undefined) => !!minimumAllowedVoltage,
                    then: number()
                        .moreThan(ref('minimumAllowedVoltage'), 'Must be more than Cell Minimum Voltage')
                        .nullable(),
                }),
            minimumAllowedVoltage: number()
                .typeError('Cell Minimum Voltage must be a number')
                .nullable()
                .positive()
                .label('Cell Minimum Voltage')
                .when('maximumAllowedVoltage', {
                    is: (maximumAllowedVoltage: number | undefined) => !!maximumAllowedVoltage,
                    then: number()
                        .lessThan(ref('maximumAllowedVoltage'), 'Must be less than Cell Maximum Voltage')
                        .nullable(),
                }),

            maximumBoostVoltage: number()
                .typeError('Cell Maximum Boost Voltage must be a number')
                .nullable()
                .positive()
                .label('Cell Maximum Boost Voltage')
                .when('minimumBoostVoltage', {
                    is: (minimumBoostVoltage: number | undefined) => !!minimumBoostVoltage,
                    then: number()
                        .moreThan(ref('minimumBoostVoltage'), 'Must be more than Cell Minimum Boost Voltage')
                        .nullable(),
                }),
            minimumBoostVoltage: number()
                .typeError('Cell Minimum Boost Voltage must be a number')
                .nullable()
                .positive()
                .label('Cell Minimum Boost Voltage')
                .when('maximumBoostVoltage', {
                    is: (maximumBoostVoltage: number | undefined) => !!maximumBoostVoltage,
                    then: number()
                        .lessThan(ref('maximumBoostVoltage'), 'Must be less than Cell Maximum Boost Voltage')
                        .nullable(),
                }),

            maximumFloatVoltage: number()
                .typeError('Cell Maximum Float Voltage must be a number')
                .nullable()
                .positive()
                .label('Cell Maximum Float Voltage')
                .when('minimumFloatVoltage', {
                    is: (minimumFloatVoltage: number | undefined) => !!minimumFloatVoltage,
                    then: number()
                        .moreThan(ref('minimumFloatVoltage'), 'Must be more than Cell Minimum Float Voltage')
                        .nullable(),
                }),
            minimumFloatVoltage: number()
                .typeError('Cell Minimum Float Voltage must be a number')
                .nullable()
                .positive()
                .label('Cell Minimum Float Voltage')
                .when('maximumFloatVoltage', {
                    is: (maximumFloatVoltage: number | undefined) => !!maximumFloatVoltage,
                    then: number()
                        .lessThan(ref('maximumFloatVoltage'), 'Must be less than Cell Maximum Float Voltage')
                        .nullable(),
                }),

            typicalVoltage: number()
                .typeError('Typical Cell Voltage must be a number')
                .nullable()
                .positive()
                .label('Typical Cell Voltage'),
        },
        [
            ['maximumAllowedVoltage', 'minimumAllowedVoltage'],
            ['maximumBoostVoltage', 'minimumBoostVoltage'],
            ['maximumFloatVoltage', 'minimumFloatVoltage'],
        ]
    ),

    peukertsConstant: number()
        .typeError("Peukert's Constant must be a number")
        .nullable()
        .positive()
        .label("Peukert's Constant"),

    attributes: array(AttributeValidationSchema).test('unique', 'Cannot have duplicate attribute', attributes => {
        const names = attributes?.map(attribute => attribute.name ?? '') ?? [];
        const visited = new Set<string>(names);
        return visited.size === names.length;
    }),

    dischargeTables: array().of(BatteryDischargeTableSchema).required(),
});
