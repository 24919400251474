import React, { FC, useMemo, useState } from 'react';
import { useFragment } from 'react-relay';

import {
    ChartFrame,
    Domain,
    MenuItemType,
    ThemedDateLine,
    ThemedLineSeriesTypes,
    tickEvenlyForDate,
} from '@accesstel/pcm-ui';
import { Theme } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { createMenuAction } from 'views/tasks/battery-health/components/MetricsLine';

import { TimeRange } from '../../../../common';
import { ReportLineChart_data$key } from './__generated__/ReportLineChart_data.graphql';

export interface ReportLineChartProps {
    title: string;
    unit: string;
    timeRange: TimeRange;
    data: ReportLineChart_data$key | null;
    error?: boolean;
    menuItems: MenuItemType[];
    domainBuffer?: number;
    allowNegative?: boolean;
    disabled?: boolean;
}

export const ReportLineChart: FC<ReportLineChartProps> = ({
    data,
    title,
    unit,
    timeRange,
    menuItems,
    allowNegative,
    domainBuffer = 10,
    disabled = false,
}) => {
    const [isZoomPaneOpen, setIsZoomPaneOpen] = useState(false);
    const rawData = useFragment<ReportLineChart_data$key>(
        graphql`
            fragment ReportLineChart_data on TimeSeriesMetrics {
                values {
                    timestamp
                    value
                }
            }
        `,
        data
    );

    const allSeries = useMemo<ThemedLineSeriesTypes<Date>[]>(() => {
        const series: ThemedLineSeriesTypes<Date> = {
            name: 'Data',
            lineColor: Theme.customEggplantWhite,
            data: [],
        };

        if (rawData) {
            series.data = rawData.values
                .filter(point => point.value !== null) // only filter the null ones, raw 0 is still valid
                .map(point => ({
                    key: new Date(point.timestamp as string),
                    value: point.value!,
                }));
        }
        return [series];
    }, [rawData]);

    const totalDurationMinutes = timeRange.getRange()[1].diff(timeRange.getRange()[0]).as('minutes');
    let numberOfTicks = Math.round(totalDurationMinutes / timeRange.interval.as('minutes'));
    while (numberOfTicks > 12) {
        numberOfTicks /= 2;
    }

    let hasData = false;
    let minValue = 0;
    for (const series of allSeries) {
        if (series.data.length > 0) {
            for (const point of series.data) {
                if (point.value == null) {
                    continue;
                }
                if (!hasData) {
                    minValue = point.value;
                    hasData = true;
                } else {
                    minValue = Math.min(minValue, point.value);
                }
            }
        }
    }

    let yDomain: Domain<number> | undefined;
    if (hasData) {
        if (allowNegative || minValue - domainBuffer >= 0) {
            yDomain = [`dataMin-${domainBuffer}`, `dataMax+${domainBuffer}`];
        } else {
            yDomain = [0, `dataMax+${domainBuffer}`];
        }
    }

    const zoomAction = createMenuAction({
        menuItems,
        menuSelected: timeRange.id,
        isOpen: isZoomPaneOpen,
        setIsOpen: setIsZoomPaneOpen,
        menuPlaceholder: 'Zoom',
    });

    return (
        <ChartFrame title={`${title} (${unit})`} actions={[zoomAction]} disabled={disabled}>
            <ThemedDateLine
                series={allSeries}
                unit={unit}
                yTicks={11}
                yDomain={yDomain}
                axisUnits
                xTicks={tickEvenlyForDate(numberOfTicks)}
                xDomain={[timeRange.getRange()[0].toJSDate(), timeRange.getRange()[1].toJSDate()]}
                unsupported={disabled}
            />
        </ChartFrame>
    );
};
