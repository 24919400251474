/**
 * @generated SignedSource<<e54776ea79752dc722f6a8e93af041d5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SiteDistributionType = "BatteryTotalTimeRemainingDistribution" | "SiteDeviceCountDistribution" | "%future added value";
export type SiteFilterRange_siteDistributionQuery$variables = {
  type: SiteDistributionType;
};
export type SiteFilterRange_siteDistributionQuery$data = {
  readonly siteDistribution: {
    readonly distribution: ReadonlyArray<{
      readonly key: number;
      readonly value: number;
    }>;
    readonly maximum: number | null;
    readonly minimum: number | null;
  };
};
export type SiteFilterRange_siteDistributionQuery = {
  response: SiteFilterRange_siteDistributionQuery$data;
  variables: SiteFilterRange_siteDistributionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "type"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": "SiteDistribution",
    "kind": "LinkedField",
    "name": "siteDistribution",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SiteDistributionCount",
        "kind": "LinkedField",
        "name": "distribution",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "key",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "minimum",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "maximum",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteFilterRange_siteDistributionQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SiteFilterRange_siteDistributionQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d2755fe542d5619e302c546adc1c66de",
    "id": null,
    "metadata": {},
    "name": "SiteFilterRange_siteDistributionQuery",
    "operationKind": "query",
    "text": "query SiteFilterRange_siteDistributionQuery(\n  $type: SiteDistributionType!\n) {\n  siteDistribution(type: $type) {\n    distribution {\n      key\n      value\n    }\n    minimum\n    maximum\n  }\n}\n"
  }
};
})();

(node as any).hash = "1abb134db62855bda43d3dbedd29c20b";

export default node;
