import React, { FC } from 'react';

import { FormikDurationPicker, FormikTextBox } from '@accesstel/pcm-ui';

import classNames from 'classnames';

import style from '../../../style.module.css';

export const CustomTestFields: FC = () => {
    return (
        <>
            <div className={classNames(style.settings_custom_test_eodv, 'font-CynthoNext-Regular')}>
                <FormikTextBox
                    name='customSettings.eodv'
                    light
                    placeHolder='End of discharge voltage (V)'
                    testId='end-of-discharge-voltage'
                    variant='outlined'
                />
            </div>
            <div className={classNames(style.settings_custom_test_max_duration, 'font-CynthoNext-Regular')}>
                <FormikDurationPicker
                    name='customSettings.maxDuration'
                    light
                    placeHolder='Maximum duration'
                    testId='maximum-duration'
                    variant='outlined'
                />
            </div>
            <div className={classNames(style.settings_custom_test_max_discharge, 'font-CynthoNext-Regular')}>
                <FormikTextBox
                    name='customSettings.maxDischarge'
                    light
                    placeHolder='Maximum discharged (Ah)'
                    testId='maximum-discharged'
                    variant='outlined'
                />
            </div>
        </>
    );
};
