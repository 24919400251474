import React from 'react';

import { Button } from '@accesstel/pcm-ui';

import { Paths } from 'lib/routes';

export function ErrorNotFound(): JSX.Element {
    return (
        <div className='error-page'>
            <div className='error-text'>404 Error</div>
            <div className='page-not-found'>Page not found</div>
            <Button buttonClasses='overview-button text-lg' buttonText='Go to dashboard' to={Paths.Dashboard} />
        </div>
    );
}
