/**
 * @generated SignedSource<<2ca29f3bfee3567dcb031c69e75aadb5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ExportJobProblem = "InvalidDevice" | "InvalidId" | "InvalidMetrics" | "InvalidRange" | "MissingCompanionTest" | "MissingDevices" | "MissingMetrics" | "RequestTooLarge" | "TestNotComplete" | "%future added value";
export type testExporterSinglePlaneMutation$variables = {
  id: string;
};
export type testExporterSinglePlaneMutation$data = {
  readonly exportResult: {
    readonly id?: string;
    readonly problems?: ReadonlyArray<ExportJobProblem>;
  } | null;
};
export type testExporterSinglePlaneMutation = {
  response: testExporterSinglePlaneMutation$data;
  variables: testExporterSinglePlaneMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "ExportJob",
  "abstractKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "problems",
      "storageKey": null
    }
  ],
  "type": "ExportJobProblemResponse",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "testExporterSinglePlaneMutation",
    "selections": [
      {
        "alias": "exportResult",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "exportBatteryTestResult",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "testExporterSinglePlaneMutation",
    "selections": [
      {
        "alias": "exportResult",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "exportBatteryTestResult",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7714d2f70b787cbebf16f2c6154dc1f3",
    "id": null,
    "metadata": {},
    "name": "testExporterSinglePlaneMutation",
    "operationKind": "mutation",
    "text": "mutation testExporterSinglePlaneMutation(\n  $id: ID!\n) {\n  exportResult: exportBatteryTestResult(id: $id) {\n    __typename\n    ... on ExportJob {\n      id\n    }\n    ... on ExportJobProblemResponse {\n      problems\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5a18cfe19478b85a8e846745ff0c5320";

export default node;
