import React, { Dispatch, FC } from 'react';

import { DeviceSelectionFooter } from '@accesstel/pcm-ui';

import { TestDeviceFilterAction, TestDeviceFilterState, TestDeviceTableColumn } from 'filters/device';
import { TableAction, TableState } from 'layouts';
import { ReportSearchResults } from 'views/reports/batteries/search';

import { ActiveStepStates } from '../../common';
import { SelectedDeviceInfo } from '../../device-selection';
import style from '../../style.module.css';
import { DeviceSiteSearch, DevicesTable, SearchResultAction } from './components';
import { DevicesTable_devices$key } from './components/__generated__/DevicesTable_devices.graphql';

interface SelectDevicesProps {
    selectedDevices: Set<string>;
    setDeviceSelected(id: string, info: SelectedDeviceInfo): void;
    setDeviceUnselected(id: string): void;
    selectNone(): void;
    selectAll: () => Promise<string[]>;
    setActiveStep: React.Dispatch<React.SetStateAction<number>>;
    allDevices: DevicesTable_devices$key | null;
    setTableFilter: (filter: string) => void;
    onResultClick: (item: ReportSearchResults) => SearchResultAction;
    onResetSearch: () => void;
    retry: () => void;
    error?: boolean;

    filterState: TestDeviceFilterState;
    dispatchFilterState: Dispatch<TestDeviceFilterAction>;
    tableState: TableState<TestDeviceTableColumn>;
    dispatchTableState: Dispatch<TableAction<TestDeviceTableColumn>>;
}

export const SelectDevices: FC<SelectDevicesProps> = ({
    selectedDevices,
    setDeviceSelected,
    setDeviceUnselected,
    selectNone,
    selectAll,
    setActiveStep,
    allDevices,
    setTableFilter,
    onResultClick,
    onResetSearch,
    retry,
    error,

    filterState,
    dispatchFilterState,
    tableState,
    dispatchTableState,
}) => {
    const handleButtonClick = () => setActiveStep(ActiveStepStates.Settings);

    return (
        <>
            <div className='space-y-6'>
                <div className={style.search_box}>
                    <DeviceSiteSearch
                        setTableFilter={setTableFilter}
                        onResultClick={onResultClick}
                        onResetSearch={onResetSearch}
                    />
                </div>
                <DevicesTable
                    selectedDevices={selectedDevices}
                    setDeviceSelected={setDeviceSelected}
                    setDeviceUnselected={setDeviceUnselected}
                    selectNone={selectNone}
                    selectAll={selectAll}
                    devices={allDevices}
                    error={error}
                    retry={retry}
                    filterState={filterState}
                    dispatchFilterState={dispatchFilterState}
                    tableState={tableState}
                    dispatchTableState={dispatchTableState}
                />
            </div>
            <div className={style.fixed_footer}>
                <DeviceSelectionFooter
                    buttonText={'Use selected devices'}
                    onClick={handleButtonClick}
                    message={`${tableState.selectedItems.length} Devices Selected`}
                />
            </div>
        </>
    );
};
