import React, { FC } from 'react';

import { useFormikContext } from 'formik';
import humanizeDuration from 'humanize-duration';
import { getDateTimeFormat } from 'lib/dateFormatter';

import { BatteryTypeWithEodvs, DeviceWithBattery, RepeatTestOptionsById } from '../../../common';
import { ScheduleTestFormValues } from '../../../schema';
import style from '../../../style.module.css';
import { DeviceList } from './DeviceList';
import {
    getCapacityTestParameterDisplay,
    getCustomTestParameterDisplay,
    getParameterDisplay,
    getQuickTestParamterDisplay,
} from './common';

interface TestDetailsProps {
    selectedDevices: DeviceWithBattery[];
    batteryTypes: BatteryTypeWithEodvs[];
}

export const TestDetails: FC<TestDetailsProps> = ({ selectedDevices, batteryTypes }) => {
    const { values } = useFormikContext<ScheduleTestFormValues>();

    return (
        <div className={'pt-3 text-customEggplant flex flex-col justify-between flex-grow pb-8'}>
            <div className={style.confirmation_devices}>
                <DeviceList devices={selectedDevices} />
            </div>
            <div>
                {values.scheduleTime && (
                    <div className={style.parameter_display}>
                        {getParameterDisplay('Starts: ', getDateTimeFormat(values.scheduleTime, { fullMonth: true }))}
                    </div>
                )}
                {values.type === 'Capacity' &&
                    getCapacityTestParameterDisplay(batteryTypes, values.capacitySettings!.targets)}
                {values.type === 'Custom' &&
                    getCustomTestParameterDisplay(
                        values.customSettings!.eodv,
                        values.customSettings?.maxDuration
                            ? humanizeDuration(values.customSettings.maxDuration.as('milliseconds'), {
                                  units: ['h', 'm'],
                                  delimiter: ', ',
                              })
                            : '',
                        values.customSettings!.maxDischarge
                    )}
                {values.type === 'Quick' &&
                    getQuickTestParamterDisplay(
                        values.quickSettings!.reserveTime,
                        values.quickSettings!.minAllowedVoltage
                    )}
                <div className={style.parameter_display}>
                    {getParameterDisplay('Repeat: ', RepeatTestOptionsById[values.scheduleRepeat].text)}
                </div>
                <div>{getParameterDisplay('Smart Start: ', values.isSmartStart ? 'Enabled' : 'Not Enabled')}</div>
            </div>
        </div>
    );
};
