import React, { FC } from 'react';

import { SingleSelectionList } from 'components/FilterSingleSelect/SingleSelectionList';
import { Operation, SelectedMetric } from 'views/explore/types';

import { DefinedMetric } from './types';

export interface OperationSelectionProps {
    metric: DefinedMetric;
    selectedMetrics: SelectedMetric[];
    onSelect: (operation: Operation) => void;
}

export const OperationSelection: FC<OperationSelectionProps> = ({ metric, selectedMetrics, onSelect }) => {
    const matchingSelections = selectedMetrics.filter(selectedMetric => selectedMetric.metric === metric.metric);

    return (
        <SingleSelectionList
            items={Object.values(Operation)}
            onSelect={item => {
                if (!isOperationAvailable(item, matchingSelections)) {
                    return;
                }

                onSelect(item);
            }}
            renderItem={operation => {
                const isAvailable = isOperationAvailable(operation, matchingSelections);

                return (
                    <span className={!isAvailable ? 'opacity-50' : ''}>
                        {operation}
                        {!isAvailable && ' (Selected)'}
                    </span>
                );
            }}
            selectedItem={undefined}
        />
    );
};

function isOperationAvailable(operation: Operation, selectedMetrics: SelectedMetric[]) {
    const isAvailable = selectedMetrics.every(selectedMetric => selectedMetric.op !== operation);

    return isAvailable;
}
