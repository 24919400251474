import React, { FC } from 'react';

import { CommonFilterProps, RangeFilter } from 'filters/common';

import { SiteFilterRange } from './SiteFilterRange';

export type SiteDeviceCountFilterUIProps = CommonFilterProps<RangeFilter | null>;

export const SiteDeviceCountFilterUI: FC<SiteDeviceCountFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => (
    <SiteFilterRange
        current={current}
        onClearOrBack={onClearOrBack}
        onClose={onClose}
        onSubmit={onSubmit}
        title='Filter by device count'
        distributionType='SiteDeviceCountDistribution'
        countLabel={['Min', 'Max']}
        discrete
        showBack={hasPrevious}
    />
);
