import React, { FC, ReactNode, useMemo, useState } from 'react';
import { useFragment } from 'react-relay';

import { ArrowLeftIcon, FilterContainer, FilterKeyValue, SelectedFilters } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { Operation, SelectedMetric } from 'views/explore/types';

import { MetricSelection } from './MetricSelection';
import { OperationSelection } from './OperationSelection';
import { SelectMetricsPaneFragment$key } from './__generated__/SelectMetricsPaneFragment.graphql';
import { DefinedMetric } from './types';

enum Mode {
    ChooseMetric,
    ChooseUnit, // Future
    ChooseOperation,
}

const PreviousMode: Record<Mode, Mode> = {
    [Mode.ChooseMetric]: Mode.ChooseMetric,
    [Mode.ChooseUnit]: Mode.ChooseMetric,
    // TODO: Allow choosing unit
    // [Mode.ChooseOperation]: Mode.ChooseUnit,
    [Mode.ChooseOperation]: Mode.ChooseMetric,
};

export interface SelectMetricsPaneProps {
    current: SelectedMetric[];
    query: SelectMetricsPaneFragment$key;
    onClose: () => void;
    onSelectMetric: (metric: SelectedMetric) => void;
    onDeselectMetric: (metric: SelectedMetric) => void;
}

export const SelectMetricsPane: FC<SelectMetricsPaneProps> = ({
    current,
    query,
    onClose,
    onSelectMetric,
    onDeselectMetric,
}) => {
    const { metricTypes } = useFragment(Fragment, query);
    const metricDefinitions = useMemo(() => {
        return metricTypes.map(metric => ({
            metric: metric.metric,
            displayName: metric.displayName,
            unit: metric.unit,
        }));
    }, [metricTypes]);

    const [mode, setMode] = useState<Mode>(Mode.ChooseMetric);
    const [selectedMetric, setSelectedMetric] = useState<DefinedMetric | null>(null);

    let content: ReactNode;
    switch (mode) {
        case Mode.ChooseMetric:
            content = (
                <MetricSelection
                    metrics={metricDefinitions}
                    selectedMetrics={current}
                    onSelect={metric => {
                        setSelectedMetric(metric);
                        // TODO: Allow choosing unit
                        // setMode(Mode.ChooseUnit);
                        setMode(Mode.ChooseOperation);
                    }}
                />
            );
            break;
        case Mode.ChooseUnit:
            content = <div>Choose unit</div>;

            break;
        case Mode.ChooseOperation:
            content = (
                <OperationSelection
                    metric={selectedMetric!}
                    selectedMetrics={current}
                    onSelect={operation => {
                        onSelectMetric({
                            metric: selectedMetric!.metric,
                            op: operation,
                        });

                        setMode(Mode.ChooseMetric);
                        setSelectedMetric(null);

                        onClose();
                    }}
                />
            );

            break;
    }

    let customClearButton: ReactNode;
    if (mode !== Mode.ChooseMetric) {
        customClearButton = (
            <div className='w-4 h-4 hover:text-customCoral'>
                <ArrowLeftIcon />
            </div>
        );
    }

    const currentMetrics = current.map<FilterKeyValue>(metric => {
        const definition = metricDefinitions.find(definition => definition.metric === metric.metric);

        const id = `${metric.metric}:${metric.op}`;
        let displayName: string;

        if (definition) {
            displayName = `${metric.op} ${definition.displayName}`;
        } else {
            displayName = `${metric.op} ${metric.metric}`;
        }

        return {
            label: id,
            value: displayName,
        };
    });

    return (
        <FilterContainer
            title='Select metrics'
            hideClearButton={mode === Mode.ChooseMetric}
            customButton={customClearButton}
            onClearClick={() => {
                setMode(PreviousMode[mode]);
            }}
            hideConfirmButton
            onClose={onClose}
            primaryContent={content}
            secondaryContent={
                <SelectedFilters
                    filters={currentMetrics}
                    onRemove={value => {
                        const [metricId, op] = value.label.split(':');
                        const selectedMetric = current.find(
                            metric => metric.metric === metricId && metric.op === (op as Operation)
                        );

                        if (selectedMetric) {
                            onDeselectMetric(selectedMetric);
                        }
                    }}
                />
            }
        />
    );
};

const Fragment = graphql`
    fragment SelectMetricsPaneFragment on Query {
        metricTypes(aggregateable: true, devices: $deviceIds) {
            metric
            displayName
            unit
        }
    }
`;
