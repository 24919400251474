import { DischargeTableData } from '@accesstel/pcm-ui';

import { BatteryTechnologyType } from 'lib/provision';
import { AttributeFormValues } from 'views/manage/common/schema';

export interface BatteryTypeCells {
    capacity: string;
    capacityRating: string;
    referenceTemperature: string;

    maximumAllowedVoltage: string | null;
    minimumAllowedVoltage: string | null;

    maximumBoostVoltage: string | null;
    minimumBoostVoltage: string | null;

    maximumFloatVoltage: string | null;
    minimumFloatVoltage: string | null;

    typicalVoltage: string | null;
}

export interface BatteryTypeFormValues {
    manufacturer: string;
    model: string;
    technology: BatteryTechnologyType | null;

    cellsPerBloc: string | null;
    cells: BatteryTypeCells;

    peukertsConstant: string | null;
    dischargeTables: DischargeTableData[];
    attributes: AttributeFormValues[];
}

export function createDefaultBatteryTypeFormValues(): BatteryTypeFormValues {
    return {
        manufacturer: '',
        model: '',
        technology: null,

        cellsPerBloc: '',
        cells: {
            capacity: '',
            capacityRating: '',
            referenceTemperature: '',

            maximumAllowedVoltage: '',
            minimumAllowedVoltage: '',

            maximumBoostVoltage: '',
            minimumBoostVoltage: '',

            maximumFloatVoltage: '',
            minimumFloatVoltage: '',

            typicalVoltage: '',
        },
        peukertsConstant: '',
        dischargeTables: [],
        attributes: [],
    };
}
