import React, { FC, Suspense } from 'react';
import { PreloadedQuery } from 'react-relay';
import { useLoaderData, useParams } from 'react-router-dom';

import { HistoryContent } from './HistoryContent';
import { HistorySkeleton } from './HistorySkeleton';
import { HistoryContentQuery } from './__generated__/HistoryContentQuery.graphql';

export const History: FC = () => {
    const queryRef = useLoaderData() as PreloadedQuery<HistoryContentQuery> | null;
    const { deviceId } = useParams() as { deviceId: string };

    return (
        <>
            {!queryRef && <HistorySkeleton />}
            {queryRef && (
                <Suspense fallback={<HistorySkeleton />}>
                    <HistoryContent key={deviceId} queryRef={queryRef} deviceId={deviceId} />
                </Suspense>
            )}
        </>
    );
};
