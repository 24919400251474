import React, { FC, useCallback, useMemo, useState } from 'react';

import { SearchBox } from '@accesstel/pcm-ui';

import { PredefinedDuration } from './common';

interface Option {
    type: 'predefined' | 'duration';
    title: string;
    range?: PredefinedDuration;
}

export interface PredefinedSelectionsProps {
    ranges: PredefinedDuration[];
    selected: PredefinedDuration | null;
    onSelect: (range: PredefinedDuration) => void;
    onCustomDurationClick: () => void;
}

export const PredefinedSelections: FC<PredefinedSelectionsProps> = ({
    ranges,
    selected,
    onSelect,
    onCustomDurationClick: onCustomDateClick,
}) => {
    const [searchValue, setSearchValue] = useState<string>('');

    const options = useMemo<Option[]>(
        () => [
            ...ranges.map<Option>(range => ({
                type: 'predefined',
                title: range.title,
                range,
            })),
            { type: 'duration', title: 'Custom Duration...' },
        ],
        [ranges]
    );

    const searchMatch = searchValue.toLowerCase();

    const matchingOptions = options.filter(option => option.title.toLowerCase().includes(searchMatch));

    const handleResultClick = useCallback(
        (option: Option) => {
            if (option.type === 'predefined') {
                onSelect(option.range!);
            } else if (option.type === 'duration') {
                onCustomDateClick();
            }
        },
        [onCustomDateClick, onSelect]
    );

    const handleSearch = () => {
        if (matchingOptions.length > 0) {
            handleResultClick(matchingOptions[0]);
            setSearchValue('');
        }
    };

    return (
        <SearchBox
            id='filterSelect'
            variant='filter'
            renderResult={option => {
                if (selected === option.range) {
                    return <span className='text-customCoral'>{option.title}</span>;
                } else {
                    return option.title;
                }
            }}
            searchResults={matchingOptions}
            value={searchValue}
            onChange={setSearchValue}
            onSubmit={handleSearch}
            onResultClick={handleResultClick}
            placeHolder='Search'
            autoFocus={true}
        />
    );
};
