import { logError } from 'lib/log';

import { MetricUnit } from '../__generated__/MetricsExploreContentQuery.graphql';

type Scale = 'unit' | 'milli' | 'kilo';

export function convertUnitToString(unit: MetricUnit, scale: Scale): string {
    const base = getUnitBaseText(unit);

    switch (scale) {
        case 'unit':
            return base;
        case 'milli':
            return `m${base}`;
        case 'kilo':
            return `k${base}`;
    }
}

export function getUnitBaseText(unit: MetricUnit): string {
    switch (unit) {
        case 'AmpHours':
            return 'Ah';
        case 'Amps':
            return 'A';
        case 'Celsius':
            return '°C';
        case 'Hertz':
            return 'Hz';
        case 'Minutes':
            return 'min';
        case 'Percent':
            return '%';
        case 'VoltAmps':
            return 'VA';
        case 'Volts':
            return 'V';
        case 'WattHours':
            return 'Wh';
        case 'Watts':
            return 'W';
        default:
            logError(`Unknown unit: ${unit}`);
            return '';
    }
}

export function getUnitDisplayName(unit: MetricUnit, scale: Scale = 'unit'): string {
    switch (unit) {
        case 'AmpHours':
            if (scale === 'milli') {
                return 'Milliamp Hours (mAh)';
            }
            return 'Amp Hours (Ah)';
        case 'Amps':
            if (scale === 'milli') {
                return 'Milliamps (mA)';
            }

            return 'Amps (A)';
        case 'Celsius':
            return 'Celsius (°C)';
        case 'Hertz':
            return 'Hertz (Hz)';
        case 'Minutes':
            return 'Minutes (min)';
        case 'Percent':
            return 'Percent (%)';
        case 'VoltAmps':
            if (scale === 'kilo') {
                return 'Kilovolt Amps (kVA)';
            }
            return 'Volt Amps (VA)';
        case 'Volts':
            if (scale === 'milli') {
                return 'Millivolts (mV)';
            }
            return 'Volts (V)';
        case 'WattHours':
            if (scale === 'kilo') {
                return 'Kilowatt Hours (kWh)';
            }

            return 'Watt Hours (Wh)';
        case 'Watts':
            if (scale === 'kilo') {
                return 'Kilowatts (kW)';
            } else if (scale === 'milli') {
                return 'Milliwatts (mW)';
            }
            return 'Watts (W)';
        default:
            logError(`Unknown unit: ${unit}`);
            return '';
    }
}
