import React from 'react';

import { BatteryTypeWithEodvs } from '../../../common';
import { CapacitySettingsTargetFormValues } from '../../../schema';
import style from '../../../style.module.css';

export function getCapacityTestParameterDisplay(
    batteryTypes: BatteryTypeWithEodvs[],
    settings: CapacitySettingsTargetFormValues[]
): JSX.Element {
    return (
        <>
            <div className={style.parameter_display}>
                <span className={style.confirmation_devices_count}>
                    <span className='font-CynthoNext-Regular text-customEggplant'>Test Type: </span>
                    <span className='font-CynthoNext-SemiBold text-customEggplant'>Capacity Test</span>
                </span>
            </div>
            {batteryTypes.map(batteryType => {
                const batteryTypeEodv = settings.find(target => target.batteryType === batteryType.id)?.eodv;
                return (
                    <div key={batteryType.id} className={style.parameter_display}>
                        <span className={style.confirmation_devices_count}>
                            <span className='font-CynthoNext-Regular text-customEggplant'>{`Battery Type & EODV: `}</span>
                            <span className='font-CynthoNext-SemiBold text-customEggplant'>
                                {`${batteryType.type} - 
                            ${batteryTypeEodv ?? 'Not set'}${batteryTypeEodv ? 'Vpc' : ''}`}
                            </span>
                        </span>
                    </div>
                );
            })}
        </>
    );
}

export function getCustomTestParameterDisplay(eodv: string, maxDuration: string, maxDischarged: string): JSX.Element {
    return (
        <>
            <div className={style.parameter_display}>
                <span className={style.confirmation_devices_count}>
                    <span className='font-CynthoNext-Regular text-customEggplant'>Test Type: </span>
                    <span className='font-CynthoNext-SemiBold text-customEggplant'>Custom Test</span>
                </span>
            </div>
            {eodv !== '' && (
                <div className={style.parameter_display}>
                    <span className={style.confirmation_devices_count}>
                        <span className='font-CynthoNext-Regular text-customEggplant'>Minimum Voltage: </span>
                        <span className='font-CynthoNext-SemiBold text-customEggplant'>{`${Number(eodv)}V`}</span>
                    </span>
                </div>
            )}
            {maxDuration !== '' && (
                <div className={style.parameter_display}>
                    <span className={style.confirmation_devices_count}>
                        <span className='font-CynthoNext-Regular text-customEggplant'>Maximum Duration: </span>
                        <span className='font-CynthoNext-SemiBold text-customEggplant'>{maxDuration}</span>
                    </span>
                </div>
            )}
            {maxDischarged !== '' && (
                <div className={style.parameter_display}>
                    <span className={style.confirmation_devices_count}>
                        <span className='font-CynthoNext-Regular text-customEggplant'>Maximum Discharge: </span>
                        <span className='font-CynthoNext-SemiBold text-customEggplant'>{`${Number(
                            maxDischarged
                        )}Ah`}</span>
                    </span>
                </div>
            )}
        </>
    );
}

export function getQuickTestParamterDisplay(reserveTime: string, minAllowedVoltage: string): JSX.Element {
    return (
        <>
            <div className={style.parameter_display}>
                <span className={style.confirmation_devices_count}>
                    <span className='font-CynthoNext-Regular text-customEggplant'>Test Type: </span>
                    <span className='font-CynthoNext-SemiBold text-customEggplant'>Quick Test</span>
                </span>
            </div>
            {reserveTime !== '' && (
                <div className={style.parameter_display}>
                    <span className={style.confirmation_devices_count}>
                        <span className='font-CynthoNext-Regular text-customEggplant'>Reserve Time: </span>
                        <span className='font-CynthoNext-SemiBold text-customEggplant'>{`${Number(
                            reserveTime
                        )}%`}</span>
                    </span>
                </div>
            )}
            {minAllowedVoltage !== '' && (
                <div className={style.parameter_display}>
                    <span className={style.confirmation_devices_count}>
                        <span className='font-CynthoNext-Regular text-customEggplant'>Voltage Threshold: </span>
                        <span className='font-CynthoNext-SemiBold text-customEggplant'>{`${Number(
                            minAllowedVoltage
                        )}V`}</span>
                    </span>
                </div>
            )}
        </>
    );
}

export function getParameterDisplay(label: string, value: string): JSX.Element {
    return (
        <span className={style.confirmation_devices_count}>
            <span className='font-CynthoNext-Regular text-customEggplant'>{label}</span>
            <span className='font-CynthoNext-SemiBold text-customEggplant'>{value}</span>
        </span>
    );
}
