/**
 * @generated SignedSource<<001e7f99f9ee93cadc079d8f022fbbbe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SystemConfigurationContentQuery$variables = {};
export type SystemConfigurationContentQuery$data = {
  readonly settings: {
    readonly " $fragmentSpreads": FragmentRefs<"BatteryTestingFragment" | "DataCollectionFragment" | "InsightsFragment">;
  };
};
export type SystemConfigurationContentQuery = {
  response: SystemConfigurationContentQuery$data;
  variables: SystemConfigurationContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "syncInterval",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SystemConfigurationContentQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Settings",
        "kind": "LinkedField",
        "name": "settings",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DataCollectionFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BatteryTestingFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "InsightsFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SystemConfigurationContentQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Settings",
        "kind": "LinkedField",
        "name": "settings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DataCollectionSettings",
            "kind": "LinkedField",
            "name": "dataCollection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DataCollectionPollingSettings",
                "kind": "LinkedField",
                "name": "polling",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "generalInterval",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "highFrequencyInterval",
                    "storageKey": null
                  },
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "timeout",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DeviceManagementSettings",
                "kind": "LinkedField",
                "name": "deviceManagement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "checkInInterval",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "missedCheckInTimeout",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "allocationDelay",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GlobalBatteryTestingSettings",
            "kind": "LinkedField",
            "name": "batteryTesting",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startTimeout",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "smartStartTimeout",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dischargeFaultDetectionTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dischargeFaultVoltageHistoresis",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "defaultQuickTestThresholdVoltage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "defaultQuickTestReservePercent",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GlobalInsightSettings",
            "kind": "LinkedField",
            "name": "insights",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rectifierCapacityHeadroomPercent",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "abnormalFloatCurrentLimitPercent",
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "854e2e76fdd52548468eff1fe303af11",
    "id": null,
    "metadata": {},
    "name": "SystemConfigurationContentQuery",
    "operationKind": "query",
    "text": "query SystemConfigurationContentQuery {\n  settings {\n    ...DataCollectionFragment\n    ...BatteryTestingFragment\n    ...InsightsFragment\n  }\n}\n\nfragment BatteryTestingFragment on Settings {\n  batteryTesting {\n    startTimeout\n    smartStartTimeout\n    dischargeFaultDetectionTime\n    dischargeFaultVoltageHistoresis\n    defaultQuickTestThresholdVoltage\n    defaultQuickTestReservePercent\n  }\n}\n\nfragment DataCollectionFragment on Settings {\n  dataCollection {\n    polling {\n      generalInterval\n      highFrequencyInterval\n      syncInterval\n      timeout\n    }\n    deviceManagement {\n      checkInInterval\n      missedCheckInTimeout\n      allocationDelay\n    }\n  }\n}\n\nfragment InsightsFragment on Settings {\n  insights {\n    rectifierCapacityHeadroomPercent\n    abnormalFloatCurrentLimitPercent\n    syncInterval\n  }\n}\n"
  }
};
})();

(node as any).hash = "552726fcfc4f515faa676f24f546040a";

export default node;
