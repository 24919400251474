/**
 * @generated SignedSource<<149931b008d512a48ab73ccd07df935a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ACPowerEventStatus = "Outage" | "OverVoltage" | "UnderVoltage" | "%future added value";
export type ActivityLogSource = "Alert" | "AuditLog" | "GridEvent" | "%future added value";
export type AlertSeverity = "Critical" | "Indeterminate" | "Major" | "Minor" | "Warning" | "%future added value";
export type BatteryTaskState = "Aborted" | "Failed" | "InProgress" | "Inconclusive" | "PartialFail" | "PartialPass" | "Passed" | "Scheduled" | "%future added value";
export type BatteryTestState = "Aborted" | "Analyzing" | "Failed" | "Finalizing" | "InProgress" | "Inconclusive" | "Passed" | "Scheduled" | "SmartStart" | "Waiting" | "%future added value";
export type BatteryTestType = "Capacity" | "Custom" | "Quick" | "%future added value";
export type HistoryContentQuery$variables = {
  id: string;
  limit: number;
};
export type HistoryContentQuery$data = {
  readonly activityLogs: {
    readonly data: ReadonlyArray<{
      readonly changes: ReadonlyArray<{
        readonly field: string;
        readonly newValue: string | null;
        readonly oldValue: string | null;
      }> | null;
      readonly link: {
        readonly __typename: "ACPowerEvent";
        readonly affectedAllFeeds: boolean;
        readonly affectedFeeds: ReadonlyArray<{
          readonly id: string;
          readonly status: ACPowerEventStatus;
        }>;
        readonly device: {
          readonly id: string;
          readonly name: string;
        };
        readonly duration: string;
        readonly worstStatus: ACPowerEventStatus;
      } | {
        readonly __typename: "Alert";
        readonly category: string;
        readonly device: {
          readonly id: string;
          readonly name: string;
        };
        readonly isActive: boolean;
        readonly message: string;
        readonly severity: AlertSeverity;
      } | {
        readonly __typename: "BatteryTest";
        readonly commencedTime: string | null;
        readonly id: string;
        readonly taskName: string | null;
      } | {
        readonly __typename: "Device";
        readonly id: string;
        readonly name: string;
      } | {
        readonly __typename: "DeviceBatteryTestResults";
        readonly commencedTime: string | null;
        readonly device: {
          readonly id: string;
          readonly name: string;
        };
        readonly id: string;
        readonly state: BatteryTestState;
        readonly task: {
          readonly abortedUser: {
            readonly name: string | null;
            readonly username: string;
          } | null;
          readonly id: string;
          readonly name: string | null;
          readonly testState: BatteryTaskState;
          readonly type: BatteryTestType;
        } | null;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      } | null;
      readonly source: ActivityLogSource;
      readonly timestamp: string;
      readonly type: string;
      readonly user: {
        readonly name: string | null;
        readonly username: string;
      } | null;
    }>;
    readonly hasMore: boolean;
    readonly total: number;
  };
  readonly device: {
    readonly dualPlaneCompanion: {
      readonly device: {
        readonly name: string;
      } | null;
    } | null;
    readonly name: string;
  } | null;
};
export type HistoryContentQuery = {
  response: HistoryContentQuery$data;
  variables: HistoryContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "limit"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "device",
    "variableName": "id"
  },
  {
    "kind": "Literal",
    "name": "includeCompanionDevices",
    "value": true
  },
  {
    "kind": "Variable",
    "name": "limit",
    "variableName": "limit"
  },
  {
    "kind": "Literal",
    "name": "types",
    "value": [
      "device_added",
      "device_edited",
      "device_removed",
      "critical",
      "major",
      "offline",
      "high",
      "test_initiate"
    ]
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasMore",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "source",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timestamp",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "username",
    "storageKey": null
  },
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "ChangeFieldValue",
  "kind": "LinkedField",
  "name": "changes",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "oldValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "newValue",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v13 = [
  (v12/*: any*/),
  (v7/*: any*/)
],
v14 = {
  "kind": "InlineFragment",
  "selections": (v13/*: any*/),
  "type": "Device",
  "abstractKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "severity",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "category",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isActive",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "Device",
  "kind": "LinkedField",
  "name": "device",
  "plural": false,
  "selections": (v13/*: any*/),
  "storageKey": null
},
v20 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "worstStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "duration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "affectedAllFeeds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ACPowerEventFeed",
      "kind": "LinkedField",
      "name": "affectedFeeds",
      "plural": true,
      "selections": [
        (v12/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v19/*: any*/)
  ],
  "type": "ACPowerEvent",
  "abstractKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "commencedTime",
  "storageKey": null
},
v22 = {
  "kind": "InlineFragment",
  "selections": [
    (v12/*: any*/),
    (v21/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BatteryTest",
      "kind": "LinkedField",
      "name": "task",
      "plural": false,
      "selections": [
        (v12/*: any*/),
        (v7/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "testState",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "abortedUser",
          "plural": false,
          "selections": (v8/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v19/*: any*/)
  ],
  "type": "DeviceBatteryTestResults",
  "abstractKey": null
},
v23 = {
  "kind": "InlineFragment",
  "selections": [
    (v12/*: any*/),
    (v21/*: any*/),
    {
      "alias": "taskName",
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "BatteryTest",
  "abstractKey": null
},
v24 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v25 = [
  (v12/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HistoryContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PaginatedActivityLogs",
        "kind": "LinkedField",
        "name": "activityLogs",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ActivityLog",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "link",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v14/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/)
                    ],
                    "type": "Alert",
                    "abstractKey": null
                  },
                  (v20/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v24/*: any*/),
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DualPlaneCompanion",
            "kind": "LinkedField",
            "name": "dualPlaneCompanion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "device",
                "plural": false,
                "selections": [
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HistoryContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PaginatedActivityLogs",
        "kind": "LinkedField",
        "name": "activityLogs",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ActivityLog",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "link",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v14/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v12/*: any*/)
                    ],
                    "type": "Alert",
                    "abstractKey": null
                  },
                  (v20/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": (v25/*: any*/),
                    "type": "BatteryType",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v25/*: any*/),
                    "type": "Site",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v24/*: any*/),
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DualPlaneCompanion",
            "kind": "LinkedField",
            "name": "dualPlaneCompanion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "device",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5cb0a56effb2b5e2930ff2797cbc85b1",
    "id": null,
    "metadata": {},
    "name": "HistoryContentQuery",
    "operationKind": "query",
    "text": "query HistoryContentQuery(\n  $id: ID!\n  $limit: Int!\n) {\n  activityLogs(device: $id, includeCompanionDevices: true, types: [\"device_added\", \"device_edited\", \"device_removed\", \"critical\", \"major\", \"offline\", \"high\", \"test_initiate\"], limit: $limit) {\n    hasMore\n    total\n    data {\n      source\n      type\n      timestamp\n      user {\n        username\n        name\n      }\n      changes {\n        field\n        oldValue\n        newValue\n      }\n      link {\n        __typename\n        ... on Device {\n          id\n          name\n        }\n        ... on Alert {\n          severity\n          category\n          message\n          isActive\n          device {\n            id\n            name\n          }\n          id\n        }\n        ... on ACPowerEvent {\n          worstStatus\n          duration\n          affectedAllFeeds\n          affectedFeeds {\n            id\n            status\n          }\n          device {\n            id\n            name\n          }\n        }\n        ... on DeviceBatteryTestResults {\n          id\n          commencedTime\n          state\n          task {\n            id\n            name\n            type\n            testState\n            abortedUser {\n              username\n              name\n            }\n          }\n          device {\n            id\n            name\n          }\n        }\n        ... on BatteryTest {\n          id\n          commencedTime\n          taskName: name\n        }\n        ... on BatteryType {\n          id\n        }\n        ... on Site {\n          id\n        }\n      }\n    }\n  }\n  device(id: $id) {\n    name\n    dualPlaneCompanion {\n      device {\n        name\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "7dde390d85e2fbc2ff077eaf41ae9c62";

export default node;
