import React, { useState } from 'react';
import { PreloadedQuery, fetchQuery, loadQuery, usePreloadedQuery, useRelayEnvironment } from 'react-relay';

import { ArrowDownIcon, HistoryItem, History as HistoryLine, useToast } from '@accesstel/pcm-ui';

import { captureException, captureMessage } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { useUserPermissions } from 'lib/auth';
import { getHumanizedTextFromDuration } from 'lib/dateFormatter';
import { getGlobalEnvironment } from 'lib/environment';
import { HistoryExportItem, createHistoryItem, humanizeActivityLogSource } from 'lib/history';
import { DateTime } from 'luxon';

import { DevicePane } from '../../components/DevicePane';
import { DefaultHistorySize, DefaultHistoryStep } from '../../settings';
import { HistoryContentQuery, HistoryContentQuery$variables } from './__generated__/HistoryContentQuery.graphql';

export interface HistoryContentProps {
    queryRef: PreloadedQuery<HistoryContentQuery>;
    deviceId: string;
}

const CsvHeader = ['Source', 'Type', 'Timestamp', 'UserId', 'UserName', 'Action', 'DeviceId', 'DeviceName'].join(',');

// NOTE: No live data as history do not change as frequently (alert, tests, outages might be useful to live refresh)
// NOTE: There will never be an empty history as "device_added" event always exists
export const HistoryContent: React.FC<HistoryContentProps> = ({ queryRef, deviceId }) => {
    const environment = useRelayEnvironment();
    const { show } = useToast();
    const { hasTasksRead, hasAssetsRead } = useUserPermissions();
    const initialData = usePreloadedQuery(HistoryQuery, queryRef);
    const [limit, setLimit] = useState(DefaultHistorySize);

    const [data, setData] = useState(initialData);

    if (!data.activityLogs) {
        return null;
    }

    if (!data.device) {
        // This should never happen as the DeviceLayout validates devices
        captureMessage('Assertion failed: device is null in HistoryContent', scope => {
            scope.setExtra('data', data);
            scope.setTag('Component', 'HistoryContent');
            return scope;
        });
        return null;
    }

    const primaryName = data.device.name;
    let secondaryName: string;
    let title: string;
    const isDualPlaneSetup = !!data.device.dualPlaneCompanion?.device;
    if (isDualPlaneSetup) {
        secondaryName = data.device.dualPlaneCompanion.device.name;

        if (secondaryName < primaryName) {
            title = `${secondaryName} & ${primaryName}`;
        } else {
            title = `${primaryName} & ${secondaryName}`;
        }
    } else {
        title = primaryName;
    }

    const items: HistoryItem[] = data.activityLogs.data.map(log => {
        const item = createHistoryItem(log, hasAssetsRead, hasTasksRead);

        return {
            ...item,
            device: isDualPlaneSetup ? item.device : undefined, // No need to show device name if it is not a dual plane device (reduce clutter)
        };
    });

    const itemsWithGroupName = items.map(item => {
        const groupName = getHumanizedTextFromDuration(
            DateTime.fromJSDate(item.timestamp).diffNow().as('milliseconds')
        );

        return { ...item, group: groupName ?? undefined };
    });

    const itemGroups: { label?: string; items: HistoryItem[] }[] = [];
    const uniqueGroupNames = new Set(itemsWithGroupName.map(item => item.group));
    uniqueGroupNames.forEach(groupName => {
        const items = itemsWithGroupName.filter(item => item.group === groupName);
        itemGroups.push({ label: groupName, items });
    });

    const exportActivityLogs = async () => {
        const exportVariables: HistoryContentQuery$variables = {
            id: deviceId,
            limit: 10_000,
        };

        try {
            const results = await fetchQuery<HistoryContentQuery>(
                environment,
                HistoryQuery,
                exportVariables
            ).toPromise();

            if (!results) {
                show({ text: 'Failed to export activity logs', variant: 'error' });
            }

            const data: HistoryExportItem[] = results!.activityLogs.data.map(log => {
                const historyItem = createHistoryItem(log, hasAssetsRead, hasTasksRead);

                const userName =
                    log.source === 'Alert' || log.source === 'GridEvent' ? 'accata' : historyItem.user?.name;
                const userId =
                    log.source === 'Alert' || log.source === 'GridEvent' ? 'accata' : historyItem.user?.username;

                return {
                    source: humanizeActivityLogSource(log.source),
                    type: log.type,
                    timestamp: log.timestamp,
                    user: { name: userName ?? '', id: userId },
                    action: historyItem.actionMessage,
                    device: { id: historyItem.device?.id ?? '', name: historyItem.device?.name ?? '' },
                };
            });

            const fileName = `activity-logs-${title.replaceAll(' ', '')}.csv`;
            // NOTE: Make sure to wrap these values in double quotes to ensure that the csv is parsed correctly
            const csvEntry = data.map(item =>
                [
                    `"${item.source}"`,
                    `"${item.type}"`,
                    `"${item.timestamp}"`,
                    `"${item.user.id}"`,
                    `"${item.user.name}"`,
                    `"${item.action}"`,
                    `"${item.device.id}"`,
                    `"${item.device.name}"`,
                ].join(',')
            );
            const csv = [CsvHeader, ...csvEntry].join('\n');

            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            link.click();
        } catch (error) {
            captureException(error, scope => {
                scope.setExtra('variables', exportVariables);
                scope.setTag('Component', 'HistoryContent');
                return scope;
            });
            show({ text: 'Failed to export activity logs', variant: 'error' });
        }
    };

    const loadMore = () => {
        const newLimit = limit + DefaultHistoryStep;

        const refetchVariables: HistoryContentQuery$variables = {
            id: deviceId,
            limit: newLimit,
        };

        setLimit(newLimit);

        fetchQuery<HistoryContentQuery>(environment, HistoryQuery, refetchVariables).subscribe({
            next(value) {
                setData(value);
            },
            error(error: unknown) {
                captureException(error, scope => {
                    scope.setExtra('variables', refetchVariables);
                    scope.setTag('Component', 'HistoryContent');
                    return scope;
                });
            },
        });
    };

    return (
        <DevicePane title={title}>
            <HistoryLine
                title='History'
                actions={[
                    {
                        buttonText: 'Export',
                        buttonIcon: <ArrowDownIcon />,
                        onClick: exportActivityLogs,
                    },
                ]}
                lineStyle='long'
                itemGroups={itemGroups}
                moreItems={data.activityLogs.hasMore}
                loadMoreCallback={loadMore}
            />
        </DevicePane>
    );
};

export const HistoryQuery = graphql`
    query HistoryContentQuery($id: ID!, $limit: Int!) {
        activityLogs(
            device: $id
            includeCompanionDevices: true
            types: [
                "device_added"
                "device_edited"
                "device_removed"
                "critical"
                "major"
                "offline"
                "high"
                "test_initiate"
            ]
            limit: $limit
        ) {
            hasMore
            total
            data {
                source
                type
                timestamp
                user {
                    username
                    name
                }
                changes {
                    field
                    oldValue
                    newValue
                }
                link {
                    __typename
                    ... on Device {
                        id
                        name
                    }
                    ... on Alert {
                        severity
                        category
                        message
                        isActive
                        device {
                            id
                            name
                        }
                    }
                    ... on ACPowerEvent {
                        worstStatus
                        duration
                        affectedAllFeeds
                        affectedFeeds {
                            id
                            status
                        }
                        device {
                            id
                            name
                        }
                    }
                    ... on DeviceBatteryTestResults {
                        id
                        commencedTime
                        state
                        task {
                            id
                            name
                            type
                            testState
                            abortedUser {
                                username
                                name
                            }
                        }
                        device {
                            id
                            name
                        }
                    }
                    ... on BatteryTest {
                        id
                        commencedTime
                        taskName: name
                    }
                }
            }
        }
        device(id: $id) {
            name
            dualPlaneCompanion {
                device {
                    name
                }
            }
        }
    }
`;

export function loadHistoryPageData(id: string) {
    return loadQuery(
        getGlobalEnvironment(),
        HistoryQuery,
        {
            id,
            limit: DefaultHistorySize,
        },
        {
            fetchPolicy: 'store-and-network',
        }
    );
}
