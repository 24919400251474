import { EditSiteFormQuery } from '../__generated__/EditSiteFormQuery.graphql';
import { SiteFormValues, createDefaultSiteFormValues } from '../schema';
import { GpsCoordinatesIn, SiteIn } from './saving';

type Site = EditSiteFormQuery['response']['site'];

export function convertSiteFormStateToApi(values: SiteFormValues): SiteIn {
    let coordinates: GpsCoordinatesIn | null = null;

    if (values.longitude && values.latitude) {
        coordinates = { longitude: values.longitude, latitude: values.latitude };
    }

    return {
        name: values.name,
        type: values.type,
        attributes: values.attributes,
        address: {
            address: values.address,
            state: values.state,
            postcode: values.postcode,
            coordinates,
        },
    };
}

export function convertSiteToFormState(site: Site): SiteFormValues {
    if (!site) {
        return createDefaultSiteFormValues();
    }

    const values: SiteFormValues = createDefaultSiteFormValues();

    values.name = site.name;
    if (site.type) {
        values.type = site.type;
    }

    if (site.address.address) {
        values.address = site.address.address;
    }
    if (site.address.state) {
        values.state = site.address.state;
    }
    if (site.address.postcode) {
        values.postcode = site.address.postcode;
    }

    if (site.address.coordinates) {
        values.latitude = site.address.coordinates.latitude ?? '';
        values.longitude = site.address.coordinates.longitude ?? '';
    }

    if (site.attributes) {
        values.attributes = site.attributes.map(attribute => ({ ...attribute }));
    }

    return values;
}
