import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { useRelayEnvironment } from 'react-relay';

import { useToast } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { IEnvironment, commitMutation } from 'relay-runtime';
import { DeleteModal } from 'views/manage/components';

import {
    BatteryTypeRemoveResponse,
    DeleteBatteryTypesModalMutation,
    DeleteBatteryTypesModalMutation$variables,
} from './__generated__/DeleteBatteryTypesModalMutation.graphql';

interface DeleteBatteryTypesModalProps {
    selectedBatteryTypes: string[];
    onDone: () => void;
    setShowDeleteModal: Dispatch<SetStateAction<boolean>>;
    showDeleteModal: boolean;
    tableRetry: () => void;
}

export const DeleteBatteryTypesModal: FC<DeleteBatteryTypesModalProps> = ({
    selectedBatteryTypes,
    onDone,
    setShowDeleteModal,
    showDeleteModal,
    tableRetry,
}) => {
    const environment = useRelayEnvironment();
    const { show } = useToast();
    const batteryTypeText = selectedBatteryTypes.length === 1 ? 'Battery Type' : 'Battery Types';
    const [isProcessing, setIsProcessing] = useState(false);

    const handleDeleteBatteryTypes = () => {
        setIsProcessing(true);

        deleteBatteryTypes(selectedBatteryTypes, environment)
            .then(response => {
                setIsProcessing(false);
                setShowDeleteModal(false);
                if (response === 'Success') {
                    show({
                        text: `Successfully deleted ${batteryTypeText.toLowerCase()}`,
                        variant: 'info',
                    });
                }

                if (response === 'UnknownBatteryType') {
                    show({
                        text: `Unknown ${batteryTypeText.toLowerCase()}`,
                        variant: 'error',
                    });
                }

                if (response === 'PartialSuccess') {
                    show({
                        text: `Unable to delete some battery types`,
                        variant: 'error',
                    });
                }

                onDone();
                setTimeout(() => {
                    // FIXME: this delay is present as elasticsearch isn't waiting for the refresh on delete
                    tableRetry();
                }, 1000);
            })
            .catch(() => {
                setIsProcessing(false);
                setShowDeleteModal(false);
                show({
                    text: `Error deleting ${batteryTypeText.toLowerCase()}`,
                    variant: 'error',
                });
            });
    };

    return (
        <DeleteModal
            itemText={batteryTypeText}
            showDeleteModal={showDeleteModal}
            setShowDeleteModal={setShowDeleteModal}
            handleDelete={handleDeleteBatteryTypes}
            isProcessing={isProcessing}
        />
    );
};

function deleteBatteryTypes(batteryTypeIds: string[], environment: IEnvironment): Promise<BatteryTypeRemoveResponse> {
    const variables: DeleteBatteryTypesModalMutation$variables = {
        ids: batteryTypeIds,
    };

    return new Promise((resolve, reject) => {
        commitMutation<DeleteBatteryTypesModalMutation>(environment, {
            mutation: graphql`
                mutation DeleteBatteryTypesModalMutation($ids: [ID!]!) {
                    removeBatteryTypes(ids: $ids)
                }
            `,
            variables,
            onError: () => {
                reject();
            },
            onCompleted: (response, err) => {
                if (err) {
                    reject();
                }

                if (response) {
                    resolve(response.removeBatteryTypes);
                }
            },
        });
    });
}
