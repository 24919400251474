import React, { FC, useEffect } from 'react';

import { FormikDropdown } from '@accesstel/pcm-ui';

import { FieldArray, useFormikContext } from 'formik';

import { BatteryTypeWithEodvs } from '../../../common';
import { ScheduleTestFormValues } from '../../../schema';
import style from '../../../style.module.css';

interface FullTestFieldsProps {
    batteryTypes: BatteryTypeWithEodvs[];
}

export const FullTestFields: FC<FullTestFieldsProps> = ({ batteryTypes }) => {
    const { setFieldValue, values } = useFormikContext<ScheduleTestFormValues>();

    useEffect(() => {
        batteryTypes.forEach((batteryType, idx) => {
            if (values.capacitySettings?.targets[idx] === undefined) {
                setFieldValue(`capacitySettings.targets[${idx}]`, {
                    batteryType: batteryType.id,
                    eodv: batteryType.eodvs.length > 0 ? batteryType.eodvs[0] : undefined,
                });
            }
        });
    }, [batteryTypes, setFieldValue, values.capacitySettings?.targets]);

    return batteryTypes.length === 0 ? (
        <></>
    ) : (
        <div className='flex flex-row space-x-6'>
            <div className='text-customEggplant text-base font-CynthoNext-Regular whitespace-nowrap'>
                End of discharge voltage (Vpc)
            </div>
            <div className='space-y-6 w-full'>
                <FieldArray
                    name='capacitySettings.targets'
                    render={() => {
                        return batteryTypes.map((battery, idx) => {
                            const suffix = battery.count === 1 ? 'Device' : 'Devices';
                            const items = battery.eodvs.map(eodv => ({
                                batteryType: battery.id,
                                eodv: `${eodv}`,
                            }));

                            return (
                                <div key={`eodv-${battery.id}`} className={style.settings_full_test_field_wrapper}>
                                    <FormikDropdown
                                        name={`capacitySettings.targets[${idx}].eodv`}
                                        light
                                        placeHolder={`${battery.type} - ${battery.count} ${suffix}`}
                                        items={items.map(item => item.eodv)}
                                        testId='end-of-discharge-voltage-vpc'
                                        variant='outlined'
                                    />
                                </div>
                            );
                        });
                    }}
                />
            </div>
        </div>
    );
};
