import React, { Dispatch, FC, useCallback } from 'react';

import { Button, Modal, ThemedPageHeading } from '@accesstel/pcm-ui';

import { FilterAction, FilterActionType } from 'filters/common';

import style from './style.module.css';

interface ClearAllModalProps {
    show: boolean;
    onClose: () => void;
    updateFilters: Dispatch<FilterAction<never, never>>;
}

export const ClearAllModal: FC<ClearAllModalProps> = ({ show, onClose, updateFilters }) => {
    const handleClearAll = useCallback(() => {
        updateFilters({ type: FilterActionType.ClearAll });
        onClose();
    }, [onClose, updateFilters]);

    return (
        <Modal open={show} closeButton={false} onHide={onClose}>
            <div className={style.modal}>
                <ThemedPageHeading value='Clear Filters' />
                <div className='text-customEggplant font-CynthoNext-Regular text-base pb-12'>
                    <span>Are you sure you want to remove all filters?</span>
                </div>
                <div className='space-x-2'>
                    <Button buttonText='Yes' buttonClasses='w-24' onClick={handleClearAll} />
                    <Button buttonText='No' buttonClasses='w-24' variant='white' onClick={onClose} />
                </div>
            </div>
        </Modal>
    );
};
