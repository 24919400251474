import React from 'react';

import { DualPlaneIcon, IconWithTooltip } from '@accesstel/pcm-ui';

import { createColumnHelper } from '@tanstack/react-table';
import classNames from 'classnames';
import { DeviceTableColumn, TestDeviceTableColumn } from 'filters/device';
import humanizeDuration from 'humanize-duration';
import { ColumnWithId } from 'layouts';
import { DateTime } from 'luxon';

import type { DevicesTable_devices$data } from './__generated__/DevicesTable_devices.graphql';
import style from './style.module.css';

type Device = DevicesTable_devices$data['data'][number];
type Tests = Device['tests'];

const columnHelper = createColumnHelper<Device>();

function getIconTooltip(state: string): JSX.Element {
    switch (state) {
        case 'Aborted':
        case 'Failed':
            return (
                <div className='pr-1'>
                    <IconWithTooltip message={state} variant='warn' />
                </div>
            );
        default:
            return <></>;
    }
}

function renderNameColumn(name: string, device: Device) {
    const icon = (
        <div className={classNames('text-customEggplant', style.dual_plane_icon)}>
            <DualPlaneIcon />
        </div>
    );

    if (device.dualPlaneCompanion?.device && device.dualPlaneCompanion?.configuration) {
        return (
            <div className='flex flex-row items-center'>
                <div>{name}</div>
                <div className='pl-2 -mt-3'>
                    <IconWithTooltip
                        message={`This device is part of a dual plane system with ${device.dualPlaneCompanion.device.name}`}
                        iconOverride={icon}
                    />
                </div>
            </div>
        );
    }

    return <span>{name}</span>;
}

function renderLastTestedColumn(tests: Tests) {
    const testData = tests.data[0];

    if (!testData || tests.data.length === 0) {
        return <div>Never tested</div>;
    }

    if (tests.data.length > 1) {
        return <span>Multiple Devices</span>;
    }

    if (!testData.commencedTime) {
        return <div>{testData.state}</div>;
    }

    const now = DateTime.now();
    const commencedDate = DateTime.fromISO(testData.commencedTime);
    const durationDiff = commencedDate.diff(now);

    return (
        <div className='flex flex-row items-center'>
            {getIconTooltip(testData.state)}
            {humanizeDuration(durationDiff.as('milliseconds'), {
                units: ['y', 'mo', 'd', 'h', 'm'],
                round: true,
                largest: 1,
            })}{' '}
            ago
        </div>
    );
}

export const SiteNameColumn = columnHelper.accessor('site.name', {
    id: DeviceTableColumn.Site,
    header: 'SITE',
    meta: {
        filterable: true,
        sortable: true,
        maxWidth: '30rem',
    },
});

export const SiteStateColumn = columnHelper.accessor('site.address.state', {
    id: DeviceTableColumn.State,
    header: 'STATE',
    meta: {
        filterable: true,
        sortable: true,
    },
});

export const NameColumn = columnHelper.accessor('name', {
    id: DeviceTableColumn.Name,
    header: 'NAME',
    cell: ({ cell }) => renderNameColumn(cell.getValue(), cell.row.original),
    meta: {
        filterable: true,
        sortable: true,
        maxWidth: '15rem',
    },
});

export const LastTestedColumn = columnHelper.accessor('tests', {
    id: DeviceTableColumn.LastTested,
    header: 'LAST TESTED',
    cell: ({ cell }) => renderLastTestedColumn(cell.getValue()),
    meta: {
        filterable: true,
        sortable: true,
    },
});

export const TableColumns = [SiteNameColumn, SiteStateColumn, NameColumn, LastTestedColumn] as ColumnWithId<
    TestDeviceTableColumn,
    Device
>[];
