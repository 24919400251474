import { NavigateTo } from '@accesstel/pcm-ui';

import { Paths } from 'lib/routes';

import { DeviceIdParam, MetricParam } from '../settings';
import { SelectedMetric } from '../types';
import { formatMetricDefinition } from './metric-definition';

export function makeLinkToMetric(devices: string[] | string, metrics: SelectedMetric[] | SelectedMetric): NavigateTo {
    const devicesAsArray = Array.isArray(devices) ? devices : [devices];
    const metricsAsArray = Array.isArray(metrics) ? metrics : [metrics];

    const formattedMetrics = metricsAsArray.map(formatMetricDefinition);

    return {
        pathname: Paths.ExploreMetrics,
        search: {
            [DeviceIdParam]: devicesAsArray.join(','),
            [MetricParam]: formattedMetrics.join(','),
        },
    };
}
