import React, { FC, ReactNode } from 'react';

export interface DevicePaneProps {
    title: ReactNode;
    subtitle?: ReactNode;

    children: ReactNode;
}

export const DevicePane: FC<DevicePaneProps> = ({ title, subtitle, children }) => {
    return (
        <div className='flex flex-col items-stretch'>
            <div className='flex flex-row pl-4 gap-2 items-baseline'>
                <div className='font-CynthoNext-SemiBold text-3xl truncate'>{title}</div>
                <div className='font-CynthoNext-Light text-sm whitespace-nowrap'>{subtitle}</div>
            </div>
            <div className='flex-grow bg-white p-5'>{children}</div>
        </div>
    );
};
