import { numberToLocaleString } from 'lib/numberFormatters';
import { DeviceIntegrationIn } from 'views/manage/__generated__/mutations_EditDeviceMutation.graphql';

import { DualPlaneConfiguration, EditDeviceFormQuery } from '../__generated__/EditDeviceFormQuery.graphql';
import { SettingsName } from '../components/Integration';
import { DeviceIn } from '../helperFunctions';
import { DeviceFormValues, DualPlaneConfigurationType, createDefaultDeviceFormValues } from '../schema';
import { DeviceBatterySettings } from '../sub-forms/battery-settings';
import { ProtocolIn } from '../sub-forms/connectivity-settings/__generated__/useConnectionTestMutation.graphql';
import {
    ConnectivitySettingsFormValues,
    createDefaultConnectivityBasicSettingsValues,
    createDefaultConnectivitySettingsValues,
    createDefaultConnectivitySnmpSettingsValues,
} from '../sub-forms/connectivity-settings/schema';

type Device = EditDeviceFormQuery['response']['device'];
type ConnectionSettings = NonNullable<Device>['connectionSettings'];

export function convertDeviceToFormState(device: Device): DeviceFormValues {
    if (!device) {
        return createDefaultDeviceFormValues();
    }

    const values: DeviceFormValues = createDefaultDeviceFormValues();

    // General information
    values.name = device.name;
    values.type = device.type.id;

    if (device.firmware && device.firmware.defined) {
        values.firmwareVersion = device.firmware.id;
    }

    if (device.site) {
        values.site = {
            id: device.site.id,
            displayName: device.site.name,
        };
    }

    if (device.attributes) {
        values.attributes = device.attributes.map(attribute => ({ ...attribute }));
    }

    values.monitorOnly = device.monitorOnly;

    values.settings = convertConnectionSettingsToFormState(device.connectionSettings);

    // Integrations
    if (device.integrations) {
        for (const integration of device.integrations) {
            // FIXME: We should be supporting any integration not just this one
            if (integration.type.id !== 'fbdr') {
                continue;
            }

            const elementNameAttribute = integration.attributes.find(
                attribute => attribute.name === SettingsName.ElementName
            );
            const siteIdAttribute = integration.attributes.find(attribute => attribute.name === SettingsName.SiteId);

            if (elementNameAttribute) {
                values.optimaIntegration.elementName = elementNameAttribute.value ?? '';
            }
            if (siteIdAttribute) {
                values.optimaIntegration.siteId = siteIdAttribute.value ?? '';
            }
        }
    }

    // Battery Information
    if (device.battery.reserveTime != null) {
        values.batteries.reserveTime = numberToLocaleString(device.battery.reserveTime);
    }
    if (device.battery.allowedVoltage.minimum != null) {
        values.batteries.minimumAllowedVoltage = numberToLocaleString(device.battery.allowedVoltage.minimum);
    }
    if (device.battery.allowedVoltage.maximum != null) {
        values.batteries.maximumAllowedVoltage = numberToLocaleString(device.battery.allowedVoltage.maximum);
    }

    if (device.battery.quickTestFailThreshold != null) {
        values.batteries.quickTestFailThreshold = numberToLocaleString(device.battery.quickTestFailThreshold);
    }
    if (device.battery.quickTestCheckPercent != null) {
        values.batteries.quickTestCheckPercent = numberToLocaleString(device.battery.quickTestCheckPercent);
    }

    if (device.battery.strings.count > 0) {
        values.batteries.strings = device.battery.strings.strings.map(batteryString => ({
            type: {
                id: batteryString.type.id,
                manufacturer: batteryString.type.manufacturer,
                model: batteryString.type.model,
                displayName: `${batteryString.type.manufacturer} ${batteryString.type.model}`, // FIXME: We have 2 places that format this. Maybe combine
            },
            cellsPerString: batteryString.cellsPerString.toFixed(0),
            installDate: batteryString.installDate ? new Date(batteryString.installDate) : null,
            manufactureDate: batteryString.manufactureDate ? new Date(batteryString.manufactureDate) : null,
        }));
    }

    // Companion
    if (device.dualPlaneCompanion) {
        if (device.dualPlaneCompanion.configuration) {
            values.companionConfiguration = device.dualPlaneCompanion.configuration as DualPlaneConfigurationType;
        }

        if (device.dualPlaneCompanion.device) {
            values.companionReference = {
                id: device.dualPlaneCompanion.device.id,
                displayName: device.dualPlaneCompanion.device.name,
                siteName: device.dualPlaneCompanion.device.site.name,
            };
        }
    }
    return values;
}

function convertConnectionSettingsToFormState(settings: ConnectionSettings): ConnectivitySettingsFormValues {
    const values: ConnectivitySettingsFormValues = createDefaultConnectivitySettingsValues();

    values.addresses = [...settings.addresses];

    if (settings.protocols.length > 0) {
        // FIXME: support arbitrary protocols
        const basicProtocol = settings.protocols.find(protocol => protocol.type === 'Basic');
        const snmpProtocol = settings.protocols.find(protocol => protocol.type === 'Snmp');

        if (basicProtocol) {
            values.webSettings = createDefaultConnectivityBasicSettingsValues(basicProtocol.definition);
            values.webSettings.username = basicProtocol.credentials?.username ?? '';
            // FIXME: Don't retrieve the password value unless the user asks for it
            values.webSettings.password = basicProtocol.credentials?.password.value ?? '';
        }

        if (snmpProtocol) {
            values.snmpSettings = createDefaultConnectivitySnmpSettingsValues(snmpProtocol.definition);

            const protocolSettings = snmpProtocol.settings!;
            values.snmpSettings.port = snmpProtocol.port!.toFixed(0);
            values.snmpSettings.version = snmpProtocol.version!;

            if (snmpProtocol.version === 'V3') {
                values.snmpSettings.user = protocolSettings.user!;
                values.snmpSettings.engineId = protocolSettings.engineId!;
                values.snmpSettings.securityLevel = protocolSettings.securityLevel!;
                values.snmpSettings.authType = protocolSettings.authType!;
                values.snmpSettings.privType = protocolSettings.privType!;

                // FIXME: Don't retrieve the password value unless the user asks for it
                if (
                    protocolSettings.authPassphrase &&
                    protocolSettings.authPassphrase.isSet &&
                    protocolSettings.authPassphrase.canBeRetrieved
                ) {
                    values.snmpSettings.authPassphrase = protocolSettings.authPassphrase.value ?? '';
                }

                if (
                    protocolSettings.privPassphrase &&
                    protocolSettings.privPassphrase.isSet &&
                    protocolSettings.privPassphrase.canBeRetrieved
                ) {
                    values.snmpSettings.privPassphrase = protocolSettings.privPassphrase.value ?? '';
                }
            } else {
                values.snmpSettings.readOnlyCommunity = snmpProtocol.settings!.readOnlyCommunity ?? '';
                values.snmpSettings.readWriteCommunity = snmpProtocol.settings!.readWriteCommunity ?? '';
            }
        }
    }

    return values;
}

export function switchCompanionStateToNormal(
    values: DeviceFormValues,
    duplicateNormalBatteries: boolean
): DeviceFormValues {
    const newValues: DeviceFormValues = {
        ...values.newCompanion!,
        newSite: values.newSite,
        newBatteryType: values.newBatteryType,
        firmwareVersion: null,
        type: values.type,
        site: values.site,
        companionConfiguration: values.companionConfiguration,
        companionReference: null,
        newCompanion: values.newCompanion,
    };

    newValues.optimaIntegration.siteId = values.optimaIntegration.siteId;

    if (duplicateNormalBatteries) {
        newValues.batteries = values.batteries;
    }
    return newValues;
}

export function convertDeviceFormStateToApi(values: DeviceFormValues): DeviceIn {
    let dualPlaneType: DualPlaneConfiguration | null = null;
    if (values.companionConfiguration && values.companionConfiguration !== DualPlaneConfigurationType.Single) {
        dualPlaneType = values.companionConfiguration;
    }

    let integrations: DeviceIntegrationIn[] | null = null;

    // FIXME: Support arbitrary integration types
    if (values.optimaIntegration.elementName && values.optimaIntegration.siteId) {
        integrations = [
            {
                type: 'fbdr',
                attributes: [
                    {
                        name: SettingsName.ElementName,
                        value: values.optimaIntegration.elementName,
                    },
                    {
                        name: SettingsName.SiteId,
                        value: values.optimaIntegration.siteId,
                    },
                ],
            },
        ];
    }

    return {
        name: values.name,
        type: values.type,
        monitorOnly: values.monitorOnly,
        site: values.site!.id,
        targetFirmware: values.firmwareVersion,
        dualPlaneType: values.companionReference?.id ? dualPlaneType : null,
        dualPlaneDevice: dualPlaneType ? values.companionReference?.id : null,
        attributes: values.attributes,
        connectionSettings: convertConnectivitySettingsFormStateToApi(values.settings),
        integrations,
        battery: convertBatteryFormStateToApi(values.batteries),
    };
}

export function convertBatteryFormStateToApi(battery: DeviceBatterySettings): NonNullable<DeviceIn['battery']> {
    return {
        strings: battery.strings.map(batteryString => ({
            type: batteryString.type!.id,
            cellsPerString: Number(batteryString.cellsPerString),
            installDate: batteryString.installDate?.toISOString(),
            manufactureDate: batteryString.manufactureDate?.toISOString(),
        })),
        reserveTime: battery.reserveTime ? Number(battery.reserveTime) : null,
        minimumAllowedVoltage: battery.minimumAllowedVoltage ? Number(battery.minimumAllowedVoltage) : null,
        maximumAllowedVoltage: battery.maximumAllowedVoltage ? Number(battery.maximumAllowedVoltage) : null,
        quickTestFailThreshold: battery.quickTestFailThreshold ? Number(battery.quickTestFailThreshold) : null,
        quickTestCheckPercent: battery.quickTestCheckPercent ? Number(battery.quickTestCheckPercent) : null,
    };
}

export function convertConnectivitySettingsFormStateToApi(
    settings: ConnectivitySettingsFormValues
): DeviceIn['connectionSettings'] {
    const protocols: Array<ProtocolIn> = [];

    const { snmpSettings, webSettings, addresses } = settings;

    if (snmpSettings?.protocolId) {
        if (snmpSettings.version === 'V3') {
            protocols.push({
                id: snmpSettings.protocolId,
                snmp: {
                    version: snmpSettings.version,
                    port: Number(snmpSettings.port),
                    settingsV3: {
                        authType: snmpSettings.authType,
                        authPassphrase: snmpSettings.authPassphrase,
                        privType: snmpSettings.privType,
                        privPassphrase: snmpSettings.privPassphrase,
                        securityLevel: snmpSettings.securityLevel,
                        user: snmpSettings.user!,
                        engineId: snmpSettings.engineId,
                    },
                },
            });
        } else {
            protocols.push({
                id: snmpSettings.protocolId,
                snmp: {
                    version: snmpSettings.version,
                    port: Number(snmpSettings.port),
                    settingsV1V2c: {
                        readOnlyCommunity: snmpSettings.readOnlyCommunity!,
                        readWriteCommunity: snmpSettings.readWriteCommunity!,
                    },
                },
            });
        }
    }

    if (webSettings?.protocolId) {
        protocols.push({
            id: webSettings.protocolId,
            basic: {
                username: webSettings.username,
                password: webSettings.password,
            },
        });
    }

    return {
        addresses,
        protocols,
    };
}
