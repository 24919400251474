import React, { Dispatch, FC, useEffect, useState } from 'react';
import { fetchQuery } from 'react-relay';
import { useRelayEnvironment } from 'react-relay';

import { useFormikContext } from 'formik';
import { getQualifiedName } from 'lib/namespaces';

import {
    queries_GetDeviceTypeConnectivityQuery,
    queries_GetDeviceTypeConnectivityQuery$data,
    queries_GetDeviceTypeConnectivityQuery$variables,
} from '../../__generated__/queries_GetDeviceTypeConnectivityQuery.graphql';
import { getDeviceTypeConnectivityQuery } from '../../queries';
import { DualPlaneCompanion } from './components';
import { Integration } from './components/Integration';
import { FormControlAction, FormControlParameters } from './lib/reducer';
import { DeviceFormValues } from './schema';
import { DualPlaneConfiguration } from './sections/DualPlaneConfiguration';
import { GeneralInformation } from './sections/GeneralInformation';
import { MonitorOnlyMode } from './sub-forms/MonitorOnlyMode';
import { DeviceBatteries } from './sub-forms/battery-settings';
import { ConnectivitySettings, ProtocolTypes } from './sub-forms/connectivity-settings/ConnectivitySettings';

export interface DeviceInformationProps {
    editingDeviceId?: string;

    // form control
    formControlState: FormControlParameters;
    formControlDispatch: Dispatch<FormControlAction>;
}

export const DeviceInformation: FC<DeviceInformationProps> = ({
    editingDeviceId,

    formControlState,
    formControlDispatch,
}) => {
    const { values, setFieldValue } = useFormikContext<DeviceFormValues>();
    const environment = useRelayEnvironment();
    const [deviceTypeConnectivity, setDeviceTypeConnectivity] = useState<queries_GetDeviceTypeConnectivityQuery$data>();

    useEffect(() => {
        if (!values.type) {
            return;
        }

        const deviceTypeVariables: queries_GetDeviceTypeConnectivityQuery$variables = {
            id: values.type,
        };

        fetchQuery<queries_GetDeviceTypeConnectivityQuery>(
            environment,
            getDeviceTypeConnectivityQuery,
            deviceTypeVariables
        )
            .toPromise()
            .then(data => {
                if (data) {
                    setDeviceTypeConnectivity(data);
                }
            });
    }, [environment, values.type]);

    // FIXME: this is a hack. It doesn't really belong here, and wouldn't be necessary if we had proper protocol support
    useEffect(() => {
        // FIXME: support arbitrary protocols
        let requireSnmp = false;
        let snmpProtocolId = '';
        let requireBasic = false;
        let basicProtocolId = '';

        deviceTypeConnectivity?.deviceType?.connectivity.protocols.forEach(protocol => {
            if (protocol.type === ProtocolTypes.Snmp) {
                requireSnmp = true;
                snmpProtocolId = protocol.id;
            } else if (protocol.type === ProtocolTypes.Basic) {
                requireBasic = true;
                basicProtocolId = protocol.id;
            }
        });

        setFieldValue(getQualifiedName('snmpSettings.protocolId', 'settings'), snmpProtocolId);
        setFieldValue(getQualifiedName('basicSettings.protocolId', 'settings'), basicProtocolId);
        setFieldValue(getQualifiedName('requireSnmp', 'settings'), requireSnmp);
        setFieldValue(getQualifiedName('requireBasic', 'settings'), requireBasic);
        setFieldValue(
            getQualifiedName('ipEnabled', 'settings'),
            !!deviceTypeConnectivity?.deviceType?.connectivity.ipEnabled
        );
        setFieldValue(getQualifiedName('snmpSettings.protocolId', 'newCompanion.settings'), snmpProtocolId);
        setFieldValue(getQualifiedName('basicSettings.protocolId', 'newCompanion.settings'), basicProtocolId);
        setFieldValue(getQualifiedName('requireSnmp', 'newCompanion.settings'), requireSnmp);
        setFieldValue(getQualifiedName('requireBasic', 'newCompanion.settings'), requireBasic);
        setFieldValue(
            getQualifiedName('ipEnabled', 'newCompanion.settings'),
            !!deviceTypeConnectivity?.deviceType?.connectivity.ipEnabled
        );
    }, [
        deviceTypeConnectivity?.deviceType?.connectivity.ipEnabled,
        deviceTypeConnectivity?.deviceType?.connectivity.protocols,
        setFieldValue,
    ]);

    return (
        <div className='space-y-6'>
            <GeneralInformation formControlState={formControlState} formControlDispatch={formControlDispatch} />

            {deviceTypeConnectivity?.deviceType?.connectivity &&
                (deviceTypeConnectivity.deviceType.connectivity.ipEnabled === true ||
                    deviceTypeConnectivity.deviceType.connectivity.protocols.length !== 0) && (
                    <ConnectivitySettings
                        deviceType={values.type}
                        namespace='settings'
                        connectivity={deviceTypeConnectivity}
                    />
                )}

            <Integration namespace='optimaIntegration' />

            <MonitorOnlyMode />

            <DeviceBatteries
                namespace='batteries'
                formControlState={formControlState}
                formControlDispatch={formControlDispatch}
            />

            <DualPlaneConfiguration
                editingDeviceId={editingDeviceId}
                formControlState={formControlState}
                formControlDispatch={formControlDispatch}
            />

            {formControlState.addNewDualPlaneCompanionDevice && (
                <DualPlaneCompanion
                    namespace='newCompanion'
                    deviceTypeConnectivity={deviceTypeConnectivity}
                    planeAMonitorOnlyModeState={values.monitorOnly}
                />
            )}

            {formControlState.addNewDualPlaneCompanionDevice && (
                <DeviceBatteries
                    namespace='newCompanion.batteries'
                    formControlState={formControlState}
                    formControlDispatch={formControlDispatch}
                    companionDevice={true}
                />
            )}
        </div>
    );
};
