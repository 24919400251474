import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export function useHistoryState<T>(name: string, initial: T): [T, (value: T) => void] {
    const location = useLocation();
    const navigate = useNavigate();

    const current = { ...initial, ...(location.state?.stored?.[name] ?? initial) } as T;

    const setState = useCallback(
        (newValue: T) => {
            const existing = location.state?.stored ?? {};
            const newHistory = {
                state: {
                    ...(location.state ?? {}),
                    stored: {
                        ...existing,
                        [name]: newValue,
                    },
                },
            };

            navigate(`${location.pathname}${location.search}${location.hash}`, {
                state: newHistory.state,
                replace: true,
                preventScrollReset: true,
            });
        },
        [location.hash, location.pathname, location.search, location.state, name, navigate]
    );

    return [current, setState];
}
