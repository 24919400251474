import { MetricsData } from '../common';

export function supportsTemperatureMetric(data: MetricsData): boolean {
    const voltageData = data[0];
    const temperatureData = data[1];
    const currentData = data[2];
    const powerData = data[3];

    // when all datapoint exists except temperature
    return !(
        voltageData &&
        voltageData.length > 0 &&
        currentData &&
        currentData.length > 0 &&
        powerData &&
        powerData.length > 0 &&
        (temperatureData === null || temperatureData.length === 0)
    );
}
