import React, { FC, Fragment } from 'react';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay';

import { Tooltip } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { renderTableStatusCell } from 'lib/table-columns';

import { BatteryTestCardExpansionContentLoadQuery } from './__generated__/BatteryTestCardExpansionContentLoadQuery.graphql';

const DevicesToDisplay = 2;

export const ContentQuery = graphql`
    query BatteryTestCardExpansionContentLoadQuery($id: ID!) {
        task(id: $id) {
            devices {
                total
                data {
                    id
                    name
                    site {
                        name
                        address {
                            state
                        }
                    }
                    test(id: $id) {
                        state
                    }
                }
            }
        }
    }
`;

interface BatteryTestCardExpansionContentProps {
    preloadedQuery: PreloadedQuery<BatteryTestCardExpansionContentLoadQuery>;
}

export const BatteryTestCardExpansionContent: FC<BatteryTestCardExpansionContentProps> = ({ preloadedQuery }) => {
    const data = usePreloadedQuery(ContentQuery, preloadedQuery);

    // Sort devices so that failed tests are first
    const sortedDevices = [...data.task!.devices.data];

    sortedDevices.sort((leftDevice, rightDevice) => {
        if (leftDevice.test?.state === 'Failed' && rightDevice.test?.state !== 'Failed') {
            return -1;
        }

        if (leftDevice.test?.state !== 'Failed' && rightDevice.test?.state === 'Failed') {
            return 1;
        }

        return 0;
    });

    let topDevices;
    if (sortedDevices.length > DevicesToDisplay) {
        topDevices = sortedDevices.slice(0, DevicesToDisplay);
    } else {
        topDevices = sortedDevices;
    }

    return (
        <>
            <div className='mt-4 pt-4 border-t grid' style={{ gridTemplateColumns: '1fr max-content' }}>
                {topDevices.map(device => (
                    <Fragment key={device.id}>
                        <Tooltip content={`${device.site.name}, ${device.site.address.state}`}>
                            <div className='w-max'>{device.name}</div>
                        </Tooltip>
                        {renderTableStatusCell(device.test?.state ?? 'Cancelled', undefined, 'base')}
                    </Fragment>
                ))}

                {sortedDevices.length > DevicesToDisplay && (
                    <div className='col-span-2 text-customEggplantWhite'>
                        And {sortedDevices.length - DevicesToDisplay} more devices
                    </div>
                )}
            </div>
        </>
    );
};
