/**
 * @generated SignedSource<<819aa5ec9ea636d82bf428bd82e150fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MetricUnit = "AmpHours" | "Amps" | "Celsius" | "Hertz" | "Minutes" | "Percent" | "VoltAmps" | "Volts" | "WattHours" | "Watts" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SelectMetricsPaneFragment$data = {
  readonly metricTypes: ReadonlyArray<{
    readonly displayName: string;
    readonly metric: string;
    readonly unit: MetricUnit | null;
  }>;
  readonly " $fragmentType": "SelectMetricsPaneFragment";
};
export type SelectMetricsPaneFragment$key = {
  readonly " $data"?: SelectMetricsPaneFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SelectMetricsPaneFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "deviceIds"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SelectMetricsPaneFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "aggregateable",
          "value": true
        },
        {
          "kind": "Variable",
          "name": "devices",
          "variableName": "deviceIds"
        }
      ],
      "concreteType": "DefinedMetric",
      "kind": "LinkedField",
      "name": "metricTypes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "metric",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unit",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "7a44f3560741777bbfe90bf1871a0e22";

export default node;
