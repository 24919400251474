/**
 * @generated SignedSource<<3c78cf6568d970f439d9ea5bc11912a5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AssetDistributionType = "BatteryTypeTechnology" | "DeviceType" | "SiteType" | "%future added value";
export type queries_GetAssetsDistributionQuery$variables = {
  type: AssetDistributionType;
};
export type queries_GetAssetsDistributionQuery$data = {
  readonly assetDistribution: {
    readonly distribution: ReadonlyArray<{
      readonly displayName: string | null;
      readonly key: string;
      readonly value: number;
    }>;
  };
};
export type queries_GetAssetsDistributionQuery = {
  response: queries_GetAssetsDistributionQuery$data;
  variables: queries_GetAssetsDistributionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "type"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": "AssetDistribution",
    "kind": "LinkedField",
    "name": "assetDistribution",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AssetDistributionCount",
        "kind": "LinkedField",
        "name": "distribution",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "key",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "queries_GetAssetsDistributionQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "queries_GetAssetsDistributionQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d53a86da495ff5d86f99c491837180d9",
    "id": null,
    "metadata": {},
    "name": "queries_GetAssetsDistributionQuery",
    "operationKind": "query",
    "text": "query queries_GetAssetsDistributionQuery(\n  $type: AssetDistributionType!\n) {\n  assetDistribution(type: $type) {\n    distribution {\n      key\n      displayName\n      value\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d9fed4ba18081efa73e681e47df9ace5";

export default node;
