import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { ThemedPageHeading } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { isFeatureEnabled } from 'lib/featureFlag';

import { OverallPerformanceSection$key } from './__generated__/OverallPerformanceSection.graphql';
import { BatteriesGauge, FirmwareGauge, GaugeTitle, PowerControllersGauge } from './components';
import style from './style.module.css';

export interface OverallPerformanceSectionProps {
    data: OverallPerformanceSection$key | null;
    error?: boolean;
    retry: () => void;
}

export const OverallPerformanceSection: FC<OverallPerformanceSectionProps> = ({ data, error, retry }) => {
    const props = useFragment<OverallPerformanceSection$key>(
        graphql`
            fragment OverallPerformanceSection on Query {
                ...PowerControllersGauge
                ...BatteriesGauge
                ...FirmwareGauge
            }
        `,
        data
    );

    return (
        <div className='pt-10'>
            <ThemedPageHeading value='Overall Performance' subheading secondary />
            <div className={style.gauge_row}>
                <div className='flex flex-col items-center'>
                    <PowerControllersGauge data={props} error={error} retry={retry} />
                    <GaugeTitle title='Power Controllers' />
                </div>
                <div className='flex flex-col items-center'>
                    <BatteriesGauge data={props} error={error} retry={retry} />
                    <GaugeTitle title='Batteries' />
                </div>
                {isFeatureEnabled('firmware') ? (
                    <div className='flex flex-col items-center'>
                        <FirmwareGauge data={props} error={error} retry={retry} />
                        <GaugeTitle title='Firmware' />
                    </div>
                ) : (
                    // An extra empty section that will takes up the space of the 3rd column
                    <div style={{ width: '15.3rem' }}>{/* TODO */}</div>
                )}

                {/* inline styles below are just to keep the layout, will be removed when gauges are implemented */}
                <div style={{ width: '15.3rem' }}>{/* TODO */}</div>
            </div>
        </div>
    );
};
