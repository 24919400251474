/**
 * @generated SignedSource<<c6dbb38e90325317b2b7e6901acf968c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RunHoursGauge_battery$data = {
  readonly latestTimeRemaining: number | null;
  readonly typicalMaximumTimeRemaining: number | null;
  readonly " $fragmentType": "RunHoursGauge_battery";
};
export type RunHoursGauge_battery$key = {
  readonly " $data"?: RunHoursGauge_battery$data;
  readonly " $fragmentSpreads": FragmentRefs<"RunHoursGauge_battery">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "unit",
    "value": "Hours"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RunHoursGauge_battery",
  "selections": [
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "latestTimeRemaining",
      "storageKey": "latestTimeRemaining(unit:\"Hours\")"
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "typicalMaximumTimeRemaining",
      "storageKey": "typicalMaximumTimeRemaining(unit:\"Hours\")"
    }
  ],
  "type": "DeviceBatteryMetrics",
  "abstractKey": null
};
})();

(node as any).hash = "108a5586997033ffd2aaeb18ac7294ab";

export default node;
