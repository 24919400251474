import React, { FC, useEffect } from 'react';

import graphql from 'babel-plugin-relay/macro';
import { logError } from 'lib/log';
import { useQuery } from 'lib/query-helpers';

import { AbortTestProgressBarQuery } from './__generated__/AbortTestProgressBarQuery.graphql';
import style from './style.module.css';

interface AbortTestProgressBarProps {
    testId?: string;
}

export const AbortTestProgressBar: FC<AbortTestProgressBarProps> = ({ testId }) => {
    let completedItems = 0;
    let totalItems = 1;

    const { data: props, error, retry } = useQuery<AbortTestProgressBarQuery>(
        graphql`
            query AbortTestProgressBarQuery($id: ID!) {
                task(id: $id) {
                    devices {
                        total
                    }
                    countByState {
                        state
                        deviceCount
                    }
                }
            }
        `,
        {
            id: testId!,
        }
    );

    if (error) {
        logError('Error running AbortTestProgressBarQuery', error);
    }

    if (props && props.task) {
        totalItems = props.task.devices.total;

        props.task.countByState.forEach(el => {
            // POSSIBLE STATES
            // state: 'aborted'
            // state: 'completed'
            // state: 'failed'
            // state: 'in-progress'
            // state: 'passed'
            // state: 'scheduled'
            // state: 'smart-start'
            // state: 'waiting'
            // state: 'inconclusive'
            // state: 'finalizing'
            // state: 'analyzing'
            if (
                el.state === 'aborted' ||
                el.state === 'failed' ||
                el.state === 'passed' ||
                el.state === 'completed' ||
                el.state === 'inconclusive'
            ) {
                completedItems += el.deviceCount;
            }
        });
    }

    useEffect(() => {
        const handle = setInterval(retry, 5000);
        return () => {
            clearInterval(handle);
        };
    }, [retry, testId]);

    const completedPerc = (completedItems / totalItems) * 100;
    const remainingPerc = 100 - completedPerc;

    return (
        <div className={`${style.abort_progress_bar} rounded-full flex flex-row flex-nowrap overflow-hidden`}>
            <div className='bg-customCoral' style={{ width: `${completedPerc}%` }} />
            <div className='bg-customWhiteEggplant' style={{ width: `${remainingPerc}%` }} />
        </div>
    );
};
