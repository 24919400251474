/**
 * @generated SignedSource<<a632f2fdfcbbd652e8f0e87113bc02b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type queries_GetExportFormAvailableMetricsQuery$variables = {};
export type queries_GetExportFormAvailableMetricsQuery$data = {
  readonly metricsForExport: ReadonlyArray<{
    readonly displayName: string;
    readonly metric: string;
  }>;
};
export type queries_GetExportFormAvailableMetricsQuery = {
  response: queries_GetExportFormAvailableMetricsQuery$data;
  variables: queries_GetExportFormAvailableMetricsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "DefinedMetric",
    "kind": "LinkedField",
    "name": "metricsForExport",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "metric",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayName",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "queries_GetExportFormAvailableMetricsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "queries_GetExportFormAvailableMetricsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "d732d087cbb0beb84f557c744e35a55e",
    "id": null,
    "metadata": {},
    "name": "queries_GetExportFormAvailableMetricsQuery",
    "operationKind": "query",
    "text": "query queries_GetExportFormAvailableMetricsQuery {\n  metricsForExport {\n    metric\n    displayName\n  }\n}\n"
  }
};
})();

(node as any).hash = "d485f5ba6d327f2fe316ec480a612a62";

export default node;
