import React, { FC } from 'react';

import { CommonFilterProps, RangeFilter } from 'filters/common';

import { DeviceFilterRange } from './DeviceFilterRange';

export const DeviceBatteryStateOfHealthFilterUI: FC<CommonFilterProps<RangeFilter | null>> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    return (
        <DeviceFilterRange
            current={current}
            onClearOrBack={onClearOrBack}
            onClose={onClose}
            onSubmit={onSubmit}
            title='Filter by Battery State of Health'
            distributionType='BatteryStateOfHealth'
            countLabel={['Min SoH', 'Max SoH']}
            range={[0, 100]}
            showBack={hasPrevious}
        />
    );
};
