import { DateRangeFilter } from 'components/FilterDateSelect/common';
import { formatDateFilter } from 'filters/common/util';

import { FilterDefinition, FilterValueMap } from '../common';
import {
    AlertCategoryFilterUI,
    AlertDateFilterUI,
    AlertDeviceNameFilterUI,
    AlertDomainFilterUI,
    AlertIsActiveFilterUI,
    AlertOriginatorFilterUI,
    AlertSeverityFilterUI,
    AlertSiteNameFilterUI,
} from './components';
import {
    AlertCategoryFilter,
    AlertClearTimeFilter,
    AlertDeviceNameFilter,
    AlertDomainFilter,
    AlertExtraFilters,
    AlertOriginatorFilter,
    AlertRaiseTimeFilter,
    AlertSeverityFilter,
    AlertSiteNameFilter,
    AlertTableColumn,
    IsAlertActive,
} from './types';

export type AlertColumnFilterMap = {
    [AlertTableColumn.Severity]: AlertSeverityFilter[];
    [AlertTableColumn.Domain]: AlertDomainFilter[];
    [AlertTableColumn.Message]: null;
    [AlertTableColumn.DeviceName]: AlertDeviceNameFilter[];
    [AlertTableColumn.SiteName]: AlertSiteNameFilter[];
    [AlertTableColumn.RaiseDate]: DateRangeFilter | null;
    [AlertTableColumn.ClearDate]: DateRangeFilter | null;
    [AlertTableColumn.Category]: AlertCategoryFilter[];
    [AlertTableColumn.Originator]: AlertOriginatorFilter[];
    [AlertTableColumn.IsActive]: IsAlertActive | null;
};

export const DefaultValues: FilterValueMap<AlertColumnFilterMap> = {
    [AlertTableColumn.Severity]: [],
    [AlertTableColumn.Domain]: [],
    [AlertTableColumn.Message]: null,
    [AlertTableColumn.DeviceName]: [],
    [AlertTableColumn.SiteName]: [],
    [AlertTableColumn.RaiseDate]: null,
    [AlertTableColumn.ClearDate]: null,
    [AlertTableColumn.Category]: [],
    [AlertTableColumn.Originator]: [],
    [AlertTableColumn.IsActive]: null,
};

export const DefaultExtraFilters: Record<AlertExtraFilters, unknown> = {};

export const StaticAlertFilterDefinitions: FilterDefinition<AlertTableColumn>[] = [
    {
        type: 'multi',
        name: 'Severity',
        category: 'Severity',
        column: AlertTableColumn.Severity,
        component: () => AlertSeverityFilterUI,
        describeValue: (value: AlertSeverityFilter) => value.value,
    },
    {
        type: 'multi',
        name: 'Domain',
        category: 'Domain',
        column: AlertTableColumn.Domain,
        component: () => AlertDomainFilterUI,
        describeValue: (value: AlertDomainFilter) => value.value,
    },
    {
        type: 'multi',
        name: 'Device Name',
        category: 'Device Name',
        column: AlertTableColumn.DeviceName,
        component: () => AlertDeviceNameFilterUI,
        describeValue: (value: AlertDeviceNameFilter) => value.name,
    },
    {
        type: 'multi',
        name: 'Site Name',
        category: 'Site Name',
        column: AlertTableColumn.SiteName,
        component: () => AlertSiteNameFilterUI,
        describeValue: (value: AlertSiteNameFilter) => value.name,
    },
    {
        type: 'single',
        name: 'Raise Time',
        category: 'Raise Time',
        column: AlertTableColumn.RaiseDate,
        component: () => AlertDateFilterUI,
        describeValue: (value: AlertRaiseTimeFilter) => formatDateFilter(value),
    },
    {
        type: 'single',
        name: 'Cleared Time',
        category: 'Cleared Time',
        column: AlertTableColumn.ClearDate,
        component: () => AlertDateFilterUI,
        describeValue: (value: AlertClearTimeFilter) => formatDateFilter(value),
    },
    {
        type: 'single',
        name: 'Is Active',
        category: 'Is Active',
        column: AlertTableColumn.IsActive,
        component: () => AlertIsActiveFilterUI,
        describeValue: (value: IsAlertActive) => value,
    },
    {
        type: 'multi',
        name: 'Category',
        category: 'Category',
        column: AlertTableColumn.Category,
        component: () => AlertCategoryFilterUI,
        describeValue: (value: AlertCategoryFilter) => value.value,
    },
    {
        type: 'multi',
        name: 'Originator',
        category: 'Originator',
        column: AlertTableColumn.Originator,
        component: () => AlertOriginatorFilterUI,
        describeValue: (value: AlertOriginatorFilter) => value.value,
    },
];
