import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import classNames from 'classnames';
import { SimpleLink } from 'components';

import { BlocId } from '../common';
import { TableBody } from './TableBody';
import { TableHeader } from './TableHeader';
import { BlocHealthTable_test$data, BlocHealthTable_test$key } from './__generated__/BlocHealthTable_test.graphql';
import style from './style.module.css';

export type BlocHealthResults = BlocHealthTable_test$data['blocs']['results'];

interface BlocHealthTableProps {
    selectedBlocs: BlocId[];
    toggleBloc: (selectedBloc: BlocId) => void;
    resetToggledBlocs: () => void;
    test: BlocHealthTable_test$key | null;
}

export const BlocHealthTable: FC<BlocHealthTableProps> = ({ selectedBlocs, toggleBloc, resetToggledBlocs, test }) => {
    const result = useFragment(
        graphql`
            fragment BlocHealthTable_test on DeviceBatteryTestResults {
                blocs {
                    total
                    results {
                        bloc
                        string
                        stateOfHealth
                        state
                    }
                }
            }
        `,
        test
    );

    if (!result || result.blocs.total === 0) {
        return <></>;
    }

    const blocCount = result?.blocs.total as number;
    const blocData = result?.blocs.results as BlocHealthResults;

    return (
        <div data-testid='bloc-table'>
            <div className='flex flex-row justify-between mb-2'>
                <div className={style.table_title}>Battery bloc health</div>
                {selectedBlocs.length !== 0 && <SimpleLink onClick={resetToggledBlocs}>Reset All</SimpleLink>}
            </div>
            <table className={classNames('text-customEggplant', style.table)}>
                <thead>
                    <TableHeader columns={blocCount} />
                </thead>
                <tbody>
                    <TableBody selectedBlocs={selectedBlocs} toggleBloc={toggleBloc} blocData={blocData} />
                </tbody>
            </table>
        </div>
    );
};
