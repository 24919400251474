/**
 * @generated SignedSource<<b58a0737decfba8364936d9785a4178e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BatteriesContentQuery$variables = {
  begin: string;
  end: string;
  id: string;
};
export type BatteriesContentQuery$data = {
  readonly device: {
    readonly dualPlaneCompanion: {
      readonly device: {
        readonly id: string;
        readonly name: string;
        readonly type: {
          readonly displayName: string;
        };
        readonly " $fragmentSpreads": FragmentRefs<"DeviceBatteries_device">;
      } | null;
    } | null;
    readonly id: string;
    readonly name: string;
    readonly type: {
      readonly displayName: string;
    };
    readonly " $fragmentSpreads": FragmentRefs<"DeviceBatteries_device">;
  } | null;
};
export type BatteriesContentQuery = {
  response: BatteriesContentQuery$data;
  variables: BatteriesContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "begin"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "DeviceType",
  "kind": "LinkedField",
  "name": "type",
  "plural": false,
  "selections": [
    (v6/*: any*/)
  ],
  "storageKey": null
},
v8 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "DeviceBatteries_device"
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "DeviceType",
  "kind": "LinkedField",
  "name": "type",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceTypeFeatures",
      "kind": "LinkedField",
      "name": "features",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DeviceTypeBatteryFeatures",
          "kind": "LinkedField",
          "name": "battery",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "testing",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "monitorOnly",
  "storageKey": null
},
v11 = [
  {
    "kind": "Literal",
    "name": "unit",
    "value": "Hours"
  }
],
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "DeviceBattery",
  "kind": "LinkedField",
  "name": "battery",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceBatteryMetrics",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "begin",
              "variableName": "begin"
            },
            {
              "kind": "Variable",
              "name": "end",
              "variableName": "end"
            }
          ],
          "concreteType": "BatteryStatusTimeBreakdown",
          "kind": "LinkedField",
          "name": "statusBreakdown",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": (v11/*: any*/),
              "kind": "ScalarField",
              "name": "time",
              "storageKey": "time(unit:\"Hours\")"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latestStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latestVoltage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latestCurrent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latestTemperature",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceBatteryStrings",
      "kind": "LinkedField",
      "name": "strings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DeviceBatteryString",
          "kind": "LinkedField",
          "name": "strings",
          "plural": true,
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "localId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "installDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "age",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cycles",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "BatteryType",
              "kind": "LinkedField",
              "name": "type",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "manufacturer",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "model",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BatteryCellDefinition",
                  "kind": "LinkedField",
                  "name": "cells",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "capacity",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v4/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "averageAge",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v11/*: any*/),
      "kind": "ScalarField",
      "name": "estimatedReserveTime",
      "storageKey": "estimatedReserveTime(unit:\"Hours\")"
    },
    {
      "alias": null,
      "args": (v11/*: any*/),
      "kind": "ScalarField",
      "name": "reserveTime",
      "storageKey": "reserveTime(unit:\"Hours\")"
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BatteriesContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DualPlaneCompanion",
            "kind": "LinkedField",
            "name": "dualPlaneCompanion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "device",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "BatteriesContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DualPlaneCompanion",
            "kind": "LinkedField",
            "name": "dualPlaneCompanion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "device",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "449cfdc3d5528e6f0a40111d552dd426",
    "id": null,
    "metadata": {},
    "name": "BatteriesContentQuery",
    "operationKind": "query",
    "text": "query BatteriesContentQuery(\n  $id: ID!\n  $begin: Timestamp!\n  $end: Timestamp!\n) {\n  device(id: $id) {\n    id\n    name\n    type {\n      displayName\n      id\n    }\n    ...DeviceBatteries_device\n    dualPlaneCompanion {\n      device {\n        id\n        name\n        type {\n          displayName\n          id\n        }\n        ...DeviceBatteries_device\n      }\n    }\n  }\n}\n\nfragment DeviceBatteries_device on Device {\n  monitorOnly\n  type {\n    features {\n      battery {\n        testing\n      }\n    }\n    id\n  }\n  battery {\n    metrics {\n      statusBreakdown(begin: $begin, end: $end) {\n        status\n        time(unit: Hours)\n      }\n      latestStatus\n      latestVoltage\n      latestCurrent\n      latestTemperature\n    }\n    strings {\n      count\n      strings {\n        id\n        ...StringRow_data\n      }\n    }\n    averageAge\n    estimatedReserveTime(unit: Hours)\n    reserveTime(unit: Hours)\n  }\n}\n\nfragment StringRow_data on DeviceBatteryString {\n  id\n  localId\n  installDate\n  age\n  cycles\n  type {\n    manufacturer\n    model\n    cells {\n      capacity\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "432c27c6076ca9b790b55929265cda31";

export default node;
