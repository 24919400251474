/**
 * @generated SignedSource<<4677ff8d2b8b2070892cfda172e72428>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BatteryTestScheduleErrorType = "DeviceBatteryMissing" | "InvalidCapacityBattery" | "InvalidCapacityBatteryInsufficient" | "InvalidCapacityEodv" | "InvalidCapacityMissingBattery" | "InvalidCustomDischarged" | "InvalidCustomDuration" | "InvalidCustomMinVoltage" | "InvalidCustomMissingRequired" | "InvalidDevice" | "InvalidName" | "InvalidQuickNoDefinedReserve" | "InvalidQuickReserveTime" | "InvalidQuickThreshold" | "InvalidScheduleTime" | "MissingDevices" | "Noop" | "NotPermitted" | "%future added value";
export type BatteryTestType = "Capacity" | "Custom" | "Quick" | "%future added value";
export type TaskRepeat = "BiMonthly" | "Monthly" | "Never" | "Quarterly" | "%future added value";
export type BatteryTestQuickTypeInput = {
  reserveTime: number;
  threshold?: number | null;
};
export type BatteryTestCapacityTypeInput = {
  targets: ReadonlyArray<BatteryTestCapacityEODVTypePair>;
};
export type BatteryTestCapacityEODVTypePair = {
  batteryType: string;
  eodv: number;
};
export type BatteryTestCustomTypeInput = {
  maxDischarged?: number | null;
  maxDuration?: number | null;
  minVoltage?: number | null;
};
export type BatteryTestScheduleInput = {
  repeat: TaskRepeat;
  time?: string | null;
};
export type mutations_AddBatteryTestTaskMutation$variables = {
  capacitySettings?: BatteryTestCapacityTypeInput | null;
  customSettings?: BatteryTestCustomTypeInput | null;
  devices: ReadonlyArray<string>;
  name: string;
  quickSettings?: BatteryTestQuickTypeInput | null;
  schedule?: BatteryTestScheduleInput | null;
  smartStart?: boolean | null;
  type: BatteryTestType;
};
export type mutations_AddBatteryTestTaskMutation$data = {
  readonly addBatteryTest: {
    readonly id?: string;
    readonly problems?: ReadonlyArray<BatteryTestScheduleErrorType>;
  } | null;
};
export type mutations_AddBatteryTestTaskMutation = {
  response: mutations_AddBatteryTestTaskMutation$data;
  variables: mutations_AddBatteryTestTaskMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "capacitySettings"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "customSettings"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "devices"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "quickSettings"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "schedule"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "smartStart"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v8 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "capacitySettings",
        "variableName": "capacitySettings"
      },
      {
        "kind": "Variable",
        "name": "customSettings",
        "variableName": "customSettings"
      },
      {
        "kind": "Variable",
        "name": "devices",
        "variableName": "devices"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "quickSettings",
        "variableName": "quickSettings"
      },
      {
        "kind": "Variable",
        "name": "schedule",
        "variableName": "schedule"
      },
      {
        "kind": "Variable",
        "name": "smartStart",
        "variableName": "smartStart"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "kind": "ObjectValue",
    "name": "settings"
  }
],
v9 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "BatteryTest",
  "abstractKey": null
},
v10 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "problems",
      "storageKey": null
    }
  ],
  "type": "BatteryTestScheduleProblemResponse",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "mutations_AddBatteryTestTaskMutation",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "addBatteryTest",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "mutations_AddBatteryTestTaskMutation",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "addBatteryTest",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v9/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "48ab253a0bc25ac5d55b33bde692a090",
    "id": null,
    "metadata": {},
    "name": "mutations_AddBatteryTestTaskMutation",
    "operationKind": "mutation",
    "text": "mutation mutations_AddBatteryTestTaskMutation(\n  $name: String!\n  $devices: [ID!]!\n  $smartStart: Boolean\n  $type: BatteryTestType!\n  $quickSettings: BatteryTestQuickTypeInput\n  $capacitySettings: BatteryTestCapacityTypeInput\n  $customSettings: BatteryTestCustomTypeInput\n  $schedule: BatteryTestScheduleInput\n) {\n  addBatteryTest(settings: {name: $name, devices: $devices, smartStart: $smartStart, type: $type, quickSettings: $quickSettings, customSettings: $customSettings, capacitySettings: $capacitySettings, schedule: $schedule}) {\n    __typename\n    ... on BatteryTest {\n      id\n    }\n    ... on BatteryTestScheduleProblemResponse {\n      problems\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fae7d233cb4b9e37786b61fbd4bf2ae0";

export default node;
