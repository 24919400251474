import React, { FC } from 'react';

import { BatteryTestCard } from 'views/tasks/battery-health/overview/components/BatteryTestCard';

import { BatteryTestResultCard } from '../../../../../tasks/battery-health/overview/components/BatteryTestResultCard';
import { queries_batteryTestCardsQuery$data } from '../../../__generated__/queries_batteryTestCardsQuery.graphql';
import style from './style.module.css';

interface DeviceBatteryTestsComponentProps {
    props: queries_batteryTestCardsQuery$data;
    device: string;
}

export const DeviceBatteryTestsCardsComponent: FC<DeviceBatteryTestsComponentProps> = ({ props, device }) => {
    return (
        <div className={style.grid_style}>
            {props.device?.tests.data.map(batteryTask => {
                const task = batteryTask.task;
                if (!task) {
                    return <BatteryTestResultCard key={batteryTask.id} test={batteryTask} />;
                }
                return <BatteryTestCard key={batteryTask.id} task={batteryTask.task} compact forceStatusDisplay />;
            })}
        </div>
    );
};
