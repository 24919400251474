import graphql from 'babel-plugin-relay/macro';

export const submitExportMetricsJob = graphql`
    mutation mutations_SubmitExportMetricsJobMutation(
        $deviceIds: [String!]
        $metrics: [String!]!
        $range: DateRangeFilter
        $singleFile: Boolean!
    ) {
        exportMetrics(deviceIds: $deviceIds, metrics: $metrics, range: $range, singleFile: $singleFile) {
            ... on ExportJob {
                id
            }
            ... on ExportJobProblemResponse {
                problems
            }
        }
    }
`;
