import React, { Dispatch, FC } from 'react';

import { FormSection, TextBox } from '@accesstel/pcm-ui';

import { Action, ActionType, EditState } from '../reducer';

export interface GeneralSectionProps {
    state: EditState;
    dispatch: Dispatch<Action>;
}

export const GeneralSection: FC<GeneralSectionProps> = ({ state, dispatch }) => {
    return (
        <FormSection label='General'>
            <TextBox
                required
                placeHolder='Name*'
                light
                value={state.name}
                onChange={event => dispatch({ type: ActionType.UpdateName, value: event.target.value })}
                errorMessage={state.nameError}
                isError={!!state.nameError}
                autoFocus
            />
            <TextBox
                required
                placeHolder='Email*'
                light
                value={state.email}
                onChange={event => dispatch({ type: ActionType.UpdateEmail, value: event.target.value })}
                errorMessage={state.emailError}
                isError={!!state.emailError}
            />
        </FormSection>
    );
};
