import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { ChartFrame, Doughnut, DoughnutDataType, LoadableContentArea } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { batteryStatusToString } from 'lib/conversion/battery-status';

import { BatteryStatus, CurrentStatusDoughnut$key } from './__generated__/CurrentStatusDoughnut.graphql';
import style from './common.module.css';

export interface BatteryReportStatus {
    status: BatteryStatus | 'Other';
    deviceCount: number;
}

export interface CurrentStatusDoughnutProps {
    data: CurrentStatusDoughnut$key | null;
    error?: boolean;
    retry: () => void;
}

const DisplayStates: BatteryStatus[] = ['FloatCharging', 'BoostCharging', 'Discharging'];
const OtherStates: BatteryStatus[] = ['Idle', 'Unknown', '%future added value'];

export const CurrentStatusDoughnut: FC<CurrentStatusDoughnutProps> = ({ data, error, retry }) => {
    const result = useFragment<CurrentStatusDoughnut$key>(
        graphql`
            fragment CurrentStatusDoughnut on Query {
                batteryMetrics {
                    statusBreakdown {
                        status
                        deviceCount
                    }
                }
            }
        `,
        data
    );

    let statusBreakdown: BatteryReportStatus[] | null;
    if (result?.batteryMetrics.statusBreakdown) {
        statusBreakdown = [];

        for (const state of DisplayStates) {
            const item = result.batteryMetrics.statusBreakdown.find(item => item.status === state);
            statusBreakdown.push({
                status: state,
                deviceCount: item?.deviceCount ?? 0,
            });
        }

        let otherTotal = 0;
        for (const state of OtherStates) {
            const item = result.batteryMetrics.statusBreakdown.find(item => item.status === state);
            if (item) {
                otherTotal += item.deviceCount;
            }
        }

        if (otherTotal > 0) {
            statusBreakdown.push({
                status: 'Other',
                deviceCount: otherTotal,
            });
        }
    } else {
        statusBreakdown = null;
    }

    return (
        <ChartFrame title='Current battery status'>
            <LoadableContentArea
                data={statusBreakdown}
                error={error}
                onRetry={retry}
                className={style.doughnut}
                render={result => {
                    const data: DoughnutDataType[] = result.map(section => ({
                        name: batteryStatusToString(section.status),
                        value: section.deviceCount,
                    }));

                    return (
                        <div className={style.doughnut}>
                            <Doughnut data={data} valueFormatter={value => `${value} devices`} />
                        </div>
                    );
                }}
            />
        </ChartFrame>
    );
};
