import React, { FC, useCallback } from 'react';
import { fetchQuery, useFragment, useMutation, useRelayEnvironment } from 'react-relay';

import { Button, FormSection, FormikDurationPicker, FormikTextBox, useToast } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { Form, Formik, FormikHelpers } from 'formik';
import { numberToLocaleString } from 'lib/numberFormatters';
import { Duration } from 'luxon';

import { InsightsFormValues } from '../schema';
import { InsightsValidationSchema } from '../validation';
import { InsightsFragment$data, InsightsFragment$key } from './__generated__/InsightsFragment.graphql';
import { GlobalInsightSettingsIn, InsightsUpdateMutation } from './__generated__/InsightsUpdateMutation.graphql';

export interface InsightsProps {
    settings: InsightsFragment$key;
}

export const Insights: FC<InsightsProps> = ({ settings }) => {
    const { show } = useToast();

    const environment = useRelayEnvironment();
    const data = useFragment(InsightsFragment, settings);
    const [saveInsights] = useMutation<InsightsUpdateMutation>(UpdateMutation);

    const handleSubmit = useCallback(
        (values: InsightsFormValues, { setSubmitting, setFieldError }: FormikHelpers<InsightsFormValues>) => {
            const initialValues = toInitialValues(data);
            const submitData = toSubmitData(values, initialValues);

            if (!submitData) {
                return;
            }

            setSubmitting(true);

            saveInsights({
                variables: {
                    input: submitData,
                },

                onCompleted: data => {
                    if (data.updateGlobalInsightSettings.success) {
                        show({
                            text: 'Saved changes',
                            variant: 'info',
                        });

                        // Reload the form
                        fetchQuery(environment, ReloadQuery, {}).subscribe({
                            next() {
                                setSubmitting(false);
                            },
                            error(error: unknown) {
                                setSubmitting(false);
                                captureException(error, scope => {
                                    scope.setTag('Function', 'Insight settings reload');
                                    return scope;
                                });
                            },
                        });
                    } else {
                        setSubmitting(false);
                        show({
                            text: 'Unable to save changes',
                            variant: 'error',
                        });

                        for (const problem of data.updateGlobalInsightSettings.problems) {
                            switch (problem) {
                                case 'AbnormalFloatCurrentLimitPercentInvalid':
                                    setFieldError('abnormalFloatCurrentLimitPercent', 'Must be between 0 and 100%');
                                    break;
                                case 'RectifierCapacityHeadroomPercentInvalid':
                                    setFieldError('rectifierCapacityHeadroomPercent', 'Must be between 0 and 100%');
                                    break;
                                case 'SyncIntervalInvalid':
                                    setFieldError('syncInterval', 'Must be a valid time');
                                    break;
                            }
                        }
                    }
                },

                onError: error => {
                    setSubmitting(false);
                    show({
                        text: 'Unable to save changes',
                        variant: 'error',
                    });

                    captureException(error, scope => {
                        scope.setTag('Function', 'Insight settings submit');
                        return scope;
                    });
                },
            });
        },
        [data, environment, saveInsights, show]
    );

    return (
        <FormSection
            label='Insights'
            blurb='Changes thresholds and other settings relating to insights. NOTE: Does not take effect until connectors are restarted.'
        >
            <Formik
                initialValues={toInitialValues(data)}
                validationSchema={InsightsValidationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isSubmitting, isValid, dirty }) => (
                    <Form>
                        <div className='space-y-4 grid grid-cols-2 py-6 gap-x-6 pr-6'>
                            <div className='col-start-1'>
                                <FormikTextBox
                                    name='rectifierCapacityHeadroomPercent'
                                    placeHolder='Rectifier Capacity Headroom (%)'
                                    light
                                    variant='outlined'
                                />
                            </div>
                            <div className='col-start-1'>
                                <FormikTextBox
                                    name='abnormalFloatCurrentLimitPercent'
                                    placeHolder='Abnormal Float Current Limit (%)'
                                    light
                                    variant='outlined'
                                />
                            </div>
                        </div>
                        <div className='space-y-4 grid grid-cols-2 pt-6 gap-x-6 pr-6'>
                            <div className='col-start-1'>
                                <FormikDurationPicker
                                    name='syncInterval'
                                    placeHolder='Sync Interval'
                                    light
                                    variant='outlined'
                                    units={['hours', 'minutes', 'seconds']}
                                    minimum={Duration.fromObject({})}
                                    maximum={Duration.fromObject({ days: 1 })}
                                />
                            </div>
                        </div>
                        <div className='flex flex-row gap-4 justify-end'>
                            <Button
                                type='reset'
                                variant='white'
                                buttonText='Reset'
                                disabled={isSubmitting || !dirty}
                                size='small'
                            />
                            <Button
                                type='submit'
                                variant='primary'
                                buttonText='Save'
                                disabled={isSubmitting || !isValid || !dirty}
                                processing={isSubmitting}
                                size='small'
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </FormSection>
    );
};

export const InsightsFragment = graphql`
    fragment InsightsFragment on Settings {
        insights {
            rectifierCapacityHeadroomPercent
            abnormalFloatCurrentLimitPercent
            syncInterval
        }
    }
`;

const UpdateMutation = graphql`
    mutation InsightsUpdateMutation($input: GlobalInsightSettingsIn!) {
        updateGlobalInsightSettings(input: $input) {
            success
            problems
        }
    }
`;

const ReloadQuery = graphql`
    query InsightsReloadQuery {
        settings {
            ...InsightsFragment
        }
    }
`;

function toInitialValues(data: InsightsFragment$data): InsightsFormValues {
    const insights = data.insights;

    return {
        rectifierCapacityHeadroomPercent: numberToLocaleString(insights.rectifierCapacityHeadroomPercent),
        abnormalFloatCurrentLimitPercent: numberToLocaleString(insights.abnormalFloatCurrentLimitPercent),
        syncInterval: Duration.fromISO(insights.syncInterval),
    };
}

function toSubmitData(values: InsightsFormValues, initialValues: InsightsFormValues): GlobalInsightSettingsIn | null {
    const submitData: GlobalInsightSettingsIn = {};
    let changed = false;
    if (values.abnormalFloatCurrentLimitPercent !== initialValues.abnormalFloatCurrentLimitPercent) {
        submitData.abnormalFloatCurrentLimitPercent = Number(values.abnormalFloatCurrentLimitPercent);
        changed = true;
    }

    if (values.rectifierCapacityHeadroomPercent !== initialValues.rectifierCapacityHeadroomPercent) {
        submitData.rectifierCapacityHeadroomPercent = Number(values.rectifierCapacityHeadroomPercent);
        changed = true;
    }

    if (!values.syncInterval.equals(initialValues.syncInterval)) {
        submitData.syncInterval = values.syncInterval.toISO();
        changed = true;
    }

    if (!changed) {
        return null;
    }

    return submitData;
}
