import React, { FC, useState } from 'react';
import { useFragment } from 'react-relay';

import {
    BarDataType,
    ChartFrame,
    ChartMarker,
    Domain,
    Menu,
    MetricViewFrame,
    StackedHorizontalBar,
    Theme,
    ThemedDateLine,
    ThemedLineDataType,
    ThemedLineSeriesTypes,
    useExtendedNavigate,
} from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import humanizeDuration, { Unit } from 'humanize-duration';
import { useUserPermissions } from 'lib/auth';
import { percentageToStringCapped } from 'lib/numberFormatters';
import { Paths } from 'lib/routes';
import { DateTime, Duration } from 'luxon';

import { DeviceACPower_device$data, DeviceACPower_device$key } from './__generated__/DeviceACPower_device.graphql';
import { FeedTile } from './components/FeedTile';

type Event = NonNullable<NonNullable<NonNullable<DeviceACPower_device$data['acPower']>['events']>['data']>[number];

const FeedColours: string[] = [Theme.customEggplant, '#705b73', '#6f3a76', '#6b67a8', '#9493c3', '#3f3d67'];

export interface DeviceACPowerProps {
    device: DeviceACPower_device$key;
    deviceId: string;
    timeRange: Duration;
}

export const DeviceACPower: FC<DeviceACPowerProps> = ({ device, deviceId, timeRange }) => {
    const { hasAssetsWrite } = useUserPermissions();
    const data = useFragment(Fragment, device);
    const navigate = useExtendedNavigate();

    const [hiddenFeeds, setHiddenFeeds] = useState<string[]>([]);

    let durationUnits: Unit[];
    if (timeRange.as('days') >= 1) {
        durationUnits = ['h', 'm'];
    } else {
        durationUnits = ['d', 'h', 'm'];
    }

    const humanizeOpts = { largest: 1, round: true, units: durationUnits };
    const timeRangeString = humanizeDuration(timeRange.as('milliseconds'), humanizeOpts);

    const uptime = data.acPower?.uptime?.percentage ? Math.round(data.acPower.uptime.percentage) : 0;

    const uptimeBarData: BarDataType[] = [];

    if (uptime > 0) {
        uptimeBarData.push({
            label: 'Online',
            value: uptime,
            bgClass: 'bg-customEggplant',
        });
    }

    if (uptime < 100) {
        uptimeBarData.push({
            label: 'Offline',
            value: 100 - uptime,
            bgClass: 'bg-customCoral',
        });
    }

    const chartSeries: ThemedLineSeriesTypes<Date>[] = [];

    const startTime = DateTime.local().minus(timeRange);
    const endTime = DateTime.local();

    const chartXDomain: Domain<Date> = [startTime.startOf('hour').toJSDate(), endTime.endOf('hour').toJSDate()];
    let chartYDomain: Domain;
    if (!data.acPower?.feeds?.data?.length) {
        chartYDomain = [220, 260];
    } else {
        chartYDomain = ['dataMin-5', 'dataMax+5'];
    }

    const feeds = data.acPower?.feeds?.data ?? [];

    feeds.forEach((feed, index) => {
        let feedData: ThemedLineDataType<Date>[];

        if (feed.metrics?.voltage?.values && !hiddenFeeds.includes(feed.label)) {
            feedData = feed.metrics.voltage.values.map<ThemedLineDataType<Date>>(value => ({
                key: new Date(value.timestamp),
                value: value.value,
            }));
        } else {
            feedData = [];
        }

        if (feedData) {
            chartSeries.push({
                id: feed.label,
                data: feedData,
                lineColor: FeedColours[index % FeedColours.length],
                name: `Feed ${feed.label}`,
            });
        }
    });

    const markers = createMarkersFromEvents(data.acPower?.events?.data ?? []);

    return (
        <div className='space-y-4'>
            <div className='flex flex-row gap-4'>
                <div className='font-CynthoNext-SemiBold text-xl'>AC Power</div>
                <div className='flex flex-col justify-start items-end flex-grow font-CynthoNext-SemiBold text-xl'>
                    <div>{percentageToStringCapped(uptime)}</div>
                    <div className='text-xs font-CynthoNext-Regular'>Uptime last {timeRangeString}</div>
                </div>
                <div>
                    <Menu
                        id={`device-menu-${deviceId}`}
                        menuItems={[
                            {
                                name: hasAssetsWrite ? 'Edit device' : 'View device',
                                onClick: () => navigate({ pathname: Paths.EditDevice, params: { id: deviceId } }),
                            },
                        ]}
                        variant='small'
                    />
                </div>
            </div>
            <StackedHorizontalBar
                data={uptimeBarData}
                valueFormatter={value => {
                    if (value == null) {
                        return '-';
                    }
                    return humanizeDuration(timeRange.as('milliseconds') * (value / 100), humanizeOpts);
                }}
            />
            <MetricViewFrame title='Feeds' view='grid'>
                {feeds.map(feed => (
                    <FeedTile
                        key={feed.label}
                        feed={feed}
                        hidden={hiddenFeeds.includes(feed.label)}
                        onToggleHidden={() =>
                            setHiddenFeeds(hiddenFeeds => {
                                if (hiddenFeeds.includes(feed.label)) {
                                    return hiddenFeeds.filter(id => id !== feed.label);
                                } else {
                                    return [...hiddenFeeds, feed.label];
                                }
                            })
                        }
                        deviceId={deviceId}
                    />
                ))}
                {feeds.length === 0 && (
                    <>
                        {/* This keeps the size consistent with displays with feeds */}
                        <div className='invisible' style={{ aspectRatio: '1/1' }}></div>
                        <div className='col-span-4 grid place-items-center'>
                            <div className=''>
                                <span>Feed information is unavailable</span>
                            </div>
                        </div>
                        <div className='invisible' style={{ aspectRatio: '1/1' }}></div>
                    </>
                )}
            </MetricViewFrame>
            <ChartFrame title={`Last ${timeRangeString}`}>
                <ThemedDateLine
                    series={chartSeries}
                    axisUnits
                    unit='V'
                    aspectRatio={2.2}
                    yDomain={chartYDomain}
                    xDomain={chartXDomain}
                    formatTooltipLabel={(label: string | null, data: ThemedLineDataType<Date>) => {
                        const timeToNow = DateTime.fromJSDate(data.key).diffNow().negate();

                        if (timeToNow.as('hours') < 1) {
                            return 'Now';
                        } else {
                            return `${humanizeDuration(timeToNow.as('milliseconds'), { largest: 1, round: true })} ago`;
                        }
                    }}
                    formatTooltipValue={(value: number | null) => `${value?.toFixed(0)}V`}
                    xTicks={8}
                    end
                    formatEndTick={() => 'Now'}
                    markers={markers}
                    legend
                    legendLayout='horizontal'
                />
            </ChartFrame>
        </div>
    );
};

const Fragment = graphql`
    fragment DeviceACPower_device on Device {
        id
        acPower {
            uptime(from: $begin, to: $end) {
                percentage
            }
            feeds {
                data {
                    id
                    label
                    metrics {
                        voltage(begin: $begin, end: $end, interval: "PT15M") {
                            values {
                                timestamp
                                value
                            }
                        }
                    }
                    ...FeedTile_data
                }
            }

            events(
                filters: {
                    startTime: {
                        min: $begin # TODO: Allow unbounded range in API
                        max: $end
                    }
                    endTime: {
                        min: $begin
                        max: $end # TODO: Allow unbounded range in API
                    }
                }
                pageSize: 1000
            ) {
                data {
                    startTime
                    endTime
                    worstStatus
                    duration
                    affectedAllFeeds
                }
            }
        }
    }
`;

function createMarkersFromEvents(events: readonly Event[]): ChartMarker<Date>[] {
    const markers: ChartMarker<Date>[] = [];

    for (const event of events) {
        let label: string;
        let color: string;

        switch (event.worstStatus) {
            case 'Outage':
                if (event.affectedAllFeeds) {
                    label = 'Outage';
                } else {
                    label = 'Partial outage';
                }
                color = Theme.customCoral;
                break;
            case 'UnderVoltage':
                if (event.affectedAllFeeds) {
                    label = 'Under voltage';
                } else {
                    label = 'Partial under voltage';
                }
                color = Theme.customMustard;
                break;
            case 'OverVoltage':
                if (event.affectedAllFeeds) {
                    label = 'Over voltage';
                } else {
                    label = 'Partial over voltage';
                }
                color = Theme.customMustard;
                break;
            default:
                label = 'Unknown';
                color = Theme.customCoral;
                break;
        }

        markers.push({
            type: 'vertical',
            color,
            x: new Date(event.startTime),
            label,
            labelPosition: 'insideBottomLeft',
            showLabelOnHover: true,
        });
        markers.push({
            type: 'area-vertical',
            color,
            x1: new Date(event.startTime),
            x2: new Date(event.endTime),
            opacity: 0.4,
            below: true,
        });
    }

    return markers;
}
