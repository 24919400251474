import React, { FC } from 'react';

import { FilterSingleSelect } from 'components/FilterSingleSelect';
import { CommonFilterProps } from 'filters/common';

import { MonitorOnlyStatus } from '../types';

export type DeviceMonitorOnlyFilterUIProps = CommonFilterProps<MonitorOnlyStatus | null>;

export const DeviceMonitorOnlyFilterUI: FC<DeviceMonitorOnlyFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
}) => {
    const options = [MonitorOnlyStatus.Yes, MonitorOnlyStatus.No];

    return (
        <FilterSingleSelect
            options={options}
            active={current ?? undefined}
            title='Filter by devices in monitor only mode'
            renderItem={item => item}
            onClear={onClearOrBack}
            onSubmit={item => onSubmit(item)}
            onClose={onClose}
            showBack={true}
        />
    );
};
