import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { Button } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { Paths } from 'lib/routes';

import { SectionHeading } from '../components';
import { PowerControllersSection$key } from './__generated__/PowerControllersSection.graphql';
import { PowerControllersHorizontalBar } from './components';

export interface PowerControllersSectionProps {
    data: PowerControllersSection$key | null;
    error?: boolean;
    retry: () => void;
}

export const PowerControllersSection: FC<PowerControllersSectionProps> = ({ data, error, retry }) => {
    const results = useFragment<PowerControllersSection$key>(
        graphql`
            fragment PowerControllersSection on Query {
                ...PowerControllersHorizontalBar
            }
        `,
        data
    );

    return (
        <>
            <SectionHeading title='Sites overview' />
            <div className='pt-8' data-testid='power-controllers-bar'>
                <PowerControllersHorizontalBar data={results} error={error} retry={retry} />
            </div>
            <div className='pt-8'>
                <Button to={Paths.PowerControllers} buttonText='View more' />
            </div>
        </>
    );
};
