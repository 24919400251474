import React, { FC } from 'react';

import { CommonFilterProps, RangeFilter } from 'filters/common';

import { SiteFilterRange } from './SiteFilterRange';

export type SiteBatteryTotalTimeRemainingFilterUIProps = CommonFilterProps<RangeFilter | null>;

export const SiteBatteryTotalTimeRemainingFilterUI: FC<SiteBatteryTotalTimeRemainingFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => (
    <SiteFilterRange
        current={current}
        onClearOrBack={onClearOrBack}
        onClose={onClose}
        onSubmit={onSubmit}
        title='Filter by battery run hours'
        distributionType='BatteryTotalTimeRemainingDistribution'
        countLabel={['Min', 'Max']}
        formatterDisplay={value => String((Number(value) / 60).toPrecision(2))}
        formatterAPI={value => Number((Number(value) * 60).toPrecision(2))}
        showBack={hasPrevious}
    />
);
