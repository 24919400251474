import React, { FC } from 'react';

import { LoadableContentArea } from '@accesstel/pcm-ui';

import classNames from 'classnames';

import { SkeletonText } from './SkeletonText';

export interface SkeletonChartProps {
    className?: string;
    stretch?: boolean;
    aspectRatio?: string;
}

export const SkeletonChart: FC<SkeletonChartProps> = ({ className, stretch, aspectRatio }) => {
    return (
        <div>
            <SkeletonText characters={10} className='pb-4' />
            <div style={{ aspectRatio }}>
                <LoadableContentArea
                    data={null}
                    className={classNames(className, 'h-full', { 'w-full': stretch, 'w-20': !stretch })}
                    variant='white'
                />
            </div>
        </div>
    );
};
