import React, { FC, Suspense } from 'react';
import { PreloadedQuery } from 'react-relay';
import { useLoaderData, useParams } from 'react-router-dom';

import { DefaultTimeRange } from '../../settings';
import { BatteriesContent } from './BatteriesContent';
import { BatteriesSkeleton } from './BatteriesSkeleton';
import { BatteriesContentQuery } from './__generated__/BatteriesContentQuery.graphql';

export const Batteries: FC = () => {
    const queryRef = useLoaderData() as PreloadedQuery<BatteriesContentQuery> | null;
    const { siteId, deviceId } = useParams() as { siteId: string; deviceId: string };

    return (
        <>
            {!queryRef && <BatteriesSkeleton />}
            {queryRef && (
                <Suspense fallback={<BatteriesSkeleton />}>
                    <BatteriesContent
                        key={siteId}
                        queryRef={queryRef}
                        timeRange={DefaultTimeRange}
                        deviceId={deviceId}
                        siteId={siteId}
                    />
                </Suspense>
            )}
        </>
    );
};
