import React, { FC, MouseEvent, useCallback, useState } from 'react';

import { Button, FormikTextBox, Modal } from '@accesstel/pcm-ui';

import { useFormikContext } from 'formik';
import { NonBreakingSpace } from 'lib/textFormatters';

import { ForgotPassword } from '../ForgotPassword';

interface LocalSignInSectionProps {
    errorMessage: string | null;
}

export const LocalSignInSection: FC<LocalSignInSectionProps> = ({ errorMessage }) => {
    const { isSubmitting, status } = useFormikContext();

    const [showForgotPassword, setForgotPassword] = useState(false);

    const doForgotPasswordClick = useCallback((event: MouseEvent) => {
        event.preventDefault();
        setForgotPassword(true);
    }, []);

    const doCloseForgotPassword = useCallback(() => {
        setForgotPassword(false);
    }, []);

    return (
        <>
            <div className='flex flex-col items-stretch text-black'>
                <FormikTextBox
                    id='username'
                    name='username'
                    placeHolder='Username*'
                    maxLength={80}
                    required
                    autoFocus
                />
            </div>
            <div className='flex flex-col items-stretch text-black'>
                <FormikTextBox id='password' name='password' placeHolder='Password*' type='password' required />
                <div className='text-customCoral mt-2'>{errorMessage ?? NonBreakingSpace}</div>
            </div>
            <div className='flex justify-start space-x-6'>
                <button
                    onClick={doForgotPasswordClick}
                    className='text-customCoral'
                    type='button'
                    disabled={isSubmitting}
                >
                    Forgot username/password?
                </button>
            </div>
            <div className='flex justify-between space-x-6'>
                <Button
                    buttonClasses='flex-grow'
                    type='submit'
                    buttonText='Login'
                    variant='primary'
                    size='large'
                    disabled={isSubmitting}
                    processing={isSubmitting && status === 'login'}
                />
            </div>
            <Modal className='w-dialog-lg' open={showForgotPassword} onHide={doCloseForgotPassword}>
                <ForgotPassword onClose={doCloseForgotPassword} />
            </Modal>
        </>
    );
};
