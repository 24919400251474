/**
 * @generated SignedSource<<4b0967720bfb45aaaa59d28572bf788c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BatteryReportsSection$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CurrentCapacityDoughnut" | "CurrentStatusDoughnut">;
  readonly " $fragmentType": "BatteryReportsSection";
};
export type BatteryReportsSection$key = {
  readonly " $data"?: BatteryReportsSection$data;
  readonly " $fragmentSpreads": FragmentRefs<"BatteryReportsSection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BatteryReportsSection",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CurrentStatusDoughnut"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CurrentCapacityDoughnut"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "6208ef0fef5cada0c8a1124ed93eb959";

export default node;
