/**
 * @generated SignedSource<<1348caecb02d8108b632cd06a58be005>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BatteryTaskState = "Aborted" | "Failed" | "InProgress" | "Inconclusive" | "PartialFail" | "PartialPass" | "Passed" | "Scheduled" | "%future added value";
export type BatteryTaskStateFilterable = "Aborted" | "Cancelled" | "Failed" | "InProgress" | "Inconclusive" | "PartialFail" | "PartialPass" | "Passed" | "Scheduled" | "%future added value";
export type BatteryTestType = "Capacity" | "Custom" | "Quick" | "%future added value";
export type SortDirection = "Asc" | "Desc" | "%future added value";
export type TaskRepeat = "BiMonthly" | "Monthly" | "Never" | "Quarterly" | "%future added value";
export type TaskSortField = "Date" | "DeviceCount" | "Name" | "Status" | "Type" | "%future added value";
export type TaskState = "Cancelled" | "Completed" | "InProgress" | "Scheduled" | "%future added value";
export type TaskFilter = {
  abortedTime?: DateRangeFilter | null;
  batteryStringManufacturerSnapshot?: ReadonlyArray<StringFilter> | null;
  batteryStringModelSnapshot?: ReadonlyArray<StringFilter> | null;
  cancelledTime?: DateRangeFilter | null;
  commencedTime?: DateRangeFilter | null;
  completedTime?: DateRangeFilter | null;
  createdTime?: DateRangeFilter | null;
  date?: DateRangeFilter | null;
  deviceCount?: IntRangeFilter | null;
  deviceIds?: ReadonlyArray<string> | null;
  name?: ReadonlyArray<StringFilter> | null;
  runTime?: DurationFilter | null;
  scheduleRepeat?: ReadonlyArray<TaskScheduleRepeatFilter> | null;
  scheduleTime?: DateRangeFilter | null;
  state?: ReadonlyArray<BatteryTaskStateFilter> | null;
  type?: ReadonlyArray<BatteryTestTypeFilter> | null;
  usingSmartStart?: BooleanFilter | null;
};
export type StringFilter = {
  value: string;
};
export type BatteryTestTypeFilter = {
  value: BatteryTestType;
};
export type IntRangeFilter = {
  max: number;
  min: number;
};
export type BatteryTaskStateFilter = {
  value: BatteryTaskStateFilterable;
};
export type DateRangeFilter = {
  max: string;
  min: string;
};
export type TaskScheduleRepeatFilter = {
  value: TaskRepeat;
};
export type BooleanFilter = {
  value: boolean;
};
export type DurationFilter = {
  max: string;
  min: string;
};
export type TaskOrdering = {
  dir?: SortDirection | null;
  field: TaskSortField;
};
export type BatteryHealthTaskListQuery$variables = {
  filters?: TaskFilter | null;
  orderBy?: TaskOrdering | null;
  page?: number | null;
  search?: string | null;
  state?: ReadonlyArray<string> | null;
  withCommencedTime?: boolean | null;
  withSmartStart?: boolean | null;
};
export type BatteryHealthTaskListQuery$data = {
  readonly tasks: {
    readonly data: ReadonlyArray<{
      readonly abortedTime?: string | null;
      readonly cancelledTime?: string | null;
      readonly commencedTime?: string | null;
      readonly completedTime: string | null;
      readonly devices: {
        readonly total: number;
      };
      readonly id: string;
      readonly name: string | null;
      readonly overallState: TaskState;
      readonly schedule?: {
        readonly repeat: TaskRepeat;
        readonly time: string;
      };
      readonly testState?: BatteryTaskState;
      readonly type?: BatteryTestType;
      readonly usingSmartStart?: boolean;
    }>;
    readonly pageInfo: {
      readonly hasNext: boolean | null;
      readonly hasPrevious: boolean | null;
      readonly page: number;
      readonly size: number;
      readonly total: number;
    };
    readonly total: number;
  };
  readonly tasksCount: {
    readonly total: number;
  };
};
export type BatteryHealthTaskListQuery = {
  response: BatteryHealthTaskListQuery$data;
  variables: BatteryHealthTaskListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v2 = {
  "defaultValue": 1,
  "kind": "LocalArgument",
  "name": "page"
},
v3 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "state"
},
v5 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "withCommencedTime"
},
v6 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "withSmartStart"
},
v7 = {
  "kind": "Variable",
  "name": "state",
  "variableName": "state"
},
v8 = {
  "kind": "Literal",
  "name": "type",
  "value": "BatteryTest"
},
v9 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  (v7/*: any*/),
  (v8/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "testState",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "abortedTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cancelledTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskSchedule",
      "kind": "LinkedField",
      "name": "schedule",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "time",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "repeat",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "condition": "withSmartStart",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "usingSmartStart",
          "storageKey": null
        }
      ]
    },
    {
      "condition": "withCommencedTime",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "commencedTime",
          "storageKey": null
        }
      ]
    }
  ],
  "type": "BatteryTest",
  "abstractKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v14 = [
  (v10/*: any*/)
],
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "PaginatedDevices",
  "kind": "LinkedField",
  "name": "devices",
  "plural": false,
  "selections": (v14/*: any*/),
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "overallState",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completedTime",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "page",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "size",
      "storageKey": null
    },
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNext",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPrevious",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v19 = {
  "alias": "tasksCount",
  "args": [
    (v7/*: any*/),
    (v8/*: any*/)
  ],
  "concreteType": "PaginatedTasks",
  "kind": "LinkedField",
  "name": "tasks",
  "plural": false,
  "selections": (v14/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BatteryHealthTaskListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "PaginatedTasks",
        "kind": "LinkedField",
        "name": "tasks",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/)
            ],
            "storageKey": null
          },
          (v18/*: any*/)
        ],
        "storageKey": null
      },
      (v19/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "BatteryHealthTaskListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "PaginatedTasks",
        "kind": "LinkedField",
        "name": "tasks",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/)
            ],
            "storageKey": null
          },
          (v18/*: any*/)
        ],
        "storageKey": null
      },
      (v19/*: any*/)
    ]
  },
  "params": {
    "cacheID": "4e63f19301baa88ceed8e133d1dc36a3",
    "id": null,
    "metadata": {},
    "name": "BatteryHealthTaskListQuery",
    "operationKind": "query",
    "text": "query BatteryHealthTaskListQuery(\n  $page: Int = 1\n  $state: [String!]\n  $filters: TaskFilter\n  $orderBy: TaskOrdering\n  $search: String = \"\"\n  $withSmartStart: Boolean = false\n  $withCommencedTime: Boolean = false\n) {\n  tasks(type: BatteryTest, page: $page, state: $state, filters: $filters, orderBy: $orderBy, search: $search) {\n    total\n    data {\n      __typename\n      id\n      ... on BatteryTest {\n        type\n        testState\n        abortedTime\n        cancelledTime\n        schedule {\n          time\n          repeat\n        }\n        usingSmartStart @include(if: $withSmartStart)\n        commencedTime @include(if: $withCommencedTime)\n      }\n      name\n      devices {\n        total\n      }\n      overallState\n      completedTime\n    }\n    pageInfo {\n      page\n      size\n      total\n      hasNext\n      hasPrevious\n    }\n  }\n  tasksCount: tasks(type: BatteryTest, state: $state) {\n    total\n  }\n}\n"
  }
};
})();

(node as any).hash = "c383521217ec9c0b63b868fd4cbdc7dc";

export default node;
