import { useCallback, useMemo, useState } from 'react';
import { useMutation } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';

import { convertConnectivitySettingsFormStateToApi } from '../../lib/convert';
import {
    useConnectionTestMutation,
    useConnectionTestMutation$data,
} from './__generated__/useConnectionTestMutation.graphql';
import { ConnectivitySettingsFormValues, ConnectivitySettingsValidationSchema } from './schema';

export type ConnectionTestCallback = () => void;

export interface ConnectionTester {
    testConnection: ConnectionTestCallback;
    canCheckConnection: boolean;
    isTestingConnection: boolean;
    isConnectionOk: boolean;
    connectionStatus: string | null;
}

export function useConnectionTest(settings: ConnectivitySettingsFormValues, type: string): ConnectionTester {
    const [isConnectionOk, setConnectionOk] = useState(true);
    const [connectionStatus, setConnectionStatus] = useState<string | null>(null);

    const [checkConnection, isTestingConnection] = useMutation<useConnectionTestMutation>(
        graphql`
            mutation useConnectionTestMutation($deviceType: ID!, $settings: ConnectionSettingsIn!) {
                checkConnectivity(settings: $settings, deviceType: $deviceType) {
                    addresses {
                        address
                        reachable
                        protocols {
                            protocolId
                            readable
                            writable
                        }
                    }
                }
            }
        `
    );

    const testConnection = useCallback(() => {
        setConnectionStatus(null);
        setConnectionOk(true);

        const connectionSettings = convertConnectivitySettingsFormStateToApi(settings);

        checkConnection({
            variables: {
                deviceType: type,
                settings: connectionSettings,
            },
            onCompleted(response) {
                const [isOk, message] = decodeResponse(response);
                setConnectionOk(isOk);
                setConnectionStatus(message);
            },
            onError() {
                setConnectionStatus('An error occurred. Please try again.');
                setConnectionOk(false);
            },
        });
    }, [checkConnection, settings, type]);

    const canCheckConnection = useMemo(() => {
        if (settings.addresses.length === 0) {
            return false;
        }

        if (!ConnectivitySettingsValidationSchema.isValidSync(settings)) {
            return false;
        }

        return true;
    }, [settings]);

    return {
        testConnection,
        canCheckConnection,
        connectionStatus,
        isConnectionOk,
        isTestingConnection,
    };
}

function decodeResponse(response: useConnectionTestMutation$data): [boolean, string] {
    if (!response.checkConnectivity) {
        return [false, 'Unable to check'];
    }

    for (const addressResult of response.checkConnectivity.addresses) {
        if (!addressResult.reachable) {
            return [false, `Unable to reach ${addressResult.address}`];
        }

        for (const protocol of addressResult.protocols) {
            if (!protocol.readable && !protocol.writable) {
                return [false, `Bad credentials for ${addressResult.address}`];
            }

            if (!protocol.readable) {
                return [false, `Bad read credentials for ${addressResult.address}`];
            }

            if (!protocol.writable) {
                return [false, `Bad write credentials for ${addressResult.address}`];
            }
        }
    }

    return [true, 'Connection successful'];
}
