import graphql from 'babel-plugin-relay/macro';
import { set } from 'lodash';
import { IEnvironment, commitMutation } from 'relay-runtime';

import {
    GpsCoordinatesIn,
    SiteIn,
    savingCreateSiteMutation,
    savingCreateSiteMutation$data,
} from './__generated__/savingCreateSiteMutation.graphql';
import { SiteUpdateIn, savingEditSiteMutation } from './__generated__/savingEditSiteMutation.graphql';

export type { SiteIn, SiteUpdateIn, GpsCoordinatesIn };

export async function addNewSite(site: SiteIn, environment: IEnvironment): Promise<string> {
    return new Promise((resolve, reject) => {
        commitMutation<savingCreateSiteMutation>(environment, {
            mutation: CreateSiteMutation,
            variables: { site },
            onCompleted: (response, error) => {
                if (response.addSite?.id) {
                    resolve(response.addSite.id);
                }
                if (response.addSite?.problems) {
                    reject(response.addSite.problems);
                }

                reject(error);
            },
            onError: reject,
        });
    });
}

export async function updateSite(id: string, site: SiteUpdateIn, environment: IEnvironment): Promise<string> {
    return new Promise((resolve, reject) => {
        commitMutation<savingEditSiteMutation>(environment, {
            mutation: EditSiteMutation,
            variables: { id, site },
            onCompleted: (response, error) => {
                if (response.editSite?.id) {
                    resolve(response.editSite.id);
                }
                if (response.editSite?.problems) {
                    reject(response.editSite.problems);
                }

                reject(error);
            },
            onError: reject,
        });
    });
}

type SiteProblemResponse = NonNullable<NonNullable<savingCreateSiteMutation$data['addSite']>['problems']>[number];

export function decodeSiteApiErrors(problems: SiteProblemResponse[]) {
    const errors = {};

    for (const problem of problems) {
        switch (problem) {
            case 'MissingName':
                set(errors, 'name', 'Name is a required field');
                break;
            case 'DuplicateName':
                set(errors, 'name', 'Name already used by another site');
                break;
            case 'InvalidAddress':
                set(errors, 'address', 'Address is not valid');
                break;
            case 'MissingAddress':
                set(errors, 'address', 'Address must be provided');
                break;
            case 'InvalidAttributeName':
                set(errors, 'attributes', 'One of the attributes has an invalid name');
                break;
            case 'InvalidLatitude':
                set(errors, 'latitude', 'Latitude is not valid');
                break;
            case 'InvalidLongitude':
                set(errors, 'longitude', 'Longitude is not valid');
                break;
            case 'InvalidPostcode':
                set(errors, 'postcode', 'Postcode is not valid');
                break;
            case 'InvalidState':
                set(errors, 'state', 'State is not valid');
                break;
            case 'MissingState':
                set(errors, 'state', 'State must be provided');
                break;
            case 'MissingType':
                set(errors, 'type', 'Type must be provided');
                break;
            case 'Noop':
                // Not an issue
                break;
            case 'InvalidSite':
            case 'EditNotAllowed':
            case 'Skipped':
                // Only valid for importing
                break;
        }
    }

    return errors;
}

const CreateSiteMutation = graphql`
    mutation savingCreateSiteMutation($site: SiteIn!) {
        addSite(site: $site) {
            ... on Site {
                id
            }
            ... on SiteProblem {
                problems
            }
        }
    }
`;

const EditSiteMutation = graphql`
    mutation savingEditSiteMutation($id: ID!, $site: SiteUpdateIn!) {
        editSite(id: $id, site: $site) {
            ... on Site {
                id
            }
            ... on SiteProblem {
                problems
            }
        }
    }
`;
