import React, { FC } from 'react';

import { FormSection, FormikDropdown, FormikTextBox } from '@accesstel/pcm-ui';

import { getQualifiedName } from 'lib/namespaces';
import { BatteryTechnologyType, BatteryTechnologyTypeList } from 'lib/provision';
import { formatBatteryType } from 'lib/textFormatters';
import { Attributes } from 'views/manage/common/sub-forms/Attributes';

import manageStyle from '../../../style.module.css';

interface GeneralInformationProps {
    namespace?: string;
}

export const GeneralInformation: FC<GeneralInformationProps> = ({ namespace }) => {
    return (
        <FormSection
            label='General Information'
            blurb='This is some general information that relates to the battery type.'
        >
            <div className={manageStyle.provisioning_content_grid}>
                <FormikTextBox
                    name={getQualifiedName('manufacturer', namespace)}
                    required
                    placeHolder={'Manufacturer*'}
                    light
                    autoFocus
                    variant='outlined'
                    testId='manufacturer'
                />
                <div className='col-start-1'>
                    <FormikTextBox
                        name={getQualifiedName('model', namespace)}
                        required
                        placeHolder={'Model*'}
                        light
                        variant='outlined'
                        testId='model'
                    />
                </div>
                <div className='col-start-1'>
                    <FormikDropdown
                        name={getQualifiedName('technology', namespace)}
                        placeHolder={'Battery Technology*'}
                        light
                        items={BatteryTechnologyTypeList.map(type => type.value)}
                        renderItem={value => formatBatteryType(value as BatteryTechnologyType)}
                        variant='outlined'
                        testId='battery-technology'
                    />
                </div>
                <div className={manageStyle.custom_attributes}>
                    <Attributes namespace={namespace} type='Battery' />
                </div>
            </div>
        </FormSection>
    );
};
