import React, { FC, useCallback, useState } from 'react';

import { FilterContainer } from '@accesstel/pcm-ui';

import { SelectionList } from 'components/MultipleSelect/SelectionList';

import { AnnotationType, AnnotationTypeDisplayText } from '../lib/annotations';

export interface SelectAnotationsPaneProps {
    options: AnnotationType[];
    selected: AnnotationType[];
    onClose: () => void;
    onSubmit: (selection: AnnotationType[]) => void;
}

export const SelectAnotationsPane: FC<SelectAnotationsPaneProps> = ({ onClose, onSubmit, options, selected }) => {
    const sortedOptions = [...options];
    sortedOptions.sort((a, b) => AnnotationTypeDisplayText[a].localeCompare(AnnotationTypeDisplayText[b]));
    const [selection, setSelection] = useState<AnnotationType[]>(selected);

    const handleSelect = useCallback(
        (item: AnnotationType) => {
            const containsSelection = selection.includes(item);
            if (containsSelection) {
                setSelection(selection.filter(selectedItem => selectedItem !== item));
            } else {
                setSelection([...selection, item]);
            }
        },
        [selection]
    );

    const handleConfirmClick = useCallback(() => {
        if (!onSubmit || !selection) {
            return;
        }

        onSubmit(selection);
        onClose();
    }, [onClose, onSubmit, selection]);

    return (
        <FilterContainer
            title='Select annotations'
            onConfirmClick={handleConfirmClick}
            onClose={onClose}
            primaryContent={
                <SelectionList<AnnotationType>
                    items={sortedOptions}
                    selectedItems={selection}
                    renderItemSimple={option => AnnotationTypeDisplayText[option]}
                    renderItem={option => AnnotationTypeDisplayText[option]}
                    onSelect={handleSelect}
                />
            }
            hideClearButton
        />
    );
};
