import React, { FC, useEffect, useState } from 'react';

import { ArrowRightIcon, CalendarIcon, ClockIcon } from '@accesstel/pcm-ui';

import humanizeDuration from 'humanize-duration';
import { getDateTimeFormat } from 'lib/dateFormatter';

import { SmallerLabelWithIcon } from '../SmallerLabelWithIcon';

export interface CardDateDisplayProps {
    startTime: Date;
    endTime?: Date;
    future?: boolean;
}

export const CardDateDisplay: FC<CardDateDisplayProps> = ({ startTime, endTime, future }) => {
    const [cachedEndTime, setCachedEndTime] = useState<Date>(endTime ?? new Date());

    // For in-progress tests, update the end time every second so the duration refreshes
    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (!endTime) {
            interval = setInterval(() => {
                setCachedEndTime(new Date());
            }, 1000);
        } else {
            setCachedEndTime(endTime);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [endTime]);

    const duration = new Date(cachedEndTime).getTime() - new Date(startTime).getTime();

    // Scheduled time display
    if (future) {
        let durationString: string;

        if (duration < 0) {
            durationString =
                'In ' +
                humanizeDuration(duration, {
                    round: true,
                    largest: 1,
                });
        } else {
            durationString = 'Now';
        }

        return (
            <div className='flex gap-2'>
                <SmallerLabelWithIcon icon={<CalendarIcon />} label={getDateTimeFormat(startTime)} />
                <SmallerLabelWithIcon icon={<ClockIcon />} label={durationString} />
            </div>
        );
    }

    // Completed and in-progress time display
    let durationTitle: string;

    if (endTime) {
        durationTitle = getDateTimeFormat(endTime);
    } else {
        durationTitle = 'In progress';
    }

    const durationString = humanizeDuration(duration, {
        round: true,
        largest: 1,
        units: ['h', 'm', 's'],
    });

    return (
        <div className='flex gap-2 '>
            <SmallerLabelWithIcon icon={<CalendarIcon />} label={getDateTimeFormat(startTime)} />
            <SmallerLabelWithIcon icon={<ArrowRightIcon />} label={durationString} title={durationTitle} />
        </div>
    );
};
