import React from 'react';

import { SiteStateFilterUI } from 'filters/site/components/SiteStateFilterUI';
import { IntRangeFilter } from 'lib/__generated__/queries_getAllDeviceIdsQuery.graphql';

import { FilterDefinition, FilterValueMap, RangeFilter } from '../common';
import { formatDateFilter } from '../common/util';
import { SiteNameFilter, SiteNameFilterUI, SiteStateFilter } from '../site';
import {
    DeviceBatteryCapacityRemainingFilterUI,
    DeviceBatteryCapacityTotalFilterUI,
    DeviceBatteryEnergyTotalFilterUI,
    DeviceBatteryReserveTimeFilterUI,
    DeviceBatteryStateOfHealthFilterUI,
    DeviceBatteryStatusFilterUI,
    DeviceBatteryStringFilterUI,
    DeviceBatteryTemperatureFilterUI,
    DeviceMonitorOnlyFilterUI,
    DeviceSNMPVersionFilterUI,
    DeviceStatusFilterUI,
} from './components';
import { DeviceBatteryDateFilterUI } from './components/DeviceBatteryDateFilterUI';
import { DeviceNameFilterUI } from './components/DeviceNameFilterUI';
import { DeviceTypeFilterUI } from './components/DeviceTypeFilterUI';
import {
    DeviceBatteryStatusFilter,
    DeviceExtraFilters,
    DeviceLastTestedDateFilter,
    DeviceNameFilter,
    DeviceSNMPVersionFilter,
    DeviceStatusFilter,
    DeviceTableColumn,
    DeviceTypeFilter,
    MonitorOnlyStatus,
    TestDeviceExtraFilters,
    TestDeviceTableColumn,
} from './types';

export type DeviceColumnFilterMap = {
    [DeviceTableColumn.Name]: DeviceNameFilter[];
    [DeviceTableColumn.Site]: SiteNameFilter[];
    [DeviceTableColumn.State]: SiteStateFilter[];
    [DeviceTableColumn.Type]: DeviceTypeFilter[];
    [DeviceTableColumn.LastTested]: DeviceLastTestedDateFilter | null;
    [DeviceTableColumn.SnmpVersion]: DeviceSNMPVersionFilter[];
    [DeviceTableColumn.DeviceStatus]: DeviceStatusFilter[];
    [DeviceTableColumn.BatteryStatus]: DeviceBatteryStatusFilter[];
    [DeviceTableColumn.MonitorOnly]: MonitorOnlyStatus | null;
    [DeviceTableColumn.BatteryStringCount]: IntRangeFilter | null;
    [DeviceTableColumn.BatteryReserveTime]: RangeFilter | null;
    [DeviceTableColumn.BatteryStateOfHealth]: RangeFilter | null;
    [DeviceTableColumn.BatteryTemperature]: RangeFilter | null;
    [DeviceTableColumn.BatteryCapacityRemaining]: RangeFilter | null;
    [DeviceTableColumn.BatteryEnergyTotal]: RangeFilter | null;
};

export const DefaultValues: FilterValueMap<DeviceColumnFilterMap> = {
    [DeviceTableColumn.Name]: [],
    [DeviceTableColumn.Site]: [],
    [DeviceTableColumn.State]: [],
    [DeviceTableColumn.Type]: [],
    [DeviceTableColumn.LastTested]: null,
    [DeviceTableColumn.SnmpVersion]: [],
    [DeviceTableColumn.DeviceStatus]: [],
    [DeviceTableColumn.BatteryStatus]: [],
    [DeviceTableColumn.MonitorOnly]: null,
    [DeviceTableColumn.BatteryStringCount]: null,
    [DeviceTableColumn.BatteryReserveTime]: null,
    [DeviceTableColumn.BatteryStateOfHealth]: null,
    [DeviceTableColumn.BatteryTemperature]: null,
    [DeviceTableColumn.BatteryCapacityRemaining]: null,
    [DeviceTableColumn.BatteryEnergyTotal]: null,
};

export const DefaultExtraFilters: Record<DeviceExtraFilters, unknown> = {
    [DeviceExtraFilters.BatteryInstallDate]: null,
    [DeviceExtraFilters.BatteryManufactureDate]: null,
    [DeviceExtraFilters.BatteryCapacityTotal]: null,
};

export const StaticDeviceFilterDefinitions: FilterDefinition<DeviceTableColumn>[] = [
    {
        type: 'multi',
        name: 'Name',
        category: 'Name',
        column: DeviceTableColumn.Name,
        component: () => DeviceNameFilterUI,
        describeValue: (value: DeviceNameFilter) => value.name,
    },
    {
        type: 'multi',
        name: 'Type',
        category: 'Type',
        column: DeviceTableColumn.Type,
        component: () => DeviceTypeFilterUI,
        describeValue: (value: DeviceTypeFilter) => value.name,
    },
    {
        type: 'multi',
        name: 'Site Name',
        category: 'Site Name',
        column: DeviceTableColumn.Site,
        component: () => SiteNameFilterUI,
        describeValue: (value: SiteNameFilter) => value.name,
    },
    {
        type: 'multi',
        name: 'Site State',
        category: 'Site State',
        column: DeviceTableColumn.State,
        component: () => SiteStateFilterUI,
        describeValue: (value: SiteStateFilter) => value.state,
    },
    {
        type: 'single',
        name: 'Last Tested',
        category: 'Last Tested',
        column: DeviceTableColumn.LastTested,
        component: () => props => <DeviceBatteryDateFilterUI {...props} title='Filter by Last Tested Battery Test' />,
        describeValue: formatDateFilter,
    },
    {
        type: 'single',
        name: 'Battery String Count',
        category: 'Battery String Count',
        column: DeviceTableColumn.BatteryStringCount,
        component: () => DeviceBatteryStringFilterUI,
        describeValue: (value: RangeFilter): string => `${value.min} - ${value.max}`,
    },
    {
        type: 'single',
        name: 'Battery Reserve Time',
        category: 'Battery Reserve Time',
        column: DeviceTableColumn.BatteryReserveTime,
        component: () => DeviceBatteryReserveTimeFilterUI,
        describeValue: (value: RangeFilter): string => `${value.min} - ${value.max}`,
    },
    {
        type: 'single',
        name: 'Battery State of Health',
        category: 'Battery State of Health',
        column: DeviceTableColumn.BatteryStateOfHealth,
        component: () => DeviceBatteryStateOfHealthFilterUI,
        describeValue: (value: RangeFilter): string => `${value.min} - ${value.max}`,
    },
    {
        type: 'multi',
        name: 'SNMP Version',
        category: 'SNMP Version',
        column: DeviceTableColumn.SnmpVersion,
        component: () => DeviceSNMPVersionFilterUI,
        describeValue: (value: DeviceSNMPVersionFilter) => value.name,
    },
    {
        type: 'multi',
        name: 'Device Status',
        category: 'Device Status',
        column: DeviceTableColumn.DeviceStatus,
        component: () => DeviceStatusFilterUI,
        describeValue: (value: DeviceStatusFilter) => value.name,
    },
    {
        type: 'multi',
        name: 'Battery Status',
        category: 'Battery Status',
        column: DeviceTableColumn.BatteryStatus,
        component: () => DeviceBatteryStatusFilterUI,
        describeValue: (value: DeviceBatteryStatusFilter) => value.name,
    },
    {
        type: 'single',
        name: 'Battery Temperature',
        category: 'Battery Temperature',
        column: DeviceTableColumn.BatteryTemperature,
        component: () => DeviceBatteryTemperatureFilterUI,
        describeValue: (value: RangeFilter): string => `${value.min}°C - ${value.max}°C`,
    },
    {
        type: 'single',
        name: DeviceExtraFilters.BatteryCapacityTotal,
        category: DeviceExtraFilters.BatteryCapacityTotal,
        component: () => DeviceBatteryCapacityTotalFilterUI,
        describeValue: (value: RangeFilter): string => `${value.min} - ${value.max}`,
    },
    {
        type: 'single',
        name: 'Battery Remaining Capacity',
        category: 'Battery Remaining Capacity',
        column: DeviceTableColumn.BatteryCapacityRemaining,
        component: () => DeviceBatteryCapacityRemainingFilterUI,
        describeValue: (value: RangeFilter): string => `${value.min} - ${value.max}`,
    },
    {
        type: 'single',
        name: 'Battery Total Energy',
        category: 'Battery Total Energy',
        column: DeviceTableColumn.BatteryEnergyTotal,
        component: () => DeviceBatteryEnergyTotalFilterUI,
        describeValue: (value: RangeFilter): string => `${value.min} - ${value.max}`,
    },
    {
        type: 'single',
        name: DeviceExtraFilters.BatteryInstallDate,
        category: DeviceExtraFilters.BatteryInstallDate,
        component: () => props => <DeviceBatteryDateFilterUI {...props} title='Filter by Battery Install Date' />,
        describeValue: formatDateFilter,
    },
    {
        type: 'single',
        name: DeviceExtraFilters.BatteryManufactureDate,
        category: DeviceExtraFilters.BatteryManufactureDate,
        component: () => props => <DeviceBatteryDateFilterUI {...props} title='Filter by Battery Manufacture Date' />,
        describeValue: formatDateFilter,
    },
    {
        type: 'single',
        name: 'Monitor Only',
        category: 'Monitor Only',
        column: DeviceTableColumn.MonitorOnly,
        component: () => DeviceMonitorOnlyFilterUI,
        describeValue: (value: MonitorOnlyStatus) => value,
    },
];

export type TestDeviceColumnFilterMap = {
    [TestDeviceTableColumn.Name]: DeviceNameFilter[];
    [TestDeviceTableColumn.Site]: SiteNameFilter[];
    [TestDeviceTableColumn.State]: SiteStateFilter[];
    [TestDeviceTableColumn.LastTested]: DeviceLastTestedDateFilter | null;
};

export const TestDeviceDefaultValues: FilterValueMap<TestDeviceColumnFilterMap> = {
    [TestDeviceTableColumn.Name]: [],
    [TestDeviceTableColumn.Site]: [],
    [TestDeviceTableColumn.State]: [],
    [TestDeviceTableColumn.LastTested]: null,
};

export const TestDeviceDefaultExtraFilters: Record<TestDeviceExtraFilters, unknown> = {
    [TestDeviceExtraFilters.BatteryInstallDate]: null,
    [TestDeviceExtraFilters.BatteryManufactureDate]: null,
    [TestDeviceExtraFilters.BatteryCapacityTotal]: null,
    [TestDeviceExtraFilters.Type]: [],
    [TestDeviceExtraFilters.SnmpVersion]: [],
    [TestDeviceExtraFilters.DeviceStatus]: [],
    [TestDeviceExtraFilters.BatteryStatus]: [],
    [TestDeviceExtraFilters.BatteryStringCount]: null,
    [TestDeviceExtraFilters.BatteryReserveTime]: null,
    [TestDeviceExtraFilters.BatteryStateOfHealth]: null,
    [TestDeviceExtraFilters.BatteryTemperature]: null,
    [TestDeviceExtraFilters.BatteryCapacityRemaining]: null,
    [TestDeviceExtraFilters.BatteryEnergyTotal]: null,
};

export const StaticTestDeviceFilterDefinitions: FilterDefinition<TestDeviceTableColumn>[] = [
    {
        type: 'multi',
        name: 'Name',
        category: 'Name',
        column: TestDeviceTableColumn.Name,
        component: () => DeviceNameFilterUI,
        describeValue: (value: DeviceNameFilter) => value.name,
    },
    {
        type: 'multi',
        name: 'Type',
        category: 'Type',
        component: () => DeviceTypeFilterUI,
        describeValue: (value: DeviceTypeFilter) => value.name,
    },
    {
        type: 'multi',
        name: 'Site Name',
        category: 'Site Name',
        column: TestDeviceTableColumn.Site,
        component: () => SiteNameFilterUI,
        describeValue: (value: SiteNameFilter) => value.name,
    },
    {
        type: 'multi',
        name: 'Site State',
        category: 'Site State',
        column: TestDeviceTableColumn.State,
        component: () => SiteStateFilterUI,
        describeValue: (value: SiteStateFilter) => value.state,
    },
    {
        type: 'single',
        name: 'Last Tested',
        category: 'Last Tested',
        column: TestDeviceTableColumn.LastTested,
        component: () => props => <DeviceBatteryDateFilterUI {...props} title='Filter by Last Tested Battery Test' />,
        describeValue: formatDateFilter,
    },
    {
        type: 'single',
        name: 'Battery String Count',
        category: 'Battery String Count',
        component: () => DeviceBatteryStringFilterUI,
        describeValue: (value: RangeFilter): string => `${value.min} - ${value.max}`,
    },
    {
        type: 'single',
        name: 'Battery Reserve Time',
        category: 'Battery Reserve Time',
        component: () => DeviceBatteryReserveTimeFilterUI,
        describeValue: (value: RangeFilter): string => `${value.min} - ${value.max}`,
    },
    {
        type: 'single',
        name: 'Battery State of Health',
        category: 'Battery State of Health',
        component: () => DeviceBatteryStateOfHealthFilterUI,
        describeValue: (value: RangeFilter): string => `${value.min} - ${value.max}`,
    },
    {
        type: 'multi',
        name: 'SNMP Version',
        category: 'SNMP Version',
        component: () => DeviceSNMPVersionFilterUI,
        describeValue: (value: DeviceSNMPVersionFilter) => value.name,
    },
    {
        type: 'multi',
        name: 'Device Health',
        category: 'Device Health',
        component: () => DeviceStatusFilterUI,
        describeValue: (value: DeviceStatusFilter) => value.name,
    },
    {
        type: 'multi',
        name: 'Battery Status',
        category: 'Battery Status',
        component: () => DeviceBatteryStatusFilterUI,
        describeValue: (value: DeviceBatteryStatusFilter) => value.name,
    },
    {
        type: 'single',
        name: 'Battery Temperature',
        category: 'Battery Temperature',
        component: () => DeviceBatteryTemperatureFilterUI,
        describeValue: (value: RangeFilter): string => `${value.min}°C - ${value.max}°C`,
    },
    {
        type: 'single',
        name: 'Battery Capacity Total',
        category: 'Battery Capacity Total',
        component: () => DeviceBatteryCapacityTotalFilterUI,
        describeValue: (value: RangeFilter): string => `${value.min} - ${value.max}`,
    },
    {
        type: 'single',
        name: 'Battery Remaining Capacity',
        category: 'Battery Remaining Capacity',
        component: () => DeviceBatteryCapacityRemainingFilterUI,
        describeValue: (value: RangeFilter): string => `${value.min} - ${value.max}`,
    },
    {
        type: 'single',
        name: 'Battery Total Energy',
        category: 'Battery Total Energy',
        component: () => DeviceBatteryEnergyTotalFilterUI,
        describeValue: (value: RangeFilter): string => `${value.min} - ${value.max}`,
    },
    {
        type: 'single',
        name: 'Battery Install Date',
        category: 'Battery Install Date',
        component: () => props => <DeviceBatteryDateFilterUI {...props} title='Filter by Battery Install Date' />,
        describeValue: formatDateFilter,
    },
    {
        type: 'single',
        name: 'Battery Manufacturer Date',
        category: 'Battery Manufacturer Date',
        component: () => props => <DeviceBatteryDateFilterUI {...props} title='Filter by Battery Manufacture Date' />,
        describeValue: formatDateFilter,
    },
];
