import React, { FC } from 'react';

import { BatteryTestDeviceNameFilter } from '../types';

export interface BatteryTestDeviceNameFilterResultProps {
    filter: BatteryTestDeviceNameFilter;
}

export const DeviceNameFilterResult: FC<BatteryTestDeviceNameFilterResultProps> = ({ filter }) => {
    if (filter.wildcard) {
        return (
            <div className='flex flex-row items-baseline space-x-2'>
                <span className='text-customEggplantWhite'>Matching</span>
                <span>{filter.name}</span>
            </div>
        );
    } else {
        return <span>{filter.name}</span>;
    }
};
