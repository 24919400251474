import React, { FC } from 'react';

import { BigButton, ThemedPageHeading } from '@accesstel/pcm-ui';

import { Paths } from 'lib/routes';

import { PageHeader, useDocumentTitle } from '../../components';
import style from './style.module.css';

export const Reports: FC = () => {
    useDocumentTitle('Reports');

    return (
        <div className='space-y-6'>
            <PageHeader />
            <ThemedPageHeading value={'Reports'} />
            <div className={style.button_container}>
                <BigButton
                    to={Paths.ReportAlerts}
                    title='Insights'
                    text='Explore a range of insights generated from devices across your network.'
                />
                <BigButton
                    to={Paths.ReportBatteries}
                    title='Batteries'
                    text='Access battery reports for various sites collected by accata.'
                />
            </div>
        </div>
    );
};
