import React from 'react';

import { createColumnHelper } from '@tanstack/react-table';
import { DeviceTestResultTableColumn } from 'filters/device-test-result';
import { ColumnWithId } from 'layouts';
import { formatDurationAsHoursAndMinutes, getDateTimeFormat } from 'lib/dateFormatter';
import { renderTableStatusCell } from 'lib/table-columns';
import { BatteryTestCauseMap, formatBatteryTestName } from 'lib/textFormatters';
import { DateTime } from 'luxon';

import type {
    BatteryTestState,
    queries_batteryTestCardsQuery$data,
} from '../../__generated__/queries_batteryTestCardsQuery.graphql';

type Result = NonNullable<queries_batteryTestCardsQuery$data['device']>['tests']['data'][number];

function renderNameColumn(row: Result) {
    return row.task?.name ?? <div className='italic'>{formatBatteryTestName(row.cause)}</div>;
}
function renderStatusCell(value: BatteryTestState | 'Cancelled') {
    return renderTableStatusCell(value);
}

function renderRunTimeColumn(row: Result) {
    const from = DateTime.fromISO(row.commencedTime as string);
    const to = DateTime.fromISO(row.completedTime as string);

    if (to) {
        return formatDurationAsHoursAndMinutes(from, to);
    } else {
        return formatDurationAsHoursAndMinutes(from, DateTime.now());
    }
}

const columnHelper = createColumnHelper<Result>();
export const NameColumn = columnHelper.display({
    id: DeviceTestResultTableColumn.Name,
    header: 'TEST NAME',
    cell: ({ row }) => renderNameColumn(row.original),
    meta: {
        filterable: true,
        sortable: true,
        maxWidth: '30rem',
    },
});

export const StateColumn = columnHelper.accessor('state', {
    id: DeviceTestResultTableColumn.State,
    header: 'STATE',
    cell: ({ cell }) => renderStatusCell(cell.getValue()),
    meta: {
        filterable: true,
        sortable: true,
    },
});

export const TypeColumn = columnHelper.accessor('task.type', {
    id: DeviceTestResultTableColumn.Type,
    header: 'TYPE',
    cell: ({ cell }) => cell.getValue() ?? 'External',
    meta: {
        filterable: true,
        sortable: true,
    },
});

export const RunTimeColumn = columnHelper.display({
    id: DeviceTestResultTableColumn.RunTime,
    header: 'RUN TIME',
    cell: ({ row }) => renderRunTimeColumn(row.original),
    meta: {
        filterable: true,
        sortable: true,
    },
});

export const StartTimeColumn = columnHelper.accessor('commencedTime', {
    id: DeviceTestResultTableColumn.StartTime,
    header: 'DATE CREATED',
    cell: ({ cell }) => getDateTimeFormat(cell.getValue() as string),
    meta: {
        filterable: true,
        sortable: true,
    },
});

export const DischargeCauseColumn = columnHelper.accessor('cause', {
    id: DeviceTestResultTableColumn.Cause,
    header: 'DISCHARGE CAUSE',
    cell: ({ cell }) => BatteryTestCauseMap[cell.getValue()],
    meta: {
        filterable: true,
    },
});

export const BaseTableColumns = [NameColumn, StateColumn, TypeColumn, RunTimeColumn, StartTimeColumn] as ColumnWithId<
    DeviceTestResultTableColumn,
    Result
>[];

export const AllTableColumns = [...BaseTableColumns, DischargeCauseColumn] as ColumnWithId<
    DeviceTestResultTableColumn,
    Result
>[];
