import React, { Dispatch, FC, useCallback } from 'react';

import { FormControlAction, FormControlParameters } from 'views/manage/device/add-edit/lib/reducer';

import { DeviceBatteryString } from '../../schema';
import { StringEditor } from './StringEditor';

export interface BatteryTypeRepresentation {
    manufacturer?: string;
    model?: string;
    value: string;
}

export interface AddStringProps {
    onClose: () => void;
    onAdd: (batteryString: DeviceBatteryString) => void;

    // form control
    formControlState: FormControlParameters;
    formControlDispatch: Dispatch<FormControlAction>;
}

export const NewStringEditor: FC<AddStringProps> = ({ onClose, onAdd, formControlState, formControlDispatch }) => {
    const handleSubmit = useCallback(
        (batteryString: DeviceBatteryString) => {
            onAdd(batteryString);
            onClose();
        },
        [onAdd, onClose]
    );

    return (
        <StringEditor
            formControlState={formControlState}
            formControlDispatch={formControlDispatch}
            onSubmit={handleSubmit}
            onCancel={onClose}
        />
    );
};
