import React, { FC } from 'react';

import { SkeletonText } from 'components/Skeleton';
import { SkeletonTable } from 'components/Skeleton';

import { DevicePane } from '../../components/DevicePane';

export const InsightsSkeleton: FC = () => {
    return (
        <DevicePane title={<SkeletonText characters={30} />}>
            <div className='font-CynthoNext-SemiBold text-xl'>
                <SkeletonText characters={10} />
            </div>

            <SkeletonTable />
        </DevicePane>
    );
};
