import React, { FC } from 'react';

import { SkeletonText } from 'components/Skeleton';

import { DevicePane } from '../../components/DevicePane';
import { DeviceRectifiersSkeleton } from './DeviceRectifiersSkeleton';

export const RectifiersSkeleton: FC = () => {
    return (
        <div className='grid grid-cols-2 gap-4'>
            <DevicePane title={<SkeletonText characters={10} />} subtitle={<SkeletonText characters={20} />}>
                <DeviceRectifiersSkeleton />
            </DevicePane>
            <DevicePane title={<SkeletonText characters={10} />} subtitle={<SkeletonText characters={20} />}>
                <DeviceRectifiersSkeleton />
            </DevicePane>
        </div>
    );
};
