/**
 * @generated SignedSource<<49c1be2677867b2091809ba1d1c894a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InsightsFragment$data = {
  readonly insights: {
    readonly abnormalFloatCurrentLimitPercent: number;
    readonly rectifierCapacityHeadroomPercent: number;
    readonly syncInterval: string;
  };
  readonly " $fragmentType": "InsightsFragment";
};
export type InsightsFragment$key = {
  readonly " $data"?: InsightsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InsightsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InsightsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "GlobalInsightSettings",
      "kind": "LinkedField",
      "name": "insights",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rectifierCapacityHeadroomPercent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "abnormalFloatCurrentLimitPercent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "syncInterval",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Settings",
  "abstractKey": null
};

(node as any).hash = "aee6fcc394befe68a1eced79db02c607";

export default node;
