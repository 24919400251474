/**
 * @generated SignedSource<<5b76457e9ce376d41b3157924305aa3b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ActiveDevicesCountQuery$variables = {};
export type ActiveDevicesCountQuery$data = {
  readonly devices: {
    readonly total: number;
  };
};
export type ActiveDevicesCountQuery = {
  response: ActiveDevicesCountQuery$data;
  variables: ActiveDevicesCountQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "PaginatedDevices",
    "kind": "LinkedField",
    "name": "devices",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "total",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ActiveDevicesCountQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ActiveDevicesCountQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "d0703e1f8a5c7e8e965171ec2e86d3a5",
    "id": null,
    "metadata": {},
    "name": "ActiveDevicesCountQuery",
    "operationKind": "query",
    "text": "query ActiveDevicesCountQuery {\n  devices {\n    total\n  }\n}\n"
  }
};
})();

(node as any).hash = "5b460470805206dc09249466472fcca3";

export default node;
