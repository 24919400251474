import React, { FC } from 'react';
import { QueryRenderer } from 'react-relay';
import { useRelayEnvironment } from 'react-relay';

import { ThemedPageHeading } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';

import { ActiveDevicesCountQuery } from './__generated__/ActiveDevicesCountQuery.graphql';

interface ActiveDevicesCountProps {
    valueSuffix: string;
}

export const ActiveDevicesCount: FC<ActiveDevicesCountProps> = ({ valueSuffix }) => {
    const environment = useRelayEnvironment();
    return (
        <QueryRenderer<ActiveDevicesCountQuery>
            environment={environment}
            query={graphql`
                query ActiveDevicesCountQuery {
                    devices {
                        total
                    }
                }
            `}
            variables={{}}
            render={({ error, props }) => {
                if (error) {
                    return <ThemedPageHeading value={`Error!`} subheading secondary />;
                }
                if (!props) {
                    return <ThemedPageHeading value={`0 ${valueSuffix}`} subheading secondary />;
                }
                return (
                    <ThemedPageHeading
                        value={`${props.devices.total.toLocaleString(undefined)} ${valueSuffix}`}
                        subheading
                        secondary
                    />
                );
            }}
        />
    );
};
