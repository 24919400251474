import React, { FC, useCallback, useMemo, useState } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';
import { generatePath } from 'react-router-dom';

import { BarDataType, LabelFormatterType } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { FilterActionType } from 'filters/common';
import { DeviceTableColumn, deviceToFilterObject, useDeviceFilter } from 'filters/device';
import { SortDirection, TableActionType, TableLayout, useTableReducer } from 'layouts';
import { useUserPermissions } from 'lib/auth';
import { useQuery } from 'lib/query-helpers';
import { Paths } from 'lib/routes';
import { IEnvironment } from 'relay-runtime';

import { colorPalette } from '../AssetManagement';
import { queries_GetAssetsDistributionQuery } from '../__generated__/queries_GetAssetsDistributionQuery.graphql';
import { getAssetsDistribution } from '../queries';
import { DevicesAllIdsQuery } from './__generated__/DevicesAllIdsQuery.graphql';
import { DevicesSearchQuery } from './__generated__/DevicesSearchQuery.graphql';
import {
    DeviceOrdering,
    DeviceSortField,
    DevicesTableQuery,
    DevicesTableQuery$data,
    DevicesTableQuery$variables,
} from './__generated__/DevicesTableQuery.graphql';
import { DeleteDevicesModal } from './components';
import { AllTableColumns, BaseTableColumns } from './settings';

type Device = DevicesTableQuery$data['devices']['data'][number];
type DeviceSearchResult = DevicesTableQuery$data['devices']['data'][number];

const TableStorageKeyPrefix = 'device-table';

export const ManageDevices: FC = () => {
    const { hasAssetsWrite } = useUserPermissions();
    const environment = useRelayEnvironment();

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [tableState, dispatchTableState] = useTableReducer<DeviceTableColumn>({
        defaultSortColumn: DeviceTableColumn.Name,
        allColumns: AllTableColumns.map(column => column.id),
        defaultVisibleColumns: BaseTableColumns.map(column => column.id),
        storageKeyPrefix: TableStorageKeyPrefix,
    });

    const [filters, dispatchFilters] = useDeviceFilter();
    const filterObject = useMemo(() => deviceToFilterObject(filters), [filters]);

    const sortObject: DeviceOrdering = {
        field: tableState.sortColumn as DeviceSortField,
        dir: tableState.sortDirection === SortDirection.Ascending ? 'Asc' : 'Desc',
    };

    const variables: DevicesTableQuery$variables = {
        page: tableState.page,
        name: tableState.search,
        orderBy: sortObject,
        filter: filterObject,
        withMonitorOnly: tableState.visibleColumnsInOrder.includes(DeviceTableColumn.MonitorOnly),
        withSnmpVersion: tableState.visibleColumnsInOrder.includes(DeviceTableColumn.SnmpVersion),
        withBatteryStatus: tableState.visibleColumnsInOrder.includes(DeviceTableColumn.BatteryStatus),
        withDeviceHealth: tableState.visibleColumnsInOrder.includes(DeviceTableColumn.DeviceStatus),
        withBatteryStringCount: tableState.visibleColumnsInOrder.includes(DeviceTableColumn.BatteryStringCount),

        withBatteryTemperature: tableState.visibleColumnsInOrder.includes(DeviceTableColumn.BatteryTemperature),

        withBatteryCapacityRemaining: tableState.visibleColumnsInOrder.includes(
            DeviceTableColumn.BatteryCapacityRemaining
        ),

        withBatteryEnergyTotal: tableState.visibleColumnsInOrder.includes(DeviceTableColumn.BatteryEnergyTotal),

        withBatteryReserveTime: tableState.visibleColumnsInOrder.includes(DeviceTableColumn.BatteryReserveTime),

        withBatteryStateOfHealth: tableState.visibleColumnsInOrder.includes(DeviceTableColumn.BatteryStateOfHealth),
    };

    const { data: props, error, retry, isFetching } = useQuery<DevicesTableQuery>(
        graphql`
            query DevicesTableQuery(
                $page: Int = 1
                $name: String = ""
                $orderBy: DeviceOrdering
                $filter: DeviceFilter
                $withMonitorOnly: Boolean = false
                $withSnmpVersion: Boolean = false
                $withBatteryStatus: Boolean = false
                $withDeviceHealth: Boolean = false
                $withBatteryStringCount: Boolean = false
                $withBatteryTemperature: Boolean = false
                $withBatteryReserveTime: Boolean = false
                $withBatteryStateOfHealth: Boolean = false
                $withBatteryCapacityRemaining: Boolean = false
                $withBatteryEnergyTotal: Boolean = false
            ) {
                devices(page: $page, search: $name, orderBy: $orderBy, filters: $filter) {
                    total
                    data {
                        id
                        name
                        type {
                            displayName
                        }
                        site {
                            name
                            address {
                                state
                            }
                        }
                        monitorOnly @include(if: $withMonitorOnly)
                        health @include(if: $withDeviceHealth)
                        connectionSettings @include(if: $withSnmpVersion) {
                            protocols {
                                ... on ProtocolSnmp {
                                    version
                                }
                            }
                        }
                        battery {
                            reserveTime @include(if: $withBatteryReserveTime)
                            metrics {
                                latestStatus @include(if: $withBatteryStatus)
                                latestTemperature @include(if: $withBatteryTemperature)
                                latestStateOfHealth @include(if: $withBatteryStateOfHealth)
                                latestRemainingCapacity @include(if: $withBatteryCapacityRemaining)
                                latestTotalEnergy @include(if: $withBatteryEnergyTotal)
                            }
                            strings {
                                count @include(if: $withBatteryStringCount)
                            }
                        }
                    }
                    pageInfo {
                        page
                        size
                        total
                        hasNext
                        hasPrevious
                    }
                }
                overallDevices: devices {
                    total
                }
            }
        `,
        variables,
        {
            fetchPolicy: 'network-only',
        }
    );

    const { data: distributionProps } = useQuery<queries_GetAssetsDistributionQuery>(
        getAssetsDistribution,
        { type: 'DeviceType' },
        { fetchPolicy: 'network-only' }
    );

    const handleSearch = useCallback(
        (input: string) => {
            return fetchQuery<DevicesSearchQuery>(
                environment,
                graphql`
                    query DevicesSearchQuery($name: String = "", $pageSize: Int!) {
                        devices(search: $name, pageSize: $pageSize) {
                            data {
                                id
                                name
                            }
                        }
                    }
                `,
                { name: input, pageSize: 10 }
            )
                .toPromise()
                .then(result => (result?.devices.data as DeviceSearchResult[]) ?? []);
        },
        [environment]
    );

    const distributionData: BarDataType[] =
        distributionProps?.assetDistribution?.distribution.map((group, i) => {
            let bgColor = undefined;
            if (colorPalette.length > i) {
                bgColor = colorPalette[i];
            }

            return {
                id: group.key,
                label: group.displayName ?? group.key,
                value: group.value,
                bgClass: bgColor,
            };
        }) ?? [];

    const labelFormatter: LabelFormatterType<BarDataType> = useCallback((label, data) => {
        if (label === null) {
            return '';
        }
        return `${data.value} ${label.toUpperCase()}`;
    }, []);

    const onSegmentClick = (id: string) => {
        dispatchFilters({
            type: FilterActionType.Apply,
            column: DeviceTableColumn.Type,
            value: [{ name: distributionData.find(d => d.id === id)!.label, id }],
        });
    };

    return (
        <>
            <TableLayout
                title='Device Management'
                columns={AllTableColumns}
                allowEditingColumns
                filterState={filters}
                dispatchFilterState={dispatchFilters}
                tableState={tableState}
                dispatchTableState={dispatchTableState}
                data={(props?.devices.data ?? null) as Device[]}
                getRowId={row => row.id}
                isProcessing={!!props && isFetching}
                page={props?.devices.pageInfo.page}
                pageCount={props?.devices.pageInfo.total}
                overallCount={props?.overallDevices.total}
                resultCount={props?.devices.total}
                hasError={!!error}
                onRetry={retry}
                searchPlaceholder='Search by Device'
                onSearch={handleSearch}
                renderSearchResult={(item: DeviceSearchResult) => item.name}
                renderSearchResultAsString={(item: DeviceSearchResult) => item.name}
                emptyMessage='There are no devices present'
                unit='Device'
                primaryAction={hasAssetsWrite ? 'Add new device' : undefined}
                primaryActionLink={hasAssetsWrite ? Paths.AddDevice : undefined}
                selection={hasAssetsWrite}
                onRequestAllIds={() => getAllDeviceIds(environment, filterObject)}
                getItemLink={getDeviceLink}
                selectionActionMessage='Delete selected %'
                onSelectionAction={() => setShowDeleteModal(true)}
                barChartData={{
                    data: distributionData,
                    emptyLabel: 'No devices added',
                    labelFormatter,
                    onSegmentClick,
                }}
            />
            {showDeleteModal && (
                <DeleteDevicesModal
                    selectedDevices={tableState.selectedItems}
                    onDone={() => dispatchTableState({ type: TableActionType.SetSelection, selection: [] })}
                    setShowDeleteModal={setShowDeleteModal}
                    showDeleteModal={showDeleteModal}
                    tableRetry={retry}
                />
            )}
        </>
    );
};

function getAllDeviceIds(environment: IEnvironment, filters: Record<string, unknown>): Promise<string[]> {
    const getAllDeviceIdsQuery = graphql`
        query DevicesAllIdsQuery($filters: DeviceFilter) {
            devices(onlyProvisioningStatuses: Active, pageSize: 10000, filters: $filters) {
                data {
                    id
                }
            }
        }
    `;

    return fetchQuery<DevicesAllIdsQuery>(environment, getAllDeviceIdsQuery, { filters })
        .toPromise()
        .then(data => data?.devices.data.map(device => device.id) ?? []);
}

function getDeviceLink(device: Device): string {
    return generatePath(Paths.EditDevice, { id: device.id });
}
