import React, { FC } from 'react';

import { MultipleSelect } from 'components/MultipleSelect';

import { CommonFilterProps } from '../../common/types';
import { DeviceBatteryStatusFilter } from '../types';

export type DeviceBatteryStatusFilterUIProps = CommonFilterProps<DeviceBatteryStatusFilter[]>;

export const DeviceBatteryStatusFilterUI: FC<DeviceBatteryStatusFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    const options: DeviceBatteryStatusFilter[] = [
        { id: 'BoostCharging', name: 'Boost Charging' },
        { id: 'Discharging', name: 'Discharging' },
        { id: 'Disconnected', name: 'Disconnected' },
        { id: 'FloatCharging', name: 'Float Charging' },
        { id: 'Idle', name: 'Idle' },
        { id: 'Missing', name: 'Missing' },
        { id: 'Unknown', name: 'Unknown' },
    ];

    return (
        <MultipleSelect
            options={options}
            active={current}
            title='Filter by Battery Status'
            renderItem={item => item.name}
            renderItemSimple={item => item.name}
            areItemsEqual={(itemA, itemB) => itemA.id === itemB.id}
            showBack={hasPrevious}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onClose={onClose}
        />
    );
};
