import React from 'react';

import { RepeatIcon, Tooltip } from '@accesstel/pcm-ui';

import { createColumnHelper } from '@tanstack/react-table';
import { NotInProgTestsTableColumn } from 'filters/task';
import { ColumnWithId } from 'layouts';
import { formatDurationAsHoursAndMinutes, getDateTimeFormat } from 'lib/dateFormatter';
import { EmptyCell, renderTableStatusCell, renderUserConfigCell } from 'lib/table-columns';
import { DateTime } from 'luxon';

import type {
    BatteryHealthTaskListQuery$data,
    BatteryTaskState,
} from './__generated__/BatteryHealthTaskListQuery.graphql';

type Task = BatteryHealthTaskListQuery$data['tasks']['data'][number];

const columnHelper = createColumnHelper<Task>();

function renderStatusCell(value: BatteryTaskState | 'Cancelled') {
    return renderTableStatusCell(value);
}

function renderTestDate(data: Task) {
    if (data.overallState === 'Scheduled') {
        if (data.schedule?.repeat === 'Never') {
            return getDateTimeFormat(data.schedule?.time as string);
        } else {
            return (
                <div className='flex flex-row items-center'>
                    <div className='pr-2'>{getDateTimeFormat(data.schedule?.time as string)}</div>
                    <Tooltip content={`Recurring ${data.schedule?.repeat.toLocaleLowerCase()}`}>
                        <div className='h-4 w-4 text-customCoral cursor-help'>
                            <RepeatIcon />
                        </div>
                    </Tooltip>
                </div>
            );
        }
    } else if (data.overallState === 'Cancelled') {
        return getDateTimeFormat(data.cancelledTime as string);
    } else if (data.testState === 'Aborted') {
        return getDateTimeFormat(data.abortedTime as string);
    } else {
        return getDateTimeFormat(data.completedTime as string);
    }
}

function renderTestRuntime(data: Task) {
    if (data.overallState === 'Scheduled') {
        return <EmptyCell />;
    } else if (data.overallState === 'Cancelled') {
        return <EmptyCell />;
    } else if (data.testState === 'Aborted') {
        return data.commencedTime && data.abortedTime
            ? formatDurationAsHoursAndMinutes(DateTime.fromISO(data.commencedTime), DateTime.fromISO(data.abortedTime))
            : '';
    } else {
        return data.commencedTime && data.completedTime
            ? formatDurationAsHoursAndMinutes(
                  DateTime.fromISO(data.commencedTime),
                  DateTime.fromISO(data.completedTime)
              )
            : '';
    }
}

export const NameColumn = columnHelper.accessor('name', {
    id: NotInProgTestsTableColumn.Name,
    header: 'NAME',
    meta: {
        filterable: true,
        sortable: true,
        maxWidth: '30rem',
    },
});
export const TypeColumn = columnHelper.accessor('type', {
    id: NotInProgTestsTableColumn.Type,
    header: 'TYPE',
    meta: {
        filterable: true,
        sortable: true,
    },
});
export const StatusColumn = columnHelper.accessor(
    data => (data.overallState === 'Cancelled' ? 'Cancelled' : data.testState),
    {
        id: NotInProgTestsTableColumn.Status,
        header: 'STATUS',
        cell: ({ cell }) => renderStatusCell(cell.getValue() ?? 'Cancelled'),
        meta: {
            filterable: true,
            sortable: true,
        },
    }
);
export const DeviceCountColumn = columnHelper.accessor('devices.total', {
    id: NotInProgTestsTableColumn.Devices,
    header: 'DEVICES',
    meta: {
        filterable: true,
        sortable: true,
    },
});
export const DateColumn = columnHelper.display({
    id: NotInProgTestsTableColumn.Date,
    header: 'DATE',
    cell: ({ row }) => renderTestDate(row.original),
    meta: {
        filterable: true,
        sortable: true,
    },
});

export const ScheduleRepeatColumn = columnHelper.display({
    id: NotInProgTestsTableColumn.ScheduleRepeat,
    header: 'REPEAT CADENCE',
    cell: ({ row }) => renderUserConfigCell(() => row.original.schedule?.repeat),
    meta: {
        filterable: true,
    },
});
export const SmartStartColumn = columnHelper.accessor('usingSmartStart', {
    id: NotInProgTestsTableColumn.UsingSmartStart,
    header: 'SMART START',
    cell: ({ cell }) => (cell.getValue() ? 'Yes' : 'No'),
    meta: {
        filterable: true,
    },
});
export const RunTimeColumn = columnHelper.display({
    id: NotInProgTestsTableColumn.RunTime,
    header: 'RUN TIME',
    cell: ({ row }) => renderTestRuntime(row.original),
    meta: {
        filterable: true,
    },
});

export const BaseTableColumns = [NameColumn, TypeColumn, StatusColumn, DeviceCountColumn, DateColumn] as ColumnWithId<
    NotInProgTestsTableColumn,
    Task
>[];

export const AllTableColumns = [
    ...BaseTableColumns,
    ScheduleRepeatColumn,
    SmartStartColumn,
    RunTimeColumn,
] as ColumnWithId<NotInProgTestsTableColumn, Task>[];
