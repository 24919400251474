import React, { FC } from 'react';

import { BigButton, ThemedPageHeading } from '@accesstel/pcm-ui';

import { Paths } from 'lib/routes';

import { PageHeader, useDocumentTitle } from '../../components';
import style from './style.module.css';

// IMPORTANT: Preserve the order of the color palette so that each group can be distinguishable even when place side by side
export const colorPalette = [
    'bg-customEggplant',
    'bg-customCoral',
    'bg-customEggplantCoral',
    'bg-customMustard',
    'bg-black',
    'bg-customPine',
];

export const AssetManagement: FC = () => {
    useDocumentTitle('Asset Management');

    return (
        <div className='space-y-6'>
            <PageHeader />
            <ThemedPageHeading value={'Manage'} />
            <div className={style.button_container}>
                <BigButton
                    to={Paths.Devices}
                    title='Devices'
                    text='Devices are physical assets located on Sites. An example is a power controller with batteries.'
                />
                <BigButton to={Paths.Sites} title='Sites' text='Sites are physical locations which contain Devices.' />
                <BigButton
                    to={Paths.BatteryTypes}
                    title='Battery Types'
                    text='Battery Types are different makes and models of batteries. Batteries are managed by Devices.'
                />
            </div>
        </div>
    );
};
