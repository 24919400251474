import React, { FC, Suspense } from 'react';
import { PreloadedQuery, fetchQuery } from 'react-relay';
import { useLoaderData, useParams } from 'react-router-dom';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { useDocumentTitle } from 'components';
import { getGlobalEnvironment } from 'lib/environment';

import { BatterySingleSiteViewContent } from './SingleSiteContent';
import { SingleSiteSkeleton } from './SingleSiteSkeleton';
import { SingleSiteContent_SiteViewQuery } from './__generated__/SingleSiteContent_SiteViewQuery.graphql';
import { SingleSiteGetSiteNameQuery } from './__generated__/SingleSiteGetSiteNameQuery.graphql';

export const BatterySingleSiteView: FC = () => {
    const queryRef = useLoaderData() as PreloadedQuery<SingleSiteContent_SiteViewQuery>;

    // FIXME: Casting isnt the best way of handling undefined type check. Currently, there are multiple ways people are dealing with this.
    // https://github.com/remix-run/react-router/issues/8200
    const { id: siteId } = useParams() as { id: string };
    useDocumentTitle(`Reports - Batteries by site - ${siteId}`);

    return (
        <>
            {!queryRef && <SingleSiteSkeleton />}
            {queryRef && (
                <Suspense fallback={<SingleSiteSkeleton />}>
                    <BatterySingleSiteViewContent key={siteId} queryRef={queryRef} />
                </Suspense>
            )}
        </>
    );
};

export async function getSingleSiteBreadcrumb(id: string): Promise<string> {
    const environment = getGlobalEnvironment();
    try {
        const data = await fetchQuery<SingleSiteGetSiteNameQuery>(
            environment,
            graphql`
                query SingleSiteGetSiteNameQuery($id: ID!) {
                    site(id: $id) {
                        name
                    }
                }
            `,
            { id },
            {
                fetchPolicy: 'store-or-network',
            }
        ).toPromise();

        return data?.site?.name ?? 'Unknown Site';
    } catch (error) {
        captureException(error, scope => {
            scope.setTag('Site', id);
            scope.setTag('Function', 'getSingleSiteBreadcrumb');
            return scope;
        });
        return 'Unknown Site';
    }
}
