import React, { FC } from 'react';
import { useRelayEnvironment } from 'react-relay';
import { fetchQuery } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { FilterSearchSelect } from 'components/FilterSearchSelect';
import { IEnvironment } from 'relay-runtime';

import { CommonFilterProps } from '../../common/types';
import { DeviceNameFilter } from '../types';
import { DeviceNameFilterResult } from './DeviceNameFilterResult';
import { DeviceNameFilterUI_deviceNameSearchQuery } from './__generated__/DeviceNameFilterUI_deviceNameSearchQuery.graphql';

export type DeviceNameFilterUIProps = CommonFilterProps<DeviceNameFilter[]>;

export const DeviceNameFilterUI: FC<DeviceNameFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    const environment = useRelayEnvironment();
    return (
        <FilterSearchSelect
            active={current}
            title='Filter by name'
            placeholder='Enter device name'
            renderItem={item => <DeviceNameFilterResult filter={item} />}
            renderItemSimple={item => item.name}
            showBack={hasPrevious}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onSearch={input => doSearch(environment, input, 5)}
            onClose={onClose}
            createDefaultItem={input => {
                if (input.trim().length > 0) {
                    return createWildcardFilter(input);
                } else {
                    return null;
                }
            }}
        />
    );
};

export async function doSearch(
    environment: IEnvironment,
    input: string,
    maxResults: number
): Promise<DeviceNameFilter[]> {
    try {
        const results = await fetchQuery<DeviceNameFilterUI_deviceNameSearchQuery>(
            environment,
            graphql`
                query DeviceNameFilterUI_deviceNameSearchQuery($input: String!, $maxResults: Int!) {
                    devices(filters: { name: [{ value: $input }] }, pageSize: $maxResults) {
                        data {
                            id
                            name
                            site {
                                name
                                address {
                                    state
                                }
                            }
                        }
                    }
                }
            `,
            {
                input,
                maxResults: maxResults - 1,
            }
        ).toPromise();

        const devices = results?.devices.data ?? [];

        const output = devices.map<DeviceNameFilter>(device => ({
            type: 'result',
            id: device.id,
            name: device.name,
            siteName: device.site.name,
            state: device.site.address.state,
        }));

        if (input.trim().length > 0) {
            if (output.length >= maxResults) {
                output[output.length - 1] = createWildcardFilter(input);
            } else {
                output.push(createWildcardFilter(input));
            }
        }

        return output;
    } catch (error) {
        return [];
    }
}

function createWildcardFilter(input: string): DeviceNameFilter {
    return {
        type: 'special',
        name: input.trim(),
        wildcard: true,
    };
}
