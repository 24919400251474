import React, { FC, MouseEvent } from 'react';

import { DeviceSelectionFooter } from '@accesstel/pcm-ui';

import style from './style.module.css';

export interface SelectionFooterProps {
    selectedCount: number;
    onActionClick: (event: MouseEvent) => void;
    unitSingular: string;
    unitPlural: string;
    actionMessageTemplate: string;
    allowShowOnZeroCount?: boolean;
}

export const SelectionFooter: FC<SelectionFooterProps> = ({
    selectedCount,
    onActionClick,
    unitSingular,
    unitPlural,
    actionMessageTemplate,
    allowShowOnZeroCount = false,
}) => {
    let suffix: string;
    if (selectedCount !== 1) {
        suffix = unitPlural;
    } else {
        suffix = unitSingular;
    }

    if (selectedCount !== 0 || allowShowOnZeroCount) {
        const message = `${selectedCount} ${suffix} Selected`;
        return (
            <div className={style.fixed_footer}>
                <DeviceSelectionFooter
                    buttonText={actionMessageTemplate.replaceAll('%', suffix.toLowerCase())}
                    onClick={onActionClick}
                    message={message}
                    buttonVariant='secondary'
                    disableButton={selectedCount === 0}
                    disableButtonMessage={`No ${suffix} selected`}
                />
            </div>
        );
    } else {
        return <></>;
    }
};
