import React, { Dispatch, FC, useState } from 'react';

import { CheckIcon, FormSection, IconWithTitle, MinusIcon, PlusIcon } from '@accesstel/pcm-ui';

import { FieldArray, useField } from 'formik';
import { getQualifiedName } from 'lib/namespaces';

import manageStyle from '../../../../style.module.css';
import { FormControlAction, FormControlActionTypes, FormControlParameters } from '../../lib/reducer';
import { BatteryStringList } from './components/BatteryStringList';
import { GeneralSettings } from './components/GeneralSettings';
import { NewStringEditor } from './components/string-editing/NewStringEditor';

export interface BatteriesProps {
    namespace?: string;
    companionDevice?: boolean;

    // form control
    formControlState: FormControlParameters;
    formControlDispatch: Dispatch<FormControlAction>;
}

export const DeviceBatteries: FC<BatteriesProps> = ({
    namespace,
    companionDevice = false,
    formControlState,
    formControlDispatch,
}) => {
    const [, batteryStringsField, batteryStringsFieldHelper] = useField<unknown[]>(
        getQualifiedName('strings', namespace)
    );
    const batteryStrings = batteryStringsField.value;
    const [isAddingBattery, setIsAddingBattery] = useState(false);

    let addText: string;
    if (isAddingBattery) {
        addText = `Battery String ${batteryStrings.length + 1}`;
    } else {
        if (!isAddingBattery && batteryStrings.length === 0) {
            addText = `Add Battery String`;
        } else {
            addText = 'Add Another Battery String';
        }
    }

    const onDuplicateOtherDevice = () => {
        formControlDispatch({
            type: FormControlActionTypes.DuplicateCompanionPlaneBatteries,
            payload: !formControlState.duplicateCompanionPlaneBatteries,
        });

        batteryStringsFieldHelper.setValue([]);
    };

    return (
        <FormSection
            label={'Battery Strings'}
            blurb='If this device uses batteries for backup power, this is where the details are configured.'
        >
            <div className={manageStyle.provisioning_content_grid}>
                <FieldArray name={getQualifiedName('strings', namespace)}>
                    {({ remove, push, replace }) => (
                        <>
                            {batteryStrings.length > 0 && (
                                <div className={manageStyle.strings_subgrid_container}>
                                    <BatteryStringList
                                        namespace={namespace}
                                        formControlState={formControlState}
                                        formControlDispatch={formControlDispatch}
                                        onRemove={remove}
                                        onUpdate={replace}
                                        onDuplicate={push}
                                    />
                                </div>
                            )}
                            <div className='col-start-1 col-span-2 flex space-x-4'>
                                <div
                                    className={
                                        formControlState.duplicateCompanionPlaneBatteries
                                            ? 'pointer-events-none cursor-not-allowed opacity-50'
                                            : ''
                                    }
                                >
                                    <IconWithTitle
                                        title={addText}
                                        size='medium'
                                        iconStroke='coral'
                                        onClick={() => setIsAddingBattery(value => !value)}
                                        fontFamily={isAddingBattery ? 'semibold' : 'regular'}
                                        icon={isAddingBattery ? <MinusIcon /> : <PlusIcon />}
                                    />
                                </div>
                                {companionDevice && (
                                    <IconWithTitle
                                        title={'Duplicate Other Plane'}
                                        size='medium'
                                        iconStroke='coral'
                                        onClick={onDuplicateOtherDevice}
                                        fontFamily={
                                            formControlState.duplicateCompanionPlaneBatteries ? 'semibold' : 'regular'
                                        }
                                        icon={
                                            formControlState.duplicateCompanionPlaneBatteries ? (
                                                <CheckIcon />
                                            ) : (
                                                <PlusIcon />
                                            )
                                        }
                                    />
                                )}
                            </div>

                            {isAddingBattery && (
                                <NewStringEditor
                                    onAdd={push}
                                    onClose={() => setIsAddingBattery(false)}
                                    formControlState={formControlState}
                                    formControlDispatch={formControlDispatch}
                                />
                            )}
                        </>
                    )}
                </FieldArray>

                {batteryStrings.length > 0 && <GeneralSettings namespace={namespace} />}
            </div>
        </FormSection>
    );
};
