import React, { FC, useCallback, useState } from 'react';

import { Link } from '@accesstel/pcm-ui';

import { Paths } from 'lib/routes';

import { DeviceWithBattery } from '../../../common';
import style from '../../../style.module.css';

export interface DeviceListProps {
    devices: DeviceWithBattery[];
}

export const DeviceList: FC<DeviceListProps> = ({ devices }) => {
    const [displayCount, setDisplayCount] = useState(5);
    const deviceCount = devices.length;

    const showAllDevices = useCallback(() => {
        setDisplayCount(deviceCount);
    }, [deviceCount]);

    const hasMore = deviceCount > displayCount;
    const remainingDevices = deviceCount - displayCount;

    const devicesRepresentation = devices
        .map(({ id, name, site }) => ({
            siteId: site.id,
            id: id,
            displayName: `${site.name} - ${name}`,
        }))
        .sort((dev1, dev2) => dev1.displayName.localeCompare(dev2.displayName));

    return (
        <>
            <div className={`${style.confirmation_devices_count} font-CynthoNext-SemiBold text-customEggplant`}>
                {`${deviceCount} ${deviceCount === 1 ? 'Device' : 'Devices'}`}
            </div>
            <div className={style.confirmation_devices_container}>
                <div
                    className={`${style.confirmation_devices} font-CynthoNext-Light text-customEggplant grid grid-cols-3`}
                >
                    {devicesRepresentation.slice(0, displayCount).map((device, idx) => (
                        <Link
                            className={`${style.device_link} w-max`}
                            to={`${Paths.ReportBatterySiteList}/${device.siteId}#${device.id}`}
                            target='_blank'
                            rel='noopener noreferrer'
                            key={`device-list-${idx}`}
                        >
                            {device.displayName}
                        </Link>
                    ))}
                    {hasMore && (
                        <button
                            className={`${style.more_devices_button} text-customCoral w-max`}
                            onClick={showAllDevices}
                        >
                            {` & ${remainingDevices} ${remainingDevices === 1 ? 'other' : 'others'}`}
                        </button>
                    )}
                </div>
            </div>
        </>
    );
};
