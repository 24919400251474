import React, { FC, useCallback, useMemo, useState } from 'react';

import {
    ChartFrame,
    ChartFrameAction,
    Domain,
    DomainAbsolute,
    MagIcon,
    Theme,
    ThemedDateLine,
    ThemedLineSeriesTypes,
    ThemedLineYAxis,
} from '@accesstel/pcm-ui';

import { MetricUnit } from '../__generated__/MetricsExploreContentQuery.graphql';
import { convertUnitToString, getUnitDisplayName } from '../lib/unit';
import { DataSeries, DefinedMetric } from '../types';

const SeriesColors = [Theme.customEggplant, '#064789', '#679436', '#A64253', '#427AA1', '#FF9F1C', '#2EC4B6'];

export interface ChartProps {
    title: string;
    xDomain: Domain<Date>;
    series: DataSeries[];
    metricDefinitions: readonly DefinedMetric[];

    actions: ChartFrameAction[];

    emptySuggestion?: 'device' | 'metric';
    onZoom: (xDomain: DomainAbsolute<Date>) => void;
    onResetZoom: () => void;
    isZoomed?: boolean;
    isAtMaxZoom?: boolean;
}

export const Chart: FC<ChartProps> = ({
    title,
    xDomain,
    series,
    metricDefinitions,
    actions,
    emptySuggestion,
    onZoom,
    onResetZoom,
    isZoomed,
    isAtMaxZoom,
}) => {
    const [selectedSeriesId, setSelectedSeriesId] = useState<string | null>(null);

    const formattedSeries = useMemo<ThemedLineSeriesTypes<Date>[]>(() => {
        return series.map((seriesInstance, index) => {
            const definition = metricDefinitions.find(metric => metric.metric === seriesInstance.metric);

            let name: string;

            if (definition) {
                let unit: string;

                if (definition.unit) {
                    // TODO: Adjust scale based on data and unit
                    unit = convertUnitToString(definition.unit, 'unit');
                } else {
                    unit = 'Unitless';
                }

                name = `${definition.displayName} - ${seriesInstance.op} (${unit})`;
            } else {
                name = `${seriesInstance.metric} - ${seriesInstance.op}`;
            }

            const id = `${seriesInstance.metric}-${seriesInstance.op}`;

            const seriesColor = SeriesColors[index % SeriesColors.length];

            return {
                id,
                axis: definition?.unit ?? 'unitless',
                name,
                lineColor: seriesColor,
                data: seriesInstance.data.map(dataPoint => ({
                    key: dataPoint.timestamp,
                    value: dataPoint.value,
                })),
                dots:
                    selectedSeriesId === id
                        ? {
                              stroke: seriesColor,
                              strokeWidth: 1,
                              fill: seriesColor,
                          }
                        : false,
            };
        });
    }, [metricDefinitions, selectedSeriesId, series]);

    const yAxes = useMemo<ThemedLineYAxis[]>(() => {
        // One axis per unit
        const units = new Set<MetricUnit | 'unitless'>();

        for (const seriesInstance of series) {
            const definition = metricDefinitions.find(metric => metric.metric === seriesInstance.metric);

            if (definition?.unit) {
                units.add(definition.unit);
            } else {
                units.add('unitless');
            }
        }

        const uniqueUnits = Array.from(units);

        return uniqueUnits.map(unit => {
            const matchingSeries = series.filter(seriesInstance => {
                const definition = metricDefinitions.find(metric => metric.metric === seriesInstance.metric);

                if (definition?.unit) {
                    return definition.unit === unit;
                } else {
                    return unit === 'unitless';
                }
            });

            const yDomain = getGoodYDomain(matchingSeries);

            if (unit === 'unitless') {
                return {
                    id: 'unitless',
                    label: 'No unit',
                    yDomain,
                };
            }

            return {
                id: unit,
                unit: convertUnitToString(unit, 'unit'),
                label: getUnitDisplayName(unit, 'unit'),
                axisUnits: true,
                yDomain,
            };
        });
    }, [metricDefinitions, series]);

    const handleZoom = useCallback(
        (domain: DomainAbsolute<Date> | null) => {
            if (domain) {
                onZoom(domain);
            } else {
                onResetZoom();
            }
        },
        [onResetZoom, onZoom]
    );

    const handleLegendClick = useCallback((_: string, id?: string) => {
        if (!id) {
            return;
        }

        setSelectedSeriesId(selection => {
            if (selection === id) {
                return null;
            } else {
                return id;
            }
        });
    }, []);

    let emptySuggestionTitle: string;
    let emptySuggestionDescription: string;

    if (emptySuggestion === 'device') {
        emptySuggestionTitle = 'No devices selected';
        emptySuggestionDescription = 'Please select a device to view metrics';
    } else if (emptySuggestion === 'metric') {
        emptySuggestionTitle = 'No metrics selected';
        emptySuggestionDescription = 'Please select a metric to view';
    } else {
        emptySuggestionTitle = '';
        emptySuggestionDescription = '';
    }

    return (
        <ChartFrame
            title={title}
            actions={[
                ...actions,
                {
                    buttonText: 'Reset zoom',
                    buttonIcon: <MagIcon />,
                    variant: 'dark',
                    onClick: () => handleZoom(null),
                    disabled: !isZoomed,
                },
            ]}
        >
            <ThemedDateLine
                aspectRatio={16 / 9}
                series={formattedSeries}
                xDomain={xDomain}
                showLegendInTooltip
                legend
                legendLayout='horizontal'
                legendAlign='left'
                legendVerticalAlign='top'
                legendOnClick={handleLegendClick}
                xTicks={10}
                unsupported={series.length === 0 && emptySuggestion !== undefined}
                unsupportedTitle={emptySuggestionTitle}
                unsupportedMessage={emptySuggestionDescription}
                zoom={isAtMaxZoom ? 'zoom-out' : true}
                onZoom={handleZoom}
                extendedLabels
                yAxes={yAxes}
                noDefaultYAxis
            />
        </ChartFrame>
    );
};

function getGoodYDomain(series: DataSeries[]): Domain<number> {
    // Try to keep the data in the middle of the chart with some but not much padding

    let min = Infinity;
    let max = -Infinity;

    for (const seriesInstance of series) {
        for (const dataPoint of seriesInstance.data) {
            if (dataPoint.value == null) {
                continue;
            }

            if (dataPoint.value < min) {
                min = dataPoint.value;
            }

            if (dataPoint.value > max) {
                max = dataPoint.value;
            }
        }
    }

    if (min === Infinity || max === -Infinity) {
        return ['auto', 'auto'];
    }

    const padding = (max - min) * 0.1;

    return [min - padding, max + padding];
}
