/**
 * @generated SignedSource<<8f1d94ac550b219d59311fac2624fd99>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AlertListSearchQuery$variables = {
  pageSize: number;
  search: string;
};
export type AlertListSearchQuery$data = {
  readonly alerts: {
    readonly data: ReadonlyArray<{
      readonly device: {
        readonly id: string;
        readonly name: string;
        readonly site: {
          readonly address: {
            readonly state: string;
          };
          readonly id: string;
          readonly name: string;
        };
      };
      readonly site: {
        readonly address: {
          readonly state: string;
        };
        readonly id: string;
        readonly name: string;
      };
    }>;
  };
};
export type AlertListSearchQuery = {
  response: AlertListSearchQuery$data;
  variables: AlertListSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageSize"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v2 = [
  {
    "kind": "Literal",
    "name": "onlyActiveAlerts",
    "value": false
  },
  {
    "kind": "Variable",
    "name": "pageSize",
    "variableName": "pageSize"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Site",
  "kind": "LinkedField",
  "name": "site",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Address",
      "kind": "LinkedField",
      "name": "address",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "state",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Device",
  "kind": "LinkedField",
  "name": "device",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AlertListSearchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "PaginatedAlerts",
        "kind": "LinkedField",
        "name": "alerts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Alert",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AlertListSearchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "PaginatedAlerts",
        "kind": "LinkedField",
        "name": "alerts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Alert",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              (v5/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0683988f896f8e90ba5df14d3839ee65",
    "id": null,
    "metadata": {},
    "name": "AlertListSearchQuery",
    "operationKind": "query",
    "text": "query AlertListSearchQuery(\n  $search: String!\n  $pageSize: Int!\n) {\n  alerts(onlyActiveAlerts: false, search: $search, pageSize: $pageSize) {\n    data {\n      device {\n        id\n        name\n        site {\n          id\n          name\n          address {\n            state\n          }\n        }\n      }\n      site {\n        id\n        name\n        address {\n          state\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1e9a7360ab553f1ebf7ee15754fceaf8";

export default node;
