import React, { FC, useEffect, useMemo, useState } from 'react';
import { PreloadedQuery, loadQuery, usePreloadedQuery } from 'react-relay';
import { useParams } from 'react-router-dom';

import { Menu, Modal, PageLastRefreshed, Tabs, ThemedPageHeading, useExtendedNavigate } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { PageHeader, useDocumentTitle } from 'components';
import { AlertBanner } from 'components/AlertBanner';
import { useUserPermissions } from 'lib/auth';
import { getGlobalEnvironment } from 'lib/environment';
import { useQuery } from 'lib/query-helpers';
import { Paths } from 'lib/routes';
import { ExportForm } from 'views/exporting/components';

import style from '../style.module.css';
import { SingleSiteContent_SiteViewQuery } from './__generated__/SingleSiteContent_SiteViewQuery.graphql';
import { DeviceReport } from './device-section/DeviceReport';

const refreshPage = () => {
    window.location.reload();
};

export const BatterySingleSiteViewQuery = graphql`
    query SingleSiteContent_SiteViewQuery($siteId: ID!) {
        site(id: $siteId) {
            name
            address {
                state
                address
            }
            devices {
                data {
                    id
                    name
                    battery {
                        installed
                        metrics {
                            originalCapacity
                            latestRemainingCapacity
                        }
                    }
                    ...Details_device
                }
            }
        }
    }
`;

export interface BatterySingleSiteViewContentProps {
    queryRef: PreloadedQuery<SingleSiteContent_SiteViewQuery>;
}

export const BatterySingleSiteViewContent: FC<BatterySingleSiteViewContentProps> = ({ queryRef }) => {
    const props = usePreloadedQuery<SingleSiteContent_SiteViewQuery>(BatterySingleSiteViewQuery, queryRef);

    // FIXME: Casting isnt the best way of handling undefined type check. Currently, there are multiple ways people are dealing with this.
    // https://github.com/remix-run/react-router/issues/8200
    const { siteId, deviceId } = useParams() as { siteId: string; deviceId: string };
    const navigate = useExtendedNavigate();
    const { hasAssetsWrite } = useUserPermissions();

    const [isExportModalOpen, setIsExportModalOpen] = useState(false);
    const activeDeviceTab = useMemo(() => {
        if (!props.site) {
            return;
        }

        if (props.site.devices.data.length === 0) {
            return;
        }

        const matchingDevice = props.site.devices.data.find(device => device.id === deviceId);
        if (matchingDevice) {
            return { id: matchingDevice.id, label: matchingDevice.name };
        }
    }, [deviceId, props.site]);

    useDocumentTitle(`Reports - Batteries by site - ${siteId}`);

    let body: React.ReactNode;
    if (!props.site) {
        body = <div>Error unknown site</div>;
    } else {
        const { site } = props;
        const devices = [...site.devices.data].sort((deviceA, deviceB) => deviceA.name.localeCompare(deviceB.name));
        const device = activeDeviceTab ? devices.find(device => device.id === activeDeviceTab.id) : null;

        let siteLocation: string;
        if (site.address.address) {
            siteLocation = `${site.address.address}, ${site.address.state}`;
        } else {
            siteLocation = site.address.state;
        }

        let reportSection = <></>;
        if (device) {
            reportSection = (
                <DeviceReport
                    id={device.id}
                    name={device.name}
                    device={device}
                    batteryInstalled={device.battery.installed}
                />
            );
        } else if (devices.length === 0) {
            reportSection = (
                <AlertBanner
                    title='No Device'
                    message='No devices configured on this site'
                    action={
                        hasAssetsWrite
                            ? {
                                  label: 'Add Device',
                                  onClick: () => navigate({ pathname: Paths.AddDevice, search: { site: siteId } }),
                              }
                            : undefined
                    }
                />
            );
        } else {
            <div>Error unknown device</div>;
        }

        body = (
            <>
                <div className='flex flex-row justify-between items-center'>
                    <ThemedPageHeading value={`${site.name}, ${site.address.state}`} />
                    <Menu
                        id={`site-${siteId}-menu`}
                        menuItems={[
                            { name: 'Export all devices', onClick: () => setIsExportModalOpen(true) },
                            {
                                name: 'View/Edit Site',
                                onClick: () => navigate({ pathname: Paths.EditSite, params: { id: siteId } }),
                            },
                        ]}
                    />
                </div>
                <div className={style.site_info}>
                    <div>
                        {/* FIXME: Remove the colour class from here once postcss and tailwind are working properly with css modules */}
                        <span className='text-customCoral'>Site Location: </span>
                        <span>{siteLocation}</span>
                    </div>
                    <PageLastRefreshed buttonClickCallback={refreshPage} />
                </div>
                {devices.length > 1 && activeDeviceTab ? (
                    <div className='pt-6 pb-2' data-testid='tabs-component'>
                        <Tabs
                            activeItem={activeDeviceTab}
                            setActiveItem={value =>
                                navigate({
                                    pathname: Paths.ReportBatteriesViewSiteDevice,
                                    params: { siteId, deviceId: value.id },
                                })
                            }
                            tabs={devices.map(device => ({ id: device.id, label: device.name }))}
                        />
                    </div>
                ) : (
                    <div className={style.divider}></div>
                )}
                {reportSection}
                <Modal
                    bgColor='bg-white'
                    open={isExportModalOpen}
                    onHide={() => setIsExportModalOpen(false)}
                    closeButton={true}
                >
                    <ExportForm
                        deviceIds={devices.map(device => device.id)}
                        basicMetrics={['BatteryVoltage', 'BatteryCurrent', 'BatteryTemperature', 'BatteryPower']}
                    />
                </Modal>
            </>
        );
    }

    return (
        <>
            <div className={`${style.container} space-y-4 text-customEggplant`}>
                {/* FIXME: Remove the eggplant colour class from here once postcss and tailwind are working properly with css modules */}

                <PageHeader />
                {body}
                <div className={style.footer}></div>
            </div>
        </>
    );
};

export const NavigateToFirstDevice: FC = () => {
    const { siteId } = useParams() as { siteId: string };
    const navigate = useExtendedNavigate();

    const { data } = useQuery<SingleSiteContent_SiteViewQuery>(BatterySingleSiteViewQuery, { siteId });

    useEffect(() => {
        if (!data?.site) {
            // Let the component handle invalid site
            navigate(
                { pathname: Paths.ReportBatteriesViewSiteDevice, params: { siteId, deviceId: 'invalid-site' } },
                { replace: true }
            );
            return;
        }

        if (data.site.devices.data.length > 0) {
            const firstDevice = [...data.site.devices.data].sort((deviceA, deviceB) =>
                deviceA.name.localeCompare(deviceB.name)
            )[0];

            navigate(
                { pathname: Paths.ReportBatteriesViewSiteDevice, params: { siteId, deviceId: firstDevice.id } },
                { replace: true }
            );
        } else {
            // Let the component handle site with no devices
            navigate(
                { pathname: Paths.ReportBatteriesViewSiteDevice, params: { siteId, deviceId: 'empty-site' } },
                { replace: true }
            );
        }
        return;
    }, [data, navigate, siteId]);

    return <></>;
};

export function loadSingleSitePageData(id: string) {
    return loadQuery(
        getGlobalEnvironment(),
        BatterySingleSiteViewQuery,
        {
            siteId: id,
        },
        {
            fetchPolicy: 'store-and-network',
        }
    );
}
