import React, { FC } from 'react';

import { FormSection, FormikTextBox } from '@accesstel/pcm-ui';

import { useFormikContext } from 'formik';
import { getQualifiedName } from 'lib/namespaces';
import { queries_GetDeviceTypeConnectivityQuery$data } from 'views/manage/__generated__/queries_GetDeviceTypeConnectivityQuery.graphql';
import { Attributes } from 'views/manage/common/sub-forms/Attributes';
import { ProvisionSectionHeading } from 'views/manage/components';

import manageStyle from '../../../../style.module.css';
import { DeviceFormValues } from '../../schema';
import { MonitorOnlyMode } from '../../sub-forms/MonitorOnlyMode';
import { ConnectivitySettings } from '../../sub-forms/connectivity-settings/ConnectivitySettings';
import { Integration } from '../Integration';

export interface DualPlaneCompanionProps {
    namespace: string;
    deviceTypeConnectivity?: queries_GetDeviceTypeConnectivityQuery$data;
    planeAMonitorOnlyModeState: boolean;
}

export const DualPlaneCompanion: FC<DualPlaneCompanionProps> = ({
    namespace,
    deviceTypeConnectivity,
    planeAMonitorOnlyModeState,
}) => {
    const { values } = useFormikContext<DeviceFormValues>();

    // FIXME: Companion devices should also have a device type selection
    return (
        <>
            <ProvisionSectionHeading>New Dual Plane Companion Device</ProvisionSectionHeading>
            <FormSection
                label={'General Information'}
                blurb='This is some general information that relates to the companion device.'
            >
                <div className={manageStyle.provisioning_content_grid}>
                    <div className='col-start-1'>
                        <FormikTextBox
                            name={getQualifiedName('name', namespace)}
                            placeHolder='Device Name*'
                            required
                            light
                            variant='outlined'
                            testId='companion-device-name'
                        />
                    </div>
                    <div className={manageStyle.custom_attributes}>
                        <Attributes type='Device' namespace={namespace} />
                    </div>
                </div>
            </FormSection>

            {deviceTypeConnectivity?.deviceType?.connectivity && (
                <ConnectivitySettings
                    namespace={getQualifiedName('settings', namespace)}
                    deviceType={values.type}
                    connectivity={deviceTypeConnectivity}
                />
            )}

            <Integration namespace={getQualifiedName('optimaIntegration', namespace)} showSiteId={false} />

            <MonitorOnlyMode
                namespace={namespace}
                // FIXME: Default to the same as A
                // monitorOnlyModeInitialState={planeAMonitorOnlyModeState}
            />
        </>
    );
};
