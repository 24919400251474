import React, { FC } from 'react';
import { useRelayEnvironment } from 'react-relay';
import { fetchQuery } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { FilterSearchSelect } from 'components/FilterSearchSelect';
import { IEnvironment } from 'relay-runtime';

import { CommonFilterProps } from '../../common/types';
import { BatteryTestDeviceNameFilter } from '../types';
import { DeviceNameFilterResult } from './DeviceNameFilterResult';
import { DeviceNameFilterUI_taskDeviceNameSearchQuery } from './__generated__/DeviceNameFilterUI_taskDeviceNameSearchQuery.graphql';

export type BatteryTestDeviceNameFilterUIProps = CommonFilterProps<BatteryTestDeviceNameFilter[]>;

export const DeviceNameFilterUI: FC<BatteryTestDeviceNameFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    const environment = useRelayEnvironment();

    return (
        <FilterSearchSelect
            active={current}
            title='Filter by Device Name'
            placeholder='Enter Device Name'
            renderItem={item => <DeviceNameFilterResult filter={item} />}
            renderItemSimple={item => item.name}
            showBack={hasPrevious}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onSearch={input => doSearch(environment, input, 5)}
            onClose={onClose}
            createDefaultItem={input => {
                if (input.trim().length > 0) {
                    return createWildcardFilter(input);
                } else {
                    return null;
                }
            }}
        />
    );
};

async function doSearch(
    environment: IEnvironment,
    input: string,
    maxResults: number
): Promise<BatteryTestDeviceNameFilter[]> {
    try {
        // TODO: figure out a query that limits the  names for related devices
        const results = await fetchQuery<DeviceNameFilterUI_taskDeviceNameSearchQuery>(
            environment,
            graphql`
                query DeviceNameFilterUI_taskDeviceNameSearchQuery($input: String!, $maxResults: Int!) {
                    devices(filters: { name: [{ value: $input }] }, pageSize: $maxResults) {
                        data {
                            id
                            name
                        }
                    }
                }
            `,
            {
                input,
                maxResults: maxResults - 1,
            }
        ).toPromise();

        const devices = results?.devices.data;

        if (!devices) {
            return [];
        }

        const output = devices.map<BatteryTestDeviceNameFilter>(device => ({
            type: 'result',
            id: device.id,
            name: device.name,
        }));

        if (input.trim().length > 0) {
            if (output.length >= maxResults) {
                output[output.length - 1] = createWildcardFilter(input);
            } else {
                output.push(createWildcardFilter(input));
            }
        }

        return output;
    } catch (error) {
        return [];
    }
}

function createWildcardFilter(input: string): BatteryTestDeviceNameFilter {
    return {
        type: 'special',
        name: input.trim(),
        wildcard: true,
    };
}
