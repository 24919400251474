/* eslint-disable no-irregular-whitespace */
import React, { FC } from 'react';

import classNames from 'classnames';

import { SkeletonPadding } from './SkeletonPadding';
import { SkeletonText } from './SkeletonText';
import style from './style.module.css';

export interface SkeletonTableProps {
    height?: string | number;
    className?: string;
}

export const SkeletonTable: FC<SkeletonTableProps> = ({ className, height }) => {
    return (
        <div className={classNames(className, style.table_skeleton)} style={{ height }}>
            <SkeletonPadding spacing={30} />
            <div className='flex flex-row gap-2'>
                <SkeletonText characters={15} />
                <SkeletonText characters={6} />
                <SkeletonText characters={20} />
                <SkeletonText characters={15} />
            </div>
            <div className={style.body}>
                <div className={style.row}></div>
                <div className={style.row}></div>
                <div className={style.row}></div>
            </div>
        </div>
    );
};
