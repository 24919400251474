import { PermissionsState } from './reducer';

export function hasAnyPermission(state: PermissionsState): boolean {
    return (
        state.assets !== 'None' ||
        state.batteryHealthTasks !== 'None' ||
        state.administration !== 'None' ||
        state.general !== 'None'
    );
}
