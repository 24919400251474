/**
 * @generated SignedSource<<186ea91f59752433279d353e8700507c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BatteryStatus = "BoostCharging" | "Charging" | "Discharging" | "Disconnected" | "FloatCharging" | "Idle" | "Missing" | "Unknown" | "%future added value";
export type DeviceSortField = "LastTested" | "Name" | "Site" | "State" | "Type" | "%future added value";
export type SortDirection = "Asc" | "Desc" | "%future added value";
export type DeviceOrdering = {
  dir?: SortDirection | null;
  field: DeviceSortField;
};
export type DeviceFilter = {
  attributes?: ReadonlyArray<AttributeFilters> | null;
  batteryCapacityRemaining?: FloatRangeFilter | null;
  batteryCapacityTotal?: FloatRangeFilter | null;
  batteryEnergyTotal?: FloatRangeFilter | null;
  batteryInstallDate?: DateRangeFilter | null;
  batteryManufactureDate?: DateRangeFilter | null;
  batteryReserveTime?: FloatRangeFilter | null;
  batteryState?: ReadonlyArray<BatteryStatusFilter> | null;
  batteryStateOfHealth?: FloatRangeFilter | null;
  batteryStringCount?: IntRangeFilter | null;
  batteryTemperature?: FloatRangeFilter | null;
  health?: ReadonlyArray<StringFilter> | null;
  ids?: ReadonlyArray<string> | null;
  lastTestedDate?: DateRangeFilter | null;
  monitorOnly?: BooleanFilter | null;
  name?: ReadonlyArray<StringFilter> | null;
  siteId?: ReadonlyArray<string> | null;
  siteName?: ReadonlyArray<StringFilter> | null;
  siteState?: ReadonlyArray<StringFilter> | null;
  snmpVersion?: ReadonlyArray<StringFilter> | null;
  supportsBatteryTesting?: BooleanFilter | null;
  type?: ReadonlyArray<StringFilter> | null;
};
export type StringFilter = {
  value: string;
};
export type AttributeFilters = {
  filters: ReadonlyArray<StringFilter>;
  name: string;
};
export type IntRangeFilter = {
  max: number;
  min: number;
};
export type FloatRangeFilter = {
  max: number;
  min: number;
};
export type BatteryStatusFilter = {
  value: BatteryStatus;
};
export type DateRangeFilter = {
  max: string;
  min: string;
};
export type BooleanFilter = {
  value: boolean;
};
export type ScheduleGetEligibleDevicesQuery$variables = {
  filters?: DeviceFilter | null;
  name: string;
  orderBy?: DeviceOrdering | null;
  page?: number | null;
};
export type ScheduleGetEligibleDevicesQuery$data = {
  readonly devices: {
    readonly data: ReadonlyArray<{
      readonly battery: {
        readonly allowedVoltage: {
          readonly maximum: number | null;
          readonly minimum: number | null;
        };
        readonly quickTestFailThreshold: number | null;
        readonly reserveTime: number | null;
        readonly strings: {
          readonly count: number;
          readonly strings: ReadonlyArray<{
            readonly cellsPerString: number;
            readonly type: {
              readonly cells: {
                readonly allowedVoltage: {
                  readonly maximum: number | null;
                  readonly minimum: number | null;
                } | null;
              };
              readonly dischargeTables: ReadonlyArray<{
                readonly endOfDischargeVoltage: number;
              }>;
              readonly id: string;
              readonly manufacturer: string;
              readonly model: string;
            };
          }>;
        };
      };
      readonly id: string;
      readonly name: string;
      readonly site: {
        readonly id: string;
        readonly name: string;
      };
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"DevicesTable_devices">;
  };
};
export type ScheduleGetEligibleDevicesQuery = {
  response: ScheduleGetEligibleDevicesQuery$data;
  variables: ScheduleGetEligibleDevicesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v3 = {
  "defaultValue": 1,
  "kind": "LocalArgument",
  "name": "page"
},
v4 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Literal",
    "name": "onlyWithValidBattery",
    "value": true
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "name"
  },
  {
    "kind": "Literal",
    "name": "searchOptions",
    "value": {
      "includeDeviceName": true,
      "includeSiteName": true
    }
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = [
  (v5/*: any*/),
  (v6/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cellsPerString",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "VoltageRange",
  "kind": "LinkedField",
  "name": "allowedVoltage",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minimum",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maximum",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "BatteryType",
  "kind": "LinkedField",
  "name": "type",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "manufacturer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "model",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DischargeTable",
      "kind": "LinkedField",
      "name": "dischargeTables",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endOfDischargeVoltage",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BatteryCellDefinition",
      "kind": "LinkedField",
      "name": "cells",
      "plural": false,
      "selections": [
        (v10/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reserveTime",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quickTestFailThreshold",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ScheduleGetEligibleDevicesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "PaginatedDevices",
        "kind": "LinkedField",
        "name": "devices",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DevicesTable_devices"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Device",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Site",
                "kind": "LinkedField",
                "name": "site",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DeviceBattery",
                "kind": "LinkedField",
                "name": "battery",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeviceBatteryStrings",
                    "kind": "LinkedField",
                    "name": "strings",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DeviceBatteryString",
                        "kind": "LinkedField",
                        "name": "strings",
                        "plural": true,
                        "selections": [
                          (v9/*: any*/),
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ScheduleGetEligibleDevicesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "PaginatedDevices",
        "kind": "LinkedField",
        "name": "devices",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Device",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Site",
                "kind": "LinkedField",
                "name": "site",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Address",
                    "kind": "LinkedField",
                    "name": "address",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "orderBy",
                    "value": {
                      "dir": "Desc",
                      "field": "StartTime"
                    }
                  },
                  {
                    "kind": "Literal",
                    "name": "pageSize",
                    "value": 1
                  }
                ],
                "concreteType": "PaginatedDeviceBatteryTestResults",
                "kind": "LinkedField",
                "name": "tests",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeviceBatteryTestResults",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": true,
                    "selections": [
                      (v14/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "commencedTime",
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "tests(orderBy:{\"dir\":\"Desc\",\"field\":\"StartTime\"},pageSize:1)"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DualPlaneCompanion",
                "kind": "LinkedField",
                "name": "dualPlaneCompanion",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Device",
                    "kind": "LinkedField",
                    "name": "device",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "configuration",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DeviceBattery",
                "kind": "LinkedField",
                "name": "battery",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeviceBatteryStrings",
                    "kind": "LinkedField",
                    "name": "strings",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DeviceBatteryString",
                        "kind": "LinkedField",
                        "name": "strings",
                        "plural": true,
                        "selections": [
                          (v9/*: any*/),
                          (v11/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "page",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "size",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "total",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNext",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPrevious",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f27b77a034f07ed3591cbd8f8c2d80cb",
    "id": null,
    "metadata": {},
    "name": "ScheduleGetEligibleDevicesQuery",
    "operationKind": "query",
    "text": "query ScheduleGetEligibleDevicesQuery(\n  $page: Int = 1\n  $name: String!\n  $orderBy: DeviceOrdering\n  $filters: DeviceFilter\n) {\n  devices(page: $page, search: $name, searchOptions: {includeSiteName: true, includeDeviceName: true}, onlyWithValidBattery: true, orderBy: $orderBy, filters: $filters) {\n    ...DevicesTable_devices\n    data {\n      id\n      name\n      site {\n        id\n        name\n      }\n      battery {\n        strings {\n          count\n          strings {\n            cellsPerString\n            type {\n              id\n              manufacturer\n              model\n              dischargeTables {\n                endOfDischargeVoltage\n              }\n              cells {\n                allowedVoltage {\n                  minimum\n                  maximum\n                }\n              }\n            }\n            id\n          }\n        }\n        allowedVoltage {\n          minimum\n          maximum\n        }\n        reserveTime\n        quickTestFailThreshold\n      }\n    }\n  }\n}\n\nfragment DevicesTable_devices on PaginatedDevices {\n  data {\n    id\n    site {\n      id\n      name\n      address {\n        state\n      }\n    }\n    name\n    tests(orderBy: {field: StartTime, dir: Desc}, pageSize: 1) {\n      data {\n        state\n        commencedTime\n        id\n      }\n    }\n    dualPlaneCompanion {\n      device {\n        id\n        name\n      }\n      configuration\n    }\n  }\n  pageInfo {\n    page\n    size\n    total\n    hasNext\n    hasPrevious\n  }\n}\n"
  }
};
})();

(node as any).hash = "2e7a4cbbe608f93ed6d944f54b2db6b4";

export default node;
