import React, { FC, useCallback, useMemo, useState } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';
import { generatePath } from 'react-router-dom';

import { BarDataType, LabelFormatterType } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { FilterActionType } from 'filters/common';
import { SiteTableColumn, siteToFilterObject, useSiteFilter } from 'filters/site';
import { SortDirection, TableActionType, TableLayout, useTableReducer } from 'layouts';
import { useUserPermissions } from 'lib/auth';
import { useQuery } from 'lib/query-helpers';
import { Paths } from 'lib/routes';
import { IEnvironment } from 'relay-runtime';

import { colorPalette } from '../AssetManagement';
import { queries_GetAssetsDistributionQuery } from '../__generated__/queries_GetAssetsDistributionQuery.graphql';
import { getAssetsDistribution } from '../queries';
import { SitesAllIdsQuery } from './__generated__/SitesAllIdsQuery.graphql';
import { SitesSearchQuery, SitesSearchQuery$data } from './__generated__/SitesSearchQuery.graphql';
import {
    SiteOrdering,
    SiteSortField,
    SitesTableQuery,
    SitesTableQuery$data,
    SitesTableQuery$variables,
} from './__generated__/SitesTableQuery.graphql';
import { DeleteSitesModal } from './components';
import { AllTableColumns, BaseTableColumns } from './settings';

type Site = SitesTableQuery$data['sites']['data'][number];
type SiteSearchResult = SitesSearchQuery$data['sites']['data'][number];

const TableStorageKeyPrefix = 'site-table';

export const ManageSites: FC = () => {
    const { hasAssetsWrite } = useUserPermissions();
    const environment = useRelayEnvironment();

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [tableState, dispatchTableState] = useTableReducer<SiteTableColumn>({
        defaultSortColumn: SiteTableColumn.Name,
        allColumns: AllTableColumns.map(column => column.id),
        defaultVisibleColumns: BaseTableColumns.map(column => column.id),
        storageKeyPrefix: TableStorageKeyPrefix,
    });

    const [filters, dispatchFilters] = useSiteFilter();
    const filterObject = useMemo(() => siteToFilterObject(filters), [filters]);

    const sortObject: SiteOrdering = {
        field: tableState.sortColumn as SiteSortField,
        dir: tableState.sortDirection === SortDirection.Ascending ? 'Asc' : 'Desc',
    };

    const variables: SitesTableQuery$variables = {
        page: tableState.page,
        search: tableState.search,
        orderBy: sortObject,
        filter: filterObject,
        withSiteType: tableState.visibleColumnsInOrder.includes(SiteTableColumn.Type),
        withPostcode: tableState.visibleColumnsInOrder.includes(SiteTableColumn.Postcode),
        withBatteryStatus: tableState.visibleColumnsInOrder.includes(SiteTableColumn.BatteryStatus),
        withBatteryCapacity: tableState.visibleColumnsInOrder.includes(SiteTableColumn.BatteryCapacity),
        withBatteryTimeRemaining: tableState.visibleColumnsInOrder.includes(SiteTableColumn.BatteryTotalTimeRemaining),
    };

    const { data: props, error, retry, isFetching } = useQuery<SitesTableQuery>(
        graphql`
            query SitesTableQuery(
                $page: Int = 1
                $search: String = ""
                $orderBy: SiteOrdering
                $filter: SiteFilter
                $withSiteType: Boolean = false
                $withPostcode: Boolean = false
                $withBatteryStatus: Boolean = false
                $withBatteryCapacity: Boolean = false
                $withBatteryTimeRemaining: Boolean = false
            ) {
                sites(
                    page: $page
                    search: $search
                    onlyProvisioningStatuses: Active
                    orderBy: $orderBy
                    filters: $filter
                ) {
                    total
                    data {
                        __typename
                        id
                        name
                        type @include(if: $withSiteType)
                        address {
                            state
                            postcode @include(if: $withPostcode)
                        }
                        devices {
                            total
                        }
                        health {
                            status
                            commonStatus
                            multipleStatuses
                        }
                        batteryMetrics {
                            commonStatus @include(if: $withBatteryStatus)
                            multipleStatuses @include(if: $withBatteryStatus)
                            totalCapacity(unit: AmpHour) @include(if: $withBatteryCapacity)
                            totalTimeRemaining(unit: Hours) @include(if: $withBatteryTimeRemaining)
                        }
                    }
                    pageInfo {
                        page
                        size
                        total
                        hasNext
                        hasPrevious
                    }
                }
                overallSites: sites {
                    total
                }
            }
        `,
        variables,
        {
            fetchPolicy: 'network-only',
        }
    );

    const { data: distributionProps } = useQuery<queries_GetAssetsDistributionQuery>(
        getAssetsDistribution,
        { type: 'SiteType' },
        { fetchPolicy: 'network-only' }
    );

    const handleSearch = useCallback(
        (input: string) => {
            return fetchQuery<SitesSearchQuery>(
                environment,
                graphql`
                    query SitesSearchQuery($name: String = "", $pageSize: Int!) {
                        sites(filters: { name: { value: $name } }, pageSize: $pageSize) {
                            data {
                                id
                                name
                            }
                        }
                    }
                `,
                { name: input, pageSize: 10 }
            )
                .toPromise()
                .then(result => (result?.sites.data as SiteSearchResult[]) ?? []);
        },
        [environment]
    );

    const distributionData: BarDataType[] =
        distributionProps?.assetDistribution?.distribution.map((group, i) => {
            let bgColor = undefined;
            if (colorPalette.length > i) {
                bgColor = colorPalette[i];
            }

            return {
                id: group.key,
                label: group.key,
                value: group.value,
                bgClass: bgColor,
            };
        }) ?? [];

    const labelFormatter: LabelFormatterType<BarDataType> = useCallback((label, data) => {
        if (label === null) {
            return '';
        }
        return `${data.value} ${label.toUpperCase()}`;
    }, []);

    const onSegmentClick = (id: string) => {
        dispatchFilters({ type: FilterActionType.Apply, column: SiteTableColumn.Type, value: [{ name: id, id }] });
    };

    return (
        <>
            <TableLayout
                title='Site Management'
                columns={AllTableColumns}
                allowEditingColumns
                filterState={filters}
                dispatchFilterState={dispatchFilters}
                tableState={tableState}
                dispatchTableState={dispatchTableState}
                data={(props?.sites.data ?? null) as Site[]}
                getRowId={row => row.id}
                isProcessing={!!props && isFetching}
                page={props?.sites.pageInfo.page}
                pageCount={props?.sites.pageInfo.total}
                overallCount={props?.overallSites.total}
                resultCount={props?.sites.total}
                hasError={!!error}
                onRetry={retry}
                searchPlaceholder='Search by Site'
                onSearch={handleSearch}
                renderSearchResult={(item: SiteSearchResult) => item.name}
                renderSearchResultAsString={(item: SiteSearchResult) => item.name}
                emptyMessage='There are no sites present'
                unit='Site'
                primaryAction={hasAssetsWrite ? 'Add new site' : undefined}
                primaryActionLink={hasAssetsWrite ? Paths.AddSite : undefined}
                selection={hasAssetsWrite}
                onRequestAllIds={() => getAllSiteIds(environment, filterObject)}
                getItemLink={getSiteLink}
                selectionActionMessage='Delete selected %'
                onSelectionAction={() => setShowDeleteModal(true)}
                barChartData={{
                    data: distributionData,
                    emptyLabel: 'No sites added',
                    labelFormatter,
                    onSegmentClick,
                }}
            />
            {showDeleteModal && (
                <DeleteSitesModal
                    selectedSites={tableState.selectedItems}
                    onDone={() => dispatchTableState({ type: TableActionType.SetSelection, selection: [] })}
                    setShowDeleteModal={setShowDeleteModal}
                    showDeleteModal={showDeleteModal}
                    tableRetry={retry}
                />
            )}
        </>
    );
};

function getAllSiteIds(environment: IEnvironment, filters: Record<string, unknown>): Promise<string[]> {
    const getAllSiteIdsQuery = graphql`
        query SitesAllIdsQuery($filters: SiteFilter) {
            sites(onlyProvisioningStatuses: Active, pageSize: 10000, filters: $filters) {
                data {
                    id
                }
            }
        }
    `;

    return fetchQuery<SitesAllIdsQuery>(environment, getAllSiteIdsQuery, { filters })
        .toPromise()
        .then(data => data?.sites.data.map(site => site.id) ?? []);
}

function getSiteLink(site: Site): string {
    return generatePath(Paths.EditSite, { id: site.id });
}
