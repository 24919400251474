/**
 * @generated SignedSource<<a05a4f399707d36c67c91aa3cc0ad5d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BatteryStatus = "BoostCharging" | "Charging" | "Discharging" | "Disconnected" | "FloatCharging" | "Idle" | "Missing" | "Unknown" | "%future added value";
export type DeviceHealth = "Critical" | "Degraded" | "Healthy" | "Offline" | "Unknown" | "%future added value";
export type SitesWithBatteries = "All" | "None" | "Partial" | "%future added value";
export type SiteFilter = {
  attributes?: ReadonlyArray<AttributeFilters> | null;
  batteryCapacity?: FloatRangeFilter | null;
  batteryStatus?: ReadonlyArray<BatteryStatusFilter> | null;
  batteryTotalTimeRemaining?: FloatRangeFilter | null;
  deviceBatteries?: SitesWithBatteries | null;
  deviceCount?: IntRangeFilter | null;
  deviceHealth?: ReadonlyArray<DeviceHealthFilter> | null;
  deviceName?: ReadonlyArray<StringFilter> | null;
  ids?: ReadonlyArray<string> | null;
  name?: ReadonlyArray<StringFilter> | null;
  postcode?: ReadonlyArray<StringFilter> | null;
  state?: ReadonlyArray<StringFilter> | null;
  type?: ReadonlyArray<StringFilter> | null;
};
export type StringFilter = {
  value: string;
};
export type IntRangeFilter = {
  max: number;
  min: number;
};
export type DeviceHealthFilter = {
  value: DeviceHealth;
};
export type AttributeFilters = {
  filters: ReadonlyArray<StringFilter>;
  name: string;
};
export type BatteryStatusFilter = {
  value: BatteryStatus;
};
export type FloatRangeFilter = {
  max: number;
  min: number;
};
export type BatterySiteListAllQuery$variables = {
  filters?: SiteFilter | null;
};
export type BatterySiteListAllQuery$data = {
  readonly sites: {
    readonly data: ReadonlyArray<{
      readonly devices: {
        readonly data: ReadonlyArray<{
          readonly id: string;
        }>;
      };
    }>;
  };
};
export type BatterySiteListAllQuery = {
  response: BatterySiteListAllQuery$data;
  variables: BatterySiteListAllQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Literal",
    "name": "onlyProvisioningStatuses",
    "value": "Active"
  },
  {
    "kind": "Literal",
    "name": "onlyWithValidBatteries",
    "value": true
  },
  {
    "kind": "Literal",
    "name": "pageSize",
    "value": 10000
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "PaginatedDevices",
  "kind": "LinkedField",
  "name": "devices",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Device",
      "kind": "LinkedField",
      "name": "data",
      "plural": true,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BatterySiteListAllQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PaginatedSites",
        "kind": "LinkedField",
        "name": "sites",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Site",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BatterySiteListAllQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PaginatedSites",
        "kind": "LinkedField",
        "name": "sites",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Site",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bd1a39f0de40c8e68a0f21f4318f71db",
    "id": null,
    "metadata": {},
    "name": "BatterySiteListAllQuery",
    "operationKind": "query",
    "text": "query BatterySiteListAllQuery(\n  $filters: SiteFilter\n) {\n  sites(onlyProvisioningStatuses: Active, onlyWithValidBatteries: true, pageSize: 10000, filters: $filters) {\n    data {\n      devices {\n        data {\n          id\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d5f256c1a8675ccf9805533793e352a4";

export default node;
