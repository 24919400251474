/**
 * @generated SignedSource<<66220d4d31ecf938ed42dc0fd2144cac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BatteryTaskState = "Aborted" | "Failed" | "InProgress" | "Inconclusive" | "PartialFail" | "PartialPass" | "Passed" | "Scheduled" | "%future added value";
export type BatteryTestType = "Capacity" | "Custom" | "Quick" | "%future added value";
export type TaskRepeat = "BiMonthly" | "Monthly" | "Never" | "Quarterly" | "%future added value";
export type TaskState = "Cancelled" | "Completed" | "InProgress" | "Scheduled" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BatteryTestCardContent_task$data = {
  readonly abortedUser?: {
    readonly email: string | null;
    readonly name: string | null;
  } | null;
  readonly cancelledTime: string | null;
  readonly cancelledUser: {
    readonly email: string | null;
    readonly name: string | null;
  } | null;
  readonly commencedTime: string | null;
  readonly completedTime: string | null;
  readonly createdTime: string;
  readonly creator: {
    readonly email: string | null;
    readonly name: string | null;
  };
  readonly devices: {
    readonly data: ReadonlyArray<{
      readonly site: {
        readonly name: string;
      };
    }>;
    readonly total: number;
  };
  readonly name: string | null;
  readonly overallState: TaskState;
  readonly schedule?: {
    readonly repeat: TaskRepeat;
    readonly time: string;
  };
  readonly testState?: BatteryTaskState;
  readonly type?: BatteryTestType;
  readonly " $fragmentSpreads": FragmentRefs<"TaskProgressBar_task">;
  readonly " $fragmentType": "BatteryTestCardContent_task";
};
export type BatteryTestCardContent_task$key = {
  readonly " $data"?: BatteryTestCardContent_task$data;
  readonly " $fragmentSpreads": FragmentRefs<"BatteryTestCardContent_task">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BatteryTestCardContent_task",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "testState",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "abortedUser",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TaskSchedule",
          "kind": "LinkedField",
          "name": "schedule",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "time",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "repeat",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TaskProgressBar_task"
        }
      ],
      "type": "BatteryTest",
      "abstractKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "PaginatedDevices",
      "kind": "LinkedField",
      "name": "devices",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Device",
          "kind": "LinkedField",
          "name": "data",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Site",
              "kind": "LinkedField",
              "name": "site",
              "plural": false,
              "selections": [
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "overallState",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commencedTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completedTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cancelledTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "cancelledUser",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Task",
  "abstractKey": "__isTask"
};
})();

(node as any).hash = "a1e917a383fceaf5d1d61b80859bc929";

export default node;
