import React, { FC } from 'react';

import { MultipleSelect } from 'components/MultipleSelect';
import { CommonFilterProps } from 'filters/common';

import { AlertOriginator, AlertOriginatorFilter } from '../types';

export type AlertOriginatorFilterUIProps = CommonFilterProps<AlertOriginatorFilter[]>;

export const AlertOriginatorFilterUI: FC<AlertOriginatorFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
}) => {
    const options: AlertOriginatorFilter[] = [{ id: AlertOriginator.Device, value: 'Device' }];

    return (
        <MultipleSelect
            options={options}
            active={current}
            title='Filter by originator'
            renderItem={item => item.value}
            renderItemSimple={item => item.value}
            areItemsEqual={(itemA, itemB) => itemA.value === itemB.value}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onClose={onClose}
        />
    );
};
