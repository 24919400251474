import React, { FC, ReactNode } from 'react';
import { useFragment } from 'react-relay';

import {
    BatteryChargingIcon,
    CheckCircledOpenIcon,
    CircleAlertIcon,
    HistoryItem,
    History as HistoryLine,
    LoadIcon,
    Menu,
    MetricTile,
    NavigateTo,
    PowerIcon,
    RectifierIcon,
    Tooltip,
    useExtendedNavigate,
} from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import humanizeDuration from 'humanize-duration';
import { useUserPermissions } from 'lib/auth';
import { getDateTimeFormat } from 'lib/dateFormatter';
import { createHistoryItem } from 'lib/history';
import { Paths } from 'lib/routes';
import { renderDeviceHealthCell } from 'lib/table-columns';
import { DateTime } from 'luxon';
import { Operation, SelectedMetric } from 'views/explore/types';

import { makeLinkToMetric } from '../../../../explore/lib/link';
import { DeviceOverview_device$key } from './__generated__/DeviceOverview_device.graphql';

interface TileStatus {
    icon: ReactNode;
    tooltip?: string;
    link?: string;
    iconStroke?: string;
}

interface MetricInfo {
    value?: number;
    tooltip?: string;
    unit?: string;
    link?: NavigateTo;
    label?: string;
}

export interface DeviceOverviewProps {
    device: DeviceOverview_device$key;
    deviceId: string;
}

export const DeviceOverview: FC<DeviceOverviewProps> = ({ device, deviceId }) => {
    const { hasAssetsWrite, hasAssetsRead, hasTasksRead } = useUserPermissions();
    const navigate = useExtendedNavigate();
    const data = useFragment(Fragment, device);

    let lastUpdatedTooltip: string;
    let lastUpdatedDescription: string;
    let systemType: string;

    if (data.dualPlaneCompanion?.device) {
        systemType = 'Dual plane';
    } else {
        systemType = 'Single plane';
    }

    if (data.lastUpdate) {
        lastUpdatedTooltip = getDateTimeFormat(data.lastUpdate as string);
        const lastUpdatedDate = DateTime.fromISO(data.lastUpdate);
        const timeSinceLastUpdate = lastUpdatedDate.diffNow().negate();

        const timeSinceLastUpdateInSeconds = Math.round(timeSinceLastUpdate.as('seconds'));

        lastUpdatedDescription = `${humanizeDuration(timeSinceLastUpdateInSeconds * 1000, { largest: 2 })} ago`;
    } else {
        lastUpdatedTooltip = 'This device has never been collected';
        lastUpdatedDescription = 'never';
    }

    const historyItems: HistoryItem[] = data.activityLogs.data.map(log =>
        createHistoryItem(log, hasAssetsRead, hasTasksRead)
    );

    const navigateToHistoryPage = () => {
        navigate({
            pathname: Paths.SiteViewViewSiteDevicePage,
            params: {
                siteId: data.site.id,
                deviceId,
                page: 'history',
            },
        });
    };

    return (
        <div>
            <div className='grid grid-cols-2 gap-2 '>
                <div className='mb-2'>
                    {renderDeviceHealthCell(data.health ?? 'Unknown')}
                    <div className='text-xs text-customEggplantWhite'>
                        <span>Last collected</span>{' '}
                        <Tooltip content={lastUpdatedTooltip}>
                            <span>{lastUpdatedDescription}</span>
                        </Tooltip>
                    </div>
                </div>
                <div className='mb-2'>
                    <div className='float-right'>
                        <Menu
                            id={`device-menu-${deviceId}`}
                            menuItems={[
                                {
                                    name: hasAssetsWrite ? 'Edit device' : 'View device',
                                    onClick: () => navigate({ pathname: Paths.EditDevice, params: { id: deviceId } }),
                                },
                            ]}
                            variant='small'
                        />
                    </div>
                    <div className='text-xs'>
                        <div>
                            <span className='font-CynthoNext-Light'>System type: </span>
                            <span className='font-CynthoNext-Regular'>{systemType ?? '-'}</span>
                        </div>
                        <div>
                            <span className='font-CynthoNext-Light'>Firmware version: </span>
                            <span className='font-CynthoNext-Regular'>{data.firmware?.name ?? '-'}</span>
                        </div>
                        <div>
                            <span className='font-CynthoNext-Light'>Serial number: </span>
                            <span className='font-CynthoNext-Regular'>{data.serialNumber || '-'}</span>
                        </div>
                    </div>
                </div>
                <MetricTile
                    title={{ icon: <PowerIcon />, label: 'AC Power', link: '../ac-power' }}
                    metric={getMetricInfo(
                        data.acPower.metrics.latestApparentPower,
                        'Apparent power',
                        deviceId,
                        {
                            metric: 'RectifierMainsApparentPower',
                            op: Operation.Average,
                        },
                        'VA',
                        'kVA'
                    )}
                    status={getTileStatus(data.acPowerAlerts.total, 'ac-power')}
                />
                <MetricTile
                    title={{ icon: <RectifierIcon />, label: 'Rectifiers', link: '../rectifiers' }}
                    metric={getMetricInfo(
                        data.rectifier?.metrics.latestOutputPower,
                        'Output power',
                        deviceId,
                        {
                            metric: 'RectifierPower',
                            op: Operation.Average,
                        },
                        'W',
                        'kW'
                    )}
                    status={getTileStatus(data.rectifierAlerts.total, 'rectifier')}
                />
                <MetricTile
                    title={{ icon: <BatteryChargingIcon />, label: 'Batteries', link: '../batteries' }}
                    metric={getMetricInfo(
                        data.battery?.metrics.latestVoltage,
                        'System voltage',
                        deviceId,
                        {
                            metric: 'BatteryVoltage',
                            op: Operation.Average,
                        },
                        'V'
                    )}
                    status={getTileStatus(data.batteryAlerts.total, 'battery')}
                />
                <MetricTile
                    title={{ icon: <LoadIcon />, label: 'Load', link: '../load' }}
                    metric={getMetricInfo(
                        data.load.metrics.latestPower,
                        'Consumed power',
                        deviceId,
                        {
                            metric: 'LoadPower',
                            op: Operation.Average,
                        },
                        'W',
                        'kW'
                    )}
                    status={getTileStatus(data.loadAlerts.total, 'load')}
                />
            </div>
            <div className='pt-4'>
                <HistoryLine
                    title='History'
                    lineStyle='short'
                    itemGroups={[{ items: historyItems }]}
                    moreItems={data.activityLogs.hasMore}
                    loadMoreCallback={navigateToHistoryPage}
                />
            </div>
        </div>
    );
};

const Fragment = graphql`
    fragment DeviceOverview_device on Device {
        site {
            id
        }
        name
        lastUpdate
        health
        dualPlaneCompanion {
            device {
                name
            }
        }
        firmware {
            name
        }
        serialNumber
        acPower {
            metrics {
                latestApparentPower(unit: VoltAmp)
            }
        }
        battery {
            metrics {
                latestVoltage
            }
        }
        load {
            metrics {
                latestPower(unit: Watt)
            }
        }
        rectifier {
            metrics {
                latestOutputPower(unit: Watt)
            }
        }
        acPowerAlerts: alerts(filters: { domain: { value: AcPower } }) {
            total
        }
        batteryAlerts: alerts(filters: { domain: { value: Battery } }) {
            total
        }
        loadAlerts: alerts(filters: { domain: { value: Load } }) {
            total
        }
        rectifierAlerts: alerts(filters: { domain: { value: Rectifier } }) {
            total
        }
        activityLogs(
            types: [
                "device_added"
                "device_edited"
                "device_removed"
                "critical"
                "major"
                "offline"
                "high"
                "test_initiate"
            ]
            limit: 5
        ) {
            hasMore
            total
            data {
                source
                type
                timestamp
                user {
                    username
                    name
                }
                changes {
                    field
                    oldValue
                    newValue
                }
                link {
                    __typename
                    ... on Device {
                        id
                        name
                    }
                    ... on Alert {
                        severity
                        category
                        message
                        isActive
                        device {
                            id
                            name
                        }
                    }
                    ... on ACPowerEvent {
                        worstStatus
                        duration
                        affectedAllFeeds
                        affectedFeeds {
                            id
                            status
                        }
                        device {
                            id
                            name
                        }
                    }
                    ... on DeviceBatteryTestResults {
                        id
                        commencedTime
                        state
                        task {
                            id
                            name
                            type
                            testState
                            abortedUser {
                                username
                                name
                            }
                        }
                        device {
                            id
                            name
                        }
                    }
                    ... on BatteryTest {
                        id
                        commencedTime
                        taskName: name
                    }
                }
            }
        }
    }
`;

function getTileStatus(totalAlerts: number, domain: string): TileStatus {
    let icon: ReactNode;
    let colour: string;
    let tooltip: string;
    if (totalAlerts > 0) {
        icon = <CircleAlertIcon />;
        colour = 'text-customCoral';
        if (totalAlerts === 1) {
            tooltip = 'See 1 insight';
        } else {
            tooltip = `See ${totalAlerts} insights`;
        }
    } else {
        icon = <CheckCircledOpenIcon />;
        colour = 'text-customPine';
        tooltip = 'No active insights';
    }

    return {
        icon,
        iconStroke: colour,
        tooltip,
        // TODO: Pre-filter insights by domain
        link: '../insights',
    };
}

function getMetricInfo(
    value: number | null | undefined,
    label: string,
    deviceId: string,
    metric: SelectedMetric,
    unit: string,
    largerUnit?: string
): MetricInfo {
    const link = makeLinkToMetric(deviceId, metric);

    if (value == null) {
        return {
            value: undefined,
            unit,
            label,
            link,
        };
    }

    if (largerUnit && value > 1000) {
        return {
            value: value / 1000,
            unit: largerUnit,
            label,
            link,
        };
    }

    return {
        value: largerUnit ? Math.round(value) : value,
        unit,
        label,
        link,
    };
}
