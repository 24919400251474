import React, { FC } from 'react';
import { useRelayEnvironment } from 'react-relay';
import { fetchQuery } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { FilterSearchSelect } from 'components/FilterSearchSelect';
import { IEnvironment } from 'relay-runtime';

import { CommonFilterProps } from '../../common/types';
import { DeviceNameFilterResult } from '../../device/components/DeviceNameFilterResult';
import { DeviceNameFilter } from '../../device/types';
import { SiteDeviceNameFilterUI_deviceNameSearchQuery } from './__generated__/SiteDeviceNameFilterUI_deviceNameSearchQuery.graphql';

export type SiteDeviceNameFilterUIProps = CommonFilterProps<DeviceNameFilter[]>;

export const SiteDeviceNameFilterUI: FC<SiteDeviceNameFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    const environment = useRelayEnvironment();
    return (
        <FilterSearchSelect
            active={current}
            title='Filter by name'
            placeholder='Enter device name'
            renderItem={item => <DeviceNameFilterResult filter={item} />}
            renderItemSimple={item => item.name}
            showBack={hasPrevious}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onSearch={input => doSearch(environment, input, 5)}
            onClose={onClose}
            createDefaultItem={input => {
                if (input.trim().length > 0) {
                    return createWildcardFilter(input);
                } else {
                    return null;
                }
            }}
        />
    );
};

async function doSearch(environment: IEnvironment, input: string, maxResults: number): Promise<DeviceNameFilter[]> {
    try {
        const results = await fetchQuery<SiteDeviceNameFilterUI_deviceNameSearchQuery>(
            environment,
            graphql`
                query SiteDeviceNameFilterUI_deviceNameSearchQuery($input: String!, $maxResults: Int!) {
                    sites(filters: { deviceName: [{ value: $input }] }, pageSize: $maxResults) {
                        data {
                            id
                            name
                            address {
                                state
                            }
                            devices {
                                data {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
            `,
            {
                input,
                maxResults: maxResults - 1,
            }
        ).toPromise();

        const sites = results?.sites.data ?? [];

        const output: DeviceNameFilter[] = [];
        sites.forEach(site => {
            const devices = site.devices.data.map<DeviceNameFilter>(device => ({
                type: 'result',
                id: site.id,
                name: device.name,
                siteName: site.name,
                state: site.address.state,
            }));

            output.push(...devices);
        });

        if (input.trim().length > 0) {
            if (output.length >= maxResults) {
                output[output.length - 1] = createWildcardFilter(input);
            } else {
                output.push(createWildcardFilter(input));
            }
        }

        return output;
    } catch (error) {
        return [];
    }
}

function createWildcardFilter(input: string): DeviceNameFilter {
    return {
        type: 'special',
        name: input.trim(),
        wildcard: true,
    };
}
