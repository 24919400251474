import React, { FC } from 'react';

import { SkeletonCard, SkeletonText } from 'components/Skeleton';

export const DeviceOverviewSkeleton: FC = () => {
    return (
        <div className='grid grid-cols-2 gap-2'>
            <div>
                <SkeletonText characters={10} />
                <SkeletonText characters={20} className='text-xs' />
            </div>
            <div className='text-xs'>
                <SkeletonText characters={30} />
                <SkeletonText characters={24} />
            </div>
            <SkeletonCard height={145} />
            <SkeletonCard height={145} />
            <SkeletonCard height={145} />
            <SkeletonCard height={145} />

            <div className='col-span-2'>
                <SkeletonText characters={10} size='subheading' />
                <SkeletonText characters={30} />
                <SkeletonText characters={30} />
                <SkeletonText characters={30} />
                <SkeletonText characters={30} />
                <SkeletonText characters={30} />
            </div>
        </div>
    );
};
