import React, { FC } from 'react';

import { DurationInput } from './DurationInput';
import { RunTimeFilter } from './common';

export interface CustomDurationProps {
    value: RunTimeFilter | null;
    onChange: (value: RunTimeFilter | null) => void;
}

export const CustomDuration: FC<CustomDurationProps> = ({ value, onChange }) => {
    const handleStartChange = (newValue: string) => {
        onChange({
            type: 'custom',
            range: [newValue, value?.range[1] ?? 'P0D'],
        });
    };

    const handleEndChange = (newValue: string) => {
        onChange({
            type: 'custom',
            range: [value?.range[0] ?? 'P0D', newValue],
        });
    };

    return (
        <div className='flex justify-between'>
            <DurationInput label='From' value={value?.range[0] ?? 'P0D'} onChange={value => handleStartChange(value)} />
            <DurationInput label='To' value={value?.range[1] ?? 'P0D'} onChange={value => handleEndChange(value)} />
        </div>
    );
};
