import React, { FC } from 'react';

import { MultipleSelect } from 'components/MultipleSelect';

import { CommonFilterProps } from '../../common/types';
import { DeviceStatusFilter } from '../types';

export type DeviceStatusFilterUIProps = CommonFilterProps<DeviceStatusFilter[]>;

export const DeviceStatusFilterUI: FC<DeviceStatusFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    const options: DeviceStatusFilter[] = [
        { id: 'Healthy', name: 'Healthy' },
        { id: 'Degraded', name: 'Degraded' },
        { id: 'Critical', name: 'Critical' },
        { id: 'Offline', name: 'Offline' },
        { id: 'Unknown', name: 'Unknown' },
    ];

    return (
        <MultipleSelect
            options={options}
            active={current}
            title='Filter by Device Status'
            renderItem={item => item.name}
            renderItemSimple={item => item.name}
            areItemsEqual={(itemA, itemB) => itemA.id === itemB.id}
            showBack={hasPrevious}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onClose={onClose}
        />
    );
};
