import React, { FC, useCallback, useRef } from 'react';
import { useRelayEnvironment } from 'react-relay';
import { useNavigate } from 'react-router-dom';

import { ThemedPageHeading, useToast } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import { PageHeader, useDocumentTitle } from 'components';
import { Formik, FormikHelpers } from 'formik';
import { FormikProvisionLayout } from 'layouts/FormikProvisionLayout';
import { Paths } from 'lib/routes';

import { SiteInformation } from './SiteInformation';
import { convertSiteFormStateToApi } from './lib/convert';
import { addNewSite, decodeSiteApiErrors } from './lib/saving';
import { SiteFormValues, createDefaultSiteFormValues } from './schema';
import { SiteValidationSchema } from './validation';

const DocumentTitle = 'Add Site';

export const AddSiteForm: FC = () => {
    const environment = useRelayEnvironment();
    const navigate = useNavigate();
    const shouldReset = useRef(false);

    useDocumentTitle(DocumentTitle);

    const { show } = useToast();

    const handleAdd = useCallback(
        async (values: SiteFormValues, { setErrors, setSubmitting, resetForm }: FormikHelpers<SiteFormValues>) => {
            setSubmitting(true);

            try {
                const newSite = convertSiteFormStateToApi(values);

                await addNewSite(newSite, environment);

                show({
                    text: 'Site succesfully added!',
                    variant: 'info',
                });

                if (shouldReset.current) {
                    shouldReset.current = false;
                    resetForm();
                } else {
                    navigate(Paths.Sites);
                }
            } catch (error) {
                if (Array.isArray(error)) {
                    const formErrors = decodeSiteApiErrors(error);
                    setErrors(formErrors);

                    show({
                        text: 'Unable to save site. Please correct the highlighted errors',
                        variant: 'error',
                    });
                } else {
                    captureException(error, scope => {
                        return scope;
                    });

                    show({
                        text: 'Unable to save site. Try again later',
                        variant: 'error',
                    });
                }
            }
        },
        [environment, navigate, show]
    );

    return (
        <div className='space-y-6'>
            <PageHeader />
            <ThemedPageHeading value={DocumentTitle} />
            <Formik
                initialValues={createDefaultSiteFormValues()}
                validationSchema={SiteValidationSchema}
                onSubmit={handleAdd}
            >
                <FormikProvisionLayout
                    type='site'
                    operation='add'
                    secondaryAction={() => {
                        shouldReset.current = true;
                    }}
                >
                    <SiteInformation />
                </FormikProvisionLayout>
            </Formik>
        </div>
    );
};
