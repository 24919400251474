import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { getDateTimeFormat } from 'lib/dateFormatter';

import { DeviceReport_ReportQuery$data } from '../__generated__/DeviceReport_ReportQuery.graphql';
import { LastCollectedTime_battery$key } from './__generated__/LastCollectedTime_battery.graphql';

interface LastCollectedTimeProps {
    device: DeviceReport_ReportQuery$data['device'];
}

export const LastCollectedTime: FC<LastCollectedTimeProps> = ({ device }) => {
    const result = useFragment<LastCollectedTime_battery$key>(
        graphql`
            fragment LastCollectedTime_battery on Device {
                lastUpdate
            }
        `,
        device
    );

    const deviceTS = result && result.lastUpdate ? getDateTimeFormat(result.lastUpdate as string) : 'Unknown';

    return <>{deviceTS}</>;
};
