import { set } from 'lodash';
import { IEnvironment, commitMutation } from 'relay-runtime';

import {
    DeviceBatteryUpdateIn,
    mutations_AddBatteryToDeviceMutation,
    mutations_AddBatteryToDeviceMutation$data,
    mutations_AddBatteryToDeviceMutation$variables,
} from '../../__generated__/mutations_AddBatteryToDeviceMutation.graphql';
import { DeviceIn, mutations_CreateDeviceMutation } from '../../__generated__/mutations_CreateDeviceMutation.graphql';
import {
    DeviceUpdateIn,
    mutations_EditDeviceMutation,
    mutations_EditDeviceMutation$variables,
} from '../../__generated__/mutations_EditDeviceMutation.graphql';
import { addBatteryToDeviceMutation, createDeviceMutation, editDeviceMutation } from '../../mutations';

export type { DeviceIn, DeviceUpdateIn };

export function editDevice(deviceId: string, device: DeviceUpdateIn, environment: IEnvironment): Promise<string> {
    const deviceUpdateVariables: mutations_EditDeviceMutation$variables = {
        id: deviceId,
        device,
    };

    return new Promise((resolve, reject) => {
        commitMutation<mutations_EditDeviceMutation>(environment, {
            mutation: editDeviceMutation,
            variables: deviceUpdateVariables,
            onCompleted: (response, error) => {
                if (response.editDevice?.id) {
                    resolve(response.editDevice.id);
                }

                if (response.editDevice?.problems) {
                    reject(response.editDevice.problems);
                }

                if (error) {
                    reject(error);
                }
            },
            onError: error => {
                reject(error);
            },
        });
    });
}

export function addNewDevice(device: DeviceIn, environment: IEnvironment): Promise<string> {
    return new Promise((resolve, reject) => {
        commitMutation<mutations_CreateDeviceMutation>(environment, {
            mutation: createDeviceMutation,
            variables: { device },
            onError: error => {
                reject(error);
            },
            onCompleted: (response, error) => {
                if (response.addDevice?.id) {
                    resolve(response.addDevice.id);
                }

                if (response.addDevice?.problems) {
                    reject(response.addDevice.problems);
                }

                if (error) {
                    reject(error);
                }
            },
        });
    });
}

export function addBatteryToDevice(
    deviceId: string,
    battery: DeviceBatteryUpdateIn,
    environment: IEnvironment
): Promise<string[]> {
    const variables: mutations_AddBatteryToDeviceMutation$variables = {
        deviceId: deviceId,
        battery: battery,
    };

    return new Promise((resolve, reject) => {
        commitMutation<mutations_AddBatteryToDeviceMutation>(environment, {
            mutation: addBatteryToDeviceMutation,
            variables,
            onError: error => {
                reject(error);
            },
            onCompleted: (response, error) => {
                if (response.editDevice?.id) {
                    resolve([response.editDevice.id]);
                }

                if (response.editDevice?.problems) {
                    reject(response.editDevice.problems);
                }

                if (error) {
                    reject(error);
                }
            },
        });
    });
}

type DeviceProblemResponse = NonNullable<
    NonNullable<mutations_AddBatteryToDeviceMutation$data['editDevice']>['problems']
>[number];

export function decodeDeviceApiErrors(problems: DeviceProblemResponse[]) {
    const errors = {};

    for (const problem of problems) {
        switch (problem.type) {
            case 'DuplicateName':
                set(errors, 'name', 'Name already used on this site');
                break;
            case 'MissingName':
                set(errors, 'name', 'Device name is a required field');
                break;
            case 'InvalidAttributeName':
                set(errors, 'attributes', 'One of the attributes has an invalid name');
                break;
            case 'InvalidBatteryVoltage':
                set(errors, 'batteries.minimumAllowedVoltage', 'Invalid Battery Voltage range');
                set(errors, 'batteries.maximumAllowedVoltage', 'Invalid Battery Voltage range');
                break;
            case 'InvalidDeviceType':
                set(errors, 'type', 'Invalid device type');
                break;
            case 'InvalidDualPlaneDevice':
                set(errors, 'companionReference', 'Device is not usable or does not exist');
                break;
            case 'InvalidFirmware':
                set(errors, 'firmwareVersion', 'Invalid firmware version');
                break;
            case 'InvalidBatteryType':
                set(errors, `batteries.strings[${problem.string}].type`, 'Battery type not usable or does not exist');
                break;
            case 'InvalidCellCount':
                set(errors, `batteries.strings[${problem.string}].cellsPerString`, 'Must be a positive whole number');
                break;
            case 'InvalidManufactureDate':
                set(
                    errors,
                    `batteries.strings[${problem.string}].manufactureDate`,
                    'If provided, must be set to a date before the install date'
                );
                break;
            case 'InvalidQuickTestCheckPercent':
                set(errors, 'batteries.quickTestCheckPercent', 'Must be between 1 and 100%');
                break;
            case 'InvalidQuickTestThreshold':
                set(errors, 'batteries.quickTestFailThreshold', 'Invalid value'); // FIXME: Should use a proper message
                break;
            case 'InvalidReserveTime':
                set(errors, 'batteries.reserveTime', '');
                break;
            case 'InvalidSite':
                set(errors, 'site', 'Site does not exist');
                break;
            case 'NotPossibleRightNow':
                set(
                    errors,
                    'monitorOnly',
                    'This cannot be changed at the current time. The device is busy with a task'
                );
                break;
            case 'EditNotAllowed':
            case 'InvalidDevice':
            case 'Skipped':
                // Only valid for importing
                break;
            case 'Noop':
                // Nothing to worry about
                break;
        }

        switch (problem.field) {
            case 'BasicPassword':
                set(errors, 'settings.webSettings.password', 'Missing or invalid password');
                break;
            case 'BasicUsername':
                set(errors, 'settings.webSettings.username', 'Missing username');
                break;
            case 'SnmpPort':
                set(errors, 'settings.snmpSettings.port', 'Must be between 1 and 65535');
                break;
            case 'SnmpV1ReadCommunity':
                set(errors, 'settings.snmpSettings.readOnlyCommunity', 'Missing community name');
                break;
            case 'SnmpV1WriteCommunity':
                set(errors, 'settings.snmpSettings.readWriteCommunity', 'Missing community name');
                break;
            case 'SnmpV3AuthPassphrase':
                set(errors, 'settings.snmpSettings.authPassphrase', 'Missing or invalid passphrase');
                break;
            case 'SnmpV3EngineId':
                set(errors, 'settings.snmpSettings.engineId', 'Invalid engine id');
                break;
            case 'SnmpV3PrivPassphrase':
                set(errors, 'settings.snmpSettings.privPassphrase', 'Missing or invalid passphrase');
                break;
            case 'SnmpV3User':
                set(errors, 'settings.snmpSettings.user', 'Missing user name');
                break;
            case 'UnknownProtocol':
            case 'MissingProtocol':
                // Technical error, ignore
                break;
        }
    }

    return errors;
}
