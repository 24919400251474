import React, { FC, PropsWithChildren, useState } from 'react';

import { Button, ChevronRightIcon, CrossIcon, PlusIcon } from '@accesstel/pcm-ui';

import { Form, useFormikContext } from 'formik';
import { capitalize } from 'lib/textFormatters';

import style from './style.module.css';

export interface FormikProvisionLayoutProps {
    type: string;
    secondaryAction?: () => void;
    operation?: 'add' | 'edit' | 'view';
}

export const FormikProvisionLayout: FC<PropsWithChildren<FormikProvisionLayoutProps>> = ({
    children,
    secondaryAction,
    type,
    operation = 'add',
}) => {
    const { isSubmitting } = useFormikContext();

    const [clickedButton, setClickedButton] = useState('none');

    const isView = operation === 'view';
    const isAdd = operation === 'add';

    return (
        <>
            <h1 className={`pl-4 ${style.note}`}>*Mandatory Fields</h1>
            <Form>
                {children}

                <div className={style.provision_grid}>
                    <div className={style.provision_buttons}>
                        {isView && (
                            <Button
                                type='button'
                                variant='white'
                                buttonText='Close'
                                onClick={e => {
                                    e.preventDefault();
                                    secondaryAction?.();
                                }}
                                disabled={isSubmitting}
                                icon={<CrossIcon />}
                            />
                        )}
                        {!isView && (
                            <Button
                                type='submit'
                                buttonText={`${isAdd ? 'Submit' : 'Save'} ${capitalize(type)}`}
                                onClick={e => {
                                    setClickedButton('primary');
                                }}
                                processing={clickedButton === 'primary' && isSubmitting}
                                disabled={isSubmitting}
                                icon={<ChevronRightIcon />}
                            />
                        )}
                        {!isView && (
                            <Button
                                type={isAdd ? 'submit' : 'button'}
                                buttonClasses='ml-8'
                                variant='white'
                                buttonText={isAdd ? 'Submit & Add Another' : 'Cancel'}
                                onClick={e => {
                                    setClickedButton('secondary');
                                    secondaryAction?.();
                                }}
                                processing={clickedButton === 'secondary' && isSubmitting}
                                disabled={isSubmitting}
                                icon={isAdd ? <PlusIcon /> : <CrossIcon />}
                            />
                        )}
                    </div>
                </div>
            </Form>
        </>
    );
};
