/**
 * The login view. Allows for the user to sign into the system.
 */
import React, { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { LogoStackedReversedExtended } from '@accesstel/pcm-ui';

import { captureException, captureMessage } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { Form, Formik, FormikHelpers } from 'formik';
import { logError } from 'lib/log';
import { useQuery } from 'lib/query-helpers';
import { parse } from 'querystring';

import { useDocumentTitle } from '../../../components';
import { AuthResultType, login, loginWithFederation, useInitialErrorMessage } from '../../../lib/auth';
import { LoginStageSettingsQuery } from './__generated__/LoginStageSettingsQuery.graphql';
import { DefaultLoginFormValues, LoginFormValidationSchema } from './schema';
import { LoginFormValues } from './schema';
import { FederatedSignInSection } from './sections/FederatedSignInSection';
import { LocalSignInSection } from './sections/LocalSignInSection';

export interface LoginStageProps {
    onLoginComplete: () => void;
    onNeedsPasswordReset: () => void;
    onNeedsForgottenPasswordReset: (username: string) => void;
}

export const LoginStage: FC<LoginStageProps> = ({
    onLoginComplete,
    onNeedsPasswordReset,
    onNeedsForgottenPasswordReset,
}) => {
    useDocumentTitle('Sign in');
    const location = useLocation();
    const locationWithSearch = useLocation();
    const initialErrorMessage = useInitialErrorMessage();

    const [errorMessage, setErrorMessage] = useState(initialErrorMessage);

    let searchOptions;
    if (locationWithSearch.search.startsWith('?')) {
        searchOptions = parse(locationWithSearch.search.substr(1));
    } else {
        searchOptions = parse(locationWithSearch.search);
    }

    const { data: loginSettings, error } = useQuery<LoginStageSettingsQuery>(
        graphql`
            query LoginStageSettingsQuery {
                settings {
                    login {
                        federation {
                            id
                            displayName
                            default
                        }
                    }
                }
            }
        `,
        {}
    );

    // Callbacks
    const doLogin = React.useCallback(
        async (
            values: LoginFormValues,
            { setSubmitting, setFieldError, setStatus }: FormikHelpers<LoginFormValues>
        ) => {
            setSubmitting(true);
            setErrorMessage('');
            setStatus('login');

            try {
                const [result, message] = await login(values.username, values.password);
                switch (result) {
                    case AuthResultType.FailureOther:
                        setErrorMessage('Unable to log you in.');
                        captureMessage(`Abnormal login result ${message}`);
                        logError('Login failed: ', message);
                        break;
                    case AuthResultType.FailureBadAuth:
                        setErrorMessage('Either username or password is incorrect.');
                        // FIXME: this is a bit of a hack because you can't make the fields
                        //        show as an error state unless you have an error message
                        setFieldError('username', ' ');
                        setFieldError('password', ' ');
                        break;
                    case AuthResultType.SuccessNeedNewPassword:
                        onNeedsPasswordReset();
                        break;
                    case AuthResultType.SuccessNeedPasswordReset:
                        onNeedsForgottenPasswordReset(values.username);
                        break;
                    case AuthResultType.Success:
                        onLoginComplete();
                        break;
                }
            } catch (error) {
                setErrorMessage('Something went wrong. Please try again later.');
                captureException(error);
            }
        },
        [onNeedsPasswordReset, onNeedsForgottenPasswordReset, onLoginComplete]
    );

    const federationOptions = loginSettings?.settings.login.federation ?? [];

    if (federationOptions.length > 0) {
        const defaultOption = federationOptions.find(option => option.default);
        if (defaultOption && !initialErrorMessage) {
            // Automatically begin the login process with default providers unless they just signed out
            if (location.pathname !== '/logout' && !('noRedirect' in searchOptions)) {
                loginWithFederation(defaultOption.id).catch(error => {
                    logError('Failed to initiate federated login', error);
                });
            }
        }
    }

    const isAuthError =
        error && (error.message.includes('auth.not-authenticated') || error.message.includes('auth.not-authorized'));

    return (
        <>
            <div className='login-view'>
                <div className='login-layout'>
                    {error && !isAuthError && (
                        <div className='login-error-banner'>Unable to connect to server. Please try again later.</div>
                    )}
                    <div className='login-layout-col-logo'>
                        <img src={LogoStackedReversedExtended} alt='accesstel logo' width={280} height={280} />
                    </div>
                    <div className='login-layout-col-form'>
                        <Formik
                            initialValues={DefaultLoginFormValues}
                            initialErrors={{ error: initialErrorMessage ?? undefined }}
                            initialTouched={{ error: true }}
                            validationSchema={LoginFormValidationSchema}
                            onSubmit={doLogin}
                        >
                            <Form className='space-y-8'>
                                <div>
                                    <h1 className='text-3xl font-CynthoNext-SemiBold'>Sign in</h1>
                                    <p>Please login to your account.</p>
                                </div>
                                <LocalSignInSection errorMessage={errorMessage} />
                                {federationOptions.length > 0 && (
                                    <FederatedSignInSection options={federationOptions} onError={setErrorMessage} />
                                )}
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    );
};
