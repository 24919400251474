import React, { Dispatch, FC, useCallback, useState } from 'react';

import { FilterContainer } from '@accesstel/pcm-ui';

import { FilterAction, FilterActionType, FilterDefinition, FilterState } from 'filters/common';

import { FilterOptions } from './FilterOptions';

export interface AddFilterPaneProps {
    state: FilterState<string, Record<string, unknown>>;
    updateState: Dispatch<FilterAction<never, never>>;
    onClose: () => void;
}

export const AddFilterPane: FC<AddFilterPaneProps> = ({ onClose, state, updateState }) => {
    const [selectedDefinition, setSelectedDefinition] = useState<FilterDefinition<never> | null>(null);

    const onSelect = useCallback((definition: FilterDefinition<never>) => {
        setSelectedDefinition(definition);
    }, []);

    // hide 'single' selection filters if they are already selected
    const multiFilters = state.filterDefinitions.filter(def => def.type === 'multi');
    const singleFilters = state.filterDefinitions.filter(def => def.type === 'single');
    const unappliedSingleFilters = singleFilters.filter(def => {
        if (def.column === undefined) {
            // check for non-column filters
            return state.extraFilters[def.name] === null;
        }

        return state.columnValues[def.column] === null;
    });

    const definitions = [...multiFilters, ...unappliedSingleFilters];

    if (!selectedDefinition) {
        return (
            <FilterContainer
                title='Add Filter'
                hideClearButton
                hideConfirmButton
                onClose={onClose}
                primaryContent={
                    <FilterOptions definitions={definitions as FilterDefinition<never>[]} onSelect={onSelect} />
                }
            />
        );
    } else {
        if (selectedDefinition!.type === 'multi') {
            return React.createElement(selectedDefinition!.component(), {
                current: [],
                hasPrevious: true,
                onClearOrBack: () => {
                    setSelectedDefinition(null);
                },
                onSubmit: value => {
                    updateState({
                        type: FilterActionType.AddApply,
                        definition: selectedDefinition!,
                        value,
                    });
                    updateState({ type: FilterActionType.HideAll });
                },
                onClose,
            });
        } else {
            return React.createElement(selectedDefinition!.component(), {
                current: null,
                hasPrevious: true,
                onClearOrBack: () => {
                    setSelectedDefinition(null);
                },
                onSubmit: value => {
                    updateState({
                        type: FilterActionType.AddApply,
                        definition: selectedDefinition!,
                        value,
                    });
                    updateState({ type: FilterActionType.HideAll });
                },
                onClose,
            });
        }
    }
};
