import React from 'react';
import { useFragment } from 'react-relay';

import { Change, SmallSparkline, TimeseriesPoint } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';

import { CommonPropsWithParentQuery, asChange } from '../common';
import { CurrentSparkline_battery$key } from './__generated__/CurrentSparkline_battery.graphql';

export function CurrentSparkline({ metrics }: CommonPropsWithParentQuery): JSX.Element {
    const result = useFragment<CurrentSparkline_battery$key>(
        graphql`
            fragment CurrentSparkline_battery on DeviceBatteryMetrics {
                latestCurrent
                current {
                    values {
                        timestamp
                        value
                    }
                    trend {
                        state
                    }
                }
            }
        `,
        metrics
    );

    const data = result?.current ?? {
        trend: {
            state: 'Flat',
        },
        values: [],
    };

    const history: TimeseriesPoint[] = data.values.map(value => ({
        date: new Date(value.timestamp as string),
        value: value.value,
    }));

    return (
        <SmallSparkline
            history={history}
            value={result?.latestCurrent ?? null}
            unit='A'
            title='Battery current'
            decimals={1}
            change={data.trend ? asChange(data.trend.state) : Change.Flat}
        />
    );
}
