import React, { FC } from 'react';

import { AttributeFilter } from '../types';

export interface AttributeFilterResultProps {
    filter: AttributeFilter;
}

export const AttributeFilterResult: FC<AttributeFilterResultProps> = ({ filter }) => {
    if (filter.wildcard) {
        return (
            <div className='flex flex-row items-baseline space-x-2'>
                <span className='text-customEggplantWhite'>Matching</span>
                <span>{filter.value}</span>
            </div>
        );
    } else {
        return <div className='flex flex-row items-baseline space-x-2'>{filter.value}</div>;
    }
};
