import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { useUserPermissions } from 'lib/auth';
import { ErrorNotFound } from 'views/ErrorPage/ErrorNotFound';

export interface AuthenticatedSectionProps {
    administrator?: boolean;
    assetsRead?: boolean;
    assetsWrite?: boolean;
    tasksRead?: boolean;
    tasksWrite?: boolean;
}

export const AuthenticatedSection: FC<AuthenticatedSectionProps> = requiredPermissions => {
    const permissions = useUserPermissions();

    let allowed = true;

    if (requiredPermissions.administrator && !permissions.hasAdministration) {
        allowed = false;
    }

    if (requiredPermissions.assetsRead && !permissions.hasAssetsRead) {
        allowed = false;
    }

    if (requiredPermissions.assetsWrite && !permissions.hasAssetsWrite) {
        allowed = false;
    }

    if (requiredPermissions.tasksRead && !permissions.hasTasksRead) {
        allowed = false;
    }

    if (requiredPermissions.tasksWrite && !permissions.hasTasksWrite) {
        allowed = false;
    }

    if (!allowed) {
        return <ErrorNotFound />;
    }

    return <Outlet />;
};
