import { Dispatch, useEffect } from 'react';
import { useRelayEnvironment } from 'react-relay';

import { AttributeFilter, FilterAction, FilterActionType, FilterState, RangeFilter, useFilterReducer } from '../common';
import { loadDynamicFilters } from './dynamic';
import { BatteryTypeColumnFilterMap, DefaultValues, StaticBatteryTypeFilterDefinitions } from './settings';
import { BatteryTypeTableColumn } from './types';

export type BatteryTypeFilterState = FilterState<BatteryTypeTableColumn, BatteryTypeColumnFilterMap>;
export type BatteryTypeFilterAction = FilterAction<BatteryTypeColumnFilterMap, BatteryTypeTableColumn>;

export function useBatteryTypeFilter(): [BatteryTypeFilterState, Dispatch<BatteryTypeFilterAction>] {
    const [state, updateState] = useFilterReducer<
        BatteryTypeTableColumn,
        BatteryTypeColumnFilterMap,
        BatteryTypeFilterState,
        BatteryTypeFilterAction
    >('battery-type-filter', DefaultValues, StaticBatteryTypeFilterDefinitions, {});

    const environment = useRelayEnvironment();

    useEffect(() => {
        loadDynamicFilters(environment).then(definitions => {
            updateState({
                type: FilterActionType.UpdateFilterTypes,
                definitions: [...StaticBatteryTypeFilterDefinitions, ...definitions],
            });
        });
    }, [environment, updateState]);

    return [state, updateState];
}

export function batteryTypeToFilterObject(filters: BatteryTypeFilterState): Record<string, unknown> {
    const output: Record<string, unknown> = {};
    const {
        Manufacturer: manufacturer,
        Model: model,
        Capacity: capacity,
        Technology: technology,
        CapacityRating: capacityRating,
        ReferenceTemperature: referenceTemperature,
        CellsPerBloc: cellsPerBloc,
        PeukertsConstant: peukertsConstant,
    } = filters.columnValues;

    if (manufacturer.length > 0) {
        output.manufacturer = manufacturer.map(filter => ({ value: filter.name }));
    }

    if (model.length > 0) {
        output.model = model.map(filter => ({ value: filter.name }));
    }

    if (technology.length > 0) {
        output.technology = technology.map(filter => filter.id);
    }

    if (capacity) {
        const roundedCapacity: RangeFilter = { min: Math.floor(capacity.min), max: Math.ceil(capacity.max) };
        output.capacity = roundedCapacity;
    }

    if (capacityRating) {
        output.cellCapacityRating = capacityRating;
    }

    if (referenceTemperature) {
        output.cellReferenceTemperature = referenceTemperature;
    }

    if (cellsPerBloc) {
        output.cellsPerBloc = cellsPerBloc;
    }

    if (peukertsConstant) {
        output.peukertsConstant = peukertsConstant;
    }

    let attributeFilters: unknown[] | undefined;

    for (const [definitionName, values] of Object.entries(filters.extraFilters)) {
        const definition = filters.filterDefinitions.find(definition => definition.name === definitionName);
        if (!definition) {
            continue;
        }

        switch (definition.category) {
            case 'Attribute': {
                const filterObjects = values as AttributeFilter[];
                if (filterObjects.length > 0) {
                    if (!attributeFilters) {
                        attributeFilters = [];
                    }
                    attributeFilters.push({
                        name: definition.attributeName,
                        filters: filterObjects,
                    });
                }
                break;
            }
        }
    }

    if (attributeFilters && attributeFilters.length > 0) {
        output.attributes = attributeFilters;
    }

    return output;
}
