import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { BarDataType, LoadableContentArea, StackedHorizontalBar } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';

import { DeviceHealth, PowerControllersHorizontalBar$key } from './__generated__/PowerControllersHorizontalBar.graphql';
import style from './style.module.css';

export interface PowerControllersHorizontalBarProps {
    data: PowerControllersHorizontalBar$key | null;
    error?: boolean;
    retry: () => void;
}

export const PowerControllersHorizontalBar: FC<PowerControllersHorizontalBarProps> = ({ data, error, retry }) => {
    const results = useFragment<PowerControllersHorizontalBar$key>(
        graphql`
            fragment PowerControllersHorizontalBar on Query {
                devices {
                    total
                }
                globalDeviceHealth {
                    status
                    count
                }
            }
        `,
        data
    );

    let runningTotal = 0;
    let unknownTotal = 0;

    return (
        <LoadableContentArea
            className={style.power_cont_bar}
            variant='framed'
            data={results}
            error={!!error}
            onRetry={retry}
            render={props => {
                const data: BarDataType[] = [];

                if (props) {
                    props.globalDeviceHealth.forEach((status: { count: number; status: DeviceHealth | null }) => {
                        if (status.status !== 'Unknown') {
                            runningTotal += status.count;

                            data.push({
                                label: status.status ?? '',
                                value: status.count,
                                bgClass: healthStringToColour(status.status ?? ''),
                            });
                        } else {
                            unknownTotal = status.count;
                        }
                    });

                    unknownTotal = props.devices.total - runningTotal + unknownTotal;
                    if (unknownTotal >= 1) {
                        data.push({
                            label: 'Unknown',
                            value: unknownTotal,
                            bgClass: healthStringToColour('Unknown'),
                        });
                    }
                }
                return (
                    <StackedHorizontalBar
                        data={data}
                        valueFormatter={valueFormatter}
                        labelFormatter={labelFormatter}
                        emptyLabel='NO POWER CONTROLLERS ADDED'
                    />
                );
            }}
        />
    );
};

function valueFormatter(value: number | null): string {
    if (value === null) {
        return '';
    }
    if (value === 1) {
        return `${value.toLocaleString()} Device`;
    } else {
        return `${value.toLocaleString()} Devices`;
    }
}
function labelFormatter(label: string | null): string {
    if (label === null) {
        return '';
    }

    return label.toUpperCase();
}
function healthStringToColour(status: string): string {
    switch (status) {
        case 'Healthy':
            return 'bg-customEggplantCoral';
        case 'Degraded':
            return 'bg-customWhiteCoral';
        case 'Critical':
            return 'bg-customMustard';
        case 'Offline':
            return 'bg-customCoral';
        case 'Unknown':
            return 'bg-customWhiteEggplant';
        default:
            return '';
    }
}
