import React, { FC, Suspense } from 'react';
import { PreloadedQuery } from 'react-relay';
import { useLoaderData, useParams } from 'react-router-dom';

import { DefaultTimeRange } from '../../settings';
import { LoadContent } from './LoadContent';
import { LoadSkeleton } from './LoadSkeleton';
import { LoadContentQuery } from './__generated__/LoadContentQuery.graphql';

export const Load: FC = () => {
    const queryRef = useLoaderData() as PreloadedQuery<LoadContentQuery> | null;
    const { deviceId } = useParams() as { deviceId: string };

    return (
        <>
            {!queryRef && <LoadSkeleton />}
            {queryRef && (
                <Suspense fallback={<LoadSkeleton />}>
                    <LoadContent key={deviceId} queryRef={queryRef} timeRange={DefaultTimeRange} deviceId={deviceId} />
                </Suspense>
            )}
        </>
    );
};
