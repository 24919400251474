import { DependencyList, useEffect, useRef } from 'react';

import { logError } from './log';

type AsyncEffectCallback = () => Promise<void>;

export function usePrevious<T>(value: T): T {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ref: any = useRef<T>();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}

export function useAsyncEffect(effect: AsyncEffectCallback, deps?: DependencyList): void {
    useEffect(() => {
        effect().catch(error => {
            logError('async effect failed', error);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}
