import { useCallback, useEffect, useState } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';

import { Suggestion } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { SUGGESTIONS_LIMIT } from 'lib/provision';

import {
    AttributeLocation,
    ProvisioningStatus,
    attributeNameSuggestQuery,
} from './__generated__/attributeNameSuggestQuery.graphql';

export type AttributeNameSuggestion = Suggestion;

type OptionalSuggestions = AttributeNameSuggestion[] | undefined;
type ClearFunction = () => void;

export interface AttributeNameSuggestOptions {
    limit?: number;
    status?: ProvisioningStatus[];
    only?: AttributeLocation[];
}

export function useSuggestAttributeName(
    search: string,
    options: AttributeNameSuggestOptions = {}
): [OptionalSuggestions, ClearFunction] {
    const { limit = SUGGESTIONS_LIMIT, status = [], only = [] } = options;

    const environment = useRelayEnvironment();
    const [nameSuggestions, setNameSuggestions] = useState<AttributeNameSuggestion[] | undefined>([]);

    useEffect(() => {
        fetchQuery<attributeNameSuggestQuery>(
            environment,
            graphql`
                query attributeNameSuggestQuery(
                    $name: String!
                    $only: [AttributeLocation!]
                    $status: [ProvisioningStatus!]
                    $limit: Int
                ) {
                    attributeNames(search: $name, only: $only, status: $status, limit: $limit)
                }
            `,
            {
                name: search,
                status,
                only,
                limit,
            }
        )
            .toPromise()
            .then(data => {
                const suggestions: AttributeNameSuggestion[] =
                    data?.attributeNames.map(type => ({ id: type, displayName: type })) ?? [];
                // append the new type to the suggestions
                if (search.length > 0 && !data?.attributeNames.includes(search)) {
                    suggestions.push({
                        id: search,
                        isNew: true,
                    });
                }
                setNameSuggestions(suggestions);
            });
        // only and status which is an array will always be seen as changed in every render which causes an infinite loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, limit, environment]);

    const clear = useCallback(() => {
        setNameSuggestions(undefined);
    }, []);

    return [nameSuggestions, clear];
}
