/**
 * @generated SignedSource<<64f89cfdbf66d861e54c62c93d8ba875>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BatteryTestType = "Capacity" | "Custom" | "Quick" | "%future added value";
export type RepeatTaskModal_TaskDetailsQuery$variables = {
  id: string;
};
export type RepeatTaskModal_TaskDetailsQuery$data = {
  readonly task: {
    readonly devices: {
      readonly data: ReadonlyArray<{
        readonly id: string;
      }>;
    };
    readonly name: string | null;
    readonly settings?: {
      readonly endOfDischargeVoltage?: number | null;
      readonly maxDischarged?: number | null;
      readonly maxDuration?: number | null;
      readonly reserveTime?: number;
      readonly targets?: ReadonlyArray<{
        readonly batteryType: {
          readonly id: string;
        } | null;
        readonly endOfDischargeVoltage: number;
      }>;
      readonly threshold?: number | null;
    };
    readonly type?: BatteryTestType;
    readonly usingSmartStart?: boolean;
  } | null;
};
export type RepeatTaskModal_TaskDetailsQuery = {
  response: RepeatTaskModal_TaskDetailsQuery$data;
  variables: RepeatTaskModal_TaskDetailsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "PaginatedDevices",
  "kind": "LinkedField",
  "name": "devices",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Device",
      "kind": "LinkedField",
      "name": "data",
      "plural": true,
      "selections": (v4/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "usingSmartStart",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endOfDischargeVoltage",
  "storageKey": null
},
v9 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BatteryTestTypeCapacityEDOVTypePair",
      "kind": "LinkedField",
      "name": "targets",
      "plural": true,
      "selections": [
        (v8/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "BatteryType",
          "kind": "LinkedField",
          "name": "batteryType",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BatteryTestTypeCapacity",
  "abstractKey": null
},
v10 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reserveTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "threshold",
      "storageKey": null
    }
  ],
  "type": "BatteryTestTypeQuick",
  "abstractKey": null
},
v11 = {
  "kind": "InlineFragment",
  "selections": [
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxDuration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxDischarged",
      "storageKey": null
    }
  ],
  "type": "BatteryTestTypeCustom",
  "abstractKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RepeatTaskModal_TaskDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "task",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "settings",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "BatteryTest",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RepeatTaskModal_TaskDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "task",
        "plural": false,
        "selections": [
          (v12/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isTask"
          },
          (v2/*: any*/),
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "settings",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "BatteryTest",
            "abstractKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c68d703c25d6e05b069df48461865e53",
    "id": null,
    "metadata": {},
    "name": "RepeatTaskModal_TaskDetailsQuery",
    "operationKind": "query",
    "text": "query RepeatTaskModal_TaskDetailsQuery(\n  $id: ID!\n) {\n  task(id: $id) {\n    __typename\n    __isTask: __typename\n    name\n    devices {\n      data {\n        id\n      }\n    }\n    ... on BatteryTest {\n      usingSmartStart\n      type\n      settings {\n        __typename\n        ... on BatteryTestTypeCapacity {\n          targets {\n            endOfDischargeVoltage\n            batteryType {\n              id\n            }\n          }\n        }\n        ... on BatteryTestTypeQuick {\n          reserveTime\n          threshold\n        }\n        ... on BatteryTestTypeCustom {\n          endOfDischargeVoltage\n          maxDuration\n          maxDischarged\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "4837a52134aec2a5e6ea4a5343a90f7b";

export default node;
