import React, { FC } from 'react';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay';

import { Button, ThemedLink, ThemedPageHeading } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import classNamesBind from 'classnames/bind';
import { useUserPermissions } from 'lib/auth';
import { Paths } from 'lib/routes';

import { ActiveDevicesCount, PageHeader } from '../../../../components';
import { BatteryHealthTaskOverviewContentQuery } from './__generated__/BatteryHealthTaskOverviewContentQuery.graphql';
import style from './layout.module.css';
import { CompletedTests, ExternalDischarges, InProgressTestsAndDischarges, ScheduledTests } from './sections';

const classNames = classNamesBind.bind(style);

export interface BatteryHealthTaskOverviewContentProps {
    preloadedQuery: PreloadedQuery<BatteryHealthTaskOverviewContentQuery>;
}

export const BatteryHealthTaskOverviewContent: FC<BatteryHealthTaskOverviewContentProps> = ({ preloadedQuery }) => {
    const { hasTasksWrite } = useUserPermissions();

    const data = usePreloadedQuery(ContentQuery, preloadedQuery);

    return (
        <div className='space-y-6'>
            <PageHeader />
            <div>
                <div className='flex justify-between items-center pt-6'>
                    <ThemedPageHeading value='Battery Health Tests' />
                    <div className='flex'>
                        {hasTasksWrite && (
                            <Button buttonText='Schedule a test' size='small' to={Paths.TestsScheduleTest} />
                        )}
                    </div>
                </div>
                <ActiveDevicesCount valueSuffix='Active Devices' />

                <div className={classNames('card_layout_container')}>
                    <ScheduledTests tasks={data.scheduledTasks} />

                    <InProgressTestsAndDischarges
                        tasks={data.inprogressTasks}
                        externalDischarges={data.inprogressTests}
                    />
                    <CompletedTests tasks={data.completedTasks} />
                    <ExternalDischarges tasks={data.unplannedTests} />
                    <div className='text-customEggplant font-CynthoNext-Regular'>
                        <ThemedLink to={Paths.TestsCancelledAborted}>See cancelled tests</ThemedLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const ContentQuery = graphql`
    query BatteryHealthTaskOverviewContentQuery {
        scheduledTasks: tasks(pageSize: 3, type: BatteryTest, state: "Scheduled", orderBy: { field: Date, dir: Asc }) {
            ...ScheduledTests_tasks
        }
        inprogressTasks: tasks(type: BatteryTest, state: "InProgress", orderBy: { field: Date, dir: Asc }) {
            ...InProgressTestsAndDischarges_tasks
        }
        inprogressTests: unplannedTests(orderBy: { field: StartTime, dir: Desc }, filters: { state: InProgress }) {
            ...InProgressTestsAndDischarges_tests
        }
        completedTasks: tasks(
            pageSize: 6
            type: BatteryTest
            state: ["Completed", "Aborted"]
            orderBy: { field: Date, dir: Desc }
        ) {
            ...CompletedTests_tasks
        }
        unplannedTests(pageSize: 3, orderBy: { field: StartTime, dir: Desc }) {
            ...ExternalDischarges_tasks
        }
    }
`;
