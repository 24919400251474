import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { Paths } from 'lib/routes';

import { BatteryTestCard } from '../components/BatteryTestCard';
import { CardSection } from '../components/CardSection';
import { ScheduledTests_tasks$key } from './__generated__/ScheduledTests_tasks.graphql';

export interface ScheduledTestsProps {
    tasks: ScheduledTests_tasks$key | null;
}

export const ScheduledTests: FC<ScheduledTestsProps> = ({ tasks }) => {
    const result = useFragment<ScheduledTests_tasks$key>(
        graphql`
            fragment ScheduledTests_tasks on PaginatedTasks {
                total
                data {
                    id
                    ...BatteryTestCard_data
                }
            }
        `,
        tasks
    );

    if (!result || result?.data.length === 0) {
        return null;
    }

    return (
        <CardSection title='Upcoming tests' testId='scheduled-section' to={Paths.TestsScheduled}>
            {result.data.map(task => {
                return <BatteryTestCard key={task.id} task={task} compact />;
            })}
        </CardSection>
    );
};
