import { Theme } from '@accesstel/pcm-ui';

import { DateTime, Duration } from 'luxon';

import { ExtendedChartMarker } from '../../components/MetricsLine';

export enum AnnotationType {
    CoupDeFouet = 'coup-de-fouet',
    QuickTestThreshold = 'quick-test-threshold',
    ReserveTime = 'reserve-time',
    VoltageLimits = 'voltage-limits',
    IdleTime = 'idle-time',
    Other = 'other',
}

export const DefaultAnnotationTypes: AnnotationType[] = [AnnotationType.CoupDeFouet, AnnotationType.IdleTime];

export const AnnotationTypeDisplayText: Record<AnnotationType, string> = {
    [AnnotationType.CoupDeFouet]: 'Coup de fouet region',
    [AnnotationType.QuickTestThreshold]: 'Quick test threshold',
    [AnnotationType.ReserveTime]: 'Reserve time',
    [AnnotationType.VoltageLimits]: 'Voltage limits',
    [AnnotationType.IdleTime]: 'Idle time',
    [AnnotationType.Other]: 'Other',
};

function getQuickTestAnnotations(
    crossedTime: Duration,
    thresholdVoltage: number,
    thresholdTime: Duration
): ExtendedChartMarker[] {
    return [
        {
            type: 'horizontal',
            color: Theme.customCoral,
            y: thresholdVoltage,
            strokeDash: '3,5',
            label: `Test threshold - ${thresholdVoltage}V`,
            labelPosition: 'insideBottomRight',
            annotationType: AnnotationType.QuickTestThreshold,
        },

        {
            type: 'vertical',
            color: Theme.customEggplantCoral,
            x: thresholdTime,
            label: 'Pass threshold',
            labelColor: Theme.customEggplantCoral,
            labelPosition: 'insideTopLeft',
            below: true,
            annotationType: AnnotationType.QuickTestThreshold,
            showLabelOnHover: true,
        },

        {
            type: 'point',
            color: Theme.customEggplantCoral,
            labelColor: Theme.customEggplantCoral,
            x: crossedTime,
            y: thresholdVoltage,
            radius: 5,
            label: 'Crossed threshold',
            strokeDash: '5,10',
            labelOffset: 10,
            labelPosition: 'top',
            annotationType: AnnotationType.QuickTestThreshold,
        },

        {
            type: 'area',
            color: Theme.customCoral,
            opacity: 0.2,
            x1: Duration.fromMillis(0),
            x2: thresholdTime,
            y1: 0,
            y2: thresholdVoltage,
            label: 'Fail region',
            labelColor: Theme.customEggplantCoral,
            labelPosition: 'insideBottomRight',
            below: true,
            annotationType: AnnotationType.QuickTestThreshold,
            showLabelOnHover: true,
        },
    ];
}

function getVoltageLimitAnnotations(
    minimumVoltage: number | null,
    maximumVoltage: number | null
): ExtendedChartMarker[] {
    const markers: ExtendedChartMarker[] = [];

    if (minimumVoltage !== null) {
        markers.push({
            type: 'area-horizontal',
            color: 'hsla(292, 13%, 90%, 1)',
            secondaryColor: 'hsla(292, 13%, 50%, 1)',
            y1: 0,
            y2: minimumVoltage,
            label: `Below ${minimumVoltage}V`,
            labelPosition: 'insideTopRight',
            labelColor: Theme.customEggplantCoral,
            annotationType: AnnotationType.VoltageLimits,
            below: true,
        });
    }

    if (maximumVoltage !== null) {
        markers.push({
            type: 'area-horizontal',
            color: 'hsla(292, 13%, 90%, 1)',
            secondaryColor: 'hsla(292, 13%, 50%, 1)',
            y1: maximumVoltage,
            y2: Infinity,
            label: `Above ${maximumVoltage}V`,
            labelPosition: 'insideTopRight',
            labelColor: Theme.customEggplantCoral,
            annotationType: AnnotationType.VoltageLimits,
            below: true,
        });
    }

    return markers;
}

function getReserveTimeAnnotations(designReserveTime: Duration): ExtendedChartMarker[] {
    return [
        {
            type: 'vertical',
            color: Theme.customEggplant,
            opacity: 0.5,
            x: designReserveTime,
            label: 'Design reserve time',
            labelPosition: 'insideTopLeft',
            annotationType: AnnotationType.ReserveTime,
            showLabelOnHover: true,
        },
    ];
}

function getIdleTimeAnnotations(
    totalDuration: Duration,
    startsWithDischarge: boolean,
    startMarks: Duration[],
    endMarks: Duration[]
): ExtendedChartMarker[] {
    let previousEndPosition: Duration | null;
    if (startsWithDischarge) {
        startMarks = [Duration.fromMillis(0), ...startMarks];
        previousEndPosition = null;
    } else {
        previousEndPosition = Duration.fromMillis(0);
    }

    let ranToEnd = false;

    const markers: ExtendedChartMarker[] = [];

    for (const mark of startMarks) {
        if (previousEndPosition) {
            // Shade the inactive area
            markers.push({
                type: 'area-vertical',
                color: 'rgba(0, 0, 0, 0.1)',
                x1: previousEndPosition,
                x2: mark,
                below: true,
                label: 'Not discharging',
                labelColor: Theme.customEggplantCoral,
                labelPosition: 'insideBottomLeft',
                annotationType: AnnotationType.IdleTime,
                showLabelOnHover: true,
            });
        }

        const endMark = endMarks.find(endMark => endMark > mark);

        if (endMark) {
            previousEndPosition = endMark;
        } else {
            // Runs for the rest of the duration
            ranToEnd = true;
            break;
        }
    }

    if (!ranToEnd && previousEndPosition) {
        // Didn't run to the end, shade the rest of the duration
        markers.push({
            type: 'area-vertical',
            color: 'rgba(0, 0, 0, 0.1)',
            x1: previousEndPosition,
            x2: totalDuration,
            below: true,
            label: 'Not discharging',
            labelColor: Theme.customEggplantCoral,
            labelPosition: 'insideBottomLeft',
            annotationType: AnnotationType.IdleTime,
            showLabelOnHover: true,
        });
    }

    return markers;
}

interface APIMarker {
    description: string;
    offset: string;
    type: string;
}

interface AnnotationOptions {
    startTime: DateTime | null;
    endTime: DateTime | null;
    quickTestThresholdVoltage: number | null;
    quickTestThresholdTime: Duration | null;
    designReserveTime: number | null;
    minimumAllowedVoltage: number | null;
    maximumAllowedVoltage: number | null;
}

export function useAnnotations(
    settings: AnnotationOptions,
    incomingMarkers: readonly APIMarker[]
): [ExtendedChartMarker[], ExtendedChartMarker[]] {
    const voltageChartMarkers: ExtendedChartMarker[] = [];
    const allChartMarkers: ExtendedChartMarker[] = [];

    if (settings.minimumAllowedVoltage != null || settings.maximumAllowedVoltage != null) {
        voltageChartMarkers.push(
            ...getVoltageLimitAnnotations(settings.minimumAllowedVoltage, settings.maximumAllowedVoltage)
        );
    }

    if (settings.designReserveTime != null) {
        // Reserve time marker is only shown if the test ran for longer than the design reserve time
        let totalDuration: Duration | null = null;

        if (settings.startTime != null) {
            const endTime = settings.endTime ?? DateTime.local();
            totalDuration = endTime.diff(settings.startTime);
        }

        if (totalDuration && totalDuration.as('minutes') >= settings.designReserveTime) {
            voltageChartMarkers.push(
                ...getReserveTimeAnnotations(Duration.fromObject({ minutes: settings.designReserveTime }))
            );
        }
    }

    if (settings.quickTestThresholdVoltage != null && settings.quickTestThresholdTime != null) {
        const thresholdReachedMarker = incomingMarkers.find(marker => marker.type === 'ThresholdReached');

        if (thresholdReachedMarker) {
            voltageChartMarkers.push(
                ...getQuickTestAnnotations(
                    Duration.fromISO(thresholdReachedMarker.offset),
                    settings.quickTestThresholdVoltage,
                    settings.quickTestThresholdTime
                )
            );
        }
    }

    if (settings.startTime != null) {
        const startMarks: Duration[] = [];
        const endMarks: Duration[] = [];

        for (const marker of incomingMarkers) {
            if (marker.type === 'DischargeStart') {
                startMarks.push(Duration.fromISO(marker.offset));
            } else if (marker.type === 'DischargeStop') {
                endMarks.push(Duration.fromISO(marker.offset));
            }
        }

        const endTime = settings.endTime ?? DateTime.local();
        const totalDuration = endTime.diff(settings.startTime);

        allChartMarkers.push(
            ...getIdleTimeAnnotations(
                totalDuration,
                startMarks.length === 0, // FIXME: the API doesn't tell us whether or not there actually was a discharge
                startMarks,
                endMarks
            )
        );
    }

    // Handle all other markers
    for (const marker of incomingMarkers) {
        if (marker.type === 'DischargeStart' || marker.type === 'DischargeStop' || marker.type === 'ThresholdReached') {
            continue;
        }

        let type = AnnotationType.Other;

        if (marker.type === 'ReserveTimeReached') {
            type = AnnotationType.ReserveTime;
        }

        voltageChartMarkers.push({
            type: 'vertical',
            color: Theme.customEggplantCoral,
            x: Duration.fromISO(marker.offset as string),
            label: marker.description,
            labelPosition: 'insideTopLeft',
            strokeDash: '5,10',
            annotationType: type,
            showLabelOnHover: true,
        });
    }

    return [voltageChartMarkers, allChartMarkers];
}
