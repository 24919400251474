/**
 * @generated SignedSource<<ecdd3199be831bba598bf12394d12c47>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TaskState = "Cancelled" | "Completed" | "InProgress" | "Scheduled" | "%future added value";
export type DoneQuery$variables = {
  id: string;
};
export type DoneQuery$data = {
  readonly task: {
    readonly overallState: TaskState;
    readonly " $fragmentSpreads": FragmentRefs<"TaskOverview_task" | "TaskProgressBar_task">;
  } | null;
};
export type DoneQuery = {
  response: DoneQuery$data;
  variables: DoneQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "overallState",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endOfDischargeVoltage",
  "storageKey": null
},
v7 = [
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DoneQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "task",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TaskOverview_task"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TaskProgressBar_task"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DoneQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "task",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isTask"
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TaskCountByState",
            "kind": "LinkedField",
            "name": "countByState",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "state",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deviceCount",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "estimatedEndTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TaskSchedule",
                "kind": "LinkedField",
                "name": "schedule",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "repeat",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "time",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "settings",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BatteryTestTypeCapacityEDOVTypePair",
                        "kind": "LinkedField",
                        "name": "targets",
                        "plural": true,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BatteryType",
                            "kind": "LinkedField",
                            "name": "batteryType",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "manufacturer",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "model",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "BatteryTestTypeCapacity",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "reserveTime",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "threshold",
                        "storageKey": null
                      }
                    ],
                    "type": "BatteryTestTypeQuick",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "maxDuration",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "maxDischarged",
                        "storageKey": null
                      }
                    ],
                    "type": "BatteryTestTypeCustom",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "testState",
                "storageKey": null
              },
              {
                "alias": "totalDevice",
                "args": null,
                "concreteType": "PaginatedDevices",
                "kind": "LinkedField",
                "name": "devices",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "total",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Device",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "commencedTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cancelledTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "cancelledUser",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "completedTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "usingSmartStart",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "creator",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "abortedTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "abortedUser",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              }
            ],
            "type": "BatteryTest",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8ca4dba8c98f27690e29960d099a26ef",
    "id": null,
    "metadata": {},
    "name": "DoneQuery",
    "operationKind": "query",
    "text": "query DoneQuery(\n  $id: ID!\n) {\n  task(id: $id) {\n    __typename\n    __isTask: __typename\n    overallState\n    ...TaskOverview_task\n    ...TaskProgressBar_task\n    id\n  }\n}\n\nfragment OverviewFields_task on BatteryTest {\n  overallState\n  commencedTime\n  cancelledTime\n  cancelledUser {\n    name\n    email\n  }\n  completedTime\n  usingSmartStart\n  createdTime\n  schedule {\n    repeat\n    time\n  }\n  type\n  creator {\n    name\n  }\n  testState\n  abortedTime\n  abortedUser {\n    name\n    email\n  }\n  totalDevice: devices {\n    total\n    data {\n      id\n    }\n  }\n}\n\nfragment TaskOverview_task on BatteryTest {\n  id\n  name\n  overallState\n  estimatedEndTime\n  schedule {\n    repeat\n  }\n  settings {\n    __typename\n    ... on BatteryTestTypeCapacity {\n      targets {\n        endOfDischargeVoltage\n        batteryType {\n          id\n          manufacturer\n          model\n        }\n      }\n    }\n    ... on BatteryTestTypeQuick {\n      reserveTime\n      threshold\n    }\n    ... on BatteryTestTypeCustom {\n      endOfDischargeVoltage\n      maxDuration\n      maxDischarged\n    }\n  }\n  testState\n  totalDevice: devices {\n    total\n    data {\n      id\n    }\n  }\n  ...OverviewFields_task\n  ...TaskProgressBar_task\n}\n\nfragment TaskProgressBar_task on Task {\n  __isTask: __typename\n  countByState {\n    state\n    deviceCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "e5632ba738b6bdd69e68d78e3baea74a";

export default node;
