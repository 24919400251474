import { POSTCODE_EXACT_REGEX } from 'lib/regex';
import { AttributeValidationSchema } from 'views/manage/common/schema';
import { array, object, string } from 'yup';

export const SiteValidationSchema = object().shape(
    {
        name: string().trim().required('A name must be provided'),
        type: string().trim().required('A type must be provided'),
        address: string().trim().required('An address must be provided'),
        state: string().trim(),
        postcode: string()
            .trim()
            .matches(POSTCODE_EXACT_REGEX, 'Invalid postcode format')
            .transform(postcode => (postcode === '' ? null : postcode)),
        latitude: string().when('longitude', {
            is: (longitude: string) => !!longitude,
            then: string().required('Latitude must be provided'),
        }),
        longitude: string().when('latitude', {
            is: (latitude: string) => !!latitude,
            then: string().required('Longitude must be provided'),
        }),
        attributes: array(AttributeValidationSchema).test('unique', 'Cannot have duplicate attribute', attributes => {
            const names = attributes?.map(attribute => attribute.name ?? '') ?? [];
            const visited = new Set<string>(names);
            return visited.size === names.length;
        }),
    },
    [['latitude', 'longitude']]
);
