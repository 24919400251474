import React, { FC } from 'react';

import { MultipleSelect } from 'components/MultipleSelect';
import { CommonFilterProps } from 'filters/common';

import { BatteryTestStatusFilter } from '../types';

export type TaskBatteryTestStatusFilterUIProps = CommonFilterProps<BatteryTestStatusFilter[]>;

export const TaskBatteryTestStatusFilterUI: FC<TaskBatteryTestStatusFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    const options: BatteryTestStatusFilter[] = [
        { id: 'Aborted', name: 'Aborted' },
        { id: 'Cancelled', name: 'Cancelled' },
        { id: 'Failed', name: 'Failed' },
        { id: 'InProgress', name: 'In Progress' },
        { id: 'Inconclusive', name: 'Inconclusive' },
        { id: 'Passed', name: 'Passed' },
        { id: 'PartialFail', name: 'Partial Fail' },
        { id: 'PartialPass', name: 'Partial Pass' },
        { id: 'Scheduled', name: 'Scheduled' },
    ];

    return (
        <MultipleSelect
            options={options}
            active={current}
            title='Filter by battery test status'
            renderItem={item => item.name}
            renderItemSimple={item => item.name}
            areItemsEqual={(itemA, itemB) => itemA.id === itemB.id}
            showBack={hasPrevious}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onClose={onClose}
        />
    );
};
