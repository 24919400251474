export enum SortDirection {
    Ascending = 'ascending',
    Descending = 'descending',
}

export interface InternalTableState<ColumnType extends string> {
    sortColumn: ColumnType;
    sortDirection: SortDirection;
    page: number;
    search: string;

    selectedItems: string[];
    allSelectableItems: string[] | null;

    visibleColumnsInOrder: ColumnType[] | null;
    isColumnEditPaneOpen: boolean;

    defaultVisibleColumns: ColumnType[];
}

export interface TableState<ColumnType extends string> extends InternalTableState<ColumnType> {
    visibleColumnsInOrder: ColumnType[];

    areProvidedColumnsInDefaultOrder(columns: ColumnType[]): boolean;
}
