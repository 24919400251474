/**
 * @generated SignedSource<<ffade428daf714950230568dfcb47fbe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BatteryTypeManufacturerFilterUI_manufacturerSearchQuery$variables = {
  input: string;
  maxResults: number;
};
export type BatteryTypeManufacturerFilterUI_manufacturerSearchQuery$data = {
  readonly batteryTypeManufacturers: {
    readonly names: ReadonlyArray<string>;
  } | null;
};
export type BatteryTypeManufacturerFilterUI_manufacturerSearchQuery = {
  response: BatteryTypeManufacturerFilterUI_manufacturerSearchQuery$data;
  variables: BatteryTypeManufacturerFilterUI_manufacturerSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "maxResults"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "includeDeleted",
        "value": false
      },
      {
        "kind": "Variable",
        "name": "limit",
        "variableName": "maxResults"
      },
      {
        "kind": "Variable",
        "name": "match",
        "variableName": "input"
      }
    ],
    "concreteType": "BatteryManufacturerLookup",
    "kind": "LinkedField",
    "name": "batteryTypeManufacturers",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "names",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BatteryTypeManufacturerFilterUI_manufacturerSearchQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BatteryTypeManufacturerFilterUI_manufacturerSearchQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "dceff4287222ca66cf59b8c361c789fc",
    "id": null,
    "metadata": {},
    "name": "BatteryTypeManufacturerFilterUI_manufacturerSearchQuery",
    "operationKind": "query",
    "text": "query BatteryTypeManufacturerFilterUI_manufacturerSearchQuery(\n  $input: String!\n  $maxResults: Int!\n) {\n  batteryTypeManufacturers(match: $input, limit: $maxResults, includeDeleted: false) {\n    names\n  }\n}\n"
  }
};
})();

(node as any).hash = "ce560767f7f8496e5f5118a06e18395e";

export default node;
