import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';

import { DualPlaneResult_test$key } from './__generated__/DualPlaneResult_test.graphql';
import { CombinedTest } from './components';

interface DualPlaneResultProps {
    mainTest: DualPlaneResult_test$key;
    otherTest: DualPlaneResult_test$key;
}

const TestResultFragment = graphql`
    fragment DualPlaneResult_test on DeviceBatteryTestResults {
        device {
            id
            name
        }
        ...TestResultDisplay_test
        ...CombinedTest_test
    }
`;

export const DualPlaneResult: FC<DualPlaneResultProps> = props => {
    const { mainTest, otherTest } = props;

    const test1 = useFragment<DualPlaneResult_test$key>(TestResultFragment, mainTest);
    const test2 = useFragment<DualPlaneResult_test$key>(TestResultFragment, otherTest);

    // Order tests by name
    const tests = [test1, test2].sort((a, b) => {
        if (a.device?.name && b.device?.name) {
            return a.device.name.localeCompare(b.device.name);
        }
        return 0;
    });

    return <CombinedTest test1Ref={tests[0]} test2Ref={tests[1]} />;
};
