import React, { FC } from 'react';

import { MultipleSelect } from 'components/MultipleSelect';

import { CommonFilterProps } from '../../common/types';
import { DeviceHealthStatus, DeviceHealthStatusNames, SiteDeviceStatusFilter } from '../types';

const FilterOptions: SiteDeviceStatusFilter[] = [
    { id: DeviceHealthStatus.Critical, displayText: DeviceHealthStatusNames[DeviceHealthStatus.Critical] },
    { id: DeviceHealthStatus.Degraded, displayText: DeviceHealthStatusNames[DeviceHealthStatus.Degraded] },
    { id: DeviceHealthStatus.Healthy, displayText: DeviceHealthStatusNames[DeviceHealthStatus.Healthy] },
    { id: DeviceHealthStatus.Offline, displayText: DeviceHealthStatusNames[DeviceHealthStatus.Offline] },
    { id: DeviceHealthStatus.Unknown, displayText: DeviceHealthStatusNames[DeviceHealthStatus.Unknown] },
];

export type SiteDeviceStatusFilterUIProps = CommonFilterProps<SiteDeviceStatusFilter[]>;

export const SiteDeviceStatusFilterUI: FC<SiteDeviceStatusFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
}) => {
    return (
        <MultipleSelect
            options={FilterOptions}
            active={current}
            title='Filter by status'
            renderItem={item => item.displayText}
            renderItemSimple={item => item.displayText}
            areItemsEqual={(itemA, itemB) => itemA.id === itemB.id}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onClose={onClose}
        />
    );
};
