import { TimeBase } from '@accesstel/pcm-ui';

import { Today, Yesterday } from './presets';

export type DateRange = [Date, Date];

export interface PredefinedRange {
    title: string;
    asDateRange: () => DateRange;
}

export interface PredefinedDateRangeFilter {
    type: 'predefined';
    preset: string;
    range: DateRange;
}

export interface CustomDateRangeFilter {
    type: 'custom';
    range: DateRange;
}

export interface DurationDateRangeFilter {
    type: 'duration';
    value: number;
    unit: TimeBase;
    range: DateRange;
}

export type DateRangeFilter = PredefinedDateRangeFilter | CustomDateRangeFilter | DurationDateRangeFilter;

export const DefaultPresetRanges: PredefinedRange[] = [Today, Yesterday];
