import React, { FC, KeyboardEvent, ReactNode, useCallback, useMemo, useState } from 'react';

import { ArrowLeftIcon, FilterContainer, FilterKeyValue, SelectedFilters, TextBox } from '@accesstel/pcm-ui';

import { POSTCODE_EXACT_REGEX, POSTCODE_RANGE_REGEX } from 'lib/regex';

import { CommonFilterProps } from '../../common/types';
import { SitePostcodeFilter } from '../types';

export type SitePostcodeFilterUIProps = CommonFilterProps<SitePostcodeFilter[]>;

export const SitePostcodeFilterUI: FC<SitePostcodeFilterUIProps> = ({ current, onClearOrBack, onClose, onSubmit }) => {
    const [postcode, setPostcode] = useState<SitePostcodeFilter>({ id: '', value: '' });
    const [postcodes, setPostcodes] = useState<SitePostcodeFilter[]>(current ?? []);

    let customClearButton: ReactNode;
    if (!postcode) {
        customClearButton = (
            <div className='w-4 h-4 hover:text-customCoral'>
                <ArrowLeftIcon />
            </div>
        );
    }

    const activeFilters = useMemo<FilterKeyValue[]>(
        () =>
            postcodes.map(item => ({
                label: '',
                value: item.value,
            })),
        [postcodes]
    );

    const checkForDuplicate = useCallback(() => {
        return postcodes.some(entry => entry.value === postcode.value);
    }, [postcode.value, postcodes]);

    const handleConfirmClick = useCallback(() => {
        if (postcode.value !== '' && POSTCODE_EXACT_REGEX.test(postcode.value) && checkForDuplicate()) {
            onSubmit([...postcodes, postcode]);
        } else {
            onSubmit(postcodes);
        }
    }, [checkForDuplicate, onSubmit, postcode, postcodes]);

    const handleOnRemove = useCallback((_filter: FilterKeyValue, index: number) => {
        setPostcodes(items => items.filter((_, itemIndex) => itemIndex !== index));
    }, []);

    const handleKeyDown = useCallback(
        (event: KeyboardEvent) => {
            switch (event.key) {
                case 'Enter':
                    if (POSTCODE_EXACT_REGEX.test(postcode.value) && !checkForDuplicate()) {
                        setPostcodes(prevValue => {
                            const newValue = [...prevValue];
                            newValue.push(postcode);
                            return newValue;
                        });
                        setPostcode({ id: '', value: '' });
                    }
                    break;
            }
        },
        [checkForDuplicate, postcode]
    );

    const handleHideConfirmButton = useCallback(() => {
        if (POSTCODE_EXACT_REGEX.test(postcode.value) || postcodes.length > 0) {
            return false;
        }

        return true;
    }, [postcode, postcodes]);

    return (
        <FilterContainer
            title='Filter by site postcode'
            onClearClick={onClearOrBack}
            onClose={onClose}
            onConfirmClick={handleConfirmClick}
            hideConfirmButton={handleHideConfirmButton()}
            customButton={customClearButton}
            primaryContent={
                <div className='px-4 text-customEggplant font-CynthoNext-Light'>
                    <TextBox
                        placeHolder='Postcode'
                        autoFocus
                        value={postcode.value}
                        onChange={e => {
                            const value = e.target.value;
                            if (POSTCODE_RANGE_REGEX.test(value)) {
                                setPostcode({ id: value, value: value });
                            }
                        }}
                        onKeyDown={handleKeyDown}
                        light
                    />
                </div>
            }
            secondaryContent={
                activeFilters.length > 0 && <SelectedFilters filters={activeFilters} onRemove={handleOnRemove} />
            }
        />
    );
};
