import graphql from 'babel-plugin-relay/macro';

export const getExportFormAvailableMetrics = graphql`
    query queries_GetExportFormAvailableMetricsQuery {
        metricsForExport {
            metric
            displayName
        }
    }
`;
