import { useEffect, useState } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';

import { useToast } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { Duration } from 'luxon';

import { DataSeries, Operation, SelectedMetric } from '../types';
import { metricLoaderQuery, metricLoaderQuery$data } from './__generated__/metricLoaderQuery.graphql';

interface MetricData {
    series: DataSeries[];
}

export function useMetricData(
    startDate: Date,
    endDate: Date,
    metrics: SelectedMetric[],
    devices: string[]
): [MetricData] {
    const environment = useRelayEnvironment();
    const { show } = useToast();

    const [series, setSeries] = useState<DataSeries[]>([]);

    useEffect(() => {
        if (devices.length === 0 || metrics.length === 0) {
            setSeries([]);
            return;
        }

        const interval = getAppropriateInterval(startDate, endDate);

        const query = fetchQuery<metricLoaderQuery>(environment, DataLoadQuery, {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
            interval: interval.toISO(),
            metrics: metrics,
            devices: devices,
        });

        function executeQuery() {
            query.subscribe({
                next(value) {
                    setSeries(convertToSeries(value.aggregateMetrics.metrics));
                },
                error(error: unknown) {
                    show({
                        text: 'Unable to load data',
                        variant: 'error',
                        actions: [
                            {
                                title: 'Try again',
                                onClick() {
                                    executeQuery();
                                },
                            },
                        ],
                        displayTime: 10000,
                    });
                    captureException(error, scope => {
                        scope.setTag('component', 'MetricLoader');
                        scope.setExtra('startDate', startDate);
                        scope.setExtra('endDate', endDate);
                        scope.setExtra('metrics', metrics);
                        scope.setExtra('devices', devices);
                        return scope;
                    });
                },
            });
        }

        executeQuery();
    }, [startDate, endDate, metrics, devices, environment, show]);

    return [
        {
            series,
        },
    ];
}

function getAppropriateInterval(startDate: Date, endDate: Date, divisions = 50): Duration {
    const duration = Duration.fromMillis(endDate.getTime() - startDate.getTime());

    const interval = Duration.fromObject({ seconds: duration.as('seconds') / divisions });

    // Minimum limit in API
    if (interval < Duration.fromObject({ minutes: 1 })) {
        return Duration.fromObject({ minutes: 1 });
    }

    return interval;
}

const DataLoadQuery = graphql`
    query metricLoaderQuery(
        $startDate: Timestamp!
        $endDate: Timestamp!
        $interval: Duration!
        $metrics: [MetricAggregationSettingIn!]!
        $devices: [ID!]!
    ) {
        aggregateMetrics(
            onlyDevices: $devices
            metrics: $metrics
            from: $startDate
            to: $endDate
            interval: $interval
        ) {
            metrics {
                metric
                op
                values {
                    timestamp
                    value
                }
            }
        }
    }
`;

type MetricsType = metricLoaderQuery$data['aggregateMetrics']['metrics'][number];

function convertToSeries(metrics: readonly MetricsType[]): DataSeries[] {
    return metrics.map(metric => ({
        metric: metric.metric,
        // TODO: This technically is incorrect as relay adds a future value placeholder
        op: metric.op as Operation,
        data: metric.values.map(value => ({
            timestamp: new Date(value.timestamp),
            value: value.value,
        })),
    }));
}
