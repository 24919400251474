import { array, boolean, number, object, string } from 'yup';

import {
    SnmpAuthAlgorithm,
    SnmpPrivAlgorithm,
    SnmpSecurityLevel,
    SnmpVersion,
} from './__generated__/useConnectionTestMutation.graphql';

export const SNMPValidationSchema = object({
    port: number()
        .typeError('Must be a number')
        .integer('Whole numbers only')
        .min(1, 'Must between 1 and 65535')
        .max(65535, 'Must between 1 and 65535')
        .default(161),
    version: string().oneOf(['V1', 'V2c', 'V3']).required('SNMP version is required'),

    // V1 and V2 settings
    readOnlyCommunity: string().when('version', {
        is: (version: string) => version === 'V1' || version === 'V2c',
        then: string().required('Read only community string required'),
    }),
    readWriteCommunity: string().when('version', {
        is: (version: string) => version === 'V1' || version === 'V2c',
        then: string().required('Read write community string required'),
    }),

    // V3 settings
    authPassphrase: string().when(['version', 'securityLevel'], {
        is: (version: string, securityLevel: string) =>
            (version === 'V3' && securityLevel === 'AuthPriv') || securityLevel === 'AuthNoPriv',
        then: string().required('Auth passphrase required when using Auth Priv or Auth No Priv security level'),
        otherwise: string().nullable(),
    }),
    authType: string().when('version', {
        is: 'V3',
        then: string().oneOf(['MD5', 'SHA']).required(),
        otherwise: string().oneOf(['MD5', 'SHA']),
    }),
    engineId: string().nullable(),
    privPassphrase: string().when(['version', 'securityLevel'], {
        is: (version: string, securityLevel: string) => version === 'V3' && securityLevel === 'AuthPriv',
        then: string().required('Priv passphrase required when using Auth Priv security level'),
        otherwise: string().nullable(),
    }),
    privType: string().when('version', {
        is: 'V3',
        then: string().oneOf(['AES', 'DES']).required(),
        otherwise: string().oneOf(['AES', 'DES']),
    }),
    securityLevel: string().when('version', {
        is: 'V3',
        then: string().required(),
    }),
    user: string().when('version', {
        is: 'V3',
        then: string().required('Username required'),
    }),
});

export const WebCredentialsSchema = object({
    username: string().required('Username is required'),
    password: string().required('Password is required'),
});

export const ConnectivitySettingsValidationSchema = object({
    requireSnmp: boolean(),
    requireWeb: boolean(),
    ipEnabled: boolean(),
    addresses: array(string().required())
        .when('ipEnabled', {
            is: true,
            then: array(string().trim().required('Address cannot be empty')).min(
                1,
                'At least one address must be provided'
            ),
            otherwise: array(string().trim().required('Address cannot be empty')),
        })
        .default([]),
    snmpSettings: SNMPValidationSchema.when('requireSnmp', {
        is: true,
        then: SNMPValidationSchema.required(),
        otherwise: SNMPValidationSchema.strip(),
    }),
    webSettings: WebCredentialsSchema.when('requireWeb', {
        is: true,
        then: WebCredentialsSchema.required(),
        otherwise: WebCredentialsSchema.strip(),
    }),
});

interface SnmpSettingsFormValues {
    protocolId: string | null;
    port: string;
    version: SnmpVersion;

    // V1 and V2 settings
    readOnlyCommunity: string;
    readWriteCommunity: string;

    // V3 settings
    authPassphrase: string;
    authType: SnmpAuthAlgorithm;
    engineId: string;
    privPassphrase: string;
    privType: SnmpPrivAlgorithm;
    securityLevel: SnmpSecurityLevel;
    user: string;
}

interface WebSettingsFormValues {
    protocolId: string | null;
    username: string;
    password: string;
}

export interface ConnectivitySettingsFormValues {
    addresses: string[];
    snmpSettings: SnmpSettingsFormValues | null;
    webSettings: WebSettingsFormValues | null;

    // validation helpers
    requireSnmp: false;
    requireWeb: false;
    ipEnabled: false;
}

export function createDefaultConnectivitySnmpSettingsValues(
    protocolId: string
): NonNullable<ConnectivitySettingsFormValues['snmpSettings']> {
    return {
        protocolId,
        port: '161',
        version: 'V3',
        readOnlyCommunity: 'public',
        readWriteCommunity: 'private',
        user: '',
        authPassphrase: '',
        authType: 'MD5',
        engineId: '',
        privPassphrase: '',
        privType: 'AES',
        securityLevel: 'NoAuthNoPriv',
    };
}

export function createDefaultConnectivityBasicSettingsValues(
    protocolId: string
): NonNullable<ConnectivitySettingsFormValues['webSettings']> {
    return {
        protocolId,
        password: '',
        username: '',
    };
}

export function createDefaultConnectivitySettingsValues(): ConnectivitySettingsFormValues {
    return {
        requireSnmp: false,
        requireWeb: false,
        ipEnabled: false,
        addresses: [],
        snmpSettings: {
            protocolId: null,
            port: '161',
            version: 'V3',
            readOnlyCommunity: 'public',
            readWriteCommunity: 'private',
            user: '',
            authPassphrase: '',
            authType: 'MD5',
            engineId: '',
            privPassphrase: '',
            privType: 'AES',
            securityLevel: 'NoAuthNoPriv',
        },
        webSettings: {
            protocolId: null,
            password: '',
            username: '',
        },
    };
}
