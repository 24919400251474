import React, { FC, useEffect } from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';

import {
    BatteryChargingIcon,
    ClockIcon,
    EyeIcon,
    HomeIcon,
    LoadIcon,
    PowerIcon,
    RectifierIcon,
} from '@accesstel/pcm-ui';
import { SiteViewNavigationItem } from '@accesstel/pcm-ui/dist/navigation/SiteViewNavigation/types';

import { Paths } from 'lib/routes';

import { useSiteDevices } from './context';

interface NavigateToFirstDeviceProps {
    page: string;
}

export const NavigateToFirstDevice: FC<NavigateToFirstDeviceProps> = ({ page }) => {
    const devices = useSiteDevices();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (devices.length === 0) {
            return;
        }

        navigate(generatePath(Paths.SiteViewRelativeDevicePage, { deviceId: devices[0].device.id, page }), {
            replace: true,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [devices, location.pathname]);

    return null;
};

/**
 * Retrieves the navigation options for the site view.
 * Update this to add new pages to the site view.
 */
export function getNavigationOptions(deviceId?: string): SiteViewNavigationItem[] {
    return [
        {
            icon: <HomeIcon />,
            title: 'Overview',
            to: deviceId ? `${deviceId}/overview` : 'overview',
        },
        {
            icon: <PowerIcon />,
            title: 'AC Power',
            to: deviceId ? `${deviceId}/ac-power` : 'ac-power',
        },
        {
            icon: <RectifierIcon />,
            title: 'Rectifiers',
            to: deviceId ? `${deviceId}/rectifiers` : 'rectifiers',
        },
        {
            icon: <BatteryChargingIcon />,
            title: 'Batteries',
            to: deviceId ? `${deviceId}/batteries` : 'batteries',
        },
        {
            icon: <LoadIcon />,
            title: 'Load',
            to: deviceId ? `${deviceId}/load` : 'load',
        },
        {
            icon: <EyeIcon />,
            title: 'Insights',
            to: deviceId ? `${deviceId}/insights` : 'insights',
        },
        {
            icon: <ClockIcon />,
            title: 'History',
            to: deviceId ? `${deviceId}/history` : 'history',
        },
    ];
}
