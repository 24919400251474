/**
 * @generated SignedSource<<96cb7017d9ef3bdb9e57fc23b9bbd7aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeviceRemoveResponse = "PartialSuccess" | "Success" | "UnknownDevice" | "%future added value";
export type DeleteDevicesModalMutation$variables = {
  ids: ReadonlyArray<string>;
};
export type DeleteDevicesModalMutation$data = {
  readonly removeDevices: DeviceRemoveResponse;
};
export type DeleteDevicesModalMutation = {
  response: DeleteDevicesModalMutation$data;
  variables: DeleteDevicesModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ids",
        "variableName": "ids"
      }
    ],
    "kind": "ScalarField",
    "name": "removeDevices",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteDevicesModalMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteDevicesModalMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fb1e7f5d64be55e36d7ab34ee1f3b746",
    "id": null,
    "metadata": {},
    "name": "DeleteDevicesModalMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteDevicesModalMutation(\n  $ids: [ID!]!\n) {\n  removeDevices(ids: $ids)\n}\n"
  }
};
})();

(node as any).hash = "1a1c84c6000f00c50185d394dfad1c63";

export default node;
