/**
 * @generated SignedSource<<507d59140204620ffbaaf140575d94f0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BatteryStatus = "BoostCharging" | "Charging" | "Discharging" | "Disconnected" | "FloatCharging" | "Idle" | "Missing" | "Unknown" | "%future added value";
export type DeviceHealth = "Critical" | "Degraded" | "Healthy" | "Offline" | "Unknown" | "%future added value";
export type SiteSortField = "BatteryCapacity" | "BatteryStatus" | "BatteryTotalTimeRemaining" | "DeviceCount" | "Name" | "State" | "Type" | "%future added value";
export type SitesWithBatteries = "All" | "None" | "Partial" | "%future added value";
export type SortDirection = "Asc" | "Desc" | "%future added value";
export type SiteOrdering = {
  dir?: SortDirection | null;
  field: SiteSortField;
};
export type SiteFilter = {
  attributes?: ReadonlyArray<AttributeFilters> | null;
  batteryCapacity?: FloatRangeFilter | null;
  batteryStatus?: ReadonlyArray<BatteryStatusFilter> | null;
  batteryTotalTimeRemaining?: FloatRangeFilter | null;
  deviceBatteries?: SitesWithBatteries | null;
  deviceCount?: IntRangeFilter | null;
  deviceHealth?: ReadonlyArray<DeviceHealthFilter> | null;
  deviceName?: ReadonlyArray<StringFilter> | null;
  ids?: ReadonlyArray<string> | null;
  name?: ReadonlyArray<StringFilter> | null;
  postcode?: ReadonlyArray<StringFilter> | null;
  state?: ReadonlyArray<StringFilter> | null;
  type?: ReadonlyArray<StringFilter> | null;
};
export type StringFilter = {
  value: string;
};
export type IntRangeFilter = {
  max: number;
  min: number;
};
export type DeviceHealthFilter = {
  value: DeviceHealth;
};
export type AttributeFilters = {
  filters: ReadonlyArray<StringFilter>;
  name: string;
};
export type BatteryStatusFilter = {
  value: BatteryStatus;
};
export type FloatRangeFilter = {
  max: number;
  min: number;
};
export type SitesTableQuery$variables = {
  filter?: SiteFilter | null;
  orderBy?: SiteOrdering | null;
  page?: number | null;
  search?: string | null;
  withBatteryCapacity?: boolean | null;
  withBatteryStatus?: boolean | null;
  withBatteryTimeRemaining?: boolean | null;
  withPostcode?: boolean | null;
  withSiteType?: boolean | null;
};
export type SitesTableQuery$data = {
  readonly overallSites: {
    readonly total: number;
  };
  readonly sites: {
    readonly data: ReadonlyArray<{
      readonly __typename: "Site";
      readonly address: {
        readonly postcode?: string | null;
        readonly state: string;
      };
      readonly batteryMetrics: {
        readonly commonStatus?: BatteryStatus | null;
        readonly multipleStatuses?: boolean;
        readonly totalCapacity?: number | null;
        readonly totalTimeRemaining?: number | null;
      };
      readonly devices: {
        readonly total: number;
      };
      readonly health: {
        readonly commonStatus: DeviceHealth | null;
        readonly multipleStatuses: boolean;
        readonly status: DeviceHealth;
      } | null;
      readonly id: string;
      readonly name: string;
      readonly type?: string | null;
    }>;
    readonly pageInfo: {
      readonly hasNext: boolean | null;
      readonly hasPrevious: boolean | null;
      readonly page: number;
      readonly size: number;
      readonly total: number;
    };
    readonly total: number;
  };
};
export type SitesTableQuery = {
  response: SitesTableQuery$data;
  variables: SitesTableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v2 = {
  "defaultValue": 1,
  "kind": "LocalArgument",
  "name": "page"
},
v3 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "withBatteryCapacity"
},
v5 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "withBatteryStatus"
},
v6 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "withBatteryTimeRemaining"
},
v7 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "withPostcode"
},
v8 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "withSiteType"
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v10 = [
  (v9/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "commonStatus",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "multipleStatuses",
  "storageKey": null
},
v13 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filters",
        "variableName": "filter"
      },
      {
        "kind": "Literal",
        "name": "onlyProvisioningStatuses",
        "value": "Active"
      },
      {
        "kind": "Variable",
        "name": "orderBy",
        "variableName": "orderBy"
      },
      {
        "kind": "Variable",
        "name": "page",
        "variableName": "page"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      }
    ],
    "concreteType": "PaginatedSites",
    "kind": "LinkedField",
    "name": "sites",
    "plural": false,
    "selections": [
      (v9/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "condition": "withSiteType",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              }
            ]
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Address",
            "kind": "LinkedField",
            "name": "address",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "state",
                "storageKey": null
              },
              {
                "condition": "withPostcode",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "postcode",
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PaginatedDevices",
            "kind": "LinkedField",
            "name": "devices",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SiteDeviceHealth",
            "kind": "LinkedField",
            "name": "health",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              (v11/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SiteBatteryMetrics",
            "kind": "LinkedField",
            "name": "batteryMetrics",
            "plural": false,
            "selections": [
              {
                "condition": "withBatteryStatus",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  (v11/*: any*/),
                  (v12/*: any*/)
                ]
              },
              {
                "condition": "withBatteryCapacity",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "unit",
                        "value": "AmpHour"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "totalCapacity",
                    "storageKey": "totalCapacity(unit:\"AmpHour\")"
                  }
                ]
              },
              {
                "condition": "withBatteryTimeRemaining",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "unit",
                        "value": "Hours"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "totalTimeRemaining",
                    "storageKey": "totalTimeRemaining(unit:\"Hours\")"
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "page",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "size",
            "storageKey": null
          },
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasNext",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasPrevious",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": "overallSites",
    "args": null,
    "concreteType": "PaginatedSites",
    "kind": "LinkedField",
    "name": "sites",
    "plural": false,
    "selections": (v10/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SitesTableQuery",
    "selections": (v13/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v8/*: any*/),
      (v7/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "SitesTableQuery",
    "selections": (v13/*: any*/)
  },
  "params": {
    "cacheID": "9ed0ed4c0c8324246cd293fc340fb142",
    "id": null,
    "metadata": {},
    "name": "SitesTableQuery",
    "operationKind": "query",
    "text": "query SitesTableQuery(\n  $page: Int = 1\n  $search: String = \"\"\n  $orderBy: SiteOrdering\n  $filter: SiteFilter\n  $withSiteType: Boolean = false\n  $withPostcode: Boolean = false\n  $withBatteryStatus: Boolean = false\n  $withBatteryCapacity: Boolean = false\n  $withBatteryTimeRemaining: Boolean = false\n) {\n  sites(page: $page, search: $search, onlyProvisioningStatuses: Active, orderBy: $orderBy, filters: $filter) {\n    total\n    data {\n      __typename\n      id\n      name\n      type @include(if: $withSiteType)\n      address {\n        state\n        postcode @include(if: $withPostcode)\n      }\n      devices {\n        total\n      }\n      health {\n        status\n        commonStatus\n        multipleStatuses\n      }\n      batteryMetrics {\n        commonStatus @include(if: $withBatteryStatus)\n        multipleStatuses @include(if: $withBatteryStatus)\n        totalCapacity(unit: AmpHour) @include(if: $withBatteryCapacity)\n        totalTimeRemaining(unit: Hours) @include(if: $withBatteryTimeRemaining)\n      }\n    }\n    pageInfo {\n      page\n      size\n      total\n      hasNext\n      hasPrevious\n    }\n  }\n  overallSites: sites {\n    total\n  }\n}\n"
  }
};
})();

(node as any).hash = "805272d49af826bfb5e68e85fda97745";

export default node;
