import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { Paths } from 'lib/routes';

import { BatteryTestCard } from '../components/BatteryTestCard/BatteryTestCard';
import { CardSection } from '../components/CardSection';
import { CompletedTests_tasks$key } from './__generated__/CompletedTests_tasks.graphql';

export interface CompletedTestsProps {
    tasks: CompletedTests_tasks$key | null;
}

export const CompletedTests: FC<CompletedTestsProps> = ({ tasks }) => {
    const result = useFragment<CompletedTests_tasks$key>(
        graphql`
            fragment CompletedTests_tasks on PaginatedTasks {
                total
                data {
                    id
                    ...BatteryTestCard_data
                }
            }
        `,
        tasks
    );

    if (!result || result?.data.length === 0) {
        return null;
    }

    return (
        <CardSection title='Completed tests' to={Paths.TestsCompleted} testId='completed-section'>
            {result.data.map(task => {
                return <BatteryTestCard key={task.id} task={task} />;
            })}
        </CardSection>
    );
};
