import React, { FC } from 'react';

import { PagedActivityLog } from 'components';
import { getDateTimeFormat } from 'lib/dateFormatter';

import type { Device } from './FirmwareTableQuery';

interface TableDeviceBodyProps {
    device: Device;
}

export const TableDeviceBody: FC<TableDeviceBodyProps> = ({ device }) => {
    const deviceTS = device.lastUpdate ? getDateTimeFormat(device.lastUpdate as string) : 'Unknown';

    return (
        <div className='font-CynthoNext-Light text-customCoral text-base whitespace-nowrap '>
            Activity log:
            <div className='px-2 pt-2'>
                <PagedActivityLog device={device.id} />
            </div>
            <div className='flex flex-row pt-5 '>
                <div className='font-CynthoNext-Light text-customCoral text-base whitespace-pre'>Last Collected: </div>
                <div className='font-CynthoNext-Light text-customEggplant'>{deviceTS}</div>
            </div>
        </div>
    );
};
