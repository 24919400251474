import { FilterDefinition, FilterValueMap, RangeFilter } from '../common';
import {
    BatteryTypeCapacityFilterUI,
    BatteryTypeCellsPerBlocFilterUI,
    BatteryTypeManufacturerFilterUI,
    BatteryTypeModelFilterUI,
    BatteryTypePeukertsConstantFilterUI,
    BatteryTypeTechnologyFilterUI,
} from './components';
import { BatteryTypeCellCapacityRatingFilterUI } from './components/BatteryTypeCellCapacityRatingFilterUI';
import { BatteryTypeCellReferenceTemperatureFilterUI } from './components/BatteryTypeCellReferenceTemperatureFilterUI';
import {
    BatteryTypeCellCapacityRatingFilter,
    BatteryTypeCellReferenceTemperatureFilter,
    BatteryTypeCellsPerBlocFilter,
    BatteryTypeManModelFilter,
    BatteryTypePeukertsConstantFilter,
    BatteryTypeTableColumn,
    BatteryTypeTechnologyFilter,
} from './types';

export type BatteryTypeColumnFilterMap = {
    [BatteryTypeTableColumn.Manufacturer]: BatteryTypeManModelFilter[];
    [BatteryTypeTableColumn.Model]: BatteryTypeManModelFilter[];
    [BatteryTypeTableColumn.Capacity]: RangeFilter | null;
    [BatteryTypeTableColumn.Technology]: BatteryTypeTechnologyFilter[];
    [BatteryTypeTableColumn.CellCapacityRating]: RangeFilter | null;
    [BatteryTypeTableColumn.CellReferenceTemperature]: RangeFilter | null;
    [BatteryTypeTableColumn.CellsPerBloc]: RangeFilter | null;
    [BatteryTypeTableColumn.PeukertsConstant]: RangeFilter | null;
};

export const DefaultValues: FilterValueMap<BatteryTypeColumnFilterMap> = {
    [BatteryTypeTableColumn.Manufacturer]: [],
    [BatteryTypeTableColumn.Model]: [],
    [BatteryTypeTableColumn.Capacity]: null,
    [BatteryTypeTableColumn.Technology]: [],
    [BatteryTypeTableColumn.CellCapacityRating]: null,
    [BatteryTypeTableColumn.CellReferenceTemperature]: null,
    [BatteryTypeTableColumn.CellsPerBloc]: null,
    [BatteryTypeTableColumn.PeukertsConstant]: null,
};

export const StaticBatteryTypeFilterDefinitions: FilterDefinition<BatteryTypeTableColumn>[] = [
    {
        type: 'multi',
        name: 'Manufacturer',
        category: 'Manufacturer',
        column: BatteryTypeTableColumn.Manufacturer,
        component: () => BatteryTypeManufacturerFilterUI,
        describeValue: (value: BatteryTypeManModelFilter) => value.name,
    },
    {
        type: 'multi',
        name: 'Model',
        category: 'Model',
        column: BatteryTypeTableColumn.Model,
        component: () => BatteryTypeModelFilterUI,
        describeValue: (value: BatteryTypeManModelFilter) => value.name,
    },
    {
        type: 'single',
        name: 'Capacity',
        category: 'Capacity',
        column: BatteryTypeTableColumn.Capacity,
        component: () => BatteryTypeCapacityFilterUI,
        describeValue: (value: RangeFilter) => `${value.min} - ${value.max}`,
    },
    {
        type: 'multi',
        name: 'Technology',
        category: 'Technology',
        column: BatteryTypeTableColumn.Technology,
        component: () => BatteryTypeTechnologyFilterUI,
        describeValue: (value: BatteryTypeTechnologyFilter) => value.name,
    },
    {
        type: 'single',
        name: 'Capacity Rating',
        category: 'Capacity Rating',
        column: BatteryTypeTableColumn.CellCapacityRating,
        component: () => BatteryTypeCellCapacityRatingFilterUI,
        describeValue: (value: BatteryTypeCellCapacityRatingFilter) => `${value.min} - ${value.max}`,
    },
    {
        type: 'single',
        name: 'Reference Temperature',
        category: 'Reference Temperature',
        column: BatteryTypeTableColumn.CellReferenceTemperature,
        component: () => BatteryTypeCellReferenceTemperatureFilterUI,
        describeValue: (value: BatteryTypeCellReferenceTemperatureFilter) => `${value.min}°C - ${value.max}°C`,
    },
    {
        type: 'single',
        name: 'Cells per Bloc',
        category: 'Cells per Bloc',
        column: BatteryTypeTableColumn.CellsPerBloc,
        component: () => BatteryTypeCellsPerBlocFilterUI,
        describeValue: (value: BatteryTypeCellsPerBlocFilter) => `${value.min} - ${value.max}`,
    },
    {
        type: 'single',
        name: 'Peukerts Constant',
        category: 'Peukerts Constant',
        column: BatteryTypeTableColumn.PeukertsConstant,
        component: () => BatteryTypePeukertsConstantFilterUI,
        describeValue: (value: BatteryTypePeukertsConstantFilter) => `${value.min} - ${value.max}`,
    },
];
