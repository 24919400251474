import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import {
    ArrowDownCircleIcon,
    CircleAlertIcon,
    CircleCheckIcon,
    CircleCrossIcon,
    CircleHelpIcon,
    Link,
    SmallMetricRow,
} from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { wattToKillowattString } from 'lib/numberFormatters';

import { makeLinkToMetric } from '../../../../../explore/lib/link';
import { Operation } from '../../../../../explore/types';
import { DeviceHealth, ModuleRow_data$key } from './__generated__/ModuleRow_data.graphql';

export interface ModuleRowProps {
    module: ModuleRow_data$key;
    deviceId: string;
}

export const ModuleRow: FC<ModuleRowProps> = ({ module, deviceId }) => {
    const data = useFragment(Fragment, module);

    let latestOutputPower: number | null = null;
    if (data.metrics?.latestOutputPower) {
        latestOutputPower = Math.round(data.metrics?.latestOutputPower);
    }

    let maximumPower: number | null = null;
    if (data.specs?.output?.maximumPower) {
        maximumPower = Math.round(data.specs?.output?.maximumPower);
    }

    let latestOutputCurrent: number | null = null;
    if (data.metrics?.latestOutputCurrent) {
        latestOutputCurrent = Math.round(data.metrics?.latestOutputCurrent);
    }

    let latestTemperature: number | null = null;
    if (data.metrics?.latestTemperature) {
        latestTemperature = Math.round(data.metrics?.latestTemperature);
    }

    let manufacturerModel: string;
    if (!data.specs?.manufacturer && !data.specs?.model) {
        manufacturerModel = 'Unknown module';
    } else {
        manufacturerModel = `${data.specs?.manufacturer} ${data.specs?.model}`.trim();
    }

    return (
        <div>
            <SmallMetricRow
                label={data.label}
                statusIcon={getTileIcon(data.metrics.status)}
                column1={
                    <div className='flex flex-col justify-start items-start leading-none'>
                        <div className='text-xl font-CynthoNext-SemiBold'>{manufacturerModel}</div>
                        <div className='text-sm font-CynthoNext-Light'>
                            {getPowerUsageText(latestOutputPower, maximumPower)}
                        </div>
                        <div>
                            <span className='text-sm font-CynthoNext-Light'>Serial: </span>
                            <span className='text-sm font-CynthoNext-Regular'>{data.serialNumber ?? '-'}</span>
                        </div>
                    </div>
                }
                column2={
                    <div className='flex flex-col justify-start items-start leading-none'>
                        <Link
                            className='hover:underline'
                            to={makeLinkToMetric(deviceId, {
                                metric: `RectifierModuleOutputCurrent.${data.label}`,
                                op: Operation.Average,
                            })}
                        >
                            <span className='text-xl font-CynthoNext-SemiBold'>{latestOutputCurrent ?? '-'}</span>
                            <span className='text-sm font-CynthoNext-Light'>A Output</span>
                        </Link>
                        <Link
                            className='hover:underline'
                            to={makeLinkToMetric(deviceId, {
                                metric: `RectifierModuleTemperature.${data.label}`,
                                op: Operation.Average,
                            })}
                        >
                            <span className='text-sm font-CynthoNext-Regular'>{latestTemperature ?? '-'}</span>
                            <span className='text-sm font-CynthoNext-Light'>°C</span>
                        </Link>
                    </div>
                }
                error={data.metrics.status === 'Healthy' ? false : true}
            />
        </div>
    );
};

function getPowerUsageText(latestOutputPower: number | null, maximumPower: number | null): string {
    if (latestOutputPower && maximumPower) {
        return `${wattToKillowattString(latestOutputPower)} of ${wattToKillowattString(maximumPower)} Used`;
    } else if (latestOutputPower && !maximumPower) {
        return `${wattToKillowattString(latestOutputPower)} Used`;
    } else if (!latestOutputPower && maximumPower) {
        return `${wattToKillowattString(maximumPower)} Total`;
    } else {
        return '-W Used';
    }
}

function getTileIcon(status: DeviceHealth): React.ReactNode {
    switch (status) {
        case 'Healthy':
            return <CircleCheckIcon />;
        case 'Degraded':
            return <ArrowDownCircleIcon />;
        case 'Critical':
            return <CircleAlertIcon />;
        case 'Offline':
            return <CircleCrossIcon />;
        case 'Unknown':
            return <CircleHelpIcon />;
    }
}

const Fragment = graphql`
    fragment ModuleRow_data on RectifierModule {
        id
        label
        specs {
            manufacturer
            model
            output {
                maximumPower(unit: Watt)
            }
        }
        serialNumber
        metrics {
            status
            latestTemperature
            latestOutputCurrent
            latestOutputPower(unit: Watt)
        }
    }
`;
