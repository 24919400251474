/**
 * @generated SignedSource<<4821c1b5ee5ea972f92676112261cb36>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BatteryTestReason = "AcFail" | "Accata" | "CompanionDischarge" | "ExternalTest" | "LowReferenceVoltage" | "Unknown" | "%future added value";
export type BatteryTestState = "Aborted" | "Analyzing" | "Failed" | "Finalizing" | "InProgress" | "Inconclusive" | "Passed" | "Scheduled" | "SmartStart" | "Waiting" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ViewBatteryTestResultCard_test$data = {
  readonly abortedTime: string | null;
  readonly cause: BatteryTestReason;
  readonly commencedTime: string | null;
  readonly companion: {
    readonly device: {
      readonly id: string;
      readonly name: string;
    };
    readonly test: {
      readonly id: string;
      readonly state: BatteryTestState;
      readonly " $fragmentSpreads": FragmentRefs<"DualPlaneResult_test" | "TestParameterViewCombined_test" | "TestResultHeader_test">;
    };
  } | null;
  readonly completedTime: string | null;
  readonly device: {
    readonly id: string;
    readonly name: string;
    readonly site: {
      readonly address: {
        readonly address: string | null;
        readonly state: string;
      };
      readonly id: string;
      readonly name: string;
    };
    readonly type: {
      readonly displayName: string;
    };
  };
  readonly id: string;
  readonly state: BatteryTestState;
  readonly task: {
    readonly id: string;
    readonly name: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"BlocHealthTable_test" | "DataDisplay_test" | "DualPlaneResult_test" | "MetricsView_test" | "TestParameterViewCombined_test" | "TestParameterView_test" | "TestResultDisplay_test" | "TestResultHeader_test">;
  readonly " $fragmentType": "ViewBatteryTestResultCard_test";
};
export type ViewBatteryTestResultCard_test$key = {
  readonly " $data"?: ViewBatteryTestResultCard_test$data;
  readonly " $fragmentSpreads": FragmentRefs<"ViewBatteryTestResultCard_test">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v4 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "TestParameterViewCombined_test"
},
v5 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "DualPlaneResult_test"
},
v6 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "TestResultHeader_test"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ViewBatteryTestResultCard_test",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "BatteryTest",
      "kind": "LinkedField",
      "name": "task",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commencedTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completedTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "abortedTime",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TestParameterView_test"
    },
    (v4/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MetricsView_test"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DataDisplay_test"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BlocHealthTable_test"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cause",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TestResultDisplay_test"
    },
    (v5/*: any*/),
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Device",
      "kind": "LinkedField",
      "name": "device",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "DeviceType",
          "kind": "LinkedField",
          "name": "type",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Site",
          "kind": "LinkedField",
          "name": "site",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Address",
              "kind": "LinkedField",
              "name": "address",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "address",
                  "storageKey": null
                },
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BatteryTestResultCompanion",
      "kind": "LinkedField",
      "name": "companion",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Device",
          "kind": "LinkedField",
          "name": "device",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DeviceBatteryTestResults",
          "kind": "LinkedField",
          "name": "test",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v3/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DeviceBatteryTestResults",
  "abstractKey": null
};
})();

(node as any).hash = "c5153008f96e8f38e7980a6aad9f1d8d";

export default node;
