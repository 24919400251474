import React, { Suspense, lazy } from 'react';
import { RelayEnvironmentProvider } from 'react-relay';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { ModalContainer, ToastContainer } from '@accesstel/pcm-ui';
import '@accesstel/pcm-ui/dist/index.css';
import '@accesstel/pcm-ui/fonts/fonts.css';

import { fetchCurrentUser, setup } from 'lib/auth';
import { GlobalLoading } from 'views/global/Loading';
import { Routes } from 'views/route';

import { getGlobalEnvironment } from './lib/environment';
import './style/style.css';

setup();
const initialUser = fetchCurrentUser();

const AuthenticationController = lazy(() => import('./components/AuthenticationController'));

const router = createBrowserRouter(Routes);

function App(): JSX.Element {
    return (
        <>
            <RelayEnvironmentProvider environment={getGlobalEnvironment()}>
                <Suspense fallback={<GlobalLoading />}>
                    <AuthenticationController initialUser={initialUser}>
                        <RouterProvider router={router} fallbackElement={<GlobalLoading />} />
                    </AuthenticationController>
                    <ModalContainer />
                    <div className='col-start-2 ml-72'>
                        <ToastContainer />
                    </div>
                </Suspense>
            </RelayEnvironmentProvider>
        </>
    );
}

export default App;
