import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { CircleStopIcon, Menu, MenuItem, StatusLabel, UserIcon2 } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { IconWithStatus } from 'components';
import { batteryTestStateToStatusLabelConfig } from 'lib/statusLabelFormatter';
import { formatAsCauseSentence, getNiceStatusName } from 'lib/textFormatters';
import { DateTime } from 'luxon';

import { formatAPIDate } from '../common';
import { DateDisplay } from './DateDisplay';
import { TestResultHeader_test$data, TestResultHeader_test$key } from './__generated__/TestResultHeader_test.graphql';

interface TestResultHeaderProps {
    test: TestResultHeader_test$key;
    otherTest?: TestResultHeader_test$key | null;
    menuItems: MenuItem[];
}

export const TestResultHeader: FC<TestResultHeaderProps> = ({ test, otherTest, menuItems }) => {
    const primaryResult = useFragment<TestResultHeader_test$key>(TestResultHeaderFragment, test);
    const secondaryResult = useFragment<TestResultHeader_test$key>(TestResultHeaderFragment, otherTest ?? null);

    const isAccataTest = primaryResult.task != null;

    let startTime: DateTime;
    let endTime: DateTime;

    [startTime, endTime] = getStartAndEndTimes(primaryResult);

    if (secondaryResult) {
        const [secondaryStartTime, secondaryEndTime] = getStartAndEndTimes(secondaryResult);

        startTime = startTime < secondaryStartTime ? startTime : secondaryStartTime;
        endTime = endTime > secondaryEndTime ? endTime : secondaryEndTime;
    }

    let bestCause = primaryResult.cause;
    if (secondaryResult) {
        if (bestCause === 'CompanionDischarge') {
            bestCause = secondaryResult.cause;
        }
    }

    let bestState = primaryResult.state;
    if (secondaryResult) {
        const secondaryState = secondaryResult.state;

        // Display in-progress if one of the tests is in progress
        if (secondaryState === 'InProgress') {
            bestState = secondaryState;
        }

        // Display failed if one of the tests is failed
        if (secondaryState === 'Failed') {
            bestState = secondaryState;
        }

        // Display passed if one of the tests is passed
        if (secondaryState === 'Passed') {
            bestState = secondaryState;
        }

        // Otherwise display the primary state
    }

    let creatorName = primaryResult.task?.creator.name ?? primaryResult.task?.creator.email;
    if (secondaryResult && !creatorName) {
        creatorName = secondaryResult.task?.creator.name ?? secondaryResult.task?.creator.email;
    }

    let title: string;

    if (secondaryResult) {
        title = `Combined results for both planes`;
    } else {
        title = `Results for ${primaryResult.device.name}`;
    }

    return (
        <div className='px-8'>
            <div className='flex flex-row justify-between items-start relative'>
                <div>
                    <div className='font-CynthoNext-Regular text-3xl '>{title}</div>
                    {isAccataTest && (
                        <div className='font-CynthoNext-Light'>As part of test {primaryResult.task?.name ?? ''}</div>
                    )}
                    {!isAccataTest && (
                        <div className='font-CynthoNext-Light'>Caused by {formatAsCauseSentence(bestCause)}</div>
                    )}
                </div>
                <div className='flex flex-row space-x-2'>
                    <StatusLabel
                        label={getNiceStatusName(bestState).toUpperCase()}
                        {...batteryTestStateToStatusLabelConfig(bestState)}
                    />
                    <Menu id='test-props-menu' menuItems={menuItems} />
                </div>
            </div>
            <div className='flex gap-6 mt-4'>
                <DateDisplay
                    startTime={startTime.toJSDate()}
                    endTime={endTime.toJSDate()}
                    future={primaryResult.state === 'Scheduled'}
                />
                {creatorName && (
                    <IconWithStatus
                        icon={<UserIcon2 />}
                        // TODO: Put link here to see what else the user has done
                        label={`Scheduled by ${creatorName ?? 'Unknown'}`}
                    />
                )}
                {!creatorName && <IconWithStatus icon={<UserIcon2 />} label={`Scheduled automatically`} />}
            </div>
            {primaryResult.abortedBy && primaryResult.abortedTime && (
                <div className='flex gap-6 text-customCoral'>
                    <IconWithStatus
                        icon={<CircleStopIcon />}
                        label={`Aborted at ${formatAPIDate(primaryResult.abortedTime)} by ${
                            primaryResult.abortedBy.name ?? primaryResult.abortedBy.email ?? 'Unknown'
                        }`}
                    />
                </div>
            )}
        </div>
    );
};

function getStartAndEndTimes(test: TestResultHeader_test$data): [DateTime, DateTime] {
    let startTime: DateTime;
    let endTime: DateTime;

    switch (test.state) {
        case 'Scheduled':
            startTime = DateTime.fromISO(test.task!.schedule!.time as string);
            endTime = DateTime.now();
            break;
        case 'Aborted':
        case 'Failed':
        case 'Inconclusive':
        case 'Finalizing':
        case 'Analyzing':
        case 'Passed':
            startTime = DateTime.fromISO(test.commencedTime as string);
            endTime = DateTime.fromISO(test.completedTime as string);
            break;
        case 'InProgress':
            startTime = DateTime.fromISO(test.commencedTime as string);
            endTime = DateTime.now();
            break;
        default:
            if (test.task?.createdTime) {
                startTime = DateTime.fromISO(test.task?.createdTime as string);
            } else {
                // This is should not happen unless we forgot a state
                startTime = DateTime.now();
            }
            endTime = DateTime.now();
            break;
    }

    return [startTime, endTime];
}

const TestResultHeaderFragment = graphql`
    fragment TestResultHeader_test on DeviceBatteryTestResults {
        state
        task {
            name
            createdTime
            creator {
                name
                email
            }
            schedule {
                time
            }
        }
        commencedTime
        completedTime
        cause
        abortedTime
        abortedBy {
            name
            email
        }
        device {
            name
        }
    }
`;
