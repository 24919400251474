/**
 * @generated SignedSource<<3174b5efd28e252a1b6aa37b8f0d3263>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BatteryTaskState = "Aborted" | "Failed" | "InProgress" | "Inconclusive" | "PartialFail" | "PartialPass" | "Passed" | "Scheduled" | "%future added value";
export type BatteryTestType = "Capacity" | "Custom" | "Quick" | "%future added value";
export type TaskRepeat = "BiMonthly" | "Monthly" | "Never" | "Quarterly" | "%future added value";
export type TaskState = "Cancelled" | "Completed" | "InProgress" | "Scheduled" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OverviewFields_task$data = {
  readonly abortedTime: string | null;
  readonly abortedUser: {
    readonly email: string | null;
    readonly name: string | null;
  } | null;
  readonly cancelledTime: string | null;
  readonly cancelledUser: {
    readonly email: string | null;
    readonly name: string | null;
  } | null;
  readonly commencedTime: string | null;
  readonly completedTime: string | null;
  readonly createdTime: string;
  readonly creator: {
    readonly name: string | null;
  };
  readonly overallState: TaskState;
  readonly schedule: {
    readonly repeat: TaskRepeat;
    readonly time: string;
  };
  readonly testState: BatteryTaskState;
  readonly totalDevice: {
    readonly data: ReadonlyArray<{
      readonly id: string;
    }>;
    readonly total: number;
  };
  readonly type: BatteryTestType;
  readonly usingSmartStart: boolean;
  readonly " $fragmentType": "OverviewFields_task";
};
export type OverviewFields_task$key = {
  readonly " $data"?: OverviewFields_task$data;
  readonly " $fragmentSpreads": FragmentRefs<"OverviewFields_task">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OverviewFields_task",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "overallState",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commencedTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cancelledTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "cancelledUser",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completedTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "usingSmartStart",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskSchedule",
      "kind": "LinkedField",
      "name": "schedule",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "repeat",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "time",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "testState",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "abortedTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "abortedUser",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": "totalDevice",
      "args": null,
      "concreteType": "PaginatedDevices",
      "kind": "LinkedField",
      "name": "devices",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Device",
          "kind": "LinkedField",
          "name": "data",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BatteryTest",
  "abstractKey": null
};
})();

(node as any).hash = "df6a294d24ff73edf75a9ba7c9764d04";

export default node;
