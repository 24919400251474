import React, { FC } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { AlertBanner } from 'components/AlertBanner';

import { useSiteDevices } from './lib/context';

export const DeviceLayout: FC = () => {
    const devices = useSiteDevices();
    const { deviceId } = useParams() as { deviceId: string };

    const exists = devices.some(device => device.device.id === deviceId || device.companion?.id === deviceId);

    if (exists) {
        // Render whatever is supposed to be shown
        return <Outlet context={devices} />;
    }

    return <AlertBanner title='Device not found' message='The device you are looking for does not exist.' />;
};
