import { createColumnHelper } from '@tanstack/react-table';
import { SiteTableColumn } from 'filters/site';
import { ColumnWithId } from 'layouts';
import {
    BatteryCapacityColumn,
    BatteryStatusColumn,
    BatteryTotalTimeRemainingColumn,
    DeviceCountColumn,
    DeviceStatusColumn,
    PostcodeColumn,
    StateColumn,
    TypeColumn,
} from 'lib/tables/site/columns';

import type { SitesTableQuery$data } from './__generated__/SitesTableQuery.graphql';

type Site = SitesTableQuery$data['sites']['data'][number];

const columnHelper = createColumnHelper<Site>();

// Custom version with "Name" as the header instead of "Site"
export const NameColumn = columnHelper.accessor('name', {
    id: SiteTableColumn.Name,
    header: 'NAME',
    meta: {
        filterable: true,
        sortable: true,
        maxWidth: '30rem',
    },
});

export const BaseTableColumns = [NameColumn, TypeColumn, StateColumn, DeviceCountColumn] as ColumnWithId<
    SiteTableColumn,
    Site
>[];

export const AllTableColumns = [
    ...BaseTableColumns,
    DeviceStatusColumn,
    PostcodeColumn,
    BatteryStatusColumn,
    BatteryCapacityColumn,
    BatteryTotalTimeRemainingColumn,
] as ColumnWithId<SiteTableColumn, Site>[];
