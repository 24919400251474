import React, { FC } from 'react';

import { CommonFilterProps } from 'filters/common';

import { BatteryTypeCellsPerBlocFilter } from '../types';
import { BatteryTypeFilterRange } from './BatteryTypeFilterRange';

export type BatteryTypeCellsPerBlocFilterUIProps = CommonFilterProps<BatteryTypeCellsPerBlocFilter | null>;

export const BatteryTypeCellsPerBlocFilterUI: FC<BatteryTypeCellsPerBlocFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => (
    <BatteryTypeFilterRange
        current={current}
        onClearOrBack={onClearOrBack}
        onClose={onClose}
        onSubmit={onSubmit}
        title='Filter by Number of Cells per Bloc'
        distributionType='CellsPerBloc'
        discrete
        showBack={hasPrevious}
    />
);
