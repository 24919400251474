/**
 * @generated SignedSource<<dc8c2b0da7fc0d8f8a0b8956c9185328>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ActiveDevices$data = {
  readonly devices: {
    readonly total: number;
  };
  readonly " $fragmentType": "ActiveDevices";
};
export type ActiveDevices$key = {
  readonly " $data"?: ActiveDevices$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActiveDevices">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActiveDevices",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PaginatedDevices",
      "kind": "LinkedField",
      "name": "devices",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "4f842d53471c92334981e2fc15be8776";

export default node;
