import React, { FC, useCallback, useEffect, useState } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';
import { generatePath } from 'react-router-dom';

import { ActivityLog, ActivityType, LoadableContentArea } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { Paths } from 'lib/routes';

import { PagedActivityLogQuery } from './__generated__/PagedActivityLogQuery.graphql';

export interface PagedActivityLogProps {
    device: string;
}

const LimitInterval = 5;

export const PagedActivityLog: FC<PagedActivityLogProps> = ({ device }) => {
    const environment = useRelayEnvironment();
    const [activityLogEntries, setActivityLogEntries] = useState<ActivityType[] | null>(null);
    const [limit, setLimit] = useState(5);
    const [hasError, setHasError] = useState(false);
    const [hasMore, setHasMore] = useState(false);

    // FIXME: The endpoint was changed from pagination to a date range. We need to ensure that this doesnt overload the UI
    const loadLog = useCallback(
        (limit: number) => {
            fetchQuery<PagedActivityLogQuery>(
                environment,
                graphql`
                    query PagedActivityLogQuery($id: ID!, $limit: Int!) {
                        activityLogs(device: $id, limit: $limit) {
                            total
                            hasMore
                            data {
                                source
                                description
                                timestamp
                                type
                                user {
                                    name
                                    email
                                }
                                link {
                                    __typename
                                    ... on Site {
                                        id
                                        name
                                    }
                                    ... on BatteryType {
                                        id
                                        manufacturer
                                        model
                                    }
                                    ... on Device {
                                        id
                                        name
                                    }
                                    ... on DeviceBatteryTestResults {
                                        id
                                        task {
                                            id
                                            name
                                        }
                                    }
                                    ... on BatteryTest {
                                        id
                                        taskName: name
                                    }
                                }
                            }
                        }
                    }
                `,
                {
                    id: device,
                    limit,
                }
            )
                .toPromise()
                .then(props => {
                    if (props?.activityLogs.data) {
                        const activityLog = props.activityLogs.data.map(rawLog => {
                            const activity: ActivityType = {
                                action: rawLog.description,
                                timestamp: new Date(rawLog.timestamp as string),
                                who: '',
                            };

                            if (rawLog.user) {
                                if (rawLog.user.name) {
                                    activity.who = rawLog.user.name;
                                } else {
                                    activity.who = rawLog.user.email || '';
                                }
                            } else {
                                activity.who = '';
                            }

                            if (rawLog.link) {
                                const { link } = rawLog;
                                switch (link.__typename) {
                                    case 'Site':
                                        activity.link = generatePath(Paths.EditSite, { id: link.id });
                                        activity.linkTooltip = link.name;
                                        break;
                                    case 'BatteryType':
                                        activity.link = generatePath(Paths.EditBatteryType, { id: link.id });
                                        activity.linkTooltip = `${link.manufacturer} ${link.model}`;
                                        break;
                                    case 'Device':
                                        activity.link = generatePath(Paths.EditDevice, { id: link.id });
                                        activity.linkTooltip = link.name;
                                        break;
                                    case 'DeviceBatteryTestResults':
                                        if (link.task) {
                                            activity.linkTooltip = link.task.name ?? 'Unnamed test';
                                            activity.link = `${Paths.TestsDetailsView}/${link.task.id}/${device}`;
                                        } else {
                                            activity.link = `${Paths.ReportBatteriesByTestDevice}/${link.id}/${device}`;
                                            // no task associated with test, making this an unplanned test
                                            activity.action = `Unplanned ${activity.action.toLowerCase()}`;
                                        }
                                        break;
                                    case 'BatteryTest':
                                        activity.link = `${Paths.TestsDetailsView}/${link.id}`;
                                        activity.linkTooltip = link.taskName ?? 'Unnamed test';
                                        break;
                                }
                            }

                            return activity;
                        });

                        setActivityLogEntries(activityLog);
                        setHasMore(
                            props.activityLogs.data.length !== props.activityLogs.total || props.activityLogs.hasMore
                        );
                    } else {
                        setActivityLogEntries([]);
                        setHasMore(false);
                    }
                })
                .catch(() => {
                    setActivityLogEntries([]);
                    setHasError(true);
                    setHasMore(false);
                });
        },
        [device, environment]
    );

    const onLoadMore = useCallback(() => {
        setLimit(currentLimit => currentLimit + LimitInterval);
    }, []);

    useEffect(() => {
        loadLog(limit);
    }, [limit, loadLog]);

    return (
        <LoadableContentArea
            data={activityLogEntries}
            error={hasError}
            render={data => {
                return <ActivityLog activity={data} moreItems={hasMore} loadMoreCallback={onLoadMore} />;
            }}
            className='h-40'
        />
    );
};
