import { object, string } from 'yup';

export const ResetFormValidationSchema = object({
    newPassword: string().required('Must provide password').min(8, 'Must have at least 8 characters'),
    confirmPassword: string()
        .required('Must provide password')
        .test({
            name: 'Matches',
            message: 'Password does not match',
            test(value, context) {
                return value === context.parent.newPassword;
            },
        }),
});

export interface ResetFormValues {
    newPassword: string;
    confirmPassword: string;
}

export const DefaultResetFormValues: ResetFormValues = {
    newPassword: '',
    confirmPassword: '',
};
