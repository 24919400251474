import { DomainAbsolute } from '@accesstel/pcm-ui';

import { DateRangeFilter } from 'components/FilterDateSelect/common';

import { MetricUnit } from './__generated__/MetricsExploreContentQuery.graphql';

export enum Operation {
    Sum = 'Sum',
    Average = 'Average',
    Minimum = 'Minimum',
    Maximum = 'Maximum',
}

export interface SelectedMetric {
    metric: string;
    op: Operation;
}

export interface DefinedMetric {
    metric: string;
    displayName: string;
    unit: MetricUnit | null;
}

export interface DataPoint {
    timestamp: Date;
    value: number | null;
}

export interface DataSeries {
    metric: string;
    op: Operation;
    data: DataPoint[];
}

export interface ViewState {
    selectedMetrics: SelectedMetric[];
    selectedDevices: string[];

    timeRange: DateRangeFilter;
    zoomedTimeRange: DomainAbsolute<Date>;
    isZoomed: boolean;
    isAtMaxZoom: boolean;

    showDeviceSelectionModal: boolean;
    showMetricSelectionModal: boolean;
}
