/**
 * @generated SignedSource<<35c4bd8db740c51d2087d3852f7504ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ExportJobProblem = "InvalidDevice" | "InvalidId" | "InvalidMetrics" | "InvalidRange" | "MissingCompanionTest" | "MissingDevices" | "MissingMetrics" | "RequestTooLarge" | "TestNotComplete" | "%future added value";
export type DateRangeFilter = {
  max: string;
  min: string;
};
export type exportSubmitMutation$variables = {
  deviceIds?: ReadonlyArray<string> | null;
  metrics: ReadonlyArray<string>;
  range?: DateRangeFilter | null;
};
export type exportSubmitMutation$data = {
  readonly exportMetrics: {
    readonly id?: string;
    readonly problems?: ReadonlyArray<ExportJobProblem>;
  } | null;
};
export type exportSubmitMutation = {
  response: exportSubmitMutation$data;
  variables: exportSubmitMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deviceIds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "metrics"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "range"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "deviceIds",
    "variableName": "deviceIds"
  },
  {
    "kind": "Variable",
    "name": "metrics",
    "variableName": "metrics"
  },
  {
    "kind": "Variable",
    "name": "range",
    "variableName": "range"
  },
  {
    "kind": "Literal",
    "name": "singleFile",
    "value": true
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "ExportJob",
  "abstractKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "problems",
      "storageKey": null
    }
  ],
  "type": "ExportJobProblemResponse",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "exportSubmitMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "exportMetrics",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "exportSubmitMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "exportMetrics",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "381c353f1d36cf227efe8317e956c297",
    "id": null,
    "metadata": {},
    "name": "exportSubmitMutation",
    "operationKind": "mutation",
    "text": "mutation exportSubmitMutation(\n  $deviceIds: [String!]\n  $metrics: [String!]!\n  $range: DateRangeFilter\n) {\n  exportMetrics(deviceIds: $deviceIds, metrics: $metrics, range: $range, singleFile: true) {\n    __typename\n    ... on ExportJob {\n      id\n    }\n    ... on ExportJobProblemResponse {\n      problems\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "90bb65a1b2f8122fb2ea778da475c54a";

export default node;
