import React from 'react';

import { createColumnHelper } from '@tanstack/react-table';
import { BatteryTestResultsColumn } from 'filters/battery-test-results-model';
import { ColumnWithId } from 'layouts';
import { formatDurationAsHoursAndMinutes, getDateTimeFormat } from 'lib/dateFormatter';
import { renderTableStatusCell } from 'lib/table-columns';
import { formatBatteryTestName } from 'lib/textFormatters';
import { DateTime } from 'luxon';

import {
    BatteryTestState,
    TestSelectModalContentQuery$data,
} from './__generated__/TestSelectModalContentQuery.graphql';

type BatteryTestResults = TestSelectModalContentQuery$data['batteryTestResults']['data'][number];

const columnHelper = createColumnHelper<BatteryTestResults>();

function renderNameColumn(row: BatteryTestResults) {
    return row.task?.name ?? <div className='italic'>{formatBatteryTestName(row.cause)}</div>;
}
function renderStatusCell(value: BatteryTestState) {
    return renderTableStatusCell(value);
}

function renderRunTimeCell(data: BatteryTestResults) {
    const from = data.commencedTime ? DateTime.fromISO(data.commencedTime) : DateTime.now();
    const to = data.completedTime;

    if (to) {
        return formatDurationAsHoursAndMinutes(from, DateTime.fromISO(to));
    } else {
        return formatDurationAsHoursAndMinutes(from, DateTime.now());
    }
}

export const TestNameColumn = columnHelper.accessor('task.name', {
    id: BatteryTestResultsColumn.TaskName,
    header: 'NAME',
    cell: ({ row }) => renderNameColumn(row.original),
    meta: {
        filterable: true,
        sortable: true,
    },
});
export const TypeColumn = columnHelper.accessor('task.type', {
    id: BatteryTestResultsColumn.Type,
    header: 'TYPE',
    cell: ({ cell }) => cell.getValue() ?? 'External',
    meta: {
        filterable: true,
        sortable: true,
    },
});
export const StatusColumn = columnHelper.accessor('state', {
    id: BatteryTestResultsColumn.State,
    cell: ({ cell }) => renderStatusCell(cell.getValue()),
    header: 'STATE',
    meta: {
        filterable: true,
        sortable: true,
    },
});
export const DateColumn = columnHelper.accessor('commencedTime', {
    id: BatteryTestResultsColumn.StartTime,
    header: 'DATE',
    cell: ({ cell }) => {
        if (cell.getValue() === null) {
            return <div className='italic'>Test not started</div>;
        }
        return getDateTimeFormat(cell.getValue() as string);
    },
    meta: {
        filterable: true,
        sortable: true,
    },
});
export const RunTimeColumn = columnHelper.display({
    id: BatteryTestResultsColumn.RunTime,
    header: 'RUN TIME',
    cell: ({ row }) => renderRunTimeCell(row.original),
    meta: {
        filterable: true,
        sortable: true,
    },
});

export const BaseTableColumns = [TestNameColumn, TypeColumn, StatusColumn, DateColumn, RunTimeColumn] as ColumnWithId<
    BatteryTestResultsColumn,
    BatteryTestResults
>[];
