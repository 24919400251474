/**
 * @generated SignedSource<<7c2051f9df5bb64ccd13747dcaf5cad1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BatteryOverviewQuery$variables = {};
export type BatteryOverviewQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CurrentCapacityDoughnut" | "CurrentStatusDoughnut">;
};
export type BatteryOverviewQuery = {
  response: BatteryOverviewQuery$data;
  variables: BatteryOverviewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviceCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BatteryOverviewQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "CurrentStatusDoughnut"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "CurrentCapacityDoughnut"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BatteryOverviewQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GlobalBatteryMetrics",
        "kind": "LinkedField",
        "name": "batteryMetrics",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BatteryStatusBreakdown",
            "kind": "LinkedField",
            "name": "statusBreakdown",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CapacityBreakdown",
            "kind": "LinkedField",
            "name": "remainingCapacityBreakdown",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rangeLow",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rangeHigh",
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "162c428502621cb114e93776b8369aae",
    "id": null,
    "metadata": {},
    "name": "BatteryOverviewQuery",
    "operationKind": "query",
    "text": "query BatteryOverviewQuery {\n  ...CurrentStatusDoughnut\n  ...CurrentCapacityDoughnut\n}\n\nfragment CurrentCapacityDoughnut on Query {\n  batteryMetrics {\n    remainingCapacityBreakdown {\n      rangeLow\n      rangeHigh\n      deviceCount\n    }\n  }\n}\n\nfragment CurrentStatusDoughnut on Query {\n  batteryMetrics {\n    statusBreakdown {\n      status\n      deviceCount\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1258ed1e8605636a4cca6842a23495e4";

export default node;
