import React from 'react';

import { formatDateFilter, formatDurationFilter } from 'filters/common/util';
import { TaskBatteryTestNameFilterUI, TaskDateFilterUI, TaskRunTimeFilterUI } from 'filters/task';
import { TestResultCauseFilter, TestResultStateFilterUI } from 'filters/test-result';
import { TestResultCauseFilterUI } from 'filters/test-result/components/TestResultCauseFilterUI';

import { FilterDefinition, FilterValueMap } from '../common';
import { DeviceTestResultTypeFilterUI } from './components';
import { describeBatteryTestType } from './helpers';
import {
    DeviceTestResultNameFilter,
    DeviceTestResultRunTimeFilter,
    DeviceTestResultStartTimeFilter,
    DeviceTestResultStateFilter,
    DeviceTestResultTableColumn,
    DeviceTestResultTypeFilter,
} from './types';

export type DeviceTestResultColumnFilterMap = {
    [DeviceTestResultTableColumn.Name]: DeviceTestResultNameFilter[];
    [DeviceTestResultTableColumn.State]: DeviceTestResultStateFilter[];
    [DeviceTestResultTableColumn.Type]: DeviceTestResultTypeFilter[];
    [DeviceTestResultTableColumn.RunTime]: DeviceTestResultRunTimeFilter | null;
    [DeviceTestResultTableColumn.StartTime]: DeviceTestResultStartTimeFilter | null;
    [DeviceTestResultTableColumn.Cause]: TestResultCauseFilter[];
};

export const DefaultValues: FilterValueMap<DeviceTestResultColumnFilterMap> = {
    [DeviceTestResultTableColumn.Name]: [],
    [DeviceTestResultTableColumn.State]: [],
    [DeviceTestResultTableColumn.Type]: [],
    [DeviceTestResultTableColumn.RunTime]: null,
    [DeviceTestResultTableColumn.StartTime]: null,
    [DeviceTestResultTableColumn.Cause]: [],
};

export const StaticDeviceTestResultFilterDefinitions: FilterDefinition<DeviceTestResultTableColumn>[] = [
    {
        type: 'multi',
        name: 'Task Name',
        category: 'Task Name',
        column: DeviceTestResultTableColumn.Name,
        component: meta => props => (
            <TaskBatteryTestNameFilterUI {...props} deviceIds={[(meta as { deviceId: string }).deviceId]} />
        ),
        describeValue: (value: DeviceTestResultNameFilter) => value.name,
    },
    {
        type: 'multi',
        name: 'State',
        category: 'State',
        column: DeviceTestResultTableColumn.State,
        component: () => TestResultStateFilterUI,
        describeValue: (value: DeviceTestResultStateFilter) => value.name,
    },
    {
        type: 'multi',
        name: 'Type',
        category: 'Type',
        column: DeviceTestResultTableColumn.Type,
        component: () => DeviceTestResultTypeFilterUI,
        describeValue: describeBatteryTestType,
    },
    {
        type: 'single',
        name: 'Run Time',
        category: 'Run Time',
        column: DeviceTestResultTableColumn.RunTime,
        component: () => TaskRunTimeFilterUI,
        describeValue: formatDurationFilter,
    },
    {
        type: 'single',
        name: 'Start Time',
        category: 'Start Time',
        column: DeviceTestResultTableColumn.StartTime,
        component: () => TaskDateFilterUI,
        describeValue: (value: DeviceTestResultStartTimeFilter) => formatDateFilter(value),
    },
    {
        type: 'multi',
        name: 'Discharge Cause',
        category: 'Discharge Cause',
        column: DeviceTestResultTableColumn.Cause,
        component: () => TestResultCauseFilterUI,
        describeValue: (value: TestResultCauseFilter) => value.name,
    },
];
