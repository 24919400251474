import React, { FC, useState } from 'react';

import { ChartFrame, LargeSparkline, LoadableContentArea, TimeseriesPoint } from '@accesstel/pcm-ui';

import { createMenuAction } from 'views/tasks/battery-health/components/MetricsLine';

import { TimeRange, useTimeRange, useTimeRangeQuery } from '../../../common';
import { queries_AverageEnergySparklineQuery } from '../../__generated__/queries_AverageEnergySparklineQuery.graphql';
import { averageEnergySparklineQuery } from '../../queries';
import style from './common.module.css';

export interface AverageEnergySparklineProps {
    timeRange: TimeRange;
}

export const AverageEnergySparkline: FC<AverageEnergySparklineProps> = ({ timeRange }) => {
    const [currentTimeRange, menuItems] = useTimeRange(timeRange);
    const [isZoomPaneOpen, setIsZoomPaneOpen] = useState(false);

    const { data: props, error, retry } = useTimeRangeQuery<queries_AverageEnergySparklineQuery>(
        averageEnergySparklineQuery,
        currentTimeRange
    );

    const zoomAction = createMenuAction({
        menuItems,
        menuSelected: currentTimeRange.id,
        isOpen: isZoomPaneOpen,
        setIsOpen: setIsZoomPaneOpen,
        menuPlaceholder: 'Zoom',
    });

    return (
        <ChartFrame title='Average energy use' variant='framed' actions={[zoomAction]} className={style.sparkline}>
            <LoadableContentArea
                data={props?.batteryMetrics.energy}
                error={!!error}
                onRetry={retry}
                className={style.chart_loading}
                variant='framed'
                render={data => {
                    let value: number | null;
                    if (data.values.length > 0) {
                        value = data.values[data.values.length - 1].value ?? 0;
                    } else {
                        value = null;
                    }

                    const history: TimeseriesPoint[] = data.values.map(value => ({
                        date: new Date(value.timestamp as string),
                        value: value.value,
                    }));

                    return (
                        <LargeSparkline
                            history={history}
                            percentChange={data.trend?.percent ?? 0}
                            value={value}
                            unit='kWh'
                        />
                    );
                }}
            />
        </ChartFrame>
    );
};
