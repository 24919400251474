import React, { FC, useMemo } from 'react';

import graphql from 'babel-plugin-relay/macro';
import { MultipleSelect } from 'components/MultipleSelect';
import { useQuery } from 'lib/query-helpers';

import { CommonFilterProps } from '../../common/types';
import { SiteTypeFilter } from '../types';
import { SiteTypeFilterUI_siteTypeQuery } from './__generated__/SiteTypeFilterUI_siteTypeQuery.graphql';

export type SiteTypeFilterUIProps = CommonFilterProps<SiteTypeFilter[]>;

export const SiteTypeFilterUI: FC<SiteTypeFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    const { data: props } = useQuery<SiteTypeFilterUI_siteTypeQuery>(
        graphql`
            query SiteTypeFilterUI_siteTypeQuery($search: String!, $limit: Int) {
                siteTypes(search: $search, limit: $limit)
            }
        `,
        {
            search: '',
            limit: 10,
        }
    );

    const options = useMemo<SiteTypeFilter[]>(() => {
        if (props) {
            return props.siteTypes.map(siteType => ({
                id: siteType,
                name: siteType,
            }));
        } else {
            return [];
        }
    }, [props]);

    return (
        <MultipleSelect
            options={options}
            active={current}
            title='Filter by type'
            renderItem={item => item.name}
            renderItemSimple={item => item.name}
            areItemsEqual={(itemA, itemB) => itemA.id === itemB.id}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            showBack={hasPrevious}
            onClose={onClose}
        />
    );
};
