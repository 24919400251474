import { useEffect } from 'react';

export const useDocumentTitle = (title?: string): void => {
    useEffect(() => {
        if (title) {
            document.title = `${title} - accata by accesstel`;
        }

        return () => {
            document.title = 'accata by accesstel';
        };
    }, [title]);
};
