import React, { FC } from 'react';

import { SiteNameFilter } from '../types';

export interface SiteNameFilterResultProps {
    filter: SiteNameFilter;
}

export const SiteNameFilterResult: FC<SiteNameFilterResultProps> = ({ filter }) => {
    if (filter.wildcard) {
        return (
            <div className='flex flex-row items-baseline space-x-2'>
                <span className='text-customEggplantWhite'>Matching</span>
                <span>{filter.name}</span>
            </div>
        );
    } else {
        return (
            <span>
                {filter.name}, {filter.state}
            </span>
        );
    }
};
