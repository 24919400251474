import React, { FC, useState } from 'react';
import { commitMutation } from 'react-relay';
import { useRelayEnvironment } from 'react-relay';

import { Button, ThemedPageHeading } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { logError } from 'lib/log';

import {
    CancelTaskModalMutation,
    CancelTaskModalMutation$variables,
} from './__generated__/CancelTaskModalMutation.graphql';
import style from './style.module.css';

interface CancelTaskModalProps {
    testId: string | undefined;
    taskName: string;
    recurringTest: boolean;
    setShowCancelModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CancelTaskModal: FC<CancelTaskModalProps> = ({ testId, taskName, recurringTest, setShowCancelModal }) => {
    const environment = useRelayEnvironment();
    const [taskCancelRequested, setTaskCancelRequested] = useState(false);
    const [confirmationHeading, setConfirmationHeading] = useState(`Cancelling: ${taskName}`);
    const [confirmationText, setConfirmationText] = useState('Test has been cancelled.');

    const handleCancelTask = () => {
        const variables: CancelTaskModalMutation$variables = {
            id: testId ?? '',
        };

        commitMutation<CancelTaskModalMutation>(environment, {
            mutation: graphql`
                mutation CancelTaskModalMutation($id: ID!) {
                    cancelTask(id: $id)
                }
            `,
            variables,
            onError: err => {
                logError('Unable to cancel test', err);
                setConfirmationHeading('Error');
                setConfirmationText('Unable to cancel the test, please try again later.');
                setTaskCancelRequested(true);
            },
            onCompleted: (response, errors) => {
                if (response.cancelTask === true) {
                    setTaskCancelRequested(true);
                }

                if (response.cancelTask === false || errors) {
                    setConfirmationHeading('Error');
                    setConfirmationText('Unable to cancel the test, please try again later.');
                    setTaskCancelRequested(true);
                }
            },
        });
    };

    return (
        <div className={style.cancel_abort_modal}>
            {!taskCancelRequested && (
                <>
                    <ThemedPageHeading value='Cancel Test' />
                    <div
                        className={`text-customEggplant font-CynthoNext-Regular text-base ${
                            recurringTest ? 'pb-1' : 'pb-12'
                        }`}
                    >
                        Are your sure you want to cancel this test?
                    </div>
                    {recurringTest && (
                        <div className=' font-CynthoNext-Regular text-xs pb-12'>
                            <span className='text-customCoral'>{`WARNING: `}</span>
                            <span className='text-customEggplant'>
                                Cancelling this test will cancel all future recurrences.
                            </span>
                        </div>
                    )}
                    <div className='space-x-2'>
                        <Button onClick={handleCancelTask} buttonText='Yes' buttonClasses='w-24' />
                        <Button
                            onClick={() => setShowCancelModal(false)}
                            buttonText='No'
                            variant='gray'
                            buttonClasses='w-24'
                        />
                    </div>
                </>
            )}
            {taskCancelRequested && (
                <>
                    <div className={`${style.confirmationHeading} text-customCoral font-CynthoNext-SemiBold`}>
                        {confirmationHeading}
                    </div>
                    <div className='text-customEggplant font-CynthoNext-Regular text-base pb-12'>
                        {confirmationText}
                    </div>
                    <Button
                        onClick={() => setShowCancelModal(false)}
                        buttonText='OK'
                        variant='gray'
                        buttonClasses='w-24'
                    />
                </>
            )}
        </div>
    );
};
