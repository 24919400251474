import React, { Dispatch, FC } from 'react';

import { Button, CheckIcon, FormikDateSelect, FormikTextBox } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import { Formik, FormikErrors, FormikTouched } from 'formik';
import { FormControlAction, FormControlParameters } from 'views/manage/device/add-edit/lib/reducer';

import { DeviceBatteryString, createDefaultBatteryString, getBatteryStringValidationSchema } from '../../schema';
import { BatteryTypeSelector } from '../BatteryTypeSelector';

interface StringEditorProps {
    initialValues?: DeviceBatteryString;
    initialErrors?: FormikErrors<DeviceBatteryString>;
    onSubmit: (values: DeviceBatteryString) => void;
    onCancel: () => void;

    // form control
    formControlState: FormControlParameters;
    formControlDispatch: Dispatch<FormControlAction>;
}

export const StringEditor: FC<StringEditorProps> = ({
    initialValues = createDefaultBatteryString(),
    initialErrors,
    onSubmit,
    onCancel,
    formControlState,
    formControlDispatch,
}) => {
    return (
        <Formik
            initialValues={initialValues}
            initialErrors={initialErrors}
            initialTouched={initialErrors as FormikTouched<DeviceBatteryString>}
            validationSchema={getBatteryStringValidationSchema(formControlState.addNewBatteryType)}
            onSubmit={onSubmit}
        >
            {({ submitForm, isValid }) => (
                <>
                    <BatteryTypeSelector
                        formControlState={formControlState}
                        formControlDispatch={formControlDispatch}
                    />
                    <div className='col-start-1'>
                        <FormikDateSelect
                            name={'installDate'}
                            placeHolder={'Install Date*'}
                            light
                            required
                            variant='outlined'
                            testId='install-date'
                        />
                    </div>
                    <div className='col-start-1'>
                        <FormikDateSelect
                            name={'manufactureDate'}
                            placeHolder={'Manufacture Date'}
                            maxDate={new Date()}
                            light
                            required={false}
                            variant='outlined'
                            testId='manufacture-date'
                        />
                    </div>
                    <div className='col-start-1'>
                        <FormikTextBox
                            name={'cellsPerString'}
                            placeHolder={'Number of Cells per String*'}
                            light
                            required
                            variant='outlined'
                            testId='number-of-cells-per-string'
                        />
                    </div>
                    {!isValid && (
                        <div className='col-start-1 col-span-2 text-customCoral font-CynthoNext-LightItalic text-xs'>
                            Incomplete Battery String Configuration
                        </div>
                    )}
                    <div className='space-x-8 col-span-2 col-start-1'>
                        <Button
                            buttonText='Confirm Battery String'
                            onClick={() => submitForm().catch(error => captureException(error))}
                            size='small'
                            type='button'
                            variant='gray'
                            icon={<CheckIcon />}
                        />
                        <Button buttonText='Cancel' onClick={onCancel} size='small' type='button' variant='white' />
                    </div>
                </>
            )}
        </Formik>
    );
};
