import React, { FC } from 'react';

import { MultipleSelect } from 'components/MultipleSelect';
import { CommonFilterProps } from 'filters/common';

import { TestResultStateFilter } from '../types';

export type TestResultStateFilterUIProps = CommonFilterProps<TestResultStateFilter[]>;

export const TestResultStateFilterUI: FC<TestResultStateFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    const options: TestResultStateFilter[] = [
        { id: 'Aborted', name: 'Aborted' },
        { id: 'Analyzing', name: 'Analyzing' },
        { id: 'Failed', name: 'Failed' },
        { id: 'Finalizing', name: 'Finalizing' },
        { id: 'InProgress', name: 'In Progress' },
        { id: 'Inconclusive', name: 'Inconclusive' },
        { id: 'Passed', name: 'Passed' },
        { id: 'Scheduled', name: 'Scheduled' },
        { id: 'SmartStart', name: 'Smart Start' },
        { id: 'Waiting', name: 'Waiting' },
    ];

    return (
        <MultipleSelect
            options={options}
            active={current}
            title='Filter by state'
            renderItem={item => item.name}
            renderItemSimple={item => item.name}
            areItemsEqual={(itemA, itemB) => itemA.id === itemB.id}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onClose={onClose}
            showBack={hasPrevious}
        />
    );
};
