import React, { ReactElement, useMemo } from 'react';

import graphql from 'babel-plugin-relay/macro';
import { FilterRange } from 'components/FilterRange';
import { RangeFilter } from 'filters/common';
import { useQuery } from 'lib/query-helpers';

import {
    TestResultFilterRange_unplannedTestDistributionQuery,
    UnplannedBatteryTestDistributionType,
} from './__generated__/TestResultFilterRange_unplannedTestDistributionQuery.graphql';

export interface TestResultFilterRangeProps {
    title: string;
    distributionType: UnplannedBatteryTestDistributionType; // add other type to this as needed
    countLabel?: [string, string];
    range?: [number, number];
    current?: RangeFilter | null;
    discrete?: boolean;
    showBack?: boolean;
    onClearOrBack: () => void;
    onClose: () => void;
    onSubmit: (items: RangeFilter | null) => void;
}

export function TestResultFilterRange({
    title,
    distributionType,
    countLabel = ['Min', 'Max'],
    range,
    current,
    discrete,
    showBack,
    onClearOrBack,
    onClose,
    onSubmit,
}: TestResultFilterRangeProps): ReactElement {
    const { data } = useQuery<TestResultFilterRange_unplannedTestDistributionQuery>(
        graphql`
            query TestResultFilterRange_unplannedTestDistributionQuery($type: UnplannedBatteryTestDistributionType!) {
                unplannedBatteryTestDistribution(type: $type) {
                    distribution {
                        key
                        value
                    }
                    minimum
                    maximum
                }
            }
        `,
        {
            type: distributionType,
        }
    );

    const distributionRange = useMemo<[number, number] | null>(() => {
        if (
            !data ||
            data.unplannedBatteryTestDistribution.minimum == null ||
            data.unplannedBatteryTestDistribution.maximum == null
        ) {
            return null;
        }

        return [data.unplannedBatteryTestDistribution.minimum, data.unplannedBatteryTestDistribution.maximum];
    }, [data]);

    return (
        <FilterRange
            title={title}
            onSubmit={onSubmit}
            onClose={onClose}
            limit={range}
            current={current}
            discrete={discrete}
            showBack={showBack}
            onClearOrBack={onClearOrBack}
            countLabel={countLabel}
            distribution={data?.unplannedBatteryTestDistribution}
            distributionRange={distributionRange}
        />
    );
}
