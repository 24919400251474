import React, { FC, ReactNode } from 'react';

import { IconWithTooltip } from '@accesstel/pcm-ui';

import { FilterSingleSelect } from 'components/FilterSingleSelect';
import { CommonFilterProps } from 'filters/common';

import { SiteDeviceBatteries } from '../types';

export type SiteDeviceWithBatteriesFilterProps = CommonFilterProps<SiteDeviceBatteries | null>;

export const SiteDeviceWithBatteriesFilterUI: FC<SiteDeviceWithBatteriesFilterProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
}) => {
    const options = [SiteDeviceBatteries.All, SiteDeviceBatteries.Partial, SiteDeviceBatteries.None];

    const renderItem = (item: SiteDeviceBatteries) => {
        switch (item) {
            case SiteDeviceBatteries.All:
                return getRenderedNode(item, 'All devices on site have batteries');
            case SiteDeviceBatteries.Partial:
                return getRenderedNode(item, 'Some devices on site have batteries');
            case SiteDeviceBatteries.None:
                return getRenderedNode(item, 'No devices on site have batteries');
            default:
                return item;
        }
    };

    return (
        <FilterSingleSelect
            options={options}
            active={current ?? undefined}
            title='Filter by site devices with batteries'
            renderItem={renderItem}
            onClear={onClearOrBack}
            onSubmit={item => onSubmit(item)}
            onClose={onClose}
            showBack={true}
        />
    );
};

function getRenderedNode(text: SiteDeviceBatteries, subtext: string): ReactNode {
    return (
        <div className='flex flex-row items-baseline space-x-2'>
            <span>{text}</span>
            <span className='text-customEggplantWhite text-xs'>
                <IconWithTooltip message={subtext} />
            </span>
        </div>
    );
}
