import React, { FC } from 'react';

import { LoadableContentArea } from '@accesstel/pcm-ui';
import { PageLastRefreshed, ThemedPageHeading } from '@accesstel/pcm-ui';

import { PageHeader, useDocumentTitle } from 'components';
import { useQuery } from 'lib/query-helpers';
import { ErrorNotFound } from 'views/ErrorPage/ErrorNotFound';

import { TestHorizontalCard } from '../overview/components/TestHorizontalCard';
import { queries_inProgressTestsQuery } from './__generated__/queries_inProgressTestsQuery.graphql';
import { inProgressTestsQuery } from './queries';

const refreshPage = () => {
    window.location.reload();
};

export const InProgressBatteryHealthTaskList: FC = () => {
    const pageTitle = 'Tests in progress';
    useDocumentTitle(pageTitle);

    const { data: props, error, retry: retryMain } = useQuery<queries_inProgressTestsQuery>(inProgressTestsQuery, {
        pageSize: 30,
        orderBy: {
            field: 'Date',
            dir: 'Asc',
        },
    });

    if (!error && props && props.tasks === null) {
        // Tasks not found
        return <ErrorNotFound />;
    }

    return (
        <div className='space-y-6'>
            <PageHeader />
            <div>
                <ThemedPageHeading value={pageTitle} />
                <PageLastRefreshed buttonClickCallback={refreshPage} />
            </div>
            <LoadableContentArea
                data={props}
                error={!!error}
                onRetry={retryMain}
                render={props => {
                    return (
                        <div>
                            {props.tasks.data.map(task => {
                                return (
                                    <div className='horizontal-card' key={task.id} data-testid='inprogress-test-card'>
                                        <TestHorizontalCard task={task} />
                                    </div>
                                );
                            })}
                        </div>
                    );
                }}
            />
        </div>
    );
};
