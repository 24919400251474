/**
 * @generated SignedSource<<26fbc372edf49c876563d8957b8427a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ProtocolType = "Basic" | "Snmp" | "%future added value";
export type queries_GetDeviceTypeConnectivityQuery$variables = {
  id: string;
};
export type queries_GetDeviceTypeConnectivityQuery$data = {
  readonly deviceType: {
    readonly connectivity: {
      readonly ipEnabled: boolean;
      readonly protocols: ReadonlyArray<{
        readonly displayName: string | null;
        readonly id: string;
        readonly type: ProtocolType;
      }>;
    };
  } | null;
};
export type queries_GetDeviceTypeConnectivityQuery = {
  response: queries_GetDeviceTypeConnectivityQuery$data;
  variables: queries_GetDeviceTypeConnectivityQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "DeviceTypeConnectivity",
  "kind": "LinkedField",
  "name": "connectivity",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ipEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProtocolDefinition",
      "kind": "LinkedField",
      "name": "protocols",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "queries_GetDeviceTypeConnectivityQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeviceType",
        "kind": "LinkedField",
        "name": "deviceType",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "queries_GetDeviceTypeConnectivityQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeviceType",
        "kind": "LinkedField",
        "name": "deviceType",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9316081c0a0d17d05f0232be020bfa82",
    "id": null,
    "metadata": {},
    "name": "queries_GetDeviceTypeConnectivityQuery",
    "operationKind": "query",
    "text": "query queries_GetDeviceTypeConnectivityQuery(\n  $id: ID!\n) {\n  deviceType(id: $id) {\n    connectivity {\n      ipEnabled\n      protocols {\n        id\n        type\n        displayName\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ed5252a647b32137584c9a673ac080df";

export default node;
