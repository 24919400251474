import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { DateTime } from 'luxon';

import { MetricsViewCombined, TestProgressBarCombined } from '../../components';
import { MetricsViewCombinedLive } from '../../components/MetricsViewCombinedLive';
import { DataDisplayCombined } from './DataDisplayCombined';
import {
    BatteryTestState,
    CombinedTest_test$data,
    CombinedTest_test$key,
} from './__generated__/CombinedTest_test.graphql';

const CombinedTestFragment = graphql`
    fragment CombinedTest_test on DeviceBatteryTestResults {
        id
        state
        commencedTime
        completedTime
        abortedTime
        device {
            id
        }
        ...MetricsViewCombined_test
        ...DataDisplay_test
        ...DataDisplayCombinedBase_test
        ...MetricsViewCombinedLive_test
    }
`;

interface CombinedTestProps {
    test1Ref: CombinedTest_test$key;
    test2Ref: CombinedTest_test$key;
}

export const CombinedTest: FC<CombinedTestProps> = ({ test1Ref, test2Ref }) => {
    const test1 = useFragment(CombinedTestFragment, test1Ref);
    const test2 = useFragment(CombinedTestFragment, test2Ref);

    const inProgress = isInProgress(test1.state) || isInProgress(test2.state);
    const liveRefresh = shouldBeLive(test1) || shouldBeLive(test2);

    return (
        <>
            <TestProgressBarCombined stateDevice1={test1.state} stateDevice2={test2.state} />
            {inProgress && <MetricsViewCombinedLive test1Ref={test1} test2Ref={test2} />}
            {!inProgress && <MetricsViewCombined test1Ref={test1} test2Ref={test2} />}
            <DataDisplayCombined test1Ref={test1} test2Ref={test2} live={liveRefresh} />
        </>
    );
};

function isInProgress(state: BatteryTestState) {
    return state === 'InProgress';
}

function shouldBeLive(test: CombinedTest_test$data): boolean {
    const completedTime = test.completedTime ?? test.abortedTime;

    switch (test.state) {
        case 'Waiting':
        case 'SmartStart':
        case 'InProgress':
        case 'Finalizing':
        case 'Analyzing':
            // Can be live
            break;
        default:
            return false;
    }

    if (isInProgress(test.state)) {
        return true;
    }

    if (completedTime) {
        const timeToNow = DateTime.now().diff(DateTime.fromISO(completedTime), 'minutes');
        if (timeToNow.as('minutes') < 5) {
            return true;
        }
    }

    return false;
}
