import React, { FC, useCallback } from 'react';
import { fetchQuery, useFragment, useMutation, useRelayEnvironment } from 'react-relay';

import { Button, FormSection, FormikDurationPicker, FormikTextBox, useToast } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { Form, Formik, FormikHelpers } from 'formik';
import { numberToLocaleString } from 'lib/numberFormatters';
import { Duration } from 'luxon';

import { BatteryTestingFormValues } from '../schema';
import { BatteryTestingValidationSchema } from '../validation';
import {
    BatteryTestingFragment$data,
    BatteryTestingFragment$key,
} from './__generated__/BatteryTestingFragment.graphql';
import {
    BatteryTestingUpdateMutation,
    GlobalBatteryTestingSettingsIn,
} from './__generated__/BatteryTestingUpdateMutation.graphql';

export interface BatteryTestingProps {
    settings: BatteryTestingFragment$key;
}

export const BatteryTesting: FC<BatteryTestingProps> = ({ settings }) => {
    const { show } = useToast();
    const data = useFragment(BatteryTestingFragment, settings);
    const [saveSettings] = useMutation<BatteryTestingUpdateMutation>(UpdateMutation);
    const environment = useRelayEnvironment();

    const handleSubmit = useCallback(
        (
            values: BatteryTestingFormValues,
            { setSubmitting, setFieldError }: FormikHelpers<BatteryTestingFormValues>
        ) => {
            const initialValues = toInitialValues(data);
            const submitData = toSubmitData(values, initialValues);

            if (!submitData) {
                return;
            }

            setSubmitting(true);
            saveSettings({
                variables: {
                    input: submitData,
                },
                onCompleted: data => {
                    if (data.updateGlobalBatteryTestingSettings.success) {
                        show({
                            text: 'Saved changes',
                            variant: 'info',
                        });

                        // Reload the form
                        fetchQuery(environment, ReloadQuery, {}).subscribe({
                            next() {
                                setSubmitting(false);
                            },
                            error(error: unknown) {
                                setSubmitting(false);
                                captureException(error, scope => {
                                    scope.setTag('Function', 'Battery testing settings reload');
                                    return scope;
                                });
                            },
                        });
                    } else {
                        setSubmitting(false);
                        show({
                            text: 'Unable to save changes',
                            variant: 'error',
                        });

                        for (const problem of data.updateGlobalBatteryTestingSettings.problems) {
                            switch (problem) {
                                case 'DefaultQuickTestReservePercentInvalid':
                                    setFieldError('defaultQuickTestReservePercent', 'Must be valid percentage');
                                    break;
                                case 'DefaultQuickTestThresholdVoltageInvalid':
                                    setFieldError('defaultQuickTestThresholdVoltage', 'Must be valid voltage');
                                    break;
                                case 'DischargeFaultDetectionTimeInvalid':
                                    setFieldError('dischargeFaultDetectionTime', 'Must be valid time');
                                    break;
                                case 'DischargeFaultVoltageHistoresisInvalid':
                                    setFieldError('dischargeFaultVoltageHistoresis', 'Must be valid voltage');
                                    break;
                                case 'SmartStartTimeoutInvalid':
                                    setFieldError('smartStartTimeout', 'Must be valid time');
                                    break;
                                case 'StartTimeoutInvalid':
                                    setFieldError('startTimeout', 'Must be valid time');
                                    break;
                            }
                        }
                    }
                },
                onError: error => {
                    setSubmitting(false);
                    show({
                        text: 'Unable to save changes',
                        variant: 'error',
                    });
                    captureException(error, scope => {
                        scope.setTag('Function', 'Battery testing settings submit');
                        return scope;
                    });
                },
            });
        },
        [data, environment, saveSettings, show]
    );

    return (
        <FormSection
            label='Battery testing'
            blurb='Modifies default test parameters and limits. NOTE: Does not take effect until connectors are restarted.'
        >
            <Formik
                initialValues={toInitialValues(data)}
                validationSchema={BatteryTestingValidationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isSubmitting, isValid, dirty }) => (
                    <Form>
                        <div className='space-y-4 grid grid-cols-2 py-6 gap-x-6 pr-6'>
                            <div className='col-start-1'>
                                <FormikDurationPicker
                                    name='startTimeout'
                                    placeHolder='Start Timeout'
                                    light
                                    variant='outlined'
                                    units={['minutes', 'seconds']}
                                    minimum={Duration.fromObject({})}
                                    maximum={Duration.fromObject({ minutes: 30 })}
                                    steps={{ minutes: 1, seconds: 10 }}
                                />
                            </div>
                            <div className='col-start-1'>
                                <FormikDurationPicker
                                    name='smartStartTimeout'
                                    placeHolder='Smart Start Timeout'
                                    light
                                    variant='outlined'
                                    units={['hours', 'minutes']}
                                    minimum={Duration.fromObject({})}
                                    maximum={Duration.fromObject({ days: 1 })}
                                />
                            </div>
                        </div>
                        <div className='space-y-4 grid grid-cols-2 py-6 gap-x-6 pr-6'>
                            <div className='col-start-1'>
                                <FormikDurationPicker
                                    name='dischargeFaultDetectionTime'
                                    placeHolder='Discharge Fault Detection Time'
                                    light
                                    variant='outlined'
                                    units={['minutes', 'seconds']}
                                    minimum={Duration.fromObject({})}
                                    maximum={Duration.fromObject({ hours: 1 })}
                                />
                            </div>
                            <div className='col-start-1'>
                                <FormikTextBox
                                    name='dischargeFaultVoltageHistoresis'
                                    placeHolder='Discharge Fault Voltage Historesis'
                                    light
                                    variant='outlined'
                                />
                            </div>
                        </div>
                        <div className='space-y-4 grid grid-cols-2 pt-6 gap-x-6 pr-6'>
                            <div className='col-start-1'>
                                <FormikTextBox
                                    name='defaultQuickTestThresholdVoltage'
                                    placeHolder='Default Quick Test Threshold Voltage'
                                    light
                                    variant='outlined'
                                />
                            </div>
                            <div className='col-start-1'>
                                <FormikTextBox
                                    name='defaultQuickTestReservePercent'
                                    placeHolder='Default Quick Test Reserve Percent'
                                    light
                                    variant='outlined'
                                />
                            </div>
                        </div>
                        <div className='flex flex-row gap-4 justify-end'>
                            <Button
                                type='reset'
                                variant='white'
                                buttonText='Reset'
                                disabled={isSubmitting || !dirty}
                                size='small'
                            />
                            <Button
                                type='submit'
                                variant='primary'
                                buttonText='Save'
                                disabled={isSubmitting || !isValid || !dirty}
                                processing={isSubmitting}
                                size='small'
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </FormSection>
    );
};

export const BatteryTestingFragment = graphql`
    fragment BatteryTestingFragment on Settings {
        batteryTesting {
            startTimeout
            smartStartTimeout
            dischargeFaultDetectionTime
            dischargeFaultVoltageHistoresis
            defaultQuickTestThresholdVoltage
            defaultQuickTestReservePercent
        }
    }
`;

const UpdateMutation = graphql`
    mutation BatteryTestingUpdateMutation($input: GlobalBatteryTestingSettingsIn!) {
        updateGlobalBatteryTestingSettings(input: $input) {
            success
            problems
        }
    }
`;

const ReloadQuery = graphql`
    query BatteryTestingReloadQuery {
        settings {
            ...BatteryTestingFragment
        }
    }
`;

function toInitialValues(data: BatteryTestingFragment$data): BatteryTestingFormValues {
    const batteryTesting = data.batteryTesting;

    return {
        startTimeout: Duration.fromISO(batteryTesting.startTimeout),
        smartStartTimeout: Duration.fromISO(batteryTesting.smartStartTimeout),
        dischargeFaultDetectionTime: Duration.fromISO(batteryTesting.dischargeFaultDetectionTime),
        defaultQuickTestReservePercent: numberToLocaleString(batteryTesting.defaultQuickTestReservePercent),
        defaultQuickTestThresholdVoltage: numberToLocaleString(batteryTesting.defaultQuickTestThresholdVoltage),
        dischargeFaultVoltageHistoresis: numberToLocaleString(batteryTesting.dischargeFaultVoltageHistoresis),
    };
}

function toSubmitData(
    values: BatteryTestingFormValues,
    initialValues: BatteryTestingFormValues
): GlobalBatteryTestingSettingsIn | null {
    const submitData: GlobalBatteryTestingSettingsIn = {};
    let changed = false;

    if (!values.startTimeout.equals(initialValues.startTimeout)) {
        submitData.startTimeout = values.startTimeout.toISO();
        changed = true;
    }

    if (!values.smartStartTimeout.equals(initialValues.smartStartTimeout)) {
        submitData.smartStartTimeout = values.smartStartTimeout.toISO();
        changed = true;
    }

    if (!values.dischargeFaultDetectionTime.equals(initialValues.dischargeFaultDetectionTime)) {
        submitData.dischargeFaultDetectionTime = values.dischargeFaultDetectionTime.toISO();
        changed = true;
    }

    if (values.dischargeFaultVoltageHistoresis !== initialValues.dischargeFaultVoltageHistoresis) {
        submitData.dischargeFaultVoltageHistoresis = Number(values.dischargeFaultVoltageHistoresis);
        changed = true;
    }

    if (values.defaultQuickTestThresholdVoltage !== initialValues.defaultQuickTestThresholdVoltage) {
        submitData.defaultQuickTestThresholdVoltage = Number(values.defaultQuickTestThresholdVoltage);
        changed = true;
    }

    if (values.defaultQuickTestReservePercent !== initialValues.defaultQuickTestReservePercent) {
        submitData.defaultQuickTestReservePercent = Number(values.defaultQuickTestReservePercent);
        changed = true;
    }

    if (!changed) {
        return null;
    }

    return submitData;
}
