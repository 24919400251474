/**
 * @generated SignedSource<<0887dabb5e6d2704f759349431b39a03>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SiteNameFilterUI_siteNameSearchQuery$variables = {
  input: string;
  maxResults: number;
};
export type SiteNameFilterUI_siteNameSearchQuery$data = {
  readonly sites: {
    readonly data: ReadonlyArray<{
      readonly address: {
        readonly state: string;
      };
      readonly id: string;
      readonly name: string;
    }>;
  };
};
export type SiteNameFilterUI_siteNameSearchQuery = {
  response: SiteNameFilterUI_siteNameSearchQuery$data;
  variables: SiteNameFilterUI_siteNameSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "maxResults"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "items": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "value",
                    "variableName": "input"
                  }
                ],
                "kind": "ObjectValue",
                "name": "name.0"
              }
            ],
            "kind": "ListValue",
            "name": "name"
          }
        ],
        "kind": "ObjectValue",
        "name": "filters"
      },
      {
        "kind": "Variable",
        "name": "pageSize",
        "variableName": "maxResults"
      }
    ],
    "concreteType": "PaginatedSites",
    "kind": "LinkedField",
    "name": "sites",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Address",
            "kind": "LinkedField",
            "name": "address",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "state",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteNameFilterUI_siteNameSearchQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SiteNameFilterUI_siteNameSearchQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "653a254bd3189f0cc6a4652b033e89bd",
    "id": null,
    "metadata": {},
    "name": "SiteNameFilterUI_siteNameSearchQuery",
    "operationKind": "query",
    "text": "query SiteNameFilterUI_siteNameSearchQuery(\n  $input: String!\n  $maxResults: Int!\n) {\n  sites(filters: {name: [{value: $input}]}, pageSize: $maxResults) {\n    data {\n      id\n      name\n      address {\n        state\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d8bf0c3e229277ddfe6a593667dd5bfd";

export default node;
