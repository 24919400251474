/**
 * @generated SignedSource<<7da9c7b12ce9f1c6893b20721bbc2ecd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AttributeLocation = "Battery" | "Device" | "Site" | "%future added value";
export type AttributeFilterUI_attributeValueSearchQuery$variables = {
  attribute: string;
  input: string;
  maxResults: number;
  type: AttributeLocation;
};
export type AttributeFilterUI_attributeValueSearchQuery$data = {
  readonly attributeSearch: ReadonlyArray<string>;
};
export type AttributeFilterUI_attributeValueSearchQuery = {
  response: AttributeFilterUI_attributeValueSearchQuery$data;
  variables: AttributeFilterUI_attributeValueSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "attribute"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "maxResults"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "type"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "limit",
        "variableName": "maxResults"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "attribute"
      },
      {
        "items": [
          {
            "kind": "Variable",
            "name": "only.0",
            "variableName": "type"
          }
        ],
        "kind": "ListValue",
        "name": "only"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "input"
      },
      {
        "kind": "Literal",
        "name": "status",
        "value": [
          "Active"
        ]
      }
    ],
    "kind": "ScalarField",
    "name": "attributeSearch",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AttributeFilterUI_attributeValueSearchQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AttributeFilterUI_attributeValueSearchQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "58e24675823465f55dcd85a7d9fe4a4c",
    "id": null,
    "metadata": {},
    "name": "AttributeFilterUI_attributeValueSearchQuery",
    "operationKind": "query",
    "text": "query AttributeFilterUI_attributeValueSearchQuery(\n  $attribute: String!\n  $input: String!\n  $maxResults: Int!\n  $type: AttributeLocation!\n) {\n  attributeSearch(name: $attribute, search: $input, limit: $maxResults, only: [$type], status: [Active])\n}\n"
  }
};
})();

(node as any).hash = "928b23fccd813f1fa242758d065de896";

export default node;
