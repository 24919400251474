import { useCallback, useEffect, useRef, useState } from 'react';

import { Domain, DomainAbsolute } from '@accesstel/pcm-ui';

import { Duration } from 'luxon';

export type DataFetchFunction<T> = (
    interval: Duration | null,
    start: Duration | null,
    end: Duration | null
) => Promise<T | null>;

export interface ZoomableDataResponse<T> {
    chartDomain: Domain<Duration>;
    isZoomed: boolean;
    doZoom: (domain: DomainAbsolute<Duration> | null) => void;
    baseData: T | null;
    zoomData: T | null;
    hasError: boolean;
    reloadData: () => void;
}

export interface ZoomableDataOptions {
    averageTickCount?: number;
    animationDelay?: number;
}

export function useZoomableData<T>(
    defaultXDomain: Domain<Duration>,
    onZoom: (domain: Domain<Duration>, isZoomed: boolean) => void,
    fetchData: DataFetchFunction<T>,
    options: ZoomableDataOptions = {}
): ZoomableDataResponse<T> {
    const { averageTickCount = 28, animationDelay = 300 } = options;

    const [chartDomain, setChartDomain] = useState<Domain<Duration>>(defaultXDomain);
    const [baseData, setBaseData] = useState<T | null>(null);
    const [zoomData, setZoomData] = useState<T | null>(null);
    const [hasError, setHasError] = useState(false);

    const activeLoadDelay = useRef<NodeJS.Timeout | null>(null);
    const pendingLoadData = useRef<T | null>(null);

    // Load the initial data
    useEffect(() => {
        fetchData(null, null, null)
            .then(data => {
                setHasError(false);
                setBaseData(data);
            })
            .catch(() => {
                setHasError(true);
            });
        // Only load it once initially
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reloadData = useCallback(() => {
        fetchData(null, null, null)
            .then(data => {
                setHasError(false);
                setBaseData(data);
            })
            .catch(() => {
                setHasError(true);
            });

        if (chartDomain !== defaultXDomain) {
            if (!Duration.isDuration(chartDomain[0]) || !Duration.isDuration(chartDomain[1])) {
                return;
            }

            let interval = Duration.fromObject({
                seconds: (chartDomain[1].as('seconds') - chartDomain[0].as('seconds')) / averageTickCount,
            });
            if (interval < Duration.fromObject({ seconds: 1 })) {
                interval = Duration.fromObject({ seconds: 1 });
            }

            fetchData(interval, chartDomain[0], chartDomain[1])
                .then(data => {
                    setHasError(false);
                    setZoomData(data);
                })
                .catch(() => {
                    setHasError(true);
                });
        }
    }, [averageTickCount, chartDomain, defaultXDomain, fetchData]);

    const requestStillRequired = useRef<boolean>(false);

    // Called when the zoom level changes
    const handleZoom = useCallback(
        (domain: DomainAbsolute<Duration> | null) => {
            if (!domain) {
                // Zoom out
                setChartDomain(defaultXDomain);
                setZoomData(null);
                onZoom(defaultXDomain, false);

                requestStillRequired.current = false;
                return;
            }

            // Zoom in
            const span = domain[1].as('minutes') - domain[0].as('minutes');
            if (span < 1) {
                domain[1] = domain[0].plus({ minutes: 1 });
            }
            setChartDomain(domain);
            onZoom(domain, true);

            // Wait until animation finished to apply data
            activeLoadDelay.current = setTimeout(() => {
                if (pendingLoadData.current) {
                    setZoomData(pendingLoadData.current);
                    pendingLoadData.current = null;
                }
                activeLoadDelay.current = null;
            }, animationDelay);

            // Begin load of zoomed data
            let interval = Duration.fromObject({
                seconds: (domain[1].as('seconds') - domain[0].as('seconds')) / averageTickCount,
            });
            if (interval < Duration.fromObject({ seconds: 1 })) {
                interval = Duration.fromObject({ seconds: 1 });
            }

            requestStillRequired.current = true;
            fetchData(interval, domain[0], domain[1])
                .then(data => {
                    setHasError(false);
                    if (!requestStillRequired.current) {
                        return;
                    }

                    if (!activeLoadDelay.current) {
                        setZoomData(data);
                    } else {
                        pendingLoadData.current = data;
                    }
                })
                .catch(() => {
                    setHasError(true);
                });
        },
        [animationDelay, averageTickCount, defaultXDomain, fetchData, onZoom]
    );

    return {
        chartDomain,
        doZoom: handleZoom,
        isZoomed: chartDomain !== defaultXDomain,
        baseData,
        zoomData,
        hasError,
        reloadData,
    };
}
