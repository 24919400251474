/**
 * @generated SignedSource<<9c79838a7814b1c62f1ebaf5a533b2c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeviceHealth = "Critical" | "Degraded" | "Healthy" | "Offline" | "Unknown" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FeedTile_data$data = {
  readonly label: string;
  readonly metrics: {
    readonly latestVoltage: number | null;
  };
  readonly status: DeviceHealth;
  readonly " $fragmentType": "FeedTile_data";
};
export type FeedTile_data$key = {
  readonly " $data"?: FeedTile_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedTile_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedTile_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ACFeedMetrics",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latestVoltage",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ACFeed",
  "abstractKey": null
};

(node as any).hash = "57265be9fb8e6bd0783e70e7dddaccc7";

export default node;
