import { Disposable, Environment, commitMutation, fetchQuery } from 'react-relay';

import { ToastOptions } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { downloadExportFile } from 'lib/download';

import { testExporterDualPlaneMutation } from './__generated__/testExporterDualPlaneMutation.graphql';
import { testExporterSinglePlaneMutation } from './__generated__/testExporterSinglePlaneMutation.graphql';
import { testExporterStatusQuery } from './__generated__/testExporterStatusQuery.graphql';

export class Exporter {
    private intervalTask: NodeJS.Timeout | null = null;
    private isRunning = true;
    private jobId: string | null = null;
    private finishCallback: (() => void) | null = null;
    private runningDisposable: Disposable | null = null;

    constructor(
        private testId: string,
        private showToast: (options: ToastOptions) => void,
        private environment: Environment,
        private isDualPlane: boolean
    ) {}

    get running(): boolean {
        return this.isRunning;
    }

    cancel() {
        if (this.intervalTask != null) {
            clearTimeout(this.intervalTask);
        }

        this.finish();

        if (this.runningDisposable) {
            this.runningDisposable.dispose();
            this.runningDisposable = null;
        }

        this.isRunning = false;
    }

    begin() {
        this.runningDisposable = commitMutation<testExporterSinglePlaneMutation | testExporterDualPlaneMutation>(
            this.environment,
            {
                mutation: this.isDualPlane ? DualPlaneExportMutation : SinglePlaneExportMutation,
                variables: {
                    id: this.testId,
                },
                onCompleted: response => {
                    this.runningDisposable = null;
                    if (response.exportResult?.id) {
                        const promise = new Promise<void>(resolve => {
                            this.finishCallback = resolve;
                        });

                        this.startWatching(response.exportResult.id, promise);
                    }
                },
                onError: error => {
                    this.runningDisposable = null;
                    captureException(error, scope => {
                        scope.setTag('Function', 'Export');
                        scope.setTag('Test', this.testId);
                        scope.setTag('DualPlane', this.isDualPlane.toString());
                        return scope;
                    });
                    this.showToast({
                        text: 'Unable to export, something went wrong.',
                        variant: 'error',
                    });
                },
            }
        );
    }

    private startWatching(id: string, finishedPromise: Promise<void>) {
        this.jobId = id;
        this.intervalTask = setTimeout(() => this.check(), 400);

        this.showToast({
            text: 'Exporting test result',
            variant: 'info',
            actions: [
                {
                    title: 'Cancel',
                    onClick: () => {
                        this.cancel();
                    },
                },
            ],
            displayUntil: finishedPromise,
        });
    }
    private check() {
        if (!this.jobId) {
            return;
        }

        fetchQuery<testExporterStatusQuery>(
            this.environment,
            graphql`
                query testExporterStatusQuery($id: ID!) {
                    exportJob(id: $id) {
                        status
                        downloadUrl
                    }
                }
            `,
            { id: this.jobId }
        )
            .toPromise()
            .then(this.handleJobStatus.bind(this));
        this.intervalTask = setTimeout(() => this.check(), 400);
    }

    private finish() {
        this.finishCallback?.();
        this.finishCallback = null;
    }

    private handleJobStatus(result?: testExporterStatusQuery['response']) {
        if (!result?.exportJob) {
            return;
        }

        if (!this.running) {
            // just in case it was cancelled between when the query was sent and this response
            return;
        }

        const job = result.exportJob;

        if (job.status === 'Failed') {
            this.finish();
            this.showToast({
                text: 'Export did not succeed. Something went wrong.',
                variant: 'error',
            });
            this.cancel();
            return;
        }

        if (job.status === 'Succeeded') {
            console.assert(job.downloadUrl, 'Expected download URL available');
            if (!job.downloadUrl) {
                return;
            }
            this.finish();

            this.showToast({
                text: 'Export finished',
                variant: 'info',
            });

            downloadExportFile(job.downloadUrl);

            this.cancel();
            return;
        }
    }
}

const SinglePlaneExportMutation = graphql`
    mutation testExporterSinglePlaneMutation($id: ID!) {
        exportResult: exportBatteryTestResult(id: $id) {
            ... on ExportJob {
                id
            }
            ... on ExportJobProblemResponse {
                problems
            }
        }
    }
`;

const DualPlaneExportMutation = graphql`
    mutation testExporterDualPlaneMutation($id: ID!) {
        exportResult: exportBatteryTestDualPlaneResult(testId: $id, singleFile: true) {
            ... on ExportJob {
                id
            }
            ... on ExportJobProblemResponse {
                problems
            }
        }
    }
`;
