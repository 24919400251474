/**
 * @generated SignedSource<<ba6abf282258ed523819635aeb3859fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LastCollectedTime_battery$data = {
  readonly lastUpdate: string | null;
  readonly " $fragmentType": "LastCollectedTime_battery";
};
export type LastCollectedTime_battery$key = {
  readonly " $data"?: LastCollectedTime_battery$data;
  readonly " $fragmentSpreads": FragmentRefs<"LastCollectedTime_battery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LastCollectedTime_battery",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastUpdate",
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
};

(node as any).hash = "fa47310191189a26947c7f31077c4b0f";

export default node;
