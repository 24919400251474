import React, { FC } from 'react';

import { CommonFilterProps, RangeFilter } from 'filters/common';

import { TaskFilterRange } from './TaskFilterRange';

export type TaskDeviceCountFilterUIProps = CommonFilterProps<RangeFilter | null>;

export const TaskDeviceCountFilterUI: FC<TaskDeviceCountFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => (
    <TaskFilterRange
        current={current}
        onClearOrBack={onClearOrBack}
        onClose={onClose}
        onSubmit={onSubmit}
        title='Filter by device count'
        distributionType='TaskDeviceCountDistribution'
        countLabel={['Min', 'Max']}
        showBack={hasPrevious}
    />
);
