import React, { ReactElement, ReactNode, useCallback, useState } from 'react';

import { ArrowLeftIcon, FilterContainer } from '@accesstel/pcm-ui';

import { SingleSelectionList } from './SingleSelectionList';

export interface FilterSingleSelectProps<T> {
    title: string;
    options: T[];
    active?: T;
    onClear: () => void;
    onSubmit?: (item: T) => void;
    onClose?: () => void;
    renderItem: (item: T) => ReactNode;
    showBack?: boolean;
}

export function FilterSingleSelect<T>({
    title,
    options,
    active,
    onClear,
    onSubmit,
    onClose,
    renderItem,
    showBack,
}: FilterSingleSelectProps<T>): ReactElement {
    const [item, setItem] = useState<T | undefined>(active);

    const handleConfirmClick = useCallback(() => {
        if (!onSubmit || !item) {
            return;
        }

        onSubmit(item);
    }, [onSubmit, item]);

    let customClearButton: ReactNode;
    if (showBack && !item) {
        customClearButton = (
            <div className='w-4 h-4 hover:text-customCoral'>
                <ArrowLeftIcon />
            </div>
        );
    }

    return (
        <FilterContainer
            title={title}
            onConfirmClick={handleConfirmClick}
            onClearClick={onClear}
            onClose={onClose}
            hideClearButton={item && !showBack}
            customButton={customClearButton}
            primaryContent={
                <SingleSelectionList items={options} selectedItem={item} onSelect={setItem} renderItem={renderItem} />
            }
        />
    );
}
