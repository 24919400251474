import React, { FC } from 'react';

import { MultipleSelect } from 'components/MultipleSelect';
import { CommonFilterProps } from 'filters/common';
import { FailReasonMap } from 'lib/textFormatters';

import { TestResultFailReasonFilter } from '../types';

export type TestResultFailReasonFilterUIProps = CommonFilterProps<TestResultFailReasonFilter[]>;

export const TestResultFailReasonFilterUI: FC<TestResultFailReasonFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    const options: TestResultFailReasonFilter[] = [
        { id: 'BatteryHealth', name: FailReasonMap['BatteryHealth'] },
        { id: 'BatteryHealthUnknown', name: FailReasonMap['BatteryHealthUnknown'] },
        { id: 'Busy', name: FailReasonMap['Busy'] },
        { id: 'DeviceRemoved', name: FailReasonMap['DeviceRemoved'] },
        { id: 'DidNotStart', name: FailReasonMap['DidNotStart'] },
        { id: 'SmartStartTimeout', name: FailReasonMap['SmartStartTimeout'] },
        { id: 'SystemAbort', name: FailReasonMap['SystemAbort'] },
        { id: 'TestLockout', name: FailReasonMap['TestLockout'] },
        { id: 'ThresholdReached', name: FailReasonMap['ThresholdReached'] },
        { id: 'Unknown', name: FailReasonMap['Unknown'] },
    ];

    return (
        <MultipleSelect
            options={options}
            active={current}
            title='Filter by Fail Reason'
            renderItem={item => item.name}
            renderItemSimple={item => item.name}
            areItemsEqual={(itemA, itemB) => itemA.id === itemB.id}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onClose={onClose}
            showBack={hasPrevious}
        />
    );
};
