/**
 * @generated SignedSource<<00c552e13e47dedfb917065cf1dd2e7d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type queries_GetDevicesByNameQuery$variables = {
  limit: number;
  name?: string | null;
  siteId?: ReadonlyArray<string> | null;
};
export type queries_GetDevicesByNameQuery$data = {
  readonly devices: {
    readonly data: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly site: {
        readonly name: string;
      };
    }>;
  };
};
export type queries_GetDevicesByNameQuery = {
  response: queries_GetDevicesByNameQuery$data;
  variables: queries_GetDevicesByNameQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limit"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "siteId"
},
v3 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "siteId",
        "variableName": "siteId"
      }
    ],
    "kind": "ObjectValue",
    "name": "filters"
  },
  {
    "kind": "Literal",
    "name": "page",
    "value": 1
  },
  {
    "kind": "Variable",
    "name": "pageSize",
    "variableName": "limit"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "name"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "queries_GetDevicesByNameQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "PaginatedDevices",
        "kind": "LinkedField",
        "name": "devices",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Device",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Site",
                "kind": "LinkedField",
                "name": "site",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "queries_GetDevicesByNameQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "PaginatedDevices",
        "kind": "LinkedField",
        "name": "devices",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Device",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Site",
                "kind": "LinkedField",
                "name": "site",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8b8031a8a16ddac9c071e294aa5a3755",
    "id": null,
    "metadata": {},
    "name": "queries_GetDevicesByNameQuery",
    "operationKind": "query",
    "text": "query queries_GetDevicesByNameQuery(\n  $name: String\n  $limit: Int!\n  $siteId: [ID!]\n) {\n  devices(search: $name, page: 1, pageSize: $limit, filters: {siteId: $siteId}) {\n    data {\n      id\n      name\n      site {\n        name\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "707b25760ffdd55a577cea55f1c61c66";

export default node;
