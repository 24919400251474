export const isValidNumber = (value: string): boolean => value === '' || isFinite(Number(value));

export const hasReachedDecimalPointLimit = (value: string, limit = 2): boolean => {
    if (value.includes('.') && value.split('.')[1].length > limit) {
        return true;
    }
    return false;
};

export function roundUp(value: number, decimalPlaces = 0): number {
    const scale = Math.pow(10, decimalPlaces);

    return Math.ceil(value * scale) / scale;
}

export function roundDown(value: number, decimalPlaces = 0): number {
    const scale = Math.pow(10, decimalPlaces);

    return Math.floor(value * scale) / scale;
}

export function round(value: number, decimalPlaces = 0): number {
    const scale = Math.pow(10, decimalPlaces);

    return Math.round(value * scale) / scale;
}
