/* eslint-disable no-irregular-whitespace */
import React, { FC } from 'react';

import classNames from 'classnames';

import style from './style.module.css';

export interface SkeletonCardProps {
    height?: string | number;
    className?: string;
    children?: React.ReactNode;
}

export const SkeletonCard: FC<SkeletonCardProps> = ({ className, children, height }) => {
    return (
        <div className={classNames(className, style.card_skeleton)} style={{ height }}>
            {children}
        </div>
    );
};
