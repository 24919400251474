import { Dispatch, Reducer, useEffect, useReducer } from 'react';
import { useLocation } from 'react-router-dom';

import { logError } from 'lib/log';
import { useHistoryState } from 'lib/saved-state';
import isEqual from 'lodash.isequal';

import { InternalTableState, SortDirection, TableState } from './state';

export enum TableActionType {
    SortColumn,
    ChangePage,
    UpdateSearch,
    SetSelection,
    AppendSelection,
    SetAllSelectableItems,
    UpdateColumns,
    ResetColumns,
    SetColumnEditPaneOpen,
}

export type TableAction<ColumnType extends string> =
    | {
          type: TableActionType.SortColumn;
          column: ColumnType;
          direction: SortDirection;
      }
    | { type: TableActionType.ChangePage; page: number }
    | { type: TableActionType.UpdateSearch; search: string }
    | { type: TableActionType.SetSelection; selection: string[] }
    | { type: TableActionType.AppendSelection; selection: string[] }
    | { type: TableActionType.SetAllSelectableItems; items: string[] | null }
    | { type: TableActionType.UpdateColumns; columns: ColumnType[] }
    | { type: TableActionType.ResetColumns }
    | { type: TableActionType.SetColumnEditPaneOpen; isOpen: boolean };

function tableReducer<ColumnType extends string>(
    state: InternalTableState<ColumnType>,
    action: TableAction<ColumnType>
): InternalTableState<ColumnType> {
    switch (action.type) {
        case TableActionType.SortColumn:
            if (action.column === state.sortColumn && action.direction === state.sortDirection) {
                return state;
            }

            return {
                ...state,
                sortColumn: action.column,
                sortDirection: action.direction,
            };
        case TableActionType.ChangePage:
            if (action.page === state.page) {
                return state;
            }

            return {
                ...state,
                page: Math.max(1, action.page),
            };
        case TableActionType.UpdateSearch:
            if (action.search === state.search) {
                return state;
            }

            return {
                ...state,
                search: action.search,
            };
        case TableActionType.SetSelection:
            if (isEqual(action.selection, state.selectedItems)) {
                return state;
            }
            return {
                ...state,
                selectedItems: action.selection,
            };
        case TableActionType.AppendSelection: {
            const selectedItems = new Set<string>(state.selectedItems.concat(action.selection));
            const newSelectedItems = Array.from(selectedItems);

            if (isEqual(newSelectedItems, state.selectedItems)) {
                return state;
            }

            return {
                ...state,
                selectedItems: newSelectedItems,
            };
        }
        case TableActionType.SetAllSelectableItems:
            if (isEqual(action.items, state.allSelectableItems)) {
                return state;
            }

            return {
                ...state,
                allSelectableItems: action.items,
            };
        case TableActionType.UpdateColumns:
            if (isEqual(action.columns, state.visibleColumnsInOrder)) {
                return state;
            }

            return {
                ...state,
                visibleColumnsInOrder: action.columns,
            };
        case TableActionType.ResetColumns:
            if (state.visibleColumnsInOrder == null) {
                return state;
            }

            return {
                ...state,
                visibleColumnsInOrder: null,
            };
        case TableActionType.SetColumnEditPaneOpen:
            if (action.isOpen === state.isColumnEditPaneOpen) {
                return state;
            }

            return {
                ...state,
                isColumnEditPaneOpen: action.isOpen,
            };
    }
}

export interface TableReducerOptions<ColumnType extends string> {
    defaultSortColumn: ColumnType;
    defaultSortDirection?: SortDirection;
    defaultVisibleColumns: ColumnType[];
    allColumns: ColumnType[];
    storageKeyPrefix: string;
    initialSelection?: string[];
}

export function useTableReducer<ColumnType extends string>(
    options: TableReducerOptions<ColumnType>
): [TableState<ColumnType>, Dispatch<TableAction<ColumnType>>] {
    const location = useLocation();

    const [storedInitialState, setStoredInitialState] = useHistoryState<
        Pick<InternalTableState<ColumnType>, 'page' | 'sortColumn' | 'sortDirection' | 'search'>
    >('table-state', {
        page: 1,
        sortColumn: options.defaultSortColumn,
        sortDirection: options.defaultSortDirection ?? SortDirection.Ascending,
        search: '',
    });

    const [state, dispatch] = useReducer(
        tableReducer as Reducer<InternalTableState<ColumnType>, TableAction<ColumnType>>,
        undefined,
        () => {
            const initialState: InternalTableState<ColumnType> = {
                sortColumn: storedInitialState.sortColumn,
                sortDirection: storedInitialState.sortDirection,
                page: storedInitialState.page,
                search: storedInitialState.search,
                selectedItems: options.initialSelection ?? [],
                allSelectableItems: null,
                visibleColumnsInOrder: loadVisibleColumns(
                    options.allColumns,
                    `${options.storageKeyPrefix}-column-selection`
                ),
                isColumnEditPaneOpen: false,
                defaultVisibleColumns: options.defaultVisibleColumns,
            };

            // load default search value from URL
            if (location.search) {
                const query = new URLSearchParams(location.search);
                initialState.search = query.get('q') ?? '';
            }
            return initialState;
        }
    );

    useEffect(() => {
        setStoredInitialState({
            page: state.page,
            sortColumn: state.sortColumn,
            sortDirection: state.sortDirection,
            search: state.search,
        });

        // NOTE: setStoredInitialState is not included or it will cause a render loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    useEffect(() => {
        saveVisibleColumns(state.visibleColumnsInOrder, `${options.storageKeyPrefix}-column-selection`);
    }, [options.storageKeyPrefix, state.visibleColumnsInOrder]);

    const outputState: TableState<ColumnType> = {
        ...state,
        visibleColumnsInOrder: state.visibleColumnsInOrder ?? state.defaultVisibleColumns,
        areProvidedColumnsInDefaultOrder(columns) {
            const providedHasDefaultOrder = isEqual(columns, state.defaultVisibleColumns);
            const stateHasDefaultOrder =
                state.visibleColumnsInOrder === null ||
                isEqual(state.visibleColumnsInOrder, state.defaultVisibleColumns);

            return providedHasDefaultOrder && stateHasDefaultOrder;
        },
    };

    return [outputState, dispatch];
}

function loadVisibleColumns<ColumnType extends string>(
    allColumns: ColumnType[],
    storageKey: string
): ColumnType[] | null {
    const storedData = localStorage.getItem(storageKey);

    if (!storedData) {
        return null;
    }

    try {
        const parsedColumnIds = JSON.parse(storedData);

        if (!Array.isArray(parsedColumnIds)) {
            throw new Error('Stored column information is not an array');
        }

        // Ensure that only valid columns are present
        const validColumnIdsOnly: string[] = parsedColumnIds.filter((columnId: ColumnType) =>
            allColumns.includes(columnId)
        );

        if (validColumnIdsOnly.length !== parsedColumnIds.length) {
            localStorage.setItem(storageKey, JSON.stringify(validColumnIdsOnly));
        }

        return validColumnIdsOnly as ColumnType[];
    } catch (error) {
        logError('Failed to parse column order from local storage:', error);
        localStorage.removeItem(storageKey);
        return null;
    }
}

function saveVisibleColumns<ColumnType extends string>(columnsInOrder: ColumnType[] | null, storageKey: string) {
    if (!columnsInOrder) {
        localStorage.removeItem(storageKey);
        return;
    }

    localStorage.setItem(storageKey, JSON.stringify(columnsInOrder));
}
