import React, { FC, Suspense } from 'react';
import { PreloadedQuery } from 'react-relay';
import { useLoaderData, useParams } from 'react-router-dom';

import { InsightsContent } from './InsightsContent';
import { InsightsSkeleton } from './InsightsSkeleton';
import { InsightsContentInitialQuery } from './__generated__/InsightsContentInitialQuery.graphql';

export const Insights: FC = () => {
    const queryRef = useLoaderData() as PreloadedQuery<InsightsContentInitialQuery> | null;
    const { deviceId } = useParams() as { deviceId: string };

    return (
        <>
            {!queryRef && <InsightsSkeleton />}
            {queryRef && (
                <Suspense fallback={<InsightsSkeleton />}>
                    <InsightsContent key={deviceId} queryRef={queryRef} deviceId={deviceId} />
                </Suspense>
            )}
        </>
    );
};
