import { object, string } from 'yup';

export interface AttributeFormValues {
    name: string;
    value: string;
}

export const AttributeValidationSchema = object({
    name: string().trim().required('Attribute name is required'),
    value: string().trim().optional(),
});
