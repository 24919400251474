import React, { FC } from 'react';

import { MultipleSelect } from 'components/MultipleSelect';
import { CommonFilterProps } from 'filters/common';
import { BatteryTestCauseMap } from 'lib/textFormatters';

import { TestResultCauseFilter } from '../types';

export type TestResultCauseFilterUIProps = CommonFilterProps<TestResultCauseFilter[]>;

export const TestResultCauseFilterUI: FC<TestResultCauseFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    const options: TestResultCauseFilter[] = [
        { id: 'AcFail', name: BatteryTestCauseMap['AcFail'] },
        { id: 'Accata', name: BatteryTestCauseMap['Accata'] },
        { id: 'ExternalTest', name: BatteryTestCauseMap['ExternalTest'] },
        { id: 'LowReferenceVoltage', name: BatteryTestCauseMap['LowReferenceVoltage'] },
        { id: 'CompanionDischarge', name: BatteryTestCauseMap['CompanionDischarge'] },
        { id: 'Unknown', name: BatteryTestCauseMap['Unknown'] },
    ];

    return (
        <MultipleSelect
            options={options}
            active={current}
            title='Filter by Discharge Cause'
            renderItem={item => item.name}
            renderItemSimple={item => item.name}
            areItemsEqual={(itemA, itemB) => itemA.id === itemB.id}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onClose={onClose}
            showBack={hasPrevious}
        />
    );
};
