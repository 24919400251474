import { Dispatch, useReducer } from 'react';

import {
    AuthLevelAdmin,
    AuthLevelRead,
    AuthLevelWrite,
    EditUserContentQuery$data,
} from './__generated__/EditUserContentQuery.graphql';
import { UserErrorType } from './__generated__/EditUserContentUpdateMutation.graphql';

export interface EditState {
    name: string;
    nameError?: string;
    email: string;
    emailError?: string;

    permissions: PermissionsState;
}

export interface PermissionsState {
    general: AuthLevelRead;
    assets: AuthLevelWrite;
    batteryHealthTasks: AuthLevelWrite;
    administration: AuthLevelAdmin;
}

export enum ActionType {
    UpdateName,
    SetNameError,
    UpdateEmail,
    SetEmailError,
    UpdatePermissionGeneral,
    UpdatePermissionAssets,
    UpdatePermissionBatteryHealthTasks,
    UpdatePermissionAdministration,
    ClearErrors,
    UpdateErrors,
}

export type Action =
    | { type: ActionType.UpdateName; value: string }
    | { type: ActionType.SetNameError; value: string | undefined }
    | { type: ActionType.UpdateEmail; value: string }
    | { type: ActionType.SetEmailError; value: string | undefined }
    | { type: ActionType.UpdatePermissionGeneral; value: AuthLevelRead }
    | { type: ActionType.UpdatePermissionAssets; value: AuthLevelWrite }
    | { type: ActionType.UpdatePermissionBatteryHealthTasks; value: AuthLevelWrite }
    | { type: ActionType.UpdatePermissionAdministration; value: AuthLevelAdmin }
    | { type: ActionType.ClearErrors }
    | { type: ActionType.UpdateErrors; problems: UserErrorType[] };

function reduceState(state: EditState, action: Action): EditState {
    switch (action.type) {
        case ActionType.UpdateName:
            return {
                ...state,
                name: action.value,
            };
        case ActionType.UpdateEmail:
            return {
                ...state,
                email: action.value,
            };
        case ActionType.UpdatePermissionGeneral:
            return {
                ...state,
                permissions: {
                    ...state.permissions,
                    general: action.value,
                },
            };
        case ActionType.UpdatePermissionAssets:
            return {
                ...state,
                permissions: {
                    ...state.permissions,
                    assets: action.value,
                },
            };
        case ActionType.UpdatePermissionBatteryHealthTasks:
            return {
                ...state,
                permissions: {
                    ...state.permissions,
                    batteryHealthTasks: action.value,
                },
            };
        case ActionType.UpdatePermissionAdministration:
            return {
                ...state,
                permissions: {
                    ...state.permissions,
                    administration: action.value,
                },
            };
        case ActionType.SetNameError:
            return {
                ...state,
                nameError: action.value,
            };
        case ActionType.SetEmailError:
            return {
                ...state,
                emailError: action.value,
            };
        case ActionType.ClearErrors:
            return {
                ...state,
                nameError: undefined,
                emailError: undefined,
            };
        case ActionType.UpdateErrors:
            return {
                ...state,
                ...decodeErrors(action.problems),
            };
    }
}

function decodeErrors(problems: UserErrorType[]): Partial<EditState> {
    const newState: Partial<EditState> = {};
    for (const problem of problems) {
        switch (problem) {
            case 'MissingEmail':
                newState.emailError = 'An email address must be provided';
                break;
            case 'MissingName':
                newState.nameError = 'A name must be provided';
                break;
            case 'EmailAlreadyRegistered':
                newState.emailError = 'This email address is already registered to another user';
                break;
        }
    }
    return newState;
}

function createInitialState(queryResult: EditUserContentQuery$data): EditState {
    const { user } = queryResult;
    if (!user) {
        return {
            name: '',
            email: '',
            permissions: {
                general: 'None',
                assets: 'None',
                batteryHealthTasks: 'None',
                administration: 'None',
            },
        };
    }

    return {
        name: user.name ?? '',
        email: user.email ?? '',
        permissions: {
            ...user.permissions,
        },
    };
}

export function useEditReducer(queryResult: EditUserContentQuery$data): [EditState, Dispatch<Action>] {
    return useReducer(reduceState, queryResult, createInitialState);
}
