import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { Card } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { NameValuePair } from 'components';
import { getDateTimeFormat } from 'lib/dateFormatter';
import { Paths } from 'lib/routes';

import { TaskProgressBar } from '../../components/TaskProgressBar';
import { TestHorizontalCard_data$key } from './__generated__/TestHorizontalCard_data.graphql';

interface TestHorizontalCardProps {
    task: TestHorizontalCard_data$key;
}
export const TestHorizontalCard: FC<TestHorizontalCardProps> = ({ task }) => {
    const result = useFragment<TestHorizontalCard_data$key>(
        graphql`
            fragment TestHorizontalCard_data on BatteryTest {
                id
                name
                type
                devices {
                    total
                }
                creator {
                    name
                    email
                }
                commencedTime
                ...TaskProgressBar_task
            }
        `,
        task
    );

    const { id, name, devices, commencedTime, creator, type } = result;

    const username = creator.name && creator.name.trim().length !== 0 ? creator.name : creator.email!;

    const linkToTest = `${Paths.TestsDetailsView}/${id}`;
    return (
        <Card title={name!} to={linkToTest}>
            <TaskProgressBar task={result} />
            <div className='grid grid-cols-2 mt-4 mb-2'>
                <NameValuePair name='COMMENCED' value={getDateTimeFormat(commencedTime as string)} />
                <NameValuePair name='INITIATED BY' value={username} />
                <NameValuePair name='NUMBER OF DEVICES' value={devices.total.toLocaleString()} />
                <NameValuePair name='TEST TYPE' value={`${type} Test`} />
            </div>
        </Card>
    );
};
