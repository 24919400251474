import React, { FC } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { FilterSearchSelect } from 'components/FilterSearchSelect';
import { CommonFilterProps } from 'filters/common';
import { DeviceNameFilterResult } from 'filters/device/components/DeviceNameFilterResult';
import { IEnvironment } from 'relay-runtime';

import { AlertDeviceNameFilter } from '../types';
import { AlertDeviceNameFilterUI_deviceNameSearchQuery } from './__generated__/AlertDeviceNameFilterUI_deviceNameSearchQuery.graphql';

export type AlertDeviceNameFilterUIProps = CommonFilterProps<AlertDeviceNameFilter[]>;

export const AlertDeviceNameFilterUI: FC<AlertDeviceNameFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    const environment = useRelayEnvironment();

    return (
        <FilterSearchSelect
            active={current}
            title='Filter by device'
            placeholder='Enter device name'
            renderItem={item => <DeviceNameFilterResult filter={item} />}
            renderItemSimple={item => item.name}
            showBack={hasPrevious}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onSearch={input => doSearch(environment, input, 5)}
            onClose={onClose}
            createDefaultItem={input => {
                if (input.trim().length > 0) {
                    return createWildcardFilter(input);
                } else {
                    return null;
                }
            }}
        />
    );
};

async function doSearch(
    environment: IEnvironment,
    input: string,
    maxResults: number
): Promise<AlertDeviceNameFilter[]> {
    try {
        const results = await fetchQuery<AlertDeviceNameFilterUI_deviceNameSearchQuery>(
            environment,
            graphql`
                query AlertDeviceNameFilterUI_deviceNameSearchQuery($input: String!, $maxResults: Int!) {
                    alerts(onlyActiveAlerts: false, filters: { deviceName: { value: $input } }, pageSize: $maxResults) {
                        data {
                            id
                            device {
                                id
                                name
                            }
                            site {
                                id
                                name
                                address {
                                    state
                                }
                            }
                        }
                    }
                }
            `,
            {
                input,
                maxResults: maxResults - 1,
            }
        ).toPromise();

        const alerts = results?.alerts.data ?? [];

        const output: AlertDeviceNameFilter[] = [];
        alerts.forEach(alert => {
            const device: AlertDeviceNameFilter = {
                type: 'result',
                id: alert.device.id,
                name: alert.device.name,
                siteName: alert.site.name,
                state: alert.site.address.state,
            };

            output.push(device);
        });

        if (input.trim().length > 0) {
            if (output.length >= maxResults) {
                output[output.length - 1] = createWildcardFilter(input);
            } else {
                output.push(createWildcardFilter(input));
            }
        }

        const uniqueDevices = output.filter((item, index, self) => index === self.findIndex(t => t.id === item.id));

        return uniqueDevices;
    } catch (error) {
        return [];
    }
}

function createWildcardFilter(input: string): AlertDeviceNameFilter {
    return {
        type: 'special',
        name: input.trim(),
        wildcard: true,
    };
}
