import React, { FC } from 'react';
import { QueryRenderer } from 'react-relay';
import { useRelayEnvironment } from 'react-relay';

import { ChartFrame, Column, ColumnDataType, Domain, LoadableContentArea } from '@accesstel/pcm-ui';

import { queries_CapacityHistoQuery } from '../../__generated__/queries_CapacityHistoQuery.graphql';
import { capacityHistoQuery } from '../../queries';
import style from './common.module.css';

export const BatteryCapacityHisto: FC = () => {
    const environment = useRelayEnvironment();
    return (
        <ChartFrame title='Battery capacity across network' className={style.chart}>
            <QueryRenderer<queries_CapacityHistoQuery>
                environment={environment}
                query={capacityHistoQuery}
                variables={{
                    from: 0,
                    to: 2000,
                    interval: 100,
                }}
                render={({ error, props, retry }) => (
                    <LoadableContentArea
                        data={props?.batteryMetrics.remainingCapacityHistogram}
                        error={!!error}
                        onRetry={() => retry?.()}
                        className={style.chart_loading}
                        render={result => {
                            const chartData: ColumnDataType[] = result.buckets.map(bucket => ({
                                key: `${bucket.rangeLow.toFixed(0)} Ah`,
                                tooltip: `${bucket.rangeLow.toFixed(1)} - ${bucket.rangeHigh.toFixed(1)} Ah`,
                                value: bucket.count,
                            }));

                            let maxIndexWithValue = 0;
                            chartData.forEach((data, index) => {
                                if (data.value !== 0) {
                                    maxIndexWithValue = index;
                                }
                            });

                            const filteredChartData =
                                maxIndexWithValue === 0 ? [] : chartData.slice(0, maxIndexWithValue + 1);

                            const maxValue =
                                filteredChartData.length > 0
                                    ? filteredChartData.reduce((prev, curr) => (prev.value > curr.value ? prev : curr))
                                          .value
                                    : 10;
                            const yDomain: Domain<number> | undefined = maxValue && maxValue < 11 ? [0, 10] : undefined;

                            return (
                                <Column
                                    data={filteredChartData.length > 0 ? filteredChartData : []}
                                    ticks={11}
                                    yDomain={yDomain}
                                    valueFormatter={value => `${value} devices`}
                                />
                            );
                        }}
                    />
                )}
            />
        </ChartFrame>
    );
};
