import { BatteryTestNameFilter } from 'filters/task';

export enum BatteryTestResultsColumn {
    TaskName = 'TaskName',
    Type = 'Type',
    State = 'State',
    StartTime = 'StartTime',
    RunTime = 'RunTime',
}

export type BatteryTestResultNameFilter = BatteryTestNameFilter;
