/**
 * @generated SignedSource<<abaa2fd9c52c550e952fe2c3af89a785>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GlobalFirmwareStatusName = "FirmwareUptoDate" | "Other" | "UpdatesAvailable" | "%future added value";
export type FirmwareStatusHorizontalBarQuery$variables = {};
export type FirmwareStatusHorizontalBarQuery$data = {
  readonly globalFirmwareStatus: ReadonlyArray<{
    readonly count: number;
    readonly name: GlobalFirmwareStatusName;
  }>;
};
export type FirmwareStatusHorizontalBarQuery = {
  response: FirmwareStatusHorizontalBarQuery$data;
  variables: FirmwareStatusHorizontalBarQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GlobalFirmwareStatus",
    "kind": "LinkedField",
    "name": "globalFirmwareStatus",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "count",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FirmwareStatusHorizontalBarQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FirmwareStatusHorizontalBarQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "86f8ed16d9b6034dd214471a99c9bd84",
    "id": null,
    "metadata": {},
    "name": "FirmwareStatusHorizontalBarQuery",
    "operationKind": "query",
    "text": "query FirmwareStatusHorizontalBarQuery {\n  globalFirmwareStatus {\n    name\n    count\n  }\n}\n"
  }
};
})();

(node as any).hash = "dda835d0fb3df8e68ff2766b3448bfe4";

export default node;
