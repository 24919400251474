/**
 * @generated SignedSource<<fed17225b2c3eade404c3f49c003991d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AlertDomain = "AcPower" | "Battery" | "Load" | "Rectifier" | "%future added value";
export type AlertOriginator = "Device" | "%future added value";
export type AlertSeverity = "Critical" | "Indeterminate" | "Major" | "Minor" | "Warning" | "%future added value";
export type AlertSortField = "Category" | "ClearDate" | "DeviceName" | "Domain" | "IsActive" | "Message" | "Originator" | "RaiseDate" | "Severity" | "SiteName" | "Type" | "%future added value";
export type AlertType = "Insight" | "%future added value";
export type SortDirection = "Asc" | "Desc" | "%future added value";
export type AlertOrdering = {
  dir?: SortDirection | null;
  field: AlertSortField;
};
export type AlertFilter = {
  category?: ReadonlyArray<StringFilter> | null;
  clearDate?: DateRangeFilter | null;
  deviceName?: ReadonlyArray<StringFilter> | null;
  domain?: ReadonlyArray<AlertDomainFilter> | null;
  ids?: ReadonlyArray<string> | null;
  isActive?: BooleanFilter | null;
  message?: ReadonlyArray<StringFilter> | null;
  originator?: ReadonlyArray<AlertOriginatorFilter> | null;
  raiseDate?: DateRangeFilter | null;
  severity?: ReadonlyArray<AlertSeverityFilter> | null;
  siteName?: ReadonlyArray<StringFilter> | null;
  suggestions?: ReadonlyArray<StringFilter> | null;
  type?: ReadonlyArray<AlertTypeFilter> | null;
};
export type AlertTypeFilter = {
  value: AlertType;
};
export type AlertSeverityFilter = {
  value: AlertSeverity;
};
export type StringFilter = {
  value: string;
};
export type AlertDomainFilter = {
  value: AlertDomain;
};
export type AlertOriginatorFilter = {
  value: AlertOriginator;
};
export type DateRangeFilter = {
  max: string;
  min: string;
};
export type BooleanFilter = {
  value: boolean;
};
export type AlertListTableQuery$variables = {
  filter?: AlertFilter | null;
  onlyActiveAlerts?: boolean | null;
  orderBy?: AlertOrdering | null;
  page?: number | null;
  search?: string | null;
};
export type AlertListTableQuery$data = {
  readonly alerts: {
    readonly data: ReadonlyArray<{
      readonly category: string;
      readonly clearDate: string | null;
      readonly device: {
        readonly id: string;
        readonly name: string;
      };
      readonly domain: AlertDomain;
      readonly id: string;
      readonly isActive: boolean;
      readonly message: string;
      readonly originator: AlertOriginator;
      readonly raiseDate: string;
      readonly severity: AlertSeverity;
      readonly site: {
        readonly address: {
          readonly state: string;
        };
        readonly id: string;
        readonly name: string;
      };
      readonly suggestions: ReadonlyArray<string>;
      readonly type: AlertType;
    }>;
    readonly pageInfo: {
      readonly hasNext: boolean | null;
      readonly hasPrevious: boolean | null;
      readonly page: number;
      readonly size: number;
      readonly total: number;
    };
    readonly total: number;
  };
  readonly overallAlerts: {
    readonly total: number;
  };
};
export type AlertListTableQuery = {
  response: AlertListTableQuery$data;
  variables: AlertListTableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "onlyActiveAlerts"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v3 = {
  "defaultValue": 1,
  "kind": "LocalArgument",
  "name": "page"
},
v4 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "search"
},
v5 = {
  "kind": "Variable",
  "name": "onlyActiveAlerts",
  "variableName": "onlyActiveAlerts"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filters",
        "variableName": "filter"
      },
      (v5/*: any*/),
      {
        "kind": "Variable",
        "name": "orderBy",
        "variableName": "orderBy"
      },
      {
        "kind": "Variable",
        "name": "page",
        "variableName": "page"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      }
    ],
    "concreteType": "PaginatedAlerts",
    "kind": "LinkedField",
    "name": "alerts",
    "plural": false,
    "selections": [
      (v6/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Alert",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "severity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "category",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "domain",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "originator",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "raiseDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clearDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isActive",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "suggestions",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Device",
            "kind": "LinkedField",
            "name": "device",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Site",
            "kind": "LinkedField",
            "name": "site",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Address",
                "kind": "LinkedField",
                "name": "address",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "state",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "page",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "size",
            "storageKey": null
          },
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasNext",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasPrevious",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": "overallAlerts",
    "args": [
      (v5/*: any*/)
    ],
    "concreteType": "PaginatedAlerts",
    "kind": "LinkedField",
    "name": "alerts",
    "plural": false,
    "selections": [
      (v6/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AlertListTableQuery",
    "selections": (v9/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "AlertListTableQuery",
    "selections": (v9/*: any*/)
  },
  "params": {
    "cacheID": "e18a8150a0346819ae06e2217e417181",
    "id": null,
    "metadata": {},
    "name": "AlertListTableQuery",
    "operationKind": "query",
    "text": "query AlertListTableQuery(\n  $page: Int = 1\n  $search: String = \"\"\n  $orderBy: AlertOrdering\n  $filter: AlertFilter\n  $onlyActiveAlerts: Boolean\n) {\n  alerts(onlyActiveAlerts: $onlyActiveAlerts, page: $page, search: $search, orderBy: $orderBy, filters: $filter) {\n    total\n    data {\n      id\n      type\n      severity\n      category\n      domain\n      message\n      originator\n      raiseDate\n      clearDate\n      isActive\n      suggestions\n      device {\n        id\n        name\n      }\n      site {\n        id\n        name\n        address {\n          state\n        }\n      }\n    }\n    pageInfo {\n      page\n      size\n      total\n      hasNext\n      hasPrevious\n    }\n  }\n  overallAlerts: alerts(onlyActiveAlerts: $onlyActiveAlerts) {\n    total\n  }\n}\n"
  }
};
})();

(node as any).hash = "2c35c76e41f359588098ce6f7287d2f9";

export default node;
