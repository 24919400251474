import React, { FC } from 'react';
import { QueryRenderer } from 'react-relay';
import { useRelayEnvironment } from 'react-relay';

import { ChartFrame, Column, ColumnDataType, Domain, LoadableContentArea } from '@accesstel/pcm-ui';

import { queries_TimeRemainingHistoQuery } from '../../__generated__/queries_TimeRemainingHistoQuery.graphql';
import { timeRemainingHistoQuery } from '../../queries';
import style from './common.module.css';

export const BatteryTimeRemainingHisto: FC = () => {
    const environment = useRelayEnvironment();

    return (
        <ChartFrame title='Battery time remaining across network' className={style.chart}>
            <QueryRenderer<queries_TimeRemainingHistoQuery>
                environment={environment}
                query={timeRemainingHistoQuery}
                variables={{
                    from: 0,
                    to: 24,
                    interval: 1,
                }}
                render={({ error, props, retry }) => {
                    const data = props?.batteryMetrics.timeRemainingHistogram;

                    return (
                        <LoadableContentArea
                            data={data}
                            error={!!error}
                            onRetry={() => retry?.()}
                            className={style.chart_loading}
                            render={data => {
                                const chartData: ColumnDataType[] = data.buckets.map(bucket => ({
                                    key: `${bucket.rangeLow.toFixed(0)} hour${bucket.rangeLow !== 1 ? 's' : ''}`,
                                    tooltip: `${bucket.rangeLow.toFixed(1)} - ${bucket.rangeHigh.toFixed(1)} Hours`,
                                    value: bucket.count,
                                }));

                                let maxIndexWithValue = 0;
                                chartData.forEach((data, index) => {
                                    if (data.value !== 0) {
                                        maxIndexWithValue = index;
                                    }
                                });

                                const filteredChartData =
                                    maxIndexWithValue === 0 ? [] : chartData.slice(0, maxIndexWithValue + 1);

                                const maxValue =
                                    filteredChartData.length > 0
                                        ? filteredChartData.reduce((prev, curr) =>
                                              prev.value > curr.value ? prev : curr
                                          ).value
                                        : 10;
                                const yDomain: Domain<number> | undefined =
                                    maxValue && maxValue < 11 ? [0, 10] : undefined;

                                return (
                                    <Column
                                        data={filteredChartData.length ? filteredChartData : []}
                                        ticks={11}
                                        yDomain={yDomain}
                                        valueFormatter={value => `${value} devices`}
                                    />
                                );
                            }}
                        />
                    );
                }}
            />
        </ChartFrame>
    );
};
