import React from 'react';
import { RouteObject } from 'react-router-dom';

import { Dashboard } from './Dashboard';

const Routes: RouteObject[] = [
    {
        index: true,
        element: <Dashboard />,
    },
];

export default Routes;
