import React, { FC } from 'react';

import { FilterDateSelect } from 'components/FilterDateSelect';
import { PredefinedRange } from 'components/FilterDateSelect/common';
import { Last5Years, LastYear, MoreThan5Years } from 'components/FilterDateSelect/presets';

import { CommonFilterProps } from '../../common/types';
import { DeviceBatteryDateFilter } from '../types';

const FilterPresets: PredefinedRange[] = [LastYear, Last5Years, MoreThan5Years];

export interface DeviceBatteryDateFilterUIProps extends CommonFilterProps<DeviceBatteryDateFilter> {
    title: string;
}

export const DeviceBatteryDateFilterUI: FC<DeviceBatteryDateFilterUIProps> = ({
    title,
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    return (
        <FilterDateSelect
            title={title}
            current={current ?? null}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onClose={onClose}
            showBack={hasPrevious}
            presets={FilterPresets}
        />
    );
};
