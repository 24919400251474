import React, { FC } from 'react';

import { MultipleSelect } from 'components/MultipleSelect';
import { getNiceSNMPVersion } from 'lib/textFormatters';

import { CommonFilterProps } from '../../common/types';
import { DeviceSNMPVersionFilter } from '../types';

export type DeviceSNMPVersionFilterUIProps = CommonFilterProps<DeviceSNMPVersionFilter[]>;

export const DeviceSNMPVersionFilterUI: FC<DeviceSNMPVersionFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    const options: DeviceSNMPVersionFilter[] = [
        { id: '1', name: getNiceSNMPVersion('V1') },
        { id: '2c', name: getNiceSNMPVersion('V2c') },
        { id: '3', name: getNiceSNMPVersion('V3') },
    ];

    return (
        <MultipleSelect
            options={options}
            active={current}
            title='Filter by SNMP Version'
            renderItem={item => item.name}
            renderItemSimple={item => item.name}
            areItemsEqual={(itemA, itemB) => itemA.id === itemB.id}
            showBack={hasPrevious}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onClose={onClose}
        />
    );
};
