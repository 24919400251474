export const Paths = {
    Dashboard: '/',
    // Powercontrollers
    PowerControllers: '/sites',
    // Assets
    Assets: '/manage',
    Sites: '/manage/sites',
    Devices: '/manage/devices',
    BatteryTypes: '/manage/batterytypes',
    // Add Assets
    AddSite: '/manage/sites/add',
    AddBatteryType: '/manage/batterytypes/add',
    AddDevice: '/manage/devices/add',
    // Edit Assets
    EditSite: '/manage/sites/:id',
    EditBatteryType: '/manage/batterytypes/:id',
    EditDevice: '/manage/devices/:id',
    // Tests
    Tasks: '/tasks',
    TestsOverview: '/tasks/batteryhealth',
    TestsScheduleTest: '/tasks/batteryhealth/schedule',
    TestsInProgress: '/tasks/batteryhealth/inprogress',
    TestsCancelledAborted: '/tasks/batteryhealth/cancelled',
    TestsCompleted: '/tasks/batteryhealth/completed',
    TestsScheduled: '/tasks/batteryhealth/scheduled',
    TestsUnplanned: '/tasks/batteryhealth/external',
    ViewExternalTestResults: '/tasks/batteryhealth/external/:id',
    TestsDetailsView: '/tasks/batteryhealth/view',
    ViewTaskDetails: '/tasks/batteryhealth/view/:id',
    ViewTaskDeviceResults: '/tasks/batteryhealth/view/:taskId/:deviceId',
    // REPORTS
    ReportsOverview: '/reports',
    // Batteries
    ReportBatteries: '/reports/batteries',
    ReportBatterySiteList: '/reports/batteries/sites',
    ReportBatteriesViewSite: '/reports/batteries/sites/:siteId',
    ReportBatteriesViewSiteDevice: '/reports/batteries/sites/:siteId/:deviceId',
    ReportBatteriesByTestDevice: '/reports/batteries/test',
    ViewTestResultFromReport: '/reports/batteries/test/:id',
    // Alerts
    ReportAlerts: '/reports/alerts',

    Settings: '/settings',
    SettingsUsers: '/settings/users',

    // SiteView
    SiteViewViewSite: '/sites/:siteId',
    SiteViewViewSiteDevice: '/sites/:siteId/:deviceId',
    SiteViewViewSiteDevicePage: '/sites/:siteId/:deviceId/:page',
    SiteViewRelativeDevicePage: ':deviceId/:page',

    // Explore
    Explore: '/explore',
    ExploreMetrics: '/explore/metrics',
};
