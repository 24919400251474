import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { fetchQuery, readInlineData, useFragment, useRelayEnvironment } from 'react-relay';

import { Domain, MenuItemType, Theme } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { TimeseriesOffsetSeries } from 'lib/dateChart';
import { useZoomableData } from 'lib/line-chart-zoom';
import { DateTime, Duration } from 'luxon';
import { MetricLine } from 'views/tasks/battery-health/components/MetricsLine';
import { ChartRefreshInterval } from 'views/tasks/battery-health/settings';

import {
    AggregatedDataPoint,
    MetricsData,
    getDisplayInterval,
    spliceAllData,
    toTimeSeriesOffsetData,
} from '../../common';
import { useCDFOverlays } from '../../lib/cdf';
import { MenuOptions } from '../../lib/common';
import { supportsTemperatureMetric } from '../../lib/feature-support';
import { DataDisplayCombinedBase_test$key } from './__generated__/DataDisplayCombinedBase_test.graphql';
import { DataDisplayCombinedQuery } from './__generated__/DataDisplayCombinedQuery.graphql';
import { DataDisplayCombined_test$key } from './__generated__/DataDisplayCombined_test.graphql';

const DefaultChartXDomain: Domain<Duration> = ['dataMin', 'dataMax'];

const DataQuery = graphql`
    query DataDisplayCombinedQuery($test1: ID!, $test2: ID!, $interval: Duration, $begin: Duration, $end: Duration) {
        test1: batteryTestResult(id: $test1) {
            device {
                name
            }
            ...DataDisplayCombined_test
        }
        test2: batteryTestResult(id: $test2) {
            device {
                name
            }
            ...DataDisplayCombined_test
        }
    }
`;

const BatteryTestDataInlineFragment = graphql`
    fragment DataDisplayCombined_test on DeviceBatteryTestResults @inline {
        aggregatedVoltage(unit: Volts, interval: $interval, begin: $begin, end: $end) {
            timestamp
            offset
            average
        }
        aggregatedTemperature(unit: Celsius, interval: $interval, begin: $begin, end: $end) {
            timestamp
            offset
            average
        }
        aggregatedCurrent(unit: Amps, interval: $interval, begin: $begin, end: $end) {
            timestamp
            offset
            average
        }
        aggregatedPower(unit: Watt, interval: $interval, begin: $begin, end: $end) {
            timestamp
            offset
            average
        }
        commencedTime
        completedTime
        abortedTime
        state

        coupDeFouet {
            float
            troughVoltage
            troughOffset
            plateauVoltage
            plateauOffset
            dip
        }
    }
`;

const BaseTestFragment = graphql`
    fragment DataDisplayCombinedBase_test on DeviceBatteryTestResults {
        id
        device {
            name
        }
        commencedTime
        completedTime
    }
`;

interface DataDisplayCombinedProps {
    test1Ref: DataDisplayCombinedBase_test$key;
    test2Ref: DataDisplayCombinedBase_test$key;

    live?: boolean;
}

export const DataDisplayCombined: FC<DataDisplayCombinedProps> = ({ test1Ref, test2Ref, live }) => {
    const environment = useRelayEnvironment();
    const [selectedMenuOption, setSelectedMenuOption] = useState<string | undefined>(MenuOptions.ViewAll);

    const test1Info = useFragment<DataDisplayCombinedBase_test$key>(BaseTestFragment, test1Ref);
    const test2Info = useFragment<DataDisplayCombinedBase_test$key>(BaseTestFragment, test2Ref);

    // Use the minimum start date and the maxium end date between the 2 tests
    let commencedTime: DateTime | null = null;
    let completedTime: DateTime | null = null;

    if (test1Info.commencedTime) {
        commencedTime = DateTime.fromISO(test1Info.commencedTime);
    }
    if (test2Info.commencedTime) {
        const secondStartDate = DateTime.fromISO(test2Info.commencedTime);
        if (!commencedTime || secondStartDate < commencedTime) {
            commencedTime = secondStartDate;
        }
    }

    if (test1Info.completedTime) {
        completedTime = DateTime.fromISO(test1Info.completedTime);
    }
    if (test2Info.completedTime) {
        const secondStartDate = DateTime.fromISO(test2Info.completedTime);
        if (!completedTime || secondStartDate > completedTime) {
            completedTime = secondStartDate;
        }
    }

    const fetchData = useCallback(
        (interval: Duration | null, begin: Duration | null, end: Duration | null) => {
            if (!interval && commencedTime) {
                if (!completedTime) {
                    interval = getDisplayInterval(commencedTime, DateTime.now());
                } else {
                    interval = getDisplayInterval(commencedTime, completedTime);
                }
            }

            return fetchQuery<DataDisplayCombinedQuery>(environment, DataQuery, {
                test1: test1Info.id,
                test2: test2Info.id,
                begin: begin?.toISO(),
                end: end?.toISO(),
                interval: interval?.toISO(),
            }).toPromise();
        },
        [commencedTime, environment, test1Info.id, test2Info.id, completedTime]
    );

    const { chartDomain, doZoom, baseData, hasError, reloadData, zoomData } = useZoomableData(
        DefaultChartXDomain,
        (_, zoomed) => {
            if (!zoomed) {
                setSelectedMenuOption(MenuOptions.ViewAll);
                return;
            }

            setSelectedMenuOption(undefined);
        },
        fetchData
    );

    // Live data
    useEffect(() => {
        if (live) {
            const handle = setInterval(reloadData, ChartRefreshInterval);
            return () => {
                clearInterval(handle);
            };
        }
    }, [live, reloadData]);

    const planeAData = useMemo<MetricsData>(() => {
        if (baseData?.test1) {
            const baseTest = readInlineData<DataDisplayCombined_test$key>(
                BatteryTestDataInlineFragment,
                baseData.test1
            );

            const baseMetrics: MetricsData = [
                toTimeSeriesOffsetData(baseTest?.aggregatedVoltage as AggregatedDataPoint[]),
                toTimeSeriesOffsetData(baseTest?.aggregatedTemperature as AggregatedDataPoint[]),
                toTimeSeriesOffsetData(baseTest?.aggregatedCurrent as AggregatedDataPoint[], true),
                toTimeSeriesOffsetData(baseTest?.aggregatedPower as AggregatedDataPoint[], true),
            ];

            if (zoomData?.test1) {
                const zoomTest = readInlineData<DataDisplayCombined_test$key>(
                    BatteryTestDataInlineFragment,
                    zoomData.test1
                );
                const overlay: MetricsData = [
                    toTimeSeriesOffsetData(zoomTest?.aggregatedVoltage as AggregatedDataPoint[]),
                    toTimeSeriesOffsetData(zoomTest?.aggregatedTemperature as AggregatedDataPoint[]),
                    toTimeSeriesOffsetData(zoomTest?.aggregatedCurrent as AggregatedDataPoint[], true),
                    toTimeSeriesOffsetData(zoomTest?.aggregatedPower as AggregatedDataPoint[], true),
                ];

                return spliceAllData(baseMetrics, overlay);
            } else {
                return baseMetrics;
            }
        } else {
            return [null, null, null, null];
        }
    }, [baseData, zoomData]);

    const planeBData = useMemo<MetricsData>(() => {
        if (baseData?.test2) {
            const baseTest = readInlineData<DataDisplayCombined_test$key>(
                BatteryTestDataInlineFragment,
                baseData.test2
            );
            const baseMetrics: MetricsData = [
                toTimeSeriesOffsetData(baseTest?.aggregatedVoltage as AggregatedDataPoint[]),
                toTimeSeriesOffsetData(baseTest?.aggregatedTemperature as AggregatedDataPoint[]),
                toTimeSeriesOffsetData(baseTest?.aggregatedCurrent as AggregatedDataPoint[], true),
                toTimeSeriesOffsetData(baseTest?.aggregatedPower as AggregatedDataPoint[], true),
            ];

            if (zoomData?.test2) {
                const zoomTest = readInlineData<DataDisplayCombined_test$key>(
                    BatteryTestDataInlineFragment,
                    zoomData.test2
                );
                const overlay: MetricsData = [
                    toTimeSeriesOffsetData(zoomTest?.aggregatedVoltage as AggregatedDataPoint[]),
                    toTimeSeriesOffsetData(zoomTest?.aggregatedTemperature as AggregatedDataPoint[]),
                    toTimeSeriesOffsetData(zoomTest?.aggregatedCurrent as AggregatedDataPoint[], true),
                    toTimeSeriesOffsetData(zoomTest?.aggregatedPower as AggregatedDataPoint[], true),
                ];

                return spliceAllData(baseMetrics, overlay);
            } else {
                return baseMetrics;
            }
        } else {
            return [null, null, null, null];
        }
    }, [baseData, zoomData]);

    const voltageDataSeries = useMemo<TimeseriesOffsetSeries[] | null>(() => {
        if (planeAData[0] && planeBData[0] && planeAData[0].length > 0 && planeBData[0].length > 0) {
            return [
                {
                    name: test1Info.device.name ?? '',
                    lineColor: Theme.customCoral,
                    points: planeAData[0],
                },
                {
                    name: test2Info.device.name ?? '',
                    lineColor: Theme.customEggplant,
                    points: planeBData[0],
                },
            ];
        }
        return null;
    }, [planeAData, planeBData, test1Info.device.name, test2Info.device.name]);

    const temperatureDataSeries = useMemo<TimeseriesOffsetSeries[] | null>(() => {
        if (planeAData[1] && planeBData[1] && planeAData[1].length > 0 && planeBData[1].length > 0) {
            return [
                {
                    name: test1Info.device.name ?? '',
                    lineColor: Theme.customCoral,
                    points: planeAData[1],
                },
                {
                    name: test2Info.device.name ?? '',
                    lineColor: Theme.customEggplant,
                    points: planeBData[1],
                },
            ];
        }
        return null;
    }, [planeAData, planeBData, test1Info.device.name, test2Info.device.name]);

    const currentDataSeries = useMemo<TimeseriesOffsetSeries[] | null>(() => {
        if (planeAData[2] && planeBData[2] && planeAData[2].length > 0 && planeBData[2].length > 0) {
            return [
                {
                    name: test1Info.device.name ?? '',
                    lineColor: Theme.customCoral,
                    points: planeAData[2],
                },
                {
                    name: test2Info.device.name ?? '',
                    lineColor: Theme.customEggplant,
                    points: planeBData[2],
                },
            ];
        }
        return null;
    }, [planeAData, planeBData, test1Info.device.name, test2Info.device.name]);

    const powerDataSeries = useMemo<TimeseriesOffsetSeries[] | null>(() => {
        if (planeAData[3] && planeBData[3] && planeAData[3].length > 0 && planeBData[3].length > 0) {
            return [
                {
                    name: test1Info.device.name ?? '',
                    lineColor: Theme.customCoral,
                    points: planeAData[3],
                },
                {
                    name: test2Info.device.name ?? '',
                    lineColor: Theme.customEggplant,
                    points: planeBData[3],
                },
            ];
        }
        return null;
    }, [planeAData, planeBData, test1Info.device.name, test2Info.device.name]);

    const test1 = baseData
        ? readInlineData<DataDisplayCombined_test$key>(BatteryTestDataInlineFragment, baseData.test1)
        : null;
    const test2 = baseData
        ? readInlineData<DataDisplayCombined_test$key>(BatteryTestDataInlineFragment, baseData.test2)
        : null;

    const [markers, cdfMenuOptions] = useCDFOverlays(
        commencedTime ? commencedTime : DateTime.now(),
        completedTime ? completedTime : DateTime.now(),
        chartDomain,
        [test1?.coupDeFouet, test2?.coupDeFouet].filter(notNull),
        cdfDomain => {
            doZoom(cdfDomain);
            setSelectedMenuOption(MenuOptions.ViewCDF);
        }
    );

    const menuOptions: MenuItemType[] = [
        {
            id: MenuOptions.ViewAll,
            name: 'All',
            onClick() {
                doZoom(null);
                setSelectedMenuOption(MenuOptions.ViewAll);
            },
        },
        ...cdfMenuOptions,
    ];

    const isTemperatureSupported = supportsTemperatureMetric(planeAData) || supportsTemperatureMetric(planeBData);

    return (
        <div>
            <MetricLine
                type='voltage'
                xDomain={chartDomain}
                series={voltageDataSeries}
                error={hasError}
                onRetry={reloadData}
                onZoom={doZoom}
                markers={markers}
                menuItems={menuOptions}
                menuPlaceholder='Zoom'
                menuSelected={selectedMenuOption}
                collapsible={true}
            />
            <MetricLine
                type='current'
                xDomain={chartDomain}
                series={currentDataSeries}
                error={hasError}
                onRetry={reloadData}
                onZoom={doZoom}
                collapsible={true}
            />
            <MetricLine
                type='power'
                xDomain={chartDomain}
                series={powerDataSeries}
                error={hasError}
                onRetry={reloadData}
                onZoom={doZoom}
                collapsible={true}
            />
            <MetricLine
                type='temperature'
                xDomain={chartDomain}
                series={temperatureDataSeries}
                error={hasError}
                onRetry={reloadData}
                onZoom={doZoom}
                unsupported={!isTemperatureSupported}
                collapsible={true}
            />
        </div>
    );
};

// Note: This was created just to use the type assertion in the .filter() above
function notNull<T>(item: T | null | undefined): item is T {
    return item != null;
}
