import { Dispatch } from 'react';

import { FilterAction, FilterState, useFilterReducer } from '../common';
import { DefaultExtraFilters, DefaultValues, StaticUserFilterDefinitions, UserColumnFilterMap } from './settings';
import { UserTableColumn } from './types';

export type UserFilterState = FilterState<UserTableColumn, UserColumnFilterMap>;
export type UserFilterAction = FilterAction<UserColumnFilterMap, UserTableColumn>;

export function useUserFilter(): [UserFilterState, Dispatch<UserFilterAction>] {
    return useFilterReducer<UserTableColumn, UserColumnFilterMap, UserFilterState, UserFilterAction>(
        'user-filter',
        DefaultValues,
        StaticUserFilterDefinitions,
        DefaultExtraFilters
    );
}

export function userToFilterObject(filters: UserFilterState): Record<string, unknown> {
    return {};
}
