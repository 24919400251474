import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { MinusIcon, PlusIcon } from '@accesstel/pcm-ui';

import { Row } from '@tanstack/react-table';
import graphql from 'babel-plugin-relay/macro';
import { AlertTableColumn } from 'filters/alert';
import { AlertColumnFilterMap } from 'filters/alert/settings';
import { CoreTableLayout, CoreTableLayoutProps } from 'layouts/TableLayout/CoreTableLayout';
import { Alert } from 'views/reports';
import { AlertListExpandedRow } from 'views/reports/alerts/alert-list/components';
import { AllTableColumns } from 'views/reports/alerts/alert-list/settings';

import { InsightTable_alerts$key } from './__generated__/InsightTable_alerts.graphql';

export interface InsightTableProps
    extends Pick<
        CoreTableLayoutProps<AlertTableColumn, Alert, AlertColumnFilterMap, never>,
        'tableState' | 'dispatchTableState' | 'filterState' | 'dispatchFilterState' | 'isProcessing' | 'hasError'
    > {
    tableData: InsightTable_alerts$key;

    onlyActiveAlerts?: boolean;
    toggleActiveAlerts: () => void;
}

export const InsightTable: FC<InsightTableProps> = ({
    tableData,
    onlyActiveAlerts,
    toggleActiveAlerts,
    ...tableProps
}) => {
    const data = useFragment(TableFragment, tableData);

    return (
        <CoreTableLayout
            tableSize='compact'
            columns={AllTableColumns}
            data={data.data}
            page={data.pageInfo.page}
            pageCount={data.pageInfo.total}
            getRowId={row => row.id}
            tableVariant='white'
            allowEditingColumns
            emptyMessage={onlyActiveAlerts ? 'No active insights' : 'No insights found'}
            additionalActions={[
                {
                    buttonIcon: onlyActiveAlerts ? <PlusIcon /> : <MinusIcon />,
                    buttonText: onlyActiveAlerts ? 'Show all alerts' : 'Hide cleared alerts',
                    onClick: toggleActiveAlerts,
                },
            ]}
            rowExpansionComponent={(row: Row<Alert>) => <AlertListExpandedRow row={row} />}
            {...tableProps}
        />
    );
};

const TableFragment = graphql`
    fragment InsightTable_alerts on PaginatedAlerts {
        total
        data {
            id
            type
            severity
            category
            domain
            message
            originator
            raiseDate
            clearDate
            isActive
            suggestions
            device {
                id
                name
            }
            site {
                id
                name
                address {
                    state
                }
            }
        }
        pageInfo {
            page
            size
            total
            hasNext
            hasPrevious
        }
    }
`;
