import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { AuthenticatedSection } from 'components/AuthenticatedSection';
import { RouteBreadcrumbData } from 'lib/breadcrumb';

import { InProgressBatteryHealthTaskList } from './battery-health/in-progress-task-list/InProgressBatteryHealthTaskList';
import { BatteryHealthTaskOverview } from './battery-health/overview/BatteryHealthTaskOverview';
import { Schedule } from './battery-health/schedule';
import { BatteryHealthTaskList } from './battery-health/task-list/BatteryHealthTaskList';
import { ViewBatteryHealthTask, getTaskViewBreadcrumb } from './battery-health/task-view';
import { TestResultList } from './battery-health/test-result-list/TestResultList';
import { ViewBatteryTestResultByTask } from './battery-health/test-result-view/ViewBatteryTestResultByTask';
import { ViewBatteryTestResultDirect } from './battery-health/test-result-view/ViewBatteryTestResultDirect';

const Routes: RouteObject[] = [
    {
        path: 'tasks',
        element: <AuthenticatedSection tasksRead />,
        handle: {
            title: 'Tasks',
        },
        children: [
            {
                index: true,
                element: <Navigate replace to='batteryhealth' />,
            },
            {
                path: 'batteryhealth',
                handle: {
                    title: 'Battery Health Tests',
                },
                children: [
                    {
                        index: true,
                        element: <BatteryHealthTaskOverview />,
                    },
                    {
                        path: 'schedule',
                        element: <AuthenticatedSection tasksWrite />,
                        handle: {
                            title: 'Schedule Battery Health Test',
                        },
                        children: [
                            {
                                index: true,
                                element: <Schedule />,
                            },
                        ],
                    },
                    {
                        path: 'inprogress',
                        element: <InProgressBatteryHealthTaskList />,
                        handle: {
                            title: 'In-progress tests',
                        },
                    },
                    {
                        path: 'cancelled',
                        element: <BatteryHealthTaskList pageType='cancelled' />,
                        handle: {
                            title: 'Cancelled tests',
                        },
                    },
                    {
                        path: 'completed',
                        element: <BatteryHealthTaskList pageType='completed' />,
                        handle: {
                            title: 'Completed tests',
                        },
                    },
                    {
                        path: 'scheduled',
                        element: <BatteryHealthTaskList pageType='scheduled' />,
                        handle: {
                            title: 'Scheduled tests',
                        },
                    },
                    {
                        path: 'external',
                        element: <TestResultList />,
                        handle: {
                            title: 'External discharges',
                        },
                    },
                    {
                        path: 'external/:id',
                        element: <ViewBatteryTestResultDirect />,
                        handle: {
                            // TODO: Add a lookup for name if we add child routes
                            title: params => params.id,
                        } as RouteBreadcrumbData,
                    },
                    {
                        path: 'view/:taskId',
                        handle: {
                            title: params => getTaskViewBreadcrumb(params.taskId!),
                        } as RouteBreadcrumbData,
                        children: [
                            {
                                index: true,
                                element: <ViewBatteryHealthTask />,
                            },
                            {
                                path: ':deviceId',
                                element: <ViewBatteryTestResultByTask />,
                                handle: {
                                    // TODO: Add a lookup for name if we add child routes
                                    title: params => params.id,
                                } as RouteBreadcrumbData,
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

export default Routes;
