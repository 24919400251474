/**
 * @generated SignedSource<<54d186404912b1b7dd4469aa977e3ee3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeviceHealth = "Critical" | "Degraded" | "Healthy" | "Offline" | "Unknown" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ModuleTile_data$data = {
  readonly id: string;
  readonly label: string;
  readonly metrics: {
    readonly latestOutputCurrent: number | null;
    readonly latestTemperature: number | null;
    readonly status: DeviceHealth;
  };
  readonly " $fragmentType": "ModuleTile_data";
};
export type ModuleTile_data$key = {
  readonly " $data"?: ModuleTile_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"ModuleTile_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ModuleTile_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RectifierModuleMetrics",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latestTemperature",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latestOutputCurrent",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RectifierModule",
  "abstractKey": null
};

(node as any).hash = "bf55a08fe12eef07b4136800c03febef";

export default node;
