/**
 * @generated SignedSource<<429217066b7a69a8bed728797f5b1e24>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BatteryTestState = "Aborted" | "Analyzing" | "Failed" | "Finalizing" | "InProgress" | "Inconclusive" | "Passed" | "Scheduled" | "SmartStart" | "Waiting" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CombinedTest_test$data = {
  readonly abortedTime: string | null;
  readonly commencedTime: string | null;
  readonly completedTime: string | null;
  readonly device: {
    readonly id: string;
  };
  readonly id: string;
  readonly state: BatteryTestState;
  readonly " $fragmentSpreads": FragmentRefs<"DataDisplayCombinedBase_test" | "DataDisplay_test" | "MetricsViewCombinedLive_test" | "MetricsViewCombined_test">;
  readonly " $fragmentType": "CombinedTest_test";
};
export type CombinedTest_test$key = {
  readonly " $data"?: CombinedTest_test$data;
  readonly " $fragmentSpreads": FragmentRefs<"CombinedTest_test">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CombinedTest_test",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commencedTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completedTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "abortedTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Device",
      "kind": "LinkedField",
      "name": "device",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MetricsViewCombined_test"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DataDisplay_test"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DataDisplayCombinedBase_test"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MetricsViewCombinedLive_test"
    }
  ],
  "type": "DeviceBatteryTestResults",
  "abstractKey": null
};
})();

(node as any).hash = "df5fd2637c8415c392349e435629bd08";

export default node;
