import React, { FC, ReactNode, useMemo, useState } from 'react';
import { generatePath } from 'react-router-dom';

import { Dropdown, Menu, MenuItem, MenuItemType, Modal, useExtendedNavigate } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { AlertBanner } from 'components/AlertBanner';
import { useUserPermissions } from 'lib/auth';
import { useQuery } from 'lib/query-helpers';
import { Paths } from 'lib/routes';
import { ExportForm } from 'views/exporting/components';
import { DefaultTimeRanges, useTimeRange } from 'views/reports/common';
import { BlocId } from 'views/tasks/battery-health/test-result-view/components/common';

import { SiteViewPagePath } from '../../../../sites/paths';
import parentStyle from '../../style.module.css';
import { DeviceReport_ReportQuery } from './__generated__/DeviceReport_ReportQuery.graphql';
import { CapacityChart } from './components/CapacityChart';
import { CapacitySparkline } from './components/CapacitySparkline';
import { CurrentChart } from './components/CurrentChart';
import { CurrentSparkline } from './components/CurrentSparkline';
import { Details } from './components/Details';
import { DeviceBatteryHealth } from './components/DeviceBatteryHealth';
import { DeviceBatteryTests } from './components/DeviceBatteryTests';
import { EnergyChart } from './components/EnergyChart';
import { LastCollectedTime } from './components/LastCollectedTime';
import { RunHoursGauge } from './components/RunHoursGauge';
import { StatusHoursDoughnut } from './components/StatusHoursDoughnut';
import { TemperatureChart } from './components/TemperatureChart';
import { TemperatureSparkline } from './components/TemperatureSparkline';
import { VoltageChart } from './components/VoltageChart';
import { VoltageSparkline } from './components/VoltageSparkline';
import { Details_device$key } from './components/__generated__/Details_device.graphql';
import style from './style.module.css';

export interface DeviceReportProps {
    id: string;
    name: string;
    device: Details_device$key;
    batteryInstalled: boolean;
}

export const DeviceReport: FC<DeviceReportProps> = ({ id, name, device, batteryInstalled }) => {
    const navigate = useExtendedNavigate();

    const [timeRange, timeRangeMenuItems] = useTimeRange(DefaultTimeRanges[0]);
    const { hasTasksRead, hasAssetsRead, hasAssetsWrite } = useUserPermissions();

    const { data: props } = useQuery<DeviceReport_ReportQuery>(
        graphql`
            query DeviceReport_ReportQuery($id: ID!) {
                device(id: $id) {
                    site {
                        id
                    }
                    battery {
                        metrics {
                            ...VoltageSparkline_battery
                            ...CurrentSparkline_battery
                            ...TemperatureSparkline_battery
                            ...CapacitySparkline_battery
                            ...RunHoursGauge_battery
                        }
                        blocs {
                            metrics {
                                bloc
                                string
                            }
                        }
                    }
                    ...LastCollectedTime_battery
                }
            }
        `,
        {
            id,
        },
        {
            // skip the query if there are no batteries present
            skip: !batteryInstalled,
        }
    );

    const [selectedBloc, setSelectedBloc] = useState<BlocId | null>(null);
    const [isExportModalOpen, setIsExportModalOpen] = useState(false);

    const batteryBlocs = props?.device?.battery.blocs;

    const blocSelectionMenuItems = useMemo<MenuItemType[]>(() => {
        let blocOptions: MenuItemType[] = [];
        if (batteryBlocs) {
            blocOptions = batteryBlocs.map(bloc => ({
                id: `${bloc.metrics.string}-${bloc.metrics.bloc}`,
                name: `String ${bloc.metrics.string} Bloc ${bloc.metrics.bloc}`,
                onClick: () => setSelectedBloc([bloc.metrics.string, bloc.metrics.bloc]),
            }));
        }
        return [
            {
                id: 'overview',
                name: 'Overall',
                onClick: () => setSelectedBloc(null),
            },
            ...blocOptions,
        ];
    }, [batteryBlocs]);

    let deviceDetails: ReactNode;
    let body: ReactNode = <></>;
    const viewEditMenuOption: MenuItem = {
        name: `${hasAssetsWrite ? 'Edit' : 'View'} Device`,
        onClick: () => navigate({ pathname: Paths.EditDevice, params: { id } }),
    };
    const menuItems: MenuItem[] = [];

    if (hasAssetsRead) {
        menuItems.push(viewEditMenuOption);
    }

    if (batteryInstalled) {
        const batteryInfo = props?.device?.battery.metrics ?? null;

        menuItems.push({ name: 'Export', onClick: () => setIsExportModalOpen(true) });

        deviceDetails = <Details device={device} />;
        body = (
            <>
                <div className='space-y-2'>
                    <h3 className='font-CynthoNext-SemiBold text-customCoral'>Latest data</h3>
                    <div className={style.sparkline_span}>
                        <VoltageSparkline metrics={batteryInfo} />
                        <CurrentSparkline metrics={batteryInfo} />
                        <TemperatureSparkline metrics={batteryInfo} />
                        <CapacitySparkline metrics={batteryInfo} />
                    </div>
                </div>
                <div>
                    <div className='flex flex-row text-customCoral space-x-4'>
                        <div>
                            <Dropdown title={timeRange.displayName} menuItems={timeRangeMenuItems} />
                        </div>
                        <span>|</span>
                        <div>
                            <Dropdown
                                title={selectedBloc ? `String ${selectedBloc[0]} Bloc ${selectedBloc[1]}` : 'Overall'}
                                menuItems={blocSelectionMenuItems}
                            />
                        </div>
                    </div>
                </div>
                <div className={parentStyle.chart_layout}>
                    <StatusHoursDoughnut device={id} timeRange={timeRange} />
                    <RunHoursGauge metrics={batteryInfo} />
                    <VoltageChart device={id} timeRange={timeRange} bloc={selectedBloc} />
                    <CurrentChart device={id} timeRange={timeRange} bloc={selectedBloc} />
                    <CapacityChart device={id} timeRange={timeRange} />
                    <EnergyChart device={id} timeRange={timeRange} bloc={selectedBloc} />
                    <TemperatureChart device={id} timeRange={timeRange} bloc={selectedBloc} />
                </div>
                <div>
                    <div className={style.device_test_title}>Battery Health</div>
                    <DeviceBatteryHealth device={id} />
                </div>
                {hasTasksRead && (
                    <div>
                        <div className={style.device_test_title}>Battery Tests</div>
                        <DeviceBatteryTests device={id} />
                    </div>
                )}
                <div>
                    <span className={style.em}>Last Collected: </span>
                    <LastCollectedTime device={props?.device ?? null} />
                </div>
                <Modal
                    bgColor='bg-white'
                    open={isExportModalOpen}
                    onHide={() => setIsExportModalOpen(false)}
                    closeButton={true}
                >
                    <ExportForm
                        deviceIds={[id]}
                        basicMetrics={['BatteryVoltage', 'BatteryCurrent', 'BatteryTemperature', 'BatteryPower']}
                    />
                </Modal>
            </>
        );
    } else {
        deviceDetails = (
            <AlertBanner
                title='No batteries'
                message={
                    <>
                        There are no batteries configured for this device.
                        {hasAssetsWrite && (
                            <>
                                <br />
                                To add batteries, edit the device.
                            </>
                        )}
                    </>
                }
                action={
                    hasAssetsRead
                        ? {
                              label: `${hasAssetsWrite ? 'Edit' : 'View'} Device`,
                              to: generatePath(Paths.EditDevice, { id }),
                          }
                        : undefined
                }
            />
        );
    }

    menuItems.push({
        name: 'View current state',
        onClick: () =>
            navigate({
                pathname: Paths.SiteViewViewSiteDevicePage,
                params: { siteId: props?.device?.site.id ?? 'unknown', deviceId: id, page: SiteViewPagePath.Batteries },
            }),
    });

    return (
        <div className='space-y-12'>
            <div className='space-y-2'>
                <div className='flex flex-row justify-between'>
                    <h2 className='font-CynthoNext-Bold text-3xl text-customEggplant' id={id}>
                        Device: {name}
                    </h2>
                    {menuItems.length >= 1 ? <Menu id={`device-${id}-menu`} menuItems={menuItems} /> : <></>}
                </div>
                {deviceDetails}
            </div>
            {body}
        </div>
    );
};
