import React, { useMemo, useState } from 'react';

import { ChartFrame, Doughnut, DoughnutDataType } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { batteryStatusToString } from 'lib/conversion/battery-status';
import { useQuery } from 'lib/query-helpers';
import { createMenuAction } from 'views/tasks/battery-health/components/MetricsLine';

import { useTimeRange } from '../../../../common';
import { CommonPropsWithTime } from '../common';
import {
    StatusHoursDoughnutQuery,
    StatusHoursDoughnutQuery$variables,
} from './__generated__/StatusHoursDoughnutQuery.graphql';
import style from './style.module.css';

export function StatusHoursDoughnut({ device, timeRange: defaultTimeRange }: CommonPropsWithTime): JSX.Element {
    const [timeRange, menuItems] = useTimeRange(defaultTimeRange);
    const [isZoomPaneOpen, setIsZoomPaneOpen] = useState(false);

    const queryArgs = useMemo<StatusHoursDoughnutQuery$variables>(() => {
        const range = timeRange.getRange();
        return {
            id: device,
            begin: range[0].toISO(),
            end: range[1].toISO(),
        };
    }, [device, timeRange]);

    const { data: result } = useQuery<StatusHoursDoughnutQuery>(
        graphql`
            query StatusHoursDoughnutQuery($id: ID!, $begin: Timestamp!, $end: Timestamp!) {
                device(id: $id) {
                    battery {
                        metrics {
                            statusBreakdown(begin: $begin, end: $end) {
                                status
                                time
                            }
                        }
                    }
                }
            }
        `,
        queryArgs
    );

    let body: React.ReactNode;

    if (!result?.device?.battery.metrics.statusBreakdown) {
        body = null;
    } else {
        let data: DoughnutDataType[] = result.device?.battery.metrics.statusBreakdown.map(point => ({
            name: batteryStatusToString(point.status),
            value: point.time ?? 0,
        }));
        data = data.filter(point => point.value > 0);

        body = <Doughnut data={data} valueFormatter={formatTooltipValue} />;
    }

    const zoomAction = createMenuAction({
        menuItems,
        menuSelected: timeRange.id,
        isOpen: isZoomPaneOpen,
        setIsOpen: setIsZoomPaneOpen,
        menuPlaceholder: 'Zoom',
    });

    return (
        <ChartFrame title={'Battery status hours'} actions={[zoomAction]}>
            <div className={style.doughnut2}>{body}</div>
        </ChartFrame>
    );
}
function formatTooltipValue(value: number | null): string {
    if (value === null) {
        return '';
    }

    return `${Math.round(value * 10) / 10} hours`;
}
