import React, { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useFragment, useRelayEnvironment } from 'react-relay';

import { Button, Menu, Modal, useToast } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { NameValuePair } from 'components';
import { NameValuePairClassesProps } from 'components/NameValuePair';
import { useUserPermissions } from 'lib/auth';
import { Paths } from 'lib/routes';
import { DateTime, Duration } from 'luxon';

import { TaskProgressBar } from '../../components/TaskProgressBar';
import { Exporter } from '../lib/task-exporter';
import { TaskOverview_task$key } from './__generated__/TaskOverview_task.graphql';
import { AbortTestModal } from './components/AbortTestModal';
import { CancelTaskModal } from './components/CancelTaskModal';
import { OverviewFields } from './components/OverviewFields';
import { RepeatTaskModal } from './components/RepeatTaskModal';

export interface TaskOverviewProps {
    task: TaskOverview_task$key;
}

const nameValueClasses: NameValuePairClassesProps = {
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, minmax(0, 1fr))',
        lineHeight: '1.5rem',
    },
    value: {
        gridColumnStart: 5,
    },
};

export const TaskOverview: FC<TaskOverviewProps> = ({ task }) => {
    const { hasTasksWrite } = useUserPermissions();
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showAbortModal, setShowAbortModal] = useState(false);
    const [showRepeatModal, setShowRepeatModal] = useState(false);
    const environment = useRelayEnvironment();
    const { show } = useToast();

    const exporter = useRef<Exporter | null>(null);

    const result = useFragment<TaskOverview_task$key>(
        graphql`
            fragment TaskOverview_task on BatteryTest {
                id
                name
                overallState
                estimatedEndTime
                schedule {
                    repeat
                }
                settings {
                    ... on BatteryTestTypeCapacity {
                        targets {
                            endOfDischargeVoltage
                            batteryType {
                                id
                                manufacturer
                                model
                            }
                        }
                    }
                    ... on BatteryTestTypeQuick {
                        reserveTime
                        threshold
                    }
                    ... on BatteryTestTypeCustom {
                        endOfDischargeVoltage
                        maxDuration
                        maxDischarged
                    }
                }
                testState
                totalDevice: devices {
                    total
                    data {
                        id
                    }
                }
                ...OverviewFields_task
                ...TaskProgressBar_task
            }
        `,
        task
    );

    useEffect(() => {
        return () => {
            if (exporter.current) {
                exporter.current.cancel();
            }
        };
    }, []);

    const testId = result?.id;
    const deviceIds = result ? result.totalDevice.data.map(({ id }) => id) : [];

    let estimatedEndTime: ReactNode;
    if (result && result.estimatedEndTime && result.overallState === 'InProgress') {
        const minutesToEnd = DateTime.fromISO(result.estimatedEndTime as string)
            .diff(DateTime.now(), 'minutes')
            .as('minutes');
        const durationToEnd = Duration.fromObject({ minutes: minutesToEnd });

        estimatedEndTime = (
            <NameValuePair
                name='Test Complete In'
                value={`${durationToEnd.hours}.${durationToEnd.minutes}`}
                classes={nameValueClasses}
            />
        );
    }

    let name: string;
    if (result) {
        name = result.name ?? 'Unnamed test';
    } else {
        name = 'Loading...';
    }

    const doExport = useCallback(() => {
        if (!testId) {
            return;
        }

        if (result.overallState !== 'Completed') {
            return;
        }

        if (exporter.current?.running) {
            return;
        }

        exporter.current = new Exporter(testId, show, environment);
        exporter.current.begin();
    }, [environment, result.overallState, show, testId]);

    return (
        <>
            <div className='flex flex-row justify-between'>
                <div className='text-customCoral font-CynthoNext-SemiBold text-3xl'>{name}</div>
                {estimatedEndTime}
                <Menu
                    id='task-menu'
                    menuItems={[
                        {
                            name: 'Export',
                            onClick: doExport,
                            disabled: result?.overallState !== 'Completed',
                        },
                    ]}
                />
            </div>
            <TaskProgressBar task={result} isCancelledTask={result?.overallState === 'Cancelled'} />
            <OverviewFields task={result} />
            <div className='flex flex-row-reverse justify-between'>
                <Button buttonText='Return to Tasks' variant='primary' size='medium' to={Paths.TestsOverview} />
                {hasTasksWrite && result?.testState === 'InProgress' && (
                    <Button
                        buttonText='Abort test'
                        variant='gray'
                        size='medium'
                        onClick={() => setShowAbortModal(true)}
                    />
                )}
                {hasTasksWrite && result?.testState === 'Scheduled' && (
                    <Button
                        buttonText='Cancel test'
                        variant='gray'
                        size='medium'
                        onClick={() => setShowCancelModal(true)}
                    />
                )}
                {hasTasksWrite && result?.overallState === 'Completed' && (
                    <Button
                        buttonText='Repeat Task'
                        variant='gray'
                        size='medium'
                        onClick={() => setShowRepeatModal(true)}
                    />
                )}
                {hasTasksWrite && result?.overallState === 'Cancelled' && (
                    <Button
                        buttonText='Reschedule'
                        variant='gray'
                        size='medium'
                        to={`${Paths.TestsScheduleTest}?copy-task=${encodeURIComponent(testId ?? '')}`}
                    />
                )}
            </div>
            {result?.testState === 'InProgress' && (
                <Modal className='bg-white text-center' open={showAbortModal} closeButton={false}>
                    <AbortTestModal testId={testId} testName={name} setShowAbortModal={setShowAbortModal} />
                </Modal>
            )}
            {result?.testState === 'Scheduled' && (
                <Modal className='bg-white text-center' open={showCancelModal} closeButton={false}>
                    <CancelTaskModal
                        testId={testId}
                        taskName={name}
                        recurringTest={result.schedule?.repeat === 'Never' ? false : true}
                        setShowCancelModal={setShowCancelModal}
                    />
                </Modal>
            )}

            {result?.overallState === 'Completed' && testId && (
                <Modal className='text-center' bgColor='bg-white' open={showRepeatModal} closeButton={false}>
                    <RepeatTaskModal devices={deviceIds} taskId={testId} onSetVisible={setShowRepeatModal} />
                </Modal>
            )}
        </>
    );
};
