import React, { FC } from 'react';

import { MultipleSelect } from 'components/MultipleSelect';
import { formatAlertDomain } from 'lib/textFormatters';

import { CommonFilterProps } from '../../common/types';
import { AlertDomain, AlertDomainFilter } from '../types';

export type AlertDomainFilterUIProps = CommonFilterProps<AlertDomainFilter[]>;

export const AlertDomainFilterUI: FC<AlertDomainFilterUIProps> = ({ current, onClearOrBack, onClose, onSubmit }) => {
    const options: AlertDomainFilter[] = [
        { id: AlertDomain.AcPower, value: formatAlertDomain(AlertDomain.AcPower) },
        { id: AlertDomain.Battery, value: formatAlertDomain(AlertDomain.Battery) },
        { id: AlertDomain.Load, value: formatAlertDomain(AlertDomain.Load) },
        { id: AlertDomain.Rectifier, value: formatAlertDomain(AlertDomain.Rectifier) },
    ];

    return (
        <MultipleSelect
            options={options}
            active={current}
            title='Filter by domain'
            renderItem={item => item.value}
            renderItemSimple={item => item.value}
            areItemsEqual={(itemA, itemB) => itemA.value === itemB.value}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onClose={onClose}
        />
    );
};
