import React, { FC } from 'react';

import graphql from 'babel-plugin-relay/macro';
import { useTimeRange, useTimeRangeQuery } from 'views/reports/common';

import { CommonPropsWithTime } from '../common';
import { ReportLineChart } from './ReportLineChart';
import { EnergyChartQuery } from './__generated__/EnergyChartQuery.graphql';

export const EnergyChart: FC<CommonPropsWithTime> = ({ device, timeRange: defaultTimeRange }) => {
    const [timeRange, menuItems] = useTimeRange(defaultTimeRange);

    const { data: result, error } = useTimeRangeQuery<EnergyChartQuery>(
        graphql`
            query EnergyChartQuery($id: ID!, $begin: Timestamp!, $end: Timestamp!, $interval: Duration!) {
                device(id: $id) {
                    type {
                        features {
                            battery {
                                energy
                            }
                        }
                    }
                    battery {
                        data: metrics {
                            energy(unit: WattHour, begin: $begin, end: $end, interval: $interval) {
                                ...ReportLineChart_data
                            }
                        }
                    }
                }
            }
        `,
        timeRange,
        { id: device }
    );

    const isDataSupported = result?.device?.type.features.battery.energy ?? true;

    return (
        <ReportLineChart
            title='Energy use'
            unit='Wh'
            data={result?.device?.battery.data.energy ?? null}
            error={!!error}
            timeRange={timeRange}
            menuItems={menuItems}
            allowNegative
            disabled={!isDataSupported}
        />
    );
};
