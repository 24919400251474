/**
 * @generated SignedSource<<e1b015b122533ce7a4ba3d072e0b63a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BatteryTestState = "Aborted" | "Analyzing" | "Failed" | "Finalizing" | "InProgress" | "Inconclusive" | "Passed" | "Scheduled" | "SmartStart" | "Waiting" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TestResultDisplay_test$data = {
  readonly abortedTime: string | null;
  readonly commencedTime: string | null;
  readonly completedTime: string | null;
  readonly device: {
    readonly battery: {
      readonly metrics: {
        readonly " $fragmentSpreads": FragmentRefs<"MetricsViewLive_metrics">;
      };
    };
    readonly id: string;
  };
  readonly id: string;
  readonly state: BatteryTestState;
  readonly task: {
    readonly name: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"BlocDataDisplay_test" | "BlocHealthTable_test" | "DataDisplay_test" | "MetricsView_test" | "TestParameterView_test">;
  readonly " $fragmentType": "TestResultDisplay_test";
};
export type TestResultDisplay_test$key = {
  readonly " $data"?: TestResultDisplay_test$data;
  readonly " $fragmentSpreads": FragmentRefs<"TestResultDisplay_test">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TestResultDisplay_test",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "BatteryTest",
      "kind": "LinkedField",
      "name": "task",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commencedTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completedTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "abortedTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Device",
      "kind": "LinkedField",
      "name": "device",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "DeviceBattery",
          "kind": "LinkedField",
          "name": "battery",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DeviceBatteryMetrics",
              "kind": "LinkedField",
              "name": "metrics",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "MetricsViewLive_metrics"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TestParameterView_test"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MetricsView_test"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DataDisplay_test"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BlocDataDisplay_test"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BlocHealthTable_test"
    }
  ],
  "type": "DeviceBatteryTestResults",
  "abstractKey": null
};
})();

(node as any).hash = "1b1c814fdb8618503a5e910b4d7f722d";

export default node;
