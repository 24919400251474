export class SuspenseObject<T> {
    private value: T | undefined;
    private error: unknown | undefined;
    private status: 'loading' | 'success' | 'error';

    private promise: Promise<T>;

    constructor(promise: Promise<T>) {
        this.promise = promise;
        this.status = 'loading';

        promise
            .then(value => {
                this.value = value;
                this.status = 'success';
            })
            .catch(error => {
                this.error = error;
                this.status = 'error';
            });
    }

    read(): T {
        switch (this.status) {
            case 'loading':
                throw this.promise;
            case 'error':
                // eslint-disable-next-line no-throw-literal
                throw this.error!;
            case 'success':
                return this.value!;
        }
    }
}
