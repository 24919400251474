import { useCallback, useRef } from 'react';
import { useMutation, useRelayEnvironment } from 'react-relay';

import { useToast } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { Exporter } from 'views/reports/batteries/site-view/device-section/metrics-exporter';

import { ViewState } from '../types';
import { exportSubmitMutation } from './__generated__/exportSubmitMutation.graphql';

export function useExportMetrics(state: ViewState) {
    const { show } = useToast();
    const exportWatcher = useRef<Exporter | null>(null);
    const [commit, isInFlight] = useMutation<exportSubmitMutation>(SubmitMutation);
    const environment = useRelayEnvironment();

    const submit = useCallback(() => {
        const metrics = state.selectedMetrics.map(metric => metric.metric);
        const range = state.timeRange.range;

        commit({
            variables: {
                deviceIds: state.selectedDevices,
                metrics,
                range: {
                    min: range[0].toISOString(),
                    max: range[1].toISOString(),
                },
            },
            onCompleted: ({ exportMetrics }) => {
                if (!exportMetrics?.id) {
                    show({
                        text: 'Failed to export metrics',
                        variant: 'error',
                    });
                    return;
                }

                exportWatcher.current = new Exporter(exportMetrics.id, show, environment);
                exportWatcher.current.begin();
            },
            onError: () => {
                show({
                    text: 'Failed to export metrics',
                    variant: 'error',
                });
            },
        });
    }, [commit, environment, show, state.selectedDevices, state.selectedMetrics, state.timeRange.range]);

    return [submit, isInFlight] as const;
}

const SubmitMutation = graphql`
    mutation exportSubmitMutation($deviceIds: [String!], $metrics: [String!]!, $range: DateRangeFilter) {
        exportMetrics(deviceIds: $deviceIds, metrics: $metrics, range: $range, singleFile: true) {
            ... on ExportJob {
                id
            }
            ... on ExportJobProblemResponse {
                problems
            }
        }
    }
`;
