import React, { Dispatch, FC, useCallback, useEffect, useState } from 'react';
import { commitMutation, useFragment, useRelayEnvironment } from 'react-relay';

import { CrossIcon, MinusIcon, StopIcon, useToast } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { BatteryTestFilterAction, BatteryTestFilterState, BatteryTestTableColumn } from 'filters/battery-test';
import { BaseColumns, BatteryTestColumnFilterMap } from 'filters/battery-test/settings';
import { TableAction, TableActionType, TableState } from 'layouts';
import { CoreTableLayout } from 'layouts/TableLayout/CoreTableLayout';
import { logError } from 'lib/log';
import { Paths } from 'lib/routes';
import { renderTableStatusCell } from 'lib/table-columns';

import { useDeviceSelection } from '../../schedule/device-selection';
import { StatusListAbortTestsMutation } from './__generated__/StatusListAbortTestsMutation.graphql';
import { StatusListCancelTestsMutation } from './__generated__/StatusListCancelTestsMutation.graphql';
import { StatusList_task$data, StatusList_task$key } from './__generated__/StatusList_task.graphql';

interface StatusCellProps {
    test: StatusCellValue;
}

export const StatusCell: FC<StatusCellProps> = ({ test }) => {
    if (test === null) {
        return renderTableStatusCell('Cancelled');
    }

    return renderTableStatusCell(test.state, test);
};

type CellData = StatusList_task$data['devices']['data'];
export type StatusCellValue = CellData[0]['test'];
export type Device = StatusList_task$data['devices']['data'][number];

export interface StatusListProps {
    task: StatusList_task$key;
    hasError?: boolean;
    retry: () => void;
    isFetching?: boolean;
    filters: BatteryTestFilterState;
    dispatchFilters: Dispatch<BatteryTestFilterAction>;
    tableState: TableState<BatteryTestTableColumn>;
    dispatchTableState: Dispatch<TableAction<BatteryTestTableColumn>>;
}

export const StatusList: FC<StatusListProps> = ({
    task,
    hasError,
    retry,
    isFetching,
    filters,
    dispatchFilters,
    tableState,
    dispatchTableState,
}) => {
    const environment = useRelayEnvironment();
    const { show } = useToast();

    const results = useFragment(StatusListFragment, task);
    const [isSelectionEnabled, setIsSelectionEnabled] = useState(false);
    const {
        selectedDevices,
        setDeviceSelected,
        setDeviceUnselected,
        setSelectedDevices,
        setTotalDeviceCount,
    } = useDeviceSelection();

    const overallState = results?.overallState;
    const isScheduled = overallState === 'Scheduled';
    const isInProgress = overallState === 'InProgress';
    const canAbortOrCancel = isScheduled || isInProgress;

    const devices = results?.devices.data;
    const pageInfo = {
        hasNext: results?.devices.pageInfo.hasNext ?? false,
        hasPrevious: results?.devices.pageInfo.hasPrevious ?? false,
        page: results?.devices.pageInfo.page ?? 1,
        total: results?.devices.pageInfo.total ?? 1,
    };

    const tableAdditionalActions = [];
    if (canAbortOrCancel) {
        const actionText = isScheduled ? 'Cancel' : 'Abort';
        const actionButton = isScheduled ? <MinusIcon /> : <StopIcon />;
        tableAdditionalActions.push({
            buttonText: isSelectionEnabled ? 'Back' : actionText,
            buttonIcon: isSelectionEnabled ? <CrossIcon /> : actionButton,
            onClick: () => {
                dispatchTableState({ type: TableActionType.SetSelection, selection: [] });
                setIsSelectionEnabled(prev => !prev);
            },
        });
    }

    const cancelTests = (testIds: string[]) => {
        commitMutation<StatusListCancelTestsMutation>(environment, {
            mutation: CancelTestsMutation,
            variables: { ids: testIds },
            onError: err => {
                logError('Unable to cancel selected tests', err);
                captureException(err, scope => {
                    scope.setExtra('Tests', testIds.toString());
                    scope.setTag('Function', 'cancelTests');
                    return scope;
                });
                show({ text: 'Unable to cancel selected tests', variant: 'error' });
            },
            onCompleted: (response, err) => {
                if (response.cancelBatteryTestTests === true) {
                    show({ text: `${testIds.length} tests cancelled` });
                    setIsSelectionEnabled(false);
                    retry();
                }

                if (response.cancelBatteryTestTests === false || err) {
                    show({ text: 'Unable to cancel selected tests', variant: 'error' });
                }
            },
        });
    };

    const abortTests = (testIds: string[]) => {
        commitMutation<StatusListAbortTestsMutation>(environment, {
            mutation: AbortTestsMutation,
            variables: { ids: testIds },
            onError: err => {
                logError('Unable to abort selected tests', err);
                captureException(err, scope => {
                    scope.setExtra('Tests', testIds.toString());
                    scope.setTag('Function', 'abortTests');
                    return scope;
                });
                show({ text: 'Unable to abort selected tests', variant: 'error' });
            },
            onCompleted: (response, err) => {
                if (response.abortBatteryTestTests === true) {
                    show({ text: `${testIds.length} tests aborted` });
                    setIsSelectionEnabled(false);
                    retry();
                }

                if (response.abortBatteryTestTests === false || err) {
                    show({ text: 'Unable to abort selected tests', variant: 'error' });
                }
            },
        });
    };

    const onSelectNone = useCallback(() => {
        setSelectedDevices([]);
        setTotalDeviceCount(0);
    }, [setSelectedDevices, setTotalDeviceCount]);

    const onUpdateSelection = useCallback(
        (deviceIds: string[]) => {
            // Newly selected
            for (const deviceId of deviceIds) {
                if (selectedDevices.has(deviceId)) {
                    continue;
                }

                // New selection
                const device = (devices ?? []).find(device => device.id === deviceId);
                if (device) {
                    setDeviceSelected(deviceId, { companion: device.dualPlaneCompanion?.device?.id });
                }
            }

            // Cleared selections
            const newSelection = new Set(deviceIds);
            selectedDevices.forEach(deviceId => {
                if (newSelection.has(deviceId)) {
                    return;
                }

                // Cleared selected
                setDeviceUnselected(deviceId);
            });
            dispatchTableState({ type: TableActionType.SetSelection, selection: Array.from(selectedDevices.keys()) });
        },
        [devices, dispatchTableState, selectedDevices, setDeviceSelected, setDeviceUnselected]
    );

    useEffect(() => {
        dispatchTableState({ type: TableActionType.SetSelection, selection: Array.from(selectedDevices.keys()) });
    }, [dispatchTableState, selectedDevices]);

    const getAllDevicesPromise = () => {
        const allDevices = devices.map(device => device.id);
        return allDevices;
    };

    return (
        <>
            <div className='font-CynthoNext-SemiBold text-customEggplant text-3xl pb-8'>Test Status</div>
            <CoreTableLayout<BatteryTestTableColumn, Device, Device, BatteryTestColumnFilterMap>
                columns={BaseColumns}
                data={devices}
                getRowId={row => row.id}
                page={pageInfo.page}
                pageCount={pageInfo.total}
                filterState={filters}
                dispatchFilterState={dispatchFilters}
                tableState={tableState}
                dispatchTableState={dispatchTableState}
                emptyMessage='There are no devices present'
                hasError={hasError}
                clickBehaviour={overallState !== 'Cancelled' ? 'navigate' : 'none'}
                isProcessing={isFetching}
                tableVariant='white'
                getItemLink={
                    overallState !== 'Cancelled'
                        ? (row: Device) => `${Paths.TestsDetailsView}/${results.id}/${row.id}`
                        : undefined
                }
                selection={canAbortOrCancel && isSelectionEnabled}
                selectionActionMessage={`${isScheduled ? 'Cancel' : 'Abort'} selected`}
                selectionFooterSelectedItems={tableState.selectedItems}
                selectionFooterUnitOverride={'Device'}
                selectionFooterUnitPluralOverride={'Devices'}
                onSelectionAction={() => {
                    const selectedDevices = devices.filter(device => tableState.selectedItems.includes(device.id));
                    const testIds = selectedDevices.map(device => device.test!.id);
                    if (isScheduled) {
                        cancelTests(testIds);
                    } else {
                        abortTests(testIds);
                    }
                }}
                onRequestAllIds={getAllDevicesPromise}
                tableOnSelectRowCallback={onUpdateSelection}
                tableOnUnselectAllCallback={onSelectNone}
                allowFooterZeroCount
                additionalActions={tableAdditionalActions}
            />
        </>
    );
};

const AbortTestsMutation = graphql`
    mutation StatusListAbortTestsMutation($ids: [ID!]!) {
        abortBatteryTestTests(ids: $ids)
    }
`;

const CancelTestsMutation = graphql`
    mutation StatusListCancelTestsMutation($ids: [ID!]!) {
        cancelBatteryTestTests(ids: $ids)
    }
`;

const StatusListFragment = graphql`
    fragment StatusList_task on BatteryTest {
        id
        overallState
        testState
        completedTime
        abortedTime
        cancelledTime
        devices(filters: $filters, deviceFilters: $deviceFilters, orderBy: $orderBy, page: $page) {
            total
            pageInfo {
                page
                size
                total
                hasNext
                hasPrevious
            }
            data {
                id
                site {
                    name
                    address {
                        state
                    }
                }
                name
                test(id: $id) {
                    id
                    state
                    failReason
                    finalSoH
                }
                dualPlaneCompanion {
                    device {
                        id
                    }
                }
            }
        }
    }
`;
