import React, { FC } from 'react';

import { FilterDurationSelect } from 'components/FilterDurationSelect';
import { PredefinedDuration } from 'components/FilterDurationSelect/common';
import { Less1Hour, Less30Min, More1Hour } from 'components/FilterDurationSelect/presets';
import { CommonFilterProps } from 'filters/common';

import { TestResultDurationFilter } from '../types';

const FilterPresets: PredefinedDuration[] = [Less30Min, Less1Hour, More1Hour];

export type TestResultDurationFilterUIProps = CommonFilterProps<TestResultDurationFilter>;

export const TestResultDurationFilterUI: FC<TestResultDurationFilterUIProps> = props => (
    <FilterDurationSelect title='Filter by Run Time' presets={FilterPresets} {...props} />
);
