import React, { Dispatch, FC } from 'react';

import { FormSection, FormikDropdown } from '@accesstel/pcm-ui';

import { useField } from 'formik';
import { getNiceDualPlaneConfigurationType } from 'lib/textFormatters';

import manageStyle from '../../../../style.module.css';
import { FormControlAction, FormControlParameters } from '../../lib/reducer';
import { DualPlaneConfigurationType } from '../../schema';
import { CompanionSystemSelector } from './components';

export interface DualPlaneConfigurationProps {
    editingDeviceId?: string;
    formControlState: FormControlParameters;
    formControlDispatch: Dispatch<FormControlAction>;
}

const DualPlaneTypeItems: DualPlaneConfigurationType[] = [
    DualPlaneConfigurationType.Single,
    DualPlaneConfigurationType.NPlusOne,
    DualPlaneConfigurationType.TwoN,
];

export const DualPlaneConfiguration: FC<DualPlaneConfigurationProps> = ({
    editingDeviceId,
    formControlState,
    formControlDispatch,
}) => {
    const [, configurationField] = useField('companionConfiguration');

    return (
        <FormSection
            label={'Dual Plane Configuration'}
            blurb='When a device is in a dual plane configuration, details should be provided here.'
        >
            <div className={manageStyle.provisioning_content_grid}>
                <div className='col-start-1'>
                    <FormikDropdown<DualPlaneConfigurationType>
                        name='companionConfiguration'
                        placeHolder={'System Type'}
                        items={DualPlaneTypeItems}
                        renderItem={type => getNiceDualPlaneConfigurationType(type)}
                        light
                        variant='outlined'
                        testId='system-type'
                    />
                </div>

                {configurationField.value !== 'Single' && (
                    <CompanionSystemSelector
                        editingDeviceId={editingDeviceId}
                        formControlState={formControlState}
                        formControlDispatch={formControlDispatch}
                    />
                )}
            </div>
        </FormSection>
    );
};
