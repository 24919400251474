import graphql from 'babel-plugin-relay/macro';

export const createDeviceMutation = graphql`
    mutation mutations_CreateDeviceMutation($device: DeviceIn!) {
        addDevice(device: $device) {
            ... on Device {
                id
            }
            ... on DeviceProblemResponse {
                problems {
                    ... on DeviceGeneralProblem {
                        type
                        string
                    }
                    ... on DeviceProtocolProblem {
                        protocolId
                        field
                    }
                    ... on DeviceIntegrationProblem {
                        integrationProblemType: type
                        integration
                        integrationAttribute
                    }
                }
            }
        }
    }
`;

export const editDeviceMutation = graphql`
    mutation mutations_EditDeviceMutation($id: ID!, $device: DeviceUpdateIn!) {
        editDevice(id: $id, device: $device) {
            ... on Device {
                id
            }
            ... on DeviceProblemResponse {
                problems {
                    ... on DeviceGeneralProblem {
                        type
                        string
                    }
                    ... on DeviceProtocolProblem {
                        protocolId
                        field
                    }
                    ... on DeviceIntegrationProblem {
                        integrationProblemType: type
                        integration
                        integrationAttribute
                    }
                }
            }
        }
    }
`;

export const createBatteryTypeMutation = graphql`
    mutation mutations_CreateBatteryTypeMutation($type: BatteryTypeIn!) {
        addBatteryType(type: $type) {
            ... on BatteryType {
                id
            }
            ... on BatteryTypeProblemResponse {
                problems {
                    table
                    row
                    type
                }
            }
        }
    }
`;

export const editBatteryTypeMutation = graphql`
    mutation mutations_EditBatteryTypeMutation($id: ID!, $type: BatteryTypeUpdateIn!) {
        editBatteryType(id: $id, type: $type) {
            ... on BatteryType {
                id
            }
            ... on BatteryTypeProblemResponse {
                problems {
                    type
                    table
                    row
                }
            }
        }
    }
`;

export const removeCompanionDeviceMutation = graphql`
    mutation mutations_RemoveCompanionMutation($deviceId: ID!) {
        editDevice(id: $deviceId, device: { dualPlaneDevice: null, dualPlaneType: null }) {
            ... on Device {
                id
            }
            ... on DeviceProblemResponse {
                problems {
                    ... on DeviceGeneralProblem {
                        type
                        string
                    }
                    ... on DeviceProtocolProblem {
                        protocolId
                        field
                    }
                }
            }
        }
    }
`;

export const addBatteryToDeviceMutation = graphql`
    mutation mutations_AddBatteryToDeviceMutation($deviceId: ID!, $battery: DeviceBatteryUpdateIn) {
        editDevice(id: $deviceId, device: { battery: $battery }) {
            ... on Device {
                id
            }
            ... on DeviceProblemResponse {
                problems {
                    ... on DeviceGeneralProblem {
                        type
                        string
                    }
                    ... on DeviceProtocolProblem {
                        protocolId
                        field
                    }
                }
            }
        }
    }
`;
