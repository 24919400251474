import React, { FC, useEffect, useState } from 'react';
import { PreloadedQuery, fetchQuery, loadQuery, usePreloadedQuery, useRelayEnvironment } from 'react-relay';

import { captureException, captureMessage } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { getGlobalEnvironment } from 'lib/environment';

import { DevicePane } from '../../components/DevicePane';
import { WrappedDevicePlaceholder } from '../../components/WrappedDevicePlaceholder';
import { LiveDataRefreshInterval } from '../../settings';
import { DeviceOverview } from './DeviceOverview';
import { OverviewContentQuery, OverviewContentQuery$variables } from './__generated__/OverviewContentQuery.graphql';

type Device = NonNullable<
    NonNullable<NonNullable<OverviewContentQuery['response']['device']>['dualPlaneCompanion']>['device']
>;

export interface OverviewContentProps {
    queryRef: PreloadedQuery<OverviewContentQuery>;
    deviceId: string;
}

export const OverviewContent: FC<OverviewContentProps> = ({ queryRef, deviceId }) => {
    const initialData = usePreloadedQuery(OverviewQuery, queryRef);
    const environment = useRelayEnvironment();

    const [data, setData] = useState(initialData);

    // Live refresh the data
    useEffect(() => {
        const refetchInterval = setInterval(() => {
            const refetchVariables: OverviewContentQuery$variables = {
                id: deviceId,
            };

            fetchQuery<OverviewContentQuery>(environment, OverviewQuery, refetchVariables, {
                fetchPolicy: 'network-only',
            }).subscribe({
                next(value) {
                    setData(value);
                },
                error(error: unknown) {
                    captureException(error, scope => {
                        scope.setExtra('variables', refetchVariables);
                        scope.setTag('Component', 'InsightsContent');
                        return scope;
                    });
                },
            });
        }, LiveDataRefreshInterval);

        return () => clearInterval(refetchInterval);
    }, [deviceId, environment]);

    if (!data.device) {
        // This should never happen as the DeviceLayout validates devices
        captureMessage('Assertion failed: device is null in OverviewContent', scope => {
            scope.setExtra('data', initialData);
            scope.setTag('Component', 'OverviewContent');
            return scope;
        });
        return null;
    }

    let primaryDevice: Device = data.device;
    let secondaryDevice: Device | undefined | null = data.device.dualPlaneCompanion?.device;

    // Order alphabetically
    if (secondaryDevice && secondaryDevice.name < primaryDevice.name) {
        [primaryDevice, secondaryDevice] = [secondaryDevice, primaryDevice];
    }

    return (
        <div className='grid grid-cols-2 gap-4'>
            {primaryDevice && (
                <DevicePane title={primaryDevice.name} subtitle={primaryDevice.type.displayName}>
                    <DeviceOverview device={primaryDevice} deviceId={primaryDevice.id} />
                </DevicePane>
            )}
            {(secondaryDevice && (
                <DevicePane title={secondaryDevice.name} subtitle={secondaryDevice.type.displayName}>
                    <DeviceOverview device={secondaryDevice} deviceId={secondaryDevice.id} />
                </DevicePane>
            )) || <WrappedDevicePlaceholder deviceId={primaryDevice.id} />}
        </div>
    );
};

export const OverviewQuery = graphql`
    query OverviewContentQuery($id: ID!) {
        device(id: $id) {
            id
            name
            type {
                displayName
            }
            ...DeviceOverview_device

            dualPlaneCompanion {
                device {
                    id
                    name
                    type {
                        displayName
                    }
                    ...DeviceOverview_device
                }
            }
        }
    }
`;

export function loadOverviewPageData(id: string) {
    return loadQuery(
        getGlobalEnvironment(),
        OverviewQuery,
        {
            id,
        },
        {
            fetchPolicy: 'store-and-network',
        }
    );
}
