import React, { FC, useMemo } from 'react';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { SingleSelectionList } from 'components/FilterSingleSelect/SingleSelectionList';

import { SelectDevicesPaneContentQuery } from './__generated__/SelectDevicesPaneContentQuery.graphql';

interface DeviceMenuOption {
    id: string;
    text: string;
    subText?: string;

    devices?: string[];
}

export interface SelectDevicesPaneContentProps {
    current: string[];
    onClose: () => void;
    onSubmit: (selection: string[]) => void;
    onSelectCustomDevices: () => void;
    metadataQueryRef: PreloadedQuery<SelectDevicesPaneContentQuery>;
}

export const SelectDevicesPaneContent: FC<SelectDevicesPaneContentProps> = ({
    onClose,
    current,
    onSubmit,
    onSelectCustomDevices,
    metadataQueryRef,
}) => {
    const metaData = usePreloadedQuery(QueryMetadata, metadataQueryRef);

    const items = useMemo(() => createMenuOptions(metaData.devices.data, current), [current, metaData.devices.data]);

    return (
        <>
            <SingleSelectionList<DeviceMenuOption>
                items={[
                    ...items,
                    {
                        id: 'custom',
                        text: 'Choose devices...',
                    },
                ]}
                onSelect={item => {
                    if (item.devices) {
                        const newSelection = [...current, ...item.devices];
                        const uniqueSelection = Array.from(new Set(newSelection));
                        onSubmit(uniqueSelection);
                    }

                    if (item.id === 'custom') {
                        onSelectCustomDevices();
                    }

                    onClose();
                }}
                selectedItem={undefined}
                renderItem={item => {
                    if ('subText' in item) {
                        return (
                            <span>
                                <span>{item.text}</span>
                                <span className='italic'>{` ${item.subText}`}</span>
                            </span>
                        );
                    } else {
                        return item.text;
                    }
                }}
            />
        </>
    );
};

export const QueryMetadata = graphql`
    query SelectDevicesPaneContentQuery($deviceIds: [ID!]!) {
        devices(filters: { ids: $deviceIds }) {
            data {
                id
                site {
                    id
                    devices {
                        data {
                            id
                        }
                    }
                }
                dualPlaneCompanion {
                    device {
                        id
                    }
                }
            }
        }
    }
`;

type Device = SelectDevicesPaneContentQuery['response']['devices']['data'][number];

function createMenuOptions(devices: readonly Device[], current: string[]): DeviceMenuOption[] {
    const options: DeviceMenuOption[] = [];

    // Add an option to include companion devices if there are any
    const companionDevices: string[] = [];
    for (const device of devices) {
        if (!device.dualPlaneCompanion?.device) {
            continue;
        }

        companionDevices.push(device.dualPlaneCompanion.device.id);
    }

    if (companionDevices.length > 0) {
        const additionalDevices = companionDevices.filter(device => !current.includes(device));
        if (additionalDevices.length > 0) {
            const companionOption: DeviceMenuOption = {
                id: 'companion',
                text: additionalDevices.length > 1 ? 'Include companions' : 'Include companion',
                subText: `(+ ${additionalDevices.length} ${additionalDevices.length > 1 ? 'devices' : 'device'})`,
                devices: additionalDevices,
            };

            options.push(companionOption);
        }
    }

    // Add an option to include all devices on the site
    const siteDevices: string[] = [];
    const visitedSites = new Set<string>();
    for (const device of devices) {
        if (!device.site) {
            continue;
        }

        visitedSites.add(device.site.id);

        for (const siteDevice of device.site.devices.data) {
            siteDevices.push(siteDevice.id);
        }
    }

    if (siteDevices.length > 0) {
        const uniqueSiteDevices = Array.from(new Set(siteDevices));

        const additionalDevices = uniqueSiteDevices.filter(device => !current.includes(device));

        if (additionalDevices.length > 0) {
            const siteOption: DeviceMenuOption = {
                id: 'site',
                text: visitedSites.size > 1 ? 'Include all on sites' : 'Include all on site',
                subText: `(+ ${additionalDevices.length} ${additionalDevices.length > 1 ? 'devices' : 'device'})`,
                devices: additionalDevices,
            };

            options.push(siteOption);
        }
    }

    return options;
}
