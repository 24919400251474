/**
 * @generated SignedSource<<cb29b2f83ad126f4012a5194129070ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BatteryHealthStatus = "Healthy" | "Unhealthy" | "Unknown" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BatteriesGauge$data = {
  readonly batteryMetrics: {
    readonly overallHealth: ReadonlyArray<{
      readonly count: number;
      readonly status: BatteryHealthStatus;
    }> | null;
  };
  readonly devices: {
    readonly total: number;
  };
  readonly " $fragmentType": "BatteriesGauge";
};
export type BatteriesGauge$key = {
  readonly " $data"?: BatteriesGauge$data;
  readonly " $fragmentSpreads": FragmentRefs<"BatteriesGauge">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BatteriesGauge",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PaginatedDevices",
      "kind": "LinkedField",
      "name": "devices",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GlobalBatteryMetrics",
      "kind": "LinkedField",
      "name": "batteryMetrics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GlobalBatteryHealth",
          "kind": "LinkedField",
          "name": "overallHealth",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "count",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "d7350bbe9a5e258714e5982081d9a75f";

export default node;
