import { TaskStateFilter } from './components/__generated__/TaskFilterRange_taskDistributionQuery.graphql';
import { PageTypes } from './types';

export function getTaskStateFromPageType(pageType: PageTypes): string[] {
    switch (pageType) {
        case 'cancelled':
            return ['Cancelled'];
        case 'scheduled':
            return ['Scheduled'];
        case 'completed':
        default:
            return ['Completed', 'Aborted'];
    }
}

export function getStringFilterFromPageType(pageType: PageTypes): TaskStateFilter[] {
    switch (pageType) {
        case 'cancelled':
            return [{ value: 'Cancelled' }];
        case 'scheduled':
            return [{ value: 'Scheduled' }];
        case 'completed':
            return [{ value: 'Completed' }, { value: 'Aborted' }];
    }
}
