import React, { FC } from 'react';

import {
    ArrowDownIcon,
    ArrowRightIcon,
    ArrowUpIcon,
    Button,
    CalendarIcon,
    MinusIcon,
    StatusLabel,
    Tooltip,
} from '@accesstel/pcm-ui';

import { Row } from '@tanstack/react-table';
import { AlertSeverity } from 'filters/alert';
import { getDateTimeFormat, getHumanizedTextBetweenDates } from 'lib/dateFormatter';
import { alertSeverityToStatusLabelConfig } from 'lib/statusLabelFormatter';
import { formatAlertDomain } from 'lib/textFormatters';
import { DateTime } from 'luxon';

import { Paths } from '../../../../../lib/routes';
import { SiteViewPagePath } from '../../../../sites/paths';
import { Alert } from '../AlertList';
import { AlertDomain } from '../__generated__/AlertListTableQuery.graphql';

const DomainToSiteViewPathMap: Record<AlertDomain, SiteViewPagePath> = {
    AcPower: SiteViewPagePath.AcPower,
    Battery: SiteViewPagePath.Batteries,
    Load: SiteViewPagePath.Load,
    Rectifier: SiteViewPagePath.Rectifiers,
    '%future added value': SiteViewPagePath.Overview,
};

export interface AlertListExpandedRowProps {
    row: Row<Alert>;
}

export const AlertListExpandedRow: FC<AlertListExpandedRowProps> = ({ row }) => {
    const alert = row.original;
    return (
        <div className='p-8' key={`expanded-row-${row.original.id}`} data-testid='alert-list-expanded-row'>
            <div className='flex flex-row justify-between pb-4 flex-wrap'>
                <div>
                    <div>
                        <span className='font-CynthoNext-SemiBold text-2xl' data-testid='alert-expanded-device-name'>
                            {row.original.device.name}
                        </span>
                        <span className='font-CynthoNext-Light text-sm pl-2' data-testid='alert-expanded-domain-text'>
                            {formatAlertDomain(row.original.domain)}
                        </span>
                    </div>
                    <div className='font-CynthoNext-Light' data-testid='alert-expanded-address'>
                        {row.original.site.name}, {row.original.site.address.state}
                    </div>
                </div>

                <div data-testid='alert-expanded-severity'>
                    <StatusLabel
                        label={row.original.isActive ? row.original.severity.toUpperCase() : 'RESOLVED'}
                        {...alertSeverityToStatusLabelConfig(
                            row.original.severity as AlertSeverity,
                            row.original.isActive
                        )}
                    />
                </div>
            </div>

            <div className='flex flex-row flex-wrap'>
                <Tooltip content='Raised date'>
                    <div className='flex flex-row items-center' data-testid='alert-expanded-active-date-text'>
                        <div className='h-4 w-8 flex flex-row text-customCoral mr-2'>
                            <CalendarIcon />
                            <ArrowUpIcon />
                        </div>
                        {getDateTimeFormat(row.original.raiseDate)}
                    </div>
                </Tooltip>

                {row.original.clearDate ? (
                    <div className='px-8 flex flex-row items-center'>
                        <div className='h-4 w-4 mr-2'>
                            <MinusIcon />
                        </div>
                        <div
                            className='text-sm text-customEggplantWhite'
                            data-testid='alert-expanded-cleared-duration-text'
                        >
                            {getHumanizedTextBetweenDates(row.original.raiseDate, row.original.clearDate)} later
                        </div>
                        <div className='h-4 w-4 ml-2'>
                            <ArrowRightIcon />
                        </div>
                    </div>
                ) : (
                    <div className='px-8 flex flex-row items-center text-customCoral'>
                        <div className='h-4 w-4 mr-2'>
                            <MinusIcon />
                        </div>
                        <div className='text-sm' data-testid='alert-expanded-active-duration-text'>
                            {getHumanizedTextBetweenDates(row.original.raiseDate, DateTime.now().toString())}
                        </div>
                        <div className='h-4 w-4 ml-2'>
                            <ArrowRightIcon />
                        </div>
                    </div>
                )}

                <Tooltip content={row.original.clearDate ? 'Resolved date' : null}>
                    <div className='flex flex-row items-center' data-testid='alert-expanded-cleared-date-element'>
                        {row.original.clearDate ? (
                            <>
                                <div className='h-4 w-8 flex flex-row text-customEggplantWhite mr-2'>
                                    <CalendarIcon />
                                    <ArrowDownIcon />
                                </div>
                                {getDateTimeFormat(row.original.clearDate)}
                            </>
                        ) : (
                            <div className='italic text-customCoral'>Currently active</div>
                        )}
                    </div>
                </Tooltip>
            </div>

            <div className='flex flex-row justify-between items-end gap-4'>
                <div>
                    <div className='pt-4 whitespace-normal' data-testid='alert-expanded-message-text'>
                        {row.original.message}
                    </div>

                    {getSuggestions(row.original.suggestions)}
                </div>
                <Button
                    buttonText='See current status'
                    size='small'
                    to={{
                        pathname: Paths.SiteViewViewSiteDevicePage,
                        params: {
                            siteId: alert.site.id,
                            deviceId: alert.device.id,
                            page: DomainToSiteViewPathMap[alert.domain],
                        },
                    }}
                />
            </div>
        </div>
    );
};

const getSuggestions = (suggestions: readonly string[]) => {
    if (suggestions.length === 0) {
        return <></>;
    } else {
        return (
            <>
                <div className='font-CynthoNext-Light text-sm text-customEggplant pt-4'>SUGGESTIONS:</div>
                <ul className='list-disc pl-4' data-testid='alert-expanded-suggestions-list'>
                    {suggestions.map((suggestion, idx) => (
                        <li key={`alert-suggestion-${idx}`}>{suggestion}</li>
                    ))}
                </ul>
            </>
        );
    }
};
