import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { MetricsExplore } from './MetricsExplore';
import { loadMetricsExplorePageData } from './MetricsExploreContent';

const Routes: RouteObject[] = [
    {
        path: 'explore',
        handle: {
            title: 'Explore',
        },
        children: [
            {
                index: true,
                element: <Navigate to='metrics' replace />,
            },
            {
                path: 'metrics',
                element: <MetricsExplore />,
                loader: ({ request }) => loadMetricsExplorePageData(new URL(request.url)),
            },
        ],
    },
];

export default Routes;
