import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import graphql from 'babel-plugin-relay/macro';
import { useQuery } from 'lib/query-helpers';
import { TaskOverview } from 'views/tasks/battery-health/task-view/subviews/TaskOverview';

import { OtherRefreshInterval } from '../../../settings';
import { DoneQuery } from './__generated__/DoneQuery.graphql';

interface DoneProps {
    taskID?: string;
}

export const Done: FC<DoneProps> = ({ taskID }) => {
    const navigate = useNavigate();

    const { data: props, retry } = useQuery<DoneQuery>(
        graphql`
            query DoneQuery($id: ID!) {
                task(id: $id) {
                    ... on Task {
                        overallState
                    }
                    ...TaskOverview_task
                    ...TaskProgressBar_task
                }
            }
        `,
        {
            id: taskID!,
        },
        {
            skip: !taskID,
        }
    );

    const testState = props?.task?.overallState;

    // Live data
    useEffect(() => {
        if (testState === 'Scheduled') {
            const handle = setInterval(retry, OtherRefreshInterval);
            return () => {
                clearInterval(handle);
            };
        } else if (testState === 'Completed' || testState === 'InProgress' || testState === 'Cancelled') {
            // Navigate to the proper page so we can see the data and device statuses
            navigate(`/tasks/batteryhealth/view/${taskID}`, { replace: true });
        }
    }, [retry, testState, taskID, navigate]);

    if (!props?.task) {
        return <></>;
    }

    return <TaskOverview task={props.task} />;
};
