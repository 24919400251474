import React, { FC } from 'react';

import {
    FirmwareIcon,
    NavigationItem,
    OverviewIcon,
    PlusIcon,
    ReportsIcon,
    SitesIcon,
    TasksIcon,
    TopLevelNavigationItem,
    VerticalNavigation,
} from '@accesstel/pcm-ui';

import { useUserPermissions } from 'lib/auth';
import { isFeatureEnabled } from 'lib/featureFlag';
import { Paths } from 'lib/routes';

interface ThemedPageProps {
    children: React.ReactNode;
}

export const ThemedPage: FC<ThemedPageProps> = ({ children }) => {
    const { hasAssetsRead, hasTasksRead } = useUserPermissions();

    const primaryNavigation: TopLevelNavigationItem[] = [
        {
            title: 'Overview',
            to: '/',
            icon: <OverviewIcon />,
        },
        {
            title: 'Sites',
            to: Paths.PowerControllers,
            icon: <SitesIcon />,
        },
        {
            title: 'Firmware Status',
            to: '/status/firmware',
            icon: <FirmwareIcon />,
            displayed: isFeatureEnabled('firmware'),
        },
        {
            title: 'Reports',
            to: Paths.ReportsOverview,
            icon: <ReportsIcon />,
            children: [
                {
                    title: 'Insights',
                    to: Paths.ReportAlerts,
                },
                {
                    title: 'Batteries',
                    to: Paths.ReportBatteries,
                },
            ],
        },
        {
            title: 'Tasks',
            to: Paths.Tasks,
            icon: <TasksIcon />,
            displayed: hasTasksRead,
            children: [
                {
                    title: 'Battery Health Tests',
                    to: Paths.TestsOverview,
                },
            ],
        },
    ];

    const secondaryNavigation: NavigationItem[] = [
        {
            title: 'Manage',
            to: Paths.Assets,
            icon: <PlusIcon />,
            displayed: hasAssetsRead,
        },
    ];

    return (
        <div className='themed-page'>
            <VerticalNavigation items={primaryNavigation} secondaryItems={secondaryNavigation} />
            <div className='col-start-2 col-span-1 px-16 container mx-auto my-10'>{children}</div>
        </div>
    );
};
