import React, { FC, useState } from 'react';

import { SearchBox } from '@accesstel/pcm-ui';

import { Operation, SelectedMetric } from 'views/explore/types';

import { DefinedMetric } from './types';

export interface MetricSelectionProps {
    metrics: DefinedMetric[];
    selectedMetrics: SelectedMetric[];
    onSelect: (metric: DefinedMetric) => void;
}

export const MetricSelection: FC<MetricSelectionProps> = ({ metrics, selectedMetrics, onSelect }) => {
    const [searchValue, setSearchValue] = useState<string>('');

    const searchMatch = searchValue.toLowerCase();
    const matchingMetrics = metrics.filter(metric => metric.displayName.toLowerCase().includes(searchMatch));

    const handleSearch = () => {
        if (matchingMetrics.length > 0) {
            onSelect(matchingMetrics[0]);
            setSearchValue('');
        }
    };

    return (
        <SearchBox
            id='metricsSelect'
            variant='filter'
            renderResult={metric => {
                const isAvailable = isMetricAvailable(metric, selectedMetrics);
                const isDisabled = !isAvailable;

                return (
                    <span className={isDisabled ? 'opacity-50' : ''}>
                        {metric.displayName}
                        {isDisabled && ' (Selected)'}
                    </span>
                );
            }}
            searchResults={matchingMetrics}
            value={searchValue}
            onChange={setSearchValue}
            onSubmit={handleSearch}
            onResultClick={item => {
                if (!isMetricAvailable(item, selectedMetrics)) {
                    return;
                }
                onSelect(item);
            }}
            placeHolder='Search'
            autoFocus
        />
    );
};

function isMetricAvailable(metric: DefinedMetric, selectedMetrics: SelectedMetric[]) {
    const matchingMetrics = selectedMetrics.filter(selectedMetric => selectedMetric.metric === metric.metric);

    // If there is at least one operation unused, the metric is available
    return matchingMetrics.length < Object.values(Operation).length;
}
