/**
 * @generated SignedSource<<9eea8a9b12dc6d72e131b80c16bd6a28>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeviceReport_ReportQuery$variables = {
  id: string;
};
export type DeviceReport_ReportQuery$data = {
  readonly device: {
    readonly battery: {
      readonly blocs: ReadonlyArray<{
        readonly metrics: {
          readonly bloc: number;
          readonly string: number;
        };
      }>;
      readonly metrics: {
        readonly " $fragmentSpreads": FragmentRefs<"CapacitySparkline_battery" | "CurrentSparkline_battery" | "RunHoursGauge_battery" | "TemperatureSparkline_battery" | "VoltageSparkline_battery">;
      };
    };
    readonly site: {
      readonly id: string;
    };
    readonly " $fragmentSpreads": FragmentRefs<"LastCollectedTime_battery">;
  } | null;
};
export type DeviceReport_ReportQuery = {
  response: DeviceReport_ReportQuery$data;
  variables: DeviceReport_ReportQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Site",
  "kind": "LinkedField",
  "name": "site",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "DeviceBatteryBloc",
  "kind": "LinkedField",
  "name": "blocs",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BlocMetrics",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bloc",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "string",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "TimestampMetric",
    "kind": "LinkedField",
    "name": "values",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "timestamp",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Trend",
    "kind": "LinkedField",
    "name": "trend",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "state",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v6 = [
  {
    "kind": "Literal",
    "name": "unit",
    "value": "Hours"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeviceReport_ReportQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceBattery",
            "kind": "LinkedField",
            "name": "battery",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DeviceBatteryMetrics",
                "kind": "LinkedField",
                "name": "metrics",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "VoltageSparkline_battery"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CurrentSparkline_battery"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "TemperatureSparkline_battery"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CapacitySparkline_battery"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "RunHoursGauge_battery"
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "LastCollectedTime_battery"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeviceReport_ReportQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceBattery",
            "kind": "LinkedField",
            "name": "battery",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DeviceBatteryMetrics",
                "kind": "LinkedField",
                "name": "metrics",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "latestVoltage",
                    "storageKey": null
                  },
                  {
                    "alias": "voltageSparkline",
                    "args": null,
                    "concreteType": "TimeSeriesMetrics",
                    "kind": "LinkedField",
                    "name": "voltage",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "latestCurrent",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TimeSeriesMetrics",
                    "kind": "LinkedField",
                    "name": "current",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "latestTemperature",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TimeSeriesMetrics",
                    "kind": "LinkedField",
                    "name": "temperature",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "latestRemainingCapacity",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TimeSeriesMetrics",
                    "kind": "LinkedField",
                    "name": "remainingCapacity",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v6/*: any*/),
                    "kind": "ScalarField",
                    "name": "latestTimeRemaining",
                    "storageKey": "latestTimeRemaining(unit:\"Hours\")"
                  },
                  {
                    "alias": null,
                    "args": (v6/*: any*/),
                    "kind": "ScalarField",
                    "name": "typicalMaximumTimeRemaining",
                    "storageKey": "typicalMaximumTimeRemaining(unit:\"Hours\")"
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastUpdate",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3647cb13f1345eda11910b558dd40ea1",
    "id": null,
    "metadata": {},
    "name": "DeviceReport_ReportQuery",
    "operationKind": "query",
    "text": "query DeviceReport_ReportQuery(\n  $id: ID!\n) {\n  device(id: $id) {\n    site {\n      id\n    }\n    battery {\n      metrics {\n        ...VoltageSparkline_battery\n        ...CurrentSparkline_battery\n        ...TemperatureSparkline_battery\n        ...CapacitySparkline_battery\n        ...RunHoursGauge_battery\n      }\n      blocs {\n        metrics {\n          bloc\n          string\n        }\n      }\n    }\n    ...LastCollectedTime_battery\n    id\n  }\n}\n\nfragment CapacitySparkline_battery on DeviceBatteryMetrics {\n  latestRemainingCapacity\n  remainingCapacity {\n    values {\n      timestamp\n      value\n    }\n    trend {\n      state\n    }\n  }\n}\n\nfragment CurrentSparkline_battery on DeviceBatteryMetrics {\n  latestCurrent\n  current {\n    values {\n      timestamp\n      value\n    }\n    trend {\n      state\n    }\n  }\n}\n\nfragment LastCollectedTime_battery on Device {\n  lastUpdate\n}\n\nfragment RunHoursGauge_battery on DeviceBatteryMetrics {\n  latestTimeRemaining(unit: Hours)\n  typicalMaximumTimeRemaining(unit: Hours)\n}\n\nfragment TemperatureSparkline_battery on DeviceBatteryMetrics {\n  latestTemperature\n  temperature {\n    values {\n      timestamp\n      value\n    }\n    trend {\n      state\n    }\n  }\n}\n\nfragment VoltageSparkline_battery on DeviceBatteryMetrics {\n  latestVoltage\n  voltageSparkline: voltage {\n    values {\n      timestamp\n      value\n    }\n    trend {\n      state\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6e7d49e3df1a323c02c418e96f5c35e7";

export default node;
