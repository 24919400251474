import React from 'react';
import { fetchQuery } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { AttributeFilter, AttributeFilterUI, FilterDefinition } from 'filters/common';
import { IEnvironment } from 'relay-runtime';

import { dynamic_loadSiteAttributeNamesQuery } from './__generated__/dynamic_loadSiteAttributeNamesQuery.graphql';
import { SiteTableColumn } from './types';

export async function loadDynamicFilters(environment: IEnvironment): Promise<FilterDefinition<SiteTableColumn>[]> {
    const data = await fetchQuery<dynamic_loadSiteAttributeNamesQuery>(
        environment,
        graphql`
            query dynamic_loadSiteAttributeNamesQuery {
                attributeNames(search: "", only: Site, limit: 1000, status: Active)
            }
        `,
        {}
    ).toPromise();

    return (
        data?.attributeNames.map(name => ({
            type: 'multi',
            name: `Tag ${name}`,
            category: 'Attribute',
            attributeName: name,
            component: () => props => <AttributeFilterUI {...props} attribute={name} type='Site' />,
            describeValue: (value: AttributeFilter) => value.value,
        })) ?? []
    );
}
