import React, { FC, useCallback, useMemo, useState } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';
import { generatePath, useNavigate } from 'react-router-dom';

import { ArrowDownIcon, CrossIcon, Modal } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { useDocumentTitle } from 'components';
import { SiteTableColumn, siteToFilterObject, useSiteFilter } from 'filters/site';
import { SiteColumnFilterMap } from 'filters/site/settings';
import { SortDirection, TableActionType, TableLayout, useTableReducer } from 'layouts';
import { useQuery } from 'lib/query-helpers';
import { Paths } from 'lib/routes';
import { ExportForm } from 'views/exporting/components/ExportForm';
import { SiteOrdering, SiteSortField } from 'views/manage/site/__generated__/SitesTableQuery.graphql';

import { ReportSearchResults, SearchGroups, renderSearchResult } from '../search';
import { BatterySiteListAllQuery } from './__generated__/BatterySiteListAllQuery.graphql';
import { BatterySiteListSearchQuery } from './__generated__/BatterySiteListSearchQuery.graphql';
import {
    BatterySiteListTableQuery,
    BatterySiteListTableQuery$data,
    BatterySiteListTableQuery$variables,
} from './__generated__/BatterySiteListTableQuery.graphql';
import { AllTableColumns, BaseTableColumns } from './settings';

type Site = BatterySiteListTableQuery$data['sites']['data'][number];
export type Device = Site['devices']['data'][number];

const TableStorageKeyPrefix = 'battery-by-site-table';

export const BatterySiteList: FC = () => {
    const [isExportModalOpen, setIsExportModalOpen] = useState(false);
    const [enableExport, setEnableExport] = useState(false);

    const environment = useRelayEnvironment();
    const navigate = useNavigate();

    useDocumentTitle('Reports - Batteries by site');

    const [tableState, dispatchTableState] = useTableReducer<SiteTableColumn>({
        defaultSortColumn: SiteTableColumn.Name,
        allColumns: AllTableColumns.map(column => column.id),
        defaultVisibleColumns: BaseTableColumns.map(column => column.id),
        storageKeyPrefix: TableStorageKeyPrefix,
    });

    const [filters, dispatchFilters] = useSiteFilter();
    const filterObject = useMemo(() => siteToFilterObject(filters), [filters]);

    const sortObject: SiteOrdering = {
        field: tableState.sortColumn as SiteSortField,
        dir: tableState.sortDirection === SortDirection.Ascending ? 'Asc' : 'Desc',
    };

    const variables: BatterySiteListTableQuery$variables = {
        page: tableState.page,
        search: tableState.search,
        orderBy: sortObject,
        filter: filterObject,
        withSiteType: tableState.visibleColumnsInOrder.includes(SiteTableColumn.Type),
        withSitePostcode: tableState.visibleColumnsInOrder.includes(SiteTableColumn.Postcode),
        withDeviceCount: tableState.visibleColumnsInOrder.includes(SiteTableColumn.DeviceCount),
    };

    const { data: props, error, retry, isFetching } = useQuery<BatterySiteListTableQuery>(
        graphql`
            query BatterySiteListTableQuery(
                $page: Int = 1
                $search: String!
                $orderBy: SiteOrdering
                $filter: SiteFilter
                $withSiteType: Boolean = false
                $withDeviceCount: Boolean = false
                $withSitePostcode: Boolean = false
            ) {
                sites(
                    page: $page
                    search: $search
                    onlyProvisioningStatuses: Active
                    orderBy: $orderBy
                    filters: $filter
                ) {
                    total
                    data {
                        __typename
                        id
                        name
                        type @include(if: $withSiteType)
                        address {
                            state
                            address
                            postcode @include(if: $withSitePostcode)
                        }
                        devices {
                            total @include(if: $withDeviceCount)
                            data {
                                __typename
                                id
                                name
                                battery {
                                    installed
                                    metrics {
                                        latestStatus
                                    }
                                }
                                site {
                                    id
                                }
                                lastUpdate
                            }
                        }
                        batteryMetrics {
                            commonStatus
                            multipleStatuses
                            totalCapacity(unit: AmpHour)
                            totalTimeRemaining(unit: Hours)
                        }
                    }
                    pageInfo {
                        page
                        size
                        total
                        hasNext
                        hasPrevious
                    }
                }
                overallSites: sites {
                    total
                }
            }
        `,
        variables,
        {
            fetchPolicy: 'network-only',
        }
    );

    const handleRequestAllDeviceIds = useCallback(() => {
        return fetchQuery<BatterySiteListAllQuery>(
            environment,
            graphql`
                query BatterySiteListAllQuery($filters: SiteFilter) {
                    sites(
                        onlyProvisioningStatuses: Active
                        onlyWithValidBatteries: true
                        pageSize: 10000
                        filters: $filters
                    ) {
                        data {
                            devices {
                                data {
                                    id
                                }
                            }
                        }
                    }
                }
            `,
            { filters: filterObject },
            { fetchPolicy: 'network-only' }
        )
            .toPromise()
            .then(data =>
                (data?.sites.data ?? [])
                    .map(site => site.devices)
                    .flatMap(devices => devices.data.map(device => device.id))
            );
    }, [environment, filterObject]);

    const handleSearch = useCallback(
        (input: string) => {
            return fetchQuery<BatterySiteListSearchQuery>(
                environment,
                graphql`
                    query BatterySiteListSearchQuery($search: String!, $pageSize: Int!) {
                        sites(
                            search: $search
                            pageSize: $pageSize
                            orderBy: { field: Name, dir: Asc }
                            onlyProvisioningStatuses: Active
                        ) {
                            data {
                                id
                                name
                                address {
                                    state
                                }
                            }
                        }
                        devices(search: $search, pageSize: $pageSize, orderBy: { field: Name, dir: Asc }) {
                            data {
                                id
                                name
                                site {
                                    id
                                    name
                                    address {
                                        state
                                    }
                                }
                            }
                        }
                    }
                `,
                { search: input, pageSize: 10 }
            )
                .toPromise()
                .then(results => {
                    const deviceResults: ReportSearchResults[] =
                        results?.devices.data.map(result => ({
                            id: result.id,
                            name: result.name,
                            site: result.site.id,
                            siteName: result.site.name,
                            state: result.site.address.state,
                            type: 'device',
                        })) ?? [];

                    const siteResults: ReportSearchResults[] =
                        results?.sites.data.map(result => ({
                            id: result.id,
                            name: result.name,
                            site: result.id,
                            siteName: result.name,
                            state: result.address.state,
                            type: 'site',
                        })) ?? [];

                    return deviceResults.concat(siteResults);
                });
        },
        [environment]
    );

    const handleSearchResultClick = useCallback(
        (item: ReportSearchResults) => {
            let url = `/reports/batteries/sites/${encodeURIComponent(item.site)}`;
            if (item.type === 'device') {
                url = `${url}#${encodeURIComponent(item.id)}`;
            }

            navigate(url);
        },
        [navigate]
    );

    return (
        <>
            <TableLayout<SiteTableColumn, Site, Device, ReportSearchResults, SiteColumnFilterMap>
                title='Batteries by site'
                showRefreshed
                columns={AllTableColumns}
                allowEditingColumns
                filterState={filters}
                dispatchFilterState={dispatchFilters}
                tableState={tableState}
                dispatchTableState={dispatchTableState}
                data={props?.sites.data ?? null}
                isProcessing={!!props && isFetching}
                getRowId={(row: Site | Device) => row.id}
                getSubRows={row => [...(row?.devices?.data ?? [])]}
                page={props?.sites.pageInfo.page}
                pageCount={props?.sites.pageInfo.total}
                overallCount={props?.overallSites.total}
                resultCount={props?.sites.total}
                hasError={!!error}
                onRetry={retry}
                searchPlaceholder='Search by Site/Device'
                onSearch={handleSearch}
                onSearchResultClick={handleSearchResultClick}
                renderSearchResult={renderSearchResult}
                renderSearchResultAsString={(item: ReportSearchResults) => item.name}
                emptyMessage='There are no matching sites'
                unit='Site'
                selection={enableExport}
                onSelectionAction={() => setIsExportModalOpen(true)}
                onRequestAllIds={handleRequestAllDeviceIds}
                selectionActionMessage={'Export selected'}
                selectionFooterSelectedItems={tableState.selectedItems}
                selectionFooterUnitOverride={'Device'}
                selectionFooterUnitPluralOverride={'Devices'}
                emptySubRowsMessage='There are no devices on this site'
                additionalActions={[
                    {
                        buttonText: enableExport ? 'Back' : 'Export',
                        buttonIcon: enableExport ? <CrossIcon /> : <ArrowDownIcon />,
                        onClick: () => {
                            dispatchTableState({
                                type: TableActionType.SetSelection,
                                selection: [],
                            });
                            setEnableExport(prev => !prev);
                        },
                    },
                ]}
                getItemLink={row => {
                    if (!('site' in row)) {
                        return '';
                    }

                    return generatePath(Paths.ReportBatteriesViewSiteDevice, { siteId: row.site.id, deviceId: row.id });
                }}
                allowFooterZeroCount
                searchBoxProperties={{
                    groups: SearchGroups,
                    groupKey: 'type',
                }}
            />
            <Modal
                bgColor='bg-white'
                open={isExportModalOpen}
                onHide={() => setIsExportModalOpen(false)}
                closeButton={true}
            >
                <ExportForm
                    deviceIds={tableState.selectedItems}
                    basicMetrics={['BatteryVoltage', 'BatteryCurrent', 'BatteryTemperature', 'BatteryPower']}
                />
            </Modal>
        </>
    );
};
