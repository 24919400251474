import { createColumnHelper } from '@tanstack/react-table';
import { BatteryTypeTableColumn } from 'filters/battery-type';
import { ColumnWithId } from 'layouts';
import { renderUserConfigCell } from 'lib/table-columns';
import { formatBatteryType } from 'lib/textFormatters';

import type { BatteryTypesTableQuery$data } from './__generated__/BatteryTypesTableQuery.graphql';

type BatteryType = BatteryTypesTableQuery$data['batteryTypes']['data'][number];

const columnHelper = createColumnHelper<BatteryType>();

export const ManufacturerColumn = columnHelper.accessor('manufacturer', {
    id: BatteryTypeTableColumn.Manufacturer,
    header: 'MANUFACTURER',
    meta: {
        filterable: true,
        sortable: true,
        maxWidth: '20rem',
    },
});
export const ModelColumn = columnHelper.accessor('model', {
    id: BatteryTypeTableColumn.Model,
    header: 'MODEL',
    meta: {
        filterable: true,
        sortable: true,
        maxWidth: '15rem',
    },
});
export const TechnologyColumn = columnHelper.accessor('technology', {
    id: BatteryTypeTableColumn.Technology,
    header: 'TECHNOLOGY',
    cell: ({ cell }) => formatBatteryType(cell.getValue()),
    meta: {
        filterable: true,
        sortable: true,
    },
});
export const CapacityColumn = columnHelper.accessor('cells.capacity', {
    id: BatteryTypeTableColumn.Capacity,
    header: 'CAPACITY',
    cell: ({ cell }) => `${cell.getValue()} Ah`,
    meta: {
        filterable: true,
        sortable: true,
    },
});

export const CapacityRatingColumn = columnHelper.accessor('cells.capacityRating', {
    id: BatteryTypeTableColumn.CellCapacityRating,
    header: 'CAPACITY RATING',
    meta: {
        filterable: true,
    },
});

export const ReferenceTemperatureColumn = columnHelper.accessor('cells.referenceTemperature', {
    id: BatteryTypeTableColumn.CellReferenceTemperature,
    header: 'REFERENCE TEMPERATURE',
    meta: {
        filterable: true,
    },
});

export const CellsPerBlocColumn = columnHelper.accessor('cellsPerBloc', {
    id: BatteryTypeTableColumn.CellsPerBloc,
    header: 'CELLS PER BLOC',
    cell: ({ cell }) => renderUserConfigCell(() => cell.getValue()),
    meta: {
        filterable: true,
    },
});

export const PeukertsConstantColumn = columnHelper.accessor('peukertsConstant', {
    id: BatteryTypeTableColumn.PeukertsConstant,
    header: 'PEUKERTS CONSTANT',
    cell: ({ cell }) => renderUserConfigCell(() => cell.getValue()),
    meta: {
        filterable: true,
    },
});

export const BaseTableColumns = [ManufacturerColumn, ModelColumn, TechnologyColumn, CapacityColumn] as ColumnWithId<
    BatteryTypeTableColumn,
    BatteryType
>[];

export const AllTableColumns = [
    ...BaseTableColumns,
    CapacityRatingColumn,
    ReferenceTemperatureColumn,
    CellsPerBlocColumn,
    PeukertsConstantColumn,
] as ColumnWithId<BatteryTypeTableColumn, BatteryType>[];
