/**
 * @generated SignedSource<<62f61ffc6e17451809b549f73cb8bb5e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CapacityChartQuery$variables = {
  begin: string;
  end: string;
  id: string;
  interval: string;
};
export type CapacityChartQuery$data = {
  readonly device: {
    readonly battery: {
      readonly data: {
        readonly remainingCapacity: {
          readonly " $fragmentSpreads": FragmentRefs<"ReportLineChart_data">;
        } | null;
      };
    };
    readonly type: {
      readonly features: {
        readonly battery: {
          readonly capacity: boolean;
        };
      };
    };
  } | null;
};
export type CapacityChartQuery = {
  response: CapacityChartQuery$data;
  variables: CapacityChartQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "begin"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "interval"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "DeviceTypeFeatures",
  "kind": "LinkedField",
  "name": "features",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceTypeBatteryFeatures",
      "kind": "LinkedField",
      "name": "battery",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "capacity",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "begin",
    "variableName": "begin"
  },
  {
    "kind": "Variable",
    "name": "end",
    "variableName": "end"
  },
  {
    "kind": "Variable",
    "name": "interval",
    "variableName": "interval"
  },
  {
    "kind": "Literal",
    "name": "unit",
    "value": "AmpHour"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CapacityChartQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceType",
            "kind": "LinkedField",
            "name": "type",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceBattery",
            "kind": "LinkedField",
            "name": "battery",
            "plural": false,
            "selections": [
              {
                "alias": "data",
                "args": null,
                "concreteType": "DeviceBatteryMetrics",
                "kind": "LinkedField",
                "name": "metrics",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v6/*: any*/),
                    "concreteType": "TimeSeriesMetrics",
                    "kind": "LinkedField",
                    "name": "remainingCapacity",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "ReportLineChart_data"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "CapacityChartQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceType",
            "kind": "LinkedField",
            "name": "type",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceBattery",
            "kind": "LinkedField",
            "name": "battery",
            "plural": false,
            "selections": [
              {
                "alias": "data",
                "args": null,
                "concreteType": "DeviceBatteryMetrics",
                "kind": "LinkedField",
                "name": "metrics",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v6/*: any*/),
                    "concreteType": "TimeSeriesMetrics",
                    "kind": "LinkedField",
                    "name": "remainingCapacity",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TimestampMetric",
                        "kind": "LinkedField",
                        "name": "values",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "timestamp",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "value",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "31f3a87394a4c9bfbcb1fb313194ce3c",
    "id": null,
    "metadata": {},
    "name": "CapacityChartQuery",
    "operationKind": "query",
    "text": "query CapacityChartQuery(\n  $id: ID!\n  $begin: Timestamp!\n  $end: Timestamp!\n  $interval: Duration!\n) {\n  device(id: $id) {\n    type {\n      features {\n        battery {\n          capacity\n        }\n      }\n      id\n    }\n    battery {\n      data: metrics {\n        remainingCapacity(unit: AmpHour, begin: $begin, end: $end, interval: $interval) {\n          ...ReportLineChart_data\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment ReportLineChart_data on TimeSeriesMetrics {\n  values {\n    timestamp\n    value\n  }\n}\n"
  }
};
})();

(node as any).hash = "decc99b9ffa4bc12c219f489df522f89";

export default node;
