import React, { FC } from 'react';
import { useRelayEnvironment } from 'react-relay';
import { fetchQuery } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { FilterSearchSelect } from 'components/FilterSearchSelect';
import { IEnvironment } from 'relay-runtime';

import { CommonFilterProps } from '../../common/types';
import { DeviceNameFilter } from '../../device/types';
import { BatteryTypeManModelFilter } from '../types';
import { ManModelFilterResult } from './ManModelFilterResult';
import { BatteryTypeManufacturerFilterUI_manufacturerSearchQuery } from './__generated__/BatteryTypeManufacturerFilterUI_manufacturerSearchQuery.graphql';

export type BatteryTypeManufacturerFilterUIProps = CommonFilterProps<BatteryTypeManModelFilter[]>;

export const BatteryTypeManufacturerFilterUI: FC<BatteryTypeManufacturerFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    const environment = useRelayEnvironment();
    return (
        <FilterSearchSelect
            active={current}
            title='Filter by manufacturer'
            placeholder='Enter manufacturer'
            renderItem={item => <ManModelFilterResult filter={item} />}
            renderItemSimple={item => item.name}
            showBack={hasPrevious}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onSearch={input => doSearch(environment, input, 5)}
            onClose={onClose}
            createDefaultItem={input => {
                if (input.trim().length > 0) {
                    return createWildcardFilter(input);
                } else {
                    return null;
                }
            }}
        />
    );
};

async function doSearch(environment: IEnvironment, input: string, maxResults: number): Promise<DeviceNameFilter[]> {
    try {
        const results = await fetchQuery<BatteryTypeManufacturerFilterUI_manufacturerSearchQuery>(
            environment,
            graphql`
                query BatteryTypeManufacturerFilterUI_manufacturerSearchQuery($input: String!, $maxResults: Int!) {
                    batteryTypeManufacturers(match: $input, limit: $maxResults, includeDeleted: false) {
                        names
                    }
                }
            `,
            {
                input,
                maxResults: maxResults - 1,
            }
        ).toPromise();

        const names = results?.batteryTypeManufacturers?.names ?? [];

        const output: BatteryTypeManModelFilter[] = names.map(name => ({
            type: 'result',
            name,
        }));

        if (input.trim().length > 0) {
            if (output.length >= maxResults) {
                output[output.length - 1] = createWildcardFilter(input);
            } else {
                output.push(createWildcardFilter(input));
            }
        }

        return output;
    } catch (error) {
        return [];
    }
}

function createWildcardFilter(input: string): BatteryTypeManModelFilter {
    return {
        type: 'special',
        name: input.trim(),
        wildcard: true,
    };
}
