import { Duration } from 'luxon';
import { array, boolean, date, number, object, string } from 'yup';

import { BatteryTestType, RepeatTestType } from './common';

export function createDefaultScheduleTestValues(): ScheduleTestFormValues {
    return {
        name: null,
        type: 'Capacity',
        isSmartStart: false,
        scheduleTime: null,
        scheduleRepeat: 'Never',

        capacitySettings: {
            targets: [],
        },
        customSettings: {
            eodv: '',
            maxDuration: null,
            maxDischarge: '',
        },
        quickSettings: {
            reserveTime: '',
            minAllowedVoltage: '',
        },
    };
}

export interface ScheduleTestFormValues {
    name: string | null;
    type: BatteryTestType;
    isSmartStart: boolean;
    scheduleTime: Date | null;
    scheduleRepeat: RepeatTestType;

    capacitySettings: CapacitySettingsFormValues | null;
    customSettings: CustomSettingsFormValues | null;
    quickSettings: QuickSettingsFormValues | null;
}

interface CapacitySettingsFormValues {
    targets: CapacitySettingsTargetFormValues[];
}

export interface CapacitySettingsTargetFormValues {
    eodv: string | undefined;
    batteryType: string;
}

interface CustomSettingsFormValues {
    eodv: string;
    maxDuration: Duration | null;
    maxDischarge: string;
}

interface QuickSettingsFormValues {
    reserveTime: string;
    minAllowedVoltage: string;
}

const CapacityTestValidationSchema = object({
    targets: array().of(
        object({
            eodv: number().typeError('Must be a number').required('EODV is required'),
            batteryType: string().required('Battery Type is required'),
        })
    ),
});

const CustomTestValidationSchema = object({
    eodv: number().typeError('Must be a number').nullable(),
    maxDuration: object().nullable(), // FIXME: Find a better way to deal with Duration object
    maxDischarge: number().typeError('Must be a number').nullable(),
});

const QuickTestValidationSchema = object({
    reserveTime: number()
        .typeError('Must be a number')
        .min(0, 'Reserve time must be betweeen 0 - 100%')
        .max(100, 'Reserve time must be between 0 - 100%')
        .required('Reserve time is required'),
    minAllowedVoltage: number()
        .typeError('Must be a number')
        .min(0, 'Invalid minimum allowed voltage')
        .required('Min allowed voltage is required'),
});

export const ScheduleTestValidationSchema = object({
    name: string().nullable(),
    type: string().oneOf(['Quick', 'Capacity', 'Custom']).required('Type is required'),
    isSmartStart: boolean().required(),
    scheduleTime: date().nullable(),
    scheduleRepeat: string().oneOf(['Never', 'Monthly', 'BiMonthly', 'Quarterly']),

    capacitySettings: object().when('type', {
        is: 'Capacity',
        then: CapacityTestValidationSchema.required(),
    }),

    customSettings: object().when('type', {
        is: 'Custom',
        then: CustomTestValidationSchema.required(),
    }),

    quickSettings: object().when('type', {
        is: 'Quick',
        then: QuickTestValidationSchema.required(),
    }),
});
