import { Disposable, Environment, fetchQuery } from 'react-relay';

import { ToastOptions } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { downloadExportFile } from 'lib/download';

import { metricsExporterStatusQuery } from './__generated__/metricsExporterStatusQuery.graphql';

export class Exporter {
    private intervalTask: NodeJS.Timeout | null = null;
    private isRunning = true;
    private finishCallback: (() => void) | null = null;
    private runningDisposable: Disposable | null = null;
    private setDownloadLink: ((downloadLink: string) => void) | null = null;
    private setProgress: ((progress: number) => void) | null = null;
    private onSuccess: (() => void) | null = null;
    private onFail: (() => void) | null = null;

    constructor(
        private jobId: string,
        private showToast: (options: ToastOptions) => void,
        private environment: Environment
    ) {}

    get running(): boolean {
        return this.isRunning;
    }

    cancel() {
        this.onFail?.();
        if (this.intervalTask != null) {
            clearTimeout(this.intervalTask);
        }

        this.finish();

        if (this.runningDisposable) {
            this.runningDisposable.dispose();
            this.runningDisposable = null;
        }

        this.isRunning = false;
    }

    begin(
        setDownloadLink?: (jobId: string) => void,
        setProgress?: (progress: number) => void,
        onSuccess?: VoidFunction,
        onFail?: VoidFunction
    ) {
        this.setDownloadLink = setDownloadLink ?? null;
        this.setProgress = setProgress ?? null;
        this.onSuccess = onSuccess ?? null;
        this.onFail = onFail ?? null;
        const promise = new Promise<void>(resolve => {
            this.finishCallback = resolve;
        });
        this.startWatching(this.jobId, promise);
    }

    private startWatching(id: string, finishedPromise: Promise<void>) {
        this.jobId = id;
        this.intervalTask = setTimeout(() => this.check(), 1000);

        this.showToast({
            text: 'Exporting metrics',
            variant: 'info',
            actions: [
                {
                    title: 'Cancel',
                    onClick: () => {
                        this.cancel();
                    },
                },
            ],
            displayUntil: finishedPromise,
        });
    }
    private check() {
        if (!this.jobId) {
            return;
        }

        fetchQuery<metricsExporterStatusQuery>(
            this.environment,
            graphql`
                query metricsExporterStatusQuery($id: ID!) {
                    exportJob(id: $id) {
                        status
                        progress
                        downloadUrl
                    }
                }
            `,
            { id: this.jobId }
        )
            .toPromise()
            .then(this.handleJobStatus.bind(this));
        this.intervalTask = setTimeout(() => this.check(), 1000);
    }

    private finish() {
        this.finishCallback?.();
        this.finishCallback = null;
    }

    private handleJobStatus(result?: metricsExporterStatusQuery['response']) {
        if (!result?.exportJob) {
            return;
        }

        if (!this.running) {
            // just in case it was cancelled between when the query was sent and this response
            return;
        }

        const job = result.exportJob;

        if (job.status === 'Waiting') {
            this.setProgress?.(0);
        }

        if (job.status === 'InProgress') {
            if (job.progress !== null) {
                this.setProgress?.(Math.floor(job.progress * 100));
            }
        }

        if (job.status === 'Failed') {
            this.finish();
            this.showToast({
                text: 'Export did not succeed. Something went wrong.',
                variant: 'error',
            });
            this.cancel();
            return;
        }

        if (job.status === 'Succeeded') {
            console.assert(job.downloadUrl, 'Expected download URL available');
            if (!job.downloadUrl) {
                return;
            }
            this.onSuccess?.();

            this.setDownloadLink?.(job.downloadUrl);

            this.finish();

            this.showToast({
                text: 'Export finished',
                variant: 'info',
            });

            downloadExportFile(job.downloadUrl);

            this.cancel();
            return;
        }
    }
}
