/**
 * @generated SignedSource<<93f09e508befcc987b693382dbfb79fa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DataCollectionReloadQuery$variables = {};
export type DataCollectionReloadQuery$data = {
  readonly settings: {
    readonly " $fragmentSpreads": FragmentRefs<"DataCollectionFragment">;
  };
};
export type DataCollectionReloadQuery = {
  response: DataCollectionReloadQuery$data;
  variables: DataCollectionReloadQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DataCollectionReloadQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Settings",
        "kind": "LinkedField",
        "name": "settings",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DataCollectionFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DataCollectionReloadQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Settings",
        "kind": "LinkedField",
        "name": "settings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DataCollectionSettings",
            "kind": "LinkedField",
            "name": "dataCollection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DataCollectionPollingSettings",
                "kind": "LinkedField",
                "name": "polling",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "generalInterval",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "highFrequencyInterval",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "syncInterval",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "timeout",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DeviceManagementSettings",
                "kind": "LinkedField",
                "name": "deviceManagement",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "checkInInterval",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "missedCheckInTimeout",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "allocationDelay",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c07ace814eb64f5c1519ace709b5631b",
    "id": null,
    "metadata": {},
    "name": "DataCollectionReloadQuery",
    "operationKind": "query",
    "text": "query DataCollectionReloadQuery {\n  settings {\n    ...DataCollectionFragment\n  }\n}\n\nfragment DataCollectionFragment on Settings {\n  dataCollection {\n    polling {\n      generalInterval\n      highFrequencyInterval\n      syncInterval\n      timeout\n    }\n    deviceManagement {\n      checkInInterval\n      missedCheckInTimeout\n      allocationDelay\n    }\n  }\n}\n"
  }
};

(node as any).hash = "dd74985c8c22d49e8dd7ea7b24630193";

export default node;
