import React, { CSSProperties, FC, ReactNode } from 'react';

import classNames from 'classnames';

export interface NameValuePairClassesProps {
    container?: CSSProperties;
    value?: CSSProperties;
}
export interface NameValuePairProps {
    name: string;
    value: ReactNode;
    primary?: boolean;
    classes?: NameValuePairClassesProps;
}

export const NameValuePair: FC<NameValuePairProps> = ({ name, value, primary, classes }) => {
    return (
        <div
            className={classNames('font-CynthoNext-Light text-sm whitespace-no-wrap truncate', {
                'text-customEggplant': !primary,
                'text-customCoral': primary,
            })}
            style={classes?.container}
        >
            {name.toUpperCase()}:{' '}
            <span style={classes?.value} className='font-CynthoNext-Regular text-base'>
                {value}
            </span>
        </div>
    );
};
