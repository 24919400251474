import { Environment, fetchQuery } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';

import { selectAllQuery } from './__generated__/selectAllQuery.graphql';

export function fetchAllDeviceIds(environment: Environment, filter: Record<string, unknown>): Promise<string[]> {
    return new Promise<string[]>((resolve, reject) => {
        fetchQuery<selectAllQuery>(environment, FetchQuery, { filter }).subscribe({
            next(value) {
                resolve(value.devices.data.map(device => device.id));
            },
            error(error: unknown) {
                reject(error);
            },
        });
    });
}

const FetchQuery = graphql`
    query selectAllQuery($filter: DeviceFilter) {
        devices(filters: $filter, pageSize: 10000) {
            data {
                id
            }
        }
    }
`;
