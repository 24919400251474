import React, { FC } from 'react';

import style from './style.module.css';

interface TableHeaderProps {
    columns: number;
}

export const TableHeader: FC<TableHeaderProps> = ({ columns }) => {
    const columnHeaders = [<th key='bloc-table-column-0'></th>];

    for (let index = 1; index <= columns; index++) {
        columnHeaders.push(<th key={`bloc-table-column-${index}`}>Bloc {index}</th>);
    }

    return <tr className={style.table_cell_bold}>{columnHeaders}</tr>;
};
