import React, { FC, ReactNode, useCallback, useState } from 'react';

import { FilterContainer } from '@accesstel/pcm-ui';

import { SingleSelectionList } from 'components/FilterSingleSelect/SingleSelectionList';

export interface SelectionWithTestIds {
    text: string;
    subText?: string;
    ids: string[];
    onClick?: () => void;
}

export interface SelectTestPaneProps {
    current: SelectionWithTestIds | null;
    tests: SelectionWithTestIds[];
    onClose: () => void;
    onSubmit: (selection: SelectionWithTestIds) => void;
}

export const SelectTestPane: FC<SelectTestPaneProps> = ({ onClose, onSubmit, tests, current }) => {
    const [selection, setSelection] = useState<SelectionWithTestIds | null>(current);

    const handleConfirmClick = useCallback(() => {
        if (!onSubmit || !selection) {
            return;
        }

        onSubmit(selection);
        onClose();
    }, [onClose, onSubmit, selection]);

    const content: ReactNode = (
        <SingleSelectionList<SelectionWithTestIds>
            items={tests}
            onSelect={item => {
                item.onClick?.();
                setSelection(item);
                onSubmit(item);
                onClose();
            }}
            selectedItem={selection ? tests.find(test => test.text === selection.text) : undefined}
            renderItem={item => {
                if ('subText' in item) {
                    return (
                        <span>
                            <span>{item.text}</span>
                            <span className='italic'>{` ${item.subText}`}</span>
                        </span>
                    );
                } else {
                    return item.text;
                }
            }}
        />
    );

    const noItems = tests.length === 0;

    return (
        <FilterContainer
            title={noItems ? 'No tests to compare' : 'Select test'}
            onConfirmClick={handleConfirmClick}
            onClose={onClose}
            primaryContent={content}
            hideConfirmButton
            hideClearButton
        />
    );
};
