export const formControlInitialState: FormControlParameters = {
    addNewBatteryType: false,
    addNewDualPlaneCompanionDevice: false,
    addNewSite: false,
    duplicateCompanionPlaneBatteries: false,
};

export enum FormControlActionTypes {
    AddNewDualPlaneCompanionDevice,
    DuplicateCompanionPlaneBatteries,
    AddNewSite,
    AddNewBatteryType,
    ResetState,
}

export type FormControlAction =
    | { type: FormControlActionTypes.AddNewBatteryType; payload: boolean }
    | { type: FormControlActionTypes.AddNewDualPlaneCompanionDevice; payload: boolean }
    | { type: FormControlActionTypes.AddNewSite; payload: boolean }
    | { type: FormControlActionTypes.DuplicateCompanionPlaneBatteries; payload: boolean }
    | { type: FormControlActionTypes.ResetState };

export type FormControlParameters = {
    addNewDualPlaneCompanionDevice: boolean;
    duplicateCompanionPlaneBatteries: boolean;
    addNewSite: boolean;
    addNewBatteryType: boolean;
};

export const formControlReducer = (state: FormControlParameters, action: FormControlAction): FormControlParameters => {
    switch (action.type) {
        case FormControlActionTypes.AddNewBatteryType:
            return { ...state, addNewBatteryType: action.payload };
        case FormControlActionTypes.AddNewDualPlaneCompanionDevice:
            return { ...state, addNewDualPlaneCompanionDevice: action.payload };
        case FormControlActionTypes.AddNewSite:
            return { ...state, addNewSite: action.payload };
        case FormControlActionTypes.DuplicateCompanionPlaneBatteries:
            return { ...state, duplicateCompanionPlaneBatteries: action.payload };
        case FormControlActionTypes.ResetState:
            return formControlInitialState;
        default:
            throw new Error();
    }
};
