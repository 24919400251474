export enum SiteTableColumn {
    Name = 'Name',
    Type = 'Type',
    State = 'State',
    Postcode = 'Postcode',
    DeviceCount = 'DeviceCount',
    BatteryStatus = 'BatteryStatus',
    BatteryCapacity = 'BatteryCapacity',
    BatteryTotalTimeRemaining = 'BatteryTotalTimeRemaining',
    DeviceStatus = 'DeviceStatus',
}

export enum SiteExtraFilters {
    DeviceName = 'Device Name',
    DeviceWithBatteries = 'Devices With Batteries',
}

export enum DeviceHealthStatus {
    Critical = 'Critical',
    Degraded = 'Degraded',
    Healthy = 'Healthy',
    Offline = 'Offline',
    Unknown = 'Unknown',
}

export interface SiteNameFilter {
    name: string;
    type: 'result' | 'special';
    wildcard?: boolean;
    id?: string;
    state?: string;
}

export interface SiteTypeFilter {
    name: string;
    id: string;
}
export interface SiteStateFilter {
    state: string;
}

export enum SiteDeviceBatteries {
    All = 'All',
    Partial = 'Partial',
    None = 'None',
}

export interface SitePostcodeFilter {
    id: string;
    value: string;
}

export interface SiteDeviceStatusFilter {
    id: DeviceHealthStatus;
    displayText: string;
}

export const DeviceHealthStatusNames: Record<DeviceHealthStatus, string> = {
    [DeviceHealthStatus.Critical]: 'Critical',
    [DeviceHealthStatus.Degraded]: 'Degraded',
    [DeviceHealthStatus.Healthy]: 'Healthy',
    [DeviceHealthStatus.Offline]: 'Offline',
    [DeviceHealthStatus.Unknown]: 'Unknown',
};
