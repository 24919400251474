import { Operation, SelectedMetric } from '../types';

export function formatMetricDefinition(metric: SelectedMetric): string {
    return `${metric.metric}:${metric.op}`;
}

export function parseMetricDefinition(input: string): SelectedMetric | null {
    if (!input.includes(':')) {
        return null;
    }

    const [metric, op] = input.split(':');

    if (!metric) {
        return null;
    }

    let operation: Operation;
    switch (op) {
        case 'Sum':
            operation = Operation.Sum;
            break;
        case 'Average':
            operation = Operation.Average;
            break;
        case 'Minimum':
            operation = Operation.Minimum;
            break;
        case 'Maximum':
            operation = Operation.Maximum;
            break;
        default:
            return null;
    }

    return {
        metric,
        op: operation,
    };
}
