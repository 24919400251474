import { useCallback, useEffect, useState } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';

import isEqual from 'lodash.isequal';
import { CacheConfig, FetchQueryFetchPolicy, GraphQLTaggedNode, OperationType } from 'relay-runtime';

export interface FetchOptions {
    fetchPolicy?: FetchQueryFetchPolicy;
    networkCacheConfig?: CacheConfig;
    skip?: boolean;
}

export interface RenderProps<T extends OperationType> {
    error: Error | null;
    data: T['response'] | null | undefined;
    retry: (cacheConfigOverride?: CacheConfig, options?: FetchOptions) => void;
    isFetching: boolean;
}

/**
 * NOTE: This is a drop in replacement to the useQuery hook from relay-hooks,
 * which should actually work with React 18
 * @param gqlQuery
 * @param variables
 * @param options
 * @returns
 */
export function useQuery<TOperationType extends OperationType = OperationType>(
    gqlQuery: GraphQLTaggedNode,
    variables: TOperationType['variables'],
    options?: FetchOptions
): RenderProps<TOperationType> {
    const [error, setError] = useState<Error | null>(null);
    const [data, setData] = useState<TOperationType['response'] | null | undefined>(undefined);
    const [isFetching, setIsFetching] = useState(false);
    const environment = useRelayEnvironment();
    const [safeVariables, setSafeVariables] = useState<TOperationType['variables']>(variables);

    const retry = useCallback(
        (cacheConfigOverride?: CacheConfig, overrideOptions?: FetchOptions) => {
            let fetchOptions: FetchOptions = {
                ...options,
            };

            if (cacheConfigOverride) {
                fetchOptions.networkCacheConfig = cacheConfigOverride;
            }

            if (overrideOptions) {
                fetchOptions = {
                    ...fetchOptions,
                    ...overrideOptions,
                };
            }

            setError(null);
            setIsFetching(true);

            fetchQuery(environment, gqlQuery, safeVariables, fetchOptions).subscribe({
                error: (error: unknown) => {
                    if (error instanceof Error) {
                        setError(error);
                    } else {
                        setError(new Error(String(error)));
                    }
                    setData(null);
                },
                next: data => {
                    setData(data);
                    setError(null);
                },
                complete: () => {
                    setIsFetching(false);
                },
            });
        },
        [options, environment, gqlQuery, safeVariables]
    );

    // Update variables if they changed deeply
    useEffect(() => {
        if (!isEqual(variables, safeVariables)) {
            setSafeVariables(variables);
        }
    }, [safeVariables, variables]);

    // Re-request the data if variables or the query changed
    useEffect(() => {
        if (options?.skip) {
            return;
        }

        retry();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gqlQuery, safeVariables, options?.skip]);

    return {
        data,
        error,
        retry,
        isFetching,
    };
}
