import React, { FC } from 'react';

import { DeviceIcon, SiteIcon, Tooltip } from '@accesstel/pcm-ui';

import style from './style.module.css';

export interface ReportSearchResults {
    site: string;
    siteName: string;
    id: string;
    name: string;
    state: string | null;
    type: 'site' | 'device';
}

export const SearchGroups = [
    {
        key: 'device',
        title: <span className='text-customEggplantCoral'>DEVICE</span>,
    },
    {
        key: 'site',
        title: <span className='text-customEggplant'>SITE</span>,
    },
];

export function renderSearchResult(result: ReportSearchResults): React.ReactNode {
    if (result.type === 'site') {
        return <SiteSearchResult item={result} />;
    } else {
        return <DeviceSearchResult item={result} />;
    }
}

interface SearchResultProps {
    item: ReportSearchResults;
}

const SiteSearchResult: FC<SearchResultProps> = ({ item }) => {
    return (
        <div className='flex flex-row items-center space-x-2'>
            <div className={style.icon_container}>
                <SiteIcon />
            </div>
            <Tooltip content={`${item.siteName}${item.state && `, ${item.state}`}`} overflowOnly>
                <div className='font-CynthoNext-Regular text-base truncate'>
                    {item.siteName}
                    {item.state && `, ${item.state}`}
                </div>
            </Tooltip>
        </div>
    );
};

const DeviceSearchResult: FC<SearchResultProps> = ({ item }) => {
    return (
        <div className='flex flex-row items-center space-x-2 text-customEggplantCoral'>
            <div className={style.icon_container}>
                <DeviceIcon />
            </div>
            <Tooltip content={item.name} overflowOnly>
                <div className='font-CynthoNext-Regular text-base truncate'>{item.name}</div>
            </Tooltip>
            <Tooltip content={`${item.siteName}${item.state && `, ${item.state}`}`} overflowOnly>
                <div className='font-CynthoNext-Light text-xs truncate'>
                    {item.siteName}
                    {item.state && `, ${item.state}`}
                </div>
            </Tooltip>
        </div>
    );
};
