import React, { FC } from 'react';

import { FormSection, FormikTextBox } from '@accesstel/pcm-ui';

import { getQualifiedName } from 'lib/namespaces';

import manageStyle from '../../../style.module.css';

export enum SettingsName {
    ElementName = 'Element Name',
    SiteId = 'Site Id',
}

export interface IntegrationProps {
    namespace?: string;
    showSiteId?: boolean;
}

export const Integration: FC<IntegrationProps> = ({ namespace, showSiteId = true }) => {
    return (
        <FormSection label='Optima Integration' blurb='The details from Optima for this device.'>
            <div className={manageStyle.provisioning_content_grid_nomargin}>
                <div className='col-start-1'>
                    <FormikTextBox
                        name={getQualifiedName('elementName', namespace)}
                        placeHolder='Element Name'
                        light
                        variant='outlined'
                        testId='optima-element-name'
                    />
                </div>
                {showSiteId && (
                    <div className='col-start-2'>
                        <FormikTextBox
                            name={getQualifiedName('siteId', namespace)}
                            placeHolder='Site Id'
                            light
                            variant='outlined'
                            testId='optima-site-id'
                        />
                    </div>
                )}
            </div>
        </FormSection>
    );
};
