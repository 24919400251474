import React, { FC } from 'react';

import { BarDataType, StackedHorizontalBar } from '@accesstel/pcm-ui';

import { StateStyleMap, TestStatus } from 'lib/common';
import { convertStatusForCSSClass, formatDataForTestProgressBar } from 'lib/textFormatters';

export interface TestProgressBarCombinedProps {
    stateDevice1: TestStatus | undefined;
    stateDevice2: TestStatus | undefined;
}

function valueFormatter(value: number | null) {
    if (value === 1 && value !== null) {
        return `${value} Device`;
    } else if (value) {
        return `${value} Devices`;
    } else {
        return '';
    }
}

export const TestProgressBarCombined: FC<TestProgressBarCombinedProps> = ({ stateDevice1 = '', stateDevice2 = '' }) => {
    const data: BarDataType[] = [];

    if (stateDevice1 === stateDevice2) {
        const convertedStatus = convertStatusForCSSClass(stateDevice1);

        data.push({
            label: stateDevice1,
            value: 2,
            bgClass: StateStyleMap[convertedStatus].bgClass,
        });
    } else {
        let convertedStatus = convertStatusForCSSClass(stateDevice1);

        data.push({
            label: stateDevice1,
            value: 1,
            bgClass: StateStyleMap[convertedStatus].bgClass,
        });

        convertedStatus = convertStatusForCSSClass(stateDevice2);
        data.push({
            label: stateDevice2,
            value: 1,
            bgClass: StateStyleMap[convertedStatus].bgClass,
        });
    }

    return (
        <StackedHorizontalBar
            labelFormatter={value => formatDataForTestProgressBar(value)}
            valueFormatter={value => valueFormatter(value)}
            data={data}
        />
    );
};
