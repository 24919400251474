import React, { FC } from 'react';

import { Button } from '@accesstel/pcm-ui';

import { useFormikContext } from 'formik';
import { loginWithFederation } from 'lib/auth';
import { logError } from 'lib/log';

import { LoginStageSettingsQuery$data } from '../__generated__/LoginStageSettingsQuery.graphql';

type SignInOption = LoginStageSettingsQuery$data['settings']['login']['federation'][number];

export interface FederatedSignInSectionProps {
    options: readonly SignInOption[];
    onError: (message: string) => void;
}

export const FederatedSignInSection: FC<FederatedSignInSectionProps> = ({ options, onError }) => {
    const { isSubmitting, setSubmitting, status, setStatus } = useFormikContext();

    return (
        <div className='flex flex-col items-stretch space-y-8'>
            <div className='login-break'>
                <div className='login-dash'></div>
                <div>OR</div>
                <div className='login-dash'></div>
            </div>
            {options.map(option => (
                <Button
                    key={option.id}
                    buttonText={`Continue with ${option.displayName}`}
                    type='button'
                    size='large'
                    variant='secondary'
                    disabled={isSubmitting}
                    processing={isSubmitting && status === option.id}
                    onClick={() => {
                        setSubmitting(true);
                        setStatus(option.id);
                        loginWithFederation(option.id).catch(error => {
                            logError('Failed to initiate federated login', error);
                            onError('Unable to log you in.');
                        });
                    }}
                />
            ))}
        </div>
    );
};
