import React, { useState } from 'react';

import { PageLastRefreshed, ThemedPageHeading } from '@accesstel/pcm-ui';

import { ActiveDevicesCount, PageHeader, useDocumentTitle } from '../../components';
import { FirmwareStatusHorizontalBar } from './FirmwareStatusHorizontalBar';
import { FirmwarePageTableQuery } from './FirmwareTableQuery';
import { SiteNameSearch } from './SiteNameSearchQuery';

const refreshPage = () => {
    window.location.reload();
};

export function FirmwareStatus(): JSX.Element {
    const [siteNameFilter, setSiteNameFilter] = useState<string>('');
    useDocumentTitle('Firmware Status');

    return (
        <div className='space-y-6'>
            <PageHeader />
            <div>
                <ThemedPageHeading value={'Firmware Status'} />
                <ActiveDevicesCount valueSuffix='Active Devices' />
                <PageLastRefreshed buttonClickCallback={refreshPage} />
                <div className='firmware-status'>
                    <FirmwareStatusHorizontalBar />
                </div>
                <div className='firmware-status-search'>
                    <SiteNameSearch iconClick={setSiteNameFilter} resultClick={setSiteNameFilter} />
                </div>
                <FirmwarePageTableQuery siteNameFilter={siteNameFilter} />
            </div>
        </div>
    );
}
