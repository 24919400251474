import React, { FC } from 'react';

import classNames from 'classnames';

import style from './style.module.css';

interface TestSectionHeadingProps {
    value: string;
}

export const TestSectionHeading: FC<TestSectionHeadingProps> = ({ value }) => {
    return (
        <div className={classNames(style.test_heading, 'text-customEggplant', 'font-CynthoNext-SemiBold')}>{value}</div>
    );
};
