import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { fetchQuery, useFragment, useRelayEnvironment } from 'react-relay';

import { Domain, MenuItemType, Theme } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { TimeseriesOffsetSeries } from 'lib/dateChart';
import { useZoomableData } from 'lib/line-chart-zoom';
import { logError } from 'lib/log';
import { DateTime, Duration } from 'luxon';

import { MetricLine } from '../../components/MetricsLine';
import { ChartRefreshInterval } from '../../settings';
import { AggregatedDataPoint, MetricsData, getDisplayInterval, spliceAllData, toTimeSeriesOffsetData } from '../common';
import { BlocId } from '../components/common';
import { useCDFOverlays } from '../lib/cdf';
import { MenuOptions } from '../lib/common';
import { supportsTemperatureMetric } from '../lib/feature-support';
import { BlocDataDisplaySingleQuery } from './__generated__/BlocDataDisplaySingleQuery.graphql';
import { BlocDataDisplay_test$key } from './__generated__/BlocDataDisplay_test.graphql';

const DefaultChartXDomain: Domain<Duration> = ['dataMin', 'dataMax'];

const DataQuery = graphql`
    query BlocDataDisplaySingleQuery(
        $testId: ID!
        $interval: Duration
        $begin: Duration
        $end: Duration
        $blocs: [BlocId!]
    ) {
        batteryTestResult(id: $testId) {
            blocs(blocs: $blocs) {
                results {
                    string
                    bloc
                    aggregatedVoltage(unit: Volts, interval: $interval, begin: $begin, end: $end) {
                        timestamp
                        offset
                        average
                    }
                    aggregatedTemperature(unit: Celsius, interval: $interval, begin: $begin, end: $end) {
                        timestamp
                        offset
                        average
                    }
                    aggregatedCurrent(unit: Amps, interval: $interval, begin: $begin, end: $end) {
                        timestamp
                        offset
                        average
                    }
                    aggregatedPower(unit: Watt, interval: $interval, begin: $begin, end: $end) {
                        timestamp
                        offset
                        average
                    }
                    coupDeFouet {
                        float
                        troughVoltage
                        troughOffset
                        plateauVoltage
                        plateauOffset
                        dip
                    }
                }
            }
            commencedTime
            completedTime
            abortedTime
            state
        }
    }
`;

const SeriesColors = [Theme.customCoral, Theme.customEggplantWhite, Theme.customEggplantCoral, Theme.customEggplant];

interface BlocMetricsData {
    id: BlocId;
    metrics: MetricsData;
}

interface BlocDataDisplayProps {
    test: BlocDataDisplay_test$key;
    blocs: BlocId[];
}

export const BlocDataDisplay: FC<BlocDataDisplayProps> = ({ test, blocs }) => {
    const environment = useRelayEnvironment();
    const [selectedMenuOption, setSelectedMenuOption] = useState<string | undefined>(MenuOptions.ViewAll);

    const filterToBlocs = useMemo(() => blocs.map(bloc => ({ string: bloc[0], bloc: bloc[1] })), [blocs]);

    const info = useFragment(
        graphql`
            fragment BlocDataDisplay_test on DeviceBatteryTestResults {
                id
                commencedTime
                completedTime
                abortedTime
                state

                coupDeFouet {
                    float
                    troughVoltage
                    troughOffset
                    plateauVoltage
                    plateauOffset
                    dip
                }
            }
        `,
        test
    );

    let isLiveUpdating = false;
    let startTime: string | null = null;
    let endTime: string | null = null;

    if (info.state === 'InProgress') {
        isLiveUpdating = true;
        startTime = info.commencedTime as string;
    } else if (info.state !== 'Scheduled') {
        startTime = info.commencedTime as string;
        endTime = info.completedTime as string;

        if (info.completedTime || info.abortedTime) {
            const finishedTime = info.completedTime ?? info.abortedTime;
            isLiveUpdating =
                DateTime.now()
                    .diff(DateTime.fromISO(finishedTime as string), 'minutes')
                    .as('minutes') < 5;
        }
    }

    const fetchData = useCallback(
        (interval: Duration | null, begin: Duration | null, end: Duration | null) => {
            if (!interval && startTime) {
                if (!endTime) {
                    interval = getDisplayInterval(DateTime.fromISO(startTime), DateTime.now());
                } else {
                    interval = getDisplayInterval(DateTime.fromISO(startTime), DateTime.fromISO(endTime));
                }
            }

            return fetchQuery<BlocDataDisplaySingleQuery>(environment, DataQuery, {
                testId: info.id,
                begin: begin?.toISO(),
                end: end?.toISO(),
                interval: interval?.toISO(),
                blocs: filterToBlocs,
            }).toPromise();
        },
        [startTime, environment, info.id, filterToBlocs, endTime]
    );

    const { chartDomain, doZoom, baseData, hasError, reloadData, zoomData } = useZoomableData(
        DefaultChartXDomain,
        (_, zoomed) => {
            if (!zoomed) {
                setSelectedMenuOption(MenuOptions.ViewAll);
                return;
            }

            setSelectedMenuOption(undefined);
        },
        fetchData
    );

    // Live data
    useEffect(() => {
        if (isLiveUpdating) {
            const handle = setInterval(reloadData, ChartRefreshInterval);
            return () => {
                clearInterval(handle);
            };
        }
    }, [isLiveUpdating, reloadData]);

    // Update if we change what blocs are rendered
    useEffect(() => reloadData(), [blocs, reloadData]);

    const data = useMemo<BlocMetricsData[]>(() => {
        return blocs.map(blocId => {
            if (baseData?.batteryTestResult) {
                const blocBase = baseData.batteryTestResult.blocs.results.find(
                    result => result.string === blocId[0] && result.bloc === blocId[1]
                );

                if (blocBase) {
                    const baseMetrics: MetricsData = [
                        toTimeSeriesOffsetData(blocBase.aggregatedVoltage as AggregatedDataPoint[]),
                        toTimeSeriesOffsetData(blocBase.aggregatedTemperature as AggregatedDataPoint[]),
                        toTimeSeriesOffsetData(blocBase.aggregatedCurrent as AggregatedDataPoint[], true),
                        toTimeSeriesOffsetData(blocBase.aggregatedPower as AggregatedDataPoint[], true),
                    ];

                    if (zoomData?.batteryTestResult) {
                        const blocZoom = zoomData.batteryTestResult.blocs.results.find(
                            result => result.string === blocId[0] && result.bloc === blocId[1]
                        );

                        if (blocZoom) {
                            const overlay: MetricsData = [
                                toTimeSeriesOffsetData(blocZoom.aggregatedVoltage as AggregatedDataPoint[]),
                                toTimeSeriesOffsetData(blocZoom.aggregatedTemperature as AggregatedDataPoint[]),
                                toTimeSeriesOffsetData(blocZoom.aggregatedCurrent as AggregatedDataPoint[], true),
                                toTimeSeriesOffsetData(blocZoom.aggregatedPower as AggregatedDataPoint[], true),
                            ];

                            return {
                                id: blocId,
                                metrics: spliceAllData(baseMetrics, overlay),
                            };
                        }
                    }
                    return {
                        id: blocId,
                        metrics: baseMetrics,
                    };
                }
            }

            return {
                id: blocId,
                metrics: [null, null, null, null],
            };
        });
    }, [baseData, zoomData, blocs]);

    const voltageSeries = data.map<TimeseriesOffsetSeries>(blocData => ({
        name: `String ${blocData.id[0]} - Bloc ${blocData.id[1]}`,
        points: blocData.metrics[0] ?? [],
        lineColor: getSeriesColor(blocData.id[1]),
    }));

    const temperatureSeries = data.map<TimeseriesOffsetSeries>(blocData => ({
        name: `String ${blocData.id[0]} - Bloc ${blocData.id[1]}`,
        points: blocData.metrics[1] ?? [],
        lineColor: getSeriesColor(blocData.id[1]),
    }));

    const currentSeries = data.map<TimeseriesOffsetSeries>(blocData => ({
        name: `String ${blocData.id[0]} - Bloc ${blocData.id[1]}`,
        points: blocData.metrics[2] ?? [],
        lineColor: getSeriesColor(blocData.id[1]),
    }));

    const powerSeries = data.map<TimeseriesOffsetSeries>(blocData => ({
        name: `String ${blocData.id[0]} - Bloc ${blocData.id[1]}`,
        points: blocData.metrics[3] ?? [],
        lineColor: getSeriesColor(blocData.id[1]),
    }));

    const [markers, cdfMenuOptions] = useCDFOverlays(
        startTime ? DateTime.fromISO(startTime) : DateTime.now(),
        endTime ? DateTime.fromISO(endTime) : DateTime.now(),
        chartDomain,
        blocs
            .map(([stringId, blocId]) =>
                baseData?.batteryTestResult?.blocs.results.find(
                    blocResult => blocResult.string === stringId && blocResult.bloc === blocId
                )
            )
            .filter(bloc => bloc != null && bloc.coupDeFouet)
            .map(bloc => bloc!.coupDeFouet!),
        cdfDomain => {
            doZoom(cdfDomain);
            setSelectedMenuOption(MenuOptions.ViewCDF);
        }
    );

    const menuOptions: MenuItemType[] = [
        {
            id: MenuOptions.ViewAll,
            name: 'All',
            onClick() {
                doZoom(null);
                setSelectedMenuOption(MenuOptions.ViewAll);
            },
        },
        ...cdfMenuOptions,
    ];

    if (baseData && !baseData.batteryTestResult) {
        // Test should always exist because this shouldnt be rendered without one.
        logError('Invalid situation found. Should not be able to have no test inside this component');
        return <></>;
    }

    const isTemperatureSupported = supportsTemperatureMetricOnBloc(data);

    return (
        <div>
            <MetricLine
                type='voltage'
                series={voltageSeries}
                error={hasError}
                onRetry={reloadData}
                xDomain={chartDomain}
                onZoom={doZoom}
                markers={markers}
                menuItems={menuOptions}
                menuPlaceholder='Zoom'
                menuSelected={selectedMenuOption}
            />
            <MetricLine
                type='current'
                series={currentSeries}
                error={hasError}
                onRetry={reloadData}
                xDomain={chartDomain}
                onZoom={doZoom}
            />
            <MetricLine
                type='power'
                series={powerSeries}
                error={hasError}
                onRetry={reloadData}
                xDomain={chartDomain}
                onZoom={doZoom}
            />
            <MetricLine
                type='temperature'
                series={temperatureSeries}
                error={hasError}
                onRetry={reloadData}
                xDomain={chartDomain}
                onZoom={doZoom}
                unsupported={!isTemperatureSupported}
            />
        </div>
    );
};

function getSeriesColor(bloc: number): string {
    return SeriesColors[(bloc - 1) % SeriesColors.length];
}

export function supportsTemperatureMetricOnBloc(blocs: BlocMetricsData[]): boolean {
    for (const bloc of blocs) {
        if (supportsTemperatureMetric(bloc.metrics)) {
            return true;
        }
    }
    return false;
}
