import React, { FC, useMemo } from 'react';

import { FormikDropdown } from '@accesstel/pcm-ui';

import { useQuery } from 'lib/query-helpers';

import { queries_GetDeviceTypesQuery } from '../../../__generated__/queries_GetDeviceTypesQuery.graphql';
import { getDeviceTypesQuery } from '../../../queries';

export interface DeviceTypeSelectorProps {
    name: string;
}

export const DeviceTypeSelector: FC<DeviceTypeSelectorProps> = ({ name }) => {
    const { deviceTypeIds, typeIdToNameMap } = useDeviceTypes();

    return (
        <FormikDropdown<string>
            name={name}
            variant='outlined'
            placeHolder='Device Type*'
            items={deviceTypeIds}
            renderItem={typeId => typeIdToNameMap[typeId]}
            light
            testId='device-type'
        />
    );
};

function useDeviceTypes() {
    // FIXME: Use useLazyLoadQuery, but that suspends
    const { data } = useQuery<queries_GetDeviceTypesQuery>(
        getDeviceTypesQuery,
        {},
        { fetchPolicy: 'store-or-network' }
    );

    const deviceTypeIds = useMemo(() => {
        const deviceTypes = data?.deviceTypes.data ?? [];
        return deviceTypes.map(deviceType => deviceType.id);
    }, [data?.deviceTypes.data]);

    const typeIdToNameMap = useMemo(() => {
        const deviceTypes = data?.deviceTypes.data ?? [];
        const map: Record<string, string> = {};

        for (const deviceType of deviceTypes) {
            map[deviceType.id] = deviceType.displayName;
        }
        return map;
    }, [data?.deviceTypes.data]);

    return { deviceTypeIds, typeIdToNameMap };
}
