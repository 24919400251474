import { SiteTableColumn } from 'filters/site';
import { ColumnWithId } from 'layouts';
import {
    BatteryCapacityColumn,
    BatteryStatusColumn,
    BatteryTotalTimeRemainingColumn,
    DeviceCountColumn,
    DeviceStatusColumn,
    NameColumn,
    PostcodeColumn,
    StateColumn,
    TypeColumn,
} from 'lib/tables/site/columns';

import { SiteListTableQuery$data } from './__generated__/SiteListTableQuery.graphql';

export type SiteWithDevices = SiteListTableQuery$data['sites']['data'][number];
export type SiteDevice = SiteWithDevices['devices']['data'][number];

export const BaseTableColumns = [NameColumn, StateColumn, DeviceCountColumn, DeviceStatusColumn] as ColumnWithId<
    SiteTableColumn,
    SiteWithDevices | SiteDevice
>[];

export const AllTableColumns = [
    ...BaseTableColumns,
    TypeColumn,
    PostcodeColumn,
    BatteryStatusColumn,
    BatteryCapacityColumn,
    BatteryTotalTimeRemainingColumn,
] as ColumnWithId<SiteTableColumn, SiteWithDevices | SiteDevice>[];
