import React, { useCallback, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { useCurrentUser } from '../../lib/auth';
import { LoginLocationState } from '../../lib/router';
import { LoginStage } from './login-stage/LoginStage';
import { ResetForgottenPasswordStage } from './reset-forgotten-password-stage/ResetForgottenPasswordStage';
import { ResetPassword as ResetPasswordView } from './reset-password-stage/ResetPassword';

const DEFAULT_REDIRECT_TO = '/';

enum LoginState {
    NeedsLogin,
    PasswordReset,
    ForgotPassword,
}

export function CombinedLoginView(): JSX.Element {
    const [state, setState] = useState<LoginState>(LoginState.NeedsLogin);
    const [username, setUsername] = useState('');

    const currentUser = useCurrentUser();
    const locationState = useLocation().state as LoginLocationState | null;
    const [canNavigate, setCanNavigate] = useState<boolean>(!!currentUser);

    // Callbacks
    const doLoginComplete = React.useCallback(() => {
        // This allows us to do some effect if we want to before navigating where we need to go.
        setCanNavigate(true);
    }, []);

    const doNeedsPasswordReset = React.useCallback(() => {
        setState(LoginState.PasswordReset);
    }, []);

    const doForgotPasswordForm = useCallback((username: string) => {
        setState(LoginState.ForgotPassword);
        setUsername(username);
    }, []);

    const doRestartLogin = useCallback(() => {
        setState(LoginState.NeedsLogin);
    }, []);

    // Get the user off this view if they are logged in.
    // NOTE: This might just be handled upstream
    let redirectLoggedInUser: React.ReactNode = undefined;
    if (currentUser && canNavigate) {
        redirectLoggedInUser = (
            <Routes>
                <Route path='*' element={<Navigate to={locationState?.continue ?? DEFAULT_REDIRECT_TO} />} />
            </Routes>
        );
    }

    let view: React.ReactElement;

    switch (state) {
        case LoginState.NeedsLogin:
            view = (
                <LoginStage
                    onLoginComplete={doLoginComplete}
                    onNeedsPasswordReset={doNeedsPasswordReset}
                    onNeedsForgottenPasswordReset={doForgotPasswordForm}
                />
            );
            break;
        case LoginState.PasswordReset:
            view = <ResetPasswordView onLoginComplete={doLoginComplete} />;
            break;
        case LoginState.ForgotPassword:
            view = <ResetForgottenPasswordStage onResetComplete={doRestartLogin} username={username} />;
            break;
    }

    return (
        <>
            {redirectLoggedInUser}
            {view}
        </>
    );
}
