/**
 * @generated SignedSource<<0c48825437600de68be20d43fae4a34a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type StatusListCancelTestsMutation$variables = {
  ids: ReadonlyArray<string>;
};
export type StatusListCancelTestsMutation$data = {
  readonly cancelBatteryTestTests: boolean | null;
};
export type StatusListCancelTestsMutation = {
  response: StatusListCancelTestsMutation$data;
  variables: StatusListCancelTestsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ids",
        "variableName": "ids"
      }
    ],
    "kind": "ScalarField",
    "name": "cancelBatteryTestTests",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StatusListCancelTestsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StatusListCancelTestsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "142b2e2523b772389ed28732fcbbe6b7",
    "id": null,
    "metadata": {},
    "name": "StatusListCancelTestsMutation",
    "operationKind": "mutation",
    "text": "mutation StatusListCancelTestsMutation(\n  $ids: [ID!]!\n) {\n  cancelBatteryTestTests(ids: $ids)\n}\n"
  }
};
})();

(node as any).hash = "9599b6bccb660abb292e39d073f05c9d";

export default node;
