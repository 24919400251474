import React from 'react';
import { fetchQuery } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { AttributeFilter, AttributeFilterUI, FilterDefinition } from 'filters/common';
import { IEnvironment } from 'relay-runtime';

import { dynamic_loadDeviceAttributeNamesQuery } from './__generated__/dynamic_loadDeviceAttributeNamesQuery.graphql';
import { DeviceTableColumn } from './types';

export async function loadDynamicFilters(environment: IEnvironment): Promise<FilterDefinition<DeviceTableColumn>[]> {
    const data = await fetchQuery<dynamic_loadDeviceAttributeNamesQuery>(
        environment,
        graphql`
            query dynamic_loadDeviceAttributeNamesQuery {
                attributeNames(search: "", only: Device, limit: 1000, status: Active)
            }
        `,
        {}
    ).toPromise();

    return (
        data?.attributeNames.map(name => ({
            type: 'multi',
            name: `Tag ${name}`,
            category: 'Attribute',
            attributeName: name,
            component: () => props => <AttributeFilterUI {...props} attribute={name} type='Device' />,
            describeValue: (value: AttributeFilter) => value.value,
        })) ?? []
    );
}
