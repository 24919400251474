import React, { FC } from 'react';

import { Button } from '@accesstel/pcm-ui';

import { FirmwareStatusHorizontalBar } from 'views/FirmwareStatus';

import style from '../../style.module.css';
import { SectionHeading } from '../components';

export const FirmwareSection: FC = () => {
    return (
        <>
            <SectionHeading title='Firmware overview' />
            <div className='pt-8' data-testid='firmware-bar'>
                <FirmwareStatusHorizontalBar />
            </div>
            <div className='pt-8'>
                <Button buttonClasses={style.section_button_links} to='/status/firmware' buttonText='Open Firmware' />
            </div>
        </>
    );
};
