/**
 * @generated SignedSource<<b29af23d312c85ad9bf39e702c268467>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BatteryTechnologyType = "LeadAcidAGM" | "LeadAcidFlooded" | "LeadAcidGel" | "LeadAcidOther" | "LeadAcidVRLA" | "LithiumIon" | "LithiumIronPhosphate" | "LithiumPolymer" | "NickelCadmium" | "NickelMetalHydride" | "Other" | "Supercapacitor" | "%future added value";
export type BatteryTypeSortField = "Capacity" | "Manufacturer" | "Model" | "Technology" | "%future added value";
export type SortDirection = "Asc" | "Desc" | "%future added value";
export type BatteryTypeFilter = {
  attributes?: ReadonlyArray<AttributeFilters> | null;
  capacity?: FloatRangeFilter | null;
  cellCapacityRating?: FloatRangeFilter | null;
  cellReferenceTemperature?: FloatRangeFilter | null;
  cellsPerBloc?: IntRangeFilter | null;
  ids?: ReadonlyArray<string> | null;
  manufacturer?: ReadonlyArray<StringFilter> | null;
  model?: ReadonlyArray<StringFilter> | null;
  peukertsConstant?: FloatRangeFilter | null;
  technology?: ReadonlyArray<BatteryTechnologyType> | null;
};
export type StringFilter = {
  value: string;
};
export type FloatRangeFilter = {
  max: number;
  min: number;
};
export type IntRangeFilter = {
  max: number;
  min: number;
};
export type AttributeFilters = {
  filters: ReadonlyArray<StringFilter>;
  name: string;
};
export type BatteryTypeOrdering = {
  dir?: SortDirection | null;
  field: BatteryTypeSortField;
};
export type BatteryTypesTableQuery$variables = {
  filter?: BatteryTypeFilter | null;
  orderBy?: BatteryTypeOrdering | null;
  page?: number | null;
  search?: string | null;
  withCapacityRating?: boolean | null;
  withCellsPerBloc?: boolean | null;
  withPeukertsConstant?: boolean | null;
  withReferenceTemperature?: boolean | null;
};
export type BatteryTypesTableQuery$data = {
  readonly batteryTypes: {
    readonly data: ReadonlyArray<{
      readonly cells: {
        readonly capacity: number;
        readonly capacityRating?: number;
        readonly referenceTemperature?: number;
      };
      readonly cellsPerBloc?: number | null;
      readonly id: string;
      readonly manufacturer: string;
      readonly model: string;
      readonly peukertsConstant?: number | null;
      readonly technology: BatteryTechnologyType;
    }>;
    readonly pageInfo: {
      readonly hasNext: boolean | null;
      readonly hasPrevious: boolean | null;
      readonly page: number;
      readonly size: number;
      readonly total: number;
    };
    readonly total: number;
  };
  readonly overallBatteryTypes: {
    readonly total: number;
  };
};
export type BatteryTypesTableQuery = {
  response: BatteryTypesTableQuery$data;
  variables: BatteryTypesTableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v2 = {
  "defaultValue": 1,
  "kind": "LocalArgument",
  "name": "page"
},
v3 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "withCapacityRating"
},
v5 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "withCellsPerBloc"
},
v6 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "withPeukertsConstant"
},
v7 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "withReferenceTemperature"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filters",
        "variableName": "filter"
      },
      {
        "kind": "Variable",
        "name": "orderBy",
        "variableName": "orderBy"
      },
      {
        "kind": "Variable",
        "name": "page",
        "variableName": "page"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      }
    ],
    "concreteType": "PaginatedBatteryTypes",
    "kind": "LinkedField",
    "name": "batteryTypes",
    "plural": false,
    "selections": [
      (v8/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "BatteryType",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "model",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "manufacturer",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BatteryCellDefinition",
            "kind": "LinkedField",
            "name": "cells",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "capacity",
                "storageKey": null
              },
              {
                "condition": "withCapacityRating",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "capacityRating",
                    "storageKey": null
                  }
                ]
              },
              {
                "condition": "withReferenceTemperature",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "referenceTemperature",
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          },
          {
            "condition": "withCellsPerBloc",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cellsPerBloc",
                "storageKey": null
              }
            ]
          },
          {
            "condition": "withPeukertsConstant",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "peukertsConstant",
                "storageKey": null
              }
            ]
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "technology",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "page",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "size",
            "storageKey": null
          },
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasNext",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasPrevious",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": "overallBatteryTypes",
    "args": null,
    "concreteType": "PaginatedBatteryTypes",
    "kind": "LinkedField",
    "name": "batteryTypes",
    "plural": false,
    "selections": [
      (v8/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BatteryTypesTableQuery",
    "selections": (v9/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v7/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "BatteryTypesTableQuery",
    "selections": (v9/*: any*/)
  },
  "params": {
    "cacheID": "afdbeb817ccf53a1313fa2017d1ea9b3",
    "id": null,
    "metadata": {},
    "name": "BatteryTypesTableQuery",
    "operationKind": "query",
    "text": "query BatteryTypesTableQuery(\n  $page: Int = 1\n  $search: String = \"\"\n  $filter: BatteryTypeFilter\n  $orderBy: BatteryTypeOrdering\n  $withCapacityRating: Boolean = false\n  $withReferenceTemperature: Boolean = false\n  $withCellsPerBloc: Boolean = false\n  $withPeukertsConstant: Boolean = false\n) {\n  batteryTypes(page: $page, search: $search, filters: $filter, orderBy: $orderBy) {\n    total\n    data {\n      id\n      model\n      manufacturer\n      cells {\n        capacity\n        capacityRating @include(if: $withCapacityRating)\n        referenceTemperature @include(if: $withReferenceTemperature)\n      }\n      cellsPerBloc @include(if: $withCellsPerBloc)\n      peukertsConstant @include(if: $withPeukertsConstant)\n      technology\n    }\n    pageInfo {\n      page\n      size\n      total\n      hasNext\n      hasPrevious\n    }\n  }\n  overallBatteryTypes: batteryTypes {\n    total\n  }\n}\n"
  }
};
})();

(node as any).hash = "c194a2c9a6f186057b2a60e926f64e89";

export default node;
