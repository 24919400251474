import { DateTime } from 'luxon';

import { DateRange, PredefinedRange } from './common';

export const Today: PredefinedRange = {
    title: 'Today',
    asDateRange: () => [DateTime.now().startOf('day').toJSDate(), DateTime.now().endOf('day').toJSDate()],
};

export const Yesterday: PredefinedRange = {
    title: 'Yesterday',
    asDateRange: (): DateRange => {
        const yesterday = DateTime.now().minus({ day: 1 });
        return [yesterday.startOf('day').toJSDate(), yesterday.endOf('day').toJSDate()];
    },
};

export const LastWeek: PredefinedRange = {
    title: 'Last Week',
    asDateRange: (): DateRange => {
        const start = DateTime.now().minus({ week: 1 });
        return [start.startOf('day').toJSDate(), DateTime.now().endOf('day').toJSDate()];
    },
};

export const LastMonth: PredefinedRange = {
    title: 'Last Month',
    asDateRange: (): DateRange => {
        const start = DateTime.now().minus({ month: 1 });
        return [start.startOf('day').toJSDate(), DateTime.now().endOf('day').toJSDate()];
    },
};

export const LastYear: PredefinedRange = {
    title: 'Last Year',
    asDateRange: (): DateRange => {
        const start = DateTime.now().minus({ year: 1 });
        return [start.startOf('day').toJSDate(), DateTime.now().endOf('day').toJSDate()];
    },
};

export const Last5Years: PredefinedRange = {
    title: 'Last 5 Years',
    asDateRange: (): DateRange => {
        const start = DateTime.now().minus({ year: 5 });
        return [start.startOf('day').toJSDate(), DateTime.now().endOf('day').toJSDate()];
    },
};

export const MoreThan1Month: PredefinedRange = {
    title: 'More than 1 Month',
    asDateRange: (): DateRange => {
        const start = new Date(0, 0);
        start.setFullYear(0);
        const end = DateTime.now().minus({ month: 1 });
        return [start, end.toJSDate()];
    },
};

export const MoreThan5Years: PredefinedRange = {
    title: 'More than 5 Years',
    asDateRange: (): DateRange => {
        const start = new Date(0, 0);
        start.setFullYear(0);
        const end = DateTime.now().minus({ year: 5 });
        return [start, end.toJSDate()];
    },
};
