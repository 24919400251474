/**
 * @generated SignedSource<<3274277a579a5955cea62d8357658055>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SortDirection = "Asc" | "Desc" | "%future added value";
export type TaskSortField = "Date" | "DeviceCount" | "Name" | "Status" | "Type" | "%future added value";
export type TaskOrdering = {
  dir?: SortDirection | null;
  field: TaskSortField;
};
export type queries_inProgressTestsQuery$variables = {
  orderBy: TaskOrdering;
  pageSize: number;
};
export type queries_inProgressTestsQuery$data = {
  readonly tasks: {
    readonly data: ReadonlyArray<{
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"TestHorizontalCard_data">;
    }>;
    readonly pageInfo: {
      readonly hasNext: boolean | null;
      readonly hasPrevious: boolean | null;
      readonly page: number;
      readonly size: number;
      readonly total: number;
    };
    readonly total: number;
  };
};
export type queries_inProgressTestsQuery = {
  response: queries_inProgressTestsQuery$data;
  variables: queries_inProgressTestsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageSize"
},
v2 = [
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  },
  {
    "kind": "Variable",
    "name": "pageSize",
    "variableName": "pageSize"
  },
  {
    "kind": "Literal",
    "name": "state",
    "value": "InProgress"
  },
  {
    "kind": "Literal",
    "name": "type",
    "value": "BatteryTest"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "page",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "size",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNext",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPrevious",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "queries_inProgressTestsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "PaginatedTasks",
        "kind": "LinkedField",
        "name": "tasks",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "TestHorizontalCard_data"
                  }
                ],
                "type": "BatteryTest",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "queries_inProgressTestsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "PaginatedTasks",
        "kind": "LinkedField",
        "name": "tasks",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PaginatedDevices",
                    "kind": "LinkedField",
                    "name": "devices",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "creator",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "commencedTime",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TaskCountByState",
                        "kind": "LinkedField",
                        "name": "countByState",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "state",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "deviceCount",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "Task",
                    "abstractKey": "__isTask"
                  }
                ],
                "type": "BatteryTest",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "385c98cb718a54f6cf6a892266038029",
    "id": null,
    "metadata": {},
    "name": "queries_inProgressTestsQuery",
    "operationKind": "query",
    "text": "query queries_inProgressTestsQuery(\n  $pageSize: Int!\n  $orderBy: TaskOrdering!\n) {\n  tasks(pageSize: $pageSize, type: BatteryTest, state: \"InProgress\", orderBy: $orderBy) {\n    total\n    data {\n      __typename\n      id\n      ... on BatteryTest {\n        ...TestHorizontalCard_data\n      }\n    }\n    pageInfo {\n      page\n      size\n      total\n      hasNext\n      hasPrevious\n    }\n  }\n}\n\nfragment TaskProgressBar_task on Task {\n  __isTask: __typename\n  countByState {\n    state\n    deviceCount\n  }\n}\n\nfragment TestHorizontalCard_data on BatteryTest {\n  id\n  name\n  type\n  devices {\n    total\n  }\n  creator {\n    name\n    email\n  }\n  commencedTime\n  ...TaskProgressBar_task\n}\n"
  }
};
})();

(node as any).hash = "a04737f45b7f5754241243777af3c7fc";

export default node;
