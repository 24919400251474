/**
 * @generated SignedSource<<f84fb0314898117aa05c3b6aca121828>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StringRow_data$data = {
  readonly age: string | null;
  readonly cycles: number | null;
  readonly id: string;
  readonly installDate: string | null;
  readonly localId: string;
  readonly type: {
    readonly cells: {
      readonly capacity: number;
    };
    readonly manufacturer: string;
    readonly model: string;
  };
  readonly " $fragmentType": "StringRow_data";
};
export type StringRow_data$key = {
  readonly " $data"?: StringRow_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"StringRow_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StringRow_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "localId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "installDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "age",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cycles",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BatteryType",
      "kind": "LinkedField",
      "name": "type",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manufacturer",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "model",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BatteryCellDefinition",
          "kind": "LinkedField",
          "name": "cells",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "capacity",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DeviceBatteryString",
  "abstractKey": null
};

(node as any).hash = "fa444da24564235e1325c0946ddc4053";

export default node;
