/* eslint-disable no-irregular-whitespace */
import React, { FC } from 'react';

import classNames from 'classnames';

import style from './style.module.css';

export interface SkeletonTextProps {
    className?: string;
    characters: number;

    size?: 'heading' | 'subheading' | 'normal' | number;
}

export const SkeletonText: FC<SkeletonTextProps> = ({ className, characters, size }) => {
    let sizeClass;
    if (typeof size === 'string') {
        sizeClass = style[`size_${size}`];
    }
    let sizeStyle;
    if (typeof size === 'number') {
        sizeStyle = `${size}rem`;
    }

    return (
        <div
            className={classNames(className, style.text_skeleton, sizeClass)}
            style={{ width: `${characters}ch`, fontSize: sizeStyle }}
        >
             
        </div>
    );
};
