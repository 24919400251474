/**
 * @generated SignedSource<<b9edf362868985ebe353a05a22e67cdc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ViewBatteryTestResultCardCancelTestsMutation$variables = {
  testIDs: ReadonlyArray<string>;
};
export type ViewBatteryTestResultCardCancelTestsMutation$data = {
  readonly cancelBatteryTestTests: boolean | null;
};
export type ViewBatteryTestResultCardCancelTestsMutation = {
  response: ViewBatteryTestResultCardCancelTestsMutation$data;
  variables: ViewBatteryTestResultCardCancelTestsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "testIDs"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ids",
        "variableName": "testIDs"
      }
    ],
    "kind": "ScalarField",
    "name": "cancelBatteryTestTests",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ViewBatteryTestResultCardCancelTestsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ViewBatteryTestResultCardCancelTestsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2a8355bbd7277a707ae2e753555d9b57",
    "id": null,
    "metadata": {},
    "name": "ViewBatteryTestResultCardCancelTestsMutation",
    "operationKind": "mutation",
    "text": "mutation ViewBatteryTestResultCardCancelTestsMutation(\n  $testIDs: [ID!]!\n) {\n  cancelBatteryTestTests(ids: $testIDs)\n}\n"
  }
};
})();

(node as any).hash = "677c0d8c9d2922708f64d81a8d7015cc";

export default node;
