import { object, string } from 'yup';

import { AttributeFormValues } from './schema';

export const CustomAttributesValidationSchema = object({
    name: string().required('A name must be provided'),
    value: string().required('A value must be provided'),
});

export function getDuplicateAttributeIndices(attributes: AttributeFormValues[]): number[] {
    const names = attributes.map(attribute => attribute.name);
    const visited = new Set<string>();

    const duplicateIndices: number[] = [];
    names.forEach((name, index) => {
        if (visited.has(name)) {
            duplicateIndices.push(index);
            return;
        }

        visited.add(name);
    });

    return duplicateIndices;
}
