import React, { ReactElement, useMemo } from 'react';

import graphql from 'babel-plugin-relay/macro';
import { FilterRange } from 'components/FilterRange';
import { RangeFilter } from 'filters/common';
import { useQuery } from 'lib/query-helpers';

import {
    SiteDistributionType,
    SiteFilterRange_siteDistributionQuery,
} from './__generated__/SiteFilterRange_siteDistributionQuery.graphql';

export interface SiteFilterRangeProps {
    title: string;
    distributionType: SiteDistributionType; // add other type to this as needed
    countLabel?: [string, string];
    range?: [number, number];
    current: RangeFilter | null | undefined;
    discrete?: boolean;
    showBack?: boolean;
    onClearOrBack: () => void;
    onClose: () => void;
    onSubmit: (items: RangeFilter | null) => void;
    formatterDisplay?: (value: number) => string;
    formatterAPI?: (value: string) => number;
}

export function SiteFilterRange({
    title,
    distributionType,
    countLabel = ['Min Count', 'Max Count'],
    range,
    current,
    discrete,
    showBack,
    onClearOrBack,
    onClose,
    onSubmit,
    formatterDisplay,
    formatterAPI,
}: SiteFilterRangeProps): ReactElement {
    const { data } = useQuery<SiteFilterRange_siteDistributionQuery>(
        graphql`
            query SiteFilterRange_siteDistributionQuery($type: SiteDistributionType!) {
                siteDistribution(type: $type) {
                    distribution {
                        key
                        value
                    }
                    minimum
                    maximum
                }
            }
        `,
        {
            type: distributionType,
        }
    );

    const distributionRange = useMemo<[number, number] | null>(() => {
        if (!data || data.siteDistribution.minimum == null || data.siteDistribution.maximum == null) {
            return null;
        }

        return [data.siteDistribution.minimum, data.siteDistribution.maximum];
    }, [data]);

    return (
        <FilterRange
            title={title}
            onSubmit={onSubmit}
            onClose={onClose}
            limit={range}
            current={current}
            discrete={discrete}
            showBack={showBack}
            onClearOrBack={onClearOrBack}
            countLabel={countLabel}
            distribution={data?.siteDistribution}
            distributionRange={distributionRange}
            formatForDisplay={formatterDisplay}
            parseFromInput={formatterAPI}
        />
    );
}
