import { createContext, useContext } from 'react';

import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';

import { logError } from './log';
import { SuspenseObject } from './suspense';

export enum AuthRole {
    AssetsReadOnly = 'AssetsR',
    AssetsReadWrite = 'AssetsRW',
    TasksBatteryHealthReadOnly = 'TasksBatteryHealthR',
    TasksBatteryHealthReadWrite = 'TasksBatteryHealthRW',
    ReadOnly = 'ReadOnly',
    Administrator = 'Administrator',
}

export function setup(): void {
    const baseURL = `${window.location.protocol}//${window.location.host}`;
    // allow cognito to be proxied through our api, so we don't have any third party domains used
    let endpoint: string | undefined;
    if (process.env.REACT_APP_COGNITO_API) {
        endpoint = process.env.REACT_APP_COGNITO_API;
    }
    Auth.configure({
        userPoolId: process.env.REACT_APP_COGNITO_POOL,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT,
        oauth: {
            domain: process.env.REACT_APP_COGNITO_DOMAIN,
            redirectSignIn: `${baseURL}`,
            redirectSignOut: `${baseURL}/logout`,
            responseType: 'code',
            scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
        },
        endpoint,
    });
}

export enum AuthResultType {
    Success = 'success',
    SuccessNeedNewPassword = 'success_newpass',
    SuccessNeedPasswordReset = 'success_password_reset',
    FailureBadAuth = 'fail_auth',
    FailureBadCode = 'fail_code',
    FailureOther = 'fail',
}

export interface User {
    username: string;
    name: string;
    roles?: string[];
}

export type AuthResult = [AuthResultType, string | undefined];

let pendingUser: CognitoUser | undefined;

// The context which allows everything on the site to know the current user and when that changes.
export interface AuthContextType {
    isLoggedIn: boolean;
    currentUser: User | null;
    initialErrorMessage: string | null;
}

export const AuthContext = createContext<AuthContextType>({
    isLoggedIn: false,
    currentUser: null,
    initialErrorMessage: null,
});

/**
 * Attempts to log into the system.
 * If the result is success, the user can be obtained using @see getCurrentUser
 * @param username Username of user
 * @param password Password of user
 * @returns A promise of an AuthResult.
 */
export async function login(username: string, password: string): Promise<AuthResult> {
    try {
        const user = await Auth.signIn(username, password);
        pendingUser = user;

        if (user.challengeName) {
            switch (user.challengeName) {
                case 'NEW_PASSWORD_REQUIRED':
                    // First time login or password reset
                    return [AuthResultType.SuccessNeedNewPassword, undefined];
                default:
                    // Unsupported challenge
                    pendingUser = undefined;
                    return [AuthResultType.FailureOther, undefined];
            }
        } else {
            return [AuthResultType.Success, undefined];
        }
    } catch (error) {
        if (error.code === 'PasswordResetRequiredException') {
            return [AuthResultType.SuccessNeedPasswordReset, error.message];
        }
        if (error.code === 'NotAuthorizedException' || error.code === 'UserNotFoundException') {
            return [AuthResultType.FailureBadAuth, error.message];
        }
        return [AuthResultType.FailureOther, error.message];
    }
}

/**
 * Attempts to login using an identity provider.
 * This will send the user off to the providers login page.
 * This function will not return.
 * The user can be obtained using @see getCurrentUser if login was successful
 * @param provider The id of the identity provider
 */
export async function loginWithFederation(provider: string): Promise<void> {
    await Auth.federatedSignIn({ customProvider: provider });
}

/**
 * Provides a new password for the user account.
 * This is only to be used when login requires a new pasword.
 * @param newPassword The password
 * @returns A Promise for an AuthResult.
 */
export async function supplyNewPassword(newPassword: string): Promise<AuthResult> {
    if (!pendingUser) {
        throw Error('No pending user');
    }

    try {
        // NOTE: This does not currently support supplying required attributes
        // It is currently assumed that these will be set by the admin
        const user = await Auth.completeNewPassword(pendingUser, newPassword, {});

        if (user.challengeName) {
            // Unsupported challenge
            // eslint-disable-next-line require-atomic-updates
            pendingUser = undefined;
            return [AuthResultType.FailureOther, undefined];
        } else {
            return [AuthResultType.Success, undefined];
        }
    } catch (error) {
        if (error.code === 'InvalidPasswordException') {
            return [AuthResultType.FailureBadAuth, error.message];
        }
        return [AuthResultType.FailureOther, error.message];
    }
}

/**
 * Confirms a password reset after the user has received a verification code
 * @param username The username of the user
 * @param code The verification code
 * @param newPassword The new password for the user
 * @returns the next state
 */
export async function confirmPasswordReset(username: string, code: string, newPassword: string): Promise<AuthResult> {
    try {
        await Auth.forgotPasswordSubmit(username, code, newPassword);
        return [AuthResultType.Success, undefined];
    } catch (error) {
        if (error.code === 'InvalidPasswordException') {
            return [AuthResultType.FailureBadAuth, error.message];
        }
        if (error.code === 'ExpiredCodeException') {
            return [AuthResultType.FailureBadCode, error.message];
        }
        return [AuthResultType.FailureOther, error.message];
    }
}

/**
 * Logs the user out of the system.
 * Note, this does not return whether this was successful.
 * it should be assumed that this always succeeds.
 */
export async function logout(): Promise<AuthResult> {
    return Auth.signOut();
}

/**
 * Retrieves the current user if authenticated.
 * @returns A promise to the @see User or null.
 */
export async function getCurrentUser(): Promise<User | null> {
    try {
        const session = await Auth.currentSession();
        const auth = await Auth.currentAuthenticatedUser();
        const groups = session.getAccessToken().payload['cognito:groups'];

        // NOTE: Any additional attributes should be captured by this interface
        return {
            username: auth.attributes.email,
            name: auth.attributes.name || '',
            roles: groups,
        };
    } catch (error) {
        return null;
    }
}

export function fetchCurrentUser(): SuspenseObject<User | null> {
    return new SuspenseObject(getCurrentUser());
}

/**
 * Retrieves the authentication token to pass to API requests
 */
export async function getAuthToken(): Promise<string | null> {
    try {
        const session = await Auth.currentSession();

        if (session.isValid()) {
            return session.getAccessToken().getJwtToken();
        } else {
            return null;
        }
    } catch (error) {
        if (error === 'No current user') {
            return null;
        }

        logError('Unhandled / unexpected error in getAuthToken', error);
        return null;
    }
}

/**
 * Retrieves the current user.
 * If the user is not authenticated, then no user is returned.
 */
export function useCurrentUser(): User | null {
    const context = useContext(AuthContext);
    return context.currentUser;
}

export function useInitialErrorMessage(): string | null {
    const context = useContext(AuthContext);
    return context.initialErrorMessage;
}

/**
 * Checks if the user has at least one of the specified roles.
 * Note: You should really use the useUserPermissions hook if you are trying to
 * see what the user can access.
 * @param roles The roles to check
 * @returns True if the user has at least one of those roles
 * @see useUserPermissions
 */
export function useUserAuthorization(...roles: AuthRole[]): boolean {
    const user = useCurrentUser();
    if (!user || user.roles === undefined) {
        return false;
    }

    return roles.some(role => user.roles!.includes(role));
}

export interface UserPermissions {
    hasGeneralRead: boolean;
    hasAssetsRead: boolean;
    hasAssetsWrite: boolean;
    hasTasksRead: boolean;
    hasTasksWrite: boolean;
    hasAdministration: boolean;
}

/**
 * Gets the users effective permissions.
 * This takes care of the detail about what roles produce what permissions.
 * @returns An object containing all user permissions levels
 */
export function useUserPermissions(): UserPermissions {
    const user = useCurrentUser();
    if (!user || user.roles === undefined) {
        return {
            hasAdministration: false,
            hasAssetsRead: false,
            hasAssetsWrite: false,
            hasGeneralRead: false,
            hasTasksRead: false,
            hasTasksWrite: false,
        };
    }

    const permissions: UserPermissions = {
        hasAdministration: user.roles.some(role => role === AuthRole.Administrator),
        hasAssetsRead: user.roles.some(role => role === AuthRole.AssetsReadOnly || role === AuthRole.AssetsReadWrite),
        hasAssetsWrite: user.roles.some(role => role === AuthRole.AssetsReadWrite),
        hasGeneralRead: user.roles.some(role => role === AuthRole.ReadOnly),
        hasTasksRead: user.roles.some(
            role => role === AuthRole.TasksBatteryHealthReadOnly || role === AuthRole.TasksBatteryHealthReadWrite
        ),
        hasTasksWrite: user.roles.some(role => role === AuthRole.TasksBatteryHealthReadWrite),
    };

    return permissions;
}
