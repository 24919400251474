import { AttributeFormValues } from 'views/manage/common/schema';

export interface SiteFormValues {
    name: string;
    type: string;
    address: string;
    state: string;
    postcode: string | null;
    latitude: string;
    longitude: string;
    attributes: AttributeFormValues[];
}

export function createDefaultSiteFormValues(): SiteFormValues {
    return {
        name: '',
        type: '',
        address: '',
        state: '',
        postcode: '',
        latitude: '',
        longitude: '',
        attributes: [],
    };
}
