import graphql from 'babel-plugin-relay/macro';
import { set } from 'lodash';
import { IEnvironment, commitMutation } from 'relay-runtime';

import {
    BatteryTypeIn,
    savingCreateBatteryTypeMutation,
    savingCreateBatteryTypeMutation$data,
} from './__generated__/savingCreateBatteryTypeMutation.graphql';
import {
    BatteryTypeUpdateIn,
    savingEditBatteryTypeMutation,
} from './__generated__/savingEditBatteryTypeMutation.graphql';

export type { BatteryTypeIn, BatteryTypeUpdateIn };

export async function addNewBatteryType(type: BatteryTypeIn, environment: IEnvironment): Promise<string> {
    return new Promise((resolve, reject) => {
        commitMutation<savingCreateBatteryTypeMutation>(environment, {
            mutation: CreateBatteryTypeMutation,
            variables: { type },
            onCompleted: (response, error) => {
                if (response.addBatteryType?.id) {
                    resolve(response.addBatteryType.id);
                }
                if (response.addBatteryType?.problems) {
                    reject(response.addBatteryType.problems);
                }

                reject(error);
            },
            onError: reject,
        });
    });
}

export async function updateBatteryType(
    id: string,
    type: BatteryTypeUpdateIn,
    environment: IEnvironment
): Promise<string> {
    return new Promise((resolve, reject) => {
        commitMutation<savingEditBatteryTypeMutation>(environment, {
            mutation: EditBatteryTypeMutation,
            variables: { id, type },
            onCompleted: (response, error) => {
                if (response.editBatteryType?.id) {
                    resolve(response.editBatteryType.id);
                }
                if (response.editBatteryType?.problems) {
                    reject(response.editBatteryType.problems);
                }

                reject(error);
            },
            onError: reject,
        });
    });
}

type BatteryTypeProblemResponse = NonNullable<
    NonNullable<savingCreateBatteryTypeMutation$data['addBatteryType']>['problems']
>[number];

export function decodeBatteryTypeApiErrors(problems: BatteryTypeProblemResponse[]) {
    const errors = {};

    for (const problem of problems) {
        switch (problem.type) {
            case 'MissingManufacturerName':
                set(errors, 'manufacturer', 'Manufacturer is a required field');
                break;
            case 'MissingModelName':
                set(errors, 'model', 'Model is a required field');
                break;
            case 'DuplicateModelName':
                set(errors, 'model', 'Name already used by another battery type');
                break;
            case 'InvalidCellCapacity':
                set(errors, 'cells.capacity', 'Capacity in not valid');
                break;
            case 'InvalidCellCapacityRating':
                set(errors, 'cells.capacityRating', 'Capacity rating is not valid');
                break;
            case 'InvalidCellMaximumAllowedVoltage':
                set(errors, 'cells.maximumAllowedVoltage', 'Maximum voltage is not valid');
                break;
            case 'InvalidCellMaximumBoostVoltage':
                set(errors, 'cells.maximumBoostVoltage', 'Maximum boost voltage is not valid');
                break;
            case 'InvalidCellMaximumFloatVoltage':
                set(errors, 'cells.maximumFloatVoltage', 'Maximum float voltage is not valid');
                break;
            case 'InvalidCellMinimumAllowedVoltage':
                set(errors, 'cells.minimumAllowedVoltage', 'Minimum voltage is not valid');
                break;
            case 'InvalidCellMinimumBoostVoltage':
                set(errors, 'cells.minimumBoostVoltage', 'Minimum boost voltage is not valid');
                break;
            case 'InvalidCellMinimumFloatVoltage':
                set(errors, 'cells.minimumFloatVoltage', 'Minimum float voltage is not valid');
                break;
            case 'InvalidCellReferenceTemperature':
                set(errors, 'cells.referenceTemperature', 'Reference temperature is not valid');
                break;
            case 'InvalidCellTypicalVoltage':
                set(errors, 'cells.typicalVoltage', 'Typical voltage is not valid');
                break;
            case 'InvalidCellsPerBloc':
                set(errors, 'cellsPerBloc', 'Cells per block is not valid');
                break;
            case 'InvalidPeukertsConstant':
                set(errors, 'peukertsConstant', 'Peukerts constant is not valid');
                break;
            case 'DuplicateDischargeTable':
                set(errors, 'dischargeTables', 'Discharge tables must be unique');
                break;
            case 'EmptyDischargeTable':
                set(errors, 'dischargeTables', 'Discharge table is not valid');
                break;
            case 'InvalidTableDischargeTime':
                set(errors, 'dischargeTables', 'Discharge table time is not valid');
                break;
            case 'InvalidTableDischargeValue':
                set(errors, 'dischargeTables', 'Discharge table value is not valid');
                break;
            case 'InvalidTableEodv':
                set(errors, 'dischargeTables', 'Discharge table end of discharge voltage is not valid');
                break;
            case 'Noop':
                // Not an issue
                break;
        }
    }

    return errors;
}

const CreateBatteryTypeMutation = graphql`
    mutation savingCreateBatteryTypeMutation($type: BatteryTypeIn!) {
        addBatteryType(type: $type) {
            ... on BatteryType {
                id
            }
            ... on BatteryTypeProblemResponse {
                problems {
                    table
                    row
                    type
                }
            }
        }
    }
`;

const EditBatteryTypeMutation = graphql`
    mutation savingEditBatteryTypeMutation($id: ID!, $type: BatteryTypeUpdateIn!) {
        editBatteryType(id: $id, type: $type) {
            ... on BatteryType {
                id
            }
            ... on BatteryTypeProblemResponse {
                problems {
                    table
                    row
                    type
                }
            }
        }
    }
`;
