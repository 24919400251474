import { round } from 'lib/number';

export function numberToLocaleString(input: number | null): string {
    if (input === null) {
        return '-';
    } else {
        return input.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 0 });
    }
}

export function percentageToStringCapped(input: number | null): string {
    if (input == null) {
        return '-%';
    }

    if (input >= 100) {
        return '100%';
    } else if (input > 99) {
        return '>99%';
    } else if (input === 0) {
        return '0%';
    } else if (input < 1) {
        return '<1%';
    } else {
        return input.toFixed(0) + '%';
    }
}

export function wattToKillowattString(watt: number | null | undefined): string {
    if (watt && watt !== 0) {
        if (watt >= 1000) {
            return `${round(watt / 1000, 1)}kW`;
        } else {
            return `${Math.round(watt)}W`;
        }
    } else {
        return '-W';
    }
}
