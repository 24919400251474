import type { BatteryStatus } from 'lib/__generated__/queries_getAllDeviceIdsQuery.graphql';

export function batteryStatusToString(commonStatus: BatteryStatus | 'Other' | null): string {
    if (!commonStatus) {
        return 'Various';
    }

    switch (commonStatus) {
        case 'BoostCharging':
            return 'Boost Charging';
        case 'Charging':
        case 'FloatCharging':
            return 'Float Charging';
        case 'Discharging':
            return 'Discharging';
        case 'Disconnected':
            return 'Disconnected';
        case 'Idle':
            return 'Idle';
        case 'Missing':
            return 'Missing';
        case 'Other':
            return 'Other';
        default:
        case 'Unknown':
            return 'Unknown';
    }
}
