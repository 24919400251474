import React, { FC } from 'react';
import { useRelayEnvironment } from 'react-relay';
import { fetchQuery } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { FilterSearchSelect } from 'components/FilterSearchSelect';
import { StringFilter } from 'lib/__generated__/queries_getAllDeviceIdsQuery.graphql';
import { IEnvironment } from 'relay-runtime';

import { CommonFilterProps } from '../../common/types';
import { BatteryTestDeviceSiteNameFilter } from '../types';
import { SiteNameFilterResult } from './SiteNameFilterResult';
import { SiteNameFilterUI_taskDeviceSiteNameSearchQuery } from './__generated__/SiteNameFilterUI_taskDeviceSiteNameSearchQuery.graphql';

export type BatteryTestDeviceSiteNameFilterUIProps = CommonFilterProps<BatteryTestDeviceSiteNameFilter[]>;

export const SiteNameFilterUI: FC<BatteryTestDeviceSiteNameFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    const environment = useRelayEnvironment();

    return (
        <FilterSearchSelect
            active={current}
            title='Filter by site'
            placeholder='Enter site name'
            renderItem={item => <SiteNameFilterResult filter={item} />}
            renderItemSimple={item => item.name}
            showBack={hasPrevious}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onSearch={input => doSearch(environment, input, undefined, 5)}
            onClose={onClose}
            createDefaultItem={input => {
                if (input.trim().length > 0) {
                    return createWildcardFilter(input);
                } else {
                    return null;
                }
            }}
        />
    );
};

export async function doSearch(
    environment: IEnvironment,
    input: string,
    deviceName: StringFilter[] | undefined,
    maxResults: number
): Promise<BatteryTestDeviceSiteNameFilter[]> {
    try {
        // TODO: figure out a query that limits the site names for related devices
        const results = await fetchQuery<SiteNameFilterUI_taskDeviceSiteNameSearchQuery>(
            environment,
            graphql`
                query SiteNameFilterUI_taskDeviceSiteNameSearchQuery(
                    $input: String!
                    $deviceName: [StringFilter!]
                    $maxResults: Int!
                ) {
                    sites(filters: { name: [{ value: $input }], deviceName: $deviceName }, pageSize: $maxResults) {
                        data {
                            id
                            name
                            address {
                                state
                            }
                        }
                    }
                }
            `,
            {
                input,
                deviceName,
                maxResults: maxResults - 1,
            }
        ).toPromise();

        const sites = results?.sites.data;

        if (!sites) {
            return [];
        }

        const output = sites.map<BatteryTestDeviceSiteNameFilter>(site => ({
            type: 'result',
            id: site.id,
            name: site.name,
            state: site.address.state,
        }));

        if (input.trim().length > 0) {
            if (output.length >= maxResults) {
                output[output.length - 1] = createWildcardFilter(input);
            } else {
                output.push(createWildcardFilter(input));
            }
        }

        return output;
    } catch (error) {
        return [];
    }
}

function createWildcardFilter(input: string): BatteryTestDeviceSiteNameFilter {
    return {
        type: 'special',
        name: input.trim(),
        wildcard: true,
    };
}
