import { useReducer } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DomainAbsolute } from '@accesstel/pcm-ui';

import { DateRangeFilter } from 'components/FilterDateSelect/common';
import { LastWeek } from 'components/FilterDateSelect/presets';
import isEqual from 'lodash.isequal';
import { DateTime, Duration } from 'luxon';

import { MetricsExploreContentQuery$data } from './__generated__/MetricsExploreContentQuery.graphql';
import { parseMetricDefinition } from './lib/metric-definition';
import { DeviceIdParam, MetricParam, MinimumZoomLevel } from './settings';
import { SelectedMetric, ViewState } from './types';

export enum ActionType {
    SetSelectedMetrics,
    SetSelectedDevices,
    SetTimeRange,
    ShowDeviceSelectionModal,
    HideDeviceSelectionModal,
    ShowMetricSelectionModal,
    HideMetricSelectionModal,
    ZoomTo,
    ResetZoom,
}

export type Action =
    | { type: ActionType.SetSelectedMetrics; metrics: SelectedMetric[] }
    | { type: ActionType.SetSelectedDevices; devices: string[] }
    | { type: ActionType.SetTimeRange; range: DateRangeFilter }
    | { type: ActionType.ShowDeviceSelectionModal }
    | { type: ActionType.HideDeviceSelectionModal }
    | { type: ActionType.ShowMetricSelectionModal }
    | { type: ActionType.HideMetricSelectionModal }
    | { type: ActionType.ZoomTo; range: DomainAbsolute<Date> }
    | { type: ActionType.ResetZoom };

function reducer(state: ViewState, action: Action): ViewState {
    switch (action.type) {
        case ActionType.SetSelectedMetrics:
            if (isEqual(state.selectedMetrics, action.metrics)) {
                return state;
            }

            return {
                ...state,
                selectedMetrics: action.metrics,
            };
        case ActionType.SetSelectedDevices:
            if (isEqual(state.selectedDevices, action.devices)) {
                return state;
            }

            return {
                ...state,
                selectedDevices: action.devices,
            };
        case ActionType.SetTimeRange:
            if (isEqual(state.timeRange, action.range)) {
                return state;
            }

            return {
                ...state,
                timeRange: action.range,
                zoomedTimeRange: action.range.range,
            };
        case ActionType.ShowDeviceSelectionModal:
            if (state.showDeviceSelectionModal) {
                return state;
            }

            return {
                ...state,
                showDeviceSelectionModal: true,
            };
        case ActionType.HideDeviceSelectionModal:
            if (!state.showDeviceSelectionModal) {
                return state;
            }

            return {
                ...state,
                showDeviceSelectionModal: false,
            };
        case ActionType.ShowMetricSelectionModal:
            if (state.showMetricSelectionModal) {
                return state;
            }

            return {
                ...state,
                showMetricSelectionModal: true,
            };
        case ActionType.HideMetricSelectionModal:
            if (!state.showMetricSelectionModal) {
                return state;
            }

            return {
                ...state,
                showMetricSelectionModal: false,
            };
        case ActionType.ZoomTo: {
            const totalDuration = Duration.fromMillis(action.range[1].getTime() - action.range[0].getTime());

            let isAtMaxZoom = false;
            // Don't allow zooming in too far
            if (totalDuration <= MinimumZoomLevel) {
                action.range = [
                    action.range[0],
                    DateTime.fromJSDate(action.range[0]).plus(MinimumZoomLevel).toJSDate(),
                ];
                isAtMaxZoom = true;
            }

            if (isEqual(state.zoomedTimeRange, action.range) && state.isAtMaxZoom === isAtMaxZoom) {
                return state;
            }

            return {
                ...state,
                zoomedTimeRange: action.range,
                isZoomed: true,
                isAtMaxZoom,
            };
        }
        case ActionType.ResetZoom:
            if (isEqual(state.zoomedTimeRange, state.timeRange.range) && !state.isAtMaxZoom && !state.isZoomed) {
                return state;
            }

            return {
                ...state,
                zoomedTimeRange: state.timeRange.range,
                isZoomed: false,
                isAtMaxZoom: false,
            };
        default:
            return state;
    }
}

export function useViewReducer(metadata: MetricsExploreContentQuery$data) {
    const [searchParams] = useSearchParams();

    return useReducer(reducer, undefined, () => {
        // Load the selected devices from the URL
        const deviceIds = searchParams.getAll(DeviceIdParam).flatMap(deviceId => deviceId.split(','));
        const selectedDevices = deviceIds.filter(deviceId =>
            metadata.devices.data.find(device => device.id === deviceId)
        );

        // Load the selected metrics from the URL
        const rawMetricDefinitions = searchParams.getAll(MetricParam).flatMap(metric => metric.split(','));

        let selectedMetrics: SelectedMetric[];
        if (rawMetricDefinitions.length > 0) {
            selectedMetrics = rawMetricDefinitions
                .map(parseMetricDefinition)
                .filter(metric => metric !== null) as SelectedMetric[];
        } else {
            selectedMetrics = [];
        }

        const initialState: ViewState = {
            selectedMetrics,
            selectedDevices,
            timeRange: {
                type: 'predefined',
                preset: LastWeek.title,
                range: LastWeek.asDateRange(),
            },
            zoomedTimeRange: LastWeek.asDateRange(),
            isZoomed: false,
            isAtMaxZoom: false,
            showDeviceSelectionModal: false,
            showMetricSelectionModal: false,
        };

        return initialState;
    });
}
