import React from 'react';
import { fetchQuery } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { AttributeFilter, AttributeFilterUI, FilterDefinition } from 'filters/common';
import { IEnvironment } from 'relay-runtime';

import { dynamic_loadBatteryTypeAttributeNamesQuery } from './__generated__/dynamic_loadBatteryTypeAttributeNamesQuery.graphql';
import { BatteryTypeTableColumn } from './types';

export async function loadDynamicFilters(
    environment: IEnvironment
): Promise<FilterDefinition<BatteryTypeTableColumn>[]> {
    const data = await fetchQuery<dynamic_loadBatteryTypeAttributeNamesQuery>(
        environment,
        graphql`
            query dynamic_loadBatteryTypeAttributeNamesQuery {
                attributeNames(search: "", only: Battery, limit: 1000, status: Active)
            }
        `,
        {}
    ).toPromise();

    return (
        data?.attributeNames.map(name => ({
            type: 'multi',
            name: `Tag ${name}`,
            category: 'Attribute',
            attributeName: name,
            component: () => props => <AttributeFilterUI {...props} attribute={name} type='Battery' />,
            describeValue: (value: AttributeFilter) => value.value,
        })) ?? []
    );
}
