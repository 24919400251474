import { object, string } from 'yup';

export const LoginFormValidationSchema = object({
    username: string().trim().email('Invalid username').required('Must provide username'),
    password: string().trim().required('Must provide password'),
});

export interface LoginFormValues {
    username: string;
    password: string;
}

export const DefaultLoginFormValues: LoginFormValues = {
    username: '',
    password: '',
};
