import React, { FC } from 'react';

import { LogoStackedReversedExtended } from '@accesstel/pcm-ui';

import style from './loading.module.css';

export const GlobalLoading: FC = () => {
    return (
        <div className='login-view'>
            <div className={style.loading_container}>
                <img src={LogoStackedReversedExtended} alt='accesstel logo' width={280} height={280} />
                <div className={style.loading_text}>
                    Loading accata
                    <div>.</div>
                    <div>.</div>
                    <div>.</div>
                </div>
            </div>
        </div>
    );
};
