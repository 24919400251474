import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { useRelayEnvironment } from 'react-relay';

import { useToast } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { IEnvironment, commitMutation } from 'relay-runtime';
import { DeleteModal } from 'views/manage/components';

import {
    DeleteDevicesModalMutation,
    DeleteDevicesModalMutation$variables,
    DeviceRemoveResponse,
} from './__generated__/DeleteDevicesModalMutation.graphql';

interface DeleteDevicesModalProps {
    selectedDevices: string[];
    onDone: () => void;
    setShowDeleteModal: Dispatch<SetStateAction<boolean>>;
    showDeleteModal: boolean;
    tableRetry: () => void;
}

export const DeleteDevicesModal: FC<DeleteDevicesModalProps> = ({
    selectedDevices,
    onDone,
    setShowDeleteModal,
    showDeleteModal,
    tableRetry,
}) => {
    const environment = useRelayEnvironment();
    const { show } = useToast();
    const deviceText = selectedDevices.length === 1 ? 'Device' : 'Devices';
    const [isProcessing, setIsProcessing] = useState(false);

    const handleDeleteDevices = () => {
        setIsProcessing(true);

        deleteDevices(selectedDevices, environment)
            .then(response => {
                setIsProcessing(false);
                setShowDeleteModal(false);
                if (response === 'Success') {
                    show({
                        text: `Successfully deleted ${deviceText.toLowerCase()}`,
                        variant: 'info',
                    });
                }

                if (response === 'UnknownDevice') {
                    show({
                        text: `Unknown ${deviceText.toLowerCase()}`,
                        variant: 'error',
                    });
                }

                if (response === 'PartialSuccess') {
                    show({
                        text: `Unable to delete some devices`,
                        variant: 'error',
                    });
                }

                onDone();
                setTimeout(() => {
                    // FIXME: this delay is present as elasticsearch isn't waiting for the refresh on delete
                    tableRetry();
                }, 1000);
            })
            .catch(() => {
                setIsProcessing(false);
                setShowDeleteModal(false);
                show({
                    text: `Error deleting ${deviceText.toLowerCase()}`,
                    variant: 'error',
                });
            });
    };

    return (
        <DeleteModal
            itemText={deviceText}
            showDeleteModal={showDeleteModal}
            setShowDeleteModal={setShowDeleteModal}
            handleDelete={handleDeleteDevices}
            isProcessing={isProcessing}
        />
    );
};

function deleteDevices(deviceIds: string[], environment: IEnvironment): Promise<DeviceRemoveResponse> {
    const variables: DeleteDevicesModalMutation$variables = {
        ids: deviceIds,
    };

    return new Promise((resolve, reject) => {
        commitMutation<DeleteDevicesModalMutation>(environment, {
            mutation: graphql`
                mutation DeleteDevicesModalMutation($ids: [ID!]!) {
                    removeDevices(ids: $ids)
                }
            `,
            variables,
            onError: () => {
                reject();
            },
            onCompleted: (response, err) => {
                if (err) {
                    reject();
                }

                if (response) {
                    resolve(response.removeDevices);
                }
            },
        });
    });
}
