import { captureException } from '@sentry/react';

import { logError } from './log';

function downloadFile(url: string, filename?: string) {
    const a = document.createElement('a');
    a.href = url;
    a.download = filename ?? '';

    a.click();
}

export function downloadExportFile(endpoint: string) {
    try {
        // let URL handle the URL generation. Avoiding manual concatenation that can lead to invalid URLs

        let baseUrl = process.env.REACT_APP_API_SERVER ?? '/';
        // Ensure the base URL ends with a slash so that the endpoint can be appended
        if (!baseUrl.endsWith('/')) {
            baseUrl += '/';
        }

        // Ensure the base URL is absolute. Relative URLs cannot be used as the origin
        const originUrl = new URL(baseUrl, window.origin);

        // Ensure the endpoint URL is relative to the current directory
        if (endpoint.startsWith('/')) {
            endpoint = `.${endpoint}`;
        }

        const { href } = new URL(endpoint, originUrl);
        return downloadFile(href);
    } catch (error) {
        captureException(error, scope => {
            scope.setTag('Function', 'Download');
            scope.setTag('Input URL', `${endpoint} - ${process.env.REACT_APP_API_SERVER}`);
            return scope;
        });
        logError(error);
    }
}
