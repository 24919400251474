/* eslint-disable no-irregular-whitespace */
import React, { FC } from 'react';

export interface SkeletonPaddingProps {
    spacing?: string | number;
    className?: string;
}

export const SkeletonPadding: FC<SkeletonPaddingProps> = ({ className, spacing }) => {
    return (
        <div className={className} style={{ height: spacing }}>
             
        </div>
    );
};
