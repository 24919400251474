/**
 * @generated SignedSource<<3c1a47bf91ba4608620c044f8be85d73>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeviceErrorType = "DuplicateName" | "EditNotAllowed" | "InvalidAttributeName" | "InvalidBatteryType" | "InvalidBatteryVoltage" | "InvalidCellCount" | "InvalidDevice" | "InvalidDeviceType" | "InvalidDualPlaneDevice" | "InvalidFirmware" | "InvalidManufactureDate" | "InvalidQuickTestCheckPercent" | "InvalidQuickTestThreshold" | "InvalidReserveTime" | "InvalidSite" | "MissingName" | "Noop" | "NotPossibleRightNow" | "Skipped" | "%future added value";
export type ProtocolErrorField = "BasicPassword" | "BasicUsername" | "MissingProtocol" | "SnmpPort" | "SnmpV1ReadCommunity" | "SnmpV1WriteCommunity" | "SnmpV3AuthPassphrase" | "SnmpV3EngineId" | "SnmpV3PrivPassphrase" | "SnmpV3User" | "UnknownProtocol" | "%future added value";
export type mutations_RemoveCompanionMutation$variables = {
  deviceId: string;
};
export type mutations_RemoveCompanionMutation$data = {
  readonly editDevice: {
    readonly id?: string;
    readonly problems?: ReadonlyArray<{
      readonly field?: ProtocolErrorField;
      readonly protocolId?: string;
      readonly string?: number | null;
      readonly type?: DeviceErrorType;
    }>;
  } | null;
};
export type mutations_RemoveCompanionMutation = {
  response: mutations_RemoveCompanionMutation$data;
  variables: mutations_RemoveCompanionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deviceId"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "device",
    "value": {
      "dualPlaneDevice": null,
      "dualPlaneType": null
    }
  },
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "deviceId"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "string",
      "storageKey": null
    }
  ],
  "type": "DeviceGeneralProblem",
  "abstractKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "protocolId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    }
  ],
  "type": "DeviceProtocolProblem",
  "abstractKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutations_RemoveCompanionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "editDevice",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "problems",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "DeviceProblemResponse",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutations_RemoveCompanionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "editDevice",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "problems",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "DeviceProblemResponse",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "769729ad20f62969e878f1a62fd21c40",
    "id": null,
    "metadata": {},
    "name": "mutations_RemoveCompanionMutation",
    "operationKind": "mutation",
    "text": "mutation mutations_RemoveCompanionMutation(\n  $deviceId: ID!\n) {\n  editDevice(id: $deviceId, device: {dualPlaneDevice: null, dualPlaneType: null}) {\n    __typename\n    ... on Device {\n      id\n    }\n    ... on DeviceProblemResponse {\n      problems {\n        __typename\n        ... on DeviceGeneralProblem {\n          type\n          string\n        }\n        ... on DeviceProtocolProblem {\n          protocolId\n          field\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fba7be8fb4686aacb5f911711fbccf5b";

export default node;
