import React, { FC, useCallback, useMemo } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { TestResultTableColumn, testResultToFilterObject, useTestResultFilter } from 'filters/test-result';
import { SortDirection, TableLayout, useTableReducer } from 'layouts';
import { useUserPermissions } from 'lib/auth';
import { useQuery } from 'lib/query-helpers';
import { Paths } from 'lib/routes';

import { useDocumentTitle } from '../../../../components';
import { TestResultListDeviceSearchQuery } from './__generated__/TestResultListDeviceSearchQuery.graphql';
import {
    TestResultListQuery,
    TestResultListQuery$data,
    TestResultListQuery$variables,
    TestResultOrdering,
    TestResultSortField,
} from './__generated__/TestResultListQuery.graphql';
import { AllTableColumns, BaseTableColumns } from './settings';

type TestResult = TestResultListQuery$data['unplannedTests']['data'][number];

const TableStorageKeyPrefix = 'external-discharge-table';

export const TestResultList: FC = () => {
    const { hasTasksWrite } = useUserPermissions();
    const pageTitle = 'External discharges';
    useDocumentTitle(pageTitle);

    const environment = useRelayEnvironment();
    const [tableState, dispatchTableState] = useTableReducer<TestResultTableColumn>({
        defaultSortColumn: TestResultTableColumn.StartTime,
        defaultSortDirection: SortDirection.Descending,
        allColumns: AllTableColumns.map(col => col.id),
        defaultVisibleColumns: BaseTableColumns.map(col => col.id),
        storageKeyPrefix: TableStorageKeyPrefix,
    });

    const [filters, dispatchFilters] = useTestResultFilter();
    const filterObject = useMemo(() => testResultToFilterObject(filters), [filters]);

    const sortObject: TestResultOrdering = {
        field: tableState.sortColumn as TestResultSortField,
        dir: tableState.sortDirection === SortDirection.Ascending ? 'Asc' : 'Desc',
    };

    const variables: TestResultListQuery$variables = {
        page: tableState.page,
        filters: filterObject,
        orderBy: sortObject,
        deviceName: tableState.search,
        withFailReason: tableState.visibleColumnsInOrder.includes(TestResultTableColumn.FailReason),
        withCurrentMetrics: tableState.visibleColumnsInOrder.includes(TestResultTableColumn.CurrentMetrics),
        withTemperatureMetrics: tableState.visibleColumnsInOrder.includes(TestResultTableColumn.TemperatureMetrics),
        withVoltageMetrics: tableState.visibleColumnsInOrder.includes(TestResultTableColumn.VoltageMetrics),
        withDischargedMetrics: tableState.visibleColumnsInOrder.includes(TestResultTableColumn.DischargedMetrics),
        withStateOfHealth: tableState.visibleColumnsInOrder.includes(TestResultTableColumn.EstimatedStateOfHealth),
        withCapacity: tableState.visibleColumnsInOrder.includes(TestResultTableColumn.EstimatedCapacity),
        withReserveTime: tableState.visibleColumnsInOrder.includes(TestResultTableColumn.EstimatedReserveTime),
    };

    const { data: props, retry, error, isFetching } = useQuery<TestResultListQuery>(
        graphql`
            query TestResultListQuery(
                $deviceName: String = ""
                $page: Int = 1
                $filters: TestResultFilter
                $orderBy: TestResultOrdering
                $withFailReason: Boolean = false
                $withCurrentMetrics: Boolean = false
                $withTemperatureMetrics: Boolean = false
                $withVoltageMetrics: Boolean = false
                $withDischargedMetrics: Boolean = false
                $withStateOfHealth: Boolean = false
                $withCapacity: Boolean = false
                $withReserveTime: Boolean = false
            ) {
                unplannedTests(page: $page, filters: $filters, orderBy: $orderBy, search: $deviceName) {
                    total
                    data {
                        id
                        state
                        commencedTime
                        completedTime
                        batteryStrings {
                            string
                        }
                        batteryTypes {
                            minimumStateOfHealth @include(if: $withStateOfHealth)
                        }
                        device {
                            id
                            name
                            battery {
                                reserveTime(unit: Minutes) @include(if: $withReserveTime)
                            }
                        }
                        cause
                        failReason @include(if: $withFailReason)
                        averageCurrent @include(if: $withCurrentMetrics)
                        averageTemperature @include(if: $withTemperatureMetrics)
                        finalVoltage @include(if: $withVoltageMetrics)
                        discharged @include(if: $withDischargedMetrics)
                        estimatedStateOfHealth @include(if: $withStateOfHealth) {
                            ... on EstimatedValue {
                                value
                                type
                                confidenceInterval {
                                    lower
                                    upper
                                }
                            }
                            ... on MissingEstimatedValue {
                                reason
                                reasonDescription
                            }
                        }
                        estimatedCapacity @include(if: $withCapacity) {
                            ... on EstimatedValue {
                                value
                                type
                                confidenceInterval {
                                    lower
                                    upper
                                }
                            }
                            ... on MissingEstimatedValue {
                                reason
                                reasonDescription
                            }
                        }
                        estimatedReserveTime @include(if: $withReserveTime) {
                            ... on EstimatedValue {
                                value
                                type
                                confidenceInterval {
                                    lower
                                    upper
                                }
                            }
                            ... on MissingEstimatedValue {
                                reason
                                reasonDescription
                            }
                        }
                    }
                    pageInfo {
                        page
                        size
                        total
                        hasNext
                        hasPrevious
                    }
                }
                testCount: unplannedTests {
                    total
                }
            }
        `,
        variables,
        {
            fetchPolicy: 'network-only',
        }
    );

    const handleSearch = useCallback(
        (input: string) => {
            return fetchQuery<TestResultListDeviceSearchQuery>(
                environment,
                graphql`
                    query TestResultListDeviceSearchQuery($deviceName: String = "", $pageSize: Int!) {
                        unplannedTests(search: $deviceName, pageSize: $pageSize) {
                            data {
                                device {
                                    name
                                }
                            }
                        }
                    }
                `,
                {
                    pageSize: 10,
                    deviceName: input,
                }
            )
                .toPromise()
                .then(
                    result =>
                        result?.unplannedTests.data
                            .map(unplannedTest => unplannedTest.device.name)
                            .filter((device, idx, devices) => devices.indexOf(device) === idx) ?? []
                );
        },
        [environment]
    );

    return (
        <TableLayout
            title={pageTitle}
            columns={AllTableColumns}
            allowEditingColumns
            filterState={filters}
            dispatchFilterState={dispatchFilters}
            tableState={tableState}
            dispatchTableState={dispatchTableState}
            data={(props?.unplannedTests.data ?? null) as TestResult[]}
            getRowId={row => row.id}
            isProcessing={!!props && isFetching}
            page={props?.unplannedTests.pageInfo.page}
            pageCount={props?.unplannedTests.pageInfo.total}
            overallCount={props?.testCount.total}
            resultCount={props?.unplannedTests.total}
            hasError={!!error}
            onRetry={retry}
            searchPlaceholder='Search by Device Name'
            onSearch={handleSearch}
            renderSearchResult={item => item}
            renderSearchResultAsString={item => item}
            emptyMessage='There are no tests present'
            unit='Test'
            primaryAction={hasTasksWrite ? 'Schedule a test' : undefined}
            primaryActionLink={hasTasksWrite ? Paths.TestsScheduleTest : undefined}
            getItemLink={item => `${Paths.TestsUnplanned}/${item.id}`}
        />
    );
};
