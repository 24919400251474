import React, { FC } from 'react';

import { Button } from '@accesstel/pcm-ui';

import classNamesBind from 'classnames/bind';

import style from '../layout.module.css';
import { TestSectionHeading } from './TestSectionHeading';

const classNames = classNamesBind.bind(style);

export interface CardSectionProps {
    title: string;
    to?: string;
    children: React.ReactNode;
    testId: string;
}

export const CardSection: FC<CardSectionProps> = ({ title, children, to, testId }) => {
    return (
        <div className={classNames('card_section')}>
            <div className={classNames('card_section__header')}>
                <TestSectionHeading value={title} />
                {to && <Button buttonText='See more' to={to} size='small' variant='gray' />}
            </div>
            <div data-testid={testId} className={classNames('card_section__body')}>
                {children}
            </div>
        </div>
    );
};
