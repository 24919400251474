/**
 * @generated SignedSource<<036fc1138a7523fc8b082fd02f0a8a9d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GlobalBatteryTestingSettingsProblemType = "DefaultQuickTestReservePercentInvalid" | "DefaultQuickTestThresholdVoltageInvalid" | "DischargeFaultDetectionTimeInvalid" | "DischargeFaultVoltageHistoresisInvalid" | "SmartStartTimeoutInvalid" | "StartTimeoutInvalid" | "%future added value";
export type GlobalBatteryTestingSettingsIn = {
  defaultQuickTestReservePercent?: number | null;
  defaultQuickTestThresholdVoltage?: number | null;
  dischargeFaultDetectionTime?: string | null;
  dischargeFaultVoltageHistoresis?: number | null;
  smartStartTimeout?: string | null;
  startTimeout?: string | null;
};
export type BatteryTestingUpdateMutation$variables = {
  input: GlobalBatteryTestingSettingsIn;
};
export type BatteryTestingUpdateMutation$data = {
  readonly updateGlobalBatteryTestingSettings: {
    readonly problems: ReadonlyArray<GlobalBatteryTestingSettingsProblemType>;
    readonly success: boolean;
  };
};
export type BatteryTestingUpdateMutation = {
  response: BatteryTestingUpdateMutation$data;
  variables: BatteryTestingUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GlobalBatteryTestingSettingsUpdateResponse",
    "kind": "LinkedField",
    "name": "updateGlobalBatteryTestingSettings",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "problems",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BatteryTestingUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BatteryTestingUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "dc7e4901d0fcfee23b81ae52ad24a919",
    "id": null,
    "metadata": {},
    "name": "BatteryTestingUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation BatteryTestingUpdateMutation(\n  $input: GlobalBatteryTestingSettingsIn!\n) {\n  updateGlobalBatteryTestingSettings(input: $input) {\n    success\n    problems\n  }\n}\n"
  }
};
})();

(node as any).hash = "13561c6c15723fe5e4866ddcd116ef53";

export default node;
