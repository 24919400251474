import React, { FC } from 'react';

import classNames from 'classnames';

import style from './style.module.css';

interface LargeTextDisplayProps {
    value: string;
}

export const LargeTextDisplay: FC<LargeTextDisplayProps> = ({ value }) => {
    return <div className={classNames('text-customCoral', style.large_text_display)}>{value}</div>;
};
