import { object, string } from 'yup';

export const ResetFormValidationSchema = object({
    code: string().trim().required('Must provide verifcation code'),
    newPassword: string().required('Must provide password'),
    confirmPassword: string()
        .required('Must provide password')
        .test({
            name: 'Matches',
            message: 'Password does not match',
            test(value, context) {
                return value === context.parent.newPassword;
            },
        }),
});

export interface ResetFormValues {
    code: string;
    newPassword: string;
    confirmPassword: string;
}

export const DefaultResetFormValues: ResetFormValues = {
    code: '',
    newPassword: '',
    confirmPassword: '',
};
