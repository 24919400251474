/**
 * @generated SignedSource<<6fbced92bc1053b0381887edd608380d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeviceLoad_device$data = {
  readonly load: {
    readonly metrics: {
      readonly latestCurrent: number | null;
      readonly latestPower: number | null;
      readonly latestVoltage: number | null;
      readonly power: {
        readonly values: ReadonlyArray<{
          readonly timestamp: string;
          readonly value: number | null;
        }>;
      } | null;
    };
    readonly uptime: {
      readonly percentage: number;
    };
  };
  readonly " $fragmentType": "DeviceLoad_device";
};
export type DeviceLoad_device$key = {
  readonly " $data"?: DeviceLoad_device$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeviceLoad_device">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "end"
    },
    {
      "kind": "RootArgument",
      "name": "start"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeviceLoad_device",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceLoad",
      "kind": "LinkedField",
      "name": "load",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "from",
              "variableName": "start"
            },
            {
              "kind": "Variable",
              "name": "to",
              "variableName": "end"
            }
          ],
          "concreteType": "DeviceLoadUptime",
          "kind": "LinkedField",
          "name": "uptime",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "percentage",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DeviceLoadMetrics",
          "kind": "LinkedField",
          "name": "metrics",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "latestVoltage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "unit",
                  "value": "Amps"
                }
              ],
              "kind": "ScalarField",
              "name": "latestCurrent",
              "storageKey": "latestCurrent(unit:\"Amps\")"
            },
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "unit",
                  "value": "Watt"
                }
              ],
              "kind": "ScalarField",
              "name": "latestPower",
              "storageKey": "latestPower(unit:\"Watt\")"
            },
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "begin",
                  "variableName": "start"
                },
                {
                  "kind": "Variable",
                  "name": "end",
                  "variableName": "end"
                },
                {
                  "kind": "Literal",
                  "name": "interval",
                  "value": "PT15M"
                }
              ],
              "concreteType": "TimeSeriesMetrics",
              "kind": "LinkedField",
              "name": "power",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TimestampMetric",
                  "kind": "LinkedField",
                  "name": "values",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "timestamp",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "value",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
};

(node as any).hash = "113650040954cd4e43de0163688c497f";

export default node;
