import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { ClockIcon, RotateCCWIcon, SmallMetricRow } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { IconWithStatus } from 'components';
import humanizeDuration from 'humanize-duration';
import { Duration } from 'luxon';

import { StringRow_data$key } from './__generated__/StringRow_data.graphql';

export interface StringRowProps {
    stringRow: StringRow_data$key;
}

export const StringRow: FC<StringRowProps> = ({ stringRow }) => {
    const data = useFragment(Fragment, stringRow);

    let cycles: string;
    if (data.cycles) {
        cycles = `${data.cycles} cycles`;
    } else {
        cycles = '- cycles';
    }

    let capacity: string;
    if (data.type.cells?.capacity) {
        capacity = `${data.type.cells?.capacity.toFixed(0)}`;
    } else {
        capacity = '-';
    }

    let age;
    if (data.age !== null) {
        age = Duration.fromISO(data.age);
        age = humanizeDuration(age.as('milliseconds'), { largest: 2, round: true, units: ['y', 'mo', 'w', 'd'] });
        age = `${age} old`;
    } else {
        age = 'Unknown';
    }

    return (
        <SmallMetricRow
            label={data.localId}
            statusIcon={null}
            column1={
                <div className='flex flex-col justify-start items-start leading-none'>
                    <div>
                        <span className='text-xl font-CynthoNext-SemiBold'>
                            {data.type.manufacturer} {data.type.model}
                        </span>
                    </div>
                    <div>
                        <IconWithStatus title='Battery Age' icon={<ClockIcon />} label={age} />
                    </div>
                </div>
            }
            column2={
                <div className='flex flex-col justify-start items-start leading-none'>
                    <div>
                        <span className='text-xl font-CynthoNext-SemiBold'>{capacity}</span>
                        <span className='text-sm font-CynthoNext-Regular'> Ah Capacity</span>
                    </div>
                    <div>
                        <IconWithStatus title='Discharge Cycles' icon={<RotateCCWIcon />} label={cycles} />
                    </div>
                </div>
            }
        />
    );
};

const Fragment = graphql`
    fragment StringRow_data on DeviceBatteryString {
        id
        localId
        installDate
        age
        cycles
        type {
            manufacturer
            model
            cells {
                capacity
            }
        }
    }
`;
