import React, { FC, Suspense } from 'react';
import { PreloadedQuery } from 'react-relay';

import { BatteryTestCardExpansionContent } from './BatteryTestCardExpansionContent';
import { BatteryTestCardExpansionSkeleton } from './BatteryTestCardExpansionSkeleton';
import { BatteryTestCardExpansionContentLoadQuery } from './__generated__/BatteryTestCardExpansionContentLoadQuery.graphql';

interface BatteryTestCardExpansionProps {
    preloadedQuery?: PreloadedQuery<BatteryTestCardExpansionContentLoadQuery> | null;
}

export const BatteryTestCardExpansion: FC<BatteryTestCardExpansionProps> = ({ preloadedQuery }) => {
    if (!preloadedQuery) {
        return <BatteryTestCardExpansionSkeleton />;
    }

    return (
        <Suspense fallback={<BatteryTestCardExpansionSkeleton />}>
            <BatteryTestCardExpansionContent preloadedQuery={preloadedQuery} />
        </Suspense>
    );
};
