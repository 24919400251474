import React from 'react';

import { FilterContainer } from '@accesstel/pcm-ui';

import { ColumnWithId } from 'layouts';

import { ColumnOptions } from './ColumnOptions';

export interface SelectColumnPaneProps<ColumnType extends string> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    availableColumns: ColumnWithId<ColumnType, any>[];
    visibleColumns: ColumnType[];
    setVisibleColumns: (columns: ColumnType[]) => void;
    onClose: () => void;
    onReset: () => void;
    hideReset?: (selectedColumns: ColumnType[]) => boolean;
}

export function SelectColumnPane<ColumnType extends string>({
    availableColumns,
    visibleColumns,
    setVisibleColumns,
    onClose,
    onReset,
    hideReset = () => false,
}: SelectColumnPaneProps<ColumnType>) {
    return (
        <FilterContainer
            title='Edit Columns'
            hideClearButton
            hideConfirmButton
            onClose={onClose}
            primaryContent={
                <ColumnOptions
                    visibleColumns={visibleColumns}
                    availableColumns={availableColumns}
                    onSubmit={columns => {
                        setVisibleColumns(columns as ColumnType[]);
                        onClose();
                    }}
                    onReset={() => {
                        onReset();
                        onClose();
                    }}
                    hideReset={hideReset}
                />
            }
        />
    );
}
