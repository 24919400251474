import React, { FC, ReactElement, ReactNode } from 'react';

import {
    ArrowDownCircleIcon,
    ArrowRightCircleIcon,
    CheckCircledOpenIcon,
    CircleAlertIcon,
    CircleCheckIcon,
    CircleCrossIcon,
    CircleDotsIcon,
    CircleHelpIcon,
    CircleMinusIcon,
    CirclePauseIcon,
    CirclePlayIcon,
    CircleStopIcon,
    Tooltip,
} from '@accesstel/pcm-ui';

import { IconWithStatus } from 'components';
import { DeviceHealth } from 'views/sites/__generated__/SiteListTableQuery.graphql';
import { StatusCellValue } from 'views/tasks/battery-health/task-view/subviews/StatusList';
import {
    BatteryTaskState,
    BatteryTestState,
} from 'views/tasks/battery-health/task-view/subviews/__generated__/StatusList_task.graphql';

import { getNiceFailReason, getNiceStatusName } from './textFormatters';

interface EmptyCellProps {
    text?: string;
}

export const EmptyCell: FC<EmptyCellProps> = ({ text = 'Not set' }) => {
    return <div className='font-CynthoNext-Light italic text-customEggplant'>{text}</div>;
};

type ValueType = ReactNode | null | undefined;
type ValueCallback = () => ValueType;

export function renderUserConfigCell(valueOrFunction: ValueType | ValueCallback): ReactNode {
    let value: ValueType;

    if (typeof valueOrFunction === 'function') {
        value = valueOrFunction();
    } else {
        value = valueOrFunction;
    }

    if (!value) {
        return <EmptyCell />;
    }

    return value;
}

export function renderMetricCell(valueOrFunction: ValueType | ValueCallback): ReactNode {
    let value: ValueType;

    if (typeof valueOrFunction === 'function') {
        value = valueOrFunction();
    } else {
        value = valueOrFunction;
    }

    if (!value) {
        return <EmptyCell text='No value' />;
    }

    return value;
}

export function renderAlertDateCell(valueOrFunction: ValueType | ValueCallback): ReactNode {
    let value: ValueType;

    if (typeof valueOrFunction === 'function') {
        value = valueOrFunction();
    } else {
        value = valueOrFunction;
    }

    if (!value) {
        return <EmptyCell text='Active alert' />;
    }

    return value;
}

export function renderTableStatusCell(
    value: BatteryTaskState | BatteryTestState | 'Cancelled',
    test?: StatusCellValue,
    size: 'base' | 'lg' = 'base'
): ReactElement {
    switch (value) {
        case 'Aborted':
            return <IconWithStatus icon={<CircleStopIcon />} label={getNiceStatusName(value)} variant={size} />;
        case 'PartialFail':
        case 'Failed':
            return (
                <Tooltip content={test ? getNiceFailReason(test) : undefined}>
                    <div>
                        <IconWithStatus
                            icon={<CircleCrossIcon />}
                            label={getNiceStatusName(value)}
                            containerClasses='text-customCoral'
                            variant={size}
                        />
                    </div>
                </Tooltip>
            );
        case 'InProgress':
            return <IconWithStatus icon={<CirclePlayIcon />} label={getNiceStatusName(value)} variant={size} />;
        case 'Inconclusive':
            return <IconWithStatus icon={<CircleHelpIcon />} label={getNiceStatusName(value)} variant={size} />;
        case 'PartialPass':
        case 'Passed':
            return (
                <IconWithStatus
                    icon={<CircleCheckIcon />}
                    label={getNiceStatusName(value)}
                    containerClasses='text-customPine'
                    variant={size}
                />
            );
        case 'Scheduled':
            return <IconWithStatus icon={<ArrowRightCircleIcon />} label={getNiceStatusName(value)} variant={size} />;
        case 'SmartStart':
            return <IconWithStatus icon={<CirclePauseIcon />} label={getNiceStatusName(value)} variant={size} />;
        case 'Analyzing':
        case 'Finalizing':
        case 'Waiting':
            return <IconWithStatus icon={<CircleMinusIcon />} label={getNiceStatusName(value)} variant={size} />;
        case 'Cancelled':
            return <IconWithStatus icon={<CircleMinusIcon />} label={getNiceStatusName(value)} variant={size} />;
        default:
            return <span>{getNiceStatusName(value)}</span>;
    }
}

export function renderDeviceHealthCell(value: DeviceHealth | 'Various', colorOverride?: string): ReactElement {
    switch (value) {
        case 'Healthy':
            return <IconWithStatus icon={<CheckCircledOpenIcon />} label={value} containerClasses='text-customPine' />;
        case 'Critical':
            return <IconWithStatus icon={<CircleAlertIcon />} label={value} containerClasses='text-customCoral' />;
        case 'Degraded':
            return (
                <IconWithStatus
                    icon={<ArrowDownCircleIcon />}
                    label={value}
                    containerClasses='text-customEggplantCoral'
                />
            );
        case 'Offline':
            return <IconWithStatus icon={<CircleCrossIcon />} label={value} containerClasses='text-customCoral' />;
        case 'Various':
            return (
                <IconWithStatus
                    icon={<CircleDotsIcon />}
                    label={value}
                    containerClasses={`${colorOverride} ?? 'text-customEggplant'`}
                />
            );
        default:
        case 'Unknown':
            return <IconWithStatus icon={<CircleHelpIcon />} label={value} containerClasses='text-customEggplant' />;
    }
}
