import React, { FC } from 'react';

import { SkeletonPadding, SkeletonText } from 'components/Skeleton';
import { SkeletonChart } from 'components/Skeleton/SkeletonChart';

import { PageHeader } from '../../../../components';

export const ViewBatteryHealthTaskSkeleton: FC = () => {
    return (
        <div className='space-y-6 mb-32'>
            <PageHeader />
            <SkeletonText characters={30} size='heading' />
            <div className='bg-white p-8 space-y-8'>
                <div className='flex flex-row justify-between'>
                    <SkeletonText characters={20} className='font-CynthoNext-SemiBold text-3xl' />
                </div>
                <SkeletonPadding spacing={38} />
                <div className='grid grid-cols-2'>
                    <SkeletonText characters={40} className='font-CynthoNext-Light text-base' />
                    <SkeletonText characters={40} className='font-CynthoNext-Light text-base' />
                    <SkeletonText characters={40} className='font-CynthoNext-Light text-base' />
                    <SkeletonText characters={40} className='font-CynthoNext-Light text-base' />
                    <SkeletonText characters={40} className='font-CynthoNext-Light text-base' />
                    <SkeletonText characters={40} className='font-CynthoNext-Light text-base' />
                </div>
                <SkeletonPadding spacing={48} />
            </div>
            <div className='bg-white p-8 space-y-8'>
                <SkeletonText characters={20} className='font-CynthoNext-SemiBold text-3xl' />
                <SkeletonChart stretch aspectRatio='9 / 2' />
                <SkeletonChart stretch aspectRatio='9 / 2' />
                <SkeletonChart stretch aspectRatio='9 / 2' />
                <SkeletonChart stretch aspectRatio='9 / 2' />
            </div>
        </div>
    );
};
