import React, { FC, Suspense } from 'react';
import { PreloadedQuery } from 'react-relay';
import { useLoaderData } from 'react-router-dom';

import { MetricsExploreContent } from './MetricsExploreContent';
import { MetricsExploreSkeleton } from './MetricsExploreSkeleton';
import { MetricsExploreContentQuery } from './__generated__/MetricsExploreContentQuery.graphql';

export const MetricsExplore: FC = () => {
    const queryRef = useLoaderData() as PreloadedQuery<MetricsExploreContentQuery>;

    return (
        <>
            {!queryRef && <MetricsExploreSkeleton />}
            {queryRef && (
                <Suspense fallback={<MetricsExploreSkeleton />}>
                    <MetricsExploreContent queryRef={queryRef} />
                </Suspense>
            )}
        </>
    );
};
