import React, { Dispatch, FC, useCallback, useEffect } from 'react';
import { useFragment } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { TestDeviceFilterAction, TestDeviceFilterState, TestDeviceTableColumn } from 'filters/device';
import { TestDeviceColumnFilterMap } from 'filters/device/settings';
import { TableAction, TableActionType, TableState } from 'layouts';
import { CoreTableLayout } from 'layouts/TableLayout/CoreTableLayout';

import { SelectedDeviceInfo } from '../../../device-selection';
import { DevicesTable_devices$data, DevicesTable_devices$key } from './__generated__/DevicesTable_devices.graphql';
import { TableColumns } from './settings';

interface DevicesTableProps {
    selectedDevices: Set<string>;
    setDeviceSelected(id: string, info: SelectedDeviceInfo): void;
    setDeviceUnselected(id: string): void;
    selectNone(): void;
    selectAll: () => Promise<string[]>;
    devices: DevicesTable_devices$key | null;
    retry: () => void;
    error?: boolean;

    filterState: TestDeviceFilterState;
    dispatchFilterState: Dispatch<TestDeviceFilterAction>;
    tableState: TableState<TestDeviceTableColumn>;
    dispatchTableState: Dispatch<TableAction<TestDeviceTableColumn>>;
}

type Device = DevicesTable_devices$data['data'][number];

export const DevicesTable: FC<DevicesTableProps> = ({
    selectedDevices,
    setDeviceSelected,
    setDeviceUnselected,
    selectNone,
    selectAll,
    devices,
    retry,
    error,

    filterState,
    dispatchFilterState,
    tableState,
    dispatchTableState,
}) => {
    const result = useFragment<DevicesTable_devices$key>(
        graphql`
            fragment DevicesTable_devices on PaginatedDevices {
                data {
                    id
                    site {
                        id
                        name
                        address {
                            state
                        }
                    }
                    name
                    tests(orderBy: { field: StartTime, dir: Desc }, pageSize: 1) {
                        data {
                            state
                            commencedTime
                        }
                    }
                    dualPlaneCompanion {
                        device {
                            id
                            name
                        }
                        configuration
                    }
                }
                pageInfo {
                    page
                    size
                    total
                    hasNext
                    hasPrevious
                }
            }
        `,
        devices
    );

    const devicesData = result?.data;

    const onUpdateSelection = useCallback(
        (deviceIds: string[]) => {
            // Newly selected
            for (const deviceId of deviceIds) {
                if (selectedDevices.has(deviceId)) {
                    continue;
                }

                // New selection
                const device = (devicesData ?? []).find(device => device.id === deviceId);
                if (device) {
                    setDeviceSelected(deviceId, { companion: device.dualPlaneCompanion?.device?.id });
                }
            }

            // Cleared selections
            const newSelection = new Set(deviceIds);
            selectedDevices.forEach(deviceId => {
                if (newSelection.has(deviceId)) {
                    return;
                }

                // Cleared selected
                setDeviceUnselected(deviceId);
            });
        },
        [devicesData, selectedDevices, setDeviceSelected, setDeviceUnselected]
    );

    useEffect(() => {
        dispatchTableState({ type: TableActionType.SetSelection, selection: Array.from(selectedDevices.keys()) });
    }, [dispatchTableState, selectedDevices]);

    return (
        <div>
            <CoreTableLayout<TestDeviceTableColumn, Device, Device, TestDeviceColumnFilterMap>
                tableVariant='white'
                data={devicesData ?? []}
                columns={TableColumns}
                getRowId={row => row.id}
                hasError={error}
                onRetry={() => retry()}
                page={result?.pageInfo.page}
                pageCount={result?.pageInfo.total}
                emptyMessage='There are no matching sites'
                onRequestAllIds={selectAll}
                tableOnSelectRowCallback={onUpdateSelection}
                tableOnUnselectAllCallback={selectNone}
                clickBehaviour='select'
                filterState={filterState}
                dispatchFilterState={dispatchFilterState}
                tableState={tableState}
                dispatchTableState={dispatchTableState}
                selection
            />
        </div>
    );
};
