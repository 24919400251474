/**
 * @generated SignedSource<<913ab232a8add13333c3c54073e7520c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BatteryTestState = "Aborted" | "Analyzing" | "Failed" | "Finalizing" | "InProgress" | "Inconclusive" | "Passed" | "Scheduled" | "SmartStart" | "Waiting" | "%future added value";
export type DualPlaneConfiguration = "NPlusOne" | "TwoN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DevicesTable_devices$data = {
  readonly data: ReadonlyArray<{
    readonly dualPlaneCompanion: {
      readonly configuration: DualPlaneConfiguration | null;
      readonly device: {
        readonly id: string;
        readonly name: string;
      } | null;
    } | null;
    readonly id: string;
    readonly name: string;
    readonly site: {
      readonly address: {
        readonly state: string;
      };
      readonly id: string;
      readonly name: string;
    };
    readonly tests: {
      readonly data: ReadonlyArray<{
        readonly commencedTime: string | null;
        readonly state: BatteryTestState;
      }>;
    };
  }>;
  readonly pageInfo: {
    readonly hasNext: boolean | null;
    readonly hasPrevious: boolean | null;
    readonly page: number;
    readonly size: number;
    readonly total: number;
  };
  readonly " $fragmentType": "DevicesTable_devices";
};
export type DevicesTable_devices$key = {
  readonly " $data"?: DevicesTable_devices$data;
  readonly " $fragmentSpreads": FragmentRefs<"DevicesTable_devices">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DevicesTable_devices",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Device",
      "kind": "LinkedField",
      "name": "data",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Site",
          "kind": "LinkedField",
          "name": "site",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Address",
              "kind": "LinkedField",
              "name": "address",
              "plural": false,
              "selections": [
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "orderBy",
              "value": {
                "dir": "Desc",
                "field": "StartTime"
              }
            },
            {
              "kind": "Literal",
              "name": "pageSize",
              "value": 1
            }
          ],
          "concreteType": "PaginatedDeviceBatteryTestResults",
          "kind": "LinkedField",
          "name": "tests",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DeviceBatteryTestResults",
              "kind": "LinkedField",
              "name": "data",
              "plural": true,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "commencedTime",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "tests(orderBy:{\"dir\":\"Desc\",\"field\":\"StartTime\"},pageSize:1)"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DualPlaneCompanion",
          "kind": "LinkedField",
          "name": "dualPlaneCompanion",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Device",
              "kind": "LinkedField",
              "name": "device",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "configuration",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PageInfo",
      "kind": "LinkedField",
      "name": "pageInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "page",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "size",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasNext",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasPrevious",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PaginatedDevices",
  "abstractKey": null
};
})();

(node as any).hash = "260c2d1e8cfd8deee6363135b064ea85";

export default node;
