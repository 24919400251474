import { Dispatch, useEffect } from 'react';
import { useRelayEnvironment } from 'react-relay';

import { AttributeFilter, FilterAction, FilterActionType, FilterState, useFilterReducer } from '../common';
import { loadDynamicFilters } from './dynamic';
import { DefaultExtraFilters, DefaultValues, SiteColumnFilterMap, StaticSiteFilterDefinitions } from './settings';
import { SiteDeviceBatteries, SiteExtraFilters, SiteNameFilter, SiteTableColumn } from './types';

export type SiteFilterState = FilterState<SiteTableColumn, SiteColumnFilterMap>;
export type SiteFilterAction = FilterAction<SiteColumnFilterMap, SiteTableColumn>;

export function useSiteFilter(): [SiteFilterState, Dispatch<SiteFilterAction>] {
    const [state, updateState] = useFilterReducer<
        SiteTableColumn,
        SiteColumnFilterMap,
        SiteFilterState,
        SiteFilterAction
    >('site-filter', DefaultValues, StaticSiteFilterDefinitions, DefaultExtraFilters);

    const environment = useRelayEnvironment();

    useEffect(() => {
        loadDynamicFilters(environment).then(definitions => {
            updateState({
                type: FilterActionType.UpdateFilterTypes,
                definitions: [...StaticSiteFilterDefinitions, ...definitions],
            });
        });
    }, [environment, updateState]);

    return [state, updateState];
}

export function siteToFilterObject(filters: SiteFilterState): Record<string, unknown> {
    const output: Record<string, unknown> = {};
    const {
        Type: type,
        Name: name,
        State: state,
        Postcode: postcode,
        DeviceCount: deviceCount,
        BatteryStatus: batteryStatus,
        BatteryCapacity: batteryCapacity,
        BatteryTotalTimeRemaining: batteryTotalTimeRemaining,
        DeviceStatus: deviceStatus,
    } = filters.columnValues;

    if (name.length > 0) {
        output.name = name.map(filter => ({ value: filter.name }));
    }

    if (type.length > 0) {
        output.type = type.map(filter => ({ value: filter.id }));
    }

    if (state.length > 0) {
        output.state = state.map(filter => ({ value: filter.state }));
    }

    if (deviceCount) {
        output.deviceCount = deviceCount;
    }

    if (batteryStatus.length > 0) {
        output.batteryStatus = batteryStatus.map(filter => ({ value: filter.id }));
    }

    if (batteryCapacity) {
        output.batteryCapacity = batteryCapacity;
    }

    if (batteryTotalTimeRemaining) {
        output.batteryTotalTimeRemaining = batteryTotalTimeRemaining;
    }

    if (postcode.length > 0) {
        output.postcode = postcode.map(p => ({ value: p.value }));
    }

    if (deviceStatus.length > 0) {
        output.deviceHealth = deviceStatus.map(filter => ({ value: filter.id }));
    }

    let attributeFilters: unknown[] | undefined;

    for (const [definitionName, values] of Object.entries(filters.extraFilters)) {
        const definition = filters.filterDefinitions.find(definition => definition.name === definitionName);
        if (!definition) {
            continue;
        }

        switch (definition.category) {
            case 'Attribute': {
                const filterObjects = values as AttributeFilter[];
                if (filterObjects.length > 0) {
                    if (!attributeFilters) {
                        attributeFilters = [];
                    }
                    attributeFilters.push({
                        name: definition.attributeName,
                        filters: filterObjects,
                    });
                }
                break;
            }
            case SiteExtraFilters.DeviceName: {
                const filterObjects = values as SiteNameFilter[];
                if (filterObjects.length > 0) {
                    output.deviceName = filterObjects.map(value => ({ value: value.name }));
                }
                break;
            }
            case SiteExtraFilters.DeviceWithBatteries: {
                const deviceBatteries = values as SiteDeviceBatteries;
                if (deviceBatteries !== null) {
                    output.deviceBatteries = deviceBatteries;
                }
                break;
            }
        }
    }

    if (attributeFilters && attributeFilters.length > 0) {
        output.attributes = attributeFilters;
    }

    return output;
}
