import React, { Dispatch, FC, SetStateAction } from 'react';

import { Button, Modal, ThemedPageHeading } from '@accesstel/pcm-ui';

import style from './style.module.css';

interface DeleteModalProps {
    handleDelete: () => void;
    itemText: string;
    secondaryText?: string;
    setShowDeleteModal: Dispatch<SetStateAction<boolean>>;
    showDeleteModal: boolean;
    error?: boolean;
    isProcessing?: boolean;
}

/**
 *
 * itemText: will be the string that is appended to the modal header and first sentence of the modal body
 * secondaryText: will be the string that is appended to the modal body (visible no matter the error state is true or false)
 * error: will be used to determine if header will contains 'Cannot' and if the action buttons will be visible
 */
export const DeleteModal: FC<DeleteModalProps> = ({
    handleDelete,
    itemText,
    setShowDeleteModal,
    showDeleteModal,
    secondaryText,
    error,
    isProcessing,
}) => {
    return (
        <Modal open={showDeleteModal} closeButton={false}>
            <div className={style.delete_modal}>
                <ThemedPageHeading value={`${error ? 'Cannot ' : ''}Delete ${itemText}`} />
                <div className='text-customEggplant font-CynthoNext-Regular text-base pb-12'>
                    {error ? (
                        <span>{secondaryText && ` ${secondaryText}`}</span>
                    ) : (
                        <>
                            <span>Are you sure you want to delete the selected {itemText.toLowerCase()}?</span>
                            <span>{secondaryText && ` ${secondaryText}`}</span>
                        </>
                    )}
                </div>
                {error ? (
                    <Button buttonText='Close' buttonClasses='w-24' onClick={() => setShowDeleteModal(false)} />
                ) : (
                    <div className='space-x-2'>
                        <Button
                            buttonText='Yes'
                            buttonClasses='w-24'
                            onClick={handleDelete}
                            processing={isProcessing}
                        />
                        <Button
                            buttonText='No'
                            buttonClasses='w-24'
                            variant='white'
                            onClick={() => setShowDeleteModal(false)}
                        />
                    </div>
                )}
            </div>
        </Modal>
    );
};
