import React, { FC, Suspense } from 'react';
import { PreloadedQuery } from 'react-relay';
import { useLoaderData, useParams } from 'react-router-dom';

import { OverviewContent } from './OverviewContent';
import { OverviewSkeleton } from './OverviewSkeleton';
import { OverviewContentQuery } from './__generated__/OverviewContentQuery.graphql';

export const Overview: FC = () => {
    const queryRef = useLoaderData() as PreloadedQuery<OverviewContentQuery> | null;
    const { deviceId } = useParams() as { deviceId: string };

    return (
        <>
            {!queryRef && <OverviewSkeleton />}
            {queryRef && (
                <Suspense fallback={<OverviewSkeleton />}>
                    <OverviewContent key={deviceId} queryRef={queryRef} deviceId={deviceId} />
                </Suspense>
            )}
        </>
    );
};
