import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { useRelayEnvironment } from 'react-relay';

import { useToast } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { useQuery } from 'lib/query-helpers';
import { IEnvironment, commitMutation } from 'relay-runtime';
import { DeleteModal } from 'views/manage/components';

import { DeleteSitesModalCheckQuery } from './__generated__/DeleteSitesModalCheckQuery.graphql';
import {
    DeleteSitesModalMutation,
    DeleteSitesModalMutation$variables,
    SiteRemoveResponse,
} from './__generated__/DeleteSitesModalMutation.graphql';

interface DeleteSitesModalProps {
    selectedSites: string[];
    onDone: () => void;
    setShowDeleteModal: Dispatch<SetStateAction<boolean>>;
    showDeleteModal: boolean;
    tableRetry: () => void;
}

export const DeleteSitesModal: FC<DeleteSitesModalProps> = ({
    selectedSites,
    onDone,
    setShowDeleteModal,
    showDeleteModal,
    tableRetry,
}) => {
    const environment = useRelayEnvironment();
    const { show } = useToast();
    const siteText = selectedSites.length === 1 ? 'Site' : 'Sites';
    const [isProcessing, setIsProcessing] = useState(false);

    const variables: DeleteSitesModalMutation$variables = {
        ids: selectedSites,
    };

    const { data: props } = useQuery<DeleteSitesModalCheckQuery>(
        graphql`
            query DeleteSitesModalCheckQuery($ids: [ID!]!) {
                sites(filters: { ids: $ids }) {
                    data {
                        id
                        name
                        devices {
                            total
                        }
                    }
                }
            }
        `,
        variables
    );

    const nonEmptySites = props?.sites?.data?.filter(site => site.devices.total > 0);
    const nonEmptySiteNames =
        nonEmptySites &&
        (nonEmptySites.length < 3
            ? `Site ${nonEmptySites.map(site => site.name).join(' & ')}`
            : `${nonEmptySites.length} sites`);

    const warningText =
        nonEmptySites && nonEmptySites.length > 0
            ? `${nonEmptySiteNames} contains one or more devices, hence cannot be deleted.`
            : undefined;
    const cannotDeleteAllSites = props?.sites?.data.every(site => site.devices.total > 0);

    const handleDeleteSites = () => {
        setIsProcessing(true);

        deleteSites(selectedSites, environment)
            .then(response => {
                setIsProcessing(false);
                setShowDeleteModal(false);
                if (response === 'Success') {
                    show({
                        text: `Successfully deleted ${siteText.toLowerCase()}`,
                        variant: 'info',
                    });
                }

                if (response === 'UnknownSite') {
                    show({
                        text: `Unknown ${siteText.toLowerCase()}`,
                        variant: 'error',
                    });
                }

                if (response === 'PartialSuccess') {
                    show({
                        text: `Unable to delete some sites`,
                        variant: 'info',
                    });
                }

                if (response === 'NotEmpty') {
                    const notEmptyText =
                        selectedSites.length === 1
                            ? 'Unable to delete site as it contains devices'
                            : 'Unable to delete sites as they contain devices';

                    show({
                        text: notEmptyText,
                        variant: 'error',
                    });
                }

                onDone();
                setTimeout(() => {
                    // FIXME: this delay is present as elasticsearch isn't waiting for the refresh on delete
                    tableRetry();
                }, 1000);
            })
            .catch(() => {
                setIsProcessing(false);
                setShowDeleteModal(false);
                show({
                    text: `Error deleting ${siteText.toLowerCase()}`,
                    variant: 'error',
                });
            });
    };

    return (
        <DeleteModal
            itemText={siteText}
            secondaryText={
                cannotDeleteAllSites
                    ? `${
                          selectedSites.length > 1
                              ? 'Selected sites cannot be deleted because they contain one or more devices'
                              : 'Selected site cannot be deleted because it contains one or more devices'
                      }`
                    : warningText
            }
            showDeleteModal={showDeleteModal}
            setShowDeleteModal={setShowDeleteModal}
            handleDelete={handleDeleteSites}
            error={cannotDeleteAllSites}
            isProcessing={isProcessing}
        />
    );
};

function deleteSites(siteIds: string[], environment: IEnvironment): Promise<SiteRemoveResponse> {
    const variables: DeleteSitesModalMutation$variables = {
        ids: siteIds,
    };

    return new Promise((resolve, reject) => {
        commitMutation<DeleteSitesModalMutation>(environment, {
            mutation: graphql`
                mutation DeleteSitesModalMutation($ids: [ID!]!) {
                    removeSites(ids: $ids)
                }
            `,
            variables,
            onError: () => {
                reject();
            },
            onCompleted: (response, err) => {
                if (err) {
                    reject();
                }

                if (response) {
                    resolve(response.removeSites);
                }
            },
        });
    });
}
