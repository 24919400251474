import React, { FC } from 'react';

interface TableDeviceHeaderProps {
    name: string;
}

export const TableDeviceHeader: FC<TableDeviceHeaderProps> = ({ name }) => {
    return (
        <>
            Device: <span className='truncate font-CynthoNext-SemiBold'>{name}</span>
        </>
    );
};
