import React, { FC } from 'react';

import { FormikTextBox } from '@accesstel/pcm-ui';

import { getQualifiedName } from 'lib/namespaces';

export interface WebCredentialsProps {
    namespace?: string;
}

export const WebCredentials: FC<WebCredentialsProps> = ({ namespace }) => {
    return (
        <>
            <div className='col-start-1'>
                <FormikTextBox
                    name={getQualifiedName('username', namespace)}
                    placeHolder='Web Credentials Username*'
                    required
                    light
                    variant='outlined'
                    testId='web-credentials-username'
                />
            </div>
            <div className='col-start-1'>
                <FormikTextBox
                    name={getQualifiedName('password', namespace)}
                    placeHolder='Web Credentials Password*'
                    type='password'
                    required
                    light
                    variant='outlined'
                    testId='web-credentials-password'
                />
            </div>
        </>
    );
};
