/**
 * @generated SignedSource<<f1d5f731a2b8a481c2c96ff6d98d46d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BatteryTestType = "Capacity" | "Custom" | "Quick" | "%future added value";
export type ProvisioningStatus = "Active" | "Deleted" | "%future added value";
export type TaskRepeat = "BiMonthly" | "Monthly" | "Never" | "Quarterly" | "%future added value";
export type ScheduleFetchTaskQuery$variables = {
  id: string;
};
export type ScheduleFetchTaskQuery$data = {
  readonly task: {
    readonly __typename: string;
    readonly devices: {
      readonly data: ReadonlyArray<{
        readonly dualPlaneCompanion: {
          readonly device: {
            readonly id: string;
            readonly monitorOnly: boolean;
            readonly provisioningStatus: ProvisioningStatus;
          } | null;
        } | null;
        readonly id: string;
        readonly provisioningStatus: ProvisioningStatus;
      }>;
    };
    readonly name: string | null;
    readonly schedule?: {
      readonly repeat: TaskRepeat;
      readonly time: string;
    };
    readonly settings?: {
      readonly endOfDischargeVoltage?: number | null;
      readonly maxDischarged?: number | null;
      readonly maxDuration?: number | null;
      readonly reserveTime?: number;
      readonly targets?: ReadonlyArray<{
        readonly batteryType: {
          readonly id: string;
        } | null;
        readonly endOfDischargeVoltage: number;
      }>;
      readonly threshold?: number | null;
    };
    readonly type?: BatteryTestType;
    readonly usingSmartStart?: boolean;
  } | null;
};
export type ScheduleFetchTaskQuery = {
  response: ScheduleFetchTaskQuery$data;
  variables: ScheduleFetchTaskQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "provisioningStatus",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "filters",
      "value": {
        "monitorOnly": {
          "value": false
        },
        "supportsBatteryTesting": {
          "value": true
        }
      }
    }
  ],
  "concreteType": "PaginatedDevices",
  "kind": "LinkedField",
  "name": "devices",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Device",
      "kind": "LinkedField",
      "name": "data",
      "plural": true,
      "selections": [
        (v4/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "DualPlaneCompanion",
          "kind": "LinkedField",
          "name": "dualPlaneCompanion",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Device",
              "kind": "LinkedField",
              "name": "device",
              "plural": false,
              "selections": [
                (v4/*: any*/),
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "monitorOnly",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "devices(filters:{\"monitorOnly\":{\"value\":false},\"supportsBatteryTesting\":{\"value\":true}})"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "usingSmartStart",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v9 = [
  {
    "kind": "Literal",
    "name": "unit",
    "value": "Volts"
  }
],
v10 = {
  "alias": null,
  "args": (v9/*: any*/),
  "kind": "ScalarField",
  "name": "endOfDischargeVoltage",
  "storageKey": "endOfDischargeVoltage(unit:\"Volts\")"
},
v11 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BatteryTestTypeCapacityEDOVTypePair",
      "kind": "LinkedField",
      "name": "targets",
      "plural": true,
      "selections": [
        (v10/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "BatteryType",
          "kind": "LinkedField",
          "name": "batteryType",
          "plural": false,
          "selections": [
            (v4/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BatteryTestTypeCapacity",
  "abstractKey": null
},
v12 = {
  "kind": "InlineFragment",
  "selections": [
    (v10/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "unit",
          "value": "Minutes"
        }
      ],
      "kind": "ScalarField",
      "name": "maxDuration",
      "storageKey": "maxDuration(unit:\"Minutes\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "unit",
          "value": "AmpHour"
        }
      ],
      "kind": "ScalarField",
      "name": "maxDischarged",
      "storageKey": "maxDischarged(unit:\"AmpHour\")"
    }
  ],
  "type": "BatteryTestTypeCustom",
  "abstractKey": null
},
v13 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reserveTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v9/*: any*/),
      "kind": "ScalarField",
      "name": "threshold",
      "storageKey": "threshold(unit:\"Volts\")"
    }
  ],
  "type": "BatteryTestTypeQuick",
  "abstractKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "TaskSchedule",
  "kind": "LinkedField",
  "name": "schedule",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "time",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "repeat",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ScheduleFetchTaskQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "task",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v6/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "settings",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": null
              },
              (v14/*: any*/)
            ],
            "type": "BatteryTest",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ScheduleFetchTaskQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "task",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isTask"
          },
          (v2/*: any*/),
          (v6/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "settings",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": null
              },
              (v14/*: any*/)
            ],
            "type": "BatteryTest",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "25b6cd2bea0ac18897c6c42aa3e936bd",
    "id": null,
    "metadata": {},
    "name": "ScheduleFetchTaskQuery",
    "operationKind": "query",
    "text": "query ScheduleFetchTaskQuery(\n  $id: ID!\n) {\n  task(id: $id) {\n    __typename\n    __isTask: __typename\n    name\n    devices(filters: {supportsBatteryTesting: {value: true}, monitorOnly: {value: false}}) {\n      data {\n        id\n        provisioningStatus\n        dualPlaneCompanion {\n          device {\n            id\n            provisioningStatus\n            monitorOnly\n          }\n        }\n      }\n    }\n    ... on BatteryTest {\n      usingSmartStart\n      type\n      settings {\n        __typename\n        ... on BatteryTestTypeCapacity {\n          targets {\n            endOfDischargeVoltage(unit: Volts)\n            batteryType {\n              id\n            }\n          }\n        }\n        ... on BatteryTestTypeCustom {\n          endOfDischargeVoltage(unit: Volts)\n          maxDuration(unit: Minutes)\n          maxDischarged(unit: AmpHour)\n        }\n        ... on BatteryTestTypeQuick {\n          reserveTime\n          threshold(unit: Volts)\n        }\n      }\n      schedule {\n        time\n        repeat\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "9a63e476a535c75f8a4a86922c0d89eb";

export default node;
