/**
 * @generated SignedSource<<ab0c5f19f0d8b77430c1ec62c68c427f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BatteryTestCard_data$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"BatteryTestCardContent_task">;
  readonly " $fragmentType": "BatteryTestCard_data";
};
export type BatteryTestCard_data$key = {
  readonly " $data"?: BatteryTestCard_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"BatteryTestCard_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BatteryTestCard_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BatteryTestCardContent_task"
    }
  ],
  "type": "Task",
  "abstractKey": "__isTask"
};

(node as any).hash = "211d6399031bcdd9d14f18712910f21f";

export default node;
