/**
 * @generated SignedSource<<a4117c8d72d986522c6e07566a69e42c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DeviceBatteryHealthQuery$variables = {
  begin: string;
  end: string;
  id: string;
  interval: string;
};
export type DeviceBatteryHealthQuery$data = {
  readonly device: {
    readonly battery: {
      readonly metrics: {
        readonly batteryHealth: {
          readonly bucket: string;
          readonly end: string;
          readonly start: string;
          readonly values: ReadonlyArray<{
            readonly timestamp: string;
            readonly value: number | null;
          }>;
        } | null;
        readonly timeRunningOnBattery: {
          readonly bucket: string;
          readonly end: string;
          readonly start: string;
          readonly values: ReadonlyArray<{
            readonly timestamp: string;
            readonly value: number | null;
          }>;
        } | null;
      };
    };
  } | null;
};
export type DeviceBatteryHealthQuery = {
  response: DeviceBatteryHealthQuery$data;
  variables: DeviceBatteryHealthQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "begin"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "interval"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "begin",
    "variableName": "begin"
  },
  {
    "kind": "Variable",
    "name": "end",
    "variableName": "end"
  },
  {
    "kind": "Variable",
    "name": "interval",
    "variableName": "interval"
  }
],
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "start",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "end",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bucket",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "TimestampMetric",
    "kind": "LinkedField",
    "name": "values",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "timestamp",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "DeviceBattery",
  "kind": "LinkedField",
  "name": "battery",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceBatteryMetrics",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": (v5/*: any*/),
          "concreteType": "TimeSeriesMetrics",
          "kind": "LinkedField",
          "name": "timeRunningOnBattery",
          "plural": false,
          "selections": (v6/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v5/*: any*/),
          "concreteType": "TimeSeriesMetrics",
          "kind": "LinkedField",
          "name": "batteryHealth",
          "plural": false,
          "selections": (v6/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeviceBatteryHealthQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "DeviceBatteryHealthQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "70489a54b3831e8e35b81d4851f6df79",
    "id": null,
    "metadata": {},
    "name": "DeviceBatteryHealthQuery",
    "operationKind": "query",
    "text": "query DeviceBatteryHealthQuery(\n  $id: ID!\n  $begin: Timestamp!\n  $end: Timestamp!\n  $interval: Duration!\n) {\n  device(id: $id) {\n    battery {\n      metrics {\n        timeRunningOnBattery(begin: $begin, end: $end, interval: $interval) {\n          start\n          end\n          bucket\n          values {\n            timestamp\n            value\n          }\n        }\n        batteryHealth(begin: $begin, end: $end, interval: $interval) {\n          start\n          end\n          bucket\n          values {\n            timestamp\n            value\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "5d62c61c4166a09898550f0f23715e7d";

export default node;
