import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { Gauge, LoadableContentArea, Theme } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';

import { BatteriesGauge$key } from './__generated__/BatteriesGauge.graphql';
import style from './style.module.css';

export interface BatteriesGaugeProps {
    data: BatteriesGauge$key | null;
    error?: boolean;
    retry: () => void;
}

export const BatteriesGauge: FC<BatteriesGaugeProps> = ({ data, error, retry }) => {
    const props = useFragment<BatteriesGauge$key>(
        graphql`
            fragment BatteriesGauge on Query {
                devices {
                    total
                }
                batteryMetrics {
                    overallHealth {
                        status
                        count
                    }
                }
            }
        `,
        data
    );

    let healthy = 0;
    if (props && props.batteryMetrics.overallHealth) {
        props.batteryMetrics.overallHealth.forEach(element => {
            if (element.status === 'Healthy') {
                healthy = element.count;
            }
        });
    }

    const hasDevice = !!props && props.devices.total > 0;

    return (
        <LoadableContentArea
            className={style.gauge_container}
            variant='framed'
            data={props}
            error={error}
            onRetry={retry}
            render={props => {
                const value = hasDevice ? (healthy / props.devices.total) * 100 : 0;

                return (
                    <div className={style.gauge_container} data-testid='batteries-gauge'>
                        <Gauge
                            max={100}
                            value={value}
                            unit='%'
                            text='Healthy'
                            colour={Theme.customWhiteEggplant}
                            backgroundColour={Theme.customWhiteCoral}
                        />
                    </div>
                );
            }}
        />
    );
};
