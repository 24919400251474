/**
 * @generated SignedSource<<f31a6524435dd789828c0739d643a573>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DataCollectionFragment$data = {
  readonly dataCollection: {
    readonly deviceManagement: {
      readonly allocationDelay: string;
      readonly checkInInterval: string;
      readonly missedCheckInTimeout: string;
    };
    readonly polling: {
      readonly generalInterval: string;
      readonly highFrequencyInterval: string;
      readonly syncInterval: string;
      readonly timeout: string;
    };
  };
  readonly " $fragmentType": "DataCollectionFragment";
};
export type DataCollectionFragment$key = {
  readonly " $data"?: DataCollectionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DataCollectionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DataCollectionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DataCollectionSettings",
      "kind": "LinkedField",
      "name": "dataCollection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DataCollectionPollingSettings",
          "kind": "LinkedField",
          "name": "polling",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "generalInterval",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "highFrequencyInterval",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "syncInterval",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "timeout",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DeviceManagementSettings",
          "kind": "LinkedField",
          "name": "deviceManagement",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "checkInInterval",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "missedCheckInTimeout",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "allocationDelay",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Settings",
  "abstractKey": null
};

(node as any).hash = "ac59c46b0c14c811bc698b6ccf99129a";

export default node;
