/**
 * @generated SignedSource<<64cb9e79c6cb10c734a9e96c72f71934>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type queries_CapacityHistoQuery$variables = {
  from: number;
  interval: number;
  to: number;
};
export type queries_CapacityHistoQuery$data = {
  readonly batteryMetrics: {
    readonly remainingCapacityHistogram: {
      readonly buckets: ReadonlyArray<{
        readonly count: number;
        readonly rangeHigh: number;
        readonly rangeLow: number;
      }>;
      readonly countMax: number;
      readonly countMin: number;
      readonly rangeHigh: number;
      readonly rangeLow: number;
    } | null;
  };
};
export type queries_CapacityHistoQuery = {
  response: queries_CapacityHistoQuery$data;
  variables: queries_CapacityHistoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "from"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "interval"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "to"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rangeLow",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rangeHigh",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GlobalBatteryMetrics",
    "kind": "LinkedField",
    "name": "batteryMetrics",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "interval",
            "variableName": "interval"
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "from",
                "variableName": "from"
              },
              {
                "kind": "Variable",
                "name": "to",
                "variableName": "to"
              }
            ],
            "kind": "ObjectValue",
            "name": "range"
          },
          {
            "kind": "Literal",
            "name": "unit",
            "value": "AmpHour"
          }
        ],
        "concreteType": "Histogram",
        "kind": "LinkedField",
        "name": "remainingCapacityHistogram",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "HistogramBucket",
            "kind": "LinkedField",
            "name": "buckets",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "count",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "countMin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "countMax",
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "queries_CapacityHistoQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "queries_CapacityHistoQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "88b7bde59a7a43ad981f6057b8c274f6",
    "id": null,
    "metadata": {},
    "name": "queries_CapacityHistoQuery",
    "operationKind": "query",
    "text": "query queries_CapacityHistoQuery(\n  $from: Float!\n  $to: Float!\n  $interval: Float!\n) {\n  batteryMetrics {\n    remainingCapacityHistogram(unit: AmpHour, interval: $interval, range: {from: $from, to: $to}) {\n      buckets {\n        rangeLow\n        rangeHigh\n        count\n      }\n      countMin\n      countMax\n      rangeLow\n      rangeHigh\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9ad3704f53b0ab426d0c5dfe669758a5";

export default node;
