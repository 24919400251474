import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { Button } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { useUserPermissions } from 'lib/auth';
import { Paths } from 'lib/routes';

import { BatteryTestCard } from '../components/BatteryTestCard/BatteryTestCard';
import { BatteryTestResultCard } from '../components/BatteryTestResultCard';
import { CardSection } from '../components/CardSection';
import {
    InProgressTestsAndDischarges_tasks$data,
    InProgressTestsAndDischarges_tasks$key,
} from './__generated__/InProgressTestsAndDischarges_tasks.graphql';
import {
    InProgressTestsAndDischarges_tests$data,
    InProgressTestsAndDischarges_tests$key,
} from './__generated__/InProgressTestsAndDischarges_tests.graphql';

type InProgressTask = InProgressTestsAndDischarges_tasks$data['data'][number];
type InProgressExternalDischarge = InProgressTestsAndDischarges_tests$data['data'][number];

export interface InProgressTestsAndDischargesProps {
    tasks: InProgressTestsAndDischarges_tasks$key | null;
    externalDischarges: InProgressTestsAndDischarges_tests$key | null;
}

export const InProgressTestsAndDischarges: FC<InProgressTestsAndDischargesProps> = ({ tasks, externalDischarges }) => {
    const { hasTasksWrite } = useUserPermissions();

    const taskResults = useFragment<InProgressTestsAndDischarges_tasks$key>(
        graphql`
            fragment InProgressTestsAndDischarges_tasks on PaginatedTasks {
                total
                data {
                    id
                    __typename
                    commencedTime
                    ... on BatteryTest {
                        ...BatteryTestCard_data
                    }
                }
            }
        `,
        tasks
    );

    const externalDischargeResults = useFragment<InProgressTestsAndDischarges_tests$key>(
        graphql`
            fragment InProgressTestsAndDischarges_tests on PaginatedDeviceBatteryTestResults {
                total
                data {
                    id
                    __typename
                    commencedTime
                    ...BatteryTestResultCard_test
                }
            }
        `,
        externalDischarges
    );

    if (!taskResults || !externalDischargeResults) {
        return <></>;
    }

    // Intermix the external discharge tests with the battery tests ordered by start time
    const orderedInProgressItems: Array<InProgressTask | InProgressExternalDischarge> = [
        ...taskResults.data,
        ...externalDischargeResults.data,
    ].sort((a, b) => {
        return new Date(b.commencedTime as string).getTime() - new Date(a.commencedTime as string).getTime();
    });

    if (orderedInProgressItems.length === 0) {
        return (
            <CardSection title='Happening now' testId='inprogress-section'>
                <div className='flex flex-col items-start justify-start gap-4 bg-white p-8 col-span-3'>
                    <div>
                        <div className='text-lg text-customEggplant font-CynthoNext-SemiBold'>All quiet in here...</div>
                        <div className='text-base font-CynthoNext-Regular text-customEggplantWhite mb-4'>
                            There are no battery tests running right now.
                        </div>
                        {hasTasksWrite && (
                            <Button buttonText='Start a test' size='small' to={Paths.TestsScheduleTest} />
                        )}
                    </div>
                </div>
            </CardSection>
        );
    }

    return (
        <CardSection title='Happening now' testId='inprogress-section'>
            {orderedInProgressItems.map(task => {
                if (task.__typename === 'DeviceBatteryTestResults') {
                    return (
                        <BatteryTestResultCard key={task.id} test={(task as unknown) as InProgressExternalDischarge} />
                    );
                } else {
                    return <BatteryTestCard key={task.id} task={(task as unknown) as InProgressTask} />;
                }
            })}
        </CardSection>
    );
};
