import React, { FC, useEffect, useMemo, useState } from 'react';
import { PreloadedQuery, fetchQuery, loadQuery, usePreloadedQuery, useRelayEnvironment } from 'react-relay';

import { captureException, captureMessage } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { AlertTableColumn, IsAlertActive, alertToFilterObject, useAlertFilter } from 'filters/alert';
import { FilterActionType } from 'filters/common';
import { SortDirection, useTableReducer } from 'layouts';
import { getGlobalEnvironment } from 'lib/environment';
import { AllTableColumns, BaseTableColumns } from 'views/reports/alerts/alert-list/settings';

import { DevicePane } from '../../components/DevicePane';
import { InsightsContentInitialQuery } from './__generated__/InsightsContentInitialQuery.graphql';
import {
    AlertOrdering,
    AlertSortField,
    InsightsContentRefetchQuery,
    InsightsContentRefetchQuery$variables,
} from './__generated__/InsightsContentRefetchQuery.graphql';
import { InsightTable } from './components/InsightTable';
import { InsightTable_alerts$key } from './components/__generated__/InsightTable_alerts.graphql';

export interface InsightsContentProps {
    queryRef: PreloadedQuery<InsightsContentInitialQuery>;
    deviceId: string;
}

export const InsightsContent: FC<InsightsContentProps> = ({ queryRef, deviceId }) => {
    const initialData = usePreloadedQuery(InsightsQuery, queryRef);
    const environment = useRelayEnvironment();

    const [filters, dispatchFilters] = useAlertFilter();
    const [tableState, dispatchTableState] = useTableReducer<AlertTableColumn>({
        defaultSortColumn: AlertTableColumn.RaiseDate,
        defaultSortDirection: SortDirection.Descending,
        allColumns: AllTableColumns.map(column => column.id),
        defaultVisibleColumns: BaseTableColumns.map(column => column.id),
        storageKeyPrefix: 'site-view-alerts-table',
    });

    const filterObject = useMemo(() => alertToFilterObject(filters), [filters]);

    const [onlyActiveAlerts, setOnlyActiveAlerts] = useState<boolean>(true);

    const [tableData, setData] = useState<InsightTable_alerts$key>(initialData.alerts);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);

    useEffect(() => {
        const sortObject: AlertOrdering = {
            field: tableState.sortColumn as AlertSortField,
            dir: tableState.sortDirection === SortDirection.Ascending ? 'Asc' : 'Desc',
        };

        const refetchVariables: InsightsContentRefetchQuery$variables = {
            id: deviceId,
            page: tableState.page,
            orderBy: sortObject,
            filter: filterObject,
            onlyActiveAlerts,
        };

        setIsFetching(true);
        setHasError(false);

        fetchQuery<InsightsContentRefetchQuery>(environment, InsightRefetchQuery, refetchVariables).subscribe({
            next(value) {
                setData(value.alerts);
                setIsFetching(false);
            },
            error(error: unknown) {
                captureException(error, scope => {
                    scope.setExtra('variables', refetchVariables);
                    scope.setTag('Component', 'InsightsContent');
                    return scope;
                });
                setHasError(true);
            },
        });
    }, [
        deviceId,
        environment,
        filterObject,
        filters,
        onlyActiveAlerts,
        tableState.page,
        tableState.sortColumn,
        tableState.sortDirection,
    ]);

    if (!initialData.device) {
        // This should never happen as the DeviceLayout validates devices
        captureMessage('Assertion failed: device is null in InsightsContent', scope => {
            scope.setExtra('data', tableData);
            scope.setTag('Component', 'InsightsContent');
            return scope;
        });
        return null;
    }

    const primaryName = initialData.device.name;
    let title: string;
    if (initialData.device.dualPlaneCompanion?.device) {
        const secondaryName = initialData.device.dualPlaneCompanion.device.name;

        if (secondaryName < primaryName) {
            title = `${secondaryName} & ${primaryName}`;
        } else {
            title = `${primaryName} & ${secondaryName}`;
        }
    } else {
        title = primaryName;
    }

    return (
        <DevicePane title={title}>
            <div className='font-CynthoNext-SemiBold text-xl'>Insights</div>
            <InsightTable
                tableData={tableData}
                filterState={filters}
                dispatchFilterState={dispatchFilters}
                tableState={tableState}
                dispatchTableState={dispatchTableState}
                onlyActiveAlerts={onlyActiveAlerts}
                toggleActiveAlerts={() => {
                    setOnlyActiveAlerts(prevValue => {
                        if (filters.columnValues.IsActive === IsAlertActive.No && !prevValue) {
                            dispatchFilters({
                                type: FilterActionType.Clear,
                                column: AlertTableColumn.IsActive,
                            });
                        }
                        return !prevValue;
                    });
                }}
                isProcessing={isFetching}
                hasError={hasError}
            />
        </DevicePane>
    );
};

export const InsightsQuery = graphql`
    query InsightsContentInitialQuery($id: ID!) {
        device(id: $id) {
            name
            dualPlaneCompanion {
                device {
                    name
                }
            }
        }
        alerts(deviceIds: [$id], includeCompanionDevices: true) {
            ...InsightTable_alerts
        }
    }
`;

const InsightRefetchQuery = graphql`
    query InsightsContentRefetchQuery(
        $id: ID!
        $page: Int = 1
        $orderBy: AlertOrdering
        $filter: AlertFilter
        $onlyActiveAlerts: Boolean
    ) {
        alerts(
            deviceIds: [$id]
            includeCompanionDevices: true
            page: $page
            orderBy: $orderBy
            filters: $filter
            onlyActiveAlerts: $onlyActiveAlerts
        ) {
            ...InsightTable_alerts
        }
    }
`;

export function loadInsightsPageData(id: string) {
    return loadQuery(
        getGlobalEnvironment(),
        InsightsQuery,
        {
            id,
        },
        {
            fetchPolicy: 'store-and-network',
        }
    );
}
