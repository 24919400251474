import React, { FC } from 'react';

import { FilterDateSelect } from 'components/FilterDateSelect';
import { DateRangeFilter, PredefinedRange } from 'components/FilterDateSelect/common';
import { LastMonth, LastWeek, MoreThan1Month } from 'components/FilterDateSelect/presets';

import { CommonFilterProps } from '../../common/types';

export type AlertDateFilterUIProps = CommonFilterProps<DateRangeFilter>;

const FilterPresets: PredefinedRange[] = [LastWeek, LastMonth, MoreThan1Month];

export const AlertDateFilterUI: FC<AlertDateFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    return (
        <FilterDateSelect
            title='Filter by date'
            current={current ?? null}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onClose={onClose}
            showBack={hasPrevious}
            presets={FilterPresets}
        />
    );
};
