import React, { FC, Suspense } from 'react';
import { PreloadedQuery } from 'react-relay';

import { CrossIcon, FilterContainer, IconWithTitle } from '@accesstel/pcm-ui';

import { SelectDevicesPaneContent } from './SelectDevicesPaneContent';
import { SelectDevicesPaneContentQuery } from './__generated__/SelectDevicesPaneContentQuery.graphql';

export interface SelectDevicesPaneProps {
    current: string[];
    onClose: () => void;
    onSubmit: (selection: string[]) => void;
    onShowSelectionModal: () => void;
    metadataQueryRef: PreloadedQuery<SelectDevicesPaneContentQuery>;
}

export const SelectDevicesPane: FC<SelectDevicesPaneProps> = ({
    onClose,
    onSubmit,
    onShowSelectionModal,
    current,
    metadataQueryRef,
}) => {
    return (
        <FilterContainer
            title='Select devices'
            onClose={onClose}
            primaryContent={
                <Suspense>
                    <SelectDevicesPaneContent
                        metadataQueryRef={metadataQueryRef}
                        current={current}
                        onClose={onClose}
                        onSubmit={onSubmit}
                        onSelectCustomDevices={onShowSelectionModal}
                    />
                </Suspense>
            }
            hideConfirmButton
            hideClearButton={current.length === 0}
            customButton={
                <IconWithTitle
                    title='Clear devices'
                    icon={<CrossIcon />}
                    iconStroke='white'
                    size='small'
                    circleColor='whiteEggplant'
                    fontFamily='light'
                />
            }
            onClearClick={() => onSubmit([])}
        />
    );
};
