import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';
import { generatePath } from 'react-router-dom';

import { BarDataType, DeviceIcon, LabelFormatterType } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { useDocumentTitle } from 'components';
import {
    BatteryTechnology,
    BatteryTypeTableColumn,
    batteryTypeToFilterObject,
    useBatteryTypeFilter,
} from 'filters/battery-type';
import { FilterActionType } from 'filters/common';
import { SortDirection, TableActionType, TableLayout, useTableReducer } from 'layouts';
import { SearchResultWithGroups } from 'layouts/TableLayout/components/Search';
import { useUserPermissions } from 'lib/auth';
import { BatteryTechnologyType } from 'lib/provision';
import { useQuery } from 'lib/query-helpers';
import { Paths } from 'lib/routes';
import { formatBatteryType } from 'lib/textFormatters';
import { IEnvironment } from 'relay-runtime';

import { colorPalette } from '../AssetManagement';
import { queries_GetAssetsDistributionQuery } from '../__generated__/queries_GetAssetsDistributionQuery.graphql';
import { getAssetsDistribution } from '../queries';
import { BatteryTypesAllIdsQuery } from './__generated__/BatteryTypesAllIdsQuery.graphql';
import { BatteryTypesSearchQuery, BatteryTypesSearchQuery$data } from './__generated__/BatteryTypesSearchQuery.graphql';
import {
    BatteryTypeOrdering,
    BatteryTypeSortField,
    BatteryTypesTableQuery,
    BatteryTypesTableQuery$data,
    BatteryTypesTableQuery$variables,
} from './__generated__/BatteryTypesTableQuery.graphql';
import { DeleteBatteryTypesModal } from './components';
import { AllTableColumns, BaseTableColumns } from './settings';

type BatteryType = BatteryTypesTableQuery$data['batteryTypes']['data'][number];
type BatteryTypeSearchResult = BatteryTypesSearchQuery$data['batteryTypes']['data'][number];

const TableStorageKeyPrefix = 'battery-type-table';

export const ManageBatteryTypes: FC = () => {
    const { hasAssetsWrite } = useUserPermissions();
    const environment = useRelayEnvironment();

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [tableState, dispatchTableState] = useTableReducer<BatteryTypeTableColumn>({
        defaultSortColumn: BatteryTypeTableColumn.Manufacturer,
        allColumns: AllTableColumns.map(column => column.id),
        defaultVisibleColumns: BaseTableColumns.map(column => column.id),
        storageKeyPrefix: TableStorageKeyPrefix,
    });

    const [filters, dispatchFilters] = useBatteryTypeFilter();
    const filterObject = useMemo(() => batteryTypeToFilterObject(filters), [filters]);

    const sortObject: BatteryTypeOrdering = {
        field: tableState.sortColumn as BatteryTypeSortField,
        dir: tableState.sortDirection === SortDirection.Ascending ? 'Asc' : 'Desc',
    };

    useDocumentTitle('Battery Type Management');

    const variables: BatteryTypesTableQuery$variables = {
        page: tableState.page,
        search: tableState.search,
        filter: filterObject,
        orderBy: sortObject,
        withCapacityRating: tableState.visibleColumnsInOrder.includes(BatteryTypeTableColumn.CellCapacityRating),
        withReferenceTemperature: tableState.visibleColumnsInOrder.includes(
            BatteryTypeTableColumn.CellReferenceTemperature
        ),
        withCellsPerBloc: tableState.visibleColumnsInOrder.includes(BatteryTypeTableColumn.CellsPerBloc),
        withPeukertsConstant: tableState.visibleColumnsInOrder.includes(BatteryTypeTableColumn.PeukertsConstant),
    };

    const { data: props, error, retry, isFetching } = useQuery<BatteryTypesTableQuery>(
        graphql`
            query BatteryTypesTableQuery(
                $page: Int = 1
                $search: String = ""
                $filter: BatteryTypeFilter
                $orderBy: BatteryTypeOrdering
                $withCapacityRating: Boolean = false
                $withReferenceTemperature: Boolean = false
                $withCellsPerBloc: Boolean = false
                $withPeukertsConstant: Boolean = false
            ) {
                batteryTypes(page: $page, search: $search, filters: $filter, orderBy: $orderBy) {
                    total
                    data {
                        id
                        model
                        manufacturer
                        cells {
                            capacity
                            capacityRating @include(if: $withCapacityRating)
                            referenceTemperature @include(if: $withReferenceTemperature)
                        }
                        cellsPerBloc @include(if: $withCellsPerBloc)
                        peukertsConstant @include(if: $withPeukertsConstant)
                        technology
                    }
                    pageInfo {
                        page
                        size
                        total
                        hasNext
                        hasPrevious
                    }
                }
                overallBatteryTypes: batteryTypes {
                    total
                }
            }
        `,
        variables,
        {
            fetchPolicy: 'network-only',
        }
    );

    const { data: distributionProps } = useQuery<queries_GetAssetsDistributionQuery>(
        getAssetsDistribution,
        { type: 'BatteryTypeTechnology' },
        { fetchPolicy: 'network-only' }
    );

    const handleSearch = useCallback(
        (input: string): Promise<SearchResultWithGroups<BatteryTypeSearchResult>> => {
            return fetchQuery<BatteryTypesSearchQuery>(
                environment,
                graphql`
                    query BatteryTypesSearchQuery($search: String = "", $pageSize: Int!) {
                        batteryTypes(search: $search, pageSize: $pageSize) {
                            data {
                                id
                                model
                                manufacturer
                                technology
                            }
                        }
                    }
                `,
                { search: input, pageSize: 10 }
            )
                .toPromise()
                .then(result => {
                    const results = (result?.batteryTypes.data as BatteryTypeSearchResult[]) ?? [];
                    const groups = results.map(searchResult => ({
                        key: searchResult.manufacturer,
                        title: <span className='text-customEggplant'>{searchResult.manufacturer}</span>,
                    }));

                    return { results, groups };
                });
        },
        [environment]
    );

    const distributionData: BarDataType[] =
        distributionProps?.assetDistribution?.distribution.map((group, i) => {
            let bgColor = undefined;
            if (colorPalette.length > i) {
                bgColor = colorPalette[i];
            }

            return {
                id: group.key,
                label: group.displayName ?? group.key,
                value: group.value,
                bgClass: bgColor,
            };
        }) ?? [];

    const labelFormatter: LabelFormatterType<BarDataType> = useCallback((label, data) => {
        if (label === null) {
            return '';
        }
        return `${data.value} ${formatBatteryType(label as BatteryTechnologyType)}`;
    }, []);

    const onSegmentClick = (id: string) => {
        dispatchFilters({
            type: FilterActionType.Apply,
            column: BatteryTypeTableColumn.Technology,
            value: [{ name: formatBatteryType(id as BatteryTechnologyType), id: id as BatteryTechnology }],
        });
    };

    return (
        <>
            <TableLayout
                title='Battery Type Management'
                columns={AllTableColumns}
                allowEditingColumns
                filterState={filters}
                dispatchFilterState={dispatchFilters}
                tableState={tableState}
                dispatchTableState={dispatchTableState}
                data={(props?.batteryTypes.data ?? null) as BatteryType[]}
                getRowId={row => row.id}
                isProcessing={!!props && isFetching}
                page={props?.batteryTypes.pageInfo.page}
                pageCount={props?.batteryTypes.pageInfo.total}
                overallCount={props?.overallBatteryTypes.total}
                resultCount={props?.batteryTypes.total}
                hasError={!!error}
                onRetry={retry}
                searchPlaceholder='Search by Battery Model/Manufacturer'
                onSearch={handleSearch}
                searchBoxProperties={{
                    groupKey: 'manufacturer',
                }}
                renderSearchResult={renderSearchResult}
                renderSearchResultAsString={(item: BatteryTypeSearchResult) => item.model}
                emptyMessage='There are no battery types present'
                unit='Battery Type'
                primaryAction={hasAssetsWrite ? 'Add new battery type' : undefined}
                primaryActionLink={hasAssetsWrite ? Paths.AddBatteryType : undefined}
                selection={hasAssetsWrite}
                onRequestAllIds={() => getAllBatteryTypeIds(environment, filterObject)}
                getItemLink={getBatteryTypeLink}
                selectionActionMessage='Delete selected %'
                onSelectionAction={() => setShowDeleteModal(true)}
                barChartData={{
                    data: distributionData,
                    emptyLabel: 'No battery types added',
                    labelFormatter,
                    onSegmentClick,
                }}
            />
            {showDeleteModal && (
                <DeleteBatteryTypesModal
                    selectedBatteryTypes={tableState.selectedItems}
                    onDone={() => dispatchTableState({ type: TableActionType.SetSelection, selection: [] })}
                    setShowDeleteModal={setShowDeleteModal}
                    showDeleteModal={showDeleteModal}
                    tableRetry={retry}
                />
            )}
        </>
    );
};

function getAllBatteryTypeIds(environment: IEnvironment, filters: Record<string, unknown>): Promise<string[]> {
    const getAllBatteryTypeIdsQuery = graphql`
        query BatteryTypesAllIdsQuery($filters: BatteryTypeFilter) {
            batteryTypes(onlyProvisioningStatuses: Active, pageSize: 10000, filters: $filters) {
                data {
                    id
                }
            }
        }
    `;

    return fetchQuery<BatteryTypesAllIdsQuery>(environment, getAllBatteryTypeIdsQuery, { filters })
        .toPromise()
        .then(data => data?.batteryTypes.data.map(batteryType => batteryType.id) ?? []);
}

function getBatteryTypeLink(batteryType: BatteryType): string {
    return generatePath(Paths.EditBatteryType, { id: batteryType.id });
}

function renderSearchResult(result: BatteryTypeSearchResult): ReactNode {
    return (
        <div className='flex flex-row items-center space-x-2 text-customEggplantCoral'>
            <div className='h-4 w-4 ml-1 inline-block align-middle'>
                <DeviceIcon />
            </div>
            <div className='flex flex-row items-baseline space-x-2'>
                <span>{result.model}</span>
                <span className='font-CynthoNext-Light text-xs'>{formatBatteryType(result.technology)}</span>
            </div>
        </div>
    );
}
