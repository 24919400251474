import React, { FC } from 'react';

import { CommonFilterProps } from 'filters/common';

import { TestResultMetricsFilter } from '../types';
import { TestResultFilterRange } from './TestResultFilterRange';

type MetricsType = 'CurrentMetrics' | 'DischargedMetrics' | 'TemperatureMetrics' | 'VoltageMetrics';

export interface TestResultMetricsFilterUIProps extends CommonFilterProps<TestResultMetricsFilter | null> {
    type: MetricsType;
}

export const TestResultMetricsFilterUI: FC<TestResultMetricsFilterUIProps> = ({
    type,
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    const metrics = getDisplayMetrics(type);

    return (
        <TestResultFilterRange
            current={current}
            onClearOrBack={onClearOrBack}
            onClose={onClose}
            onSubmit={onSubmit}
            title={`Filter by ${metrics}`}
            distributionType={type}
            showBack={hasPrevious}
        />
    );
};

function getDisplayMetrics(type: MetricsType): string {
    switch (type) {
        case 'CurrentMetrics':
            return 'Average Current';
        case 'TemperatureMetrics':
            return 'Average Temperature';
        case 'VoltageMetrics':
            return 'End Voltage';
        case 'DischargedMetrics':
            return 'Total Discharged';
    }
}
