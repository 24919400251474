import React, { FC } from 'react';

import { DischargeTable, FormSection } from '@accesstel/pcm-ui';

import { getQualifiedName } from 'lib/namespaces';

import manageStyle from '../../style.module.css';
import { CellDetail, CellVoltage, GeneralInformation } from './components';

export interface BatteryInformationProps {
    namespace?: string;
}

export const BatteryInformation: FC<BatteryInformationProps> = ({ namespace }) => {
    return (
        <div className='space-y-6'>
            <GeneralInformation namespace={namespace} />

            <CellDetail namespace={namespace} />

            <CellVoltage namespace={namespace} />

            <FormSection
                label='Discharge Tables'
                blurb='In this section, the discharge tables for this battery type can be entered.'
            >
                <div className={manageStyle.provisioning_content_grid}>
                    <div className='col-start-1 col-span-2'>
                        <DischargeTable
                            name={getQualifiedName('dischargeTables', namespace)}
                            // TODO: PCM-788 allow power discharge table
                            features='Current'
                        />
                    </div>
                </div>
            </FormSection>
        </div>
    );
};
