import React, { FC } from 'react';

import { FilterDurationSelect } from 'components/FilterDurationSelect';
import { PredefinedDuration, RunTimeFilter } from 'components/FilterDurationSelect/common';
import { Less1Hour, Less30Min, More1Hour } from 'components/FilterDurationSelect/presets';
import { CommonFilterProps } from 'filters/common';

const FilterPresets: PredefinedDuration[] = [Less30Min, Less1Hour, More1Hour];

export type TaskRunTimeFilterUIProps = CommonFilterProps<RunTimeFilter>;

export const TaskRunTimeFilterUI: FC<TaskRunTimeFilterUIProps> = props => {
    return <FilterDurationSelect title='Filter by task run time' presets={FilterPresets} {...props} />;
};
