/**
 * @generated SignedSource<<6ab9aab9d5c13b363b7d9672105fdbfa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BatteryTypeModelFilterUI_modelSearchQuery$variables = {
  input: string;
  maxResults: number;
};
export type BatteryTypeModelFilterUI_modelSearchQuery$data = {
  readonly batteryTypeModels: {
    readonly names: ReadonlyArray<string>;
  } | null;
};
export type BatteryTypeModelFilterUI_modelSearchQuery = {
  response: BatteryTypeModelFilterUI_modelSearchQuery$data;
  variables: BatteryTypeModelFilterUI_modelSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "maxResults"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "includeDeleted",
        "value": false
      },
      {
        "kind": "Variable",
        "name": "limit",
        "variableName": "maxResults"
      },
      {
        "kind": "Variable",
        "name": "match",
        "variableName": "input"
      }
    ],
    "concreteType": "BatteryModelLookup",
    "kind": "LinkedField",
    "name": "batteryTypeModels",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "names",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BatteryTypeModelFilterUI_modelSearchQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BatteryTypeModelFilterUI_modelSearchQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "aef215b3fe2eaa82a0fe7658938639c8",
    "id": null,
    "metadata": {},
    "name": "BatteryTypeModelFilterUI_modelSearchQuery",
    "operationKind": "query",
    "text": "query BatteryTypeModelFilterUI_modelSearchQuery(\n  $input: String!\n  $maxResults: Int!\n) {\n  batteryTypeModels(match: $input, limit: $maxResults, includeDeleted: false) {\n    names\n  }\n}\n"
  }
};
})();

(node as any).hash = "fcd3a479fe2b5d5bbea81765957c7ec2";

export default node;
