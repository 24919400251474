import React, { FC, useCallback, useEffect, useState } from 'react';
import { fetchQuery } from 'react-relay';
import { useRelayEnvironment } from 'react-relay';

import { SearchBox } from '@accesstel/pcm-ui';

import { logError } from 'lib/log';
import type { queries_SearchQuery } from 'views/reports/batteries/__generated__/queries_SearchQuery.graphql';
import { searchBoxQuery } from 'views/reports/batteries/queries';
import { ReportSearchResults, SearchGroups, renderSearchResult } from 'views/reports/batteries/search';

export interface SearchResultAction {
    action: 'none' | 'refine';
    filter?: string;
}

export interface DeviceSiteSearchProps {
    setTableFilter: (filter: string) => void;
    onResultClick: (item: ReportSearchResults) => SearchResultAction;
    onResetSearch: () => void;
}

export const DeviceSiteSearch: FC<DeviceSiteSearchProps> = ({ setTableFilter, onResultClick, onResetSearch }) => {
    const environment = useRelayEnvironment();
    const [searchInput, setSearchInput] = useState<string>('');
    const [searchResults, setSearchResults] = useState<ReportSearchResults[]>();
    const [showReset, setShowReset] = useState<boolean>(false);

    useEffect(() => {
        if (searchInput && searchInput.length !== 0 && searchInput.length >= 2) {
            fetchQuery<queries_SearchQuery>(environment, searchBoxQuery, {
                search: searchInput,
                pageSize: 10,
            })
                .toPromise()
                .then(results => {
                    const deviceResults: ReportSearchResults[] =
                        results?.devices.data.map(result => ({
                            id: result.id,
                            name: result.name,
                            site: result.site.id,
                            siteName: result.site.name,
                            state: result.site.address.state,
                            type: 'device',
                        })) ?? [];

                    const siteResults: ReportSearchResults[] =
                        results?.sites.data.map(result => ({
                            id: result.id,
                            name: result.name,
                            site: result.id,
                            siteName: result.name,
                            state: result.address.state,
                            type: 'site',
                        })) ?? [];

                    setSearchResults(deviceResults.concat(siteResults));
                })
                .catch(error => {
                    logError('Failed to retrieve search results', error);
                    // TODO: Error state for suggestions box
                    setSearchResults([]);
                });
        } else {
            setSearchResults(undefined);
            setTableFilter('');
        }
    }, [environment, searchInput, setSearchInput, setTableFilter]);

    const doSearch = useCallback(
        (value: string) => {
            setShowReset(true);
            setTableFilter(value);
        },
        [setTableFilter]
    );

    const doResultClick = useCallback(
        (item: ReportSearchResults) => {
            setShowReset(true);
            const action = onResultClick(item);
            if (action.action === 'refine' && action.filter != null) {
                setTableFilter(action.filter);
                setSearchInput(action.filter);
            }
        },
        [setTableFilter, onResultClick]
    );

    const doReset = useCallback(() => {
        setShowReset(false);
        setSearchInput('');
        setTableFilter('');
        onResetSearch();
    }, [setTableFilter, onResetSearch]);

    function handleChange(value: string) {
        setShowReset(false);
        setSearchInput(value);
    }

    return (
        <SearchBox
            autoFocus
            id='test-device-search'
            placeHolder='Search by Site/Device'
            value={searchInput}
            onChange={handleChange}
            renderResult={renderSearchResult}
            searchResults={searchResults}
            moreResults={searchInput.length > 0}
            groups={SearchGroups}
            groupKey='type'
            onSubmit={doSearch}
            onReset={doReset}
            onResultClick={doResultClick}
            showReset={showReset}
            backgroundClass='bg-customGray'
            className='bg-customGray'
        />
    );
};
