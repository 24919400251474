/**
 * @generated SignedSource<<4bb27cc017e9df4bb199b6ebefe5f272>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TestNameFilterUI_testNameSearchQuery$variables = {
  input: string;
  maxResults: number;
  testIds?: ReadonlyArray<string> | null;
};
export type TestNameFilterUI_testNameSearchQuery$data = {
  readonly batteryTestResults: {
    readonly data: ReadonlyArray<{
      readonly task: {
        readonly id: string;
        readonly name: string | null;
      } | null;
    }>;
  };
};
export type TestNameFilterUI_testNameSearchQuery = {
  response: TestNameFilterUI_testNameSearchQuery$data;
  variables: TestNameFilterUI_testNameSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "maxResults"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "testIds"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "testIds"
  },
  {
    "kind": "Variable",
    "name": "pageSize",
    "variableName": "maxResults"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "BatteryTest",
  "kind": "LinkedField",
  "name": "task",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TestNameFilterUI_testNameSearchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PaginatedDeviceBatteryTestResults",
        "kind": "LinkedField",
        "name": "batteryTestResults",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceBatteryTestResults",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TestNameFilterUI_testNameSearchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PaginatedDeviceBatteryTestResults",
        "kind": "LinkedField",
        "name": "batteryTestResults",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceBatteryTestResults",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "940d54fdfec9e1abea1e6a82d4ed4cdc",
    "id": null,
    "metadata": {},
    "name": "TestNameFilterUI_testNameSearchQuery",
    "operationKind": "query",
    "text": "query TestNameFilterUI_testNameSearchQuery(\n  $input: String!\n  $maxResults: Int!\n  $testIds: [String!]\n) {\n  batteryTestResults(search: $input, pageSize: $maxResults, ids: $testIds) {\n    data {\n      task {\n        id\n        name\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a0e4b5dbef2cb1cb78a405926d55d912";

export default node;
