import React, { FC } from 'react';
import { useRelayEnvironment } from 'react-relay';
import { fetchQuery } from 'react-relay';
import { useParams } from 'react-router-dom';

import graphql from 'babel-plugin-relay/macro';
import { FilterSearchSelect } from 'components/FilterSearchSelect';
import { IEnvironment } from 'relay-runtime';

import { CommonFilterProps } from '../../common/types';
import { BatteryTestNameFilter, PageTypes } from '../types';
import { getTaskStateFromPageType } from '../util';
import { TaskBatteryTestNameFilterResult } from './TaskBatteryTestNameFilterResult';
import { TaskBatteryTestNameFilterUI_testNameSearchQuery } from './__generated__/TaskBatteryTestNameFilterUI_testNameSearchQuery.graphql';

export interface TaskBatteryTestNameFilterUIProps extends CommonFilterProps<BatteryTestNameFilter[]> {
    // limits the search that matches these device ids
    deviceIds?: string[];
}

export const TaskBatteryTestNameFilterUI: FC<TaskBatteryTestNameFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
    deviceIds,
}) => {
    const environment = useRelayEnvironment();
    // get the pageType so we can limit the search results to only those that match the page we're on
    // FIXME: Casting isnt the best way of handling undefined type check. Currently, there are multiple ways people are dealing with this.
    // https://github.com/remix-run/react-router/issues/8200
    const { pageType } = useParams() as { pageType: PageTypes };

    return (
        <FilterSearchSelect
            active={current}
            title='Filter by battery test'
            placeholder='Enter battery test name'
            renderItem={item => <TaskBatteryTestNameFilterResult filter={item} />}
            renderItemSimple={item => item.name}
            showBack={hasPrevious}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onSearch={input => doSearch(environment, input, 5, pageType, deviceIds)}
            onClose={onClose}
            createDefaultItem={input => {
                if (input.trim().length > 0) {
                    return createWildcardFilter(input);
                } else {
                    return null;
                }
            }}
        />
    );
};

export async function doSearch(
    environment: IEnvironment,
    input: string,
    maxResults: number,
    pageType: PageTypes,
    deviceIds?: string[]
): Promise<BatteryTestNameFilter[]> {
    try {
        const results = await fetchQuery<TaskBatteryTestNameFilterUI_testNameSearchQuery>(
            environment,
            graphql`
                query TaskBatteryTestNameFilterUI_testNameSearchQuery(
                    $input: String!
                    $maxResults: Int!
                    $state: [String!]
                    $deviceIds: [ID!]
                ) {
                    tasks(
                        filters: { name: [{ value: $input }], deviceIds: $deviceIds }
                        pageSize: $maxResults
                        state: $state
                    ) {
                        data {
                            id
                            name
                        }
                    }
                }
            `,
            {
                input,
                maxResults: maxResults - 1,
                state: getTaskStateFromPageType(pageType),
                deviceIds,
            }
        ).toPromise();

        const tasks = results?.tasks.data ?? [];

        const output: BatteryTestNameFilter[] = tasks
            .filter(task => task.name)
            .map(task => ({
                type: 'result',
                id: task.id,
                name: task.name!,
            }));

        if (input.trim().length > 0) {
            if (output.length >= maxResults) {
                output[output.length - 1] = createWildcardFilter(input);
            } else {
                output.push(createWildcardFilter(input));
            }
        }

        return output;
    } catch (error) {
        return [];
    }
}

function createWildcardFilter(input: string): BatteryTestNameFilter {
    return {
        type: 'special',
        name: input.trim(),
        wildcard: true,
    };
}
