import React, { FC, useCallback } from 'react';
import { PreloadedQuery, useMutation, usePreloadedQuery } from 'react-relay';
import { useNavigate } from 'react-router-dom';

import { useToast } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { ProvisionLayout } from 'layouts';
import { Paths } from 'lib/routes';
import { ErrorNotFound } from 'views/ErrorPage/ErrorNotFound';

import { EditUserContentQuery } from './__generated__/EditUserContentQuery.graphql';
import { EditUserContentUpdateMutation } from './__generated__/EditUserContentUpdateMutation.graphql';
import { GeneralSection } from './components/GeneralSection';
import { PermissionsSection } from './components/PermissionsSection';
import { ActionType, useEditReducer } from './reducer';

export interface EditUserContentProps {
    userId: string;
    queryRef: PreloadedQuery<EditUserContentQuery>;
}

export const EditUserContent: FC<EditUserContentProps> = ({ userId, queryRef }) => {
    const navigate = useNavigate();
    const { show: showToast } = useToast();

    const data = usePreloadedQuery<EditUserContentQuery>(
        graphql`
            query EditUserContentQuery($username: String!) {
                user(username: $username) {
                    name
                    email
                    permissions {
                        general
                        assets
                        batteryHealthTasks
                        administration
                    }
                    type
                }
            }
        `,
        queryRef
    );

    const [commitEditUser, isCommitInProgress] = useMutation<EditUserContentUpdateMutation>(
        graphql`
            mutation EditUserContentUpdateMutation($username: String!, $user: UserUpdateIn!) {
                editUser(username: $username, user: $user) {
                    __typename
                    ... on User {
                        username
                    }
                    ... on UserProblemResponse {
                        problems {
                            type
                        }
                    }
                }
            }
        `
    );

    const [editState, dispatchEditState] = useEditReducer(data);

    const saveUserChanges = useCallback(() => {
        commitEditUser({
            variables: {
                username: userId,
                user: {
                    name: editState.name,
                    email: editState.email,
                    permissions: editState.permissions,
                },
            },
            onCompleted({ editUser }) {
                if (!editUser) {
                    showToast({
                        text: 'Unable to save changes.',
                        variant: 'error',
                    });
                    return;
                }

                if (editUser.__typename === 'User') {
                    // Success
                    showToast({
                        text: 'User updated succesfully!',
                        variant: 'info',
                    });
                    navigate(Paths.SettingsUsers);
                } else if (editUser.__typename === 'UserProblemResponse') {
                    // Validation error
                    if (editUser.problems.length === 1 && editUser.problems[0].type === 'Noop') {
                        // This is ok
                        navigate(Paths.SettingsUsers);
                        return;
                    }

                    dispatchEditState({
                        type: ActionType.UpdateErrors,
                        problems: editUser.problems.map(problem => problem.type),
                    });
                    showToast({
                        text: 'Cannot save changes. There are issues with your changes.',
                        variant: 'error',
                    });
                }
            },
            onError(error) {
                captureException(error, scope => {
                    scope.setTag('Edit User', userId);
                    return scope;
                });

                showToast({
                    text: 'Failed to save changes.',
                    variant: 'error',
                    actions: [{ title: 'Retry', onClick: saveUserChanges }],
                });
            },
        });
    }, [
        commitEditUser,
        dispatchEditState,
        editState.email,
        editState.name,
        editState.permissions,
        navigate,
        showToast,
        userId,
    ]);

    if (!data.user) {
        // TODO: This looks quite bad. It would be better to show the form but
        // disabled with an error message
        return <ErrorNotFound />;
    }

    return (
        <ProvisionLayout
            type='User'
            operation='edit'
            primaryAction={saveUserChanges}
            secondaryAction={() => {
                navigate(Paths.SettingsUsers);
            }}
            disableFormButtons={isCommitInProgress}
        >
            <div className='space-y-8'>
                <GeneralSection state={editState} dispatch={dispatchEditState} />
                {data.user.type !== 'External' && <PermissionsSection state={editState} dispatch={dispatchEditState} />}
            </div>
        </ProvisionLayout>
    );
};
