import { ColumnDataType, LineDot, ThemedLineDataType, TimeseriesPoint } from '@accesstel/pcm-ui';

import { DateTime, Duration } from 'luxon';

export interface TimeseriesOffsetPoint {
    timestamp: DateTime;
    offset: Duration;
    value: number;
    stdDeviation?: number | null;
}

export interface TimeseriesOffsetSeries {
    points: TimeseriesOffsetPoint[];
    id?: string;
    name: string;
    lineColor: string;
    stdDev?: boolean;
    strokeDash?: string;
    dots?: LineDot;
}

export function asColumnData(data: TimeseriesPoint[], interval: Duration): ColumnDataType[] {
    if (data.length === 0) {
        return [];
    }

    // Determine span
    const earliest = DateTime.fromJSDate(data[0].date);
    const latest = DateTime.fromJSDate(data[data.length - 1].date);
    const today = DateTime.now();

    let includeTooltipYear = false;
    let includeTooltipMonth = false;
    let includeTooltipDay = false;

    if (earliest.year !== latest.year || earliest.year !== today.year) {
        includeTooltipYear = true;
    }
    if (earliest.month !== latest.month || earliest.month !== today.month || includeTooltipYear) {
        includeTooltipMonth = true;
    }
    if (earliest.day !== latest.day || earliest.day !== today.day || includeTooltipYear) {
        includeTooltipDay = true;
    }

    if (interval < Duration.fromObject({ hour: 1 })) {
        // Display minutes
        let lastMonth: number = today.month;
        let lastDay: number = today.day;

        let tooltipFormat = 'H:m';
        if (includeTooltipDay) {
            tooltipFormat = `ccc d/L ${tooltipFormat}`;
        }
        if (includeTooltipMonth) {
            tooltipFormat = `LLL ${tooltipFormat}`;
        }
        if (includeTooltipYear) {
            tooltipFormat = `${tooltipFormat} yyyy`;
        }

        return data.map(point => {
            const timestamp = DateTime.fromJSDate(point.date);

            let format: string;

            if (timestamp.month !== lastMonth) {
                lastMonth = timestamp.month;
                lastDay = timestamp.day;

                format = 'LLL d H:m';
            } else if (timestamp.day !== lastDay) {
                lastDay = timestamp.day;

                format = 'ccc H:m';
            } else {
                format = 'H:m';
            }

            return {
                key: timestamp.toFormat(format),
                tooltip: timestamp.toFormat(tooltipFormat),
                value: Number((point.value ?? 0).toFixed(2)),
            };
        });
    } else if (interval < Duration.fromObject({ day: 1 })) {
        // Display hours
        let lastMonth: number = today.month;

        let tooltipFormat = 'H:mm';
        if (includeTooltipDay) {
            tooltipFormat = `ccc d/L ${tooltipFormat}`;
        }
        if (includeTooltipMonth) {
            tooltipFormat = `LLL ${tooltipFormat}`;
        }
        if (includeTooltipYear) {
            tooltipFormat = `${tooltipFormat} yyyy`;
        }

        return data.map(point => {
            const timestamp = DateTime.fromJSDate(point.date);

            let format: string;

            if (timestamp.month !== lastMonth) {
                lastMonth = timestamp.month;

                format = 'LLL d H:m';
            } else {
                format = 'H:00';
            }

            return {
                key: timestamp.toFormat(format),
                tooltip: timestamp.toFormat(tooltipFormat),
                value: Number((point.value ?? 0).toFixed(2)),
            };
        });
    } else if (interval < Duration.fromObject({ week: 1 })) {
        // Display days
        let lastMonth: number = today.month;

        let tooltipFormat = `ccc d/L`;
        if (includeTooltipMonth) {
            tooltipFormat = `LLL ${tooltipFormat}`;
        }
        if (includeTooltipYear) {
            tooltipFormat = `${tooltipFormat} yyyy`;
        }

        return data.map(point => {
            const timestamp = DateTime.fromJSDate(point.date);

            let format: string;

            if (timestamp.month !== lastMonth) {
                lastMonth = timestamp.month;

                format = 'LLL ccc d';
            } else {
                format = 'ccc';
            }

            return {
                key: timestamp.toFormat(format),
                tooltip: timestamp.toFormat(tooltipFormat),
                value: Number((point.value ?? 0).toFixed(2)),
            };
        });
    } else if (interval < Duration.fromObject({ month: 1 })) {
        // Display weeks
        let lastMonth: number = today.month;

        let tooltipFormat = 'd/L';
        if (includeTooltipYear) {
            tooltipFormat = `${tooltipFormat} yyyy`;
        }

        return data.map(point => {
            const timestamp = DateTime.fromJSDate(point.date);
            const weekTimestamp = timestamp.minus({ days: timestamp.weekday });

            let format: string;

            if (weekTimestamp.month !== lastMonth) {
                lastMonth = weekTimestamp.month;

                format = 'LLL d';
            } else {
                format = 'd/L';
            }

            return {
                key: weekTimestamp.toFormat(format),
                tooltip: weekTimestamp.toFormat(`[Week of ]${tooltipFormat}`),
                value: Number((point.value ?? 0).toFixed(2)),
            };
        });
    } else {
        // Display months
        let lastYear: number = today.year;

        let tooltipFormat = 'LLLL';
        if (includeTooltipYear) {
            tooltipFormat = `${tooltipFormat} yyyy`;
        }

        return data.map(point => {
            const timestamp = DateTime.fromJSDate(point.date);

            let format: string;

            if (timestamp.year !== lastYear) {
                lastYear = timestamp.year;

                format = 'LLL yy';
            } else {
                format = 'LLL';
            }

            return {
                key: timestamp.toFormat(format),
                tooltip: timestamp.toFormat(tooltipFormat),
                value: Number((point.value ?? 0).toFixed(2)),
            };
        });
    }
}

export function asDurationLineData(data: TimeseriesOffsetPoint[]): ThemedLineDataType<Duration>[] {
    if (data.length === 0) {
        return [];
    }

    return data.map(point => {
        if (point.stdDeviation !== null && point.stdDeviation !== undefined) {
            return {
                key: point.offset,
                value: point.value,
                stdDev: [point.value - point.stdDeviation, point.value + point.stdDeviation],
            };
        } else {
            return {
                key: point.offset,
                value: point.value,
            };
        }
    });
}
