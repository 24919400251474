import { DateRangeFilter } from 'components/FilterDateSelect/common';
import { DeviceHealth } from 'views/sites/__generated__/SiteListTableQuery.graphql';

export enum DeviceTableColumn {
    Name = 'Name',
    Type = 'Type',
    Site = 'Site',
    State = 'State',
    LastTested = 'LastTested',
    SnmpVersion = 'SnmpVersion',
    DeviceStatus = 'DeviceStatus',
    BatteryStatus = 'BatteryStatus',
    MonitorOnly = 'MonitorOnly',
    BatteryStringCount = 'BatteryStringCount',
    BatteryTemperature = 'BatteryTemperature',
    BatteryCapacityRemaining = 'BatteryCapacityRemaining',
    BatteryEnergyTotal = 'BatteryEnergyTotal',
    BatteryReserveTime = 'BatteryReserveTime',
    BatteryStateOfHealth = 'BatteryStateOfHealth',
}

export enum DeviceExtraFilters {
    BatteryCapacityTotal = 'Battery Capacity Total',
    BatteryInstallDate = 'Battery Install Date',
    BatteryManufactureDate = 'Battery Manufacture Date',
}

export enum TestDeviceTableColumn {
    Name = 'Name',
    Site = 'Site',
    State = 'State',
    LastTested = 'LastTested',
}

export enum TestDeviceExtraFilters {
    Type = 'Type',
    SnmpVersion = 'SNMP Version',
    DeviceStatus = 'Device Status',
    BatteryStatus = 'Battery Status',
    BatteryStringCount = 'Battery String Count',
    BatteryTemperature = 'Battery Temperature',
    BatteryCapacityRemaining = 'Battery Capacity Remaining',
    BatteryEnergyTotal = 'Battery Energy Total',
    BatteryReserveTime = 'Battery Reserve Time',
    BatteryStateOfHealth = 'Battery State Of Health',
    BatteryCapacityTotal = 'Battery Capacity Total',
    BatteryInstallDate = 'Battery Install Date',
    BatteryManufactureDate = 'Battery Manufacture Date',
}

export interface DeviceNameFilter {
    name: string;
    type: 'result' | 'special';
    wildcard?: boolean;
    id?: string;
    siteName?: string;
    state?: string;
}

export interface DeviceTypeFilter {
    name: string;
    id: string;
}

export interface DeviceAttributeFilter {
    value: string;
    wildcard?: boolean;
}

export interface DeviceSNMPVersionFilter {
    name: string;
    id: '1' | '2c' | '3';
}

export interface DeviceStatusFilter {
    name: string;
    id: DeviceHealth;
}

export interface DeviceBatteryStatusFilter {
    name: string;
    id: 'BoostCharging' | 'Discharging' | 'Disconnected' | 'FloatCharging' | 'Idle' | 'Missing' | 'Unknown';
}

export type DeviceBatteryDateFilter = DateRangeFilter;
export type DeviceLastTestedDateFilter = DateRangeFilter;

export enum MonitorOnlyStatus {
    Yes = 'Yes',
    No = 'No',
}
