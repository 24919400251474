import React from 'react';

import { TestNameFilterUI } from 'components/TestSelectModalContent/components';
import { formatDateFilter, formatDurationFilter } from 'filters/common/util';
import {
    DeviceTestResultRunTimeFilter,
    DeviceTestResultStartTimeFilter,
    DeviceTestResultStateFilter,
    DeviceTestResultTypeFilter,
    DeviceTestResultTypeFilterUI,
} from 'filters/device-test-result';
import { describeBatteryTestType } from 'filters/device-test-result/helpers';
import { TaskDateFilterUI, TaskRunTimeFilterUI } from 'filters/task';
import { TestResultStateFilterUI } from 'filters/test-result';

import { FilterDefinition, FilterValueMap } from '../common';
import { BatteryTestResultNameFilter, BatteryTestResultsColumn } from './types';

export type BatteryTestResultColumnFilterMap = {
    [BatteryTestResultsColumn.StartTime]: DeviceTestResultStartTimeFilter | null;
    [BatteryTestResultsColumn.TaskName]: BatteryTestResultNameFilter[];
    [BatteryTestResultsColumn.RunTime]: DeviceTestResultRunTimeFilter | null;
    [BatteryTestResultsColumn.State]: DeviceTestResultStateFilter[];
    [BatteryTestResultsColumn.Type]: DeviceTestResultTypeFilter[];
};

export const DefaultValues: FilterValueMap<BatteryTestResultColumnFilterMap> = {
    [BatteryTestResultsColumn.StartTime]: null,
    [BatteryTestResultsColumn.TaskName]: [],
    [BatteryTestResultsColumn.RunTime]: null,
    [BatteryTestResultsColumn.State]: [],
    [BatteryTestResultsColumn.Type]: [],
};

export const StaticBatteryTestResultFilterDefinitions: FilterDefinition<BatteryTestResultsColumn>[] = [
    {
        type: 'multi',
        name: 'Name',
        category: 'Name',
        column: BatteryTestResultsColumn.TaskName,
        component: meta => props => <TestNameFilterUI {...props} testIds={(meta as { testIds: string[] }).testIds} />,
        describeValue: (value: BatteryTestResultNameFilter) => value.name,
    },
    {
        type: 'multi',
        name: 'Type',
        category: 'Type',
        column: BatteryTestResultsColumn.Type,
        component: () => DeviceTestResultTypeFilterUI,
        describeValue: describeBatteryTestType,
    },
    {
        type: 'multi',
        name: 'State',
        category: 'State',
        column: BatteryTestResultsColumn.State,
        component: () => TestResultStateFilterUI,
        describeValue: (value: DeviceTestResultStateFilter) => value.name,
    },
    {
        type: 'single',
        name: 'Date',
        category: 'Date',
        column: BatteryTestResultsColumn.StartTime,
        component: () => TaskDateFilterUI,
        describeValue: (value: DeviceTestResultStartTimeFilter) => formatDateFilter(value),
    },
    {
        type: 'single',
        name: 'Run Time',
        category: 'Run Time',
        column: BatteryTestResultsColumn.RunTime,
        component: () => TaskRunTimeFilterUI,
        describeValue: formatDurationFilter,
    },
];
