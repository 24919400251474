import React, { FC } from 'react';

import { PageHeader } from '../../components';
import { SkeletonText } from '../../components/Skeleton';
import { SkeletonChart } from '../../components/Skeleton/SkeletonChart';

export const MetricsExploreSkeleton: FC = () => {
    return (
        <div className='space-y-6 mb-32'>
            <PageHeader />
            <div>
                <SkeletonText characters={15} size='heading' />
                <SkeletonText characters={40} size='subheading' />
            </div>
            <div className='bg-white p-5'>
                <div>
                    <SkeletonChart aspectRatio='16 / 9' stretch />
                </div>
            </div>
        </div>
    );
};
