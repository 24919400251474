import React, { FC, PropsWithChildren } from 'react';

import classNames from 'classnames';

import style from './style.module.css';

export interface SimpleLinkProps {
    onClick: () => void;
}

export const SimpleLink: FC<PropsWithChildren<SimpleLinkProps>> = ({ onClick, children }) => {
    return (
        <button className={classNames('text-customCoral', style.link)} onClick={onClick}>
            {children}
        </button>
    );
};
