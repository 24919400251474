import { useEffect, useMemo, useState } from 'react';

import { MenuItemType } from '@accesstel/pcm-ui';

import { FetchOptions, RenderProps, useQuery } from 'lib/query-helpers';
import { DateTime, Duration } from 'luxon';
import { GraphQLTaggedNode, OperationType } from 'relay-runtime';

export interface TimeRange {
    id: string;
    getRange(): [DateTime, DateTime];
    interval: Duration;
    displayName: string;
}

/**
 * The predefined time ranges for reporting
 */
export const DefaultTimeRanges: TimeRange[] = [
    {
        id: '24hours',
        displayName: 'Last 24 hours',
        getRange: (): [DateTime, DateTime] => [DateTime.now().minus({ day: 1 }), DateTime.now()],
        interval: Duration.fromObject({ hour: 1 }),
    },
    {
        id: '7days',
        displayName: 'Last 7 days',
        getRange: (): [DateTime, DateTime] => [DateTime.now().minus({ days: 7 }), DateTime.now()],
        interval: Duration.fromObject({ day: 1 }),
    },
    {
        id: '6weeks',
        displayName: 'Last 6 weeks',
        getRange: (): [DateTime, DateTime] => [DateTime.now().minus({ weeks: 6 }), DateTime.now()],
        interval: Duration.fromObject({ week: 1 }),
    },
    {
        id: '6months',
        displayName: 'Last 6 months',
        getRange: (): [DateTime, DateTime] => [DateTime.now().minus({ months: 6 }), DateTime.now()],
        interval: Duration.fromObject({ month: 1 }),
    },
    {
        id: '1year',
        displayName: 'Last year',
        getRange: (): [DateTime, DateTime] => [DateTime.now().minus({ year: 1 }), DateTime.now()],
        interval: Duration.fromObject({ month: 1 }),
    },
];

export function useTimeRange(defaultRange: TimeRange): [TimeRange, MenuItemType[]] {
    const [timeRange, setTimeRange] = useState<TimeRange>(defaultRange);

    const menuItems = useMemo<MenuItemType[]>(() => {
        return DefaultTimeRanges.map(range => ({
            id: range.id,
            name: range.displayName,
            onClick: () => setTimeRange(range),
        }));
    }, []);

    useEffect(() => {
        setTimeRange(defaultRange);
    }, [defaultRange.id, defaultRange]);

    return [timeRange, menuItems];
}

export function useTimeRangeQuery<TOperationType extends OperationType = OperationType>(
    gqlQuery: GraphQLTaggedNode,
    timeRange: TimeRange,
    variables?: Omit<TOperationType['variables'], 'begin' | 'end' | 'interval'>,
    options?: FetchOptions
): RenderProps<TOperationType> {
    const [startTimestamp, endTimestamp] = useMemo(() => timeRange.getRange(), [timeRange]);

    return useQuery<TOperationType>(
        gqlQuery,
        {
            ...variables,
            begin: startTimestamp.toISO(),
            end: endTimestamp.toISO(),
            interval: timeRange.interval.toISO(),
        },
        options
    );
}
