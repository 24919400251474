import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { Paths } from 'lib/routes';

import { BatteryTestResultCard } from '../components/BatteryTestResultCard';
import { CardSection } from '../components/CardSection';
import { ExternalDischarges_tasks$key } from './__generated__/ExternalDischarges_tasks.graphql';

export interface ExternalDischargesProps {
    tasks: ExternalDischarges_tasks$key | null;
}

export const ExternalDischarges: FC<ExternalDischargesProps> = ({ tasks }) => {
    const result = useFragment<ExternalDischarges_tasks$key>(
        graphql`
            fragment ExternalDischarges_tasks on PaginatedDeviceBatteryTestResults {
                total
                data {
                    id
                    ...BatteryTestResultCard_test
                }
            }
        `,
        tasks
    );

    if (!result || result?.total === 0) {
        return null;
    }

    return (
        <CardSection title='External discharges' to={Paths.TestsUnplanned} testId='unplanned-section'>
            {result.data.map(test => (
                <BatteryTestResultCard key={test.id} test={test} />
            ))}
        </CardSection>
    );
};
