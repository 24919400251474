import React, { FC, ReactNode } from 'react';

import classNamesBind from 'classnames/bind';

import style from './style.module.css';

const classNames = classNamesBind.bind(style);

export interface BorderedSectionProps {
    icon: ReactNode;
    title: string;
    children: ReactNode;
}

export const BorderedSection: FC<BorderedSectionProps> = ({ icon, title, children }) => {
    return (
        <div className={classNames('section')}>
            <div className={classNames('section_icon')}>{icon}</div>
            <div className={classNames('section_panel')}>
                <div>
                    <span className={classNames('section_title')}>{title}</span>
                </div>
                <div className={classNames('section_list')}>{children}</div>
            </div>
        </div>
    );
};
