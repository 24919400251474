import React, { FC, ReactNode } from 'react';

import { Button, CircleAlertIcon } from '@accesstel/pcm-ui';

interface AlertBannerAction {
    label: string;
    onClick?: () => void;
    to?: string;
}

export interface AlertBannerProps {
    title: string;
    message: ReactNode;
    action?: AlertBannerAction;
}

export const AlertBanner: FC<AlertBannerProps> = ({ title, message, action }) => {
    return (
        <div className='pt-10'>
            <div className='p-4 w-2/3 text-center rounded-2xl bg-white mx-auto'>
                <div className='flex justify-center mb-1'>
                    <div className='w-7 h-7 text-customCoral'>
                        <CircleAlertIcon />
                    </div>
                </div>
                <div className='text-lg font-CynthoNext-SemiBold text-customCoral'>{title}</div>
                <div className='text-sm font-CynthoNext-Regular pb-4'>{message}</div>
                {action && <Button buttonText={action.label} onClick={action.onClick} to={action.to} />}
            </div>
        </div>
    );
};
