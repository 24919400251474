import React, { FC, Suspense, useEffect } from 'react';
import { fetchQuery, useQueryLoader, useRelayEnvironment } from 'react-relay';

import { useDocumentTitle } from '../../../../components';
import { BatteryHealthTaskOverviewContent } from './BatteryHealthTaskOverviewContent';
import { BatteryHealthTaskOverviewSkeleton } from './BatteryHealthTaskOverviewSkeleton';
import BatteryHealthTaskOverviewContentQuery, {
    BatteryHealthTaskOverviewContentQuery as BatteryHealthTaskOverviewContentQueryType,
} from './__generated__/BatteryHealthTaskOverviewContentQuery.graphql';

const RefreshInterval = 10000; // 10 seconds

export const BatteryHealthTaskOverview: FC = () => {
    useDocumentTitle('Battery Health Tests');

    const environment = useRelayEnvironment();
    const [contentQueryRef, loadQuery, disposeQuery] = useQueryLoader<BatteryHealthTaskOverviewContentQueryType>(
        BatteryHealthTaskOverviewContentQuery
    );

    useEffect(() => {
        loadQuery({}, { fetchPolicy: 'store-and-network' });
        return () => {
            disposeQuery();
        };
    }, [disposeQuery, loadQuery]);

    // Do live updates while the user is on this page
    useEffect(() => {
        const interval = setInterval(() => {
            // NOTE: Do not use loadQuery() here because it makes the page go back to the skeleton state

            // Update the store which should cause a re-render in the component
            fetchQuery(
                environment,
                BatteryHealthTaskOverviewContentQuery,
                {},
                { fetchPolicy: 'network-only' }
            ).subscribe({});
        }, RefreshInterval);
        return () => {
            clearInterval(interval);
        };
    }, [environment]);

    return (
        <div className='space-y-6 mb-32'>
            {!contentQueryRef && <BatteryHealthTaskOverviewSkeleton />}
            {contentQueryRef && (
                <Suspense fallback={<BatteryHealthTaskOverviewSkeleton />}>
                    <BatteryHealthTaskOverviewContent preloadedQuery={contentQueryRef} />
                </Suspense>
            )}
        </div>
    );
};
