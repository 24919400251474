import React, { FC } from 'react';

import { AlertIcon, Button, CheckIcon, FormSection } from '@accesstel/pcm-ui';

import classNamesBind from 'classnames/bind';
import { useField } from 'formik';
import { queries_GetDeviceTypeConnectivityQuery$data } from 'views/manage/__generated__/queries_GetDeviceTypeConnectivityQuery.graphql';

import manageStyle from '../../../../style.module.css';
import { Addresses } from './components/Addresses';
import { SNMPSettings } from './components/SNMPSettings';
import { WebCredentials } from './components/WebCredentials';
import style from './connection-settings.module.css';
import { ConnectivitySettingsFormValues } from './schema';
import { useConnectionTest } from './use-connection-test';

const classNames = classNamesBind.bind(style);

export interface ConnectivitySettingsProps {
    connectivity: queries_GetDeviceTypeConnectivityQuery$data;
    namespace: string;
    deviceType: string;
}

export enum ProtocolTypes {
    Basic = 'Basic',
    Snmp = 'Snmp',
}

export const ConnectivitySettings: FC<ConnectivitySettingsProps> = ({ connectivity, namespace, deviceType }) => {
    const [, currentSettingsMeta] = useField<ConnectivitySettingsFormValues>(namespace);
    const currentSettings = currentSettingsMeta.value;

    const {
        testConnection,
        canCheckConnection,
        isTestingConnection,
        isConnectionOk,
        connectionStatus,
    } = useConnectionTest(currentSettings, deviceType);

    return (
        <FormSection label='Connection Settings' blurb='Connection details for communicating with the device.'>
            <div className={manageStyle.provisioning_content_grid}>
                {connectivity.deviceType?.connectivity.ipEnabled && (
                    <div className='col-start-1'>
                        <Addresses namespace={namespace} />
                    </div>
                )}

                {connectivity.deviceType?.connectivity.protocols.map(protocol => {
                    if (protocol.type === ProtocolTypes.Snmp) {
                        return <SNMPSettings key={'snmp-settings'} namespace={namespace} />;
                    } else if (protocol.type === ProtocolTypes.Basic) {
                        return <WebCredentials key={'web-settings'} namespace={namespace} />;
                    } else {
                        return <></>;
                    }
                })}

                <div className={classNames('test_connection_section', 'col-start-1')}>
                    <Button
                        buttonClasses={classNames('test_connection_button')}
                        size='small'
                        buttonText='Test connection'
                        variant='secondary'
                        processing={isTestingConnection}
                        disabled={!canCheckConnection}
                        disabledMessage='Make sure all connection settings are filled in'
                        onClick={testConnection}
                    />
                    <div
                        className={classNames('test_connection_result', {
                            'text-customEggplant': isConnectionOk,
                            'text-customCoral': !isConnectionOk,
                        })}
                    >
                        <div className='w-4'>
                            {connectionStatus && (isConnectionOk ? <CheckIcon /> : <AlertIcon />)}
                        </div>
                        {connectionStatus}
                    </div>
                </div>
            </div>
        </FormSection>
    );
};
