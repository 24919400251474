import React, { FC, useState } from 'react';

import { ChartFrame, Column, LoadableContentArea, TimeseriesPoint } from '@accesstel/pcm-ui';

import { createMenuAction } from 'views/tasks/battery-health/components/MetricsLine';

import { asColumnData } from '../../../../../lib/dateChart';
import { TimeRange, useTimeRange, useTimeRangeQuery } from '../../../common';
import { queries_AverageCurrentQuery } from '../../__generated__/queries_AverageCurrentQuery.graphql';
import { averageCurrentQuery } from '../../queries';
import style from './common.module.css';

export interface AverageBatteryCurrentProps {
    timeRange: TimeRange;
}

export const AverageBatteryCurrent: FC<AverageBatteryCurrentProps> = ({ timeRange }) => {
    const [currentTimeRange, menuItems] = useTimeRange(timeRange);
    const [isZoomPaneOpen, setIsZoomPaneOpen] = useState(false);

    const { data: props, error, retry } = useTimeRangeQuery<queries_AverageCurrentQuery>(
        averageCurrentQuery,
        currentTimeRange
    );

    const zoomAction = createMenuAction({
        menuItems,
        menuSelected: currentTimeRange.id,
        isOpen: isZoomPaneOpen,
        setIsOpen: setIsZoomPaneOpen,
        menuPlaceholder: 'Zoom',
    });

    return (
        <ChartFrame title='Average battery current (A)' actions={[zoomAction]} className={style.chart}>
            <LoadableContentArea
                data={props?.batteryMetrics.current}
                error={!!error}
                onRetry={retry}
                className={style.chart_loading}
                render={result => {
                    const data: TimeseriesPoint[] = result.values.map(point => ({
                        date: new Date(point.timestamp as string),
                        value: point.value,
                    }));

                    return (
                        <Column data={asColumnData(data, currentTimeRange.interval)} unit={'A'} ticks={11} axisUnits />
                    );
                }}
            />
        </ChartFrame>
    );
};
