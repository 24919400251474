import React, { FC, useCallback, useMemo, useState } from 'react';
import { commitMutation, fetchQuery, useRelayEnvironment } from 'react-relay';
import { useLocation, useNavigate } from 'react-router-dom';

import { Card, Stepper, ThemedPageHeading, useToast } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { TestDeviceTableColumn, scheduleTestDeviceToFilterObject, useScheduleTestDeviceFilter } from 'filters/device';
import { Formik, FormikHelpers } from 'formik';
import { SortDirection, TableActionType, useTableReducer } from 'layouts';
import { useAsyncEffect } from 'lib/hook';
import { logError } from 'lib/log';
import { useQuery } from 'lib/query-helpers';
import { Paths } from 'lib/routes';
import { Duration } from 'luxon';
import { IEnvironment } from 'relay-runtime';
import { DeviceOrdering, DeviceSortField } from 'views/manage/device/__generated__/DevicesTableQuery.graphql';
import { ReportSearchResults } from 'views/reports/batteries/search';

import { PageHeader, useDocumentTitle } from '../../../../components';
import {
    ScheduleFetchDeviceBatteryInfoQuery,
    ScheduleFetchDeviceBatteryInfoQuery$data,
} from './__generated__/ScheduleFetchDeviceBatteryInfoQuery.graphql';
import { ScheduleFetchDeviceInfoQuery } from './__generated__/ScheduleFetchDeviceInfoQuery.graphql';
import { ScheduleFetchTaskQuery } from './__generated__/ScheduleFetchTaskQuery.graphql';
import { ScheduleGetEligibleDevicesQuery } from './__generated__/ScheduleGetEligibleDevicesQuery.graphql';
import { ScheduleSelectAllQuery } from './__generated__/ScheduleSelectAllQuery.graphql';
import {
    BatteryTestCapacityEODVTypePair,
    mutations_AddBatteryTestTaskMutation,
    mutations_AddBatteryTestTaskMutation$variables,
} from './__generated__/mutations_AddBatteryTestTaskMutation.graphql';
import {
    ActiveStepStates,
    BatteryTestType,
    DeviceWithBattery,
    RepeatTestType,
    getBatteryTypesWithEodv,
    setSettingsError,
    stepperItems,
    validateSettings,
} from './common';
import { SelectedDeviceInfo, SelectedDeviceInfoWithId, useDeviceSelection } from './device-selection';
import { addBatteryTestTaskMutation } from './mutations';
import { ScheduleTestFormValues, ScheduleTestValidationSchema, createDefaultScheduleTestValues } from './schema';
import { Confirmation, Done, SelectDevices, Settings } from './steps';
import { SearchResultAction } from './steps/1SelectDevices/components';
import { TableColumns } from './steps/1SelectDevices/components/settings';
import style from './style.module.css';

export const Schedule: FC = () => {
    const [activeStep, setActiveStep] = useState(ActiveStepStates.SelectDevices);
    const [stepperDone, setStepperDone] = useState(false);
    const [deviceFilter, setDeviceFilter] = useState('');
    const [showOnlyDevices, setShowOnlyDevices] = useState<string[] | null>(null);
    const {
        selectedDevices,
        setDeviceSelected,
        setDeviceUnselected,
        setSelectedDevices,
        setTotalDeviceCount,
    } = useDeviceSelection();
    const [selectedDevicesWithBattery, setSelectedDevicesWithBattery] = useState(new Map<string, DeviceWithBattery>());
    const [initialValues, setInitialValues] = useState<ScheduleTestFormValues>(createDefaultScheduleTestValues());

    const navigate = useNavigate();
    // completed
    const [taskID, setTaskID] = useState<string | undefined>(undefined);
    const { show: showToast } = useToast();

    const environment = useRelayEnvironment();

    const [tableState, dispatchTableState] = useTableReducer<TestDeviceTableColumn>({
        defaultSortColumn: TestDeviceTableColumn.Site,
        allColumns: TableColumns.map(column => column.id),
        defaultVisibleColumns: TableColumns.map(column => column.id),
        storageKeyPrefix: 'schedule-test-table',
    });

    const [filters, dispatchFilters] = useScheduleTestDeviceFilter();
    const filterObject = useMemo(() => scheduleTestDeviceToFilterObject(filters), [filters]);

    const sortObject: DeviceOrdering = {
        field: tableState.sortColumn as DeviceSortField,
        dir: tableState.sortDirection === SortDirection.Ascending ? 'Asc' : 'Desc',
    };

    let pageHeading = '';
    let currentSubview: JSX.Element = <></>;

    const queryParams = new URLSearchParams(useLocation().search);
    const reportNavDevice = queryParams.get('device');
    const copySettingsFrom = queryParams.get('copy-task');
    // FIXME: Filter by siteId once PCM-798 is merged
    const { data: props, retry, error } = useQuery<ScheduleGetEligibleDevicesQuery>(GetEligibleDevicestQuery, {
        page: tableState.page,
        name: deviceFilter,
        orderBy: sortObject,
        filters: {
            ...filterObject,
            ids: showOnlyDevices,
            monitorOnly: { value: false },
            supportsBatteryTesting: { value: true },
        },
    });

    useDocumentTitle('Schedule a test');

    const preSelectDevices = useCallback(
        async (deviceId: string) => {
            try {
                const info = await fetchDeviceInfo(deviceId, environment);
                if (info) {
                    setDeviceSelected(deviceId, info);
                    setActiveStep(ActiveStepStates.Settings);
                    navigate(Paths.TestsScheduleTest, { replace: true });
                } else {
                    showToast({
                        text: 'Unable to find device',
                        variant: 'error',
                    });
                }
            } catch (error) {
                showToast({
                    text: 'Unable to load device',
                    variant: 'error',
                });
                logError('Failed to load device for schedule from report. ', reportNavDevice, error);
            }
        },
        [environment, navigate, reportNavDevice, setDeviceSelected, showToast]
    );

    const applySettings = useCallback(
        async (taskId: string) => {
            try {
                const task = await fetchTaskSettings(taskId, environment);
                if (task) {
                    setInitialValues(task.settings);
                    setSelectedDevices(task.devices);
                    setActiveStep(ActiveStepStates.Settings);
                } else {
                    showToast({
                        text: 'Unable to find task',
                        variant: 'error',
                    });
                }
            } catch (error) {
                showToast({
                    text: 'Unable to load task',
                    variant: 'error',
                });
                logError('Failed to load task for schedule from report. ', copySettingsFrom, error);
            }
        },
        [copySettingsFrom, environment, setSelectedDevices, showToast]
    );

    useAsyncEffect(async () => {
        if (reportNavDevice) {
            await preSelectDevices(reportNavDevice);
        } else if (copySettingsFrom) {
            await applySettings(copySettingsFrom);
        }
    }, []);

    const devices = props?.devices.data;

    useAsyncEffect(async () => {
        const devicesWithBattery = new Map<string, DeviceWithBattery>();
        let wasModified = selectedDevices.size !== selectedDevicesWithBattery.size;

        const devicesOrBlank = devices ?? [];
        const missingDevices: string[] = [];
        selectedDevices.forEach(id => {
            const device = devicesOrBlank.find(device => device.id === id);
            const existingState = selectedDevicesWithBattery.get(id);
            // device will be undefined when device is not in active page
            if (device) {
                devicesWithBattery.set(id, device as DeviceWithBattery);
                if (!existingState) {
                    wasModified = true;
                }
            } else {
                if (existingState) {
                    devicesWithBattery.set(id, existingState);
                } else {
                    missingDevices.push(id);
                }
            }
        });

        if (missingDevices.length > 0) {
            try {
                const missingDeviceData = await fetchDeviceBatteryInfo(missingDevices, environment);
                for (const data of missingDeviceData) {
                    devicesWithBattery.set(data.id, data as DeviceWithBattery);
                    wasModified = true;
                }
            } catch (error) {
                logError('Failed to load extra device information', error);
            }
        }

        dispatchTableState({
            type: TableActionType.SetAllSelectableItems,
            items: Array.from(devicesWithBattery.keys()),
        });

        if (wasModified) {
            setSelectedDevicesWithBattery(devicesWithBattery);
        }
    }, [selectedDevices, devices]);

    const extendedSelectedDevices = useMemo(() => {
        const devices: DeviceWithBattery[] = [];
        selectedDevicesWithBattery.forEach(value => {
            devices.push(value);
        });
        return devices;
    }, [selectedDevicesWithBattery]);

    const batteryTypes = useMemo(() => getBatteryTypesWithEodv(extendedSelectedDevices), [extendedSelectedDevices]);

    const handleResultClick = useCallback<(item: ReportSearchResults) => SearchResultAction>(
        (item: ReportSearchResults) => {
            if (item.type === 'device') {
                setShowOnlyDevices([item.id]);
            } else if (item.type === 'site') {
                // TODO: Once PCM-798 is merged, this should use the `site` filter on the query to return devices on the site
                return { action: 'refine', filter: item.siteName };
            }
            return { action: 'none' };
        },
        []
    );
    const handleResetSearch = useCallback(() => {
        setShowOnlyDevices(null);
        setDeviceFilter('');
    }, []);

    const handleSelectAll = useCallback(async () => {
        try {
            const selectedDevices = await getAllDevicesForSelection(
                environment,
                deviceFilter === '' ? undefined : deviceFilter,
                { ...filterObject, monitorOnly: { value: false } }
            );
            setSelectedDevices(selectedDevices, true);
            setTotalDeviceCount(selectedDevices.length);

            return selectedDevices.map(device => device.id);
        } catch (error) {
            logError('Failed to load all devices for selection', error);
            return [];
        }
    }, [environment, deviceFilter, filterObject, setSelectedDevices, setTotalDeviceCount]);

    const handleSelectNone = useCallback(() => {
        setSelectedDevices([]);
        setTotalDeviceCount(0);
    }, [setSelectedDevices, setTotalDeviceCount]);

    const onSubmit = (values: ScheduleTestFormValues, formikHelpers: FormikHelpers<ScheduleTestFormValues>) => {
        const { setFieldError, setSubmitting } = formikHelpers;

        const capacityEodvs: BatteryTestCapacityEODVTypePair[] =
            values.capacitySettings?.targets.map(target => ({
                batteryType: target.batteryType,
                eodv: Number(target.eodv),
            })) ?? [];

        const scheduledTime = values.scheduleTime ?? null;
        const capacitySettings =
            values.type === 'Capacity'
                ? {
                      targets: capacityEodvs,
                  }
                : null;
        const quickSettings =
            values.type === 'Quick'
                ? {
                      // hacky solution to return appropriate error when reserveTime is blank instead of 0 (which is valid)
                      reserveTime:
                          values.quickSettings?.reserveTime !== '' ? Number(values.quickSettings?.reserveTime) : -1,
                      threshold:
                          values.quickSettings?.minAllowedVoltage !== ''
                              ? Number(values.quickSettings?.minAllowedVoltage)
                              : undefined,
                  }
                : null;
        const customSettings =
            values.type === 'Custom'
                ? {
                      minVoltage: values.customSettings?.eodv !== '' ? Number(values.customSettings?.eodv) : undefined,
                      maxDuration: values.customSettings?.maxDuration
                          ? values.customSettings.maxDuration.as('minutes')
                          : undefined,
                      maxDischarged:
                          values.customSettings?.maxDischarge !== ''
                              ? Number(values.customSettings?.maxDischarge)
                              : undefined,
                  }
                : null;

        const variables: mutations_AddBatteryTestTaskMutation$variables = {
            name: values.name as string,
            devices: extendedSelectedDevices.map(device => device.id),
            smartStart: values.isSmartStart,
            type: values.type,
            capacitySettings,
            quickSettings,
            customSettings,
            schedule: {
                time: scheduledTime?.toISOString(),
                repeat: values.scheduleRepeat,
            },
        };

        setSubmitting(true);

        commitMutation<mutations_AddBatteryTestTaskMutation>(environment, {
            mutation: addBatteryTestTaskMutation,
            variables,
            onError: err => {
                captureException(error);

                logError('Unable to create task', err);
                showToast({
                    variant: 'error',
                    text: 'Unable to create task. Please try again later.',
                });
                setSubmitting(false);
            },
            onCompleted: (response, errors) => {
                if (response.addBatteryTest?.id) {
                    setStepperDone(true);
                    setActiveStep(ActiveStepStates.Done);
                    setTaskID(response.addBatteryTest.id);
                }
                if (response.addBatteryTest?.problems) {
                    response.addBatteryTest.problems.forEach(problem => {
                        setSettingsError(problem, setFieldError);
                    });
                    setActiveStep(ActiveStepStates.Settings);
                }
                if (errors) {
                    showToast({
                        variant: 'error',
                        text: 'Unable to create task. Please try again later.',
                    });
                }
                setSubmitting(false);
            },
        });
    };

    switch (activeStep) {
        case ActiveStepStates.SelectDevices:
            pageHeading = 'Select device(s):';
            currentSubview = (
                <SelectDevices
                    selectedDevices={selectedDevices}
                    setDeviceSelected={setDeviceSelected}
                    setDeviceUnselected={setDeviceUnselected}
                    selectNone={handleSelectNone}
                    selectAll={handleSelectAll}
                    setActiveStep={setActiveStep}
                    allDevices={props?.devices ?? null}
                    setTableFilter={setDeviceFilter}
                    onResultClick={handleResultClick}
                    error={!!error}
                    retry={retry}
                    onResetSearch={handleResetSearch}
                    filterState={filters}
                    dispatchFilterState={dispatchFilters}
                    tableState={tableState}
                    dispatchTableState={dispatchTableState}
                />
            );
            break;
        case ActiveStepStates.Settings:
            pageHeading = 'Settings';
            currentSubview = (
                <Settings
                    selectedDeviceList={extendedSelectedDevices}
                    batteryTypes={batteryTypes}
                    setActiveStep={setActiveStep}
                />
            );
            break;
        case ActiveStepStates.Confirmation:
            pageHeading = 'Confirmation';
            currentSubview = (
                <Confirmation
                    selectedDevices={extendedSelectedDevices}
                    batteryTypes={batteryTypes}
                    setActiveStep={setActiveStep}
                    setTaskID={setTaskID}
                    setStepperDone={setStepperDone}
                />
            );
            break;
        case ActiveStepStates.Done:
            pageHeading = 'Scheduled test';
            currentSubview = <Done taskID={taskID} />;
            break;
        default:
            break;
    }

    return (
        <>
            <div className='space-y-6'>
                <PageHeader />
                <ThemedPageHeading value={pageHeading} />
                <Stepper
                    stepperItems={stepperItems}
                    activeItem={activeStep}
                    setActiveItem={setActiveStep}
                    stepperDone={stepperDone}
                    disableLastStep={!stepperDone}
                />
                <div className={style.card}>
                    <Card>
                        <div className={style.card_content}>
                            <Formik
                                initialValues={initialValues}
                                onSubmit={onSubmit}
                                validationSchema={ScheduleTestValidationSchema}
                                enableReinitialize
                                validate={values => validateSettings(values, extendedSelectedDevices)}
                            >
                                {currentSubview}
                            </Formik>
                        </div>
                    </Card>
                </div>
            </div>
        </>
    );
};

async function getAllDevicesForSelection(
    environment: IEnvironment,
    search?: string,
    filters?: Record<string, unknown>
): Promise<SelectedDeviceInfoWithId[]> {
    const data = await fetchQuery<ScheduleSelectAllQuery>(
        environment,
        graphql`
            query ScheduleSelectAllQuery($search: String, $filters: DeviceFilter) {
                devices(
                    onlyWithValidBattery: true
                    pageSize: 10000
                    search: $search
                    filters: $filters
                    searchOptions: { includeSiteName: true, includeDeviceName: true }
                ) {
                    data {
                        id
                        dualPlaneCompanion {
                            device {
                                id
                            }
                        }
                    }
                }
            }
        `,
        {
            search,
            filters,
        }
    ).toPromise();

    return (
        data?.devices.data.map(device => ({
            id: device.id,
            companion: device.dualPlaneCompanion?.device?.id,
        })) ?? []
    );
}

async function fetchDeviceInfo(id: string, environment: IEnvironment): Promise<SelectedDeviceInfo | null> {
    const result = await fetchQuery<ScheduleFetchDeviceInfoQuery>(
        environment,
        graphql`
            query ScheduleFetchDeviceInfoQuery($id: ID!) {
                devices(
                    onlyWithValidBattery: true
                    onlyProvisioningStatuses: [Active]
                    filters: { ids: [$id], monitorOnly: { value: false }, supportsBatteryTesting: { value: true } }
                ) {
                    data {
                        id
                        name
                        dualPlaneCompanion {
                            device {
                                id
                                provisioningStatus
                                monitorOnly
                            }
                        }
                    }
                }
            }
        `,
        {
            id,
        }
    ).toPromise();

    if (result?.devices.data && result.devices.data.length === 1) {
        // vaiidate dualPlaneCompanion
        const companionDevice = result.devices.data[0].dualPlaneCompanion?.device;
        if (companionDevice) {
            const isCompanionEligibleForBatteryTest =
                !companionDevice.monitorOnly && companionDevice.provisioningStatus === 'Active';
            if (isCompanionEligibleForBatteryTest) {
                return {
                    companion: companionDevice.id,
                };
            }
        } else {
            return {
                companion: undefined,
            };
        }
    }
    return null;
}

interface FetchedTask {
    devices: SelectedDeviceInfoWithId[];
    settings: ScheduleTestFormValues;
}

async function fetchTaskSettings(id: string, environment: IEnvironment): Promise<FetchedTask | null> {
    const result = await fetchQuery<ScheduleFetchTaskQuery>(
        environment,
        graphql`
            query ScheduleFetchTaskQuery($id: ID!) {
                task(id: $id) {
                    ... on Task {
                        name
                        __typename
                        devices(filters: { supportsBatteryTesting: { value: true }, monitorOnly: { value: false } }) {
                            data {
                                id
                                provisioningStatus
                                dualPlaneCompanion {
                                    device {
                                        id
                                        provisioningStatus
                                        monitorOnly
                                    }
                                }
                            }
                        }
                    }
                    ... on BatteryTest {
                        usingSmartStart
                        type
                        settings {
                            ... on BatteryTestTypeCapacity {
                                targets {
                                    endOfDischargeVoltage(unit: Volts)
                                    batteryType {
                                        id
                                    }
                                }
                            }
                            ... on BatteryTestTypeCustom {
                                endOfDischargeVoltage(unit: Volts)
                                maxDuration(unit: Minutes)
                                maxDischarged(unit: AmpHour)
                            }
                            ... on BatteryTestTypeQuick {
                                reserveTime
                                threshold(unit: Volts)
                            }
                        }
                        schedule {
                            time
                            repeat
                        }
                    }
                }
            }
        `,
        {
            id,
        }
    ).toPromise();

    if (!result?.task) {
        return null;
    }

    if (result.task.__typename !== 'BatteryTest' || !result.task.settings) {
        return null;
    }

    const devices: SelectedDeviceInfoWithId[] = result.task.devices.data
        .filter(device => device.provisioningStatus === 'Active')
        .map(deviceData => {
            const companion = deviceData.dualPlaneCompanion?.device;
            const isCompanionEligibleForBatteryTest =
                companion && !companion.monitorOnly && companion.provisioningStatus === 'Active';
            return {
                id: deviceData.id,
                companion: isCompanionEligibleForBatteryTest ? companion?.id : undefined,
            };
        });

    const capacityEodvMap = new Map<string, number | null>();

    if (result.task.settings.targets) {
        for (const target of result.task.settings.targets) {
            if (!target.batteryType?.id) {
                continue;
            }

            capacityEodvMap.set(target.batteryType.id, target.endOfDischargeVoltage);
        }
    }

    const settings: ScheduleTestFormValues = {
        name: result.task.name,
        type: result.task.type as BatteryTestType,
        scheduleTime: result.task.schedule?.time ? new Date(result.task.schedule.time as string) : new Date(),
        scheduleRepeat: (result.task.schedule?.repeat as RepeatTestType) ?? 'Never',
        isSmartStart: !!result.task.usingSmartStart,

        capacitySettings: {
            targets:
                result.task.settings.targets
                    ?.filter(target => target.batteryType !== null)
                    .map(target => ({
                        batteryType: target.batteryType!.id,
                        eodv: `${target.endOfDischargeVoltage}`,
                    })) ?? [],
        },

        customSettings: {
            maxDischarge: result.task.settings.maxDischarged?.toFixed(2) ?? '',
            maxDuration: result.task.settings.maxDuration
                ? Duration.fromObject({ minutes: result.task.settings.maxDuration })
                : null,
            eodv: result.task.settings.endOfDischargeVoltage?.toFixed(2) ?? '',
        },

        quickSettings: {
            reserveTime: result.task.settings.reserveTime?.toFixed(2) ?? '',
            minAllowedVoltage: result.task.settings.threshold?.toFixed(2) ?? '',
        },
    };

    return {
        devices,
        settings,
    };
}

async function fetchDeviceBatteryInfo(
    ids: string[],
    environment: IEnvironment
): Promise<ScheduleFetchDeviceBatteryInfoQuery$data['devices']['data']> {
    const results = await fetchQuery<ScheduleFetchDeviceBatteryInfoQuery>(
        environment,
        graphql`
            query ScheduleFetchDeviceBatteryInfoQuery($ids: [ID!], $count: Int!) {
                devices(pageSize: $count, filters: { ids: $ids }) {
                    data {
                        id
                        name
                        site {
                            id
                            name
                        }
                        battery {
                            strings {
                                count
                                strings {
                                    cellsPerString
                                    type {
                                        id
                                        manufacturer
                                        model
                                        dischargeTables {
                                            endOfDischargeVoltage
                                        }
                                        cells {
                                            allowedVoltage {
                                                minimum
                                                maximum
                                            }
                                        }
                                    }
                                }
                            }
                            allowedVoltage {
                                minimum
                                maximum
                            }
                            reserveTime
                            quickTestFailThreshold
                        }
                    }
                }
            }
        `,
        { ids, count: ids.length }
    ).toPromise();

    return results?.devices.data ?? [];
}

const GetEligibleDevicestQuery = graphql`
    query ScheduleGetEligibleDevicesQuery(
        $page: Int = 1
        $name: String!
        $orderBy: DeviceOrdering
        $filters: DeviceFilter
    ) {
        devices(
            page: $page
            search: $name
            searchOptions: { includeSiteName: true, includeDeviceName: true }
            onlyWithValidBattery: true
            orderBy: $orderBy
            filters: $filters
        ) {
            ...DevicesTable_devices
            data {
                id
                name
                site {
                    id
                    name
                }
                battery {
                    strings {
                        count
                        strings {
                            cellsPerString
                            type {
                                id
                                manufacturer
                                model
                                dischargeTables {
                                    endOfDischargeVoltage
                                }
                                cells {
                                    allowedVoltage {
                                        minimum
                                        maximum
                                    }
                                }
                            }
                        }
                    }
                    allowedVoltage {
                        minimum
                        maximum
                    }
                    reserveTime
                    quickTestFailThreshold
                }
            }
        }
    }
`;
