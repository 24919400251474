import React from 'react';
import { useFragment } from 'react-relay';

import { ChartFrame, Gauge } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { Duration } from 'luxon';

import { formatMinutesAsHoursAndMinutes } from '../../../../../../lib/dateFormatter';
import { CommonPropsWithParentQuery } from '../common';
import { RunHoursGauge_battery$key } from './__generated__/RunHoursGauge_battery.graphql';
import style from './style.module.css';

export function RunHoursGauge({ metrics }: CommonPropsWithParentQuery): JSX.Element {
    const result = useFragment<RunHoursGauge_battery$key>(
        graphql`
            fragment RunHoursGauge_battery on DeviceBatteryMetrics {
                latestTimeRemaining(unit: Hours)
                typicalMaximumTimeRemaining(unit: Hours)
            }
        `,
        metrics
    );

    let body: React.ReactNode;

    if (!result || !result.latestTimeRemaining || !result.typicalMaximumTimeRemaining) {
        body = <Gauge value={0} max={100} text={`No Data`} />;
    } else {
        const value = result.latestTimeRemaining;
        const max = result.typicalMaximumTimeRemaining;

        body = (
            <Gauge
                value={Math.min((value / max) * 100, 100)} // NOTE: dont allow this to go over 100%
                max={100}
                unit='%'
                text={`${formatMinutesAsHoursAndMinutes(
                    Duration.fromObject({ hours: value }).as('minutes')
                )} remaining`}
            />
        );
    }

    return (
        <ChartFrame title={'Battery run hours'}>
            <div className={style.gauge}>
                <div className={style.inner}>{body}</div>
            </div>
        </ChartFrame>
    );
}
