import React from 'react';
import { RouteObject } from 'react-router-dom';

import { isFeatureEnabled } from 'lib/featureFlag';

import { FirmwareStatus } from './FirmwareStatus';

const Routes: RouteObject[] = [];

if (isFeatureEnabled('firmware')) {
    Routes.push({
        path: 'status/firmware',
        element: <FirmwareStatus />,
    });
}

export default Routes;
