import { createColumnHelper } from '@tanstack/react-table';
import { DeviceTableColumn } from 'filters/device';
import { ColumnWithId } from 'layouts';
import { batteryStatusToString } from 'lib/conversion/battery-status';
import { formatMinutesAsHoursAndMinutes } from 'lib/dateFormatter';
import { numberToLocaleString } from 'lib/numberFormatters';
import { renderDeviceHealthCell, renderMetricCell, renderUserConfigCell } from 'lib/table-columns';
import { getNiceSNMPVersion } from 'lib/textFormatters';

import type { DeviceHealth, DevicesTableQuery$data } from './__generated__/DevicesTableQuery.graphql';

type Device = DevicesTableQuery$data['devices']['data'][number];

const columnHelper = createColumnHelper<Device>();

function renderStatusCell(value?: DeviceHealth | null) {
    if (value) {
        return renderDeviceHealthCell(value);
    }
}

export const NameColumn = columnHelper.accessor('name', {
    id: DeviceTableColumn.Name,
    header: 'NAME',
    meta: {
        filterable: true,
        sortable: true,
        maxWidth: '30rem',
    },
});
export const TypeColumn = columnHelper.accessor('type.displayName', {
    id: DeviceTableColumn.Type,
    header: 'TYPE',
    meta: {
        filterable: true,
        sortable: true,
    },
});
export const SiteNameColumn = columnHelper.accessor('site.name', {
    id: DeviceTableColumn.Site,
    header: 'SITE',
    meta: {
        filterable: true,
        sortable: true,
        maxWidth: '15rem',
    },
});
export const SiteStateColumn = columnHelper.accessor('site.address.state', {
    id: DeviceTableColumn.State,
    header: 'STATE',
    meta: {
        filterable: true,
        sortable: true,
    },
});
export const SnmpVersionColumn = columnHelper.accessor('connectionSettings.protocols', {
    id: DeviceTableColumn.SnmpVersion,
    header: 'SNMP VERSION',
    cell: ({ cell }) =>
        renderUserConfigCell(() => {
            if (!cell.getValue() || !cell.getValue()[0]?.version) {
                return;
            }

            return getNiceSNMPVersion(cell.getValue()[0].version!);
        }),
    meta: {
        filterable: true,
    },
});
export const DeviceStatusColumn = columnHelper.accessor('health', {
    id: DeviceTableColumn.DeviceStatus,
    header: 'STATUS',
    cell: ({ cell }) => renderStatusCell(cell.getValue()),
    meta: {
        filterable: true,
    },
});
export const BatteryStatusColumn = columnHelper.accessor('battery.metrics.latestStatus', {
    id: DeviceTableColumn.BatteryStatus,
    header: 'BATTERY STATUS',
    cell: ({ cell }) => batteryStatusToString(cell.getValue() ?? null),
    meta: {
        filterable: true,
    },
});
export const MonitorOnlyColumn = columnHelper.accessor('monitorOnly', {
    id: DeviceTableColumn.MonitorOnly,
    header: 'MONITOR ONLY',
    cell: ({ cell }) => (cell.getValue() ? 'Yes' : 'No'),
    meta: {
        filterable: true,
    },
});
export const BatteryStringCountColumn = columnHelper.accessor('battery.strings.count', {
    id: DeviceTableColumn.BatteryStringCount,
    header: 'BATTERY STRING COUNT',
    meta: {
        filterable: true,
    },
});
export const BatteryReserveTimeColumn = columnHelper.accessor('battery.reserveTime', {
    id: DeviceTableColumn.BatteryReserveTime,
    header: 'BATTERY RESERVE TIME',
    cell: ({ cell }) =>
        renderUserConfigCell(() => {
            if (!cell.getValue()) {
                return;
            }

            return formatMinutesAsHoursAndMinutes(cell.getValue() as number);
        }),
    meta: {
        filterable: true,
    },
});
export const BatteryStateOfHealthColumn = columnHelper.accessor('battery.metrics.latestStateOfHealth', {
    id: DeviceTableColumn.BatteryStateOfHealth,
    header: 'BATTERY STATE OF HEALTH',
    cell: ({ cell }) =>
        renderMetricCell(() => {
            if (!cell.getValue()) {
                return;
            }

            return `${cell.getValue()} %`;
        }),
    meta: {
        filterable: true,
    },
});
export const BatteryTemperatureColumn = columnHelper.accessor('battery.metrics.latestTemperature', {
    id: DeviceTableColumn.BatteryTemperature,
    header: 'BATTERY TEMPERATURE',
    cell: ({ cell }) =>
        renderMetricCell(() => {
            if (!cell.getValue()) {
                return;
            }

            return `${cell.getValue()} °C`;
        }),
    meta: {
        filterable: true,
    },
});
export const BatteryCapacityRemainingColumn = columnHelper.accessor('battery.metrics.latestRemaingCapacity', {
    id: DeviceTableColumn.BatteryCapacityRemaining,
    header: 'BATTERY REMAINING CAPACITY',
    cell: ({ cell }) =>
        renderMetricCell(() => {
            if (!cell.getValue()) {
                return;
            }

            return `${cell.getValue()} Ah`;
        }),
    meta: {
        filterable: true,
    },
});
export const BatteryEnergyTotalColumn = columnHelper.accessor('battery.metrics.latestTotalEnergy', {
    id: DeviceTableColumn.BatteryEnergyTotal,
    header: 'BATTERY TOTAL ENERGY',
    cell: ({ cell }) =>
        renderMetricCell(() => {
            if (!cell.getValue()) {
                return;
            }

            return `${numberToLocaleString(cell.getValue() as number)} kWh`;
        }),
    meta: {
        filterable: true,
    },
});

export const BaseTableColumns = [NameColumn, TypeColumn, SiteNameColumn, SiteStateColumn] as ColumnWithId<
    DeviceTableColumn,
    Device
>[];

export const AllTableColumns = [
    ...BaseTableColumns,
    SnmpVersionColumn,
    DeviceStatusColumn,
    BatteryStatusColumn,
    MonitorOnlyColumn,
    BatteryStringCountColumn,
    BatteryReserveTimeColumn,
    BatteryStateOfHealthColumn,
    BatteryTemperatureColumn,
    BatteryCapacityRemainingColumn,
    BatteryEnergyTotalColumn,
] as ColumnWithId<DeviceTableColumn, Device>[];
