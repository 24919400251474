import React, { FC } from 'react';

import { ActiveDevices } from './components';
import { ActiveDevices$key } from './components/__generated__/ActiveDevices.graphql';

interface CountsProps {
    data: ActiveDevices$key | null;
    error?: boolean;
    retry: () => void;
}
export const CountsSection: FC<CountsProps> = ({ data, error, retry }) => {
    return (
        <div className='flex flex-row justify-between pt-2'>
            <ActiveDevices data={data} error={error} retry={retry} />
        </div>
    );
};
