/**
 * @generated SignedSource<<4945e1485b5da68ad5bf802501be89ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ExportJobStatus = "Cancelled" | "Failed" | "InProgress" | "Succeeded" | "Waiting" | "%future added value";
export type taskExporterStatusQuery$variables = {
  id: string;
};
export type taskExporterStatusQuery$data = {
  readonly exportJob: {
    readonly downloadUrl: string | null;
    readonly status: ExportJobStatus;
  } | null;
};
export type taskExporterStatusQuery = {
  response: taskExporterStatusQuery$data;
  variables: taskExporterStatusQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "downloadUrl",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "taskExporterStatusQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ExportJob",
        "kind": "LinkedField",
        "name": "exportJob",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "taskExporterStatusQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ExportJob",
        "kind": "LinkedField",
        "name": "exportJob",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0f4d9b46cfe959f6630adb1fcaa04466",
    "id": null,
    "metadata": {},
    "name": "taskExporterStatusQuery",
    "operationKind": "query",
    "text": "query taskExporterStatusQuery(\n  $id: ID!\n) {\n  exportJob(id: $id) {\n    status\n    downloadUrl\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "7584b0af57eade2ad7e578af80ecb90f";

export default node;
