import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { AuthenticatedSection } from 'components/AuthenticatedSection';
import { RouteBreadcrumbData } from 'lib/breadcrumb';

import { SystemConfiguration, loadSystemConfigurationView } from './configuration';
import { ManageUsers } from './users/ManageUsers';
import { EditUser } from './users/edit/EditUser';

const Routes: RouteObject[] = [
    {
        path: 'settings',
        element: <AuthenticatedSection administrator />,
        handle: {
            title: 'Settings',
        },
        children: [
            {
                index: true,
                element: <Navigate replace to='users' />,
            },
            {
                path: 'users',
                handle: {
                    title: 'Users',
                },
                children: [
                    {
                        index: true,
                        element: <ManageUsers />,
                    },
                    {
                        path: ':id',
                        element: <EditUser />,
                        handle: {
                            // TODO: Username
                            title: params => params.id,
                        } as RouteBreadcrumbData<{ id: string }>,
                    },
                ],
            },
            {
                path: 'configuration',
                handle: {
                    title: 'Configuration',
                },
                element: <SystemConfiguration />,
                loader: () => loadSystemConfigurationView(),
            },
        ],
    },
];

export default Routes;
