import React from 'react';

import { Button } from '@accesstel/pcm-ui';

export interface ForgotPasswordProps {
    onClose: (event: React.MouseEvent) => void;
}

export function ForgotPassword(props: ForgotPasswordProps): JSX.Element {
    return (
        <>
            <div className='space-y-8 flex flex-col items-center'>
                <div className='flex flex-col items-center space-y-1'>
                    <h1 className='text-customCoral font-CynthoNext-SemiBold text-3xl'>Forgot username or password?</h1>
                    <p>Please contact accesstel support and we can help you.</p>
                </div>

                <div className='space-y-4'>
                    <p>
                        Email:{' '}
                        <a className='text-customCoral' href='mailto:support@accesstel.com.au'>
                            support@accesstel.com.au
                        </a>
                    </p>
                    <p>
                        Phone:{' '}
                        <a className='text-customCoral' href='tel:06138057100'>
                            +61 (0) 3 8605 7100
                        </a>
                    </p>
                </div>
                <Button buttonText='Close' onClick={props.onClose} size='large' />
            </div>
        </>
    );
}
