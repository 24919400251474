import React, { FC, useMemo } from 'react';
import { PreloadedQuery, loadQuery, usePreloadedQuery } from 'react-relay';
import { Outlet, generatePath, useMatch, useParams } from 'react-router-dom';

import {
    Menu,
    MenuItem,
    PageLastRefreshed,
    SiteViewNavigation,
    ThemedPageHeading,
    useExtendedNavigate,
} from '@accesstel/pcm-ui';
import { SiteViewDeviceGroup } from '@accesstel/pcm-ui/dist/navigation/SiteViewNavigation/types';

import graphql from 'babel-plugin-relay/macro';
import { PageHeader, useDocumentTitle } from 'components';
import { AlertBanner } from 'components/AlertBanner';
import { useUserPermissions } from 'lib/auth';
import { getGlobalEnvironment } from 'lib/environment';
import { Paths } from 'lib/routes';
import { ErrorNotFound } from 'views/ErrorPage/ErrorNotFound';

import {
    SiteViewLayoutContentQuery,
    SiteViewLayoutContentQuery$data,
} from './__generated__/SiteViewLayoutContentQuery.graphql';
import { getNavigationOptions } from './lib';

type SiteDevice = NonNullable<SiteViewLayoutContentQuery$data['site']>['devices']['data'][number];

export interface SiteViewLayoutContentProps {
    queryRef: PreloadedQuery<SiteViewLayoutContentQuery>;
}

export const SiteViewLayoutContent: FC<SiteViewLayoutContentProps> = ({ queryRef }) => {
    const data = usePreloadedQuery(SiteViewQuery, queryRef);
    const { deviceId, siteId } = useParams() as { deviceId: string; siteId: string };
    const match = useMatch(Paths.SiteViewViewSiteDevicePage);
    const navigate = useExtendedNavigate();
    const { hasAssetsWrite } = useUserPermissions();

    const page = match?.params.page ?? 'overview';

    const site = data.site;

    useDocumentTitle(`View ${site?.name ?? 'Site'}`);

    const devices = useMemo(() => {
        if (!site) {
            return [];
        }

        return createNavigationDevices(site.devices.data, page);
    }, [site, page]);

    if (!site) {
        return <ErrorNotFound />;
    }

    let siteLocation: string;
    if (site.address.address) {
        siteLocation = `${site.address.address}, ${site.address.state}`;
    } else {
        siteLocation = site.address.state;
    }

    const viewEditSiteMenuItem: MenuItem = {
        name: hasAssetsWrite ? 'Edit Site' : 'View Site',
        onClick: () => {
            navigate({ pathname: Paths.EditSite, params: { id: siteId } });
        },
    };

    return (
        <div className='space-y-6 mb-32'>
            <PageHeader />
            <div className='flex flex-row justify-between'>
                <ThemedPageHeading value={`${site.name}, ${site.address.state}`} />
                <Menu id='site-view-menu' menuItems={[viewEditSiteMenuItem]} />
            </div>
            <div>
                <div className='font-CynthoNext-Light text-sm'>
                    <span className='text-customCoral'>Site Location: </span>
                    <span>{siteLocation}</span>
                </div>
                <PageLastRefreshed buttonClickCallback={() => window.location.reload()} />
            </div>
            <div className='w-full border-t border-customWhiteEggplant' />
            {devices.length > 0 && (
                <>
                    <SiteViewNavigation devices={devices} items={getNavigationOptions(deviceId)} />
                    <Outlet context={devices} />
                </>
            )}
            {devices.length === 0 && (
                <AlertBanner title='No Devices Found' message='There are no devices associated with this site.' />
            )}
        </div>
    );
};

function createNavigationDevices(devices: readonly SiteDevice[], page: string): SiteViewDeviceGroup[] {
    const visited = new Set<string>();
    const deviceGroups: SiteViewDeviceGroup[] = [];

    for (const device of devices) {
        if (!device.dualPlaneCompanion?.device) {
            // Single plane
            deviceGroups.push({
                device: {
                    id: device.id,
                    name: device.name,
                },
                to: generatePath(Paths.SiteViewRelativeDevicePage, { deviceId: device.id, page }),
            });
        } else {
            // Dual plane
            if (visited.has(device.id)) {
                continue;
            }

            const companion = device.dualPlaneCompanion.device;
            visited.add(companion.id);

            deviceGroups.push({
                device: {
                    id: device.id,
                    name: device.name,
                },
                to: generatePath(Paths.SiteViewRelativeDevicePage, { deviceId: device.id, page }),
                companion: {
                    id: companion.id,
                    name: companion.name,
                },
            });
        }
    }

    return deviceGroups;
}

export const SiteViewQuery = graphql`
    query SiteViewLayoutContentQuery($id: ID!) {
        site(id: $id) {
            name
            address {
                address
                state
            }
            devices {
                data {
                    id
                    name
                    dualPlaneCompanion {
                        device {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;

export function loadSiteViewPageData(id: string) {
    return loadQuery(
        getGlobalEnvironment(),
        SiteViewQuery,
        {
            id,
        },
        {
            fetchPolicy: 'store-and-network',
        }
    );
}
