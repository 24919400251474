import React, { FC, useCallback } from 'react';
import { fetchQuery, useFragment, useMutation, useRelayEnvironment } from 'react-relay';

import { Button, FormSection, FormikDurationPicker, useToast } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { Form, Formik, FormikHelpers } from 'formik';
import { Duration } from 'luxon';

import { DataCollectionFormValues } from '../schema';
import { DataCollectionValidationSchema } from '../validation';
import {
    DataCollectionFragment$data,
    DataCollectionFragment$key,
} from './__generated__/DataCollectionFragment.graphql';
import {
    DataCollectionSettingsIn,
    DataCollectionUpdateMutation,
} from './__generated__/DataCollectionUpdateMutation.graphql';

export interface DataCollectionProps {
    settings: DataCollectionFragment$key;
}

export const DataCollection: FC<DataCollectionProps> = ({ settings }) => {
    const { show } = useToast();
    const data = useFragment(DataCollectionFragment, settings);

    const [saveDataCollection] = useMutation<DataCollectionUpdateMutation>(UpdateMutation);
    const environment = useRelayEnvironment();

    const handleSubmit = useCallback(
        (
            values: DataCollectionFormValues,
            { setSubmitting, setFieldError }: FormikHelpers<DataCollectionFormValues>
        ) => {
            const initialValues = toInitialValues(data);
            const submitData = toSubmitData(values, initialValues);

            if (!submitData) {
                return;
            }

            setSubmitting(true);
            saveDataCollection({
                variables: {
                    input: submitData,
                },
                onCompleted: data => {
                    if (data.updateGlobalDataCollectionSettings.success) {
                        show({
                            text: 'Saved changes',
                            variant: 'info',
                        });

                        // Reload the form
                        fetchQuery(environment, ReloadQuery, {}).subscribe({
                            next() {
                                setSubmitting(false);
                            },
                            error(error: unknown) {
                                setSubmitting(false);
                                captureException(error, scope => {
                                    scope.setTag('Function', 'Data collection reload');
                                    return scope;
                                });
                            },
                        });
                    } else {
                        setSubmitting(false);
                        show({
                            text: 'Unable to save changes',
                            variant: 'error',
                        });

                        for (const problem of data.updateGlobalDataCollectionSettings.problems) {
                            switch (problem) {
                                case 'AllocationDelayInvalid':
                                    setFieldError('deviceManagement.allocationDelay', 'Must be valid time');
                                    break;
                                case 'CheckInIntervalInvalid':
                                    setFieldError('deviceManagement.checkInInterval', 'Must be valid time');
                                    break;
                                case 'GeneralIntervalInvalid':
                                    setFieldError('polling.generalInterval', 'Must be valid time');
                                    break;
                                case 'HighFrequencyIntervalInvalid':
                                    setFieldError('polling.highFrequencyInterval', 'Must be valid time');
                                    break;
                                case 'MissedCheckInTimeoutInvalid':
                                    setFieldError('deviceManagement.missedCheckInTimeout', 'Must be valid time');
                                    break;
                                case 'SyncIntervalInvalid':
                                    setFieldError('polling.syncInterval', 'Must be valid time');
                                    break;
                                case 'TimeoutInvalid':
                                    setFieldError('polling.timeout', 'Must be valid time');
                                    break;
                                case 'GeneralHighFrequencyOutOfOrder':
                                    setFieldError(
                                        'polling.generalInterval',
                                        'Must be less frequent than high frequency interval'
                                    );
                                    setFieldError(
                                        'polling.highFrequencyInterval',
                                        'Must be more frequent than general interval'
                                    );
                                    break;
                            }
                        }
                    }
                },
                onError: error => {
                    setSubmitting(false);
                    show({
                        text: 'Unable to save changes',
                        variant: 'error',
                    });
                    captureException(error, scope => {
                        scope.setTag('Function', 'Data collection settings submit');
                        return scope;
                    });
                },
            });
        },
        [data, environment, saveDataCollection, show]
    );

    return (
        <FormSection
            label='Data collection'
            blurb='Controls how data is collected from devices. NOTE: Does not take effect until connectors are restarted.'
        >
            <Formik
                initialValues={toInitialValues(data)}
                validationSchema={DataCollectionValidationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isSubmitting, isValid, dirty }) => (
                    <Form>
                        <div className='space-y-4 grid grid-cols-2 pt-6 gap-x-6 pr-6'>
                            <div className='col-span-2 font-CynthoNext-SemiBold'>Polling</div>
                            <div className='col-start-1'>
                                <FormikDurationPicker
                                    name='polling.generalInterval'
                                    placeHolder='General Polling Interval'
                                    light
                                    variant='outlined'
                                    units={['minutes', 'seconds']}
                                    minimum={Duration.fromObject({})}
                                    maximum={Duration.fromObject({ minutes: 30 })}
                                    steps={{ minutes: 1, seconds: 10 }}
                                />
                            </div>
                            <div className='col-start-1'>
                                <FormikDurationPicker
                                    name='polling.highFrequencyInterval'
                                    placeHolder='High Frequency Polling Interval'
                                    light
                                    variant='outlined'
                                    units={['minutes', 'seconds']}
                                    minimum={Duration.fromObject({})}
                                    maximum={Duration.fromObject({ minutes: 30 })}
                                    steps={{ minutes: 1, seconds: 10 }}
                                />
                            </div>
                            <div className='col-start-1'>
                                <FormikDurationPicker
                                    name='polling.syncInterval'
                                    placeHolder='Sync Interval'
                                    light
                                    variant='outlined'
                                    units={['hours', 'minutes', 'seconds']}
                                    minimum={Duration.fromObject({})}
                                    maximum={Duration.fromObject({ days: 1 })}
                                />
                            </div>
                            <div className='col-start-1'>
                                <FormikDurationPicker
                                    name='polling.timeout'
                                    placeHolder='Poll Timeout'
                                    light
                                    variant='outlined'
                                    units={['seconds', 'milliseconds']}
                                    minimum={Duration.fromObject({ milliseconds: 50 })}
                                    maximum={Duration.fromObject({ seconds: 30 })}
                                    steps={{ seconds: 1 }}
                                />
                            </div>
                        </div>
                        <div className='space-y-4 grid grid-cols-2 pt-6 gap-x-6 pr-6'>
                            <div className='col-span-2 font-CynthoNext-SemiBold'>Device Management</div>
                            <div className='col-start-1'>
                                <FormikDurationPicker
                                    name='deviceManagement.checkInInterval'
                                    placeHolder='Check-in Interval'
                                    light
                                    variant='outlined'
                                    units={['minutes', 'seconds']}
                                    minimum={Duration.fromObject({})}
                                    maximum={Duration.fromObject({ minutes: 30 })}
                                    steps={{ minutes: 1, seconds: 10 }}
                                />
                            </div>
                            <div className='col-start-1'>
                                <FormikDurationPicker
                                    name='deviceManagement.missedCheckInTimeout'
                                    placeHolder='Missed Check-in Timeout'
                                    light
                                    variant='outlined'
                                    units={['minutes', 'seconds']}
                                    minimum={Duration.fromObject({})}
                                    maximum={Duration.fromObject({ minutes: 30 })}
                                    steps={{ minutes: 1, seconds: 10 }}
                                />
                            </div>
                            <div className='col-start-1'>
                                <FormikDurationPicker
                                    name='deviceManagement.allocationDelay'
                                    placeHolder='Allocation Delay'
                                    light
                                    variant='outlined'
                                    units={['minutes', 'seconds']}
                                    minimum={Duration.fromObject({})}
                                    maximum={Duration.fromObject({ minutes: 30 })}
                                    steps={{ minutes: 1, seconds: 10 }}
                                />
                            </div>
                        </div>
                        <div className='flex flex-row gap-4 justify-end'>
                            <Button
                                type='reset'
                                variant='white'
                                buttonText='Reset'
                                disabled={isSubmitting || !dirty}
                                size='small'
                            />
                            <Button
                                type='submit'
                                variant='primary'
                                buttonText='Save'
                                disabled={isSubmitting || !isValid || !dirty}
                                processing={isSubmitting}
                                size='small'
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </FormSection>
    );
};

export const DataCollectionFragment = graphql`
    fragment DataCollectionFragment on Settings {
        dataCollection {
            polling {
                generalInterval
                highFrequencyInterval
                syncInterval
                timeout
            }
            deviceManagement {
                checkInInterval
                missedCheckInTimeout
                allocationDelay
            }
        }
    }
`;

const UpdateMutation = graphql`
    mutation DataCollectionUpdateMutation($input: DataCollectionSettingsIn!) {
        updateGlobalDataCollectionSettings(input: $input) {
            success
            problems
        }
    }
`;

const ReloadQuery = graphql`
    query DataCollectionReloadQuery {
        settings {
            ...DataCollectionFragment
        }
    }
`;

function toInitialValues(data: DataCollectionFragment$data): DataCollectionFormValues {
    const { polling, deviceManagement } = data.dataCollection;

    return {
        polling: {
            generalInterval: Duration.fromISO(polling.generalInterval),
            highFrequencyInterval: Duration.fromISO(polling.highFrequencyInterval),
            syncInterval: Duration.fromISO(polling.syncInterval),
            timeout: Duration.fromISO(polling.timeout),
        },
        deviceManagement: {
            checkInInterval: Duration.fromISO(deviceManagement.checkInInterval),
            missedCheckInTimeout: Duration.fromISO(deviceManagement.missedCheckInTimeout),
            allocationDelay: Duration.fromISO(deviceManagement.allocationDelay),
        },
    };
}

function toSubmitData(
    values: DataCollectionFormValues,
    initialValues: DataCollectionFormValues
): DataCollectionSettingsIn | null {
    const submitData: DataCollectionSettingsIn = {
        polling: {},
        deviceManagement: {},
    };
    let changed = false;
    if (!values.polling.generalInterval.equals(initialValues.polling.generalInterval)) {
        submitData.polling!.generalInterval = values.polling.generalInterval.toISO();
        changed = true;
    }

    if (!values.polling.highFrequencyInterval.equals(initialValues.polling.highFrequencyInterval)) {
        submitData.polling!.highFrequencyInterval = values.polling.highFrequencyInterval.toISO();
        changed = true;
    }

    if (!values.polling.syncInterval.equals(initialValues.polling.syncInterval)) {
        submitData.polling!.syncInterval = values.polling.syncInterval.toISO();
        changed = true;
    }

    if (!values.polling.timeout.equals(initialValues.polling.timeout)) {
        submitData.polling!.timeout = values.polling.timeout.toISO();
        changed = true;
    }

    if (!values.deviceManagement.checkInInterval.equals(initialValues.deviceManagement.checkInInterval)) {
        submitData.deviceManagement!.checkInInterval = values.deviceManagement.checkInInterval.toISO();
        changed = true;
    }

    if (!values.deviceManagement.missedCheckInTimeout.equals(initialValues.deviceManagement.missedCheckInTimeout)) {
        submitData.deviceManagement!.missedCheckInTimeout = values.deviceManagement.missedCheckInTimeout.toISO();
        changed = true;
    }

    if (!values.deviceManagement.allocationDelay.equals(initialValues.deviceManagement.allocationDelay)) {
        submitData.deviceManagement!.allocationDelay = values.deviceManagement.allocationDelay.toISO();
        changed = true;
    }

    if (!changed) {
        return null;
    }

    return submitData;
}
