import { DateRangeFilter } from 'components/FilterDateSelect/common';

export enum AlertTableColumn {
    Severity = 'Severity',
    Domain = 'Domain',
    Message = 'Message',
    DeviceName = 'DeviceName',
    SiteName = 'SiteName',
    RaiseDate = 'RaiseDate',
    ClearDate = 'ClearDate',
    Category = 'Category',
    Originator = 'Originator',
    IsActive = 'IsActive',
}

export enum AlertSeverity {
    Critical = 'Critical',
    Major = 'Major',
    Minor = 'Minor',
    Warning = 'Warning',
    Indeterminate = 'Indeterminate',
}

export enum AlertDomain {
    AcPower = 'AcPower',
    Battery = 'Battery',
    Load = 'Load',
    Rectifier = 'Rectifier',
}

export enum AlertOriginator {
    Device = 'Device',
}

export enum AlertExtraFilters {}

export interface AlertSeverityFilter {
    id: AlertSeverity;
    value: string;
}

export interface AlertDomainFilter {
    id: AlertDomain;
    value: string;
}

export interface AlertOriginatorFilter {
    id: AlertOriginator;
    value: string;
}

export interface AlertCategoryFilter {
    id?: string;
    type: 'result' | 'special';
    wildcard?: boolean;
    value: string;
}

export interface AlertDeviceNameFilter {
    name: string;
    siteName?: string;
    type: 'result' | 'special';
    wildcard?: boolean;
    id?: string;
    state?: string;
}

export interface AlertSiteNameFilter {
    name: string;
    type: 'result' | 'special';
    wildcard?: boolean;
    id?: string;
    state?: string;
}

export type AlertRaiseTimeFilter = DateRangeFilter;
export type AlertClearTimeFilter = DateRangeFilter;

export enum IsAlertActive {
    Yes = 'Yes',
    No = 'No',
}
