import './wdyr';

import React from 'react';
import { createRoot } from 'react-dom/client';

import * as Sentry from '@sentry/react';
import { getCurrentUser } from 'lib/auth';

import App from './App';
import * as serviceWorker from './serviceWorker';

const DefaultSentryTraceSampleRate = 0.2;

if (process.env.NODE_ENV === 'production') {
    let traceSampleRate: number;
    if (process.env.REACT_APP_SENTRY_TRACE_PERCENT) {
        traceSampleRate = Number(process.env.REACT_APP_SENTRY_TRACE_PERCENT);
        if (!isFinite(traceSampleRate)) {
            traceSampleRate = DefaultSentryTraceSampleRate;
        } else if (traceSampleRate < 0) {
            traceSampleRate = 0;
        } else if (traceSampleRate > 1) {
            traceSampleRate = 1;
        }
    } else {
        traceSampleRate = DefaultSentryTraceSampleRate;
    }

    const environment = process.env.REACT_APP_SENTRY_ENVIRONMENT ?? 'unknown';
    const release = process.env.REACT_APP_SENTRY_RELEASE ?? 'unknown';
    const tunnelEndpoint = process.env.REACT_APP_SENTRY_TUNNEL_URL ?? undefined;

    Sentry.init({
        dsn: 'https://33ef7634f6cf4bdc99c062d8764827f0@o1271598.ingest.sentry.io/6464069',
        tunnel: tunnelEndpoint,
        integrations: [
            new Sentry.BrowserTracing(),
            new Sentry.Replay({
                maskAllText: true,
                blockAllMedia: true,
            }),
        ],

        tracesSampleRate: traceSampleRate,
        replaysOnErrorSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        environment,
        release: `accata-frontend@${release}`,
        beforeSend: async event => {
            // Populate the user information
            const user = await getCurrentUser();
            if (user) {
                if (event.user) {
                    event.user.username = user.username;
                } else {
                    event.user = {
                        username: user.username,
                    };
                }
            }

            return event;
        },
    });
}

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
