import { FormikErrors } from 'formik';
import { set } from 'lodash';
import { AttributeValidationSchema } from 'views/manage/common/schema';
import { getDuplicateAttributeIndices } from 'views/manage/common/validation';
import { SiteValidationSchema } from 'views/manage/site/add-edit/validation';
import { array, boolean, number, object, string } from 'yup';

import { FormControlParameters } from './lib/reducer';
import { DeviceFormValues } from './schema';
import { getBatterySettingsValidationSchema } from './sub-forms/battery-settings';
import { ConnectivitySettingsValidationSchema } from './sub-forms/connectivity-settings/schema';

export const OptimaIntegrationValidationSchema = object({
    elementName: string().optional().trim(),
    siteId: number().optional().integer("Optima Site Id's must be whole numbers"),
});

function getExistingSiteValidation(usingNew: boolean) {
    let schema = object({
        id: string().required(),
    }).nullable();

    if (!usingNew) {
        schema = schema.required('Site must be provided');
    }

    return schema;
}

export function getDeviceValidationSchema(newSite: boolean, newBatteryType: boolean) {
    return object({
        name: string().trim().required('Name must be provided'),
        type: string().required(),
        firmwareVersion: string().nullable(),
        site: getExistingSiteValidation(newSite),
        newSite: newSite ? SiteValidationSchema : object(),
        settings: ConnectivitySettingsValidationSchema,
        batteries: getBatterySettingsValidationSchema(newBatteryType),
        monitorOnly: boolean().required(),
        optimaIntegration: OptimaIntegrationValidationSchema,
        attributes: array(AttributeValidationSchema).test('unique', 'Cannot have duplicate attribute', attributes => {
            const names = attributes?.map(attribute => attribute.name ?? '') ?? [];
            const visited = new Set<string>(names);
            return visited.size === names.length;
        }),
    });
}

export function checkDeviceFormAdditionalValidity(
    values: DeviceFormValues,
    formState: FormControlParameters
): FormikErrors<DeviceFormValues> {
    const errors: FormikErrors<DeviceFormValues> = {};

    // Validate attributes are unique
    const duplicateAttributeIndices = getDuplicateAttributeIndices(values.attributes);
    if (duplicateAttributeIndices.length > 0) {
        for (const index of duplicateAttributeIndices) {
            set(errors, `attributes[${index}].name`, 'Attribute must be unique');
        }
    }

    const duplicateCompanionAttributeIndices = getDuplicateAttributeIndices(values.newCompanion.attributes);
    if (duplicateCompanionAttributeIndices.length > 0) {
        for (const index of duplicateCompanionAttributeIndices) {
            set(errors, `newCompanion.attributes[${index}].name`, 'Attribute must be unique');
        }
    }

    return errors;
}
