import React, { FC } from 'react';

import { FormikTextBox } from '@accesstel/pcm-ui';

import classNames from 'classnames';

import style from '../../../style.module.css';

export const QuickTestFields: FC = () => {
    return (
        <>
            <div className={classNames(style.settings_quick_reserved_time, 'font-CynthoNext-Regular')}>
                <FormikTextBox
                    name='quickSettings.reserveTime'
                    light
                    placeHolder='Reserve time to test (%)'
                    required
                    testId='reserve-time-to-test'
                    variant='outlined'
                />
            </div>
            <div className={classNames(style.settings_quick_min_voltage, 'font-CynthoNext-Regular')}>
                <FormikTextBox
                    name='quickSettings.minAllowedVoltage'
                    light
                    placeHolder='Minimum allowed voltage (V)'
                    testId='minimum-allowed-voltage'
                    variant='outlined'
                />
            </div>
        </>
    );
};
