import React, { FC, useMemo, useState } from 'react';

import { Checkbox, FieldError, SearchBox } from '@accesstel/pcm-ui';

import { FieldArray, useField } from 'formik';

import { queries_GetExportFormAvailableMetricsQuery$data } from '../__generated__/queries_GetExportFormAvailableMetricsQuery.graphql';
import style from '../style.module.css';

interface MetricsProps {
    name: string;
    availableMetrics: queries_GetExportFormAvailableMetricsQuery$data['metricsForExport'];
}

export const Metrics: FC<MetricsProps> = ({ name, availableMetrics }) => {
    const [field, , helper] = useField<string[]>(name);
    const [filterSearch, setFilterSearch] = useState('');

    const filteredMetrics = useMemo(() => {
        if (filterSearch !== '') {
            const pattern = new RegExp(filterSearch, 'i');
            const tempMetrics = availableMetrics.filter(metric => {
                return metric.displayName.match(pattern);
            });

            return tempMetrics;
        } else {
            return availableMetrics;
        }
    }, [availableMetrics, filterSearch]);

    return (
        <div data-testid='metrics-section'>
            <div className={style.metrics_header}>
                <div className={style.metrics_header_title}>
                    <span>Export Metrics</span>
                    <FieldError name={name} />
                </div>
                <div className='flex flex-col'>
                    <SearchBox
                        as='div'
                        id='metrics-search'
                        renderResult={() => null}
                        variant='filter'
                        onChange={setFilterSearch}
                        placeHolder='Search Metrics'
                        showReset={filterSearch !== ''}
                        onReset={() => setFilterSearch('')}
                        value={filterSearch}
                    />
                    <div className={style.button_group}>
                        <div
                            className={style.select_button}
                            onClick={() => {
                                const existingSelected = field.value;
                                const filteredMetricsValue = filteredMetrics.map(m => m.metric);

                                const combinedSelectedMetrics = new Set([...existingSelected, ...filteredMetricsValue]);
                                helper.setValue(Array.from(combinedSelectedMetrics));
                            }}
                        >
                            Select All
                        </div>
                        <span className={style.divider}>|</span>
                        <div
                            className={style.select_button}
                            onClick={() => {
                                const remainingMetrics = field.value.filter(
                                    metric => !filteredMetrics.map(m => m.metric).includes(metric)
                                );
                                helper.setValue(remainingMetrics);
                            }}
                        >
                            Select None
                        </div>
                    </div>
                </div>
            </div>
            <FieldArray
                name={name}
                render={arrayHelpers => (
                    <div className={style.metric_list}>
                        {filteredMetrics.map((metric, idx) => (
                            <div key={idx}>
                                <Checkbox
                                    placeHolder={metric.displayName}
                                    onChange={e => {
                                        const existingMetrics = field.value;
                                        const isChecked = e.target.checked;
                                        if (isChecked) {
                                            arrayHelpers.push(metric.metric);
                                        } else {
                                            helper.setValue(existingMetrics.filter(m => m !== metric.metric));
                                        }
                                    }}
                                    checked={field.value.includes(metric.metric)}
                                    variant='white'
                                />
                            </div>
                        ))}
                        {filteredMetrics.length === 0 && <span className='text-customCoral'>No matching metrics</span>}
                    </div>
                )}
            />
            <div className={style.metrics_counter}>
                {field.value.length > 0 && <span>{`${field.value.length} metrics selected`}</span>}
            </div>
        </div>
    );
};
