import graphql from 'babel-plugin-relay/macro';

export const addBatteryTestTaskMutation = graphql`
    mutation mutations_AddBatteryTestTaskMutation(
        $name: String!
        $devices: [ID!]!
        $smartStart: Boolean
        $type: BatteryTestType!
        $quickSettings: BatteryTestQuickTypeInput
        $capacitySettings: BatteryTestCapacityTypeInput
        $customSettings: BatteryTestCustomTypeInput
        $schedule: BatteryTestScheduleInput
    ) {
        addBatteryTest(
            settings: {
                name: $name
                devices: $devices
                smartStart: $smartStart
                type: $type
                quickSettings: $quickSettings
                customSettings: $customSettings
                capacitySettings: $capacitySettings
                schedule: $schedule
            }
        ) {
            ... on BatteryTest {
                id
            }
            ... on BatteryTestScheduleProblemResponse {
                problems
            }
        }
    }
`;
