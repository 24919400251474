import React, { FC, Suspense } from 'react';
import { PreloadedQuery, loadQuery } from 'react-relay';
import { useLoaderData } from 'react-router-dom';

import { useDocumentTitle } from 'components';
import { getGlobalEnvironment } from 'lib/environment';

import { PageDataQuery, SystemConfigurationContent } from './SystemConfigurationContent';
import { SystemConfigurationContentQuery } from './__generated__/SystemConfigurationContentQuery.graphql';

export const SystemConfiguration: FC = () => {
    const queryRef = useLoaderData() as PreloadedQuery<SystemConfigurationContentQuery>;

    useDocumentTitle('Settings - Configuration');

    return (
        <>
            {queryRef && (
                <Suspense fallback={<div>Loading</div>}>
                    <SystemConfigurationContent queryRef={queryRef} />
                </Suspense>
            )}
        </>
    );
};

export async function loadSystemConfigurationView() {
    return loadQuery(getGlobalEnvironment(), PageDataQuery, {});
}
