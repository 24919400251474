import React, { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';

import { Button, ChevronRightIcon, CrossIcon, PlusIcon } from '@accesstel/pcm-ui';

import { capitalize } from 'lib/textFormatters';

import style from './style.module.css';

export interface ProvisionLayoutProps {
    type: string;
    primaryAction: () => void;
    secondaryAction: () => void;
    operation?: 'add' | 'edit' | 'view';
    disableFormButtons?: boolean;
}

export const ProvisionLayout: FC<PropsWithChildren<ProvisionLayoutProps>> = ({
    children,
    primaryAction,
    secondaryAction,
    type,
    operation = 'add',
    disableFormButtons = false,
}) => {
    const formRef = useRef<HTMLFormElement>(null);

    const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(false);
    const [isSecondaryButtonDisabled, setIsSecondaryButtonDisabled] = useState(false);

    const isView = operation === 'view';
    const isAdd = operation === 'add';

    useEffect(() => {
        if (!disableFormButtons) {
            setIsPrimaryButtonDisabled(false);
            setIsSecondaryButtonDisabled(false);
        }
    }, [disableFormButtons]);

    return (
        <>
            <h1 className={`pl-4 ${style.note}`}>*Mandatory Fields</h1>
            <form ref={formRef}>
                {children}

                <div className={style.provision_grid}>
                    <div className={style.provision_buttons}>
                        {isView && (
                            <Button
                                type='button'
                                variant='gray'
                                buttonText='Close'
                                onClick={e => {
                                    e.preventDefault();
                                    secondaryAction();
                                }}
                                disabled={disableFormButtons}
                                icon={<CrossIcon />}
                            />
                        )}
                        {!isView && (
                            <Button
                                type='submit'
                                buttonText={`${isAdd ? 'Submit' : 'Save'} ${capitalize(type)}`}
                                onClick={e => {
                                    e.preventDefault();
                                    if (formRef.current?.reportValidity() && !disableFormButtons) {
                                        setIsPrimaryButtonDisabled(true);
                                        primaryAction();
                                    }
                                }}
                                processing={isPrimaryButtonDisabled && disableFormButtons}
                                disabled={disableFormButtons}
                                icon={<ChevronRightIcon />}
                            />
                        )}
                        {!isView && (
                            <Button
                                type='submit'
                                buttonClasses='ml-8'
                                variant='white'
                                buttonText={isAdd ? 'Submit & Add Another' : 'Cancel'}
                                onClick={e => {
                                    e.preventDefault();
                                    if (!isAdd) {
                                        setIsSecondaryButtonDisabled(true);
                                        secondaryAction();
                                    } else if (formRef.current?.reportValidity() && !disableFormButtons) {
                                        setIsSecondaryButtonDisabled(true);
                                        secondaryAction();
                                    }
                                }}
                                processing={isSecondaryButtonDisabled && disableFormButtons}
                                disabled={disableFormButtons}
                                icon={isAdd ? <PlusIcon /> : <CrossIcon />}
                            />
                        )}
                    </div>
                </div>
            </form>
        </>
    );
};
