import { useCallback, useEffect, useState } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';

import { Suggestion } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';

import { siteTypeSuggestQuery } from './__generated__/siteTypeSuggestQuery.graphql';

export interface SiteTypeSuggestion extends Suggestion {
    displayName: string;
}

type OptionalSuggestions = SiteTypeSuggestion[] | undefined;
type ClearFunction = () => void;

export function useSuggestSiteType(search: string, limit: number): [OptionalSuggestions, ClearFunction] {
    const environment = useRelayEnvironment();

    const [typeSuggestions, setTypeSuggestions] = useState<SiteTypeSuggestion[] | undefined>([]);

    useEffect(() => {
        fetchQuery<siteTypeSuggestQuery>(
            environment,
            graphql`
                query siteTypeSuggestQuery($name: String!, $limit: Int) {
                    siteTypes(search: $name, limit: $limit)
                }
            `,
            {
                name: search,
                limit,
            }
        )
            .toPromise()
            .then(data => {
                const suggestions: SiteTypeSuggestion[] =
                    data?.siteTypes.map(type => ({ id: type, displayName: type })) ?? [];
                // append the new type to the suggestions
                if (search.length > 0 && !data?.siteTypes.includes(search)) {
                    suggestions.push({
                        id: search,
                        displayName: search,
                        isNew: true,
                    });
                }
                setTypeSuggestions(suggestions);
            });
    }, [search, limit, environment]);

    const clear = useCallback(() => {
        setTypeSuggestions(undefined);
    }, []);

    return [typeSuggestions, clear];
}
