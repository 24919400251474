/**
 * @generated SignedSource<<c56404651d6faaf251485a023f65cd68>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LoginStageSettingsQuery$variables = {};
export type LoginStageSettingsQuery$data = {
  readonly settings: {
    readonly login: {
      readonly federation: ReadonlyArray<{
        readonly default: boolean;
        readonly displayName: string;
        readonly id: string;
      }>;
    };
  };
};
export type LoginStageSettingsQuery = {
  response: LoginStageSettingsQuery$data;
  variables: LoginStageSettingsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Settings",
    "kind": "LinkedField",
    "name": "settings",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LoginSettings",
        "kind": "LinkedField",
        "name": "login",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FederatedLoginOption",
            "kind": "LinkedField",
            "name": "federation",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "default",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginStageSettingsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LoginStageSettingsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "783ed9eb15a05079f9d339460664eb6b",
    "id": null,
    "metadata": {},
    "name": "LoginStageSettingsQuery",
    "operationKind": "query",
    "text": "query LoginStageSettingsQuery {\n  settings {\n    login {\n      federation {\n        id\n        displayName\n        default\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b1e127c5dd5ea45ef72c172464a995b6";

export default node;
