import React, { FC, Suspense, useEffect } from 'react';
import { fetchQuery, useQueryLoader } from 'react-relay';
import { useParams } from 'react-router-dom';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { getGlobalEnvironment } from 'lib/environment';

import { ViewBatteryHealthTaskContent } from './ViewBatteryHealthTaskContent';
import { ViewBatteryHealthTaskSkeleton } from './ViewBatteryHealthTaskSkeleton';
import ViewBatteryHealthTaskContentQuery, {
    ViewBatteryHealthTaskContentQuery as ViewBatteryHealthTaskContentQueryType,
} from './__generated__/ViewBatteryHealthTaskContentQuery.graphql';
import { ViewBatteryHealthTaskGetTaskNameQuery } from './__generated__/ViewBatteryHealthTaskGetTaskNameQuery.graphql';

export const ViewBatteryHealthTask: FC = () => {
    // FIXME: Casting isnt the best way of handling undefined type check. Currently, there are multiple ways people are dealing with this.
    // https://github.com/remix-run/react-router/issues/8200
    const { taskId } = useParams() as { taskId: string };

    const [contentQueryRef, loadQuery, disposeQuery] = useQueryLoader<ViewBatteryHealthTaskContentQueryType>(
        ViewBatteryHealthTaskContentQuery
    );

    useEffect(() => {
        loadQuery(
            {
                id: taskId,
                orderBy: {
                    field: 'Site',
                    dir: 'Asc',
                },
                filters: null,
                deviceFilters: null,
                page: 1,
            },
            { fetchPolicy: 'store-and-network' }
        );
        return () => {
            disposeQuery();
        };
    }, [disposeQuery, loadQuery, taskId]);

    return (
        <div className='space-y-6 mb-32' key={taskId}>
            {!contentQueryRef && <ViewBatteryHealthTaskSkeleton />}
            {contentQueryRef && (
                <Suspense fallback={<ViewBatteryHealthTaskSkeleton />}>
                    <ViewBatteryHealthTaskContent key={taskId} queryRef={contentQueryRef} />
                </Suspense>
            )}
        </div>
    );
};

export async function getTaskViewBreadcrumb(id: string): Promise<string> {
    const environment = getGlobalEnvironment();
    try {
        const data = await fetchQuery<ViewBatteryHealthTaskGetTaskNameQuery>(
            environment,
            graphql`
                query ViewBatteryHealthTaskGetTaskNameQuery($id: ID!) {
                    task(id: $id) {
                        ... on BatteryTest {
                            name
                        }
                    }
                }
            `,
            { id },
            {
                fetchPolicy: 'store-or-network',
            }
        ).toPromise();

        return data?.task?.name ?? 'Unnamed Test';
    } catch (error) {
        captureException(error, scope => {
            scope.setTag('Site', id);
            scope.setTag('Function', 'getTaskViewBreadcrumb');
            return scope;
        });
        return 'Unnamed Test';
    }
}
