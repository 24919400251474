import React, { FC, useCallback } from 'react';
import { useRelayEnvironment } from 'react-relay';
import { useNavigate, useParams } from 'react-router-dom';

import { LoadableContentArea, ThemedPageHeading, useReferrer, useToast } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { PageHeader, useDocumentTitle } from 'components';
import { Formik, FormikHelpers } from 'formik';
import { FormikProvisionLayout } from 'layouts/FormikProvisionLayout';
import { useUserPermissions } from 'lib/auth';
import { useQuery } from 'lib/query-helpers';
import { Paths } from 'lib/routes';
import { ErrorNotFound } from 'views/ErrorPage/ErrorNotFound';

import { SiteInformation } from './SiteInformation';
import { EditSiteFormQuery } from './__generated__/EditSiteFormQuery.graphql';
import { convertSiteFormStateToApi, convertSiteToFormState } from './lib/convert';
import { decodeSiteApiErrors, updateSite } from './lib/saving';
import { SiteFormValues } from './schema';
import { SiteValidationSchema } from './validation';

export interface EditSiteProps {
    id: string;
}

export const EditSiteForm: FC = () => {
    // FIXME: Casting isnt the best way of handling undefined type check. Currently, there are multiple ways people are dealing with this.
    // https://github.com/remix-run/react-router/issues/8200
    const { id: siteId } = useParams() as { id: string };

    const { hasAssetsWrite } = useUserPermissions();
    const environment = useRelayEnvironment();
    const navigate = useNavigate();
    const referrer = useReferrer() ?? Paths.Sites;

    let documentTitle: string;
    if (hasAssetsWrite) {
        documentTitle = 'Edit Site';
    } else {
        documentTitle = 'View Site';
    }

    useDocumentTitle(documentTitle);

    const { show } = useToast();

    // FIXME: Use a suspending query so we don't have to handle null states
    const { data, error } = useQuery<EditSiteFormQuery>(
        LoadExistingSiteQuery,
        {
            siteId: siteId,
        },
        {
            fetchPolicy: 'network-only',
        }
    );

    const handleEdit = useCallback(
        async (values: SiteFormValues, { setErrors, setSubmitting }: FormikHelpers<SiteFormValues>) => {
            setSubmitting(true);

            try {
                const updatedSite = convertSiteFormStateToApi(values);

                await updateSite(siteId, updatedSite, environment);

                show({
                    text: 'Site updated successfully!',
                    variant: 'info',
                });

                navigate(referrer);
            } catch (error) {
                if (Array.isArray(error)) {
                    const formErrors = decodeSiteApiErrors(error);
                    setErrors(formErrors);

                    show({
                        text: 'Unable to save site. Please correct the highlighted errors',
                        variant: 'error',
                    });
                } else {
                    captureException(error, scope => {
                        scope.setTag('Site', siteId);
                        return scope;
                    });

                    show({
                        text: 'Unable to save site. Try again later',
                        variant: 'error',
                    });
                }
            }
        },
        [environment, navigate, referrer, show, siteId]
    );

    if (error || (data && data.site === null)) {
        return <ErrorNotFound />;
    }

    return (
        <div className='space-y-6'>
            <PageHeader />
            <ThemedPageHeading value={documentTitle} />
            <LoadableContentArea
                data={data}
                className='h-96'
                render={loadedData => (
                    <Formik
                        initialValues={convertSiteToFormState(loadedData.site)}
                        validationSchema={SiteValidationSchema}
                        onSubmit={handleEdit}
                    >
                        <FormikProvisionLayout
                            type='site'
                            operation={hasAssetsWrite ? 'edit' : 'view'}
                            secondaryAction={() => navigate(referrer)}
                        >
                            <SiteInformation />
                        </FormikProvisionLayout>
                    </Formik>
                )}
            />
        </div>
    );
};

const LoadExistingSiteQuery = graphql`
    query EditSiteFormQuery($siteId: ID!) {
        site(id: $siteId) {
            name
            type
            address {
                address
                state
                postcode
                coordinates {
                    longitude
                    latitude
                }
            }
            attributes {
                name
                value
            }
        }
    }
`;
