import React, { FC } from 'react';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay';

import { ThemedPageHeading } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { PageHeader } from 'components';

import { SystemConfigurationContentQuery } from './__generated__/SystemConfigurationContentQuery.graphql';
import { BatteryTesting } from './sections/BatteryTesting';
import { DataCollection } from './sections/DataCollection';
import { Insights } from './sections/Insights';

export interface SystemConfigurationContentProps {
    queryRef: PreloadedQuery<SystemConfigurationContentQuery>;
}

export const SystemConfigurationContent: FC<SystemConfigurationContentProps> = ({ queryRef }) => {
    const data = usePreloadedQuery(PageDataQuery, queryRef);

    return (
        <div className='space-y-6 mb-32 text-customEggplant'>
            <PageHeader />
            <ThemedPageHeading value='Configuration' />

            <div className='space-y-6'>
                <DataCollection settings={data.settings} />
                <BatteryTesting settings={data.settings} />
                <Insights settings={data.settings} />
            </div>
        </div>
    );
};

export const PageDataQuery = graphql`
    query SystemConfigurationContentQuery {
        settings {
            ...DataCollectionFragment
            ...BatteryTestingFragment
            ...InsightsFragment
        }
    }
`;
