/**
 * @generated SignedSource<<b5f045ef30c714bb55ecf58ca7076709>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BatteryStatus = "BoostCharging" | "Charging" | "Discharging" | "Disconnected" | "FloatCharging" | "Idle" | "Missing" | "Unknown" | "%future added value";
export type BatteryTechnologyType = "LeadAcidAGM" | "LeadAcidFlooded" | "LeadAcidGel" | "LeadAcidOther" | "LeadAcidVRLA" | "LithiumIon" | "LithiumIronPhosphate" | "LithiumPolymer" | "NickelCadmium" | "NickelMetalHydride" | "Other" | "Supercapacitor" | "%future added value";
export type DeviceCapability = "BlocLevelData" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Details_device$data = {
  readonly battery: {
    readonly metrics: {
      readonly latestRemainingCapacity: number | null;
      readonly latestStatus: BatteryStatus;
      readonly originalCapacity: number | null;
    };
    readonly strings: {
      readonly strings: ReadonlyArray<{
        readonly installDate: string | null;
        readonly type: {
          readonly manufacturer: string;
          readonly model: string;
          readonly technology: BatteryTechnologyType;
        };
      }>;
    };
  };
  readonly capabilities: ReadonlyArray<DeviceCapability>;
  readonly dualPlaneCompanion: {
    readonly device: {
      readonly name: string;
    } | null;
  } | null;
  readonly " $fragmentType": "Details_device";
};
export type Details_device$key = {
  readonly " $data"?: Details_device$data;
  readonly " $fragmentSpreads": FragmentRefs<"Details_device">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Details_device",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "capabilities",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceBattery",
      "kind": "LinkedField",
      "name": "battery",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DeviceBatteryMetrics",
          "kind": "LinkedField",
          "name": "metrics",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "latestStatus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "originalCapacity",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "latestRemainingCapacity",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DeviceBatteryStrings",
          "kind": "LinkedField",
          "name": "strings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DeviceBatteryString",
              "kind": "LinkedField",
              "name": "strings",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "installDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BatteryType",
                  "kind": "LinkedField",
                  "name": "type",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "model",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "manufacturer",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "technology",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DualPlaneCompanion",
      "kind": "LinkedField",
      "name": "dualPlaneCompanion",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Device",
          "kind": "LinkedField",
          "name": "device",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
};

(node as any).hash = "31a8a392e818b61159d3f6b5e898e19e";

export default node;
