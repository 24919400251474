import React from 'react';

import { createColumnHelper } from '@tanstack/react-table';
import { SiteTableColumn } from 'filters/site';
import { batteryStatusToString } from 'lib/conversion/battery-status';
import { formatMinutesAsHoursAndMinutes } from 'lib/dateFormatter';
import { numberToLocaleString } from 'lib/numberFormatters';
import { renderDeviceHealthCell, renderUserConfigCell } from 'lib/table-columns';
import { Duration } from 'luxon';
import { DeviceHealth } from 'views/sites/__generated__/SiteListTableQuery.graphql';

import { TableDeviceHeader } from './TableDeviceHeader';
import { SiteHealth, SiteRow, SiteRowDevice } from './type';

const columnHelper = createColumnHelper<SiteRow | SiteRowDevice>();
export const NameColumn = columnHelper.accessor('name', {
    id: SiteTableColumn.Name,
    header: 'SITE',
    cell: ({ cell, row }) => {
        if (row.depth === 0) {
            return cell.getValue();
        }

        return <TableDeviceHeader name={cell.getValue()!} />;
    },
    meta: {
        filterable: true,
        sortable: true,
        maxWidth: '30rem',
    },
});

export const StateColumn = columnHelper.accessor('address.state', {
    id: SiteTableColumn.State,
    header: 'STATE',
    meta: {
        filterable: true,
        sortable: true,
    },
});

export const TypeColumn = columnHelper.accessor('type', {
    id: SiteTableColumn.Type,
    header: 'TYPE',
    meta: {
        filterable: true,
    },
});

export const DeviceCountColumn = columnHelper.accessor('devices.total', {
    id: SiteTableColumn.DeviceCount,
    header: 'DEVICE COUNT',
    meta: {
        filterable: true,
    },
});

export const PostcodeColumn = columnHelper.accessor('address.postcode', {
    id: SiteTableColumn.Postcode,
    header: 'POSTCODE',
    cell: ({ cell }) => renderUserConfigCell(() => cell.getValue()),
    meta: {
        filterable: true,
    },
});

export const BatteryStatusColumn = columnHelper.display({
    id: SiteTableColumn.BatteryStatus,
    header: 'BATTERY STATUS',
    cell: ({ row }) => {
        if (row.original.__typename === 'Site') {
            const site = row.original;
            if (site.devices?.data) {
                const noBatteriesOnSite = site.devices.data.every(device => device?.battery?.installed === false);

                if (noBatteriesOnSite) {
                    return <div>No batteries configured</div>;
                }
            }

            if (site.batteryMetrics?.multipleStatuses) {
                return 'Various';
            }

            return site.batteryMetrics?.commonStatus
                ? batteryStatusToString(site.batteryMetrics?.commonStatus)
                : 'Unknown';
        } else {
            if (row.original.battery?.installed) {
                return batteryStatusToString(row.original.battery.metrics?.latestStatus ?? 'Unknown');
            } else {
                return <div>No batteries configured</div>;
            }
        }
    },
    meta: {
        filterable: true,
        sortable: true,
    },
});

export const BatteryCapacityColumn = columnHelper.accessor('batteryMetrics.totalCapacity', {
    id: SiteTableColumn.BatteryCapacity,
    header: 'CAPACITY',
    cell: ({ cell, row }) => {
        if (row.depth !== 0) {
            return '';
        }

        const value = cell.getValue();

        if (value == null) {
            return '';
        }

        return numberToLocaleString(value) + ' Ah';
    },
    meta: {
        filterable: true,
        sortable: true,
    },
});

export const BatteryTotalTimeRemainingColumn = columnHelper.accessor('batteryMetrics.totalTimeRemaining', {
    id: SiteTableColumn.BatteryTotalTimeRemaining,
    header: 'RUN TIME',
    cell: ({ cell, row }) => {
        if (row.depth !== 0) {
            return '';
        }

        return cell.getValue()
            ? formatMinutesAsHoursAndMinutes(Duration.fromObject({ hours: cell.getValue()! }).as('minutes'))
            : 'Unknown';
    },
    meta: {
        filterable: true,
        sortable: true,
    },
});

function renderStatusCell(value: DeviceHealth | 'Various', colorOverride?: string) {
    return renderDeviceHealthCell(value, colorOverride);
}

export const DeviceStatusColumn = columnHelper.accessor('health', {
    id: SiteTableColumn.DeviceStatus,
    header: 'STATUS',
    cell: ({ cell }) => {
        if (!cell.getValue()) {
            return renderStatusCell('Unknown');
        }

        if (cell.row.depth > 0) {
            return renderStatusCell(cell.getValue() as DeviceHealth);
        }

        const status = cell.getValue() as SiteHealth;

        if (status.multipleStatuses) {
            return renderStatusCell(
                'Various',
                status.status === 'Offline' || status.status === 'Critical' ? 'text-customCoral' : undefined
            );
        } else if (status.commonStatus) {
            return renderStatusCell(status.commonStatus);
        } else if (status.status) {
            return renderStatusCell(status.status);
        }

        return renderStatusCell('Unknown');
    },
    meta: {
        filterable: true,
        sortable: false,
    },
});
