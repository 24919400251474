/**
 * @generated SignedSource<<aa99193abadb880cc158780c0209307c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SiteNameSearchQuery$variables = {
  page?: number | null;
  pageSize: number;
  search: string;
};
export type SiteNameSearchQuery$data = {
  readonly sites: {
    readonly data: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
    readonly pageInfo: {
      readonly hasNext: boolean | null;
      readonly hasPrevious: boolean | null;
      readonly page: number;
      readonly size: number;
      readonly total: number;
    };
  };
};
export type SiteNameSearchQuery = {
  response: SiteNameSearchQuery$data;
  variables: SiteNameSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": 1,
  "kind": "LocalArgument",
  "name": "page"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageSize"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "onlyProvisioningStatuses",
        "value": "Active"
      },
      {
        "kind": "Literal",
        "name": "orderBy",
        "value": {
          "dir": "Asc",
          "field": "Name"
        }
      },
      {
        "kind": "Variable",
        "name": "page",
        "variableName": "page"
      },
      {
        "kind": "Variable",
        "name": "pageSize",
        "variableName": "pageSize"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      }
    ],
    "concreteType": "PaginatedSites",
    "kind": "LinkedField",
    "name": "sites",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "page",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "size",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "total",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasNext",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasPrevious",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteNameSearchQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SiteNameSearchQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "d42a60812c67f2c046382308b8953ced",
    "id": null,
    "metadata": {},
    "name": "SiteNameSearchQuery",
    "operationKind": "query",
    "text": "query SiteNameSearchQuery(\n  $search: String!\n  $pageSize: Int!\n  $page: Int = 1\n) {\n  sites(search: $search, pageSize: $pageSize, page: $page, orderBy: {field: Name, dir: Asc}, onlyProvisioningStatuses: Active) {\n    pageInfo {\n      page\n      size\n      total\n      hasNext\n      hasPrevious\n    }\n    data {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dbf586ed1c77cafaa9d6467f4c1fd367";

export default node;
