import React, { FC, Suspense } from 'react';
import { PreloadedQuery } from 'react-relay';
import { useLoaderData, useParams } from 'react-router-dom';

import { DefaultTimeRange } from '../../settings';
import { ACPowerContent } from './ACPowerContent';
import { ACPowerSkeleton } from './ACPowerSkeleton';
import { ACPowerContentQuery } from './__generated__/ACPowerContentQuery.graphql';

export const ACPower: FC = () => {
    const queryRef = useLoaderData() as PreloadedQuery<ACPowerContentQuery>;
    const { siteId, deviceId } = useParams() as { siteId: string; deviceId: string };

    return (
        <>
            {!queryRef && <ACPowerSkeleton />}
            {queryRef && (
                <Suspense fallback={<ACPowerSkeleton />}>
                    <ACPowerContent key={siteId} queryRef={queryRef} timeRange={DefaultTimeRange} deviceId={deviceId} />
                </Suspense>
            )}
        </>
    );
};
