import { Dispatch } from 'react';

import { DateRangeFilter } from 'components/FilterDateSelect/common';
import { BatteryTypeManModelFilter } from 'filters/battery-type/types';

import { FilterAction, FilterState, useFilterReducer } from '../common';
import { DefaultExtraFilters, DefaultValues, StaticTaskFilterDefinitions, TaskColumnFilterMap } from './settings';
import { NotInProgTestsTableColumn, TaskExtraFilters } from './types';

export type TaskFilterState = FilterState<NotInProgTestsTableColumn, TaskColumnFilterMap>;
export type TaskFilterAction = FilterAction<TaskColumnFilterMap, NotInProgTestsTableColumn>;

export function useTaskFilter(): [TaskFilterState, Dispatch<TaskFilterAction>] {
    const [state, updateState] = useFilterReducer<
        NotInProgTestsTableColumn,
        TaskColumnFilterMap,
        TaskFilterState,
        TaskFilterAction
    >('task-filter', DefaultValues, StaticTaskFilterDefinitions, DefaultExtraFilters);

    return [state, updateState];
}

export function taskToFilterObject(filters: TaskFilterState): Record<string, unknown> {
    const output: Record<string, unknown> = {};
    const {
        Name: name,
        Type: type,
        DeviceCount: deviceCount,
        Status: state,
        Date: date,
        ScheduleRepeat: scheduleRepeat,
        UsingSmartStart: usingSmartStart,
        RunTime: runTime,
    } = filters.columnValues;

    if (name.length > 0) {
        output.name = name.map(filter => ({ value: filter.name }));
    }

    if (type.length > 0) {
        output.type = type.map(filter => ({ value: filter.id }));
    }

    if (deviceCount) {
        output.deviceCount = deviceCount;
    }

    if (state.length > 0) {
        output.state = state.map(filter => ({ value: filter.id }));
    }

    if (date) {
        const dateFilter = date as DateRangeFilter;
        output.date = {
            min: dateFilter.range[0].toISOString(),
            max: dateFilter.range[1].toISOString(),
        };
    }

    if (scheduleRepeat.length > 0) {
        output.scheduleRepeat = scheduleRepeat.map(filter => ({ value: filter.id }));
    }

    if (usingSmartStart) {
        output.usingSmartStart = { value: usingSmartStart === 'Yes' ? true : false };
    }
    if (runTime) {
        output.runTime = {
            min: runTime.range[0],
            max: runTime.range[1],
        };
    }

    for (const [definitionName, values] of Object.entries(filters.extraFilters)) {
        const definition = filters.filterDefinitions.find(definition => definition.name === definitionName);
        if (!definition) {
            continue;
        }

        switch (definition.category) {
            case TaskExtraFilters.DateCreated: {
                const dateFilter = values as DateRangeFilter;
                if (dateFilter) {
                    output.createdTime = {
                        min: dateFilter.range[0].toISOString(),
                        max: dateFilter.range[1].toISOString(),
                    };
                }
                break;
            }
            case TaskExtraFilters.DateCommenced: {
                const dateFilter = values as DateRangeFilter;
                if (dateFilter) {
                    output.commencedTime = {
                        min: dateFilter.range[0].toISOString(),
                        max: dateFilter.range[1].toISOString(),
                    };
                }
                break;
            }
            case TaskExtraFilters.DateCompleted: {
                const dateFilter = values as DateRangeFilter;
                if (dateFilter) {
                    output.completedTime = {
                        min: dateFilter.range[0].toISOString(),
                        max: dateFilter.range[1].toISOString(),
                    };
                }
                break;
            }
            case TaskExtraFilters.DateCancelled: {
                const dateFilter = values as DateRangeFilter;
                if (dateFilter) {
                    output.cancelledTime = {
                        min: dateFilter.range[0].toISOString(),
                        max: dateFilter.range[1].toISOString(),
                    };
                }
                break;
            }
            case TaskExtraFilters.DateAborted: {
                const dateFilter = values as DateRangeFilter;
                if (dateFilter) {
                    output.abortedTime = {
                        min: dateFilter.range[0].toISOString(),
                        max: dateFilter.range[1].toISOString(),
                    };
                }
                break;
            }
            case TaskExtraFilters.DateScheduled: {
                const dateFilter = values as DateRangeFilter;
                if (dateFilter) {
                    output.scheduleTime = {
                        min: dateFilter.range[0].toISOString(),
                        max: dateFilter.range[1].toISOString(),
                    };
                }
                break;
            }
            case TaskExtraFilters.BatteryStringManufacturer: {
                const batteryStringManufacturer = values as BatteryTypeManModelFilter[];
                if (batteryStringManufacturer.length > 0) {
                    output.batteryStringManufacturerSnapshot = batteryStringManufacturer.map(filter => ({
                        value: filter.name,
                    }));
                }
                break;
            }
            case TaskExtraFilters.BatteryStringModel: {
                const batteryStringModel = values as BatteryTypeManModelFilter[];
                if (batteryStringModel.length > 0) {
                    output.batteryStringModelSnapshot = batteryStringModel.map(filter => ({
                        value: filter.name,
                    }));
                }
                break;
            }
        }
    }

    return output;
}
