import React, { FC, useMemo } from 'react';

import graphql from 'babel-plugin-relay/macro';
import { MultipleSelect } from 'components/MultipleSelect';
import { useQuery } from 'lib/query-helpers';

import { CommonFilterProps } from '../../common/types';
import { DeviceTypeFilter } from '../types';
import { DeviceTypeFilterUI_deviceTypeQuery } from './__generated__/DeviceTypeFilterUI_deviceTypeQuery.graphql';

export type DeviceTypeFilterUIProps = CommonFilterProps<DeviceTypeFilter[]>;

export const DeviceTypeFilterUI: FC<DeviceTypeFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    const { data: props } = useQuery<DeviceTypeFilterUI_deviceTypeQuery>(
        graphql`
            query DeviceTypeFilterUI_deviceTypeQuery {
                deviceTypes {
                    data {
                        id
                        displayName
                    }
                }
            }
        `,
        {}
    );

    const options = useMemo<DeviceTypeFilter[]>(() => {
        if (props) {
            return props.deviceTypes.data.map(deviceType => ({
                id: deviceType.id,
                name: deviceType.displayName,
            }));
        } else {
            return [];
        }
    }, [props]);

    return (
        <MultipleSelect
            options={options}
            active={current}
            title='Filter by type'
            renderItem={item => item.name}
            renderItemSimple={item => item.name}
            areItemsEqual={(itemA, itemB) => itemA.id === itemB.id}
            showBack={hasPrevious}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onClose={onClose}
        />
    );
};
