/**
 * @generated SignedSource<<279e4984612c986b37adcbe36a003cfc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SnmpAuthAlgorithm = "MD5" | "SHA1" | "%future added value";
export type SnmpPrivAlgorithm = "AES" | "DES" | "%future added value";
export type SnmpSecurityLevel = "AuthNoPriv" | "AuthPriv" | "NoAuthNoPriv" | "%future added value";
export type SnmpVersion = "V1" | "V2c" | "V3" | "%future added value";
export type ConnectionSettingsIn = {
  addresses: ReadonlyArray<string>;
  protocols: ReadonlyArray<ProtocolIn>;
};
export type ProtocolIn = {
  basic?: ProtocolBasicIn | null;
  id: string;
  snmp?: ProtocolSnmpIn | null;
};
export type ProtocolSnmpIn = {
  port?: number | null;
  settingsV1V2c?: ProtocolSnmpV1AndV2cIn | null;
  settingsV3?: ProtocolSnmpV3In | null;
  version: SnmpVersion;
};
export type ProtocolSnmpV1AndV2cIn = {
  readOnlyCommunity: string;
  readWriteCommunity: string;
};
export type ProtocolSnmpV3In = {
  authPassphrase?: string | null;
  authType: SnmpAuthAlgorithm;
  engineId?: string | null;
  privPassphrase?: string | null;
  privType: SnmpPrivAlgorithm;
  securityLevel: SnmpSecurityLevel;
  user: string;
};
export type ProtocolBasicIn = {
  password: string;
  username: string;
};
export type useConnectionTestMutation$variables = {
  deviceType: string;
  settings: ConnectionSettingsIn;
};
export type useConnectionTestMutation$data = {
  readonly checkConnectivity: {
    readonly addresses: ReadonlyArray<{
      readonly address: string;
      readonly protocols: ReadonlyArray<{
        readonly protocolId: string;
        readonly readable: boolean;
        readonly writable: boolean;
      }>;
      readonly reachable: boolean;
    }>;
  } | null;
};
export type useConnectionTestMutation = {
  response: useConnectionTestMutation$data;
  variables: useConnectionTestMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deviceType"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "settings"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "deviceType",
        "variableName": "deviceType"
      },
      {
        "kind": "Variable",
        "name": "settings",
        "variableName": "settings"
      }
    ],
    "concreteType": "ConnectivityResult",
    "kind": "LinkedField",
    "name": "checkConnectivity",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AddressConnectivityResult",
        "kind": "LinkedField",
        "name": "addresses",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "address",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "reachable",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProtocolConnectivityResult",
            "kind": "LinkedField",
            "name": "protocols",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "protocolId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "readable",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "writable",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useConnectionTestMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useConnectionTestMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a0e7ead4a9b9f4561ea7954ef92d26e4",
    "id": null,
    "metadata": {},
    "name": "useConnectionTestMutation",
    "operationKind": "mutation",
    "text": "mutation useConnectionTestMutation(\n  $deviceType: ID!\n  $settings: ConnectionSettingsIn!\n) {\n  checkConnectivity(settings: $settings, deviceType: $deviceType) {\n    addresses {\n      address\n      reachable\n      protocols {\n        protocolId\n        readable\n        writable\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c5980f0930431f474ff0114d4ba5a551";

export default node;
