/**
 * @generated SignedSource<<7d3590f22d77c29c47c61777ad765c8a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ProvisioningStatus = "Active" | "Deleted" | "%future added value";
export type ScheduleFetchDeviceInfoQuery$variables = {
  id: string;
};
export type ScheduleFetchDeviceInfoQuery$data = {
  readonly devices: {
    readonly data: ReadonlyArray<{
      readonly dualPlaneCompanion: {
        readonly device: {
          readonly id: string;
          readonly monitorOnly: boolean;
          readonly provisioningStatus: ProvisioningStatus;
        } | null;
      } | null;
      readonly id: string;
      readonly name: string;
    }>;
  };
};
export type ScheduleFetchDeviceInfoQuery = {
  response: ScheduleFetchDeviceInfoQuery$data;
  variables: ScheduleFetchDeviceInfoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "items": [
              {
                "kind": "Variable",
                "name": "ids.0",
                "variableName": "id"
              }
            ],
            "kind": "ListValue",
            "name": "ids"
          },
          {
            "kind": "Literal",
            "name": "monitorOnly",
            "value": {
              "value": false
            }
          },
          {
            "kind": "Literal",
            "name": "supportsBatteryTesting",
            "value": {
              "value": true
            }
          }
        ],
        "kind": "ObjectValue",
        "name": "filters"
      },
      {
        "kind": "Literal",
        "name": "onlyProvisioningStatuses",
        "value": [
          "Active"
        ]
      },
      {
        "kind": "Literal",
        "name": "onlyWithValidBattery",
        "value": true
      }
    ],
    "concreteType": "PaginatedDevices",
    "kind": "LinkedField",
    "name": "devices",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DualPlaneCompanion",
            "kind": "LinkedField",
            "name": "dualPlaneCompanion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "device",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "provisioningStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "monitorOnly",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ScheduleFetchDeviceInfoQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ScheduleFetchDeviceInfoQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "5e75729913f024d6764d00c22bff0466",
    "id": null,
    "metadata": {},
    "name": "ScheduleFetchDeviceInfoQuery",
    "operationKind": "query",
    "text": "query ScheduleFetchDeviceInfoQuery(\n  $id: ID!\n) {\n  devices(onlyWithValidBattery: true, onlyProvisioningStatuses: [Active], filters: {ids: [$id], monitorOnly: {value: false}, supportsBatteryTesting: {value: true}}) {\n    data {\n      id\n      name\n      dualPlaneCompanion {\n        device {\n          id\n          provisioningStatus\n          monitorOnly\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "234ad6a4bc28afcb1960b93d7449a29d";

export default node;
