import React, { FC, useMemo, useState } from 'react';

import { CustomAttributes } from '@accesstel/pcm-ui';

import { FieldArray, useField, useFormikContext } from 'formik';
import { getQualifiedName } from 'lib/namespaces';
import { useSuggestAttributeName } from 'lib/suggestors';
import { AttributeLocation } from 'lib/suggestors/__generated__/attributeNameSuggestQuery.graphql';

import { AttributeFormValues } from '../schema';

export interface AttributesProps {
    namespace?: string;
    type: AttributeLocation;
}

export const Attributes: FC<AttributesProps> = ({ namespace, type }) => {
    const [currentAttributeName, setCurrentAttributeName] = useState<string>('');

    const [, meta] = useField<AttributeFormValues[]>(getQualifiedName('attributes', namespace));
    const { setFieldValue } = useFormikContext();
    const [customAttributeSuggestions] = useSuggestAttributeName(currentAttributeName, { only: [type] });

    const targetValues = useMemo(() => {
        return meta.value.map((value, index) => {
            let error: string | undefined;
            if (meta.error) {
                if (Array.isArray(meta.error)) {
                    const errorObject = ((meta.error as unknown) as Record<string, string>[])[index];
                    if (errorObject?.name) {
                        error = errorObject.name;
                    }
                } else if (typeof meta.error === 'string') {
                    error = meta.error;
                }
            }

            return {
                ...value,
                error,
            };
        });
    }, [meta.error, meta.value]);

    return (
        <FieldArray name={getQualifiedName('attributes', namespace)}>
            {({ push, remove }) => (
                <CustomAttributes
                    values={targetValues}
                    suggestions={customAttributeSuggestions}
                    title='CUSTOM ATTRIBUTES'
                    titleStyle={{ fontSize: '0.625rem' }}
                    onAdd={(name, value) => {
                        push({ name: name ?? '', value: value ?? '' });
                        setCurrentAttributeName('');
                    }}
                    onRemove={index => {
                        remove(index);
                        setCurrentAttributeName('');
                    }}
                    onUpdateName={(index, name) => {
                        setFieldValue(`${getQualifiedName('attributes', namespace)}.${index}.name`, name);
                        setCurrentAttributeName(name);
                    }}
                    onUpdateValue={(index, value) =>
                        setFieldValue(`${getQualifiedName('attributes', namespace)}.${index}.value`, value)
                    }
                    light
                    noSuggestionsText={
                        <div>
                            {currentAttributeName}{' '}
                            <span className='font-CynthoNext-Light text-sm'>(New Attribute)</span>
                        </div>
                    }
                    variant='outlined'
                />
            )}
        </FieldArray>
    );
};
