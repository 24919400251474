/**
 * @generated SignedSource<<0bf3e95d2bed9f533a2c8af5b7f816ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ViewBatteryTestResultCardAbortTestsMutation$variables = {
  testIDs: ReadonlyArray<string>;
};
export type ViewBatteryTestResultCardAbortTestsMutation$data = {
  readonly abortBatteryTestTests: boolean | null;
};
export type ViewBatteryTestResultCardAbortTestsMutation = {
  response: ViewBatteryTestResultCardAbortTestsMutation$data;
  variables: ViewBatteryTestResultCardAbortTestsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "testIDs"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ids",
        "variableName": "testIDs"
      }
    ],
    "kind": "ScalarField",
    "name": "abortBatteryTestTests",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ViewBatteryTestResultCardAbortTestsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ViewBatteryTestResultCardAbortTestsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "22fd23122c912c9f41e06683e5860d73",
    "id": null,
    "metadata": {},
    "name": "ViewBatteryTestResultCardAbortTestsMutation",
    "operationKind": "mutation",
    "text": "mutation ViewBatteryTestResultCardAbortTestsMutation(\n  $testIDs: [ID!]!\n) {\n  abortBatteryTestTests(ids: $testIDs)\n}\n"
  }
};
})();

(node as any).hash = "f456c764773bef104c02fdff0ae65fbe";

export default node;
