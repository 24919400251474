import React, { ComponentType, Dispatch, ReactNode } from 'react';

import { getFilters } from './helpers';
import { FilterAction, FilterActionType } from './reducer';
import { CommonFilterProps, FilterDefinition, FilterState } from './types';

export function renderColumnFilterComponent<
    ColumnType extends string,
    TypeMap extends Record<ColumnType, unknown>,
    Component extends ComponentType<CommonFilterProps<TypeMap[ColumnType]>>
>(
    column: ColumnType,
    state: FilterState<ColumnType, TypeMap>,
    dispatch: Dispatch<FilterAction<TypeMap, ColumnType>>,
    type: Component
): ReactNode {
    return React.createElement(type, {
        current: state.columnValues[column],
        hasPrevious: false,
        onClearOrBack: () => {
            dispatch({ type: FilterActionType.Clear, column });
            dispatch({ type: FilterActionType.HideAll });
        },
        onSubmit: value => {
            dispatch({ type: FilterActionType.Apply, column, value });
            dispatch({ type: FilterActionType.HideAll });
        },
        onClose: () => {
            dispatch({ type: FilterActionType.HideAll });
        },
    });
}

export function renderFilterComponent<ColumnType extends string, TypeMap extends Record<ColumnType, unknown>, T>(
    definition: FilterDefinition<ColumnType, T>,
    index: number,
    state: FilterState<ColumnType, TypeMap>,
    dispatch: Dispatch<FilterAction<TypeMap, ColumnType, T>>
): ReactNode {
    if (definition.column) {
        return renderColumnFilterComponent(
            definition.column as ColumnType,
            state,
            dispatch,
            (definition.component(definition.props) as unknown) as ComponentType<CommonFilterProps<TypeMap[ColumnType]>>
        );
    }

    if (definition.type === 'multi') {
        return React.createElement(definition.component(), {
            current: state.extraFilters[definition.name] as T[],
            hasPrevious: false,
            onClearOrBack: () => {
                dispatch({ type: FilterActionType.Remove, definition: definition.name, index });
                dispatch({ type: FilterActionType.HideAll });
            },
            onSubmit: value => {
                dispatch({ type: FilterActionType.ApplyIndex, definition, index, value });
                dispatch({ type: FilterActionType.HideAll });
            },
            onClose: () => {
                dispatch({ type: FilterActionType.HideAll });
            },
        });
    } else {
        return React.createElement(definition.component(), {
            current: state.extraFilters[definition.name] as T,
            hasPrevious: false,
            onClearOrBack: () => {
                dispatch({ type: FilterActionType.Remove, definition: definition.name, index });
                dispatch({ type: FilterActionType.HideAll });
            },
            onSubmit: value => {
                dispatch({ type: FilterActionType.ApplyIndex, definition, index, value });
                dispatch({ type: FilterActionType.HideAll });
            },
            onClose: () => {
                dispatch({ type: FilterActionType.HideAll });
            },
        });
    }
}

export function renderEmbeddedColumnFilter<ColumnType extends string, TypeMap extends Record<ColumnType, unknown>>(
    state: FilterState<ColumnType, TypeMap>,
    dispatch: Dispatch<FilterAction<TypeMap, ColumnType>>
): [ColumnType, ReactNode] | undefined {
    if (!state.activeColumn) {
        return;
    }

    const definition = state.filterDefinitions.find(definition => definition.column === state.activeColumn);

    const component = definition?.component;
    if (!component) {
        return;
    }
    const node = renderColumnFilterComponent(
        state.activeColumn,
        state,
        dispatch,
        component() as ComponentType<CommonFilterProps<TypeMap[ColumnType]>>
    );

    if (node) {
        return [state.activeColumn, node];
    }
}

export function renderEmbeddedTopLevelFilter<ColumnType extends string, TypeMap extends Record<ColumnType, unknown>>(
    state: FilterState<ColumnType, TypeMap>,
    dispatch: Dispatch<FilterAction<TypeMap, ColumnType>>
): [number, ReactNode] | undefined {
    if (!state.activeExtra) {
        return;
    }

    const filters = getFilters(state);
    const [definitionName, index] = state.activeExtra;

    const overallIndex = filters.findIndex(filter => filter.definition === definitionName && filter.index === index);

    const definition = state.filterDefinitions.find(definition => definition.name === definitionName);
    if (!definition) {
        return;
    }
    const node = renderFilterComponent(definition, index, state, dispatch);

    if (node) {
        return [overallIndex, node];
    }
}
