import React, { FC } from 'react';

import { CommonFilterProps } from 'filters/common';

import { BatteryTypePeukertsConstantFilter } from '../types';
import { BatteryTypeFilterRange } from './BatteryTypeFilterRange';

export type BatteryTypePeukertsConstantFilterUIProps = CommonFilterProps<BatteryTypePeukertsConstantFilter | null>;

export const BatteryTypePeukertsConstantFilterUI: FC<BatteryTypePeukertsConstantFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => (
    <BatteryTypeFilterRange
        current={current}
        onClearOrBack={onClearOrBack}
        onClose={onClose}
        onSubmit={onSubmit}
        title='Filter by Peukerts Constant'
        distributionType='PeukertsConstant'
        showBack={hasPrevious}
    />
);
