/**
 * @generated SignedSource<<1cfcee39bbdd06ffa06fa965ba94f3c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BatteryStatus = "BoostCharging" | "Charging" | "Discharging" | "Disconnected" | "FloatCharging" | "Idle" | "Missing" | "Unknown" | "%future added value";
export type DeviceHealth = "Critical" | "Degraded" | "Healthy" | "Offline" | "Unknown" | "%future added value";
export type DeviceSortField = "LastTested" | "Name" | "Site" | "State" | "Type" | "%future added value";
export type SnmpVersion = "V1" | "V2c" | "V3" | "%future added value";
export type SortDirection = "Asc" | "Desc" | "%future added value";
export type DeviceOrdering = {
  dir?: SortDirection | null;
  field: DeviceSortField;
};
export type DeviceFilter = {
  attributes?: ReadonlyArray<AttributeFilters> | null;
  batteryCapacityRemaining?: FloatRangeFilter | null;
  batteryCapacityTotal?: FloatRangeFilter | null;
  batteryEnergyTotal?: FloatRangeFilter | null;
  batteryInstallDate?: DateRangeFilter | null;
  batteryManufactureDate?: DateRangeFilter | null;
  batteryReserveTime?: FloatRangeFilter | null;
  batteryState?: ReadonlyArray<BatteryStatusFilter> | null;
  batteryStateOfHealth?: FloatRangeFilter | null;
  batteryStringCount?: IntRangeFilter | null;
  batteryTemperature?: FloatRangeFilter | null;
  health?: ReadonlyArray<StringFilter> | null;
  ids?: ReadonlyArray<string> | null;
  lastTestedDate?: DateRangeFilter | null;
  monitorOnly?: BooleanFilter | null;
  name?: ReadonlyArray<StringFilter> | null;
  siteId?: ReadonlyArray<string> | null;
  siteName?: ReadonlyArray<StringFilter> | null;
  siteState?: ReadonlyArray<StringFilter> | null;
  snmpVersion?: ReadonlyArray<StringFilter> | null;
  supportsBatteryTesting?: BooleanFilter | null;
  type?: ReadonlyArray<StringFilter> | null;
};
export type StringFilter = {
  value: string;
};
export type AttributeFilters = {
  filters: ReadonlyArray<StringFilter>;
  name: string;
};
export type IntRangeFilter = {
  max: number;
  min: number;
};
export type FloatRangeFilter = {
  max: number;
  min: number;
};
export type BatteryStatusFilter = {
  value: BatteryStatus;
};
export type DateRangeFilter = {
  max: string;
  min: string;
};
export type BooleanFilter = {
  value: boolean;
};
export type DevicesTableQuery$variables = {
  filter?: DeviceFilter | null;
  name?: string | null;
  orderBy?: DeviceOrdering | null;
  page?: number | null;
  withBatteryCapacityRemaining?: boolean | null;
  withBatteryEnergyTotal?: boolean | null;
  withBatteryReserveTime?: boolean | null;
  withBatteryStateOfHealth?: boolean | null;
  withBatteryStatus?: boolean | null;
  withBatteryStringCount?: boolean | null;
  withBatteryTemperature?: boolean | null;
  withDeviceHealth?: boolean | null;
  withMonitorOnly?: boolean | null;
  withSnmpVersion?: boolean | null;
};
export type DevicesTableQuery$data = {
  readonly devices: {
    readonly data: ReadonlyArray<{
      readonly battery: {
        readonly metrics: {
          readonly latestRemainingCapacity?: number | null;
          readonly latestStateOfHealth?: number | null;
          readonly latestStatus?: BatteryStatus;
          readonly latestTemperature?: number | null;
          readonly latestTotalEnergy?: number | null;
        };
        readonly reserveTime?: number | null;
        readonly strings: {
          readonly count?: number;
        };
      };
      readonly connectionSettings?: {
        readonly protocols: ReadonlyArray<{
          readonly version?: SnmpVersion;
        }>;
      };
      readonly health?: DeviceHealth | null;
      readonly id: string;
      readonly monitorOnly?: boolean;
      readonly name: string;
      readonly site: {
        readonly address: {
          readonly state: string;
        };
        readonly name: string;
      };
      readonly type: {
        readonly displayName: string;
      };
    }>;
    readonly pageInfo: {
      readonly hasNext: boolean | null;
      readonly hasPrevious: boolean | null;
      readonly page: number;
      readonly size: number;
      readonly total: number;
    };
    readonly total: number;
  };
  readonly overallDevices: {
    readonly total: number;
  };
};
export type DevicesTableQuery = {
  response: DevicesTableQuery$data;
  variables: DevicesTableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "name"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v3 = {
  "defaultValue": 1,
  "kind": "LocalArgument",
  "name": "page"
},
v4 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "withBatteryCapacityRemaining"
},
v5 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "withBatteryEnergyTotal"
},
v6 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "withBatteryReserveTime"
},
v7 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "withBatteryStateOfHealth"
},
v8 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "withBatteryStatus"
},
v9 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "withBatteryStringCount"
},
v10 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "withBatteryTemperature"
},
v11 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "withDeviceHealth"
},
v12 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "withMonitorOnly"
},
v13 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "withSnmpVersion"
},
v14 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "name"
  }
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "Address",
  "kind": "LinkedField",
  "name": "address",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v20 = {
  "condition": "withMonitorOnly",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "monitorOnly",
      "storageKey": null
    }
  ]
},
v21 = {
  "condition": "withDeviceHealth",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "health",
      "storageKey": null
    }
  ]
},
v22 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "version",
      "storageKey": null
    }
  ],
  "type": "ProtocolSnmp",
  "abstractKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "DeviceBattery",
  "kind": "LinkedField",
  "name": "battery",
  "plural": false,
  "selections": [
    {
      "condition": "withBatteryReserveTime",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reserveTime",
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceBatteryMetrics",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": false,
      "selections": [
        {
          "condition": "withBatteryStatus",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "latestStatus",
              "storageKey": null
            }
          ]
        },
        {
          "condition": "withBatteryTemperature",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "latestTemperature",
              "storageKey": null
            }
          ]
        },
        {
          "condition": "withBatteryStateOfHealth",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "latestStateOfHealth",
              "storageKey": null
            }
          ]
        },
        {
          "condition": "withBatteryCapacityRemaining",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "latestRemainingCapacity",
              "storageKey": null
            }
          ]
        },
        {
          "condition": "withBatteryEnergyTotal",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "latestTotalEnergy",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceBatteryStrings",
      "kind": "LinkedField",
      "name": "strings",
      "plural": false,
      "selections": [
        {
          "condition": "withBatteryStringCount",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "count",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "page",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "size",
      "storageKey": null
    },
    (v15/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNext",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPrevious",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v25 = {
  "alias": "overallDevices",
  "args": null,
  "concreteType": "PaginatedDevices",
  "kind": "LinkedField",
  "name": "devices",
  "plural": false,
  "selections": [
    (v15/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DevicesTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v14/*: any*/),
        "concreteType": "PaginatedDevices",
        "kind": "LinkedField",
        "name": "devices",
        "plural": false,
        "selections": [
          (v15/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Device",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v16/*: any*/),
              (v17/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "DeviceType",
                "kind": "LinkedField",
                "name": "type",
                "plural": false,
                "selections": [
                  (v18/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Site",
                "kind": "LinkedField",
                "name": "site",
                "plural": false,
                "selections": [
                  (v17/*: any*/),
                  (v19/*: any*/)
                ],
                "storageKey": null
              },
              (v20/*: any*/),
              (v21/*: any*/),
              {
                "condition": "withSnmpVersion",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ConnectionSettings",
                    "kind": "LinkedField",
                    "name": "connectionSettings",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "protocols",
                        "plural": true,
                        "selections": [
                          (v22/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ]
              },
              (v23/*: any*/)
            ],
            "storageKey": null
          },
          (v24/*: any*/)
        ],
        "storageKey": null
      },
      (v25/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v8/*: any*/),
      (v11/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "DevicesTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v14/*: any*/),
        "concreteType": "PaginatedDevices",
        "kind": "LinkedField",
        "name": "devices",
        "plural": false,
        "selections": [
          (v15/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Device",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v16/*: any*/),
              (v17/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "DeviceType",
                "kind": "LinkedField",
                "name": "type",
                "plural": false,
                "selections": [
                  (v18/*: any*/),
                  (v16/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Site",
                "kind": "LinkedField",
                "name": "site",
                "plural": false,
                "selections": [
                  (v17/*: any*/),
                  (v19/*: any*/),
                  (v16/*: any*/)
                ],
                "storageKey": null
              },
              (v20/*: any*/),
              (v21/*: any*/),
              {
                "condition": "withSnmpVersion",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ConnectionSettings",
                    "kind": "LinkedField",
                    "name": "connectionSettings",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "protocols",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          (v22/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ]
              },
              (v23/*: any*/)
            ],
            "storageKey": null
          },
          (v24/*: any*/)
        ],
        "storageKey": null
      },
      (v25/*: any*/)
    ]
  },
  "params": {
    "cacheID": "4962104f487a7d70505b9730b093a2af",
    "id": null,
    "metadata": {},
    "name": "DevicesTableQuery",
    "operationKind": "query",
    "text": "query DevicesTableQuery(\n  $page: Int = 1\n  $name: String = \"\"\n  $orderBy: DeviceOrdering\n  $filter: DeviceFilter\n  $withMonitorOnly: Boolean = false\n  $withSnmpVersion: Boolean = false\n  $withBatteryStatus: Boolean = false\n  $withDeviceHealth: Boolean = false\n  $withBatteryStringCount: Boolean = false\n  $withBatteryTemperature: Boolean = false\n  $withBatteryReserveTime: Boolean = false\n  $withBatteryStateOfHealth: Boolean = false\n  $withBatteryCapacityRemaining: Boolean = false\n  $withBatteryEnergyTotal: Boolean = false\n) {\n  devices(page: $page, search: $name, orderBy: $orderBy, filters: $filter) {\n    total\n    data {\n      id\n      name\n      type {\n        displayName\n        id\n      }\n      site {\n        name\n        address {\n          state\n        }\n        id\n      }\n      monitorOnly @include(if: $withMonitorOnly)\n      health @include(if: $withDeviceHealth)\n      connectionSettings @include(if: $withSnmpVersion) {\n        protocols {\n          __typename\n          ... on ProtocolSnmp {\n            version\n          }\n        }\n      }\n      battery {\n        reserveTime @include(if: $withBatteryReserveTime)\n        metrics {\n          latestStatus @include(if: $withBatteryStatus)\n          latestTemperature @include(if: $withBatteryTemperature)\n          latestStateOfHealth @include(if: $withBatteryStateOfHealth)\n          latestRemainingCapacity @include(if: $withBatteryCapacityRemaining)\n          latestTotalEnergy @include(if: $withBatteryEnergyTotal)\n        }\n        strings {\n          count @include(if: $withBatteryStringCount)\n        }\n      }\n    }\n    pageInfo {\n      page\n      size\n      total\n      hasNext\n      hasPrevious\n    }\n  }\n  overallDevices: devices {\n    total\n  }\n}\n"
  }
};
})();

(node as any).hash = "d6e44c36b9c35ff86e9904adc94c70d6";

export default node;
