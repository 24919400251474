import React, { FC } from 'react';

import { CommonFilterProps } from 'filters/common';

import { BatteryTypeCellCapacityRatingFilter } from '../types';
import { BatteryTypeFilterRange } from './BatteryTypeFilterRange';

export type BatteryTypeCellCapacityRatingFilterUIProps = CommonFilterProps<BatteryTypeCellCapacityRatingFilter | null>;

export const BatteryTypeCellCapacityRatingFilterUI: FC<BatteryTypeCellCapacityRatingFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => (
    <BatteryTypeFilterRange
        current={current}
        onClearOrBack={onClearOrBack}
        onClose={onClose}
        onSubmit={onSubmit}
        title='Filter by Cell Capacity Rating'
        distributionType='CellCapacityRating'
        showBack={hasPrevious}
    />
);
