/**
 * @generated SignedSource<<3e4c1f9e7ced951cc89a4be8ec4a1f08>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ViewBatteryTestResultCardIgnoreMutation$variables = {
  deviceID: string;
};
export type ViewBatteryTestResultCardIgnoreMutation$data = {
  readonly ignoreUnplannedBatteryTest: boolean | null;
};
export type ViewBatteryTestResultCardIgnoreMutation = {
  response: ViewBatteryTestResultCardIgnoreMutation$data;
  variables: ViewBatteryTestResultCardIgnoreMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deviceID"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "device",
        "variableName": "deviceID"
      }
    ],
    "kind": "ScalarField",
    "name": "ignoreUnplannedBatteryTest",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ViewBatteryTestResultCardIgnoreMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ViewBatteryTestResultCardIgnoreMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "edc650e870bf0022372b054e0a4fe0a5",
    "id": null,
    "metadata": {},
    "name": "ViewBatteryTestResultCardIgnoreMutation",
    "operationKind": "mutation",
    "text": "mutation ViewBatteryTestResultCardIgnoreMutation(\n  $deviceID: ID!\n) {\n  ignoreUnplannedBatteryTest(device: $deviceID)\n}\n"
  }
};
})();

(node as any).hash = "062b61052cf6cec43346fab877c78015";

export default node;
