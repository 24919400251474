/**
 * @generated SignedSource<<f28a063cd369a0579fbe2ea6ddcf0844>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompletedTests_tasks$data = {
  readonly data: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"BatteryTestCard_data">;
  }>;
  readonly total: number;
  readonly " $fragmentType": "CompletedTests_tasks";
};
export type CompletedTests_tasks$key = {
  readonly " $data"?: CompletedTests_tasks$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompletedTests_tasks">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CompletedTests_tasks",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "total",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "data",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BatteryTestCard_data"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PaginatedTasks",
  "abstractKey": null
};

(node as any).hash = "41843490daba42ca5e7e1a19218b5d04";

export default node;
