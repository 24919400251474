import React, { FC } from 'react';

import { Button, Dropdown, ThemedPageHeading } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { useQuery } from 'lib/query-helpers';

import { PageHeader, useDocumentTitle } from '../../../../components';
import { DefaultTimeRanges, useTimeRange } from '../../common';
import style from './Overview.module.css';
import { BatteryOverviewQuery } from './__generated__/BatteryOverviewQuery.graphql';
import { AverageBatteryCurrent } from './components/AverageBatteryCurrent';
import { AverageEnergyColumn } from './components/AverageEnergyColumn';
import { AverageEnergySparkline } from './components/AverageEnergySparkline';
import { AverageTemperatureSparkline } from './components/AverageTemperatureSparkline';
import { BatteryCapacityHisto } from './components/BatteryCapacityHisto';
import { BatteryTimeRemainingHisto } from './components/BatteryTimeRemainingHisto';
import { CurrentCapacityDoughnut } from './components/CurrentCapacityDoughnut';
import { CurrentStatusDoughnut } from './components/CurrentStatusDoughnut';
import { ReportSearch } from './components/ReportSearch';

export const BatteryOverview: FC = () => {
    const [timeRange, timeRangeMenuItems] = useTimeRange(DefaultTimeRanges[0]);
    useDocumentTitle('Reports - Batteries');

    const { data: props, error, retry } = useQuery<BatteryOverviewQuery>(
        graphql`
            query BatteryOverviewQuery {
                ...CurrentStatusDoughnut
                ...CurrentCapacityDoughnut
            }
        `,
        {}
    );

    return (
        <div className='space-y-6'>
            <PageHeader />
            <div className='flex justify-between items-center'>
                <ThemedPageHeading value='Batteries' />
                <div className='w-64'>
                    <ReportSearch />
                </div>
            </div>
            <div className={style.toolbar}>
                <Dropdown rightAlign title={timeRange.displayName} menuItems={timeRangeMenuItems} />
            </div>
            <div className={style.chart_layout}>
                <CurrentStatusDoughnut data={props ?? null} error={!!error} retry={retry} />
                <CurrentCapacityDoughnut data={props ?? null} error={!!error} retry={retry} />
                <AverageTemperatureSparkline timeRange={timeRange} />
                <AverageEnergySparkline timeRange={timeRange} />
                <BatteryTimeRemainingHisto />
                <AverageBatteryCurrent timeRange={timeRange} />
                <BatteryCapacityHisto />
                <AverageEnergyColumn timeRange={timeRange} />
            </div>
            <div className={style.footer}>
                <Button buttonText='View all Sites' variant='primary' size='medium' to='/reports/batteries/sites' />
            </div>
        </div>
    );
};
