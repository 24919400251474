import React, { FC, useCallback, useMemo } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';
import { useParams } from 'react-router-dom';

import { useDocumentTitle } from 'components';
import {
    DeviceTestResultTableColumn,
    deviceTestResultToFilterObject,
    useDeviceTestResultFilter,
} from 'filters/device-test-result';
import { SortDirection, TableLayout, useTableReducer } from 'layouts';
import { useQuery } from 'lib/query-helpers';
import { Paths } from 'lib/routes';

import {
    DeviceTestOrdering,
    queries_batteryTestCardsQuery,
    queries_batteryTestCardsQuery$variables,
} from '../../__generated__/queries_batteryTestCardsQuery.graphql';
import { queries_deviceTestSearchQuery } from '../../__generated__/queries_deviceTestSearchQuery.graphql';
import { batteryTestCardsQuery, deviceTestSearchQuery } from '../../queries';
import { AllTableColumns, BaseTableColumns } from './settings';

const TableStorageKeyPrefix = 'single-device-tests-table';

export const SingleDeviceTests: FC = () => {
    const pageTitle = 'Battery tests';
    // FIXME: Casting isnt the best way of handling undefined type check. Currently, there are multiple ways people are dealing with this.
    // https://github.com/remix-run/react-router/issues/8200
    const { deviceId: device } = useParams() as { deviceId: string };

    useDocumentTitle(pageTitle);

    const environment = useRelayEnvironment();
    const [tableState, dispatchTableState] = useTableReducer<DeviceTestResultTableColumn>({
        defaultSortColumn: DeviceTestResultTableColumn.StartTime,
        defaultSortDirection: SortDirection.Descending,
        allColumns: AllTableColumns.map(column => column.id),
        defaultVisibleColumns: BaseTableColumns.map(column => column.id),
        storageKeyPrefix: TableStorageKeyPrefix,
    });

    const [filters, dispatchFilters] = useDeviceTestResultFilter(device);
    const filterObject = useMemo(() => deviceTestResultToFilterObject(filters), [filters]);

    const sortObject: DeviceTestOrdering = {
        field: tableState.sortColumn,
        dir: tableState.sortDirection === SortDirection.Ascending ? 'Asc' : 'Desc',
    };

    const variables: queries_batteryTestCardsQuery$variables = {
        id: device,
        status: ['Passed', 'Failed', 'Aborted', 'InProgress', 'Waiting', 'Inconclusive', 'Finalizing', 'Analyzing'],
        pageSize: 30,
        testFilters: filterObject,
        testOrdering: sortObject,
        testName: tableState.search,
    };

    const { data, error, retry } = useQuery<queries_batteryTestCardsQuery>(batteryTestCardsQuery, variables);

    const tests = data?.device?.tests;

    const handleSearch = useCallback(
        (input: string) => {
            return fetchQuery<queries_deviceTestSearchQuery>(environment, deviceTestSearchQuery, {
                id: device,
                pageSize: 10,
                testName: input,
            })
                .toPromise()
                .then(result => {
                    const namedTests = result?.device?.tests.data
                        ? result.device.tests.data.filter(test => test.task?.name)
                        : [];
                    return namedTests.map(test => test.task!.name!);
                });
        },
        [device, environment]
    );

    return (
        <TableLayout
            title={`${pageTitle} ${data?.device?.name ? `for ${data.device.name}` : ''}`}
            columns={AllTableColumns}
            allowEditingColumns
            filterState={filters}
            dispatchFilterState={dispatchFilters}
            tableState={tableState}
            dispatchTableState={dispatchTableState}
            data={tests?.data ?? null}
            getRowId={row => row.id}
            page={tests?.pageInfo.page}
            pageCount={tests?.pageInfo.total}
            overallCount={data?.device?.overallTests.total}
            resultCount={data?.device?.tests.total}
            hasError={!!error}
            onRetry={retry}
            searchPlaceholder='Search by Test Name'
            onSearch={handleSearch}
            renderSearchResult={(item: string) => item}
            renderSearchResultAsString={(item: string) => item}
            emptyMessage='No tests have been run for this device.'
            unit='Test'
            primaryAction='Schedule a test'
            primaryActionLink={Paths.TestsScheduleTest}
            getItemLink={item => `${Paths.ReportBatteriesByTestDevice}/${item.id}`}
        />
    );
};
