import React, { FC } from 'react';

import { CommonFilterProps, RangeFilter } from 'filters/common';

import { BatteryTypeFilterRange } from './BatteryTypeFilterRange';

export type BatteryTypeCapacityFilterUIProps = CommonFilterProps<RangeFilter | null>;

export const BatteryTypeCapacityFilterUI: FC<BatteryTypeCapacityFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => (
    <BatteryTypeFilterRange
        current={current}
        onClearOrBack={onClearOrBack}
        onClose={onClose}
        onSubmit={onSubmit}
        title='Filter by Capacity (Ah)'
        distributionType='Capacity'
        countLabel={['Min', 'Max']}
        showBack={hasPrevious}
    />
);
