import { Dispatch } from 'react';

import { DeviceTypeFilter } from 'filters/device';

import { FilterAction, FilterState, useFilterReducer } from '../common';
import {
    BatteryTestColumnFilterMap,
    DefaultExtraFilters,
    DefaultValues,
    StaticBatteryTestFilterDefinitions,
} from './settings';
import { BatteryTestExtraFilters, BatteryTestTableColumn } from './types';

export type BatteryTestFilterState = FilterState<BatteryTestTableColumn, BatteryTestColumnFilterMap>;
export type BatteryTestFilterAction = FilterAction<BatteryTestColumnFilterMap, BatteryTestTableColumn>;

export function useBatteryTestFilter(taskId?: string): [BatteryTestFilterState, Dispatch<BatteryTestFilterAction>] {
    const [state, updateState] = useFilterReducer<
        BatteryTestTableColumn,
        BatteryTestColumnFilterMap,
        BatteryTestFilterState,
        BatteryTestFilterAction
    >('battery-test-filter', DefaultValues, StaticBatteryTestFilterDefinitions, DefaultExtraFilters, { taskId });

    return [state, updateState];
}

export function batteryTestToFilterObject(filters: BatteryTestFilterState): Record<string, unknown> {
    const output: Record<string, unknown> = {};
    const { Site: site, State: state, Name: name } = filters.columnValues;

    if (name.length > 0) {
        output.name = name.map(filter => ({ value: filter.name }));
    }

    if (site.length > 0) {
        output.siteName = site.map(filter => ({ value: filter.name }));
    }

    if (state.length > 0) {
        output.siteState = state.map(filter => ({ value: filter.state }));
    }

    for (const [definitionName, values] of Object.entries(filters.extraFilters)) {
        const definition = filters.filterDefinitions.find(definition => definition.name === definitionName);
        if (!definition || !values) {
            continue;
        }

        switch (definition.category) {
            case BatteryTestExtraFilters.DeviceType: {
                const types = values as DeviceTypeFilter[];
                if (types.length > 0) {
                    output.deviceType = types.map(type => ({ value: type.id }));
                }
                break;
            }
        }
    }

    return output;
}

export function batteryTestDeviceToFilterObject(filters: BatteryTestFilterState): Record<string, unknown> {
    const output: Record<string, unknown> = {};
    const { Status: status } = filters.columnValues;

    if (status.length > 0) {
        output.deviceStatus = status.map(filter => ({ value: filter.id }));
    }

    return output;
}
