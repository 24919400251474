import React, { FC } from 'react';

import { SkeletonText } from 'components/Skeleton';

export const BatteryTestCardExpansionSkeleton: FC = () => {
    return (
        <div className='mt-4 pt-4 border-t grid' style={{ gridTemplateColumns: '1fr max-content' }}>
            <div>
                <SkeletonText characters={15} />
            </div>
            <div>
                <SkeletonText characters={10} />
            </div>
            <div>
                <SkeletonText characters={15} />
            </div>
            <div>
                <SkeletonText characters={10} />
            </div>
            <div className='col-span-2'>
                <SkeletonText characters={25} />
            </div>
        </div>
    );
};
