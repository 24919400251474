import React, { FC } from 'react';

import { MultipleSelect } from 'components/MultipleSelect';

import { CommonFilterProps } from '../../common/types';
import { SiteStateFilter } from '../types';

export type SiteStateFilterUIProps = CommonFilterProps<SiteStateFilter[]>;

export const SiteStateFilterUI: FC<SiteStateFilterUIProps> = ({ current, onClearOrBack, onClose, onSubmit }) => {
    const options: SiteStateFilter[] = [
        { state: 'ACT' },
        { state: 'NSW' },
        { state: 'NT' },
        { state: 'QLD' },
        { state: 'SA' },
        { state: 'TAS' },
        { state: 'VIC' },
        { state: 'WA' },
    ];

    return (
        <MultipleSelect
            options={options}
            active={current}
            title='Filter by state'
            renderItem={item => item.state}
            renderItemSimple={item => item.state}
            areItemsEqual={(itemA, itemB) => itemA.state === itemB.state}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onClose={onClose}
        />
    );
};
