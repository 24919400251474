import React, { Dispatch, FC } from 'react';

import { FormDropdown, FormSection } from '@accesstel/pcm-ui';

import { AuthLevelAdmin, AuthLevelRead, AuthLevelWrite } from '../__generated__/EditUserContentQuery.graphql';
import { hasAnyPermission } from '../helpers';
import { Action, ActionType, EditState } from '../reducer';

const AuthLevelReadOptions: AuthLevelRead[] = ['None', 'ReadOnly'];
const AuthLevelWriteOptions: AuthLevelWrite[] = ['None', 'ReadOnly', 'ReadWrite'];
const AuthLevelAdminOptions: AuthLevelAdmin[] = ['None', 'Admin'];

export interface PermissionsSectionProps {
    state: EditState;
    dispatch: Dispatch<Action>;
}

export const PermissionsSection: FC<PermissionsSectionProps> = ({ state, dispatch }) => {
    let generalError: string | undefined;
    if (hasAnyPermission(state.permissions) && state.permissions.general === 'None') {
        generalError = 'This will be set to Read Only because the user has other permissions.';
    }

    return (
        <FormSection label='Permissions'>
            <FormDropdown
                placeHolder='General Access'
                items={AuthLevelReadOptions}
                value={state.permissions.general}
                renderItem={renderAuthLevelRead}
                onChange={value => dispatch({ type: ActionType.UpdatePermissionGeneral, value })}
                errorMessage={generalError}
                light
            />
            <FormDropdown
                placeHolder='Asset Management'
                items={AuthLevelWriteOptions}
                value={state.permissions.assets}
                renderItem={renderAuthLevelWrite}
                onChange={value => dispatch({ type: ActionType.UpdatePermissionAssets, value })}
                light
            />
            <FormDropdown
                placeHolder='Battery Health Tasks'
                items={AuthLevelWriteOptions}
                value={state.permissions.batteryHealthTasks}
                renderItem={renderAuthLevelWrite}
                onChange={value => dispatch({ type: ActionType.UpdatePermissionBatteryHealthTasks, value })}
                light
            />
            <FormDropdown
                placeHolder='Administration Access'
                items={AuthLevelAdminOptions}
                value={state.permissions.administration}
                renderItem={renderAuthLevelAdmin}
                onChange={value => dispatch({ type: ActionType.UpdatePermissionAdministration, value })}
                light
            />
        </FormSection>
    );
};

function renderAuthLevelRead(level: AuthLevelRead): string {
    switch (level) {
        case 'None':
            return 'No Access';
        case 'ReadOnly':
            return 'Read Only';
        default:
            return 'Unknown';
    }
}

function renderAuthLevelAdmin(level: AuthLevelAdmin): string {
    switch (level) {
        case 'None':
            return 'No Access';
        case 'Admin':
            return 'Admin Access';
        default:
            return 'Unknown';
    }
}

function renderAuthLevelWrite(level: AuthLevelWrite): string {
    switch (level) {
        case 'None':
            return 'No Access';
        case 'ReadOnly':
            return 'Read Only';
        case 'ReadWrite':
            return 'Read and Write';
        default:
            return 'Unknown';
    }
}
