/**
 * @generated SignedSource<<4cd2f8205c9f206c7bf82c7d4a34282b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TaskDistributionType = "TaskDeviceCountDistribution" | "%future added value";
export type TaskStateFilterable = "Aborted" | "Cancelled" | "Completed" | "InProgress" | "Scheduled" | "%future added value";
export type TaskStateFilter = {
  value: TaskStateFilterable;
};
export type TaskFilterRange_taskDistributionQuery$variables = {
  taskState?: ReadonlyArray<TaskStateFilter> | null;
  type: TaskDistributionType;
};
export type TaskFilterRange_taskDistributionQuery$data = {
  readonly taskDistribution: {
    readonly distribution: ReadonlyArray<{
      readonly key: number;
      readonly value: number;
    }>;
    readonly maximum: number | null;
    readonly minimum: number | null;
  };
};
export type TaskFilterRange_taskDistributionQuery = {
  response: TaskFilterRange_taskDistributionQuery$data;
  variables: TaskFilterRange_taskDistributionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "taskState"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "taskState",
        "variableName": "taskState"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": "TaskDistribution",
    "kind": "LinkedField",
    "name": "taskDistribution",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TaskDistributionCount",
        "kind": "LinkedField",
        "name": "distribution",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "key",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "minimum",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "maximum",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskFilterRange_taskDistributionQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TaskFilterRange_taskDistributionQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "6ca027019b959b02348dd05a5f009b22",
    "id": null,
    "metadata": {},
    "name": "TaskFilterRange_taskDistributionQuery",
    "operationKind": "query",
    "text": "query TaskFilterRange_taskDistributionQuery(\n  $type: TaskDistributionType!\n  $taskState: [TaskStateFilter!]\n) {\n  taskDistribution(type: $type, taskState: $taskState) {\n    distribution {\n      key\n      value\n    }\n    minimum\n    maximum\n  }\n}\n"
  }
};
})();

(node as any).hash = "d01b684e5f163afd9b75e4c80d3eed53";

export default node;
