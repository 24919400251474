import React, { useEffect, useState } from 'react';
import { QueryRenderer } from 'react-relay';
import { useRelayEnvironment } from 'react-relay';

import { Table } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';

import { TableDeviceBody } from './TableDeviceBody';
import { FirmwareTableQuery, FirmwareTableQuery$data } from './__generated__/FirmwareTableQuery.graphql';
import { TableColumns } from './settings';

interface TableQueryProps {
    siteNameFilter?: string;
}

export type Site = FirmwareTableQuery$data['sites']['data'][number];
export type Device = Site['devices']['data'][number];

export const FirmwarePageTableQuery: React.FC<TableQueryProps> = ({ siteNameFilter = '' }) => {
    const environment = useRelayEnvironment();
    const [pageNumber, setPageNumber] = useState(1);
    useEffect(() => {
        // this effect prevents the table from being on the incorrect
        // page after doing a filter
        // ie:
        // moveing to page 300
        // then doing a filter, the table is still on page 300
        // even though there are only 3 pages of results
        setPageNumber(1);
    }, [siteNameFilter]);

    return (
        <QueryRenderer<FirmwareTableQuery>
            environment={environment}
            query={graphql`
                query FirmwareTableQuery($page: Int = 1, $search: String!) {
                    sites(
                        page: $page
                        search: $search
                        orderBy: { field: Name, dir: Asc }
                        onlyProvisioningStatuses: Active
                    ) {
                        pageInfo {
                            page
                            total
                            hasNext
                            hasPrevious
                        }
                        data {
                            firmware {
                                commonVersion {
                                    name
                                }
                                multipleVersions
                                status
                            }
                            name
                            address {
                                state
                            }
                            id
                            devices {
                                data {
                                    id
                                    name
                                    firmwareStatus
                                    lastUpdate
                                    firmware {
                                        name
                                    }
                                }
                            }
                        }
                    }
                }
            `}
            variables={{
                page: pageNumber,
                search: siteNameFilter,
            }}
            render={({ error, props, retry }) => {
                const pageInfo = {
                    page: 1,
                    total: 1,
                    hasNext: false,
                    hasPrevious: false,
                };
                let data = null;
                if (props) {
                    data = props.sites.data;
                    pageInfo.page = props.sites.pageInfo.page;
                    pageInfo.total = props.sites.pageInfo.total;
                    pageInfo.hasNext = props.sites.pageInfo.hasNext ?? false;
                    pageInfo.hasPrevious = props.sites.pageInfo.hasPrevious ?? false;
                }

                const customColumnMap = new Map<string, string>();
                customColumnMap.set('FIRMWARE VERSION', 'w-1/4');
                customColumnMap.set('UPDATES', 'w-1/4');

                return (
                    <Table<Site, Device>
                        columns={TableColumns}
                        data={data}
                        getRowId={row => row.id}
                        getSubRows={row => [...(row.devices?.data ?? [])]}
                        isError={!!error}
                        onRetry={() => retry?.()}
                        onSetPage={setPageNumber}
                        page={props?.sites.pageInfo.page}
                        pageCount={props?.sites.pageInfo.total}
                        autoExpandSubRow
                        clickBehaviour='expand'
                        subRowExpansionComponent={row => <TableDeviceBody device={row.original} />}
                        emptyMessage={'There are no matching sites'}
                        emptySubRowsMessage='There are no devices on this site'
                    />
                );
            }}
        />
    );
};
