/**
 * @generated SignedSource<<e03d0ad7127f054a68ee8f56bb2727e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BatteryTestState = "Aborted" | "Analyzing" | "Failed" | "Finalizing" | "InProgress" | "Inconclusive" | "Passed" | "Scheduled" | "SmartStart" | "Waiting" | "%future added value";
export type BatteryTestCardExpansionContentLoadQuery$variables = {
  id: string;
};
export type BatteryTestCardExpansionContentLoadQuery$data = {
  readonly task: {
    readonly devices: {
      readonly data: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly site: {
          readonly address: {
            readonly state: string;
          };
          readonly name: string;
        };
        readonly test: {
          readonly state: BatteryTestState;
        } | null;
      }>;
      readonly total: number;
    };
  } | null;
};
export type BatteryTestCardExpansionContentLoadQuery = {
  response: BatteryTestCardExpansionContentLoadQuery$data;
  variables: BatteryTestCardExpansionContentLoadQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v6 = [
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Address",
  "kind": "LinkedField",
  "name": "address",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BatteryTestCardExpansionContentLoadQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "task",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PaginatedDevices",
            "kind": "LinkedField",
            "name": "devices",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "data",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Site",
                    "kind": "LinkedField",
                    "name": "site",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v1/*: any*/),
                    "concreteType": "DeviceBatteryTestResults",
                    "kind": "LinkedField",
                    "name": "test",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BatteryTestCardExpansionContentLoadQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "task",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PaginatedDevices",
            "kind": "LinkedField",
            "name": "devices",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "data",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Site",
                    "kind": "LinkedField",
                    "name": "site",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v7/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v1/*: any*/),
                    "concreteType": "DeviceBatteryTestResults",
                    "kind": "LinkedField",
                    "name": "test",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "79bcea468c349a4df0c53a4075e7a463",
    "id": null,
    "metadata": {},
    "name": "BatteryTestCardExpansionContentLoadQuery",
    "operationKind": "query",
    "text": "query BatteryTestCardExpansionContentLoadQuery(\n  $id: ID!\n) {\n  task(id: $id) {\n    __typename\n    devices {\n      total\n      data {\n        id\n        name\n        site {\n          name\n          address {\n            state\n          }\n          id\n        }\n        test(id: $id) {\n          state\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "15144149efca215f8cb5f130371884cd";

export default node;
