import graphql from 'babel-plugin-relay/macro';

export const inProgressTestsQuery = graphql`
    query queries_inProgressTestsQuery($pageSize: Int!, $orderBy: TaskOrdering!) {
        tasks(pageSize: $pageSize, type: BatteryTest, state: "InProgress", orderBy: $orderBy) {
            total
            data {
                id
                ... on BatteryTest {
                    ...TestHorizontalCard_data
                }
            }
            pageInfo {
                page
                size
                total
                hasNext
                hasPrevious
            }
        }
    }
`;
