import { Duration } from 'luxon';
import { number, object } from 'yup';

const duration = object()
    .required('This is required')
    .test({
        name: 'validDuration',
        message: 'Invalid duration',
        test: value => {
            if (!value) {
                return false;
            }

            return Duration.isDuration(value);
        },
    });

export const DataCollectionValidationSchema = object({
    polling: object({
        generalInterval: duration.test({
            name: 'slowerThanHighFrequencyInterval',
            message: 'General interval must be slower than high frequency interval',
            test: (value, context) => {
                if (!value) {
                    return false;
                }

                const durationValue = (value as unknown) as Duration;
                if (!durationValue.isValid) {
                    return false;
                }

                const highFrequencyInterval = context.parent.highFrequencyInterval;
                if (!highFrequencyInterval) {
                    return true;
                }

                const durationHighFrequencyInterval = (highFrequencyInterval as unknown) as Duration;

                if (!durationHighFrequencyInterval.isValid) {
                    return true;
                }

                return durationValue >= durationHighFrequencyInterval;
            },
        }),
        highFrequencyInterval: duration.test({
            name: 'fasterThanGeneralInterval',
            message: 'High frequency interval must be faster than general interval',
            test: (value, context) => {
                if (!value) {
                    return false;
                }

                const durationValue = (value as unknown) as Duration;
                if (!durationValue.isValid) {
                    return false;
                }

                const generalInterval = context.parent.generalInterval;
                if (!generalInterval) {
                    return true;
                }

                const durationGeneralInterval = (generalInterval as unknown) as Duration;
                if (!durationGeneralInterval.isValid) {
                    return true;
                }

                return durationValue <= durationGeneralInterval;
            },
        }),
        syncInterval: duration,
        timeout: duration,
    }),
    deviceManagement: object({
        checkInInterval: duration,
        missedCheckInTimeout: duration,
        allocationDelay: duration,
    }),
});

export const BatteryTestingValidationSchema = object({
    startTimeout: duration,
    smartStartTimeout: duration,
    dischargeFaultDetectionTime: duration,
    dischargeFaultVoltageHistoresis: number()
        .typeError('Must be a number greater than 0')
        .required('This is required')
        .positive('Must be greater than 0'),
    defaultQuickTestThresholdVoltage: number()
        .typeError('Must be a number greater than 0')
        .required('This is required')
        .positive('Must be greater than 0'),
    defaultQuickTestReservePercent: number()
        .typeError('Must be a number between 0 and 100')
        .required('This is required')
        .min(0, 'Must be between 0 and 100')
        .max(100, 'Must be between 0 and 100'),
});

export const InsightsValidationSchema = object({
    rectifierCapacityHeadroomPercent: number()
        .typeError('Must be a number between 0 and 100')
        .required('This is required')
        .min(0, 'Must be between 0 and 100')
        .max(100, 'Must be between 0 and 100'),
    abnormalFloatCurrentLimitPercent: number()
        .typeError('Must be a number between 0 and 100')
        .required('This is required')
        .min(0, 'Must be between 0 and 100')
        .max(100, 'Must be between 0 and 100'),
    syncInterval: duration,
});
