import React, { FC, useCallback, useReducer, useRef } from 'react';
import { useRelayEnvironment } from 'react-relay';
import { useNavigate } from 'react-router-dom';

import { ThemedPageHeading, useToast } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import { PageHeader, useDocumentTitle } from 'components';
import { Formik, FormikErrors, FormikHelpers } from 'formik';
import { FormikProvisionLayout } from 'layouts/FormikProvisionLayout';
import { Paths } from 'lib/routes';
import { BatteryInformation } from 'views/manage/battery-type/add-edit/BatteryInformation';
import { convertBatteryTypeFormStateToApi } from 'views/manage/battery-type/add-edit/lib/convert';
import { addNewBatteryType } from 'views/manage/battery-type/add-edit/lib/saving';
import { ProvisionSectionHeading } from 'views/manage/components';
import { SiteInformation } from 'views/manage/site/add-edit/SiteInformation';
import { convertSiteFormStateToApi } from 'views/manage/site/add-edit/lib/convert';
import { addNewSite } from 'views/manage/site/add-edit/lib/saving';

import { DeviceInformation } from './DeviceInformation';
import { addBatteryToDevice, addNewDevice, decodeDeviceApiErrors } from './helperFunctions';
import { convertBatteryFormStateToApi, convertDeviceFormStateToApi, switchCompanionStateToNormal } from './lib/convert';
import { usePrefillData } from './lib/prefill';
import { formControlInitialState, formControlReducer } from './lib/reducer';
import { DeviceFormValues } from './schema';
import { checkDeviceFormAdditionalValidity, getDeviceValidationSchema } from './validation';

const Title = 'Add Device';

/**
 * Form for adding a new device.
 *
 * Prefilling:
 * - If a site ID is passed in the URL using the `site` search option, it will be pre-filled in the form.
 * - If a companion ID is passed in the URL using the `companion` search option, it will be pre-filled in the form IF the site ID is also passed in the URL.
 */
export const AddDeviceForm: FC = () => {
    const environment = useRelayEnvironment();
    const navigate = useNavigate();
    const shouldReset = useRef(false);

    const initialFormData = usePrefillData();

    useDocumentTitle(Title);

    const { show } = useToast();
    // device state variables
    const [formControlState, formControlDispatch] = useReducer(formControlReducer, formControlInitialState);

    const handleSite = useCallback(
        async (values: DeviceFormValues, setErrors: (errors: FormikErrors<DeviceFormValues>) => void) => {
            try {
                const newSite = convertSiteFormStateToApi(values.newSite);
                return await addNewSite(newSite, environment);
            } catch (error) {
                if (Array.isArray(error)) {
                    const formErrors = decodeDeviceApiErrors(error);
                    setErrors({ newSite: formErrors });

                    show({
                        text: 'Unable to save site. Please correct the highlighted errors',
                        variant: 'error',
                    });
                } else {
                    captureException(error);

                    show({
                        text: 'Unable to save site. Try again later',
                        variant: 'error',
                    });
                }
                return;
            }
        },
        [environment, show]
    );

    const handleBattery = useCallback(
        async (values: DeviceFormValues, setErrors: (errors: FormikErrors<DeviceFormValues>) => void) => {
            try {
                const newBatteryType = convertBatteryTypeFormStateToApi(values.newBatteryType);
                return await addNewBatteryType(newBatteryType, environment);
            } catch (error) {
                if (Array.isArray(error)) {
                    const formErrors = decodeDeviceApiErrors(error);
                    setErrors({ newBatteryType: formErrors });

                    show({
                        text: 'Unable to save battery type. Please correct the highlighted errors',
                        variant: 'error',
                    });
                } else {
                    captureException(error);

                    show({
                        text: 'Unable to save battery type. Try again later',
                        variant: 'error',
                    });
                }
            }
        },
        [environment, show]
    );

    const handleCompanionDevice = useCallback(
        async (
            values: DeviceFormValues,
            setErrors: (errors: FormikErrors<DeviceFormValues>) => void
        ): Promise<string | undefined> => {
            const updatedValues = switchCompanionStateToNormal(
                values,
                formControlState.duplicateCompanionPlaneBatteries
            );

            const deviceToAdd = convertDeviceFormStateToApi(updatedValues);

            try {
                return await addNewDevice(deviceToAdd, environment);
            } catch (error) {
                if (Array.isArray(error)) {
                    const formErrors = decodeDeviceApiErrors(error);
                    setErrors({ newCompanion: formErrors });

                    show({
                        text: 'Unable to save device. Please correct the highlighted errors',
                        variant: 'error',
                    });
                } else {
                    captureException(error, scope => {
                        return scope;
                    });

                    show({
                        text: 'Unable to save device. Try again later',
                        variant: 'error',
                    });
                }
                return;
            }
        },
        [environment, formControlState.duplicateCompanionPlaneBatteries, show]
    );

    const handleAdd = useCallback(
        async (values: DeviceFormValues, { setErrors, setSubmitting, resetForm }: FormikHelpers<DeviceFormValues>) => {
            setSubmitting(true);
            try {
                // NEW SITE SECTION
                if (formControlState.addNewSite) {
                    const siteId = await handleSite(values, setErrors);
                    if (!siteId) {
                        setSubmitting(false);
                        return;
                    }
                    values.site = {
                        id: siteId,
                        displayName: '',
                    };
                }

                // NEW BATTERY SECTION
                if (formControlState.addNewBatteryType) {
                    const addedBatteryTypeId = await handleBattery(values, setErrors);
                    if (!addedBatteryTypeId) {
                        setSubmitting(false);
                        return;
                    }
                    for (const batteryString of values.batteries.strings) {
                        if (batteryString.type === null) {
                            batteryString.type = {
                                id: addedBatteryTypeId,
                                manufacturer: '',
                                model: '',
                                displayName: '',
                            };
                        }
                    }
                    for (const batteryString of values.newCompanion.batteries.strings) {
                        if (batteryString.type === null) {
                            batteryString.type = {
                                id: addedBatteryTypeId,
                                manufacturer: '',
                                model: '',
                                displayName: '',
                            };
                        }
                    }
                }

                // NEW COMPANION DEVICE SECTION
                if (formControlState.addNewDualPlaneCompanionDevice) {
                    const addedCompanionDeviceId = await handleCompanionDevice(values, setErrors);
                    if (!addedCompanionDeviceId) {
                        setSubmitting(false);
                        return;
                    }
                    values.companionReference = {
                        id: addedCompanionDeviceId,
                        displayName: '',
                        siteName: '',
                    };
                }

                // ADD BATTERIES TO EXISTING DUAL PLANE DEVICE
                if (
                    formControlState.duplicateCompanionPlaneBatteries &&
                    !formControlState.addNewDualPlaneCompanionDevice &&
                    values.companionReference
                ) {
                    const batteries = convertBatteryFormStateToApi(values.batteries);
                    await addBatteryToDevice(values.companionReference.id, batteries, environment)
                        .then(() => {
                            // Complete
                        })
                        .catch(errors => {
                            // TODO: error handling validations
                            show({
                                text: 'Failed to add batteries to Dual Plane Companion Device',
                                variant: 'error',
                            });
                            return;
                        });
                }

                const newDevice = convertDeviceFormStateToApi(values);

                try {
                    await addNewDevice(newDevice, environment);

                    // USER NOTIFICATION
                    if (formControlState.addNewSite) {
                        show({
                            text: 'Site and Device successfully added!',
                            variant: 'info',
                        });
                    } else {
                        show({
                            text: 'Device successfully added!',
                            variant: 'info',
                        });
                    }

                    if (shouldReset.current) {
                        shouldReset.current = false;
                        resetForm();
                    } else {
                        navigate(Paths.Devices);
                    }
                } catch (error) {
                    if (Array.isArray(error)) {
                        const formErrors = decodeDeviceApiErrors(error);
                        setErrors(formErrors);

                        show({
                            text: 'Unable to save device. Please correct the highlighted errors',
                            variant: 'error',
                        });
                    } else {
                        captureException(error, scope => {
                            return scope;
                        });

                        show({
                            text: 'Unable to save device. Try again later',
                            variant: 'error',
                        });
                    }
                }
            } finally {
                setSubmitting(false);
            }
        },
        [
            environment,
            formControlState.addNewBatteryType,
            formControlState.addNewDualPlaneCompanionDevice,
            formControlState.addNewSite,
            formControlState.duplicateCompanionPlaneBatteries,
            handleBattery,
            handleCompanionDevice,
            handleSite,
            navigate,
            show,
        ]
    );

    // FIXME: Reset error state of the sites with "new" options when those options change
    return (
        <div className='space-y-6'>
            <PageHeader />
            <ThemedPageHeading value={Title} />
            <Formik
                initialValues={initialFormData}
                validationSchema={getDeviceValidationSchema(
                    formControlState.addNewSite,
                    formControlState.addNewBatteryType
                )}
                validate={values => checkDeviceFormAdditionalValidity(values, formControlState)}
                onSubmit={handleAdd}
            >
                <FormikProvisionLayout
                    type='device'
                    secondaryAction={() => {
                        shouldReset.current = true;
                    }}
                    operation='add'
                >
                    <DeviceInformation
                        // form control
                        formControlState={formControlState}
                        formControlDispatch={formControlDispatch}
                    />

                    {formControlState.addNewSite && (
                        <div className='pt-6 space-y-6'>
                            <ProvisionSectionHeading>New Site</ProvisionSectionHeading>
                            <SiteInformation namespace='newSite' />
                        </div>
                    )}

                    {formControlState.addNewBatteryType && (
                        <div className='pt-6 space-y-6'>
                            <ProvisionSectionHeading>New Battery Type</ProvisionSectionHeading>
                            <BatteryInformation namespace='newBatteryType' />
                        </div>
                    )}
                </FormikProvisionLayout>
            </Formik>
        </div>
    );
};
