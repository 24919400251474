import { Duration } from 'luxon';

import { PredefinedDuration } from './common';

export const Less30Min: PredefinedDuration = {
    title: 'Less than 30 minutes',
    asDurationRange: () => [Duration.fromObject({ seconds: 0 }).toISO(), Duration.fromObject({ minutes: 30 }).toISO()],
};

export const Less1Hour: PredefinedDuration = {
    title: 'Less than 1 hour',
    asDurationRange: () => [Duration.fromObject({ seconds: 0 }).toISO(), Duration.fromObject({ hour: 1 }).toISO()],
};

export const More1Hour: PredefinedDuration = {
    title: 'More than 1 hour',
    asDurationRange: () => [Duration.fromObject({ hours: 1 }).toISO(), Duration.fromObject({ days: 1 }).toISO()],
};

export const Less3Hour: PredefinedDuration = {
    title: 'Less than 3 hours',
    asDurationRange: () => [Duration.fromObject({ seconds: 0 }).toISO(), Duration.fromObject({ hours: 3 }).toISO()],
};

export const More5Hour: PredefinedDuration = {
    title: 'More than 5 hours',
    asDurationRange: () => [Duration.fromObject({ hours: 5 }).toISO(), Duration.fromObject({ years: 1 }).toISO()],
};

export const DefaultPresetRanges: PredefinedDuration[] = [Less30Min, More1Hour];
